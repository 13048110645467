import { Button, HStack, ModalFooter, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaSave, FaUndo } from 'react-icons/fa';

import { useModalInstance } from '@bq/components/UseModal';

import { useCasesBulkFormProvider } from './CasesBulkFormWrapper';

export const CasesBulkFormModalFooter = () => {
  const { t } = useTranslation('Sales');
  const { closeWithNoCallback } = useModalInstance();
  const {
    ctx: { formState },
  } = useCasesBulkFormProvider();

  return (
    <ModalFooter>
      <HStack justifyContent="flex-end">
        {formState === 'error' && (
          <Text as="span" color="red.500">
            {t('global:save_error')}
          </Text>
        )}
        {formState === 'saved' && (
          <Text as="span" color="green.500">
            {t('global:save_success')}
          </Text>
        )}
        <Button
          colorScheme="red"
          variant="outline"
          leftIcon={<FaUndo />}
          onClick={() => {
            closeWithNoCallback();
          }}
        >
          {t('global:cancel')}
        </Button>
        <Button
          type="submit"
          colorScheme="brand"
          isLoading={formState === 'saving'}
          leftIcon={<FaSave />}
        >
          {t('global:save')}
        </Button>
      </HStack>
    </ModalFooter>
  );
};
