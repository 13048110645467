import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { QueryClient, useQuery, useQueryClient } from '@tanstack/react-query';
import { getCustomEventTypes } from './api';
import { getCustomEventForm } from './custom-forms';
import { CustomEventType } from './types';

interface ICustomEventsContext {
  customEventTypes: CustomEventType[] | null;
}

export const CustomEventsContext = createContext<ICustomEventsContext | null>(
  null
);

export const useCustomEvents = (): ICustomEventsContext => {
  const context = useContext(CustomEventsContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
};

export const CustomEventsProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const customEventTypes = useCustomEventTypes();

  return (
    <CustomEventsContext.Provider value={{ customEventTypes }}>
      {children}
    </CustomEventsContext.Provider>
  );
};

/**
 * Get custom event types, but preload their form definitions before returning
 */
const getPreloadedCustomEventTypes = async (
  queryClient: QueryClient
): Promise<CustomEventType[]> => {
  const eventTypes = await getCustomEventTypes();

  Promise.all(
    eventTypes.map((eventType) => getCustomEventForm(queryClient, eventType.ID))
  );

  return eventTypes;
};

const useCustomEventTypes = (): CustomEventType[] | null => {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: ['customEventTypes'],
    queryFn: () => getPreloadedCustomEventTypes(queryClient),
  });

  return data ?? null;
};
