export async function setTheme(themeName: string): Promise<void> {
  const themeFileName = window.build_manifest[`theme_${themeName}.css`];

  // Disable all other theme style sheets
  document.querySelectorAll('link.theme-stylesheet').forEach((stylesheet) => {
    if (stylesheet instanceof HTMLLinkElement) {
      stylesheet.disabled = true;
    }
  });

  // Enable stylesheet of selected theme
  const selectedStylesheet = document.querySelector(
    `link.theme-stylesheet[href="${themeFileName}"]`
  );
  if (selectedStylesheet instanceof HTMLLinkElement) {
    selectedStylesheet.disabled = false;
  }
}
