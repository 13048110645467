module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-12\" data-field-id=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n    <div class=\"row no-gutters\">\n        <div class=\"col-auto px-1 py-2 editable-field-drag-handle\">\n            <span class=\"fa fa-bars\"></span>\n        </div>\n        <div class=\"col px-1 editable-field-wrapper\"></div>\n        <div class=\"col-auto px-1 py-1\">\n            <button type=\"button\" class=\"btn btn-danger btn-sm editable-field-delete-btn\">\n                <span class=\"fa fa-trash\"></span>\n            </button>\n            <button type=\"button\" class=\"btn btn-secondary btn-sm editable-field-edit-btn\">\n                <span class=\"fa fa-cog\"></span>\n            </button>\n        </div>\n    </div>\n</div>";
},"useData":true})