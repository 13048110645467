import { useEffect } from 'react';

export interface KeyModifiers {
  shift?: boolean;
  ctrl?: boolean;
  meta?: boolean;
  alt?: boolean;
}

export type KeyDescription =
  | { modifiers?: KeyModifiers; key: string }
  | { modifiers?: KeyModifiers; code: string };

export type KeyTrigger = 'up' | 'down';

export function useGlobalHotkey(
  key: KeyDescription | string,
  cb: (ev: KeyboardEvent) => void,
  trigger: KeyTrigger = 'down'
): void {
  useEffect(() => {
    const evName = trigger === 'up' ? 'keyup' : 'keydown';
    const keyDesc = typeof key === 'string' ? { key } : key;
    const listener = (ev: KeyboardEvent) => {
      if (keyMatches(ev, keyDesc)) {
        cb(ev);
      }
    };
    document.addEventListener(evName, listener);

    return () => {
      document.removeEventListener(evName, listener);
    };
  }, [key, cb, trigger]);
}

function keyMatches(ev: KeyboardEvent, keyDesc: KeyDescription): boolean {
  if ('key' in keyDesc && keyDesc.key) {
    if (keyDesc.key !== ev.key) {
      return false;
    }
  }

  if ('code' in keyDesc && keyDesc.code) {
    if (keyDesc.code !== ev.code) {
      return false;
    }
  }

  if (keyDesc.modifiers) {
    return modifiersMatch(ev, keyDesc.modifiers);
  }

  return true;
}

function modifiersMatch(ev: KeyboardEvent, modifiers: KeyModifiers): boolean {
  if (modifiers.shift !== undefined && modifiers.shift !== ev.shiftKey) {
    return false;
  }
  if (modifiers.ctrl !== undefined && modifiers.ctrl !== ev.ctrlKey) {
    return false;
  }
  if (modifiers.meta !== undefined && modifiers.meta !== ev.metaKey) {
    return false;
  }
  if (modifiers.alt !== undefined && modifiers.alt !== ev.altKey) {
    return false;
  }

  return true;
}
