import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import { SimpleContactDisplay } from '../../../../../../BootQuery/Assets/components/ContactDisplay/SimpleContactDisplay';
import { CustomerContactDisplayFields } from './CustomerContactDisplayFields';

interface CUstomerProps {
  contactID: number;
  contactType: ContactType;
  contactName: string;
  valueFontWeight?: string | undefined;
}

export const CustomerContactDisplay = ({
  contactName,
  contactID,
  contactType,
  valueFontWeight,
}: CUstomerProps) => {
  return (
    <SimpleContactDisplay
      valueFontWeight={valueFontWeight}
      contactID={contactID}
      contactType={contactType}
      contactName={contactName}
    >
      <CustomerContactDisplayFields
        contactID={contactID}
        contactType={contactType}
      />
    </SimpleContactDisplay>
  );
};
