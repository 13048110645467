import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TicketPriorityFormProps } from './types';

export const PrioritiesFormFields = () => {
  const { t } = useTranslation('Ticketing');
  const { register } = useFormContext<TicketPriorityFormProps>();

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('global:name')}</FormLabel>
        <Input {...register('name', { required: true })}></Input>
      </FormControl>
      <FormLabel alignSelf="flex-start" fontWeight="bold">
        {t('Ticketing:response_interval')}
      </FormLabel>
      <SimpleGrid columns={[1, 1, 3]} w="full" gridGap={4}>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.minute')}:</FormLabel>
          <Input {...register('responseInterval.minutes')}></Input>
        </FormControl>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.hours')}:</FormLabel>
          <Input {...register('responseInterval.hours')}></Input>
        </FormControl>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.days')}:</FormLabel>
          <Input {...register('responseInterval.days')}></Input>
        </FormControl>
      </SimpleGrid>
      <FormLabel alignSelf="flex-start" fontWeight="bold">
        {t('Ticketing:resolution_interval')}
      </FormLabel>
      <SimpleGrid columns={[1, 1, 3]} w="full" gridGap={4}>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.minute')}:</FormLabel>
          <Input {...register('resolutionInterval.minutes')}></Input>
        </FormControl>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.hours')}:</FormLabel>
          <Input {...register('resolutionInterval.hours')}></Input>
        </FormControl>
        <FormControl as={HStack}>
          <FormLabel>{t('global:time_units.days')}:</FormLabel>
          <Input {...register('resolutionInterval.days')}></Input>
        </FormControl>
      </SimpleGrid>
    </VStack>
  );
};
