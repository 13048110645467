import { find } from 'lodash-es';

import Module from 'BootQuery/Assets/js/module';
import { renderReact } from 'BootQuery/Assets/js/render-react';

import { eventTypeProviders } from '../components/event-type-providers';
import { Settings } from '../components/Settings';
import { ReactRoutes } from './react-routes';

export default class Events extends Module {
  init(data) {
    super.init(data);
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      eventTypes: eventTypeProviders,
    };
  }

  static matchReactRoute = /^[/](events|calendar)[/]/;

  activateElements(target, data) {
    this.activateSettings(target);
  }

  activateSettings(target) {
    const $tgt = target.findElement('#events-settings-root');
    if ($tgt.length > 0) {
      this.renderSettings($tgt);
    }
  }

  renderSettings($container) {
    const container = $container[0];
    const customEventTypes = this.findEventsTab().data.customEventTypes ?? [];
    setTimeout(() => {
      renderReact(container, Settings, { customEventTypes });
    }, 0);
  }

  findEventsTab(data) {
    data = data ?? window.Bootstrap;
    return find(data.result.tabs, {
      key: 'events',
    });
  }
}
