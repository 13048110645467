import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonRadio, Option } from '@bq/components/ButtonRadio';

import { BlacklistDir } from './types';

interface Props {
  value: BlacklistDir;
  onChange: Dispatch<SetStateAction<BlacklistDir>>;
}

export const DirectionInput = ({ value, onChange }: Props): ReactElement => {
  const blacklistOptions = useBlacklistOptions();

  const handleChange = useCallback(
    (newVal: string) => {
      assertDirection(newVal);
      onChange(newVal);
    },
    [onChange]
  );

  return (
    <ButtonRadio
      name="direction"
      options={blacklistOptions}
      value={value}
      onChange={handleChange}
    />
  );
};

function assertDirection(direction: string): asserts direction is BlacklistDir {
  if (!['both', 'inbound', 'outbound'].includes(direction)) {
    throw new Error(`Got invalid direction from input: ${direction}`);
  }
}

function useBlacklistOptions(): Option[] {
  const { t } = useTranslation('Telephony');

  return useMemo(
    () => [
      {
        id: 'both',
        title: t('Telephony:blacklist_direction.both'),
      },
      {
        id: 'inbound',
        title: t('Telephony:blacklist_direction.inbound'),
      },
      {
        id: 'outbound',
        title: t('Telephony:blacklist_direction.outbound'),
      },
    ],
    [t]
  );
}
