import { useCallback, useMemo } from 'react';
import { AsyncCreatableSelect, MultiValue } from 'chakra-react-select';
import { FieldError } from 'react-hook-form';

import { usePortalContainer } from '@bq/components/Portal/PortalContext';
import { modalSelect } from '@bq/components/Select/select-styles';
import { SelectOption } from '@bq/components/SelectWrappers';

import { getManyTags } from '../../../api/Tags/getManyTags';

type TagSelectOption = SelectOption<string>;
interface Props {
  value?: string[];
  onChange: (val: string[] | null) => void;
  error?: FieldError;
}
export const TagsField = ({ onChange, error, value }: Props) => {
  const { current: menuPortalTarget } = usePortalContainer();

  const valueToSelectOptions = useCallback(
    (val?: string[]): TagSelectOption[] => {
      return val?.map((item) => ({ label: item, value: item })) ?? [];
    },
    []
  );
  const selectOptionsToValue = useCallback(
    (value: MultiValue<TagSelectOption>): string[] => {
      return value.map((item) => item.value);
    },
    []
  );
  const valueSelect = useMemo(
    () => valueToSelectOptions(value),
    [value, valueToSelectOptions]
  );

  return (
    <AsyncCreatableSelect<TagSelectOption, true>
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={valueSelect}
      menuPortalTarget={menuPortalTarget}
      styles={modalSelect}
      isMulti={true}
      isInvalid={!!error?.message}
      cacheOptions
      defaultOptions
      loadOptions={async (search) => {
        const tags = await getManyTags({ filters: { $search: search } });

        return tags.map((tag) => ({
          value: tag.tag,
          label: tag.tag,
        }));
      }}
      onChange={(newVal) => {
        onChange(selectOptionsToValue(newVal ?? []));
      }}
      selectedOptionColorScheme="brand"
    />
  );
};
