import { ReactElement, useMemo } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RichText } from '@bq/components/RichText';
import { useItemPlaceholders } from '@bq/components/RichText/Placeholders';

import { getTemplateElements } from '../../Offers/Templating/TemplateElements';
import { Template } from './types';

interface Props {
  type: 'headerHTML' | 'footerHTML';
}
export const TemplateHeaderFooterField = ({ type }: Props): ReactElement => {
  const { control } = useFormContext<Partial<Template>>();
  const { field } = useController({
    control,
    name: type,
  });
  const templateElements = useMemo(() => getTemplateElements(), []);
  const { t } = useTranslation('Sales');

  const itemPlaceHolders = useItemPlaceholders(templateElements, {});

  return (
    <AccordionItem>
      <AccordionButton>
        <Box>
          {type === 'headerHTML'
            ? t('Sales:document_form.header')
            : t('Sales:document_form.footer')}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <RichText
          extensions={[itemPlaceHolders]}
          content={field.value ?? ''}
          onChange={field.onChange}
        />
      </AccordionPanel>
    </AccordionItem>
  );
};
