import { ReactElement, useCallback } from 'react';
import { Box, Flex, Heading, HStack } from '@chakra-ui/react';
import { subMinutes } from 'date-fns';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddButton } from '@bq/components/AddButton';
import { DateInput } from '@bq/components/DateInput';
import { DeleteButton } from '@bq/components/DeleteButton';
import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';
import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { uniqid } from 'app/assets/js/tsutil';
import { useSyncCrudField } from 'app/Modules/Ticketing/Assets/components/TicketingForm/use-sync-crud-field';

import { useAppointmentFormContext } from './form';
import { IReminderForm } from './schema';

export const AppointmentRemindersField = (): ReactElement => {
  const { t } = useTranslation('Events');

  const { control, getValues } = useAppointmentFormContext();
  const { field } = useController({
    control,
    name: 'reminders',
  });
  const [modifiedItems, modify, modifications, clearModifications] = useCrud({
    items: field.value.defaults ?? [],
  });

  useSyncCrudField({ field, modifications, clearModifications });

  const addReminder = useCallback(() => {
    // Remind 30 minutes before by default
    const startAt = getValues('startAt');
    const defaultTimestamp = startAt ? subMinutes(startAt, 30) : null;

    modify.add({ ID: uniqid(), timestamp: defaultTimestamp });
  }, [modify, getValues]);

  return (
    <>
      <Heading as="h2" fontSize="md">
        {t('Events:appointment_reminders')}
      </Heading>
      {modifiedItems.map((reminder) => (
        <AppointmentReminderRow
          key={reminder.ID}
          reminder={reminder}
          modify={modify}
        />
      ))}
      <Flex justifyContent="end">
        <AddButton onClick={addReminder} />
      </Flex>
    </>
  );
};

interface RowProps {
  modify: SettingsMethods<IReminderForm>;
  reminder: WithChange<IReminderForm>;
}

const AppointmentReminderRow = ({
  reminder,
  modify,
}: RowProps): ReactElement => {
  const deleteReminder = useCallback(() => {
    modify.del(reminder.ID);
  }, [modify, reminder.ID]);
  const setTimestamp = useCallback(
    (timestamp: Date | null) => {
      modify.upd({ ID: reminder.ID, timestamp });
    },
    [modify, reminder.ID]
  );

  return (
    <HStack>
      <Box flex="1 1 auto">
        <DateInput
          showTime
          value={reminder.timestamp ?? null}
          onChange={setTimestamp}
        />
      </Box>
      <DeleteButton onClick={deleteReminder} />
    </HStack>
  );
};
