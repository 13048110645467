import { memo, ReactElement, useMemo } from 'react';
import {
  ListItem,
  useColorModeValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { FilterTypeItem, UseComboboxRet } from './types';

interface AutocompleteListItemProps {
  item: FilterTypeItem;
  index: number;
  selectItem: UseComboboxRet['selectItem'];
  getItemProps: UseComboboxRet['getItemProps'];
  isActive: boolean;
}

const AutocompleteListItem = memo(
  ({
    item,
    index,
    selectItem,
    getItemProps,
    isActive,
  }: AutocompleteListItemProps): ReactElement => {
    const { item: itemStyle } = useMultiStyleConfig('Menu', {});
    const activeBg = useColorModeValue('gray.200', 'whiteAlpha.200');
    const activeStyle = useMemo(
      () => ({
        ...itemStyle,
        bg: activeBg,
      }),
      [itemStyle, activeBg]
    );

    return (
      <ListItem
        {...getItemProps({
          item,
          index,
          onMouseDown: () => {
            selectItem(item);
          },
        })}
        sx={isActive ? activeStyle : itemStyle}
        cursor="pointer"
        textAlign="left"
      >
        {item?.name ?? ''}
      </ListItem>
    );
  }
);
AutocompleteListItem.displayName = 'AutocompleteListItem';

export { AutocompleteListItem };
