import { useMemo } from 'react';

import { roundTo } from 'app/assets/js/tsutil';

import { IProductFormItem } from '../types';

export interface ProductTotals {
  grossWeightKg: number;
  netWeightKg: number;
  value: number;
  count: number;
}

export function useProductTotals(products: IProductFormItem[]): ProductTotals {
  return useMemo(() => computeTotals(products), [products]);
}

function computeTotals(products: IProductFormItem[]): ProductTotals {
  const initial: ProductTotals = {
    grossWeightKg: 0,
    netWeightKg: 0,
    value: 0,
    count: 0,
  };

  // Sum up values of all products
  const totals = products.reduce(
    (acc: ProductTotals, item: IProductFormItem) => {
      const netWeightKg = maybeParseFloat(item.netWeightKg) ?? 0;
      const grossWeightKg = maybeParseFloat(item.grossWeightKg) ?? 0;
      const value = maybeParseFloat(item.value) ?? 0;

      return {
        grossWeightKg: acc.grossWeightKg + grossWeightKg,
        netWeightKg: acc.netWeightKg + netWeightKg,
        value: acc.value + value,
        count: acc.count + 1,
      };
    },
    initial
  );

  // Use roundTo to cut off ugly floating point values like 3.3000000000000003
  return {
    ...totals,
    grossWeightKg: roundTo(totals.grossWeightKg, 12),
    netWeightKg: roundTo(totals.netWeightKg, 12),
    value: roundTo(totals.value, 12),
  };
}

function maybeParseFloat(value: string | undefined): number | null {
  if (!value) {
    return null;
  }

  const parsed = parseFloat(value);

  return Number.isNaN(parsed) ? null : parsed;
}
