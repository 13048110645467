import i18n from 'BootQuery/Assets/js/i18n';
import { Operator } from '../../types';

export const getDefaultComboboxOperators = (
  nullable = true
): (() => Operator[]) => {
  if (nullable) {
    return () => [
      { operator: 'eq', display: i18n.t('global:operators.is') },
      { operator: 'neq', display: i18n.t('global:operators.is_not') },
      { operator: 'null', display: i18n.t('global:operators.null') },
      { operator: 'notnull', display: i18n.t('global:operators.not_null') },
    ];
  }

  return () => [
    { operator: 'eq', display: i18n.t('global:operators.is') },
    { operator: 'neq', display: i18n.t('global:operators.is_not') },
  ];
};
