import { chakra, MenuList } from '@chakra-ui/react';

export const ScrollableMenuList = chakra(MenuList, {
  baseStyle: {
    zIndex: 'dropdown',
    maxH: 'max(calc(90vh - var(--navbar-height)), 400px)',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});
