import { Column, makeSelectionCell } from '@bq/components/Table';
import { formatDuration } from 'app/assets/js/format-duration';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import i18n from 'BootQuery/Assets/js/i18n';

import { Ticket } from '../../types';
import { ExpandCell } from './Cells/ExpandCell';
import { TicketContactCell } from './Cells/TicketContactCell';
import { TicketCreatorCell } from './Cells/TicketCreatorCell';
import { TicketCustomerCell } from './Cells/TicketCustomerCell';
import { TicketReportMethodCell } from './Cells/TicketReportMethodCell';
import { TicketStatus } from './Cells/TicketStatusCell';
import { TicketTagCell } from './Cells/TicketTagCell';
import { TicketTypeCell } from './Cells/TicketTypeCell';

type TicketCell = Column<Ticket>;
export function ticketColumns(expandable = true): TicketCell[] {
  return [
    makeSelectionCell(),
    {
      key: 'ID',
      title: '#',
      sort: 'ID',
      width: '140px',
      minWidth: 80,
      Cell: expandable ? ExpandCell : undefined,
      tdProps: { px: 1, py: 0 },
    },
    {
      key: 'state',
      title: i18n.t('Ticketing:form.state'),
      Cell: TicketStatus,
      width: '120px',
      tdProps: { py: 0 },
    },
    {
      key: 'title',
      title: i18n.t('Ticketing:form.title'),
      sort: 'title',
    },
    {
      key: 'customer',
      title: i18n.t('Ticketing:form.customer'),
      Cell: TicketCustomerCell,
    },
    {
      key: 'contact',
      title: i18n.t('Ticketing:form.contact'),
      Cell: TicketContactCell,
      defaultVisible: false,
    },
    {
      key: 'group',
      title: i18n.t('Ticketing:form.group'),
      getValue: ({ row }) => row.group.name,
    },
    {
      key: 'reportMethod',
      title: i18n.t('Ticketing:form.report_method'),
      Cell: TicketReportMethodCell,
    },
    {
      key: 'ticketType',
      title: i18n.t('Ticketing:form.ticket_type'),
      Cell: TicketTypeCell,
    },
    { key: 'tags', title: i18n.t('Ticketing:tags'), Cell: TicketTagCell },
    {
      key: 'priority',
      title: i18n.t('Ticketing:form.priority'),
      getValue: ({ row }) => row.priority.name,
    },
    {
      key: 'assignedUser',
      title: i18n.t('Ticketing:form.agent'),
      Cell: ({ row }) => TicketCreatorCell({ user: row.assignedUser }),
    },
    {
      key: 'createdByUser',
      title: i18n.t('Ticketing:form.creator'),
      Cell: ({ row }) => TicketCreatorCell({ user: row.createdByUser }),
    },
    {
      key: 'fromMailAddress.email',
      title: i18n.t('Ticketing:from_mail_address'),
      defaultVisible: false,
    },
    {
      key: 'createdAt',
      title: i18n.t('global:item_crud_dates.created_at'),
      sort: 'createdAt',
      getValue: ({ row }) => formatDate(row.createdAt, DateType.DateTime),
    },
    {
      key: 'updatedAt',
      sort: 'updatedAt',
      title: i18n.t('global:item_crud_dates.updated_at'),
      getValue: ({ row }) => formatDate(row.updatedAt, DateType.DateTime),
    },
    {
      key: 'reportedAt',
      sort: 'reportedAt',
      title: i18n.t('Ticketing:form.report_time'),
      getValue: ({ row }) => formatDate(row.reportedAt, DateType.DateTime),
    },
    {
      key: 'respondUntil',
      sort: 'respondUntil',
      title: i18n.t('Ticketing:form.respond_until'),
      getValue: ({ row }) => formatDate(row.respondUntil, DateType.DateTime),
      defaultVisible: false,
    },
    {
      key: 'resolveUntil',
      sort: 'resolveUntil',
      title: i18n.t('Ticketing:form.resolve_until'),
      getValue: ({ row }) => formatDate(row.resolveUntil, DateType.DateTime),
      defaultVisible: false,
    },
    {
      key: 'totalWorkDuration',
      sort: 'totalWorkDuration',
      title: i18n.t('Ticketing:total_work_duration'),
      defaultVisible: false,
      getValue: ({ row }) => {
        return formatDuration(row.totalWorkDurationSecs, true, true);
      },
    },
  ];
}

export const ticketFeatures = {
  $selected: 'select',
  title: 'title',
  ID: 'ID',
  state: 'state',
  customer: 'customer',
  contact: 'contact',
  group: 'group',
  assignedUser: 'assignedUser',
  ticketType: 'ticketType',
  priority: 'priority',
  createdAt: 'createdAt',
  createdByUser: 'createdByUser',
  reportMethod: 'reportMethod',
  reportedAt: 'reportedAt',
  updatedAt: 'updatedAt',
  tags: 'tags',
};
