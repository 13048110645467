/* eslint-disable react/jsx-no-literals */
import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

export interface DropdownCreateLabelProps {
  label: string;
}

export const DropdownCreateLabel = ({
  label,
}: DropdownCreateLabelProps): ReactNode => (
  <Trans
    ns="global"
    i18nKey="dropdown_add_as_new"
    values={{ label }}
    defaults='Dodaj <strong> "{{ label }}" </strong> kao novo'
  />
);
