import { ButtonGroup } from '@chakra-ui/react';
import { BubbleMenu, Editor } from '@tiptap/react';

import { Table } from './Buttons/Table/Table';

interface Props {
  editor: Editor;
}
export const TableBubbleMenu = ({ editor }: Props) => {
  return (
    <BubbleMenu
      editor={editor}
      shouldShow={({ editor }) => {
        return editor.isActive('table');
      }}
    >
      <ButtonGroup size="sm" isAttached bg="brand.darkGray" rounded="md">
        <Table showAddButton={false} />
      </ButtonGroup>
    </BubbleMenu>
  );
};
