import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ModalContent } from '@chakra-ui/react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';

import { uniqid } from 'app/assets/js/tsutil';

import { useFormState } from '@bq/components/form-state';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { Campaign } from '../../schemas';
import { CampaignFormData, ICampaignFormCtx } from './types';

const CampaignFormCtx = createContext<ICampaignFormCtx | null>(null);

export const CampaignFormWrapper = (
  props: PropsWithChildren<CampaignFormData>
) => {
  const methods = useForm<WithChange<Campaign>>({
    defaultValues: makeDefaults(props),
  });

  const {
    preventClose: { setState },
  } = useModalInstance();
  const isDirty = useMemo(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );
  const [formState, setFormState] = useFormState();
  useEffect(() => {
    setState(isDirty);
  }, [isDirty, setState]);

  const { handleSubmit } = methods;
  const { closeWithCallback } = useModalInstance();

  return (
    <CampaignFormCtx.Provider
      value={{ formState, setFormState, mode: props.mode }}
    >
      <FormProvider {...methods}>
        <ModalContent
          as="form"
          onSubmit={handleSubmit(
            (data) => {
              if (props.mode === 'create') {
                closeWithCallback<Campaign>({ ...data, ID: data.ID ?? uniqid() }, true);
              }
              if (props.mode === 'edit') {
                closeWithCallback<Campaign>(data, true);
              }
              setFormState('saved');
            },
            (a) => {
              console.log('error', a);
              /** Set form state ERROR */
              setFormState('error');
            }
          )}
        >
          {props.children}
        </ModalContent>
      </FormProvider>
    </CampaignFormCtx.Provider>
  );
};

export const useCampaignFormWrapper = () => {
  const ctx = useContext(CampaignFormCtx);
  if (!ctx) {
    throw Error('Missing Campaign Form Wrapper');
  }

  return ctx;
};

function makeDefaults(
  campaign: CampaignFormData
): DeepPartial<WithChange<Campaign>> {
  if (campaign.mode === 'edit') {
    return campaign.data as DeepPartial<Campaign>;
  }

  return {};
}
