import { CallCurrentState } from '../../store/calls-zustand';
import { pauseMe, unpauseMe } from '../Api/pause';
import { callFormEvents, editCallForm } from '../CallForm';
import { CallFormSubmit } from '../CallForm/types';
import { dialerStore, getCurrentPause } from './store/pbx';
import { CompulsoryCallFormPreferences } from './types';

/**
 * Open the call form after call end, if so configured.
 *
 * Matches conditions to determine if form should be opened, like
 * if call form is compulsory, call direction, call answered.
 *
 * Also pauses the user while the form is filled out, if configured to do so.
 */
export function maybeOpenCallForm(
  event: 'answer' | 'hangup',
  call: CallCurrentState,
  formPreferences: CompulsoryCallFormPreferences
): void {
  if (!formPreferences.isCompulsory) {
    return;
  }

  if (event === 'answer' && formPreferences.trigger !== 'answer') {
    return;
  }

  const directionMatches =
    formPreferences.direction === 'both' ||
    formPreferences.direction === call.direction;

  if (call.answered && directionMatches && !call.callFormFilled) {
    const currentPause = getCurrentPause(dialerStore.getState());

    console.log('Openning call form: ', call);
    editCallForm(call, true);

    // Pause the user while they fill out the form, unless already paused
    if (formPreferences.pause && !currentPause) {
      pauseWhileFillingOutForm(call.callId, formPreferences.pause);
    }
  }
}

function pauseWhileFillingOutForm(callId: string, pauseType: string): void {
  // Unpause the user after the form is submitted
  const handleSubmit = (submitted: CallFormSubmit) => {
    if (submitted.callId === callId) {
      console.log('Form submitted, unpausing: ', callId);
      callFormEvents.off('submit', handleSubmit);
      unpauseMe();
    }
  };
  callFormEvents.on('submit', handleSubmit);

  console.log('Pausing user while they fill out the form...');
  pauseMe(pauseType);
}
