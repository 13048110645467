import { useCallback } from 'react';
import { Box, BoxProps, Button } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';

import { fixedChakraFactory } from '../fixed-chakra-factory';
import { useOverviewsContext } from './OverviewsContext';

export type TreeLinkVariant = 'button' | 'list-item';

interface Props extends BoxProps {
  path: string;
  slug: string;
  title: string;
  variant?: TreeLinkVariant;
}

export const TreeLink = ({
  slug,
  title,
  path,
  variant = 'button',
  ...boxProps
}: Props) => {
  const { selectedOverview, mode, getQueryParams } = useOverviewsContext();
  const pathname = `/${path}/${slug}`;
  const navigate = useNavigate();

  const getParamsCallback = useCallback(
    (e: MouseEvent) => {
      if (e.ctrlKey || e.metaKey || e.shiftKey) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      const queryParams = getQueryParams?.();
      const search = queryParams ? queryParams.toString() : '';

      navigate({ pathname, search });
    },
    [getQueryParams, navigate, pathname]
  );

  if (mode === 'state') {
    return (
      <TreeLinkInner
        as={Button}
        isActive={selectedOverview === slug}
        variant={variant}
        {...boxProps}
      >
        {title}
      </TreeLinkInner>
    );
  }

  return (
    <TreeLinkInner
      as={Link}
      isActive={selectedOverview === slug}
      variant={variant}
      onClick={getParamsCallback}
      to={pathname}
      {...boxProps}
    >
      {title}
    </TreeLinkInner>
  );
};

interface LinkItemProps {
  isActive?: boolean;
  variant?: TreeLinkVariant;
}

export const TreeLinkInner = fixedChakraFactory<typeof Box, LinkItemProps>(
  Box,
  {
    label: 'TreeLinkInner',
    baseStyle({ isActive, variant }) {
      const color = isActive ? 'brand.200' : 'white';

      if (variant === 'list-item') {
        return {
          color,
          display: 'list-item',
          width: 'full',
          fontSize: { base: 'lg', md: 'sm' },
          fontWeight: '400',
          _hover: { color: 'brand.300', textDecoration: 'none' },
          listStyle: 'disc',
          textAlign: 'left',
        };
      }

      return {
        color,
        display: 'flex',
        justifyContent: 'start',
        bg: 'transparent',
        fontWeight: 'semibold',
        fontSize: { base: 'lg', md: 'sm' },
        _hover: { color: 'brand.100', textDecoration: 'none' },
        width: 'full',
        borderRadius: 0,
        textAlign: 'left',
      };
    },
    shouldForwardProp(prop: string) {
      return prop !== 'variant' && prop !== 'isActive';
    },
  }
);
