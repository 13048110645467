import { ReactElement } from 'react';
import {
  Button,
  chakra,
  Popover,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { ColorPicker } from 'BootQuery/Assets/components/ColorPicker';

interface Props {
  color: string;
  onChange: (color: string) => void;
}

const ColorButton = chakra(Button, {
  baseStyle: {
    padding: 0,
    flex: '1 0 auto',
    width: '8',
    height: '8',
    minWidth: 0,
    transition: 'all 0.3s',
    transform: 'scale(0.9)',
  },
});

export const ColorInput = ({ color, onChange }: Props): ReactElement => (
  <Popover>
    <PopoverTrigger>
      <ColorButton
        _hover={{ transform: 'scale(1.0)', backgroundColor: color }}
        backgroundColor={color}
      />
    </PopoverTrigger>
    <FixedPopoverContent width="auto" p={0}>
      <PopoverArrow />
      <PopoverBody p={0}>
        <ColorPicker border="none" color={color} onChange={onChange} />
      </PopoverBody>
    </FixedPopoverContent>
  </Popover>
);
