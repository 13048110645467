export function printUrl(url: string) {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  iframe?.contentWindow?.addEventListener('afterprint', () => {
    document.body.removeChild(iframe);
  });
  iframe?.contentWindow?.addEventListener('load', () => {
    iframe?.contentWindow?.print();
  });
}
