import { FormEvent, ReactElement, useCallback } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@bq/components/FormActions';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useModalInstance } from '@bq/components/UseModal';

import { BlacklistEditor } from './BlacklistEditor';
import { BlacklistItem } from './types';
import { useBlacklistItems } from './use-blacklist';
import { useBlacklistCRUD } from './use-blacklist.crud';

export const BlacklistEditModal = (): ReactElement => {
  const { data: items } = useBlacklistItems();

  if (!items) {
    return (
      <ModalContent>
        <LoadingPage />
      </ModalContent>
    );
  }

  return <BlacklistModalContent initial={items} />;
};

interface ContentProps {
  initial: BlacklistItem[];
}

const BlacklistModalContent = ({ initial }: ContentProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { closeWithNoCallback } = useModalInstance();

  const { items, modify, saveChanges, modifications } =
    useBlacklistCRUD(initial);

  const submit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      saveChanges();
      // setTimeout is used to wait a render cycle
      // for the useEffects to update the DOM value before closing
      setTimeout(closeWithNoCallback, 0);
    },
    [saveChanges, closeWithNoCallback]
  );
  const cancel = useCallback(() => {
    modify.clearAll();
    closeWithNoCallback();
  }, [modify, closeWithNoCallback]);

  return (
    <ModalContent
      onSubmit={(ev: FormEvent<HTMLFormElement>) => submit(ev)}
      as="form"
    >
      <ModalHeader>{t('Telephony:edit_blacklist')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <BlacklistEditor
          value={items}
          modify={modify}
          modifications={modifications}
        />
      </ModalBody>
      <ModalFooter>
        <FormActions onCancel={cancel} />
      </ModalFooter>
    </ModalContent>
  );
};
