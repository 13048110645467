import { MouseEventHandler } from 'react';
import { SortState, SortValue } from './types';

export const useSortingHeaders = (
  sortState: SortState | undefined,
  sort: string | undefined | null
): {
  onClick: MouseEventHandler;
  sortingElement: SortValue | undefined;
  sortingIndex: number | undefined;
} => {
  const elem = sortState?.value.find((item) => item.sortBy === sort);
  const elemIndex = sortState?.value.findIndex((item) => item.sortBy === sort);
  const active = !!elem;
  let array: SortValue[] = [];
  const onClick: MouseEventHandler = (e): void => {
    const val: SortValue = { sortBy: sort ?? '', direction: 'asc' };
    if (elem?.direction === 'asc') { val.direction = 'desc'; }
    if (elem?.direction === 'desc' || !active) { val.direction = 'asc'; }
    array = [val];
    if (e.ctrlKey) {
      if (sortState?.value) {
        array = [...sortState.value, val];
        if (elemIndex !== undefined && elemIndex !== -1) {
          array = [...sortState.value];
          array[elemIndex] = val;
        }
      }
    }
    sortState?.onChange(() => array);
  };

  return { onClick, sortingElement: elem, sortingIndex: elemIndex };
};
