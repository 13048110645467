import Module from 'BootQuery/Assets/js/module';
import { renderReact } from 'BootQuery/Assets/js/render-react';

import { ReactRoutes } from './react-routes';

import { Settings } from '../components/Settings/Settings';

export default class Events extends Module {
  init(data) {
    super.init(data);
  }

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  static matchReactRoute = '/products/';

  activateElements(target, data) {
    this.activateSettings(target);
  }

  activateSettings(target) {
    const $tgt = target.findElement('#product-settings-root');
    if ($tgt.length > 0) {
      this.renderSettings($tgt);
    }
  }

  renderSettings($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, Settings);
    }, 0);
  }
}
