import { ReactElement } from 'react';

import { useFormDefinition } from '@bq/components/FormEditor';

import { CallFormProvider } from '../CallForm/CallFormProvider';

/**
 * Wrapper for making the call form available anywhere (since Dialer is global)
 */
export const GlobalCallForm = (): ReactElement => {
  const customFields = useFormDefinition('Telephony.callForm');
  if (!customFields) {
    return <></>;
  }

  return <CallFormProvider customFields={customFields} />;
};
