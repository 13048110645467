import { ReactElement } from 'react';

import { Call } from '../../types';
import { CallStatus } from '../CallCard/CallStatus';

export const FormModalHeader = (call: Call): ReactElement => {
  return (
    <CallStatus
      finished={call.endAt !== null}
      direction={call.direction}
      answered={call.answered}
      verbose={true}
    />
  );
};
