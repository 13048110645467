import LRU from 'lru-cache';

const contactCache = new LRU({ max: 1000 });
const resolvingRequests: Record<string, Promise<ContactInfo|null>> = {};

const CLEANUP_NUMBER_REGEX = /[^+0-9]/g;

export interface ContactInfo {
    displayName: string;
    link: string|null;
    icon: string|null;
}

interface NumberContactInfoProvider {
    getContactInfo: (phoneNumber: string) => Promise<ContactInfo|null>;
}

export class NumberContactInfoResolver {
  providers(): NumberContactInfoProvider[] {
    return window.BootQuery.getProviders<NumberContactInfoProvider>('numberContactInfo');
  }

  async getContactInfo(number: string): Promise<ContactInfo|null> {
    const normalizedNumber = number.replace(CLEANUP_NUMBER_REGEX, '');

    if (contactCache.has(normalizedNumber)) {
      return contactCache.get(normalizedNumber) as ContactInfo|null;
    }

    if (normalizedNumber in resolvingRequests) {
      return resolvingRequests[normalizedNumber];
    }

    const providers = this.providers();
    if (providers.length === 0) {
      console.warn('Tried to fetch contact info, but no providers registered');

      return null;
    }

    resolvingRequests[normalizedNumber] = this.doFetchContactInfo(providers, normalizedNumber);
    const numberInfo = await resolvingRequests[normalizedNumber];
    contactCache.set(normalizedNumber, numberInfo);
    delete resolvingRequests[normalizedNumber];

    return numberInfo;
  }

  manuallyCacheInfoForNumber(number: string, contactInfo: ContactInfo|null): void {
    const normalizedNumber = number.replace(CLEANUP_NUMBER_REGEX, '');
    contactCache.set(normalizedNumber, contactInfo);
  }

  invalidateInfoForNumber(number: string): void {
    const normalizedNumber = number.replace(CLEANUP_NUMBER_REGEX, '');
    contactCache.del(normalizedNumber);
  }

  async doFetchContactInfo(
    providers: NumberContactInfoProvider[],
    number: string
  ): Promise<ContactInfo|null> {
    const fetches = providers.map((provider) => provider
      .getContactInfo(number)
      .catch((err) => {
        console.error('Ignoring failed contact info provider: ', err);

        return null;
      }));

    // Parallel requests to all available providers and filter out those that returned no value
    const possibleResults = (await Promise.all(fetches)).filter((result) => !!result);

    // Return first result, if exists
    return possibleResults[0] || null;
  }
}
