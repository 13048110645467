import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { CaseType } from '../../schemas';

export const getCaseTypes = async (params?: GetListingParams) => {
  const { data } = await Api.get<ListingResponse<CaseType>>(
    '/api/sales/caseTypes',
    { params }
  );

  return data;
};
