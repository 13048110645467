import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterReminderDate = makeDateFilter({
  name: () => i18n.t('Ticketing:filter.reminder_date'),
  toFilter(filter, filterTypes) {
    const expr = makeDateToFilter('scheduledTask.timestamp')(
      filter,
      filterTypes
    );

    if (!expr) {
      return null;
    }

    return {
      'reminders:$any': expr,
    };
  },
});
