import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams, OBJWithID } from 'BootQuery/Assets/js/globalTypes';

import { PostSalesCase, SalesCase, SimplePostSalesCase } from '../../schemas';

export const createOneCase = async <T extends OBJWithID = SalesCase>(
  campaignID: number | string,
  data: PostSalesCase | SimplePostSalesCase,
  params?: GetOneParams
) => {
  const { data: newData } = await Api.post<T>(
    `/api/sales/campaigns/${campaignID}/cases`,
    data,
    { params }
  );

  return newData;
};
