module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<span class=\"fa fa-plus\"></span>\n						"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.add_mail_account",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":6},"end":{"line":8,"column":37}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<span class=\"fa fa-edit\"></span>\n						"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.edit_mail_account",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":11,"column":38}}}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "								<select name=\"incomingProto\" id=\"incomingProto\" class=\"pickle\">\n									<option value=\"IMAP\">IMAP</option>\n									<option value=\"POP3\">POP3</option>\n								</select>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<input type=\"text\" class=\"form-control-plaintext\" readonly value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"incomingProto") : depth0), depth0))
    + "\">\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"authMethod\">\n							Auth method:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<select name=\"authMethod\" id=\"authMethod\" class=\"pickle\">\n								<option "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"authMethod") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":16},"end":{"line":61,"column":57}}})) != null ? stack1 : "")
    + " value=\"\">&lt;Basic - Password&gt;</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"authProviders") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":64,"column":17}}})) != null ? stack1 : "")
    + "							</select>\n						</div>\n					</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "selected";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "									<option "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":17},"end":{"line":63,"column":48}}})) != null ? stack1 : "")
    + " value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "</option>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<input class=\"form-control\" type=\"password\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"password") : depth0), depth0))
    + "\" name=\"password\" id=\"password\">\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<button type=\"button\" class=\"btn btn-primary change-pass-btn\" data-toggle=\"collapse\" data-target=\"#mail-password-change-collapse\" id=\"password\">\n									<span class=\"fa fa-lock\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.change_password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":79,"column":42},"end":{"line":79,"column":73}}}))
    + "\n								</button>\n\n								<div class=\"collapse\" id=\"mail-password-change-collapse\">\n									<div class=\"card card-body border-primary\">\n										<input type=\"hidden\" name=\"passwordChanged\" value=\"false\">\n										<div class=\"form-group\">\n											<div class=\"row\">\n												<label class=\"col-12 col-form-label col-md-4 control-label text-left text-md-right\" for=\"newPassword\">\n													New password:\n												</label>\n												<div class=\"col-12 col-md-8\">\n													<input class=\"form-control\" type=\"password\" name=\"newPassword\" id=\"newPassword\" disabled>\n												</div>\n											</div>\n										</div>\n									</div>\n								</div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "checked";
},"20":function(container,depth0,helpers,partials,data) {
    return " checked";
},"22":function(container,depth0,helpers,partials,data) {
    return " active";
},"24":function(container,depth0,helpers,partials,data) {
    return " show active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal\" tabindex=\"-1\" role=\"dialog\">\n	<div class=\"modal-dialog modal-lg\" role=\"document\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<h5 class=\"modal-title\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewAccount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":5},"end":{"line":12,"column":12}}})) != null ? stack1 : "")
    + "				</h5>\n				<button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n					<span aria-hidden=\"true\">&times;</span>\n				</button>\n			</div>\n			<div class=\"modal-body\">\n				<div class=\"col-12\">\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingProto\">\n							Type:\n						</label>\n						<div class=\"col-12 col-md-9\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewAccount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":25,"column":7},"end":{"line":32,"column":14}}})) != null ? stack1 : "")
    + "						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"email\">\n							Email:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"email\" id=\"email\" name=\"email\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"username\">\n							Username:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"username\" name=\"username\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"authProviders") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":5},"end":{"line":68,"column":12}}})) != null ? stack1 : "")
    + "\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"password\">\n							Password:\n						</label>\n						<div class=\"col-12 col-md-9\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNewAccount") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":75,"column":7},"end":{"line":97,"column":14}}})) != null ? stack1 : "")
    + "						</div>\n					</div>\n\n					<div class=\"form-group row\" hidden>\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"auth-provider-login\">\n							Account:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<select value=\"oauthAccountID\" name=\"oauthAccountID\" id=\"oauthAccountID\" class=\"pickle\"></select>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingServer\">\n							Incoming server:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"incomingServer\" name=\"incomingServer\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"incomingServer") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingPort\">\n							Incoming port:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"number\" step=\"1\" id=\"incomingPort\" name=\"incomingPort\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"incomingPort") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"incomingSSL\">\n							Incoming SSL:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"incomingSSL\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"incomingSSL\" name=\"incomingSSL\" value=\"true\" class=\"form-control\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"incomingSSL") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":130},"end":{"line":135,"column":163}}})) != null ? stack1 : "")
    + ">\n								<label class=\"custom-control-label\" for=\"incomingSSL\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingServer\">\n							Outgoing server:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"outgoingServer\" name=\"outgoingServer\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"outgoingServer") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingPort\">\n							Outgoing port:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"number\" step=\"1\" id=\"outgoingPort\" name=\"outgoingPort\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"outgoingPort") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingSSL\">\n							Outgoing SSL:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"outgoingSSL\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"outgoingSSL\" name=\"outgoingSSL\" value=\"true\" class=\"form-control\"\n								 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"outgoingSSL") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":167,"column":9},"end":{"line":167,"column":43}}})) != null ? stack1 : "")
    + "> <label class=\"custom-control-label\" for=\"outgoingSSL\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"outgoingAuth\">\n							Outgoing auth:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"outgoingAuth\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"outgoingAuth\" name=\"outgoingAuth\" value=\"true\" class=\"form-control\"\n								 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"outgoingAuth") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":9},"end":{"line":180,"column":44}}})) != null ? stack1 : "")
    + "> <label class=\"custom-control-label\" for=\"outgoingAuth\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n					<hr>\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-form-label control-label\" for=\"signature-edit\">\n							Signature:\n						</label>\n						<div class=\"col-12\">\n							<div class=\"row\">\n								<div class=\"col-3\">\n									<div class=\"nav flex-column nav-pills\">\n										<a class=\"nav-link"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRawSignature") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":28},"end":{"line":193,"column":73}}})) != null ? stack1 : "")
    + "\" data-toggle=\"pill\" href=\"#signature-edit-editor\" role=\"tab\">Editor</a>\n										<a class=\"nav-link"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRawSignature") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":194,"column":28},"end":{"line":194,"column":65}}})) != null ? stack1 : "")
    + "\" data-toggle=\"pill\" href=\"#signature-edit-html\" role=\"tab\">HTML</a>\n									</div>\n								</div>\n								<div class=\"col-9\">\n									<div class=\"tab-content\">\n										<div class=\"tab-pane fade"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRawSignature") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":35},"end":{"line":199,"column":85}}})) != null ? stack1 : "")
    + "\" id=\"signature-edit-editor\">\n											<!-- Wrapped in an empty element so it doesn't fly out of the window -->\n											<div>\n												<div class=\"signature-edit\"></div>\n											</div>\n										</div>\n										<div class=\"tab-pane fade"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"useRawSignature") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":35},"end":{"line":205,"column":77}}})) != null ? stack1 : "")
    + "\" id=\"signature-edit-html\">\n											<textarea class=\"form-control\" name=\"signatureRawHtml\">"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"signatureRawHtml") : depth0), depth0))
    + "</textarea>\n										</div>\n									</div>\n								</div>\n							</div>\n						</div>\n					</div>\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"displayName\">\n							Display name:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"text\" id=\"displayName\" name=\"displayName\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"displayName") : depth0), depth0))
    + "\" class=\"form-control\">\n						</div>\n					</div>\n					<div class=\"form-group row\">\n						<label class=\"col-12 col-form-label col-md-3 col-form-label control-label text-left text-md-right\" for=\"showUserNameInFrom\">\n							Show user's name in from field:\n						</label>\n						<div class=\"col-12 col-md-9\">\n							<input type=\"hidden\" name=\"showUserNameInFrom\" value=\"false\">\n							<div class=\"custom-control custom-checkbox mt-2\">\n								<input type=\"checkbox\" class=\"custom-control-input\" id=\"showUserNameInFrom\" name=\"showUserNameInFrom\" value=\"true\" class=\"form-control\"\n								 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showUserNameInFrom") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":229,"column":9},"end":{"line":229,"column":50}}})) != null ? stack1 : "")
    + "> <label class=\"custom-control-label\" for=\"showUserNameInFrom\">&nbsp;</label>\n							</div>\n						</div>\n					</div>\n				</div>\n			</div>\n			<div class=\"modal-footer\">\n				<button type=\"button\" class=\"btn btn-link text-red\" data-dismiss=\"modal\">"
    + alias3(lookupProperty(helpers,"tr").call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":236,"column":77},"end":{"line":236,"column":99}}}))
    + "</button>\n				<button type=\"button\" class=\"btn btn-success save-btn\">\n					<span class=\"fa fa-check\"></span> "
    + alias3(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":238,"column":39},"end":{"line":238,"column":59}}}))
    + "\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})