import { getEmailForGroups, MailInfo } from '../../../Api/mails/mails';
import { FilterOption } from './MailSelect';

export const loadOptions = async (search: string) => {
  const { data } = await getEmailForGroups({
    filters: {
      $search: search,
    },
    fields: ['ID', 'email'],
  });

  return toFilterOption(data);
};

const toFilterOption = (data: MailInfo[]): FilterOption[] => {
  return data.map((item) => ({
    label: item.email,
    value: item.ID,
  }));
};
