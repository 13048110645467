import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { debounce } from 'lodash-es';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { searchPrograms } from '../../../API/Program/searchPrograms';
import { ItemCreatableProgram, PartialItem } from '../../../types';
import { FieldProps } from '../../types';

export const ProgramField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<PartialItem>();
  const {
    field: { value, onChange },
  } = useController<PartialItem, 'program'>({
    control,
    name: 'program',
  });
  const type = watch('productType');

  const debouncedSearch = useMemo(() => {
    return debounce(
      (search: string) => searchPrograms({ search, itemType: type }),
      300,
      { leading: true }
    );
  }, [type]);

  useChangeEffect(
    type,
    () => {
      onChange(null);
    },
    [onChange]
  );

  return (
    <FormControl isInvalid={!!errors.program} {...formControlProps}>
      <FormLabel htmlFor="program">{t('Products:program')}</FormLabel>
      <Select<number | string>
        isCreatable
        creatableProps={{
          allowCreateWhileLoading: false,
        }}
        isClearable
        size={['md', 'md', 'sm']}
        value={parseValue(value)}
        options={async (search) => {
          const results = (await debouncedSearch(search)) ?? [];

          return results.map((result) => ({
            value: result.ID,
            label: result.name,
          }));
        }}
        onChange={(newValue, actionMeta) => {
          if (actionMeta.action === 'create-option') {
            onChange({ $new: actionMeta.option.value });
          }
          if (actionMeta.action === 'select-option' && newValue) {
            onChange({ ID: newValue.value, name: newValue.label });
          }
          if (actionMeta.action === 'clear') {
            onChange(null);
          }
        }}
      />
    </FormControl>
  );
};

export const parseValue = (
  value?: ItemCreatableProgram
): SelectOption<number | string> | null => {
  if (value) {
    if ('$new' in value) {
      return { label: value.$new, value: value.$new, __isNew__: true };
    }

    return { label: value.name, value: value.ID };
  }

  return null;
};
