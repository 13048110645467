import { StackProps, useColorModeValue, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface LayoutHeaderProps extends StackProps {
  children: ReactNode;
}
export const Layout = ({
  children,
  ...styleProps
}: LayoutHeaderProps) => {
  const color = useColorModeValue('#3C485C', '#F5F5F5');

  return (
    <VStack color={color} maxH='fullNoNavbar' height="fullNoNavbar" {...styleProps}>
      {children}
    </VStack>
  );
};
