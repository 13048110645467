import { memo, useMemo } from 'react';
import {
  Button,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { GenericBulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  action: GenericBulkAction<T>;
}

export const MobileBulkActionConfirmButton = <T extends OBJ>({
  action,
}: Props<T>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selection, clearSelected } = useTableSelection<T>();
  const { t } = useTranslation('');

  const parsedSelection = useMemo(() => {
    return action.parseSelection !== undefined
      ? action.parseSelection(selection)
      : selection;
  }, [action, selection]);

  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <MenuItem
        aria-label={t('global:confirm')}
        key={action.name}
        onClick={onOpen}
        isDisabled={!action.isAllowed(parsedSelection)}
        icon={action.icon}
      >
        {action.name}
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${t('global:confirm_action')} "${
            action.name
          }"`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{`${t('global:will_affect')} ${parsedSelection.length} ${t(
            'items'
          )}`}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
              leftIcon={<FaTimes />}
            >
              {t('global:cancel')}
            </Button>
            <Button
              leftIcon={<FaCheck />}
              colorScheme="green"
              onClick={() => {
                if (action.isAllowed(parsedSelection)) {
                  action.handler(parsedSelection, selection).then(() => {
                    if (action.onActionEnd !== undefined) {
                      action.onActionEnd(selection);
                      clearSelected();
                    }
                  });
                }
              }}
            >
              {t('global:submit')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Tooltip>
  );
};
const MobileBulkActionConfirmButtonMemo = memo(
  MobileBulkActionConfirmButton
) as typeof MobileBulkActionConfirmButton;

export { MobileBulkActionConfirmButtonMemo as BulkActionConfirmButton };
