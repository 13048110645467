import { ReactElement, useMemo } from 'react';
import { Stack } from '@chakra-ui/react';

import { getFieldTypes } from './field-types';
import { StaticListProvider } from './field-types/ListField/StaticListFieldContext';
import { StaticGeneratedFormField } from './StaticGeneratedFormField';
import { FieldType, FieldValue } from './types';

interface GeneratedFormProps {
  prefix?: string | null;
  fieldTypes?: FieldType[];
  fields: FieldValue[];
}

export const StaticGeneratedForm = ({
  prefix = null,
  fieldTypes: suppliedFieldTypes,
  fields,
}: GeneratedFormProps): ReactElement => {
  const fieldTypes = useMemo(
    () => suppliedFieldTypes ?? getFieldTypes(),
    [suppliedFieldTypes]
  );

  return (
    <StaticListProvider prefix={prefix} fieldTypes={fieldTypes}>
      <Stack>
        {fields.map((field) => (
          <StaticGeneratedFormField
            key={field.id}
            prefix={prefix}
            field={field}
            fieldTypes={fieldTypes}
          />
        ))}
      </Stack>
    </StaticListProvider>
  );
};
