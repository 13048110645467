import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Select } from '@bq/components/SelectWrappers';

import { listDatasets } from '../api/datasets';
import { CaseTypesForm, DatasetOption } from '../types';

export const DatasetField = (): ReactElement => {
  const { control } = useFormContext<CaseTypesForm>();
  const { field } = useController({ control, name: 'dataset' });

  return (
    <Select
      value={field.value}
      onChange={field.onChange}
      options={getDatasetOptions}
    />
  );
};

async function getDatasetOptions(search: string): Promise<DatasetOption[]> {
  const datasets = await listDatasets({ filters: { $search: search } });

  return datasets.map((dataset) => ({
    value: dataset.ID,
    label: dataset.name,
  }));
}
