import { TranslationCollection } from './types';

const globalLocaleRequires = require.context('app/assets/locales/', true, /[^/]+\.json$/);
const moduleLocaleRequires = require.context('app/Modules/', true, /\.\/[^/]+\/Assets\/locales\/.+\.json$/);

const globalTranslations = globalLocaleRequires.keys()
  .reduce<TranslationCollection[]>((prev, path) => {
    // Path: ./{locale}.json
    const pathParts = path.split('/');
    const locale = pathParts[1].replace(/\.json$/, '');
    const value = globalLocaleRequires(path);

    const col: TranslationCollection = { locale, value, module: 'global' };

    return [...prev, col];
  }, []);

const moduleTranslations = moduleLocaleRequires.keys()
  .reduce<TranslationCollection[]>((prev, path) => {
    // Path: ./{Module}/Assets/locales/{locale}.json
    const pathParts = path.split('/');
    const module = pathParts[1];
    const locale = pathParts[4].replace(/\.json$/, '');
    const value = moduleLocaleRequires(path);

    const col: TranslationCollection = { locale, value, module };

    return [...prev, col];
  }, []);

export const translations: TranslationCollection[] = [
  ...globalTranslations,
  ...moduleTranslations,
];
export const rawLocale = false;
