import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { PreviewImportConfig, PreviewRow } from '../types';

export async function getImportPreview(
  config: PreviewImportConfig
): Promise<PreviewRow[]> {
  const { data } = await Api.post<PreviewRow[]>(
    '/api/datasets/importPreview',
    config
  );

  return data;
}

export function useImportPreview(
  config: PreviewImportConfig,
  queryOptions: Partial<UseQueryOptions<PreviewRow[]>> = {}
): UseQueryResult<PreviewRow[]> {
  return useQuery({
    queryKey: ['Datasets.importPreview', config],
    queryFn: () => getImportPreview(config),
    ...queryOptions,
  });
}
