import { ReactElement } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { AutoselectCompanyAsClient } from './AutoselectCompanyAsClient';
import { EditOverviews } from './EditOverviews';
import { TicketGroups } from './TicketGroups/TicketGroups';
import { TicketPriorities } from './TicketPriorities/TicketPriorities';
import { TicketingSettingsProvider } from './TicketSettingsContext';
import { TicketStateSettings } from './TicketStates/TicketStateSettings';
import { TicketTypes } from './TicketTypes/TicketTypes';
import { WorkFormEdit } from './TicketWorks';

export const Settings = (): ReactElement => {
  return (
    <TicketingSettingsProvider>
      <SettingsContent />
    </TicketingSettingsProvider>
  );
};

const SettingsContent = (): ReactElement => {
  const { t } = useTranslation('Ticketing');

  return (
    <>
      <VStack alignItems="start" px={4}>
        <AutoselectCompanyAsClient />
        <EditOverviews />
      </VStack>
      <Divider mb={0} />
      <Accordion w="full" p={0} allowMultiple>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Ticketing:groups')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <TicketGroups />
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              {t('Ticketing:ticket_types')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <TicketTypes />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              {t('Ticketing:ticket_priorities')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pr={0}>
            <TicketPriorities />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              {t('Ticketing:ticket_states')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pr={0}>
            <TicketStateSettings />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              {t('Ticketing:works')}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pr={0}>
            <WorkFormEdit />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
