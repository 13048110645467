module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n    <meta charset=\"UTF-8\">\n    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n    <title>"
    + alias1(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + " API Docs</title>\n\n	<link id=\"favicon\" rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/logo_ast.png\">\n\n    <script async src=\""
    + alias1(lookupProperty(helpers,"resolveAssetName").call(depth0 != null ? depth0 : (container.nullContext || {}),"apiDoc.js","/dist/apiDoc.js",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":10,"column":23},"end":{"line":10,"column":73}}}))
    + "\"></script>\n</head>\n<body>\n    <div id=\"api-docs-container\">\n        ...\n    </div>\n</body>\n</html>";
},"useData":true})