import {
  Box,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';
import { useModalInstance } from '@bq/components/UseModal';
import { CompanyDepartmentFormFields } from 'app/Modules/Phonebook/Assets/components/CompanyDepartmentForm/CompanyDepartmentFormFields';

import {
  CompanyDepartmentFormWrapper,
  useCompanyDepartmentFormWrapper,
} from './CompanyDepartmentFormWrapper';
import { CompanyDepartmentModalContentProps } from './types';
import { useLabelForCompanyDepartmentModal } from './utils';

export const CompanyDepartmentModalContent = (
  props: CompanyDepartmentModalContentProps
) => {
  const label = useLabelForCompanyDepartmentModal(props.mode);
  const { formState } = useCompanyDepartmentFormWrapper();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <CompanyDepartmentFormWrapper {...props}>
      <ModalHeader>
        <HStack>
          <Box>
            <FaPlus />
          </Box>
          <Box>{label}</Box>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <CompanyDepartmentFormFields />
      </ModalBody>
      <ModalFooter>
        <FormActions
          state={formState}
          onCancel={() => {
            closeWithNoCallback();
          }}
        />
      </ModalFooter>
    </CompanyDepartmentFormWrapper>
  );
};
