import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DndContext,
  Modifiers,
  MouseSensor,
  pointerWithin,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import { defaultCloneHandler } from './default-clone-item';
import { DndContainersContext } from './DndContainersContext';
import { useDragHandlers } from './handlers';
import {
  BaseItem,
  CloneHandler,
  DraggedItemInfo,
  OnDragHandler,
  OnDropHandler,
} from './types';

interface Props<C> {
  items: BaseItem<C>[];
  onChange: Dispatch<SetStateAction<BaseItem<C>[]>>;
  cloneHandler?: CloneHandler<C>;
  modifiers?: Modifiers;
  onDrag?: OnDragHandler<C>;
  onDrop?: OnDropHandler<C>;
}

export const DndContainersProvider = <C, >({
  children,
  items,
  onChange,
  modifiers,
  cloneHandler: suppliedCloneHandler,
  onDrag,
  onDrop,
}: PropsWithChildren<Props<C>>) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 8 } })
  );
  const currentlyDragged = useRef<DraggedItemInfo<C> | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const cloneHandler = useCloneHandler(suppliedCloneHandler);
  const itemsRef = useRef<BaseItem<C>[]>(items);
  itemsRef.current = items;

  const handlers = useDragHandlers({
    setActiveId,
    currentlyDragged,
    onChange,
    cloneHandler,
    itemsRef,
    onDrag,
    onDrop,
  });

  const value = useMemo(
    () => ({ items, onChange, activeId }),
    [items, onChange, activeId]
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      modifiers={modifiers}
      {...handlers}
    >
      <DndContainersContext.Provider value={value}>
        {children}
      </DndContainersContext.Provider>
    </DndContext>
  );
};

function useCloneHandler<C>(
  suppliedHandler?: CloneHandler<C>
): CloneHandler<C> {
  return useMemo(
    () => suppliedHandler ?? (defaultCloneHandler as CloneHandler<C>),
    [suppliedHandler]
  );
}
