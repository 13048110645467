import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { SimpleFormSalesCase } from '../../schemas';
import { SubjectType } from '../../Settings/CaseTypes/types';
import { CasesBulkModalContent } from './CasesBulkModalContent';

export const useCasesBulkFormModal = () => {
  const { addModal } = useModal();
  const callCasesBulkFormModal = useCallback(
    (cb: (data: SimpleFormSalesCase) => void, subjectType?: SubjectType) => {
      addModal({
        callback: cb,
        children: (
          <CasesBulkModalContent mode="create" subjectType={subjectType} />
        ),
        componentProps: { modal: { scrollBehavior: 'inside' } },
      });
    },
    [addModal]
  );

  return callCasesBulkFormModal;
};
