import { ReactElement, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';

import { StaticFormContext } from '../StaticFormContext';
import { FieldValue } from '../types';
import { GenericStaticFormField } from './GenericStaticFormField';

export const DefaultStaticFieldComponent = ({
  id,
  settings,
}: FieldValue): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id);
  const displayValue = useFormattedValue(value);

  return <GenericStaticFormField value={displayValue} title={settings.name} />;
};

function useFormattedValue(value: unknown): string {
  const { t } = useTranslation('global');

  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
    case 'bigint':
      return value.toString();
    case 'boolean':
      return value ? t('global:yes') : t('global:no');
    default:
      return '';
  }
}
