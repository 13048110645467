module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form id=\"login-form\" class=\"form-horizontal\" action=\"/user/login\" method=\"post\">\n    <div class=\"form-group row\">\n        <label for=\"username\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"User:form.username",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":39}}}))
    + ":\n        </label>\n        <div class=\"col-12 col-md-8\">\n            <input id=\"username\" class=\"form-control\" type=\"text\" name=\"username\" placeholder=\"Korisničko ime\" autofocus />\n        </div>\n    </div>\n    <div class=\"form-group row normal-login-only\">\n        <label for=\"password\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"User:form.password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":39}}}))
    + ":\n        </label>\n        <div class=\"col-12 col-md-8\">\n            <input id=\"password\" class=\"form-control\" type=\"password\" name=\"password\" maxlength=\"255\" placeholder=\"Lozinka\" />\n        </div>\n    </div>\n    <div class=\"form-group row normal-login-only\">\n        <label for=\"stayLoggedIn\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"User:form.stayLoggedIn",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":43}}}))
    + ":\n        </label>\n        <div class=\"col-12 col-md-8\">\n            <div class=\"custom-control custom-checkbox\">\n                <input type=\"checkbox\" class=\"custom-control-input\" name=\"stayLoggedIn\" id=\"stayLoggedIn\">\n                <label class=\"custom-control-label\" for=\"stayLoggedIn\">&nbsp;</label>\n            </div>\n        </div>\n    </div>\n    <div class=\"form-group row\">\n        <div class=\"col-12 col-md-8 ml-auto\">\n            <button id=\"login-form-submit\" type=\"submit\" class=\"form-control btn btn-primary\">\n                "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"User:button.login",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":42}}}))
    + "\n            </button>\n        </div>\n    </div>\n    <div class=\"form-group row normal-login-only\">\n        <div class=\"col-12 col-md-8 ml-auto text-right\">\n            <a href=\"/user/requestRecovery\">\n                "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"User:form.password_recovery",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":52}}}))
    + "\n                <span class=\"fas fa-arrow-right\"></span>\n            </a>\n        </div>\n    </div>\n    <input type=\"hidden\" name=\"n\" value=\""
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"n") : stack1), depth0))
    + "\">\n</form>";
},"useData":true})