import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';

import { CaseState } from '../../schemas';
import { AddStateButton } from './AddStateButton';
import { StateItem } from './StateItem';
import { useStates } from './use-states';

export const CaseStateContent = () => {
  const { data: states } = useStates();
  if (!states) {
    return <LoadingPage />;
  }

  return <CaseStateSettings states={states.data} />;
};

interface Props {
  states: CaseState[];
}

export const CaseStateSettings = ({ states }: Props) => {
  const [modifiedStates, modify] = useSettingsCrud<CaseState>({
    parent: '#settings-form',
    inputName: 'sales[states]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: states,
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedStates.map((state) => (
          <StateItem key={state.ID} modify={modify} state={state} />
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <AddStateButton modify={modify} />
      </Flex>
    </>
  );
};
