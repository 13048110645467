import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';

import { DefinitionType, getCustomFormDefinition } from './api';
import { FieldValue, QueryOptions } from './types';

export function useFormDefinitionMultiple(
  names: string[],
  mode: DefinitionType = 'use',
  options: QueryOptions = {}
): { [name: string]: FieldValue[] } | null {
  const results = useQueries({
    queries: names.map((name) => ({
      queryKey: [`useCustomFormDefinition:${name}:${mode}`],
      queryFn: async () => getCustomFormDefinition(name, mode),
      options,
    })),
  });

  const loading = useMemo(
    () => results.some((result) => result.isLoading),
    [results]
  );

  const returnType = results.reduce(
    (all, current) => {
      if (current.data?.data) {
        return { ...all, [current.data.name]: current.data.data };
      }

      return all;
    },
    {} as { [name: string]: FieldValue[] }
  );

  // Ensure that all are loaded before trying to return results

  return loading || Object.keys(returnType).length <= 0 ? null : returnType;
}
