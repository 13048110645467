// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import otherTypes from 'mime/types/other';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import standardTypes from 'mime/types/standard';

import type { TypeMap } from 'mime';

export const mimeTypes: TypeMap = { ...standardTypes, ...otherTypes };
