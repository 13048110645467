import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

import { formatSoftphoneError } from './format-softphone-error';
import { useSoftphoneEvent } from './store/softphone-events';

/**
 * Catch softphone errors and show a toast with error info
 */
export function useSoftphoneErrorToast(): void {
  const showToast = useToast({
    position: 'top-right',
    status: 'error',
    isClosable: true,
    containerStyle: { marginTop: '60px' },
    duration: 10000,
  });

  useSoftphoneEvent(
    'error',
    useCallback(
      (err) => {
        showToast(formatSoftphoneError(err));
      },
      [showToast]
    )
  );
}
