import { Box, Grid, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PriceField } from './PriceField';

export const PriceFields = () => {
  const { t } = useTranslation('Products');

  return (
    <VStack w="full">
      <Grid gridTemplateColumns="1fr 3fr 3fr" w="full">
        <Box></Box>
        <Box fontWeight="bold">{t('Products:without_tax')}</Box>
        <Box fontWeight="bold">{t('Products:with_tax')}</Box>
        <PriceField
          formKey="purchasePrice"
          label={t('Products:purchase_price')}
        />
        <PriceField
          formKey="wholesalePrice"
          label={t('Products:wholesale_price')}
        />
        <PriceField formKey="retailPrice" label={t('Products:retail_price')} />
        <PriceField
          formKey="contractPrice"
          label={t('Products:contract_price')}
        />
      </Grid>
    </VStack>
  );
};
