import mime from 'mime';
import { AllowTypesSelectOptions, SplitMIME } from './types';
import { MATCH_MIME } from './mime-regex';

export const parseValueToSelectOptions = (
  staticOptions: AllowTypesSelectOptions[],
  value?: string | string[]
): AllowTypesSelectOptions[] => {
  if (!value) {
    return [];
  }
  const valueArr = Array.isArray(value) ? value : [value];

  const removedDuplicates = [...new Set(valueArr)];

  return removedDuplicates.reduce(
    (all: AllowTypesSelectOptions[], rawValue) => {
      const isValid = MATCH_MIME.test(rawValue);
      if (!isValid) {
        return all;
      }

      return [...all, parseSingularValue(staticOptions, rawValue)];
    },
    []
  );
};

export const parseSingularValue = (
  staticOptions: AllowTypesSelectOptions[],
  value: string
): AllowTypesSelectOptions => {
  const matchStaticOption = staticOptions.find((item) => item.value === value);
  if (matchStaticOption) {
    return matchStaticOption;
  }
  const splitMIME = value.split('/') as SplitMIME;
  const ext = mime.getExtension(value);

  const label = ext || splitMIME[1];

  return { value, label };
};
