import { ReactElement, useCallback, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaAddressBook } from 'react-icons/fa';

import { PhonebookFormsProvider } from 'app/Modules/Phonebook/Assets/components/ModalForms';
import { ContactSettingsModal } from './ContactSettingsModal';

export const ContactSettingsButton = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);
  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <PhonebookFormsProvider>
      <Button onClick={open}>
        <FaAddressBook />
        &nbsp;
        {t('Telephony:settings.contacts')}
      </Button>
      <ContactSettingsModal isOpen={isOpen} onClose={close} />
    </PhonebookFormsProvider>
  );
};
