import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CampaignFormFields } from './CampaignFormFields';
import { CampaignFormWrapper } from './CampaignFormWrapper';
import { FooterButtons } from './FooterButttons';
import { CampaignFormData } from './types';

export const CampaignModal = (props: CampaignFormData) => {
  const { t } = useTranslation('Sales');

  return (
    <CampaignFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Sales:create_campaign')
          : t('Sales:edit_campaign')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <CampaignFormFields mode={props.mode} />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </CampaignFormWrapper>
  );
};
