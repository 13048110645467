import { FilterTypes } from '@bq/components/FilterBar';

import { FilterStorage, UseFiltersResult } from './types';
import { useQueryFilters } from './use-query-filters';
import { useStateFilters } from './use-state-filters';

export function useFilters(
  filterTypes: FilterTypes,
  storage: FilterStorage
): UseFiltersResult {
  const stateFilters = useStateFilters();
  const queryFilters = useQueryFilters(filterTypes);

  return storage === 'query' ? queryFilters : stateFilters;
}
