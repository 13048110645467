import { useContext } from 'react';
import { DndContainersContext } from './DndContainersContext';
import { IDndContainersContext } from './types';

export function useDndContainers<C>(): IDndContainersContext<C> {
  const ctx = useContext(DndContainersContext);
  if (!ctx) {
    throw new Error('Missing DndContainersContext');
  }

  return ctx as IDndContainersContext<C>;
}
