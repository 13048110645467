import { createContext, PropsWithChildren, useContext } from 'react';
import { ModalContent } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useModalInstance } from '@bq/components/UseModal';
import { PersonFormData } from 'app/Modules/Phonebook/Assets/components/PersonForm';
import { handleNationalNumber } from 'app/Modules/Phonebook/Assets/components/utils';

import { patchPerson, postPerson } from './api';
import { PersonFormCtxI, PersonModalContentProps } from './types';

const PersonFormCtx = createContext<PersonFormCtxI | null>(null);

export const PersonFormWrapper = (
  props: PropsWithChildren<PersonModalContentProps>
) => {
  const methods = useForm({ defaultValues: props.data });

  const [formState, setFormState] = useFormState();

  return (
    <PersonFormCtx.Provider
      value={{ formState, mode: props.mode, setFormState }}
    >
      <FormProvider {...methods}>
        <PersonModalForm {...props} />
      </FormProvider>
    </PersonFormCtx.Provider>
  );
};

const PersonModalForm = (props: PropsWithChildren<PersonModalContentProps>) => {
  const { setFormState } = usePersonFormWrapper();
  const { closeWithCallback } = useModalInstance();
  const { handleSubmit } = useFormContext<PersonFormData>();

  const { mutateAsync } = useMutation({
    mutationFn: (data: PersonFormData) => {
      if (props.mode === 'create') {
        return postPerson({ data: handleNationalNumber(data) });
      }

      return patchPerson({
        data: handleNationalNumber(data),
        ID: props.personID,
      });
    },
  });

  return (
    <ModalContent
      as="form"
      data-ignore-form
      onSubmit={handleSubmit(
        async (data) => {
          setFormState('saving');
          const { data: newData } = await mutateAsync(data);
          setFormState('saved');
          closeWithCallback({ ...data, ...newData });
        },
        (e) => {
          console.log(e);
          setFormState('error');
        }
      )}
    >
      {props.children}
    </ModalContent>
  );
};

export const usePersonFormWrapper = () => {
  const ctx = useContext(PersonFormCtx);
  if (!ctx) {
    throw Error('Missing PersonFormWrapper');
  }

  return ctx;
};
