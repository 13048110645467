module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"previousHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"previousText") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":11,"column":8}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <pre>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"previousText") : depth0), depth0))
    + "</pre>\n        ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"currentHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n<hr>\n<div class=\"clickvox-quote-wrapper\">\n    On "
    + alias3(lookupProperty(helpers,"format_date").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),lookupProperty(helpers,"tr").call(alias2,"format.datetime_s",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":59}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":4,"column":7},"end":{"line":4,"column":62}}}))
    + " "
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"fromMailAddress") : stack1), depth0))
    + " wrote:\n    <br>\n    <blockquote cite=\"mid:"
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"messageID") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depth0 != null ? lookupProperty(depth0,"previousHtml") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":11,"column":15}}})) != null ? stack1 : "")
    + "    </blockquote>\n</div>";
},"useData":true})