import {
  Button,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { useRichText } from '@bq/components/RichText/RichtextContex';
import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { useFocusProvider } from '../FocusContext';
import { ItemPlaceholderMenuItem } from './ItemPlaceholderMenuItem';
import { TemplateElement } from './types';

interface Props<T extends OBJ = OBJ, U extends OBJ = OBJ> {
  elements: TemplateElement<T, U>[];
}

export const ItemPlaceHolders = <T extends OBJ = OBJ, U extends OBJ = OBJ>({
  elements,
}: Props<T, U>) => {
  const { editor } = useRichText();
  const { t } = useTranslation('Sales');
  const { tabIndex, handleBlur, handleEscapingToolbar } = useFocusProvider();

  return (
    <ButtonGroup isAttached size="sm">
      <Menu closeOnSelect={false}>
        <MenuButton
          as={Button}
          rightIcon={<FaChevronDown />}
          tabIndex={tabIndex}
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Escape') {
              handleEscapingToolbar();
            }
          }}
          onBlur={handleBlur}
        >
          {t('global:add')}
        </MenuButton>
        <MenuList>
          {elements.map((item) => {
            return (
              <ItemPlaceholderMenuItem<T, U> key={item.key} element={item} />
            );
          })}
        </MenuList>
      </Menu>
      <Button
        onClick={() => {
          editor?.commands.toggleShowValues();
        }}
      >
        {t('Sales:show_values')}
      </Button>
    </ButtonGroup>
  );
};
