import { FilterTypes } from '../FilterBar';
import { filterTypesWithGroup } from '../FilterBar/filters/GroupFilter';
import { Jsonish } from '../type-util';
import { IOverviewEditorItem } from './types';

export function loadOverviews(
  overviews: Jsonish,
  filterTypes: FilterTypes
): IOverviewEditorItem[] {
  if (!(overviews instanceof Object)) {
    console.error('Expected array of overviews, got: ', overviews);
    throw new Error('Got primitive overviews value');
  }

  if (!Array.isArray(overviews)) {
    // Make an exception for the case when PHP serializes empty array as empty object by default
    if (Object.keys(overviews).length === 0) {
      return [];
    }

    console.error('Expected array of overviews, got: ', overviews);
    throw new Error('Got non-array overviews value');
  }

  const allFilterTypes = filterTypesWithGroup(filterTypes);

  return (overviews as unknown as IOverviewEditorItem[]).map((overview) => ({
    ...overview,
    filters: (overview.filters ?? []).map((filter) => {
      const type = allFilterTypes[filter.filter];
      if (!type) {
        throw new Error(`Unknown filter type ${filter.filter}`);
      }

      if (type.fromJSON) {
        return {
          ...filter,
          value: type.fromJSON(
            filter.value,
            filter.operator ?? null,
            filterTypes
          ),
        };
      }

      return filter;
    }),
  }));
}
