import { getURLComponents, handlebarsRender } from 'app/assets/js/util';
// eslint-disable-next-line import/no-cycle
import {
  getTemplate,
  renderController,
  getJSON,
  activateElements,
} from 'BootQuery/Assets/js/BootQuery';

export default function createModal(properties, onShown, onClosed) {
  const originalRoute = { ...window.Bootstrap.bootquery };
  const { modalLink } = properties;
  const modalModule = properties.modalModule || null;
  const { controller, method, parameters } = getURLComponents(modalLink);

  getJSON('get', controller, method, parameters, true, (data) => {
    if (data && data.bootquery) {
      data.bootquery.isModal = true;
    }

    const templateModule = modalModule || data.bootquery.moduleName;
    getTemplate([data.bootquery.template, 'modal'], templateModule).then((fetchedTemplates) => {
      const modalTemplate = fetchedTemplates.modal;
      const template = fetchedTemplates[data.bootquery.template];
      const rendered = $(handlebarsRender(template, data));

      const modalHeading = $(rendered).find(properties.modalHeading);
      if (modalHeading.find('.section-title')) {
        modalHeading
          .find('.section-title')
          .append(
            '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>',
          );
      } else {
        modalHeading.append(
          '<button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>',
        );
      }

      const renderData = $.extend({}, properties);
      renderData.modalHeading = modalHeading.html();
      renderData.modalBody = rendered.find(properties.modalBody).html();
      renderData.modalFooter = rendered.find(properties.modalFooter).html();

      const modalWindow = handlebarsRender(modalTemplate, renderData);
      const modal = $(modalWindow).modal({ focus: false });

      if (properties.modalSummoner) {
        modal.data('modal-summoner', properties.modalSummoner);
      }

      if (properties.modalSelectUpdate) {
        $(modal).attr('data-modal-select-update', properties.modalSelectUpdate);
      }

      $(modal).ev('shown.bs.modal', (e) => {
        activateElements('.modal-dialog', data);

        if (typeof onShown === 'function') {
          onShown(modal);
        }
      });
      $(modal).ev('hide.bs.modal', (e) => {
        if (typeof onClosed === 'function') {
          onClosed(modal);
        }
        if (properties.modalReload || properties.modalTarget) {
          window.targetElement = properties.modalTarget || '.bq-content';
          renderController(
            'get',
            originalRoute.controller,
            originalRoute.method,
            originalRoute.parameters,
            originalRoute.moduleName,
          );
        }
      });

      // Modal is just hidden when dismissed and I don't want trouble with the leftovers
      $(modal).ev('hidden.bs.modal', (e) => {
        $(e.currentTarget).closest('.modal').remove();
      });
    });
  });
}
