import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import { LoadedItem } from '../types';

export type ProductEvents = {
  createProduct: LoadedItem;
  editProduct: LoadedItem;
  deleteProduct: { ID: number };
};

export const productEvents = mitt<ProductEvents>();

export const useProductEvents = createUseMitt(productEvents);

export const handleNewProduct = (data: LoadedItem) => {
  productEvents.emit('createProduct', data);
};

export const handleEditProduct = (data: LoadedItem) => {
  productEvents.emit('editProduct', data);
};

export const handleDeleteProduct = (data: { ID: number }) => {
  productEvents.emit('deleteProduct', data);
};
