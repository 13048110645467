import { useCallback, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaImage } from 'react-icons/fa';

import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { ImageInputBase64 } from 'BootQuery/Assets/components/ImageInput/ImageInputBase64';

import { useFocusProvider } from '../../FocusContext';
import { useRichText } from '../../RichtextContex';
import { URLtab } from './UrlTab';

export const Image = () => {
  const [imageUrl, setImage] = useState<string>('');
  const { t } = useTranslation();
  const { editor } = useRichText();

  const memoizedCallback = useCallback(() => {
    editor
      ?.chain()
      .focus()
      .setImage({ src: imageUrl ?? '' })
      .run();
  }, [editor, imageUrl]);

  const submitHandler = () => {
    memoizedCallback();
    setImage('');
  };

  const { tabIndex, handleBlur, handleEscapingToolbar } = useFocusProvider();

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <IconButton
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Escape') {
              handleEscapingToolbar();
            }
          }}
          onBlur={handleBlur}
          tabIndex={tabIndex}
          icon={<FaImage />}
          label={t('global:richtext.add_image')}
          size="sm"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverCloseButton zIndex="overlay" />
          <FormControl>
            <PopoverHeader borderBottom="1px solid gray.200">
              <FormLabel
                htmlFor="imageLink imageUpload"
                textAlign="center"
                w="full"
              >
                {t('global:richtext.add_image')}
              </FormLabel>
            </PopoverHeader>
            <PopoverBody>
              <Tabs isFitted>
                <TabList>
                  <Tab>{t('global:from_pc')}</Tab>
                  <Tab>{t('global:from_url')}</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <ImageInputBase64
                      changeCallback={(newVal) => setImage(newVal ?? '')}
                      submitHandler={submitHandler}
                      value={imageUrl}
                    />
                  </TabPanel>

                  <TabPanel>
                    <URLtab
                      changeCallback={(newVal) => setImage(newVal ?? '')}
                      submitHandler={submitHandler}
                      value={imageUrl}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </PopoverBody>
          </FormControl>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
