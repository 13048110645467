import { ReactElement, ForwardedRef, forwardRef } from 'react';
import { useDialerContext } from '../DialerContext';
import { DialInput } from './DialInput';
import { DTMFInput } from './DTMFInput';

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {};
const DialerInputInner = (
  _props: Props,
  ref: ForwardedRef<HTMLInputElement>
): ReactElement => {
  const { input, setInput, inputMode, sendDTMF, submitInput } =
    useDialerContext();

  if (inputMode === 'dtmf') {
    return <DTMFInput onInput={sendDTMF} ref={ref} />;
  }

  return (
    <DialInput
      value={input}
      onChange={setInput}
      onSubmit={submitInput}
      ref={ref}
    />
  );
};

export const DialerInput = forwardRef(DialerInputInner);
