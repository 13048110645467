import { createContext, PropsWithChildren, ReactElement } from 'react';

import { makeUseContext } from '../make-use-context';
import { FieldType } from './types';

export interface IStaticFormContext {
  formData: Record<string, unknown>;
  prefix?: string | unknown;
  fieldTypes?: FieldType[];
}

export const StaticFormContext = createContext<IStaticFormContext>({
  formData: {},
});

export const useStaticFormContext = makeUseContext({ StaticFormContext });

interface ProviderProps {
  formData: Record<string, unknown>;
}

export const StaticFormProvider = ({
  children,
  formData,
}: PropsWithChildren<ProviderProps>): ReactElement => (
  <StaticFormContext.Provider value={{ formData }}>
    {children}
  </StaticFormContext.Provider>
);
