import { ReactElement } from 'react';

import { FieldValue, useFormDefinition } from '@bq/components/FormEditor';
import { useDisplayMode } from '@bq/components/ListingSettings';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { CompanyLocationsCardList } from './CompanyLocationCard/CompanyLocationsCardList';
import { CompanyLocationsTableList } from './CompanyLocationsTableList';
import { MobileCompanyLocationsList } from './MobileCompanyLocationsList';

interface Props {
  company?: { ID: number; name: string };
  customFields?: FieldValue[];
}

export const CompanyLocationsList = ({ company }: Props): ReactElement => {
  const [displayMode] = useDisplayMode(
    company?.ID
      ? 'Phonebook.CompanyLocationListEmbed'
      : 'Phonebook.CompanyLocationList'
  );
  const customFields = useFormDefinition('Phonebook.companyLocation');
  const settingsLoaded = useLoadUserSettings(
    company?.ID
      ? 'Phonebook.CompanyLocationListEmbed'
      : 'Phonebook.CompanyLocationList',
    {}
  );
  const isMobile = useIsMobile();

  if (!customFields || !settingsLoaded) {
    return <LoadingPage />;
  }
  if (isMobile) {
    return (
      <MobileCompanyLocationsList
        companyID={company?.ID}
        customFields={customFields}
      />
    );
  }

  return displayMode === 'card' ? (
    <CompanyLocationsCardList
      customFields={customFields}
      companyID={company?.ID}
    />
  ) : (
    <CompanyLocationsTableList
      customFields={customFields}
      companyID={company?.ID}
    />
  );
};
