import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';
import { TicketContactColumn } from './TicketContactColumn';
import { TicketEventCard } from './TicketEventCard';
import { TicketEventColumn } from './TicketEventColumn';
import { TicketTypeColumn } from './TicketTypeColumn';
import { TicketUserColumn } from './TicketUserColumn';

export * from './types';
export { TicketEventCard };

export const ticketEventProvider: ModuleEventTypeProviders = {
  ticket: {
    components: {
      Card: TicketEventCard,
    },
    columns: {
      type: { Cell: TicketTypeColumn },
      event: { Cell: TicketEventColumn },
      user: { Cell: TicketUserColumn },
      contact: { Cell: TicketContactColumn },
    },
  },
};
