import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardBody, CardFooter } from '@bq/components/Card';

import { DatasetUploadForm } from '../../components/DatasetUploadForm';
import { SubmitNextButton } from '../../components/SubmitNextButton';
import type { UploadFormStructure } from '../../components/types';
import { useCardBodyProps } from '../../components/use-card-body-props';

export const CreateUpload = (): ReactElement => {
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (data: UploadFormStructure) => {
      if (data.file?.type !== 'uploaded') {
        console.error('File not uploaded: ', data.file);
        throw new Error('Invalid file state');
      }

      const { uploadId } = data.file;

      navigate({
        pathname: `/datasets/create/import/${uploadId}/setup`,
        search: `?name=${data.name}`,
      });
    },
    [navigate]
  );

  return (
    <>
      <CardBody {...useCardBodyProps()}>
        <DatasetUploadForm id="dataset-upload-form" onSubmit={handleSubmit} />
      </CardBody>
      <CardFooter display="flex" justifyContent="end">
        <SubmitNextButton form="dataset-upload-form" />
      </CardFooter>
    </>
  );
};
