import Vue from 'vue';
import VueTr from 'BootQuery/Assets/js/vue-tr';
import VueDateFormat from 'BootQuery/Assets/js/vue-dateformat';
import VueNumberFormat from 'BootQuery/Assets/js/vue-numberformat';
import VueBoundary from 'BootQuery/Assets/js/vue-boundary';
import VueEventListeners from 'BootQuery/Assets/js/vue-event-listeners';
import VueRouter from 'vue-router';
import VueTimers from 'vue-timers';
import VueMq from 'vue-mq';
import Vuex from 'vuex';

Vue.use(VueEventListeners);
Vue.use(VueTr);
Vue.use(VueDateFormat);
Vue.use(VueNumberFormat);
Vue.use(VueBoundary);
Vue.use(VueRouter);
Vue.use(VueTimers);
Vue.use(Vuex);
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity,
  },
});
export default Vue;
