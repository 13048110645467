import { FilterTypes } from '@bq/components/FilterBar';
import { makeComboboxFilter } from '@bq/components/FilterBar/filters/ComboboxFilter';
import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';
import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { searchGroups } from '../API/Group/searchGroups';
import { searchPrograms } from '../API/Program/searchPrograms';
import { searchSubgroups } from '../API/Subgroup/searchSubgroups';

export const idFilter = makeNumberFilter({
  name: '#',
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'ID:gte': value.from,
          'ID:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['ID', operator ?? 'eq', value];
    }

    return null;
  },
});
export const nameFilter = makeTextFilter({
  name: () => i18n.t('global:title'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return ['name', operator ?? 'contains:ci', value];
  },
});

export const longNameFilter = makeTextFilter({
  name: () => i18n.t('Products:long_name'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return ['longName', operator ?? 'contains:ci', value];
  },
});

export const programFilter = makeComboboxFilter({
  name: () => i18n.t('Products:program'),
  operators: [],
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    return typeof value === 'string'
      ? { 'program.name:contains:ci': value }
      : { 'program.ID': value.ID };
  },
  extraProps: {
    cacheKey: 'programFilter',
    search: async (search: string) => {
      const data = await searchPrograms({ search });

      return data;
    },
    itemToString: (item) => item.name,
    itemToValue: (item) => item,
    valueToItem: (item) => item,
  },
});
export const groupFilter = makeComboboxFilter({
  name: () => i18n.t('Products:group'),
  operators: [],
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    return typeof value === 'string'
      ? { 'group.name:contains:ci': value }
      : { 'group.ID': value.ID };
  },
  extraProps: {
    cacheKey: 'groupFilter',
    search: async (search: string) => {
      const data = await searchGroups({ search });

      return data;
    },
    itemToString: (item) => item.name,
    itemToValue: (item) => item,
    valueToItem: (item) => item,
  },
});
export const subgroupFilter = makeComboboxFilter({
  name: () => i18n.t('Products:subgroup'),
  operators: [],
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    return typeof value === 'string'
      ? { 'subgroup.name:contains:ci': value }
      : { 'subgroup.ID': value.ID };
  },
  extraProps: {
    cacheKey: 'subgroupFilter',
    search: async (search: string) => {
      const data = await searchSubgroups({ search });

      return data;
    },
    itemToString: (item) => item.name,
    itemToValue: (item) => item,
    valueToItem: (item) => item,
  },
});
/** default amount unit, amount unit group */

/* export const supplierFilter = makeContactFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return {
        'good.supplierCompany.name:contains:ci': value,
      };
    }
    if (typeof value === 'object') {
      const { ID } = value;
      return {
        'good.supplierCompany.ID': ID,
      };
    }
    return null;
  },
  name: () => i18n.t('Products:supplier'),
  operators: [],
  allowedContactTypes: {
    company: true,
    companyDepartment: false,
    companyLocation: false,
    person: false,
    user: false,
  },
}); */

export const codeFilter = makeTextFilter({
  name: () => i18n.t('Products:code'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return ['code', operator ?? 'contains:ci', value];
  },
});

export const baseFilters: FilterTypes = {
  idFilter,
  nameFilter,
  longNameFilter,
  programFilter,
  groupFilter,
  subgroupFilter,
  // supplierFilter,
  codeFilter,
};
