import Quill from 'quill/core';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Underline from 'quill/formats/underline';
import Strike from 'quill/formats/strike';
import Script from 'quill/formats/script';
import Header from 'quill/formats/header';
import Blockquote from 'quill/formats/blockquote';
import CodeBlock from 'quill/formats/code';
import Link from 'quill/formats/link';
import List, { ListItem } from 'quill/formats/list';
import Image from 'quill/formats/image';

import { AlignStyle } from 'quill/formats/align';
import { ColorStyle } from 'quill/formats/color';
import { DirectionStyle } from 'quill/formats/direction';
import { FontStyle } from 'quill/formats/font';
import { SizeStyle } from 'quill/formats/size';
import { BackgroundStyle } from 'quill/formats/background';

import SnowTheme from 'quill/themes/snow';
import ToolbarModule from 'quill/modules/toolbar';

import AutoLinks from 'quill-auto-links';
import SignatureMarker from './signature-marker';
import RawHtml from './raw-html';
import QuillTemplate from './template';
import QuillQuotedContent from './quoted-content';

const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

Quill.register(
  {
    'attributors/style/align': AlignStyle,
    'attributors/style/background': BackgroundStyle,
    'attributors/style/color': ColorStyle,
    'attributors/style/direction': DirectionStyle,
    'attributors/style/font': FontStyle,
    'attributors/style/size': SizeStyle,
    'formats/image': Image,
    'formats/bold': Bold,
    'formats/italic': Italic,
    'formats/underline': Underline,
    'formats/strike': Strike,
    'formats/script': Script,
    'formats/header': Header,
    'formats/color': ColorStyle,
    'formats/background': BackgroundStyle,
    'formats/font': FontStyle,
    'formats/align': AlignStyle,
    'formats/blockquote': Blockquote,
    'formats/code-block': CodeBlock,
    'formats/link': Link,
    'formats/list': List,
    'formats/list/item': ListItem,
    'formats/signature-marker': SignatureMarker,
    'modules/template': QuillTemplate,
    'formats/quoted-content': QuillQuotedContent,
    'formats/raw-html': RawHtml,
    'themes/snow': SnowTheme,
    'modules/toolbar': ToolbarModule,
    'modules/autoLinks': AutoLinks,
  },
  true,
);

export default Quill;
