module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid py-3\" data-settings-form=\"displaySettings\" data-form=\"displaySettings\">\n    <div class=\"row\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = (depth0 != null ? lookupProperty(depth0,"forms") : depth0)) != null ? lookupProperty(stack1,"displayForm") : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true})