import { useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { Select } from '@bq/components/SelectWrappers';

import { getStates } from '../../../../api/State/getStates';
import { CaseState } from '../../../../schemas';
import { StateOption } from './StateOption';
import { StateValue } from './StateValue';
import { StateExtraData, StateSelectOption } from './types';

interface Props {
  value?: CaseState;
  onChange: (val: CaseState | null) => void;
  error?: FieldError;
}
export const StateField = ({ onChange, error, value }: Props) => {
  const selectToStateValue = useCallback(
    (val: StateSelectOption | null | undefined): CaseState | null => {
      if (!val) {
        return null;
      }

      return {
        ID: val.value,
        name: val.label,
        color: val.color,
        outcomeType: val.outcomeType,
      };
    },
    []
  );

  const stateValueToSelect = useCallback(
    (val: CaseState | null | undefined): StateSelectOption | null => {
      if (!val) {
        return null;
      }

      return {
        label: val.name,
        color: val.color,
        value: val.ID,
        outcomeType: val.outcomeType,
      };
    },
    []
  );

  const selectValue = useMemo(
    () => stateValueToSelect(value),
    [stateValueToSelect, value]
  );

  return (
    <Select<number, StateExtraData>
      isInvalid={!!error?.message}
      options={async (search) => {
        const { data } = await getStates({
          filters: { $search: search },
        });

        return data.map((item) => ({
          label: item.name,
          value: item.ID,
          color: item.color,
          outcomeType: item.outcomeType,
        }));
      }}
      value={selectValue}
      onChange={(val) => {
        onChange(selectToStateValue(val));
      }}
      components={{ Option: StateOption, SingleValue: StateValue }}
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
    />
  );
};
