import {
  ContactInfo,
  NumberContactInfoResolver,
} from '../../js/number-contact-info-resolver';

export function getContactForNumber(
  phoneNumber: string
): Promise<ContactInfo | null> {
  const contactInfoResolver = new NumberContactInfoResolver();

  return contactInfoResolver.getContactInfo(phoneNumber);
}
