import { CellProps } from 'BootQuery/Assets/components/Table';
import { ReactElement } from 'react';
import { CallDestination } from '../CallDestination';
import { CallListCall } from './types';

export const CallDestinationCell = ({
  row: call,
}: CellProps<CallListCall>): ReactElement => {
  const { destinations, destinationInfo } = call;

  return (
    <CallDestination
      destinations={destinations}
      destinationInfo={destinationInfo}
    />
  );
};
