import { useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { getPriorities } from '../../../api/Priority/getPriorities';
import { CasePriority } from '../../../schemas';

type PrioritySelectOption = SelectOption<number, { val: number }>;
interface Props {
  value?: CasePriority;
  onChange: (val: CasePriority | null) => void;
  error?: FieldError;
}
export const PriorityField = ({ onChange, value, error }: Props) => {
  const selectToPriorityValue = useCallback(
    (val: PrioritySelectOption | null | undefined): CasePriority | null => {
      if (!val) {
        return null;
      }

      return { ID: val.value, name: val.label, value: val.val };
    },
    []
  );

  const priorityValueToSelect = useCallback(
    (val: CasePriority | null | undefined): PrioritySelectOption | null => {
      if (!val) {
        return null;
      }

      return { label: val.name, val: val.value, value: val.ID };
    },
    []
  );

  const selectValue = useMemo(
    () => priorityValueToSelect(value),
    [priorityValueToSelect, value]
  );

  return (
    <Select<number, { val: number }>
      options={async (search) => {
        const { data } = await getPriorities({
          filters: { $search: search },
        });

        return data.map((item) => ({
          label: item.name,
          value: item.ID,
          val: item.value,
        }));
      }}
      value={selectValue}
      onChange={(val) => {
        onChange(selectToPriorityValue(val));
      }}
      isInvalid={!!error?.message}
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
    />
  );
};
