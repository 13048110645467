import { ReactElement, useCallback, useMemo } from 'react';

import { Select, SelectOption } from '@bq/components/SelectWrappers';
import { listProductsRaw } from 'app/Modules/Products/Assets/components/Settings/API/Product/list-products-raw';

import { Product } from './types';

export interface ProductSelectProps {
  isClearable?: boolean;
  isInvalid?: boolean;
  value?: Product | null;
  onChange: (value: Product | null) => void;
}

interface ProductOption extends SelectOption {
  product: Product;
}

export const ProductSelect = ({
  value,
  onChange,
  isInvalid,
  isClearable = false,
}: ProductSelectProps): ReactElement => {
  const optionValue: ProductOption | null = useMemo(() => {
    if (!value) {
      return null;
    }

    return productToOption(value);
  }, [value]);

  const handleChange = useCallback(
    (newValue: ProductOption | null) => {
      if (!newValue) {
        onChange(null);
      } else {
        onChange(newValue.product);
      }
    },
    [onChange]
  );

  const loadOptions = useCallback(
    async (search: string): Promise<ProductOption[]> => {
      const { data } = await listProductsRaw({
        params: { filters: { $search: search }, withCount: false },
      });

      return data.map(productToOption);
    },
    []
  );

  return (
    <Select
      isInvalid={isInvalid}
      isClearable={isClearable}
      value={optionValue}
      onChange={handleChange}
      options={loadOptions}
    />
  );
};

function productToOption(product: Product): ProductOption {
  return {
    label: product.name,
    value: product.ID,
    product,
  };
}
