import {
  FieldValue,
  customFormColumns,
} from 'BootQuery/Assets/components/FormEditor';
import { getFieldTypes } from 'BootQuery/Assets/components/FormEditor/field-types';
import { Column } from 'BootQuery/Assets/components/Table';
import { useMemo } from 'react';
import { columns } from '../PersonList';
import { Permissions, PersonApiData } from '../types';
import { PersonCardFeatures } from '../PersonList/types';
import { PersonColumn } from '../PersonList/columns';

export function useColumns(
  permissions: Permissions,
  customFields: FieldValue[] = []
): Column<PersonApiData>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<PersonApiData>[];

    const normalColumns = columns(permissions)
      .filter((col) => col.key !== 'company')
      .map((col) => {
        if (col.key === 'department') {
          return { ...col, defaultVisible: true };
        }

        return col;
      });

    return [...normalColumns, ...customColumns];
  }, [customFields, permissions]);
}

export function useTableColumns(
  permissions: Permissions,
  customFields: FieldValue[] = []
): Column<PersonApiData>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<PersonApiData>[];

    const normalColumns = columns(permissions)
      .filter((col) => col.key !== 'company')
      .map((col) => {
        if (col.key === 'department') {
          return { ...col, defaultVisible: true };
        }

        return col;
      });

    return [...normalColumns, ...customColumns];
  }, [customFields, permissions]);
}

export function useCardColumns(
  permissions: Permissions,
  customFields: FieldValue[] = []
): Column<PersonApiData>[] {
  return useMemo(() => {
    const cardColumns = columns(permissions)
      .filter((col) => col.forCard && col.key !== 'company')
      .map((col) => {
        if (col.key === 'department') {
          return { ...col, defaultVisible: true };
        }

        return col;
      });

    const customColumns = customFormColumns(customFields, getFieldTypes());

    return [...cardColumns, ...(customColumns as unknown as PersonColumn[])];
  }, [customFields, permissions]);
}

interface SplitColumnFeatures {
  features: Partial<PersonCardFeatures>;
  columns: Column<PersonApiData>[];
}

const notColumns = [
  'phoneNumbers',
  'emails',
  'firstName',
  'lastName',
  '$selected',
  'company',
];
export function useSplitColumnsFeatures(
  permissions: Permissions,
  columns: Column<PersonApiData>[]
): SplitColumnFeatures {
  return useMemo(
    () => ({
      columns: columns.filter((col) => !notColumns.includes(col.key)),
      features: {
        phoneNumbers: columns.some((col) => col.key === 'phoneNumbers'),
        emails: columns.some((col) => col.key === 'emails'),
        firstName: columns.some((col) => col.key === 'firstName'),
        select: columns.some((col) => col.key === '$selected'),
      },
    }),
    [columns]
  );
}
