import { ReactElement } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ReportAgentsPermissionModal } from '@bq/components/ReportAgentsPermissions';

import { TicketingProvider } from '../TicketingContext';
import { DeleteEventsPermission } from './DeleteEventsPermission';
import { DeleteTicketsPermission } from './DeleteTicketsPermission';
import { EditPermissionsModal } from './EditPermissionsModal';
import { ReportPermission } from './ReportPermission';
import {
  useAgentsPermissions,
  useSetAgentPermissions,
} from './use-agent-permissions';

export const PermissionSettings = (): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const groupID = window.Bootstrap.bootquery.parameters.groupID as string;

  const editModalState = useDisclosure();

  const agentsModalState = useDisclosure();
  const agentsPermissions = useAgentsPermissions(groupID);
  const setAgentsPermissions = useSetAgentPermissions(groupID);

  return (
    <div className="mt-5">
      <h3>
        <span className="fas fa-list-alt"></span>{' '}
        {t('Ticketing:ticketing_title')}
      </h3>
      <hr />
      <div className="flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={editModalState.onOpen}
        >
          <span className="fas fa-edit"></span>{' '}
          {t('Ticketing:permissions.edit_permissions')}
        </button>{' '}
      </div>
      <div className="mt-3 d-flex">
        <ReportPermission groupID={groupID} />
        <button
          type="button"
          className="btn btn-primary ml-3"
          onClick={agentsModalState.onOpen}
        >
          <span className="fas fa-edit"></span>{' '}
          {t('global:report_agents_permissions')}
        </button>{' '}
      </div>
      <div className="mt-3 d-flex">
        <DeleteTicketsPermission groupID={groupID} />
      </div>
      <div className="mt-3">
        <DeleteEventsPermission groupID={groupID} />
      </div>
      <TicketingProvider>
        <EditPermissionsModal
          groupID={groupID}
          isOpen={editModalState.isOpen}
          onClose={editModalState.onClose}
        />
        <ReportAgentsPermissionModal
          isOpen={agentsModalState.isOpen}
          onClose={agentsModalState.onClose}
          agentsPermissions={agentsPermissions}
          setAgentsPermissions={setAgentsPermissions}
        />
      </TicketingProvider>
    </div>
  );
};
