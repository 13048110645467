import { useMemo } from 'react';
import { FormControl, FormLabel, Grid, Input } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';
import { useUnits } from 'app/Modules/Products/Assets/js/api';

import { Good } from '../../../types';
import { parseNumberOrUndefined } from '../../utils';

export const DimensionInput = () => {
  const { register, control } = useFormContext<Good>();
  const { t } = useTranslation('Products');
  const dimensionUnits = useDistanceUnitOptions();
  const { field } = useController<Good, 'good.dimensionUnit'>({
    name: 'good.dimensionUnit',
    control,
  });

  return (
    <Grid templateColumns={['1fr', 'repeat(3, 1fr) 6rem']}>
      <FormControl mb="5">
        <FormLabel htmlFor="good.lengthMeters">
          {t('Products:length')}
        </FormLabel>
        <Input
          step="any"
          size={['md', 'md', 'sm']}
          type="number"
          {...register('good.lengthMeters', {
            setValueAs: parseNumberOrUndefined,
          })}
          placeholder={t('Products:length')}
          borderRightRadius={0}
        />
      </FormControl>

      <FormControl mb="5">
        <FormLabel htmlFor="good.widthMeters">{t('Products:width')}</FormLabel>
        <Input
          size={['md', 'md', 'sm']}
          type="number"
          step="any"
          {...register('good.widthMeters', {
            setValueAs: parseNumberOrUndefined,
          })}
          placeholder={t('Products:width')}
          borderRadius={0}
        />
      </FormControl>

      <FormControl mb="5">
        <FormLabel htmlFor="good.heightMeters">
          {t('Products:height')}
        </FormLabel>
        <Input
          step="any"
          size={['md', 'md', 'sm']}
          type="number"
          {...register('good.heightMeters', {
            setValueAs: parseNumberOrUndefined,
          })}
          placeholder={t('Products:height')}
          borderRadius={0}
        />
      </FormControl>
      <FormControl mb="5">
        <FormLabel htmlFor="good.dimensionUnit">{t('Products:unit')}</FormLabel>
        <Select
          size="sm"
          onChange={(val) => {
            if (val !== null) {
              field.onChange({ symbol: val.value });
            }
          }}
          defaultValue={
            field.value
              ? { value: field.value.symbol, label: field.value.symbol }
              : null
          }
          variant="outline"
          options={dimensionUnits}
        />
      </FormControl>
    </Grid>
  );
};

function useDistanceUnitOptions(): SelectOption[] {
  const { data: units } = useUnits();

  return useMemo(() => {
    if (!units) {
      return [];
    }

    return Object.values(units.distance.units).map(({ symbol }) => ({
      label: symbol,
      value: symbol,
    }));
  }, [units]);
}
