import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Api } from 'BootQuery/Assets/js/api';
import { ICallListSettings } from './CallListSettingsContext';
import { EnabledRecordingIndicators } from './types';

export async function getCallListSettings(): Promise<ICallListSettings> {
  const [enabledRecordingIndicators, useUglyIcons] = await Promise.all([
    getEnabledRecordingIndicators(),
    getUseUglyIcons(),
  ]);

  return { enabledRecordingIndicators, useUglyIcons };
}

export function useLoadCallListSettings(): UseQueryResult<ICallListSettings> {
  return useQuery({
    queryKey: ['Telephony.callListSettings'],
    queryFn: getCallListSettings,
  });
}

export async function getEnabledRecordingIndicators(): Promise<EnabledRecordingIndicators> {
  const { data } = await Api.get<EnabledRecordingIndicators>(
    '/api/telephony/recordingIndicators'
  );

  return data;
}

export async function getUseUglyIcons(): Promise<boolean> {
  const { data } = await Api.get<boolean>('/api/telephony/useUglyIcons');

  return data;
}
