import { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';

import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { RichtextFieldSettings } from './types';

type Props = EditorFieldComponentProps<RichtextFieldSettings>;

export const SettingsComponent = (props: Props): ReactElement => {
  const { setSetting } = useFieldSettings(props);

  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setMode = (ev: ChangeEvent<HTMLSelectElement>) => {
    const newMode = ev.target.value;
    if (newMode !== 'advanced' && newMode !== 'basic') {
      throw new Error(`Invalid mode ${newMode}`);
    }

    setSetting('mode', newMode);
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input
          placeholder={t('global:name') ?? undefined}
          value={props.content.settings.name}
          onChange={setName}
          size="sm"
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('global:form_editor.richtext_field_mode')}:</FormLabel>
        <Select
          value={props.content.settings.mode ?? 'advanced'}
          onChange={setMode}
          size="sm"
        >
          <option value="basic">
            {t('global:form_editor.richtext_field_basic')}
          </option>
          <option value="advanced">
            {t('global:form_editor.richtext_field_advanced')}
          </option>
        </Select>
      </FormControl>
    </VStack>
  );
};
