import { useTranslation } from 'react-i18next';

import { CellProps } from '@bq/components/Table';

import { LoadedItem } from '../types';

export const TypeCell = ({ row }: CellProps<LoadedItem>) => {
  const { t } = useTranslation('Products');

  return (
    <>
      {row.productType === 'good'
        ? t('Products:product')
        : t('Products:service')}
    </>
  );
};
