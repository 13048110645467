import { ReactElement } from 'react';
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaCircle } from 'react-icons/fa';

import { pauseMe, unpauseMe } from '../Api/pause';
import { dialerStore } from './store/pbx';

interface PauseItemTextProps {
  pause: string;
}

export const PauseItemText = ({ pause }: PauseItemTextProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  if (pause === '$available') {
    return (
      <Flex alignItems="center">
        <Text as="span" color="green.400">
          <FaCircle />
        </Text>
        &nbsp;
        {t('Telephony:available')}
      </Flex>
    );
  }

  return (
    <Flex alignItems="center">
      <Text as="span" color="yellow.400">
        <FaCircle />
      </Text>
      &nbsp;
      {pause || t('Telephony:pause')}
    </Flex>
  );
};

export const PauseMenu = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { pauseTypes, userQueues } = dialerStore(({ user, pauseTypes }) => ({
    pauseTypes: pauseTypes.length > 0 ? pauseTypes : '',
    userQueues: user?.queues ?? [],
  }));
  const allPauseTypes = ['$available', ...pauseTypes];
  const handleChange = (newVal: string | string[]) => {
    if (typeof newVal !== 'string') {
      throw new Error('Got non-string value');
    }

    if (newVal === '$available') {
      unpauseMe();
    } else {
      pauseMe(newVal);
    }
  };
  const currentPause =
    userQueues.map((queue) => queue.pause).find((pause) => pause !== null)
      ?.name ?? '$available';

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<FaChevronDown />}
        bg="transparent"
        color="brand.fontStaticLight"
        _hover={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
        _focus={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
        _active={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
      >
        <PauseItemText pause={currentPause} />
      </MenuButton>
      <MenuList zIndex="popover">
        <MenuOptionGroup
          title={t('Telephony:pause')}
          type="radio"
          value={currentPause}
          onChange={handleChange}
        >
          {allPauseTypes.map((type) => (
            <MenuItemOption key={type} value={type}>
              <PauseItemText pause={type} />
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
