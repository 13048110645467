import { useMemo } from 'react';

import { GroupBy } from './types';
import { Column, Row } from '../Table';

/**
 * Returns a list of custom form fields for currently grouped columns
 * The fields will have `alwaysVisible` set because it makes no sense to hide
 * a column the user grouped by
 *
 * @param groupedBy Active group columns
 * @returns Custom form fields for active group by columns
 */
export const useGroupedColumns = <RowType = Row>(
  groupedBy: GroupBy[],
  columns: Column<RowType>[]
): Column<RowType>[] => {
  return useMemo(() => {
    const groupedColumnKeys = groupedBy.map((grp) => grp.columnKey ?? grp.key);

    return columns
      .filter((col) => groupedColumnKeys.includes(col.key))
      .map((col) => ({ ...col, alwaysVisible: true }));
  }, [groupedBy, columns]);
};
