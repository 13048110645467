import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { SalesState } from '../types';

export const getSalesStates = async <T = SalesState>(
  params: GetListingParams = {}
): Promise<T[]> => {
  const { data } = await Api.get<ListingResponse<T>>('/api/sales/states', {
    params,
  });

  return data.data;
};
