import { useContactCreateModal } from '@bq/components/ContactModals/useContactCreateModal';
import { useContactEditModal } from '@bq/components/ContactModals/useContactEditModal';
import { useCallback } from 'react';
import { GenericCallbackData } from 'BootQuery/Assets/js/globalTypes';
import { ContactItem, ContactSelectSingleValue } from './types';

const emptyFn = () => {
  /** Note : this fn will never trigger but typescript is a bit wonky so */
};
export const useContactEditButton = (
  value: ContactSelectSingleValue,
  onChange: (value: ContactItem | null) => void
) => {
  const callEditModal = useContactEditModal();
  const callCreateModal = useContactCreateModal();

  return useCallback(() => {
    /** If no value we return no callback */
    if (!value) {
      return emptyFn();
    }
    /** If we newly created a value, we can edit it but we open a create modal */
    if ('$new' in value) {
      /** All Contact except for ID have a field called name so we can handle them in bulk */
      if (
        value.type === 'company' ||
        value.type === 'companyDepartment' ||
        value.type === 'companyLocation'
      ) {
        return callCreateModal({
          type: value.type,
          /** Pass the newly created name as default name */
          defaults: { name: value.$new },
          closeCallback: (data: GenericCallbackData) => {
            /** This returns a partial data of what has been changed and ID */
            /** The only field of interest is name  */
            /** because we will change the label if it changed */
            /** If name didn't change we don't have to change the select option */
            /** Because its same as before */
            if ('name' in data && typeof data.name === 'string') {
              onChange({ ID: data.ID, name: data.name, type: value.type });
            }
          },
        });
      }
      /** Person has firstName / lastName fields */
      if (value.type === 'person') {
        /** We don't really know which part is firstName which */
        /** Is last name so we split them in two equal parts */
        /** First half for name, otherhalf for lastName */
        const splitNew = value.$new.split(' ');
        const half = Math.ceil(splitNew.length / 2);
        const firstHalf = splitNew.slice(0, half).join(' ');
        const secondHalf = splitNew.slice(half).join(' ');

        return callCreateModal({
          type: value.type,
          defaults: { firstName: firstHalf, lastName: secondHalf },
          closeCallback: (data) => {
            /** Same as before but this might be a bit weird on changes */
            if ('firstName' in data || 'lastName' in data) {
              onChange({
                ID: data.ID,
                name: `${data.firstName} ${data.lastName}`,
                type: 'person',
              });
            }
          },
        });
      }

      return emptyFn();
    }
    if (
      value.type === 'company' ||
      value.type === 'companyDepartment' ||
      value.type === 'companyLocation' ||
      value.type === 'person'
    ) {
      return callEditModal({
        type: value.type,
        ID: value.ID,
        closeCallback: (data: GenericCallbackData) => {
          if (
            value.type === 'person' &&
            'firstName' in data &&
            'lastName' in data
          ) {
            onChange({
              ID: data.ID,
              name: `${data.firstName} ${data.lastName}`,
              type: 'person',
            });
          }
          if ('name' in data && typeof data.name === 'string') {
            onChange({ ID: data.ID, name: data.name, type: value.type });
          }
        },
      });
    }

    return emptyFn();
  }, [callCreateModal, callEditModal, onChange, value]);
};
