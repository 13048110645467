import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormEditor } from '@bq/components/FormEditor';

import { StatesMultiselectField } from './StatesMultiselectField';
import { SubjectFields, SubjectFieldsStatic } from './SubjectFields';
import { CaseTypesForm } from './types';

interface Props {
  formMode: 'create' | 'edit';
}

export const TypeFormFields = ({ formMode }: Props) => {
  const { control, register } = useFormContext<CaseTypesForm>();
  const { field } = useController({
    name: 'form',
    control,
  });
  const { t } = useTranslation('Sales');

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input {...register('name', { required: true })} size="sm" />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Sales:states')}:</FormLabel>
        <StatesMultiselectField />
      </FormControl>
      {formMode === 'create' ? <SubjectFields /> : <SubjectFieldsStatic />}
      <Divider />
      <FormEditor value={field.value ?? []} onChange={field.onChange} />
    </VStack>
  );
};
