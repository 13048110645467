import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';
import { TicketGroup } from '../../types';

export const getTicketGroups = async <T = TicketGroup[]>(params: GetListingParams = {}) => {
  const { data } = await Api.get<T>(
    '/api/ticketing/groups',
    {
      params,
    }
  );

  return data;
};
