module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"formEditorSelectItem"),depth0,{"name":"formEditorSelectItem","hash":{"noSubitems":(depths[1] != null ? lookupProperty(depths[1],"isLastSelect") : depths[1]),"id":(depth0 != null ? lookupProperty(depth0,"key") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + " ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card\" data-select=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n    <div class=\"card-header text-center py-2\">\n        <span class=\"title-thingie label-text\" contenteditable=\"true\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n    </div>\n    <div class=\"card-body p-0\">\n        <div class=\"list-group list-group-flush\">\n            "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":97}}})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"card-footer py-2\">\n        <button type=\"button\" class=\"btn btn-sm btn-success float-right select-list-add-btn\">\n            <span class=\"fa fa-plus\"></span> Add\n        </button>\n    </div>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true})