import {
  ForwardedRef,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  HTMLChakraProps,
  StyleProps,
  ThemingProps,
} from '@chakra-ui/react';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { Combobox } from 'BootQuery/Assets/components/Combobox';

import { useValueToItem, valueItemCache } from './items';
import { ComboboxFilterProps, ValBase } from './types';

interface ComboboxInactiveDisplayProps<ItemType extends ValBase> {
  itemReady: boolean;
  item: ItemType | string | null;
  itemToString: (item: ItemType) => string;
  renderItem?: (item: ItemType) => ReactElement;
}

const ComboboxInactiveDisplay = <ItemType extends ValBase>({
  item,
  itemReady,
  renderItem: ItemDisplay,
  itemToString,
}: ComboboxInactiveDisplayProps<ItemType>): ReactElement => {
  const { t } = useTranslation();

  if (!itemReady) {
    return <>{t('global:loading')}...</>;
  }

  if (!item) {
    return <></>;
  }

  if (typeof item === 'string') {
    return <>~{item}</>;
  }

  return (
    <strong>
      {ItemDisplay ? <ItemDisplay {...item} /> : itemToString(item)}
    </strong>
  );
};

interface FilterComboboxInputProps<
  ItemType extends ValBase,
  ValueType extends ValBase,
> extends ComboboxFilterProps<ItemType, ValueType> {
  inputStyle?: StyleProps & ThemingProps<'Input'>;
  buttonStyle?: HTMLChakraProps<'button'> & ThemingProps<'button'>;
  inputRef?: ForwardedRef<HTMLInputElement>;
}

export const FilterComboboxInput = <I extends ValBase, V extends ValBase>({
  search,
  itemToString,
  itemToValue,
  valueToItem,
  value,
  onChange,
  inputStyle,
  buttonStyle = {},
  inputRef,
  onInputValueChange,
  renderItem,
  enableTextSearch = true,
  cacheKey,
  autoFocus,
  isNew,
}: FilterComboboxInputProps<I, V>): ReactElement => {
  const [active, setActive] = useState<boolean>(isNew ?? false);
  const { item, itemReady } = useValueToItem(value, valueToItem, cacheKey);
  const justActivated = useRef(isNew ?? false);

  const handleChange = useCallback(
    (item: I | string | null) => {
      if (item === null || typeof item === 'string') {
        onChange(item);
      } else {
        const value = itemToValue(item);
        const key = `${cacheKey}-${hash(value)}`;
        valueItemCache.set(key, item);
        onChange(value);
      }
    },
    [onChange, itemToValue, cacheKey]
  );

  // Toggle justActivated to false after every re-render
  // Old value will be kept during current render since useEffect runs later
  useEffect(() => {
    justActivated.current = false;
  });

  const activate = useCallback(() => {
    setActive(true);
    justActivated.current = true;
  }, [setActive]);

  const deactivate = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <>
      {active ? (
        <Combobox<I>
          getResults={search}
          itemToString={itemToString}
          onEditEnd={deactivate}
          value={item}
          onChange={handleChange}
          enableTextSearch={enableTextSearch}
          cacheKey={cacheKey}
          inputStyle={inputStyle}
          inputRef={inputRef}
          onInputValueChange={onInputValueChange}
          renderItem={renderItem}
          autoFocus={autoFocus ?? justActivated.current}
        />
      ) : (
        <Button
          {...buttonStyle}
          onClick={activate}
          flex="0 1 auto"
          d="flex"
          rightIcon={<FaChevronDown fontSize="1rem" />}
        >
          <Box minW="48px">
            <ComboboxInactiveDisplay
              item={item}
              itemReady={itemReady}
              renderItem={renderItem}
              itemToString={itemToString}
            />
          </Box>
        </Button>
      )}
    </>
  );
};
