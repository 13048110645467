import { createContext, useContext } from 'react';
import { DeepPartial } from 'react-hook-form';
import { CustomEventFormData, Event } from '../types';
import { UseEventsResult } from '../use-events';

export type AddCustomEvent = (defaults: DeepPartial<CustomEventFormData>) => void;
export type EditCustomEvent = (eventID: number) => void;
export type DeleteCustomEvent = (eventID: number) => void;
export type AddEvent = (event: Event) => void;
export type RemoveEvent = (type: string, id: string) => void;

export interface IEventListContext {
  addCustomEvent: AddCustomEvent;
  editCustomEvent: EditCustomEvent;
  deleteCustomEvent: DeleteCustomEvent;
  refetch: () => void;
  addEvent: AddEvent;
  removeEvent?: RemoveEvent;
  eventQuery: UseEventsResult
  viewOnly?: boolean;
}

export const EventListContext = createContext<IEventListContext | null>(null);

export const useEventList = (): IEventListContext => {
  const context = useContext(EventListContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
};
