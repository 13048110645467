import { ReactElement } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CaseTypesForm } from '../types';
import { DatasetField } from './DatasetField';
import { SubjectTypeField } from './SubjectTypeField';

export const SubjectFields = (): ReactElement => {
  const { t } = useTranslation('Sales');
  const { control } = useFormContext<CaseTypesForm>();
  const subjectType = useWatch({ control, name: 'subjectType' });

  return (
    <>
      <FormControl>
        <FormLabel>{t('Sales:subject_type')}:</FormLabel>
        <SubjectTypeField />
      </FormControl>
      {subjectType === 'dataset' && (
        <FormControl>
          <FormLabel>{t('Sales:dataset')}:</FormLabel>
          <DatasetField />
        </FormControl>
      )}
    </>
  );
};
