import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import { CustomData } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { DateIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { DateField } from './DateField';
import { StaticDateField } from './StaticDateField';

function loadVal(val: unknown): Date | null {
  if (typeof val === 'string' && val !== '') {
    return new Date(val);
  }

  return val instanceof Date ? val : null;
}

export const dateField = (): FieldType => ({
  type: 'date',
  name: i18n.t('global:form_editor.field_date'),
  icon: DateIcon,
  components: {
    FormField: DateField,
    StaticFormField: StaticDateField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  loadValue: (field, formValues) => ({
    ...formValues,
    [field.id]: loadVal(formValues[field.id]),
  }),
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        sort: `data.${field.id}`,
        getValue: ({ row }) => {
          if (!row.data) {
            return '-';
          }

          const value = (row.data as CustomData)[field.id];
          if (!value) {
            return '-';
          }

          return formatDate(value, DateType.Date);
        },
      },
    ];
  },
  getFilters(field) {
    return {
      [`$custom-${field.id}`]: makeDateFilter({
        name: field.settings.name,
        // Since ISO strings are purposely designed to be properly ordered
        // in string format, we can just compare strings directly
        //
        // Custom date fields are stored as full ISO8601 strings.
        // This might be wrong, but we need to account for this.
        toFilter: makeDateToFilter(`data.${field.id}`),
      }),
    };
  },
});
