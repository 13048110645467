import { ReactElement } from 'react';
import {
  Flex,
  Tooltip,
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import { FaBan, FaChevronDown } from 'react-icons/fa';
import { Device } from '../../types/device';
import { DeviceStatusIndicator } from './DeviceStatusIndicator';
import { dialerStore } from './store/pbx';

interface Props {
  buttonColor?: string;
  showDeviceName?: boolean;
}
export const DeviceIndicator = ({ showDeviceName }: Props): ReactElement => {
  const devices = dialerStore((state) => state.user?.devices ?? []);
  const currentDevice = dialerStore((state) => state.currentDevice);
  const setDevice = (device: Device) => {
    dialerStore.getState().setCurrentDevice(device);
  };

  if (!devices.length) {
    return (
      <Tooltip hasArrow label="No device">
        <div>
          <FaBan />
        </div>
      </Tooltip>
    );
  }

  const deviceName = showDeviceName
    ? currentDevice?.name
    : currentDevice?.deviceId;

  return (
    <Menu isLazy>
      <MenuButton
        as={Button}
        variant="ghost"
        color="brand.fontStaticLight"
        _hover={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
        _focus={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
        _active={{ color: 'brand.fontStaticDark', bg: 'brand.background' }}
        size="sm"
        rightIcon={<FaChevronDown />}
      >
        <Flex alignItems="center">
          {currentDevice && (
            <DeviceStatusIndicator status={currentDevice.currentState} />
          )}
          &nbsp;
          {deviceName ?? 'Select a device'}
        </Flex>
      </MenuButton>
      <MenuList>
        {devices.map(({ device }) => (
          <MenuItem key={device.deviceId} onClick={() => setDevice(device)}>
            <DeviceStatusIndicator status={device.currentState} />
            &nbsp;
            {device.name} ({device.deviceId})
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
