import urlParse from 'url-parse';

import { ObjectEntries } from 'BootQuery/Assets/js/globalTypes';

export {
  formatNumber,
  numberToE164,
  getPhoneNumberCountryCode,
  phoneNumberValid,
} from './number-util';

export { formatDuration } from './format-duration';
export { formatPercentage } from './format-percentage';

/**
 * Checks if element is or is inside of a selector
 */
export function isInsideOrIs(element: EventTarget, selector: string): boolean {
  let el: EventTarget | null = element;
  while (el instanceof Element) {
    if (el.matches(selector)) {
      return true;
    }
    el = el.parentNode;
  }

  return false;
}

/**
 * Checks if element is or is inside of a link or button
 */
export function isInsideLinkOrButton(element: EventTarget): boolean {
  return isInsideOrIs(element, 'a, button');
}

/**
 * Checks if element is or is inside of a modal
 */
export function isInsideModal(element: EventTarget): boolean {
  return isInsideOrIs(element, '.modal, [class^="chakra-modal"]');
}

type ARGS = Array<unknown>;
type CbFunc<ARGT extends ARGS> = (...args: ARGT) => void;
export function optionalCallback<ARGT extends ARGS>(
  cb: CbFunc<ARGT> | null | undefined
): CbFunc<ARGT> {
  return (...args: ARGT): void => {
    if (cb) {
      cb(...args);
    }
  };
}

export function uniqid(): string {
  return Math.random().toString(36).substring(2);
}

export function formatFileSize(sizeBytes: number): string {
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (!sizeBytes) {
    // A special case for empty files. I'm sure someone will try to
    // upload one, they always do that kind of stuff
    return '0B';
  }

  // Math.log is a natural logarithm, this will get it to base 1000
  const unitIndex = Math.floor(Math.log(sizeBytes) / Math.log(1000));
  const unit = units[unitIndex] || 'WTF?';

  return (sizeBytes / 1000 ** unitIndex).toFixed(1) + unit;
}

export type MemorySizeUnit =
  | 'B'
  | 'kB'
  | 'MB'
  | 'GB'
  | 'TB'
  | 'PB'
  | 'EB'
  | 'ZB'
  | 'YB';

export function formatFileSizeObject(sizeBytes: number): {
  size: number;
  unit: MemorySizeUnit;
} {
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (!sizeBytes) {
    // A special case for empty files. I'm sure someone will try to
    // upload one, they always do that kind of stuff inti je pao na "tko" foru
    // onak 4 puta za redom 03.01.2023.
    return { size: 0, unit: 'B' };
  }

  // Math.log is a natural logarithm, this will get it to base 1000
  const unitIndex = Math.floor(Math.log(sizeBytes) / Math.log(1000));
  const unit = units[unitIndex] as MemorySizeUnit;

  return { size: sizeBytes / 1000 ** unitIndex, unit };
}

export function sleep(delayMS: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, delayMS);
  });
}

export function arraySwap<T>(arr: T[], idx1: number, idx2: number): T[] {
  const copy = arr.slice();
  [copy[idx1], copy[idx2]] = [copy[idx2], copy[idx1]];

  return copy;
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export function basename(path: string): string {
  return path.replace(/^(?:.*?)([^/\\]+)$/, '$1');
}

type ParseQueryResult = Record<string, string | undefined>;
type ParseURLResult = urlParse<string> & { paramsObj: ParseQueryResult };

export function parseQueryString(queryStr: string): ParseQueryResult {
  return urlParse.qs.parse(queryStr);
}

export function parseURL(urlStr: string): ParseURLResult {
  const parsed = urlParse(urlStr);

  return {
    ...parsed,
    paramsObj: parseQueryString(parsed.query),
  };
}

type EntriesInput = Record<string | number | symbol, unknown>;
export function entries<O extends EntriesInput>(obj: O): ObjectEntries<O> {
  return Object.entries(obj) as ObjectEntries<O>;
}

export function roundTo(num: number, precision: number): number {
  const decimalFactor = 10 ** precision;

  return Math.round(num * decimalFactor) / decimalFactor;
}
