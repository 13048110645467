import { useCallback } from 'react';
import { Api } from 'BootQuery/Assets/js/api';

import { useDialerContext } from './DialerContext';
import { useCurrentCall } from './hooks';
import { dialerStore, getCurrentCall as getCurrentPbxCall } from './store/pbx';
import { canAccept, canConfirmTransfer, canHangup } from './util';
import { softphoneState } from './store/softphone';
import { getConferenceMembers } from '../../store/calls-zustand';

interface UseCurrentCallActionsResult {
  canHold: boolean;
  isOnHold: boolean;
  holdCall: () => void;
  unholdCall: () => void;
  canBlindTransfer: boolean;
  blindTransfer: () => void;
  canAttendedTransfer: boolean;
  attendedTransfer: () => void;
  canAddToConference: boolean;
  addToConference: () => Promise<void>;
  newCall: () => void;
  canHangup: boolean;
  hangup: () => void;
  canAccept: boolean;
  accept: () => void;
  canConfirmTransfer: boolean;
  confirmTransfer: () => void;
}

export function useCurrentCallActions(): UseCurrentCallActionsResult {
  const callInfo = useCurrentCall();
  const { selectedAction, setSelectedAction, setExpanded, focusInput } =
    useDialerContext();

  const currentPbxCall = dialerStore(getCurrentPbxCall);
  const currentDevice = dialerStore((state) => state.currentDevice);
  const ownNumber = dialerStore(
    (state) => state.user?.phoneNumber?.phoneNumberE164 ?? null
  );

  const noopAction = useCallback(() => {
    console.warn('Tried to execute call action without active call');
  }, []);
  const noopActionAsync = useCallback(async () => {
    console.warn('Tried to execute call action without active call');
  }, []);

  if (!callInfo) {
    return {
      canHold: false,
      isOnHold: false,
      holdCall: noopAction,
      unholdCall: noopAction,
      canBlindTransfer: false,
      blindTransfer: noopAction,
      canAttendedTransfer: false,
      attendedTransfer: noopAction,
      canAddToConference: false,
      addToConference: noopActionAsync,
      newCall: noopAction,
      canHangup: false,
      hangup: noopAction,
      canAccept: false,
      accept: noopAction,
      canConfirmTransfer: false,
      confirmTransfer: noopAction,
    };
  }

  let canHold: boolean;
  let isOnHold: boolean;
  let canBlindTransfer: boolean;
  let canAttendedTransfer: boolean;
  let canAddToConference: boolean;

  if (callInfo.type === 'softphone') {
    canHold = callInfo.call.state === 'inCall';
    isOnHold = callInfo.call.state === 'onHold';
    canBlindTransfer = callInfo.call.state === 'inCall';
    canAttendedTransfer = callInfo.call.state === 'inCall';
    canAddToConference = callInfo.call.state === 'inCall';
  } else {
    const state = callInfo.call.call.currentState?.callState ?? null;

    canHold = false;
    isOnHold = false;
    canBlindTransfer = state === 'up';
    canAttendedTransfer = false;
    canAddToConference = false;
  }

  const holdCall = () => {
    if (callInfo.type === 'softphone') {
      callInfo.call.hold();
    }
  };
  const unholdCall = () => {
    if (callInfo.type === 'softphone') {
      callInfo.call.unhold();
    }
  };
  const blindTransfer = () => {
    if (selectedAction === 'blind') {
      setSelectedAction(null);
    } else {
      setSelectedAction('blind');
      setExpanded(true);
      setTimeout(focusInput);
    }
  };
  const attendedTransfer = () => {
    if (selectedAction === 'attended') {
      setSelectedAction(null);
    } else {
      setSelectedAction('attended');
      setExpanded(true);
      setTimeout(focusInput);
    }
  };
  const newCall = () => {
    if (selectedAction === 'newCall') {
      setSelectedAction(null);
    } else {
      setSelectedAction('newCall');
      setExpanded(true);
      setTimeout(focusInput);
    }
  };
  const addToConference = async () => {
    setSelectedAction(null);
    if (!currentPbxCall || !currentDevice || !ownNumber) {
      return;
    }

    await Api.post(
      `/api/dialer/devices/${currentDevice.deviceId}/conference`,
      currentPbxCall.call.callId
    );

    // TODO: Check if this conference name thing needs to be more generic
    unholdOwnConference(`__${ownNumber}__`);
  };

  const hangup = () => {
    if (!callInfo) {
      return;
    }

    if (callInfo.type === 'softphone') {
      callInfo.call.end();
    } else {
      const ourSide = callInfo.call.connection === 'source' ? 'a' : 'b';
      Api.delete(`/api/dialer/calls/${callInfo.call.call.callId}/${ourSide}`);
    }
  };

  const canAcceptCurrent = callInfo ? canAccept(callInfo) : false;
  const accept = () => {
    if (!callInfo || !canAcceptCurrent) {
      return;
    }

    if (callInfo.type !== 'softphone') {
      return;
    }

    callInfo.call.answer();
  };

  const confirmTransfer = () => {
    if (callInfo?.type === 'softphone') {
      callInfo.call.confirmTransfer();
    }
  };

  return {
    canHold,
    isOnHold,
    holdCall,
    unholdCall,
    canBlindTransfer,
    blindTransfer,
    canAttendedTransfer,
    attendedTransfer,
    canAddToConference,
    addToConference,
    newCall,
    canHangup: canHangup(callInfo),
    hangup,
    canAccept: canAcceptCurrent,
    accept,
    canConfirmTransfer: callInfo ? canConfirmTransfer(callInfo) : false,
    confirmTransfer,
  };
}

function unholdOwnConference(conference: string) {
  const ownNumber =
    dialerStore.getState().user?.phoneNumber?.phoneNumberE164 ?? null;
  if (!ownNumber) {
    console.error('Can\'t determine own number to unhold conference');

    return;
  }

  const pbxCalls = dialerStore.getState().calls.map((call) => call.call);
  const conferenceMembers = getConferenceMembers(pbxCalls, conference);
  const ourCall = conferenceMembers.find(
    (member) => member.member.phoneNumber === ownNumber
  );

  if (ourCall) {
    const softphoneConf = softphoneState.getState().sessions.find((sess) => {
      return ourCall.member.sipCallId === sess.sipCallId;
    });
    softphoneConf?.unhold();
  }
}
