import { useMemo } from 'react';

import { makePhoneNumbersCell } from '@bq/components/Cells/PhoneNumbersCell';
import {
  customFormColumns,
  FieldValue,
} from 'BootQuery/Assets/components/FormEditor';
import { getFieldTypes } from 'BootQuery/Assets/components/FormEditor/field-types';
import { Column, makeSelectionCell } from 'BootQuery/Assets/components/Table';
import i18n from 'BootQuery/Assets/js/i18n';

import { EmailsCell } from '../../EmailsCell';
import { Permissions, PersonApiData } from '../../types';
import { PersonCardFeatures } from '../types';
import { CompanyCell } from './CompanyCell';

export interface PersonColumn extends Column<PersonApiData> {
  forCard?: boolean;
  cardDefault?: boolean;
}

export function columns(permissions: Partial<Permissions>): PersonColumn[] {
  const cols: PersonColumn[] = [
    makeSelectionCell(),
    {
      key: 'firstName',
      title: i18n.t('Phonebook:first_name'),
      sort: 'firstName',
    },
    {
      key: 'lastName',
      title: i18n.t('Phonebook:last_name'),
      sort: 'lastName',
    },
    {
      key: 'company',
      title: i18n.t('Phonebook:company'),
      Cell: CompanyCell,
      sort: 'company.name',
    },
    {
      key: 'phoneNumbers',
      title: i18n.t('Phonebook:phone_number'),
      Cell: makePhoneNumbersCell({
        getCellValue: (row) => {
          return row.phoneNumbers.map((num) => num.phoneNumber.phoneNumberE164);
        },
      }),
      forCard: true,
    },
    {
      key: 'emails',
      title: i18n.t('Phonebook:email_address'),
      Cell: EmailsCell,
      forCard: true,
    },
    {
      key: 'department',
      title: i18n.t('Phonebook:department'),
      defaultVisible: false,
      getValue: ({ row }) => row.department?.name ?? '-',
      forCard: true,
      cardDefault: false,
      sort: 'department.name',
    },
    {
      key: 'position',
      title: i18n.t('Phonebook:position'),
      getValue: ({ row }) => row.companyPosition?.name ?? '-',
      forCard: true,
      cardDefault: false,
      sort: 'companyPosition.name',
    },
    {
      key: 'companyLocation',
      title: i18n.t('Phonebook:company_location'),
      getValue: ({ row }) => row.companyLocation?.name ?? '-',
      forCard: true,
      cardDefault: false,
      sort: 'companyLocation.name',
    },
    {
      key: 'city',
      title: i18n.t('Phonebook:city'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.city?.name ?? '-',
    },
    {
      key: 'postalCode',
      title: i18n.t('Phonebook:postal_code'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.postalCode?.code ?? '-',
    },
    {
      key: 'country',
      title: i18n.t('Phonebook:country'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.country?.name ?? '-',
    },
    {
      key: 'street',
      title: i18n.t('Phonebook:street'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.street?.name ?? '-',
    },
    {
      key: 'streetNumber',
      title: i18n.t('Phonebook:street_number'),
      defaultVisible: false,
      getValue: ({ row }) => row.addresses[0]?.address?.streetNumber ?? '-',
    },
    {
      key: 'nationalNumber',
      title: i18n.t('Phonebook:national_number'),
      defaultVisible: false,
      getValue: ({ row }) => row.nationalNumber?.nationalNumber ?? '-',
      sort: 'nationalNumber.nationalNumber',
    },
  ];

  return cols.filter((col) => {
    if (col.key === 'nationalNumber') {
      return permissions.viewPersonNationalNumber ?? false;
    }

    return true;
  });
}

export function useTableColumns(
  permissions: Permissions,
  customFields: FieldValue[] = []
): Column<PersonApiData>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<PersonApiData>[];

    return [...columns(permissions), ...customColumns];
  }, [customFields, permissions]);
}

export function useCardColumns(
  permissions: Permissions,
  customFields: FieldValue[] = []
): Column<PersonApiData>[] {
  return useMemo(() => {
    const cardColumns = columns(permissions).filter((col) => col.forCard);
    const customColumns = customFormColumns(customFields, getFieldTypes());

    return [...cardColumns, ...(customColumns as unknown as PersonColumn[])];
  }, [customFields, permissions]);
}

interface SplitColumnFeatures {
  features: Partial<PersonCardFeatures>;
  columns: Column<PersonApiData>[];
}

const notColumns = ['phoneNumbers', 'emails'];
export function useSplitColumnsFeatures(
  permissions: Permissions,
  columns: Column<PersonApiData>[]
): SplitColumnFeatures {
  return useMemo(
    () => ({
      columns: columns.filter((col) => !notColumns.includes(col.key)),
      features: {
        phoneNumbers: columns.some((col) => col.key === 'phoneNumbers'),
        emails: columns.some((col) => col.key === 'emails'),
      },
    }),
    [columns]
  );
}

export const personCardFeatures = {
  $selected: 'select',
  firstName: 'firstName',
  lastName: 'lastName',
  company: 'company',
  emails: 'emails',
  phoneNumbers: 'phoneNumbers',
};
