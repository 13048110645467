import Parchment from 'parchment';

class Template extends Parchment.Embed {
  static create(value) {
    if (typeof value === 'string') {
      value = {
        label: value,
        name: value,
      };
    }

    const node = super.create(value);
    const labelNode = document.createTextNode(value.label);
    const wrapper = document.createElement('span');
    wrapper.classList.add('editor-template-placeholder');
    wrapper.setAttribute('contenteditable', 'false');
    node.dataset.label = value.label;
    node.dataset.name = value.name;
    wrapper.appendChild(labelNode);
    node.appendChild(wrapper);

    return node;
  }

  static value(node) {
    return node.dataset;
  }

  length() {
    return 1;
  }
}

Template.blotName = 'template';
Template.tagName = 'x-template-tag';

export default Template;
