import { Select } from 'chakra-react-select';
import { useMemo } from 'react';

export interface FilterOption {
  label: string;
  value: number;
}
interface Props {
  onChange: (newValue: string) => void;
  value: FilterOption | string | null;
}

export const MailTypeSelect = ({ onChange, value }: Props) => {
  const options = useMemo(
    () => [
      {
        value: 'none',
        label: 'Mail subject',
      },
      {
        value: 'idLeft',
        label: '[Ticket#1234] Mail subject',
      },
      {
        value: 'idRight',
        label: 'Mail subject [Ticket#1234]',
      },
    ],
    []
  );
  const findOption = useMemo(
    () => options.find((item) => item.value === value),
    [options, value]
  );

  return (
    <Select
      useBasicStyles
      size="sm"
      options={options}
      value={findOption ?? { label: 'Mail subject', value: 'none' }}
      onChange={(newVal) => {
        onChange(newVal?.value ?? 'none');
      }}
      selectedOptionColorScheme="brand"
    />
  );
};
