import { useCallback, useEffect, useState } from 'react';

import { Api } from 'BootQuery/Assets/js/api';

import { ContactInfo } from '../../js/number-contact-info-resolver';
import { CallAndConnection } from './connected-calls';
import { getContactForNumber } from './get-contact-for-number';
import { dialerStore, getCurrentCall as getCurrentPbxCall } from './store/pbx';
import {
  getCurrentCall as getCurrentSoftphoneCall,
  softphoneState,
} from './store/softphone';
import { CallDisplayProps, SessionInterface } from './types';
import { getCallLeg, getIsRecording } from './util';

export function useIsWebrtc(): boolean {
  const device = dialerStore((state) => state.currentDevice);
  const protocol = device?.pbxTransport?.protocol ?? null;

  return protocol ? ['ws', 'wss'].includes(protocol) : false;
}

export function useCurrentCall(): CallDisplayProps | null {
  const isWebrtc = useIsWebrtc();
  const currentWebrtcCall: SessionInterface | null = softphoneState(
    getCurrentSoftphoneCall
  );
  const currentPbxCall = dialerStore(getCurrentPbxCall);

  if (currentWebrtcCall) {
    return {
      type: 'softphone',
      call: currentWebrtcCall,
    };
  }

  if (!isWebrtc && currentPbxCall) {
    return {
      type: 'pbx',
      call: currentPbxCall,
    };
  }

  return null;
}

export function useOtherCalls(
  activeCall: CallDisplayProps | null
): CallDisplayProps[] {
  // State from softphone
  const device = dialerStore((state) => state.currentDevice);
  const isWebrtc = useIsWebrtc();

  const webrtcSessionId =
    activeCall?.type === 'softphone' ? activeCall.call.sessionId : null;
  const pbxCallId =
    activeCall?.type === 'pbx' ? activeCall.call.call.callId : null;

  const webrtcCalls = softphoneState((state) => {
    return state.sessions.filter((sess) => sess.sessionId !== webrtcSessionId);
  });
  const otherPbxCalls = dialerStore((state) => {
    return state.calls
      .filter((call) => call.deviceId !== device?.deviceId)
      .filter((call) => call.call.callId !== pbxCallId);
  });

  // State from webrtc
  if (activeCall?.type === 'softphone' || (activeCall === null && isWebrtc)) {
    return webrtcCalls.map((call) => ({ call, type: 'softphone' }));
  }

  // State from PBX
  return otherPbxCalls.map((call) => ({ call, type: 'pbx' }));
}

interface ContactInfoResult {
  loaded: boolean;
  contact: ContactInfo | null;
}

export function useContactForNumber(
  phoneNumber: string | null
): ContactInfoResult {
  const [loaded, setLoaded] = useState<boolean>(true);
  const [contact, setContact] = useState<ContactInfo | null>(null);

  useEffect(() => {
    if (!phoneNumber) {
      setLoaded(true);
      setContact(null);

      return;
    }

    const fetchContact = async () => {
      setLoaded(false);
      const contactInfo = await getContactForNumber(phoneNumber);
      setContact(contactInfo);
      setLoaded(true);
    };
    fetchContact();
  }, [phoneNumber]);

  return { loaded, contact };
}

export interface UseRecordingResult {
  isRecording: boolean;
  canRecord: boolean;
  toggleRecording: () => void;
}

export function useRecording(
  pbxCall: CallAndConnection | null
): UseRecordingResult {
  const pbxCallId = pbxCall?.call?.callId ?? null;
  const callLeg = pbxCall ? getCallLeg(pbxCall) : null;
  const currentState = pbxCall?.call.currentState?.callState;
  const canRecord = currentState === 'up' || currentState === 'hold';

  const toggleRecording = useCallback(() => {
    if (!pbxCallId || !callLeg || !canRecord) {
      console.warn('Ignoring recording toggle request', {
        pbxCallId,
        callLeg,
        canRecord,
      });

      return;
    }

    Api.post(`/api/telephony/calls/${pbxCallId}/record`, {
      callLeg,
      mode: 'toggle',
    });
  }, [pbxCallId, callLeg, canRecord]);

  const isRecording = pbxCall ? getIsRecording(pbxCall) : false;

  return {
    isRecording,
    canRecord,
    toggleRecording,
  };
}
