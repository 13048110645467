import { ReactNode } from 'react';
import { BaseHeadingProps, HeadingProps } from '../ListHeading';
import { OBJ } from '../Table/types';
import { DisplayModeOption, defaultDisplayModeOptions } from '../TableMenu';
import { UseListingSettingsReturn } from './types';

/**
 * packs return from useListingSettings into a format usable
 * in listing heading.
 * IMPORTANT: PASS ACTIONS MANUALLY, menu children are passed
 * here to avoid destructuring deep props
 * @param listingProps - return from useListingSettings
 * @param menuChildren - menu children, search the codebase
 * for examples, e.g. custom settings like SMS sending methods
 * @param noPagination - should pagination be showed or not
 * @returns
 */
export const useHeadingProps = <ColumnType extends OBJ>({
  listingProps,
  menuChildren,
  noPagination = false,
  count,
  limits,
  displayModeOptions,
}: {
  listingProps: UseListingSettingsReturn<ColumnType>;
  menuChildren?: ReactNode;
  noPagination?: boolean;
  count: number | null;
  limits?: number[];
  displayModeOptions?: DisplayModeOption[];
}): HeadingProps => {
  const {
    allColumns,
    density,
    displayMode,
    filterTypes,
    filterValues,
    search,
    setDensity,
    setDisplayMode,
    setFilterValues,
    setSearch,
    setVisibleColumns,
    visibleColumns,
    limit,
    page,
    setPage,
    setLimit,
  } = listingProps;

  const baseProps: BaseHeadingProps = {
    count,
    filterProps: {
      filterTypes,
      filters: filterValues,
      setFilters: setFilterValues,
      search,
      setSearch,
    },
    menuProps: {
      visibleColumns: {
        columns: allColumns,
        value: visibleColumns,
        onChange: setVisibleColumns,
      },
      density: { value: density, onChange: setDensity },
      displayMode: {
        value: displayMode,
        onChange: setDisplayMode,
        options: displayModeOptions ?? defaultDisplayModeOptions,
      },
      limit: { value: limit, onChange: setLimit, limits },
      children: menuChildren,
    },
  };

  // This is kept to be consistend with types, but not really needed
  return noPagination
    ? { ...baseProps, noPagination: true, limit, page, setPage }
    : { ...baseProps, noPagination: false, limit, page, setPage };
};
