module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hidden listform-row-hidden-editable";
},"3":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"5":function(container,depth0,helpers,partials,data) {
    return " col-sm-9";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"hidden\" name=\""
    + alias2(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),(depth0 != null ? lookupProperty(depth0,"$rowIndex") : depth0),"$id",{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":5,"column":43},"end":{"line":5,"column":91}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"editable") : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":93},"end":{"line":5,"column":132}}})) != null ? stack1 : "")
    + " value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"$id") : depth0), depth0))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"form-list-row-field "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sizeClass") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":8,"column":48},"end":{"line":8,"column":106}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minWidth") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":108},"end":{"line":8,"column":163}}})) != null ? stack1 : "")
    + ">\n                    <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"editable") : depths[1]),{"name":"unless","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.program(19, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":14,"column":35}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"sizeClass") : depth0), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    return "col-12 col-sm";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "style=\"min-width: "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"minWidth") : depth0), depth0))
    + ";\"";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),depth0,{"name":"form","hash":{"plain":(depths[1] != null ? lookupProperty(depths[1],"plain") : depths[1]),"disabled":true,"nested":true,"parentPath":lookupProperty(helpers,"array_to_path").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depths[1] != null ? lookupProperty(depths[1],"$rowIndex") : depths[1]),{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":11,"column":52},"end":{"line":11,"column":91}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),depth0,{"name":"form","hash":{"plain":(depths[1] != null ? lookupProperty(depths[1],"plain") : depths[1]),"nested":true,"parentPath":lookupProperty(helpers,"array_to_path").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depths[1] != null ? lookupProperty(depths[1],"$rowIndex") : depths[1]),{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":13,"column":52},"end":{"line":13,"column":91}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":50,"column":15}}})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-xs-12 col-sm-3\">\n        <div class=\"row\">\n            <label class=\"col-12 col-form-label\">&zwj;</label>\n            <div class=\"col-12\">\n                <div class=\"btn-group btn-block\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"$is_new_row") : depth0),{"name":"unless","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":45,"column":31}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n        </div>\n    </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEditableReplacedRow") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":28,"column":24},"end":{"line":36,"column":31}}})) != null ? stack1 : "")
    + "                        <button type=\"button\" class=\"listform-button-remove btn btn-danger col-6\" name=\""
    + alias2(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),(depth0 != null ? lookupProperty(depth0,"$rowIndex") : depth0),"$deleteBtn",{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":37,"column":104},"end":{"line":37,"column":159}}}))
    + "\">\n                            <input hidden type=\"checkbox\" name=\""
    + alias2(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),(depth0 != null ? lookupProperty(depth0,"$rowIndex") : depth0),"$deleted",{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":38,"column":64},"end":{"line":38,"column":117}}}))
    + "\" value=true>\n                            <span class=\"fa fa-trash-alt\"></span>\n                        </button>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "                            <button type=\"button\" class=\"listform-button-cancel-edit btn btn-secondary col-6\">\n                                <span class=\"fa fa-times\"></span>\n                            </button>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <button type=\"button\" class=\"listform-button-edit btn btn-primary col-6\" name=\""
    + container.escapeExpression(lookupProperty(helpers,"array_to_path").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),(depth0 != null ? lookupProperty(depth0,"$rowIndex") : depth0),"$editBtn",{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":33,"column":107},"end":{"line":33,"column":160}}}))
    + "\">\n                                <span class=\"fa fa-pencil-alt\"></span>\n                            </button>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "                        <button type=\"button\" class=\"listform-button-newrow-remove btn btn-danger col-6 pull-right\">\n                            <span class=\"fa fa-trash-alt\"></span>\n                        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row listform-row listform-row-form "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_hidden_editable_row") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":119}}})) != null ? stack1 : "")
    + "\" data-listform-row-id=\""
    + container.escapeExpression(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),(depth0 != null ? lookupProperty(depth0,"$rowIndex") : depth0),"$id",{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":1,"column":143},"end":{"line":1,"column":191}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_hidden_editable_row") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":193},"end":{"line":1,"column":236}}})) != null ? stack1 : "")
    + ">\n    <div class=\"col-12"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":59}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"$id") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":6,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"fields") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":17,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":51,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true})