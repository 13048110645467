import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Message } from '../../../js/types';
import { MessageActionsContext } from '../../Message';
import { MailEditorsContext } from './MailEditorsContext';
import {
  ForwardHandler,
  MailEditor,
  MailEditorMap,
  ReplyHandler,
  SentHandler,
} from './types';
import { useMailActions } from './use-mail-actions';

interface MailEditorProviderProps {
  onReply?: ReplyHandler;
  onForward?: ForwardHandler;
  onSent?: SentHandler;
}

type Props = PropsWithChildren<MailEditorProviderProps>;

export const MailEditorsProvider = ({
  children,
  onReply,
  onForward,
  onSent,
}: Props): ReactElement => {
  const [mailEditors, setMailEditors] = useState<MailEditorMap>({});

  const addEditor = useCallback((editor: MailEditor) => {
    setMailEditors((prev) => ({ ...prev, [editor.editorID]: editor }));
  }, []);

  const removeEditor = useCallback((editorID: string) => {
    setMailEditors((prev) => {
      const newState = { ...prev };
      delete newState[editorID];

      return newState;
    });
  }, []);

  const actions = useMailActions(addEditor);

  const messageActions = useMemo(
    () => ({
      reply: (message: Message) => {
        if (onReply) {
          onReply('sender', message, actions);
        } else {
          actions.replyMail('sender', message);
        }
      },
      replyAll: (message: Message) => {
        if (onReply) {
          onReply('all', message, actions);
        } else {
          actions.replyMail('all', message);
        }
      },
      forward: (message: Message) => {
        if (onForward) {
          onForward(message, actions);
        } else {
          actions.forwardMail(message);
        }
      },
    }),
    [actions, onForward, onReply]
  );

  return (
    <MailEditorsContext.Provider
      value={{ mailEditors, setMailEditors, addEditor, removeEditor, onSent }}
    >
      <MessageActionsContext.Provider value={messageActions}>
        {children}
      </MessageActionsContext.Provider>
    </MailEditorsContext.Provider>
  );
};
