import { ReactElement } from 'react';

import { FilterEditor, FilterValue } from '@bq/components/FilterBar';
import { FieldValue } from '@bq/components/FormEditor';

import { useFilterTypes } from '../CallList/filter-types';
import { UseCallFiltersResult } from './api';

interface Props extends Omit<UseCallFiltersResult, 'filters'> {
  filters: FilterValue[];
  customFields: FieldValue[];
}

export const OperatorCallFilters = ({
  filters,
  setFilters,
  customFields,
}: Props): ReactElement => {
  const filterTypes = useFilterTypes(customFields);

  return (
    <FilterEditor
      filterTypes={filterTypes}
      onChange={setFilters}
      value={filters}
    />
  );
};
