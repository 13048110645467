import { useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';

import { CaseSubject } from '../../../../schemas';

interface Props {
  isDisabled?: boolean;
  value?: CaseSubject | null;
  onChange: (val: CaseSubject | null) => void;
  error?: FieldError;
  isClearable?: boolean;
  allowedTypes?: Record<ContactType, boolean>;
}

export const SubjectFormField = ({
  onChange,
  error,
  value,
  allowedTypes,
  isDisabled,
  isClearable,
}: Props) => {
  const contactItemToVal = useCallback(
    (value: ContactItem | null): CaseSubject | null => {
      if (!value) {
        return null;
      }
      if ('$new' in value) {
        throw Error('Cant create new value');
      }
      if (value.type === 'person') {
        return { type: 'person', name: value.name, id: value.ID };
      }
      if (value.type === 'company') {
        return { type: 'company', name: value.name, id: value.ID };
      }
      // TODO: DATASET
      throw Error('Unknown value type');
    },
    []
  );
  const valueToContactItem = useCallback(
    (value: CaseSubject | undefined | null): ContactItem | null => {
      if (!value) {
        return null;
      }
      if (value.type === 'company') {
        return { ID: value.id, name: value.name, type: 'company' };
      }
      if (value.type === 'person') {
        return { ID: value.id, name: value.name, type: 'person' };
      }
      // TODO: DATASET
      throw Error('Unknown value type');
    },
    []
  );
  const val = useMemo(
    () => valueToContactItem(value),
    [value, valueToContactItem]
  );

  return (
    <ContactSelect
      hasDetails
      isDisabled={isDisabled}
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
      isMulti={false}
      onChange={(val) => {
        onChange(contactItemToVal(val));
      }}
      isInvalid={!!error?.message}
      value={val}
      allowedTypes={allowedTypes}
      createMode="none"
      isClearable={isClearable}
    />
  );
};
