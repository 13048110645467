import { ReactElement, useCallback } from 'react';
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonRadio, OptionType } from '@bq/components/ButtonRadio';
import { QuillField } from 'BootQuery/Assets/js/quill/QuillField';

import { MailTemplate } from './types';

export const ContentField = (): ReactElement => {
  const { t } = useTranslation('Mails');
  const {
    control,
    formState: { errors },
  } = useFormContext<Partial<MailTemplate>>();

  const {
    field: { value: contentHTML, onChange: setContentHTML },
  } = useController({
    control,
    name: 'contentHTML',
  });

  const {
    field: { value: isRaw, onChange: setIsRaw },
  } = useController({
    control,
    name: 'isRaw',
  });

  const mode = isRaw ? 'html' : 'editor';
  const setMode = useCallback(
    (mode: string) => {
      setIsRaw(mode === 'html');
    },
    [setIsRaw]
  );

  return (
    <FormControl isInvalid={!!errors.contentHTML}>
      <FormLabel htmlFor="contentHTML">{t('Mails:template_content')}</FormLabel>
      <ButtonRadio
        name="mode"
        options={modeOptions}
        value={mode}
        onChange={setMode}
      />
      {isRaw ? (
        <Textarea value={contentHTML ?? ''} onChange={setContentHTML} />
      ) : (
        <QuillField value={contentHTML ?? ''} onChange={setContentHTML} />
      )}
    </FormControl>
  );
};

const modeOptions: OptionType[] = [
  { id: 'editor', title: 'Editor' },
  { id: 'html', title: 'HTML' },
];
