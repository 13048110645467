import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FooterButtons } from './FooterButtons';
import { InterestLevelFormFields } from './InterestLevelFormFields';
import { InterestLevelFormWrapper } from './InterestLevelFormWrapper';
import { InterestLevelFormData } from './types';

export const InterestLevelModal = (props: InterestLevelFormData) => {
  const { t } = useTranslation('Sales');

  return (
    <InterestLevelFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Sales:create_interest_level')
          : t('Sales:edit_interest_level')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <InterestLevelFormFields />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </InterestLevelFormWrapper>
  );
};
