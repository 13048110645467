import { useMemo } from 'react';

import { Column } from '@bq/components/Table';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import i18n from 'BootQuery/Assets/js/i18n';

import { ActionsCell } from './cells/ActionsCell';
import { DatasetListItem } from './schema';

export function columns(): Column<DatasetListItem>[] {
  return [
    {
      key: 'name',
      title: i18n.t('global:name'),
    },
    {
      key: 'createdAt',
      title: i18n.t('global:created_at'),
      getValue: ({ row }) => formatDate(row.creationDate, DateType.DateTime),
    },
    {
      tdProps: { py: 0 },
      key: 'action',
      width: '160px',
      title: '',
      Cell: ActionsCell,
    },
  ];
}

export function useColumns(): Column<DatasetListItem>[] {
  return useMemo(columns, []);
}
