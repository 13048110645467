import { FC, useCallback, useState } from 'react';
import { chakra } from '@chakra-ui/react';
import { ColorResult, SketchPicker } from 'react-color';

export interface ColorPickerProps {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
}

export const ColorPickerWrapped: FC<ColorPickerProps> = ({
  value,
  onChange,
  ...props
}) => {
  const [color, setColor] = useState(value);
  const handleChange = useCallback((value: ColorResult) => {
    setColor(value.hex);
  }, []);
  const handleChangeComplete = useCallback(
    (value: ColorResult) => {
      setColor(value.hex);
      if (onChange) {
        onChange(value.hex);
      }
    },
    [onChange]
  );

  return (
    <SketchPicker
      color={color}
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
      {...props}
    />
  );
};

export const ColorPicker = chakra(ColorPickerWrapped);
