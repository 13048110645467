import { getDefaultComboboxOperators } from '@bq/components/FilterBar/filters/ComboboxFilter';
import {
  makeUserFilter,
  userFilterIDExpr,
} from '@bq/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const assignedToUserFilter = makeUserFilter({
  name: () => i18n.t('Sales:case.assigned_to'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return {
        $or: {
          [`assignedToUser.username:${containsOp}:ci`]: value,
          [`assignedToUser.person.fullName:${containsOp}:ci`]: value,
        },
      };
    }

    const userID = userFilterIDExpr(value);

    return { [`assignedToUser.ID:${operator ?? 'eq'}`]: userID };
  },
});
