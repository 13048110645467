import { makeContactFilter } from '@bq/components/FilterBar/filters/ContactFilter';
import i18n from 'BootQuery/Assets/js/i18n';
import { upperFirst } from 'lodash-es';

export const offererFilter = makeContactFilter({
  allowedContactTypes: {
    company: true,
    person: true,
    user: false,
    companyDepartment: false,
    companyLocation: false,
  },
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return {
        $or: {
          'offererCompany.name:contains:ci': value,
          'offererPerson.fullName:contains:ci': value,
        },
      };
    }
    if (typeof value === 'object') {
      const { type, ID } = value;

      return {
        [`offerer${upperFirst(type)}.ID`]: ID,
      };
    }

    return null;
  },
  name: () => i18n.t('Sales:offerer'),
  operators: [],
});
