import { ReactElement } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  StackProps,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ImportSetupForm } from '../types';
import { CharsetSelect } from './CharsetSelect';

export const CsvSettingsFields = (props: StackProps): ReactElement => {
  const { t } = useTranslation('Datasets');
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ImportSetupForm>();

  const { field: encodingField } = useController({
    control,
    name: 'csvOptions.encoding',
  });

  return (
    <HStack {...props}>
      <FormControl flex="1 1 50%" isInvalid={!!errors.csvOptions?.encoding}>
        <FormLabel fontWeight="bold">{t('Datasets:csv_encoding')}:</FormLabel>
        <CharsetSelect
          value={encodingField.value}
          onChange={encodingField.onChange}
        />
        {errors.csvOptions?.encoding && (
          <FormErrorMessage>
            {errors.csvOptions.encoding.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl flex="1 1 25%" isInvalid={!!errors.csvOptions?.delimiter}>
        <FormLabel fontWeight="bold">{t('Datasets:csv_delimiter')}:</FormLabel>
        <Input
          {...register('csvOptions.delimiter', { required: true })}
          isInvalid={!!errors.csvOptions?.delimiter}
          placeholder={t('Datasets:csv_delimiter')}
        />
        {errors.csvOptions?.delimiter && (
          <FormErrorMessage>
            {errors.csvOptions.delimiter.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl flex="1 1 25%" isInvalid={!!errors.csvOptions?.enclosure}>
        <FormLabel fontWeight="bold">{t('Datasets:csv_enclosure')}:</FormLabel>
        <Input
          {...register('csvOptions.enclosure', { required: true })}
          isInvalid={!!errors.csvOptions?.enclosure}
          placeholder={t('Datasets:csv_enclosure')}
        />
        {errors.csvOptions?.enclosure && (
          <FormErrorMessage>
            {errors.csvOptions.enclosure.message}
          </FormErrorMessage>
        )}
      </FormControl>
    </HStack>
  );
};
