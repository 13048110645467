import {
  FieldValue,
  useCustomFormColumns,
} from 'BootQuery/Assets/components/FormEditor';
import { Column } from 'BootQuery/Assets/components/Table';
import i18next from 'BootQuery/Assets/js/i18n';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';
import { useMemo } from 'react';
import { CallActionCell } from './CallActionCell';
import { CallDestinationCell } from './CallDestinationCell';
import { CallSourceCell } from './CallSourceCell';
import { CallStatusCell } from './CallStatusCell';
import { CallListCall } from './types';

import { q850Causes } from '../../js/q850-causes';
import { CallDurationCell } from './CallDurationCell';
import { makeWrappedCallCell } from './WrappedCallCell';
import { CallTagsCell } from './CallTagsCell';
import { useCallListSettings, ICallListSettings } from './CallListSettingsContext';
import { formatDuration } from '../util';

const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Ppp', { locale: hr });
};

const hasTicketing = () => 'Ticketing' in window.Bootstrap.modules;

type CallCell = Column<CallListCall>;

export function columns(settings: ICallListSettings): CallCell[] {
  return [
    {
      key: 'callStatus',
      title: '',
      Cell: CallStatusCell,
      width: '62px',
      minWidth: 62,
    },
    {
      key: 'startAt',
      title: i18next.t('Telephony:columns.call_start'),
      getValue: ({ row }) => formatDate(row.startAt),
    },
    {
      key: 'endAt',
      title: i18next.t('Telephony:columns.call_end'),
      getValue: ({ row }) => formatDate(row.endAt),
      defaultVisible: false,
    },
    {
      key: 'src',
      title: i18next.t('Telephony:columns.call_from'),
      Cell: CallSourceCell,
    },
    {
      key: 'dst',
      title: i18next.t('Telephony:columns.call_to'),
      Cell: CallDestinationCell,
    },
    {
      key: 'queue',
      title: i18next.t('Telephony:columns.queue'),
    },
    {
      key: 'duration_total',
      title: i18next.t('Telephony:columns.duration_total'),
      Cell: CallDurationCell,
    },
    {
      key: 'duration_talk',
      title: i18next.t('Telephony:columns.duration_talk'),
      getValue: ({ row }) => formatDuration(row.talkTime),
      defaultVisible: false,
    },
    {
      key: 'dialedNumber',
      title: i18next.t('Telephony:columns.raw_dialed_number'),
      defaultVisible: false,
    },
    {
      key: 'hangupCause',
      title: i18next.t('Telephony:columns.hangup_cause'),
      defaultVisible: false,
      getValue: ({ row }: { row: CallListCall }): string => {
        if (!row.hangupCause) {
          return '-';
        }

        return q850Causes[row.hangupCause] ?? `Q850: ${row.hangupCause}`;
      },
    },
    {
      key: 'tags',
      title: i18next.t('Telephony:columns.tags'),
      defaultVisible: false,
      Cell: CallTagsCell,
      tdProps: { py: 0 },
    },
    {
      key: 'fromCountry',
      title: i18next.t('Telephony:columns.from_country'),
      defaultVisible: false,
      getValue: ({ row }) => row.sourcePhonePoint.phonePoint.trunkPoint?.countryISO ?? '-',
    },
    {
      key: 'callExtra',
      title: '',
      Cell: CallActionCell,
      tdProps: { py: 0, px: 1, textAlign: 'center' },
      ...extraColumnWidths(settings),
    },
  ];
}

export function useTableColumns(customFields: FieldValue[] = []): CallCell[] {
  const settings = useCallListSettings();
  const customColumns = useCustomFormColumns(
    customFields
  ) as unknown as CallCell[];
  const wrappedCustomColumns = useMemo(
    () => customColumns.map(
      (column): CallCell => ({
        ...column,
        Cell: makeWrappedCallCell(column),
        tdProps: { px: 0, py: 0 },
      })
    ),
    [customColumns]
  );

  return useMemo(() => {
    const normalColumns = columns(settings);
    const callExtraIdx = normalColumns.findIndex(
      (col) => col.key === 'callExtra'
    );

    return [
      ...normalColumns.slice(0, callExtraIdx),
      ...wrappedCustomColumns,
      ...normalColumns.slice(callExtraIdx),
    ];
  }, [wrappedCustomColumns, settings]);
}

interface ExtraColumnWidths {
  minWidth: number;
  width: string;
}

function extraColumnWidths(settings: ICallListSettings): ExtraColumnWidths {
  let baseWidth = 84;
  if (hasTicketing()) {
    baseWidth += 38;
  }

  if (settings.enabledRecordingIndicators.audio) {
    baseWidth += 24;
  }
  if (settings.enabledRecordingIndicators.video) {
    baseWidth += 24;
  }
  if (settings.enabledRecordingIndicators.voicemail) {
    baseWidth += 24;
  }

  return {
    width: `${baseWidth}px`,
    minWidth: baseWidth,
  };
}
