import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';

import { Campaign } from '../../schemas';
import { AddCampaignButton } from './AddCampaignButton';
import { CaseCampaignItem } from './CaseCampaignItem';
import { useCampaigns } from './use-campaings';

export const CaseCampaigns = () => {
  const { data: campaigns } = useCampaigns();

  if (!campaigns) {
    return <LoadingPage />;
  }

  return <CaseCampaignsContent defaultCampaigns={campaigns.data} />;
};

interface ContentProps {
  defaultCampaigns: Campaign[];
}

export const CaseCampaignsContent = ({ defaultCampaigns }: ContentProps) => {
  const modifiedCampaigns = defaultCampaigns?.map((campaign) => ({
    ...campaign,
    caseTypeID: campaign.ID,
  }));

  const [modifiedStates, modify] = useSettingsCrud<Campaign>({
    parent: '#settings-form',
    inputName: 'sales[campaigns]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: modifiedCampaigns,
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedStates.map((campaign) => (
          <CaseCampaignItem
            key={campaign.ID}
            campaign={campaign}
            modify={modify}
          />
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <AddCampaignButton modify={modify} />
      </Flex>
    </>
  );
};
