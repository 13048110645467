import { FilterTypes } from 'BootQuery/Assets/components/FilterBar';
import { makeBooleanFilter } from 'BootQuery/Assets/components/FilterBar/filters/Boolean';
import { makeContactFilter } from 'BootQuery/Assets/components/FilterBar/filters/ContactFilter';
import {
  makeDateFilter,
  makeDateToFilter,
} from 'BootQuery/Assets/components/FilterBar/filters/DateFilter';
import { numberFilterExpression } from 'BootQuery/Assets/components/FilterBar/filters/PhoneNumberFilter';
import { makeTextFilter } from 'BootQuery/Assets/components/FilterBar/filters/TextFilter';
import { makeTimeFilter } from 'BootQuery/Assets/components/FilterBar/filters/TimeFilter';
import {
  makeUserFilter,
  userFilterIDExpr,
} from 'BootQuery/Assets/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { makeSendingAccountFilter } from './filters/sending-account-filter';

function timezoneOffsetStr(): string {
  const offset = -new Date().getTimezoneOffset();
  const sign = offset < 0 ? '-' : '+';
  const absOffset = Math.abs(offset);

  const hours = Math.trunc(absOffset / 60);
  const minutes = absOffset % 60;

  const hoursStr = hours.toString().padStart(2, '0');
  const minuteStr = minutes.toString().padStart(2, '0');

  return `${sign}${hoursStr}:${minuteStr}`;
}

const startDate = makeDateFilter({
  name: () => i18n.t('Sms:filters.last_message_date'),
  toFilter: makeDateToFilter('lastMessageAt'),
});

const startTime = makeTimeFilter({
  name: () => i18n.t('Sms:filters.last_message_time'),
  toFilter: ({ operator, value }) => {
    if (!value) {
      return null;
    }

    return {
      [`lastMessageAt:${operator}:time`]: `${value}${timezoneOffsetStr()}`,
    };
  },
});

const phoneNumber = makeTextFilter({
  name: () => i18n.t('global:phone_number'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return {
      'parties:$any': numberFilterExpression(
        value,
        operator ?? 'eq',
        'party.phoneNumber.phoneNumberE164'
      ),
    };
  },
});

const contact = makeContactFilter({
  toFilter: async ({ value }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }
    const allNums = [
      ...(value.phoneNumbers ?? []),
      ...(value.person?.phoneNumbers ?? []),
    ];
    if (!allNums.length) {
      return null;
    }
    const phoneNumbers = allNums.map((num) => num.phoneNumber.phoneNumberE164);

    return {
      'parties:$any': {
        'party.phoneNumber.phoneNumberE164:inArray': phoneNumbers,
      },
    };
  },
});

const seen = makeBooleanFilter({
  name: () => i18n.t('Sms:filters.seen'),
  toFilter: ({ value }) => {
    const hasUnseen = {
      'messages:$any': { seen: false },
    };

    return value ? { $not: hasUnseen } : hasUnseen;
  },
});

const messageContent = makeTextFilter({
  name: () => i18n.t('Sms:filters.message_content'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return {
      'messages:$any': {
        [`content:${operator ?? 'contains'}:ci`]: value,
      },
    };
  },
});

const sendingAccount = makeSendingAccountFilter({
  toFilter: ({ value }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    return value.type === 'group'
      ? { 'group.name': value.name }
      : { 'account.senderId': value.phoneNumber };
  },
});

const senderUser = makeUserFilter({
  name: () => i18n.t('Sms:filters.sender_user'),
  toFilter: ({ value }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    const userID = userFilterIDExpr(value);

    return {
      'messages:$any': {
        sentByUserID: userID,
      },
    };
  },
});

export const filterTypes: FilterTypes = {
  startDate,
  startTime,
  phoneNumber,
  contact,
  seen,
  messageContent,
  sendingAccount,
  senderUser,
};
