import { ReactElement, useContext } from 'react';
import { get } from 'lodash-es';

import { Product } from '@bq/components/FormFields/Product';

import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';
import { GenericStaticFormField } from '../GenericStaticFormField';

export const StaticProductField = ({
  id,
  settings,
}: FieldValue): ReactElement => {
  const { formData } = useContext(StaticFormContext);
  const value = get(formData, id) as Product | null;

  return (
    <GenericStaticFormField title={settings.name} value={value?.name ?? '-'} />
  );
};
