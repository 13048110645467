import { useCallback } from 'react';
import { useModal } from '@bq/components/UseModal/use-modal';
import { CompanyDepartmentModalContent } from './CompanyDepartmentModalContent';
import { EditCompanyDepartmentModalContent } from './EditCompanyDepartment';
import {
  CreateCompanyDepartmentModal,
  EditCompanyDepartmentModal,
} from './types';
import { CompanyCallbackData } from '../CompanyModal/types';

export const useCompanyDepartmentModal = () => {
  const { addModal } = useModal();

  const createCompanyDepartmentModal: CreateCompanyDepartmentModal =
    useCallback(
      (closeCallback, defaults) => {
        addModal<CompanyCallbackData>({
          callback: (data) => {
            closeCallback(data);
          },
          componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
          children: (
            <CompanyDepartmentModalContent mode="create" data={defaults} />
          ),
        });
      },
      [addModal]
    );
  const editCompanyDepartmentModal: EditCompanyDepartmentModal = useCallback(
    (ID, closeCallback) => {
      addModal<CompanyCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <EditCompanyDepartmentModalContent ID={ID} />,
      });
    },
    [addModal]
  );

  return { createCompanyDepartmentModal, editCompanyDepartmentModal };
};
