import { useLocation, useNavigate } from 'react-router-dom';
import { parse as parseQuery, stringify as stringifyQuery } from 'qs';
import { useCallback, useMemo } from 'react';
import { UsePageResult } from './types';

export function useQueryPage(): UsePageResult {
  const navigate = useNavigate();
  const location = useLocation();
  const page = useMemo(
    () => pageFromSearch(location.search),
    [location.search]
  );
  const setPage = useCallback((page: number) => {
    const prevSearch = parseQuery(
      location.search,
      { ignoreQueryPrefix: true }
    );
    navigate({
      ...location,
      search: stringifyQuery(
        { ...prevSearch, page },
        { addQueryPrefix: false }
      ),
    });
  }, [navigate, location]);

  return [page, setPage];
}

function pageFromSearch(search: string): number {
  const query = parseQuery(search, { ignoreQueryPrefix: true });
  if (typeof query === 'string' || typeof query.page !== 'string') {
    return 1;
  }

  const page = parseInt(query.page, 10);

  return Number.isNaN(page) ? 1 : page;
}
