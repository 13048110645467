import { Flex, Text } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { ReactElement, useContext } from 'react';
import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';
import { TagList } from './TagList';
import { TagFieldSettings } from './types';

export const StaticTagField = ({
  id,
  settings,
}: FieldValue<TagFieldSettings>): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = (get(formData, id) ?? []) as string[];

  return (
    <Flex alignItems="center">
      <Text as="span" fontWeight="bold">
        {settings.name}:
      </Text>
      &nbsp;
      <TagList value={value} availableTags={settings.tags} />
    </Flex>
  );
};
