import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterEmail = makeTextFilter({
  name: () => i18n.t('Ticketing:mail_address'),
  toFilter: ({ operator, value }) => {
    return [
      'mailEvents',
      '$any',
      {
        $or: [
          ['message.fromMailAddress.email', operator, value || ''],
          [
            'message.addresses',
            '$any',
            [['mailAddress.email', operator, value || '']],
          ],
        ],
      },
    ];
  },
});
