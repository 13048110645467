import { format } from 'date-fns';
import hr from 'date-fns/locale/hr';

export const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return format(dateObj, 'P', { locale: hr });
};
