import { ReactElement } from 'react';

import { FieldValue, useFormDefinition } from '@bq/components/FormEditor';
import { useDisplayMode } from '@bq/components/ListingSettings';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { usePhonebookContext } from '../PhonebookContext';
import { usePermissions } from '../use-permissions';
import { CompanyEmployeeCardList } from './CompanyEmployeeCardList';
import { CompanyEmployeeTableList } from './CompanyEmployeeTableList';
import { MobileCompanyEmployeeList } from './MobileCompanyEmpoyeeList';

interface Props {
  listingName?: string;
  company?: { ID: number; name: string };
  department?: { ID: number; name: string };
  companyLocation?: { ID: number; name: string };
  customFields?: FieldValue[];
  isStandalone?: boolean;
}

export const CompanyEmployeeList = ({
  company: forCompany,
  isStandalone = false,
  listingName = 'Phonebook.CompanyEmployeeList',
  department,
  companyLocation,
}: Props): ReactElement => {
  const customFields = useFormDefinition('Phonebook.person');
  const settingsLoaded = useLoadUserSettings(listingName, {});
  const [displayMode] = useDisplayMode(listingName);
  const { data: permissions } = usePermissions();

  const { localCompany } = usePhonebookContext();
  const company = forCompany ?? localCompany;
  const isMobile = useIsMobile();

  if (!company) {
    throw Error('No company');
  }
  if (!customFields || !settingsLoaded || !permissions) {
    return <LoadingPage />;
  }

  if (isMobile) {
    return (
      <MobileCompanyEmployeeList
        customFields={customFields}
        company={company}
        department={department}
        companyLocation={companyLocation}
        permissions={permissions}
      />
    );
  }

  return displayMode === 'card' ? (
    <CompanyEmployeeCardList
      customFields={customFields}
      company={company}
      department={department}
      listingName={listingName}
      permissions={permissions}
    />
  ) : (
    <CompanyEmployeeTableList
      listingName={listingName}
      isStandalone={isStandalone}
      customFields={customFields}
      company={company}
      department={department}
      companyLocation={companyLocation}
      permissions={permissions}
    />
  );
};
