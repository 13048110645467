import { createContext, useContext } from 'react';
import { IPhonebookFormsContext } from './types';

export const PhonebookFormsContext =
  createContext<IPhonebookFormsContext | null>(null);

export function usePhonebookFormsContext(): IPhonebookFormsContext {
  const context = useContext(PhonebookFormsContext);
  if (!context) {
    throw new Error('Missing PhonebookFormsContext');
  }

  return context;
}
