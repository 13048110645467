import {
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  MutableRefObject,
  PropsWithoutRef,
  ReactElement,
  Ref,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from 'react';

export function useEnsureForwardedRef<T>(
  forwardedRef: ForwardedRef<T | null>
): MutableRefObject<T | null> {
  const ensuredRef = useRef<T | null>(null);

  useImperativeHandle(forwardedRef, () => ensuredRef.current as T);

  return ensuredRef;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type FwdRefComponent<T, P = {}> = (
  props: P,
  ref: Ref<T>
) => ReactElement | null;
// eslint-disable-next-line @typescript-eslint/ban-types
export function ensureForwardedRef<T, P = {}>(
  Component: FwdRefComponent<T, P>
): ForwardRefExoticComponent<PropsWithoutRef<P> & RefAttributes<T>> {
  // eslint-disable-next-line react/display-name
  return forwardRef<T, P>((props, ref) => {
    const ensuredRef = useEnsureForwardedRef<T>(ref);

    return Component(props, ensuredRef);
  });
}
