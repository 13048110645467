import {
  Box, Flex, HStack, IconButton, Tooltip,
} from '@chakra-ui/react';
import { Api } from 'BootQuery/Assets/js/api';
import { useGlobalHotkey } from 'BootQuery/Assets/js/use-global-hotkey';
import { ReactElement, useCallback } from 'react';
import { FaExchangeAlt, FaPhone, FaPhoneSlash } from 'react-icons/fa';
import { Call } from '../../types/call';
import { formatCall } from '../CallList/format-call';
import { CallActionsMenu } from './CallActionsMenu';
import { CurrentCallActions } from './CurrentCallActions';
import { RemotePartyDisplay } from './RemotePartyDisplay';
import { dialerStore } from './store/pbx';
import { softphoneState } from './store/softphone';
import { CallDisplayProps, SessionInterface } from './types';
import { pbxCallBySipCallId } from './util';

export const CallDisplay = (callInfo: CallDisplayProps): ReactElement => {
  const pbxCalls = dialerStore((store) => store.calls.map((call) => call.call));

  let canAnswer: boolean;
  let canHangup: boolean;
  let canConfirmTransfer: boolean;
  let pbxCall: Call | null;

  if (callInfo.type === 'softphone') {
    const { call } = callInfo;
    pbxCall = pbxCallBySipCallId(pbxCalls, call.sipCallId);
    canAnswer = callInfo.call.state === 'ringing';
    canHangup = callInfo.call.state !== 'ended';

    const callWaitingForTransfer: SessionInterface | null = softphoneState(
      (state) => state.sessions.find(
        (other) => other.waitingForAttendedTransfer &&
            other.sessionId !== call.sessionId
      ) ?? null
    );
    // const callWaitingForTransfer: SessionInterface|null = null;
    canConfirmTransfer =
      call.state === 'inCall' && callWaitingForTransfer !== null;
  } else {
    pbxCall = callInfo.call.call;
    const callState = callInfo.call.call.currentState?.callState ?? null;

    canAnswer = false;
    canHangup = callState !== 'terminated';
    canConfirmTransfer = false;
  }

  const endCall = useCallback(() => {
    if (callInfo.type === 'softphone') {
      callInfo.call.end();
    } else {
      const ourSide = callInfo.call.connection === 'source' ? 'a' : 'b';
      Api.delete(`/api/dialer/calls/${callInfo.call.call.callId}/${ourSide}`);
    }
  }, [callInfo.call, callInfo.type]);

  const answerCall = useCallback(() => {
    if (callInfo.type === 'softphone') {
      callInfo.call.answer();
    }
  }, [callInfo.call, callInfo.type]);

  const answerOrHangup = useCallback(() => {
    if (callInfo.type !== 'softphone') {
      return;
    }

    const { state } = callInfo.call;

    if (state === 'ringing') {
      answerCall();

      const { preset, presets, setPreset } = softphoneState.getState().settings;
      // Switch to speaker if available when answering using the big button
      if (preset !== 'speaker' && presets.speaker?.enabled) {
        setPreset('speaker');
      }
    } else if (state !== 'ended') {
      endCall();
    }
  }, [callInfo, endCall, answerCall]);

  useGlobalHotkey({ key: '5', modifiers: { ctrl: true } }, answerOrHangup);

  const confirmTransfer = () => {
    if (callInfo.type === 'softphone') {
      callInfo.call.confirmTransfer();
    }
  };

  return (
    <Flex flexGrow={1}>
      <Flex flexGrow={1} direction="column">
        <Box>
          <Box width="100%" textAlign="center">
            <RemotePartyDisplay {...callInfo} />
          </Box>
        </Box>
        <CurrentCallActions callInfo={callInfo} />
      </Flex>
      <HStack spacing="1">
        <Tooltip hasArrow label="Hangup">
          <IconButton
            size="sm"
            isDisabled={!canHangup}
            colorScheme="red"
            aria-label="Hangup"
            onClick={endCall}
          >
            <FaPhoneSlash />
          </IconButton>
        </Tooltip>
        {canAnswer && (
          <Tooltip hasArrow label="Answer">
            <IconButton
              size="sm"
              colorScheme="green"
              aria-label="Answer"
              onClick={answerCall}
            >
              <FaPhone />
            </IconButton>
          </Tooltip>
        )}
        {canConfirmTransfer && (
          <Tooltip hasArrow label="Confirm transfer">
            <IconButton
              size="sm"
              colorScheme="blue"
              aria-label="Confirm transfer"
              onClick={confirmTransfer}
            >
              <FaExchangeAlt />
            </IconButton>
          </Tooltip>
        )}
        {pbxCall && <CallActionsMenu call={formatCall(pbxCall)} />}
      </HStack>
    </Flex>
  );
};
