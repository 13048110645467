import { Link, LinkProps } from '@chakra-ui/react';
import { ReactElement, PropsWithChildren, memo } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useItemStyle } from './item-style';

interface OverviewProps extends LinkProps {
  link: string;
  title: string;
  isActive?: boolean;
}

const Overview = memo(
  ({
    children,
    link,
    title,
    isActive = false,
    ...linkProps
  }: PropsWithChildren<OverviewProps>): ReactElement => (
    <Link
      as={ReactLink}
      to={link}
      {...useItemStyle({ isActive })}
      {...linkProps}
    >
      {children ?? title}
    </Link>
  )
);
Overview.displayName = 'Overview';

export { Overview };
