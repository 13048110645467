import { ReactElement } from 'react';

import {
  EventMenuItems,
  EventCardComponent,
} from 'app/Modules/Events/Assets/components';

import { Message } from '../../Message';
import { MailEvent } from './types';

export const MailEventCard: EventCardComponent<MailEvent> = (
  event
): ReactElement => (
  <Message {...event.data} rightMenuAddons={<EventMenuItems {...event} />} />
);
