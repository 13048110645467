module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "mb-0";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label class=\"col-12 col-form-label "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"nested") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":44},"end":{"line":4,"column":135}}})) != null ? stack1 : "")
    + "\" for=\""
    + container.escapeExpression(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":4,"column":142},"end":{"line":4,"column":174}}}))
    + "\">\n            "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"formLabel"),depth0,{"name":"formLabel","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " col-md-2 col-form-label control-label text-left text-md-right";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"col-6"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"nested") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":25},"end":{"line":9,"column":63}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"col-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"can_sms")),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":32,"column":19}}})) != null ? stack1 : "")
    + "            <button class=\"btn btn-sm btn-success float-right phonenumber-button mb-1\" data-extension=\""
    + alias3(alias2(((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"extension")), depth0))
    + "\" data-number=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">\n                <span class=\"fa fa-phone\"></span>&nbsp;\n                "
    + alias3(lookupProperty(helpers,"tr").call(alias1,"Dialer:button.call",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":35,"column":16},"end":{"line":35,"column":43}}}))
    + "\n            </button>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " col-md-6";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>\n                    "
    + container.escapeExpression(lookupProperty(helpers,"format_number").call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":12,"column":43}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"carrierInfo") : depth0),{"name":"with","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":21,"column":29}}})) != null ? stack1 : "")
    + "                </span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <br>\n                        <small>\n                            <span class=\"has-tooltip\" title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ported") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":16,"column":61},"end":{"line":16,"column":168}}})) != null ? stack1 : "")
    + "\" data-toggle=\"tooltip\">\n                                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"carrierName") : depth0), depth0))
    + "\n                                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"ported") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":32},"end":{"line":18,"column":94}}})) != null ? stack1 : "")
    + "\n                            </span>\n                        </small>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Broj je prenesen "
    + container.escapeExpression(lookupProperty(helpers,"format_date").call(alias1,(depth0 != null ? lookupProperty(depth0,"portedAt") : depth0),lookupProperty(helpers,"tr").call(alias1,"format.date",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":16,"column":115},"end":{"line":16,"column":133}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":16,"column":92},"end":{"line":16,"column":135}}}));
},"13":function(container,depth0,helpers,partials,data) {
    return "Broj nije prenesen";
},"15":function(container,depth0,helpers,partials,data) {
    return "<span class=\"fas fa-exchange-alt\"></span>";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <input type=\"text\" name=\""
    + alias2(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":24,"column":41},"end":{"line":24,"column":73}}}))
    + "\" value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":24,"column":100},"end":{"line":24,"column":162}}})) != null ? stack1 : "")
    + "\" autocomplete=\"off\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"autofocus") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":183},"end":{"line":24,"column":216}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":217},"end":{"line":24,"column":248}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":249},"end":{"line":24,"column":280}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":281},"end":{"line":24,"column":336}}})) != null ? stack1 : "")
    + ">\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "form-control-plaintext";
},"20":function(container,depth0,helpers,partials,data) {
    return "form-control";
},"22":function(container,depth0,helpers,partials,data) {
    return "autofocus";
},"24":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"26":function(container,depth0,helpers,partials,data) {
    return "readonly";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "placeholder=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"placeholder") : depth0), depth0))
    + "\"";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"sms:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" class=\"btn btn-sm btn-success float-right phonenumber-sms-button mb-1 ml-1\" data-number=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" title=\""
    + alias2(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"Sms:button.send_sms",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":29,"column":143},"end":{"line":29,"column":171}}}))
    + "\" data-toggle=\"tooltip\">\n                <span class=\"fas fa-sms\"></span>\n            </a>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <label class=\"col-12 col-form-label\">&zwj;</label>\n        <div class=\"col-12\">\n            <input type=\"text\" name=\""
    + alias2(lookupProperty(helpers,"array_to_path").call(alias1,(depth0 != null ? lookupProperty(depth0,"parentPath") : depth0),(depth0 != null ? lookupProperty(depth0,"key") : depth0),{"name":"array_to_path","hash":{},"data":data,"loc":{"start":{"line":41,"column":37},"end":{"line":41,"column":69}}}))
    + "\" value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":41,"column":96},"end":{"line":41,"column":158}}})) != null ? stack1 : "")
    + "\" autocomplete=\"off\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"autofocus") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":179},"end":{"line":41,"column":212}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":213},"end":{"line":41,"column":244}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"readonly") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":245},"end":{"line":41,"column":276}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"placeholder") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":277},"end":{"line":41,"column":332}}})) != null ? stack1 : "")
    + ">\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-12\">\n    <div class=\"form-group row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":55}}})) != null ? stack1 : "")
    + "\" style=\"align-items: center;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"plain") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":43,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true})