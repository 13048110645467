import { useCallback, useEffect, useRef, useState } from 'react';

type VoidPromise<R> = () => Promise<R>;

export function useDebouncedAsyncCallback<R>(
  fn: VoidPromise<R>,
  durationMS = 1000
): VoidPromise<R> {
  const timeout = useRef<number | null>(null);
  const waiting = useRef(false);
  const [isFetching, setIsFetching] = useState(false);
  const rerunScheduled = useRef(false);
  const waitingPromises = useRef<((res: R) => void)[]>([]);

  const doFetch: VoidPromise<R> = useCallback(async () => {
    rerunScheduled.current = false;

    setIsFetching(true);
    waiting.current = true;
    const res = await fn();

    waitingPromises.current.forEach((prom) => prom(res));
    waitingPromises.current = [];

    setIsFetching(false);

    return res;
  }, [fn]);

  const startWaiting = useCallback(() => {
    waiting.current = true;

    if (timeout.current !== null) {
      window.clearTimeout(timeout.current);
    }

    timeout.current = window.setTimeout(() => {
      waiting.current = false;
      timeout.current = null;
      if (rerunScheduled.current) {
        doFetch();
      }
    }, durationMS);
  }, [durationMS, doFetch]);

  useEffect(() => {
    if (!isFetching) {
      startWaiting();
    }
  }, [isFetching, startWaiting]);

  return useCallback(() => {
    if (waiting.current) {
      rerunScheduled.current = true;

      return new Promise<R>((resolve) => {
        waitingPromises.current.push(resolve);
      });
    }

    return doFetch();
  }, [doFetch]);
}
