import { Button, Link, ModalBody, ModalFooter } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaTimes } from 'react-icons/fa';

interface Props {
  exportLink: string;
  onClose: () => void;
}

export const ExportModalLink = ({
  exportLink,
  onClose,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');

  return (
    <>
      <ModalBody display="flex" justifyContent="center">
        <Button as={Link} href={exportLink} download>
          <FaDownload />&nbsp;
          {t('global:download')}
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="red"
          rightIcon={<FaTimes />}
          variant="outline"
          onClick={onClose}
        >
          {t('global:close')}
        </Button>
      </ModalFooter>
    </>
  );
};
