import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useListingSettings } from '@bq/components/ListingSettings';
import { parseSort } from 'BootQuery/Assets/js/utils';

import { useConvertUnits } from '../../../js/use-convert-units';
import { getProducts } from '../API/Product/getProducts';
import { columns } from '../Columns/columns';
import { filterTypes } from '../Filters/filter-types';
import { LoadedItem } from '../types';
import { defaultVisibleColumns } from './default-visible-columns';
import { ProductSettingsCtxI } from './types';

const ProductSettingsCtx = createContext<ProductSettingsCtxI | null>(null);

export const ProductSettingsProvider = ({ children }: PropsWithChildren) => {
  const listingSettings = useListingSettings<LoadedItem>({
    listingName: 'ProductSettings',
    viewName: 'table',
    filterTypes,
    columns: columns(),
    storage: 'state',
    defaults: { visibleColumns: defaultVisibleColumns },
  });
  const { filters, limit, sort, page } = listingSettings;
  const { data: convertUnits } = useConvertUnits();
  const queryKey = useMemo(
    () => ['ProductSettings', 'table', filters, limit, sort, page],
    [filters, limit, page, sort]
  );
  const { data } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!convertUnits) {
        throw new Error('Tried to load products before units loaded');
      }

      const data = await getProducts(
        {
          params: { filters, limit, page, sort: parseSort(sort) },
        },
        convertUnits
      );

      return data;
    },
    enabled: !!convertUnits,
  });
  const count = useMemo(() => data?.meta.count ?? 0, [data?.meta.count]);

  return (
    <ProductSettingsCtx.Provider
      value={{
        listingSettings,
        queryKey,
        count,
        items: data?.data ?? [],
      }}
    >
      {children}
    </ProductSettingsCtx.Provider>
  );
};

export const useProductSettingsCtx = () => {
  const ctx = useContext(ProductSettingsCtx);
  if (!ctx) {
    throw Error('Missing Product Settings Ctx');
  }

  return ctx;
};
