import { TableDensity } from '@bq/components/TableMenu';

export const setColValues = (density: TableDensity) => {
  if (density === 'sm') {
    return { base: 1, sm: 2, md: 2, lg: 5 };
  }
  if (density === 'lg') {
    return { base: 1, sm: 2, md: 2, lg: 3 };
  }

  return { base: 1, sm: 2, md: 2, lg: 4 };
};
