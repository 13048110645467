import { MouseEvent, ReactElement, useCallback, useState } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@bq/components/FormActions';
import {
  FieldValue,
  FormEditor,
  useFormDefinition,
} from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useDOMInputJSON } from '@bq/components/use-dom-input';
import { useModalInstance } from '@bq/components/UseModal';

export const WorkFormEditModal = (): ReactElement => {
  const formDef = useFormDefinition('Ticketing.workForm', 'edit');

  if (!formDef) {
    return (
      <ModalContent>
        <LoadingPage />
      </ModalContent>
    );
  }

  return <WorkFormModalContent formDefinition={formDef} />;
};

interface ContentProps {
  formDefinition: FieldValue[];
}

const WorkFormModalContent = ({
  formDefinition,
}: ContentProps): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { closeWithNoCallback } = useModalInstance();

  const [savedDef, setSavedDef] = useDOMInputJSON<FieldValue[]>(
    '#settings-form',
    'ticketingWorkForm',
    formDefinition
  );
  const [value, onChange] = useState(savedDef);

  const submit = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      setSavedDef(value);
      // setTimeout is used to wait a render cycle
      // for the useEffects to update the DOM value before closing
      setTimeout(closeWithNoCallback, 0);
    },
    [value, setSavedDef, closeWithNoCallback]
  );
  const cancel = useCallback(() => {
    onChange(savedDef);
    closeWithNoCallback();
  }, [savedDef, closeWithNoCallback]);

  return (
    <ModalContent as="form">
      <ModalHeader>{t('global:edit_form')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormEditor value={value} onChange={onChange} />
      </ModalBody>
      <ModalFooter>
        <FormActions onSubmit={submit} onCancel={cancel} />
      </ModalFooter>
    </ModalContent>
  );
};
