module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    return " btn btn-success btn-block";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-center\">\n	<a href=\"/mails/all/threads/editor?to="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" data-mail-address=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" class=\"btn btn-success btn-block clickvox-mail-btn "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasMailAccount") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":135},"end":{"line":2,"column":180}}})) != null ? stack1 : "")
    + "\">\n		<span class=\"fas fa-paper-plane\"></span>\n		"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Mails:button.compose",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":31}}}))
    + "\n	</button>\n    <a href=\"mailto:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" class=\"noparse"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"hasMailAccount") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":47},"end":{"line":6,"column":110}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Mails:button.use_external_mail_client",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":54}}}))
    + "\n    </a>\n</div>";
},"useData":true})