import { Button, Divider, useColorModeValue, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useFilteredLink } from '@bq/components/FilterLink';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';

interface CustomerProps {
  contactID: number;
  contactType: ContactType;
}

export const CustomerContactDisplayFields = ({
  contactID,
  contactType,
}: CustomerProps) => {
  const customerInfoLink = useFilteredLink({
    filter: 'customer',
    value: { ID: contactID, type: contactType },
  });
  const { t } = useTranslation('Ticketing');
  const color = useColorModeValue('gray.700', 'gray.400');

  const isValidClient = contactType === 'person' || contactType === 'company';
  if (!isValidClient) {
    return <></>;
  }

  return (
    <>
      <Divider />
      <VStack>
        <Button
          leftIcon={<FaFilter />}
          as={Link}
          to={customerInfoLink}
          color={color}
        >
          {t('Ticketing:filter_by_client')}
        </Button>
      </VStack>
    </>
  );
};
