import { FormActions } from '@bq/components/FormActions';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { useCompanyFormWrapper } from './CompanyFormWrapper';

export const CompanyModalFooter = () => {
  const { formState } = useCompanyFormWrapper();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <FormActions
      state={formState}
      onCancel={() => {
        closeWithNoCallback();
      }}
    />
  );
};
