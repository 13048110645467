import { IMailEditorsContext } from './MailEditorsContext';
import { MailActions, useMailActions } from './use-mail-actions';
import { useMailEditorsContext } from './use-mail-editors-context';

export interface UseMailEditorsResult
  extends IMailEditorsContext,
    MailActions {}

export function useMailEditors(): UseMailEditorsResult {
  const ctx = useMailEditorsContext();
  const actions = useMailActions(ctx.addEditor);

  return {
    ...ctx,
    ...actions,
  };
}
