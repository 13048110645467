import { QueryKey, useQueryClient } from '@tanstack/react-query';

import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { LoadedItem } from '../types';
import { useProductEvents } from './product-events';

export const useProductListUpdates = (queryKey: QueryKey) => {
  const queryClient = useQueryClient();

  useProductEvents('createProduct', (data) => {
    queryClient.setQueriesData<ListingResponse<LoadedItem>>(
      queryKey,
      (prev) => {
        if (!prev) {
          return prev;
        }

        const updCount = prev.meta.count ?? 0 + 1;
        const modify: ListingResponse<LoadedItem> = {
          data: [data, ...prev.data],
          meta: { ...prev.meta, count: updCount },
        };

        return modify;
      }
    );
  });

  useProductEvents('editProduct', (data) => {
    queryClient.setQueriesData<ListingResponse<LoadedItem>>(
      queryKey,
      (prev) => {
        if (!prev) {
          return prev;
        }

        const modify: ListingResponse<LoadedItem> = {
          data: prev.data.reduce<LoadedItem[]>((all, current) => {
            if (current.ID === data.ID) {
              return [...all, data];
            }

            return [...all, current];
          }, []),
          meta: { ...prev.meta },
        };

        return modify;
      }
    );
  });

  useProductEvents('deleteProduct', (data) => {
    queryClient.setQueriesData<ListingResponse<LoadedItem>>(
      queryKey,
      (prev) => {
        if (!prev) {
          return prev;
        }

        const updCount = prev.meta.count ?? 0 + 1;
        const modify: ListingResponse<LoadedItem> = {
          data: prev.data.filter((item) => item.ID !== data.ID),
          meta: { ...prev.meta, count: updCount },
        };

        return modify;
      }
    );
  });
};
