import { useMemo } from 'react';
import { Address, AddressType, TypedAddress } from '../../js/types';

type GroupedAddresses = {
  [key in AddressType]: Address[];
};

export function useGroupedAddresses(
  addresses: TypedAddress[]
): GroupedAddresses {
  return useMemo(() => {
    const initial = { to: [], cc: [], bcc: [] };

    return addresses.reduce(
      (grouped, address) => ({
        ...grouped,
        [address.addressType]: [
          ...grouped[address.addressType],
          address.mailAddress,
        ],
      }),
      initial
    );
  }, [addresses]);
}
