import { FaShoppingCart } from 'react-icons/fa';

import i18n from 'BootQuery/Assets/js/i18n';

import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { ProductItemsField } from './ProductItemsField';
import { ProductItemsSettings } from './types';

export type { ProductItemsSettings };

export const productItemsField = (): FieldType<ProductItemsSettings> => ({
  type: 'productItems',
  name: i18n.t('global:form_editor.field_product_items'),
  icon: FaShoppingCart,
  components: {
    FormField: ProductItemsField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  getFilters(_field) {
    return {};
  },
  getColumns(_field) {
    return [];
  },
});
