import { Dispatch } from 'react';

import { FileInfo } from './types';

function setFileInList(files: FileInfo[], file: FileInfo): FileInfo[] {
  return files.map((f) => (f.fileId === file.fileId ? file : f));
}

function setFileProgress(
  files: FileInfo[],
  fileId: string,
  progress: number
): FileInfo[] {
  return files.map((f) => {
    if (f.fileId === fileId && f.type === 'uploading') {
      return { ...f, progress };
    }

    return f;
  });
}

function renameFile(
  files: FileInfo[],
  fileId: string,
  name: string
): FileInfo[] {
  return files.map((f) => (f.fileId === fileId ? { ...f, name } : f));
}

export type FileStateAction =
  | { type: 'addFile'; file: FileInfo }
  | { type: 'removeFile'; file: FileInfo }
  | { type: 'addFiles'; files: FileInfo[] }
  | { type: 'renameFile'; fileId: string; name: string }
  | { type: 'setProgress'; fileId: string; progress: number }
  | {
      type: 'setUploaded';
      file: Pick<FileInfo, 'fileId' | 'name' | 'size'>;
      uploadId: string;
    };

export type FilesDispatch = Dispatch<FileStateAction>;

export function filesStateReducer(
  state: FileInfo[],
  action: FileStateAction
): FileInfo[] {
  switch (action.type) {
    case 'addFile':
      return [...state, action.file];
    case 'removeFile':
      return state.filter((f) => f.fileId !== action.file.fileId);
    case 'addFiles':
      return [...state, ...action.files];
    case 'renameFile':
      return renameFile(state, action.fileId, action.name);
    case 'setProgress':
      return setFileProgress(state, action.fileId, action.progress);
    case 'setUploaded':
      return setFileInList(state, {
        type: 'uploaded',
        uploadId: action.uploadId,
        name: action.file.name,
        fileId: action.file.fileId,
        link: `/api/uploads/${action.uploadId}`,
        size: action.file.size,
      });
    default:
      throw new Error('Unknown action');
  }
}
