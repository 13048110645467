import { Box } from '@chakra-ui/react';

import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { TicketState } from '../types';

export const ticketFilterStatus = makeComboboxFilter({
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return { [`state.name:${containsOp}:ci`]: value };
    }

    if (typeof value === 'object') {
      const { ID } = value;

      return {
        [`state.ID:${operator ?? 'eq'}`]: ID,
      };
    }

    return null;
  },
  extraProps: {
    cacheKey: 'ticketFilterStatus',
    search: async (search: string) => {
      const { data } = await Api.get<TicketState[]>('/api/ticketing/states', {
        params: {
          filters: search ? { $search: search } : {},
        },
      });

      return data;
    },
    itemToString: (item: TicketState) => item.name,
    enableTextSearch: true,
    itemToValue: (item: TicketState) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<TicketState>(
        `/api/ticketing/states/${value.id}`
      );

      return data;
    },
    renderItem: (item) => Item(item),
  },

  name: () => i18n.t('Ticketing:form.state'),
  operators: getDefaultComboboxOperators(false),
});

const Item = (props: TicketState) => {
  return (
    <Box
      bg={props.color}
      p="0.5"
      px="2"
      rounded="sm"
      color="white"
      w="fit-content"
    >
      {props.name}
    </Box>
  );
};
