module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"CustomerChat.groupSettingsCard"),depth0,{"name":"CustomerChat.groupSettingsCard","hash":{"expand":(data && lookupProperty(data,"first")),"form":lookupProperty(helpers,"lookup").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"forms") : depths[1]),lookupProperty(helpers,"concat").call(alias1,"groups-",(depth0 != null ? lookupProperty(depth0,"ID") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":21,"column":64},"end":{"line":21,"column":85}}}),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":86}}})},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid py-3\" id=\"customerchat-groups\">\n    <h3>Generalno</h3>\n    <div class=\"col-12\">\n        <div class=\"form-group row\">\n            <label for=\"chat-limit-input\" class=\"col-12 col-form-label col-md-2 col-form-label control-label text-left text-md-right\">\n                Max. aktivnih chatova:\n            </label>\n            <div class=\"col-12 col-md-10\">\n                <input class=\"form-control\" required type=\"number\" min=\"0\" name=\"customerChat[chatLimit]\" value=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"chatLimit") : depth0), depth0))
    + "\">\n            </div>\n        </div>\n    </div>\n\n    <h3>Feedback</h3>\n    <div class=\"row\" data-form=\"feedbackSettings\">\n        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = (depth0 != null ? lookupProperty(depth0,"forms") : depth0)) != null ? lookupProperty(stack1,"feedback") : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <h3>Grupe </h3>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"groups") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "\n    <button type=\"button\" class=\"btn btn-success float-right my-3 customerchat-groups-add-btn\">\n        <span class=\"fa fa-plus\"></span> "
    + alias1(lookupProperty(helpers,"tr").call(alias2,"CustomerChat:button.add_group",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":79}}}))
    + "\n    </button>\n</div>";
},"usePartial":true,"useData":true,"useDepths":true})