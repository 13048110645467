import { useCallback, useMemo } from 'react';
import { FieldError } from 'react-hook-form';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';

import { User } from '../../../../schemas';

interface Props {
  value?: User | null;
  onChange: (val: User | null) => void;
  error?: FieldError;
}
export const AssignedToUserFormField = ({ onChange, error, value }: Props) => {
  const contactItemToUser = useCallback(
    (value: ContactItem | null): User | null => {
      if (!value) {
        return null;
      }
      // This 2 cases can't happen but ts can't really get correct type unless
      // I handle them
      if (value?.type !== 'user') {
        throw Error('Only users are permitted');
      }
      if ('$new' in value) {
        throw Error(
          'Trying to create new user through select is not permitted'
        );
      }

      return { ID: value.ID, username: value.name, person: null };
    },
    []
  );

  const userToContactItem = useCallback(
    (user: User | null | undefined): ContactItem | null => {
      if (!user) {
        return null;
      }

      return { ID: user.ID, name: user.username, type: 'user' };
    },
    []
  );

  const val = useMemo(
    () => userToContactItem(value),
    [userToContactItem, value]
  );

  return (
    <ContactSelect
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
      isMulti={false}
      onChange={(val) => {
        onChange(contactItemToUser(val));
      }}
      value={val}
      allowedTypes={{
        company: false,
        companyDepartment: false,
        companyLocation: false,
        person: false,
        user: true,
      }}
      isInvalid={!!error?.message}
      createMode="none"
      isClearable
    />
  );
};
