import { ChangeEvent, useMemo } from 'react';
import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { NoteFieldSettings, NoteSelectOption } from './types';

export const NoteFieldSettingsComponent = (
  props: EditorFieldComponentProps<NoteFieldSettings>
) => {
  const { setSetting } = useFieldSettings(props);

  const { t } = useTranslation();

  const options = useMemo<NoteSelectOption[]>(
    () => [
      { label: t('global:note_type.warning'), value: 'warning' },
      { label: t('global:note_type.error'), value: 'error' },
      { label: t('global:note_type.success'), value: 'success' },
      { label: t('global:note_type.info'), value: 'info' },
    ],
    [t]
  );

  const findSelected = useMemo(() => {
    return (
      options.find((item) => item.label === props.content.type) ?? options[0]
    );
  }, [options, props.content.type]);

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setType = (val: NoteSelectOption) => {
    setSetting('type', val.value);
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input
          placeholder="Name"
          value={props.content.settings.name}
          onChange={setName}
          size="sm"
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('global:type')}:</FormLabel>
        <Select<NoteSelectOption, false>
          options={options}
          value={findSelected}
          onChange={(newValue) => {
            if (newValue) {
              setType(newValue);
            }
          }}
        />
      </FormControl>
    </VStack>
  );
};
