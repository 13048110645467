import { Box, HStack, Text } from '@chakra-ui/react';

import { CellProps } from '@bq/components/Table';

import { CustomerContactDisplay } from '../../../TicketingContactDisplay/CustomerContactDisplay';
import { iconForTicketingContactType } from '../../../TicketingForm/Contact/utils';
import { Ticket, TicketContact } from '../../../types';

export const TicketContactCell = (props: CellProps<Ticket>) => {
  if (props.row.contact) {
    const { contact } = props.row.contact;
    const Icon = iconForTicketingContactType(contact.type);

    return (
      <HStack rounded="sm" w="full">
        <Box w="fit-content">{contact.type !== 'manual' && <Icon />}</Box>
        {contact.type === 'manual' ? (
          <Text>{contact.manualContact}</Text>
        ) : (
          <CustomerContactDisplay
            valueFontWeight="normal"
            contactID={contact.ID}
            contactType={contact.type}
            contactName={getContactName(contact)}
          />
        )}
      </HStack>
    );
  }

  return <>-</>;
};

const getContactName = (contact: TicketContact) => {
  if (contact.type === 'company') {
    return contact.name;
  }
  if (contact.type === 'person') {
    return contact.fullName;
  }
  if (contact.type === 'companyLocation') {
    return contact.name;
  }
  if (contact.type === 'companyDepartment') {
    return contact.name;
  }
  if (contact.type === 'manual') {
    return contact.manualContact;
  }

  // Use the square bracked syntax to make TS happy,
  // otherwise it complains about property not existing
  //
  // eslint-disable-next-line dot-notation
  throw Error(`Unknown contact type ${contact['type']}`);
};
