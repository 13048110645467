import { FilterTypes } from '@bq/components/FilterBar';
import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';
import i18n from 'BootQuery/Assets/js/i18n';

// Currency Filter
// Taxrate Filter
export const wholeSalePriceFilter = makeNumberFilter({
  name: () => i18n.t('Products:wholesale_price'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'price.wholesalePrice:gte': value.from,
          'price.wholesalePrice:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['price.wholesalePrice', operator ?? 'eq', value];
    }

    return null;
  },
});
export const retailPriceFilter = makeNumberFilter({
  name: () => i18n.t('Products:retail_price'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'price.retailPrice:gte': value.from,
          'price.retailPrice:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['price.retailPrice', operator ?? 'eq', value];
    }

    return null;
  },
});
export const purchasePriceFilter = makeNumberFilter({
  name: () => i18n.t('Products:purchase_price'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'price.purchasePrice:gte': value.from,
          'price.purchasePrice:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['price.purchasePrice', operator ?? 'eq', value];
    }

    return null;
  },
});
export const contractPriceFilter = makeNumberFilter({
  name: () => i18n.t('Products:contract_price'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'price.contractPrice:gte': value.from,
          'price.contractPrice:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['price.contractPrice', operator ?? 'eq', value];
    }

    return null;
  },
});
// Action Prices column
export const priceFilters: FilterTypes = {
  wholeSalePriceFilter,
  retailPriceFilter,
  purchasePriceFilter,
  contractPriceFilter,
};
