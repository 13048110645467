import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { CaseType } from '../types';

export async function getCaseTypes<T = CaseType>(
  params: GetListingParams = {}
): Promise<T[]> {
  const { data } = await Api.get<ListingResponse<T>>('/api/sales/caseTypes', {
    params,
  });

  return data.data;
}
