/* eslint-disable no-underscore-dangle */
import { Contact, ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import i18n from 'BootQuery/Assets/js/i18n';
import { entries } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';
import {
  FaBuilding,
  FaMapMarkerAlt,
  FaIdCard,
  FaPen,
  FaUser,
} from 'react-icons/fa';
import { ImTree } from 'react-icons/im';
import { ReactNode } from 'react';
import {
  ContactItem,
  ContactSelectMultiValue,
  ContactSelectOption,
  ContactSelectSingleValue,
  ContactTypeWithManual,
  GetContactValue,
  PartialContactItem,
} from './types';
import { createCompany } from './API/createCompany';
import { createPerson } from './API/createPerson';
import { getContacts } from './API/getContacts';

export const arrayOfValidTypes = (
  allowedTypes: Record<ContactType, boolean>
): ContactType[] => {
  return entries(allowedTypes).reduce<ContactType[]>(
    (all, [type, isAllowed]) => {
      if (isAllowed) {
        return [...all, type];
      }

      return all;
    },
    []
  );
};

// get a label for creating a single type
const labelForSingleType = (type: ContactTypeWithManual) => {
  switch (type) {
    case 'company':
      return () => i18n.t('Phonebook:create_company');
    case 'companyLocation':
      return () => i18n.t('Phonebook:create_company_location');
    case 'companyDepartment':
      return () => i18n.t('Phonebook:create_company_department');
    case 'person':
      return () => i18n.t('Phonebook:create_person');
    case 'user':
      return () => i18n.t('Phonebook:user');
    case 'manual':
      return () => '';
    default:
      return () => i18n.t('Phonebook:contact');
  }
};
// load contact from a value
export const loadContact = async (value: GetContactValue) => {
  const { data: contact } = await Api.get<Contact>(
    `/api/phonebook/contacts/${value.type}/${value.ID}`
  );

  return { label: contact.name, value: contact.ID, type: contact.type };
};

// make a create option
export const getCreateOption = (
  type: ContactTypeWithManual,
  search: string
): ContactSelectOption => ({
  type,
  value: search,
  label: `${labelForSingleType(type)()}: '${search}'`,
  __isNew__: true,
});

export const singleValueToSelectOption = (
  value?: ContactSelectSingleValue
): ContactSelectOption | null => {
  if (value) {
    if ('$new' in value) {
      return {
        label: value.$new,
        value: value.$new,
        type: value.type,
      };
    }

    return { label: value.name, value: value.ID, type: value.type };
  }

  return null;
};

export const singleSelectOptionToValue = (
  value?: ContactSelectOption | null
): ContactItem | null => {
  if (value) {
    if (typeof value.value === 'string') {
      return { $new: value.value, type: value.type };
    }

    return { ID: value.value, name: value.label, type: value.type };
  }

  return null;
};

export const loadSingleValue = async (
  value: ContactSelectSingleValue | PartialContactItem
) => {
  /** If we only saved IDs we fetch contacts for that id */
  if (value) {
    if ('name' in value && value.name !== undefined) {
      return singleValueToSelectOption(value);
    }
    if ('$new' in value) {
      return {
        label: value.$new,
        value: value.$new,
        type: value.type,
      };
    }
    if ('ID' in value && value.ID !== undefined) {
      return loadContact({
        ID: typeof value.ID === 'number' ? value.ID.toString() : value.ID,
        type: value.type,
      });
    }
    console.log(
      'Hello if you see this, please tell Inti immediately so he can check what happened'
    );
    console.log('Tell him something-something-contactselect-utils-loadcontact');

    return null;
  }

  return null;
};

export const multiValueToSelectOptions = (
  value?: ContactSelectMultiValue
): ContactSelectOption[] => {
  if (value) {
    return value.reduce((all: ContactSelectOption[], current) => {
      const parseCurrent = singleValueToSelectOption(current);
      if (!parseCurrent) {
        return all;
      }

      return [...all, parseCurrent];
    }, []);
  }

  return [];
};

export const createOne = (
  type: ContactType,
  value: string
): Promise<ContactSelectOption> => {
  switch (type) {
    case 'company':
      return createCompany(value);
    case 'person':
      return createPerson(value);
    default:
      throw Error('No type');
  }
};

export const searchContactsRaw = async (
  search: string,
  allowedTypes: Record<ContactType, boolean>
): Promise<Contact[]> => {
  const allowedTypesArray = arrayOfValidTypes(allowedTypes);

  const contacts = await getContacts(search, allowedTypesArray);

  return contacts;
};

export const loadMultiValue = (
  value: ContactSelectMultiValue
): ContactSelectOption[] => {
  return value.reduce<ContactSelectOption[]>((all, current) => {
    if (current) {
      if ('name' in current) {
        const parseCurrent = singleValueToSelectOption(current);
        if (parseCurrent) {
          return [...all, parseCurrent];
        }

        return all;
      }
      if ('$new' in current) {
        return [
          ...all,
          {
            label: current.$new,
            value: current.$new,
            type: current.type,
          },
        ];
      }

      return all;
    }

    return all;
  }, []);
};

export function CSIconForContactType(type?: ContactTypeWithManual): ReactNode {
  switch (type) {
    case 'company':
      return <FaBuilding style={{ display: 'inline' }} />;
    case 'companyLocation':
      return <FaMapMarkerAlt style={{ display: 'inline' }} />;
    case 'manual':
      return <FaPen style={{ display: 'inline' }} />;
    case 'companyDepartment':
      return <ImTree />;
    case 'person':
      return <FaUser />;
    default:
      return <FaIdCard style={{ display: 'inline' }} />;
  }
}

export const isEditableType = (
  value?: ContactTypeWithManual
): value is 'person' | 'company' | 'companyDepartment' | 'companyLocation' => {
  if (!value || value === 'user' || value === 'manual') {
    return false;
  }

  return true;
};
