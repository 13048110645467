import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { parseToSlug } from 'BootQuery/Assets/components/TreeOverviews/utils';
import { Department, DepartmentMap, OffspringMap } from './types';

// this returns map of parent-children
function makeDepartmentMap(departments: Department[]): DepartmentMap {
  return departments.reduce((deps, dep) => {
    const parentID = dep.parentDepartment?.ID ?? -1;

    return {
      ...deps,
      [parentID]: [...(deps[parentID] ?? []), dep],
    };
  }, {} as DepartmentMap);
}
// this returns map of parentID- array of childrenID + children.childrenID
export function makeDepartmentOffspringMap(departments: Department[]) {
  return departments.reduce((deps, dep) => {
    const parentID = dep.parentDepartment?.ID ?? -1;
    const ancestorIDs = findAncestors(parentID, departments);
    const data: OffspringMap = {};
    data[`${parentID}`] = [...(deps[parentID] ?? []), dep.ID];
    ancestorIDs.forEach((ancestor) => {
      data[`${ancestor}`] = [...(deps[ancestor] ?? []), dep.ID];
    });

    return { ...deps, ...data };
  }, {} as OffspringMap);
}

const findAncestors = (parentID: number, departments: Department[]) => {
  let ancestorIDs: number[] = [];
  const grandparentID =
    departments.find((dep) => dep.ID === parentID)?.parentDepartment?.ID ?? -1;
  if (parentID !== -1 && parentID !== grandparentID) {
    ancestorIDs = findAncestors(grandparentID, departments);
  }
  ancestorIDs = [...ancestorIDs, parentID];

  return ancestorIDs;
};

export function doMakeDepartmentTree(
  map: DepartmentMap,
  offspringMap: OffspringMap,
  showSubDep: boolean,
  parentID: number | null = null
): IOverviewEditorItem[] {
  const deps = map[parentID ?? -1] ?? [];

  return deps.map(
    (dep): IOverviewEditorItem => ({
      title: dep.name,
      slug: `${parseToSlug(dep.name)}`,
      type: 'item',
      overviews: doMakeDepartmentTree(map, offspringMap, showSubDep, dep.ID),
      filters: [getFilter(showSubDep, dep.ID, offspringMap)],
      global: true,
      visible: false,
    })
  );
}

export function makeDepartmentTree(
  departments: Department[],
  showSubDep: boolean
): IOverviewEditorItem[] {
  const offspringMap = makeDepartmentOffspringMap(departments);

  return doMakeDepartmentTree(
    makeDepartmentMap(departments),
    offspringMap,
    showSubDep
  );
}

export const stringToBool = (boolInString: 'true' | 'false') => boolInString === 'true';

const getFilter = (
  showSubDep: boolean,
  currentID: number,
  offspringMap: OffspringMap
) => {
  if (showSubDep) {
    return {
      value: [currentID, ...(offspringMap[currentID] ?? [])],
      filter: 'department',
    };
  }

  return { value: { ID: `${currentID}` }, filter: 'department' };
};
