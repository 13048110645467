import { useContext, useMemo } from 'react';
import {
  FormControl,
  HStack,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { get } from 'lodash-es';

import { getFieldTypes, StaticGeneratedFormField } from '../..';
import {
  StaticFormContext,
  useStaticFormContext,
} from '../../StaticFormContext';
import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { StaticListProvider } from './StaticListFieldContext';
import { ListFieldSettings, ListValue } from './types';

export const StaticListField = ({
  id,
  settings,
}: FieldValue<ListFieldSettings>) => {
  const bg = useColorModeValue('brand.lightGray', '#494949');

  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id) as ListValue[];

  return (
    <FormControl>
      <FieldLabel {...settings} />
      {settings.subfields.length > 0 && (
        <Stack bg={bg} p={2} rounded="md">
          {value.map((row, idx) => (
            <StaticListRow
              key={row.$id}
              prefix={`${id}.${idx}`}
              fields={settings.subfields}
            />
          ))}
        </Stack>
      )}
    </FormControl>
  );
};

interface StaticListRowProps {
  prefix: string;
  fields: FieldValue[];
}
const StaticListRow = ({ prefix, fields }: StaticListRowProps) => {
  const { fieldTypes: suppliedFieldTypes } = useStaticFormContext();
  const dividerColor = useColorModeValue('brand.fontMedium', 'brand.fontLight');
  const fieldTypes = useMemo(() => {
    return suppliedFieldTypes ?? getFieldTypes();
  }, [suppliedFieldTypes]);

  return (
    <FormControl
      as={HStack}
      spacing={8}
      w="full"
      mb="0.5"
      py="3"
      borderBottom="1px solid"
      borderColor={dividerColor}
      _last={{ border: 'none' }}
    >
      <StaticListProvider prefix={prefix} fieldTypes={fieldTypes}>
        {fields.map((field) => (
          <StaticGeneratedFormField
            key={field.id}
            prefix={prefix}
            field={field}
            fieldTypes={fieldTypes}
          />
        ))}
      </StaticListProvider>
    </FormControl>
  );
};
