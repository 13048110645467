import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndo } from 'react-icons/fa';

interface Props {
  onClick?: () => void;
  size?: string;
}

export const ResetButton = ({ onClick, size = 'sm' }: Props): ReactElement => {
  const { t } = useTranslation('global');

  return (
    <IconButton
      size={size}
      variant="ghost"
      alignItems="center"
      borderRadius="full"
      label={t('global:reset')}
      icon={<FaUndo />}
      onClick={onClick}
    />
  );
};
