import { ReactElement, useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberInputFull } from '@bq/components/NumberInputFull';
import { useTranslateSymbols } from 'app/Modules/Products/Assets/components/Settings/TranslateUnits/use-translate-symbols';

import { useAutoItemValues } from './hooks/use-auto-item-values';
import { ProductSelect } from './ProductSelect';
import { ItemFields, ItemFieldSettings, Product } from './types';

interface Props {
  fieldName: string;
  required?: boolean;
  itemFields?: ItemFields;
}

export const ProductItem = ({
  fieldName,
  itemFields: suppliedFields,
  required,
}: Props): ReactElement => {
  useAutoItemValues(fieldName);

  const itemFields = useMemo(
    () => suppliedFields ?? defaultFields,
    [suppliedFields]
  );

  return (
    <>
      {itemFields.map((field) => (
        <ItemField
          key={field.field}
          fieldName={fieldName}
          required={required}
          field={field}
        />
      ))}
    </>
  );
};

const defaultFields = [
  { field: 'product', required: true },
  { field: 'quantity', required: true },
  { field: 'value', required: true },
  { field: 'grossWeightKg', required: false },
  { field: 'netWeightKg', required: false },
] satisfies ItemFields;

interface ItemFieldProps {
  fieldName: string;
  required?: boolean;
  field: ItemFieldSettings;
}

const ItemField = ({
  fieldName,
  field,
  required,
}: ItemFieldProps): ReactElement => {
  const { t } = useTranslation('Products');

  const unitSymbol = useUnitSymbol(fieldName);
  const unitStr = unitSymbol ? ` (${unitSymbol})` : '';

  switch (field.field) {
    case 'product':
      return (
        <FormControl>
          <FormLabel>{field.label ?? t('Products:product')}:</FormLabel>
          <ProductField
            fieldName={fieldName}
            required={required ?? field.required}
          />
        </FormControl>
      );
    case 'quantity':
      return (
        <FormControl>
          <FormLabel>
            {field.label ?? t('Products:quantity')}
            {unitStr}:
          </FormLabel>
          <QuantityField
            fieldName={fieldName}
            required={required ?? field.required}
          />
        </FormControl>
      );
    case 'value':
      return (
        <FormControl>
          <FormLabel>{field.label ?? t('Products:value')}:</FormLabel>
          <ValueField
            fieldName={fieldName}
            required={required ?? field.required}
          />
        </FormControl>
      );
    case 'grossWeightKg':
      return (
        <FormControl>
          <FormLabel>{field.label ?? t('Products:gross_weight_kg')}:</FormLabel>
          <MassField
            fullFieldName={`${fieldName}.grossWeightKg`}
            required={field.required}
          />
        </FormControl>
      );
    case 'netWeightKg':
      return (
        <FormControl>
          <FormLabel>{field.label ?? t('Products:net_weight_kg')}:</FormLabel>
          <MassField
            fullFieldName={`${fieldName}.netWeightKg`}
            required={field.required}
          />
        </FormControl>
      );
    default:
      console.error('Unknown field; ', field);
      throw new Error('Unknown field');
  }
};

interface FieldProps {
  fieldName: string;
  required?: boolean;
}

const ProductField = ({ fieldName, required }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.product`,
    rules: { required },
  });

  return (
    <ProductSelect
      isInvalid={fieldState.invalid}
      isClearable={!required}
      value={value}
      onChange={onChange}
    />
  );
};

function useUnitSymbol(fieldName: string): string {
  const translate = useTranslateSymbols();
  const { control } = useFormContext();

  const product: Product = useWatch({
    control,
    name: `${fieldName}.product`,
  });

  if (!product?.defaultAmountUnit) {
    return '';
  }

  return translate(product?.defaultAmountUnit?.symbol);
}

const QuantityField = ({ fieldName, required }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.quantity`,
    rules: { required },
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      step={1}
    />
  );
};

const ValueField = ({ fieldName, required }: FieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: `${fieldName}.value`,
    rules: { required },
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      precision={2}
      step={1}
    />
  );
};

interface MassFieldProps {
  fullFieldName: string;
  required?: boolean;
}

const MassField = ({
  fullFieldName,
  required,
}: MassFieldProps): ReactElement => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    control,
    name: fullFieldName,
    rules: { required },
  });

  return (
    <NumberInputFull
      isInvalid={fieldState.invalid}
      value={value}
      onChange={onChange}
      min={0}
      step={1}
    />
  );
};
