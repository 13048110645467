import { ExportParams } from '@bq/components/Export';
import { Api } from 'BootQuery/Assets/js/api';

interface ExportResponse {
  downloadAt: string;
}

export async function generateExport(params: ExportParams): Promise<string> {
  const { data } = await Api.get<ExportResponse>(
    '/api/phonebook/people/export',
    { params }
  );

  return data.downloadAt;
}
