import { ReactElement, useEffect, useRef } from 'react';

import Quill from './index';
import { quillOptions } from './quill-options';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

export const QuillField = ({ value, onChange }: Props): ReactElement => {
  const quillRef = useRef<Quill | null>(null);
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elRef.current && !quillRef.current) {
      const el = elRef.current;
      const quill = new Quill(el, quillOptions);
      quillRef.current = quill;

      quill.clipboard.dangerouslyPasteHTML(value);
      quill.on('text-change', () => {
        if (!(el.firstChild instanceof HTMLElement)) {
          throw new Error(
            'Failed to get editor content, no expected firstChild'
          );
        }

        onChange(el.firstChild.innerHTML);
      });
    }
  }, [value, onChange]);

  return (
    <div style={{ background: '#fff', color: '#282828' }}>
      <div ref={elRef} />
    </div>
  );
};
