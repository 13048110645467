import { CellProps } from '@bq/components/Table';
import { WithSeparator } from '@bq/components/WithSeparator';

import { Ticket } from '../../../types';

export const TicketTagCell = (props: CellProps<Ticket>) => {
  if (props.row.tags && props.row.tags.length > 0) {
    const { tags } = props.row;

    return (
      <WithSeparator separator=", ">{tags.map((tag) => tag)}</WithSeparator>
    );
  }

  return <>-</>;
};
