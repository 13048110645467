import { Contact } from './types';

export function contactLink(contact: Contact): string | null {
  switch (contact.type) {
    case 'company':
      return `/phonebook/companies/${contact.ID}`;
    case 'user':
      return contact.person ? `/phonebook/people/${contact.person.ID}` : null;
    case 'companyLocation':
      return contact.company
        ? `/phonebook/companies/${contact.company.ID}`
        : null;
    case 'person':
      return `/phonebook/people/${contact.ID}`;
    default:
      return null;
  }
}
