import { ReactElement } from 'react';
import { RadioPropGetter } from './chakra-types';
import { ButtonRadioButton } from './ButtonRadioButton';
import { Option } from './types';

interface ButtonRadioOptionProps extends Option {
  getRadioProps: RadioPropGetter;
}

export const ButtonRadioOption = ({
  id,
  title,
  icon: Icon,
  getRadioProps,
}: ButtonRadioOptionProps): ReactElement => {
  const radio = getRadioProps({ value: id });

  return (
    <ButtonRadioButton {...radio}>{Icon ? <Icon /> : title}</ButtonRadioButton>
  );
};
