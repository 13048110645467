/* eslint-disable react/jsx-no-literals */
import {
  Box,
  Flex,
  IconButton,
  Stack,
  styled,
  Tooltip,
} from '@chakra-ui/react';
import { memo, MouseEventHandler, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  pageCount: number;
  page: number;
  onPageChange?: (page: number) => void;
}

interface PaginationButtonProps {
  label: string;
  icon: ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isDisabled: boolean;
}

const PaginationButton = ({
  label,
  ...btnProps
}: PaginationButtonProps): ReactElement => (
  <Tooltip label={label} hasArrow>
    <IconButton aria-label={label} variant="ghost" size="sm" {...btnProps} />
  </Tooltip>
);

const Pagination = memo(
  ({ pageCount, page, onPageChange }: Props): ReactElement => {
    const { t } = useTranslation();
    const handlePageChange = (page: number) => onPageChange && onPageChange(page);

    const prevPage = () => {
      handlePageChange(Math.max(page - 1, 1));
    };
    const firstPage = () => {
      handlePageChange(1);
    };
    const nextPage = () => {
      handlePageChange(Math.min(page + 1, pageCount));
    };
    const lastPage = () => {
      handlePageChange(pageCount);
    };

    const canPrev = page > 1;
    const canNext = page < pageCount;

    return (
      <Stack spacing="0.5">
        <Box textAlign="center" fontSize="xs">
          <Trans
            ns="global"
            i18nKey="page_x_of_y"
            components={{ PageLabel: <PageLabel /> }}
            values={{ page, pageCount }}
            defaults="<PageLabel>Stranica</PageLabel> <strong>{{ page }}</strong> od <strong>{{ pageCount }}</strong>"
          />
        </Box>
        <Flex justifyContent="space-between">
          <Box mr="1">
            <PaginationButton
              label={t('global:pagination.first_page')}
              icon={<span className="fas fa-angle-double-left"></span>}
              isDisabled={!canPrev}
              onClick={() => firstPage()}
            />
            <PaginationButton
              label={t('global:pagination.previous_page')}
              icon={<span className="fas fa-angle-left"></span>}
              isDisabled={!canPrev}
              onClick={() => prevPage()}
            />
          </Box>
          <Box>
            <PaginationButton
              label={t('global:pagination.next_page')}
              icon={<span className="fas fa-angle-right"></span>}
              isDisabled={!canNext}
              onClick={() => nextPage()}
            />
            <PaginationButton
              label={t('global:pagination.last_page')}
              icon={<span className="fas fa-angle-double-right"></span>}
              isDisabled={!canNext}
              onClick={() => lastPage()}
            />
          </Box>
        </Flex>
      </Stack>
    );
  }
);
Pagination.displayName = 'Pagination';

export { Pagination };

const PageLabel = styled('span', {
  baseStyle: {
    display: {
      base: 'none',
      lg: 'inline',
    },
    mr: '0.3em',
  },
});
