import { uniqid } from 'app/assets/js/tsutil';
import { FileUpload } from 'BootQuery/Assets/js/file-upload';

import { FilesDispatch } from './files-state';
import { FileInfo, UploadingFile } from './types';

export function addUploads(newFiles: File[], dispatch: FilesDispatch): void {
  const newUploads: UploadingFile[] = newFiles.map((f) => {
    const upload = new FileUpload(f);
    const fileId = uniqid();

    const file: FileInfo = {
      fileId,
      file: f,
      upload,
      type: 'uploading',
      name: f.name,
      progress: 0,
      size: f.size,
    };

    upload
      .start()
      .then((uploadId) => {
        dispatch({ type: 'setUploaded', file, uploadId });
      })
      .catch((err) => {
        if (typeof err === 'object' && err.message === 'canceled') {
          console.log('Upload cancelled: ', err, fileId);
        } else {
          throw err;
        }
      });
    upload.events.on('progress', (progress) => {
      dispatch({ type: 'setProgress', fileId, progress });
    });

    return file;
  });
  dispatch({ type: 'addFiles', files: newUploads });
}
