import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { useTranslation } from 'react-i18next';
import { ChatEvent } from '../types';

export const ChatEventColumn: EventCellComponent<ChatEvent> = ({
  row: { data: chat },
}) => {
  const { t } = useTranslation('CustomerChat');

  return (
    <>
      {t('CustomerChat:message_count')}: {chat.messageCount}
    </>
  );
};
