import { Box, HStack } from '@chakra-ui/react';

import { CancelButton } from '@bq/components/CancelButton';
import { SaveButton } from '@bq/components/SaveButton';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { useProductFormWrapper } from '../ProductFormWrapper';

export const FooterButtons = () => {
  const { closeWithNoCallback } = useModalInstance();
  const { formState } = useProductFormWrapper();

  return (
    <HStack>
      <Box>{formState}</Box>
      <CancelButton
        onClick={() => {
          closeWithNoCallback();
        }}
      />
      <SaveButton type="submit" />
    </HStack>
  );
};
