import { memo, ReactElement, useMemo } from 'react';
import { Box, chakra, Flex, HStack, Link } from '@chakra-ui/react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaEye } from 'react-icons/fa';

import { getFileExtension } from '@bq/components/FileInput/util';
import { IconButton } from '@bq/components/IconButton';
import { FileInfo } from '@bq/components/SingleFileInput';
import { WithSeparator } from '@bq/components/WithSeparator';
import { formatFileSize } from 'app/assets/js/tsutil';

type FileDisplayProps = FileInfo;

const FileDisplayBox = chakra(Box, {
  baseStyle: {
    rounded: 'base',
    shadow: 'base',
    bg: 'white',
    mt: '1',
    py: '1',
    px: '3',
    position: 'relative',
    overflow: 'hidden',
    cursor: 'default',
  },
});

const StaticFileDisplay = (file: FileDisplayProps): ReactElement => {
  const { t } = useTranslation('');
  const link = file.type !== 'uploading' ? file.link : null;

  const ext = useMemo(() => getFileExtension(file.name), [file.name]);
  const defaultFileStyle = useMemo(() => {
    return ext && ext in defaultStyles
      ? defaultStyles[ext as DefaultExtensionType]
      : {};
  }, [ext]);

  return (
    <FileDisplayBox>
      <HStack>
        {ext && (
          <Box w="32px">
            <FileIcon extension={ext} {...defaultFileStyle} />
          </Box>
        )}
        <Box flexGrow={1}>
          <Box color="gray.700">{file.name}</Box>
          <Flex fontSize="sm" color="gray.400" alignItems="center">
            <WithSeparator separator="&nbsp;•&nbsp;">
              {formatFileSize(file.size)}
            </WithSeparator>
          </Flex>
        </Box>
        <Flex alignItems="center">
          {link && (
            <Link href={`${link}?download=true`} target="_blank" download>
              <IconButton
                color="gray.700"
                size="sm"
                variant="ghost"
                label={t('global:download')}
                icon={<FaDownload />}
              />
            </Link>
          )}
          {link && (
            <Link href={link} target="_blank">
              <IconButton
                color="gray.700"
                size="sm"
                variant="ghost"
                label={t('global:open_new_tab')}
                icon={<FaEye />}
              />
            </Link>
          )}
        </Flex>
      </HStack>
    </FileDisplayBox>
  );
};
const FileDisplayMemo = memo(StaticFileDisplay);
FileDisplayMemo.displayName = 'StaticFileDisplay';

export { FileDisplayMemo as StaticFileDisplay };
