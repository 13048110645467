import { useMemo } from 'react';
import { Grid, IconButton, Input } from '@chakra-ui/react';
import {
  useController,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';

import { SelectOption } from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';

import { Good } from '../../../types';

interface Props {
  idx: number;
  remove: UseFieldArrayRemove;
}
export const BarcodeField = ({ idx, remove }: Props) => {
  const { t } = useTranslation();
  const { register, control } = useFormContext<Good>();
  const barcodeTypes = useMemo((): SelectOption[] => {
    return barcodeTypesRaw.map((item) => ({ label: item, value: item }));
  }, []);
  const {
    field: { value, onChange },
  } = useController({
    name: `good.barcodes.${idx}.type`,
    control,
    rules: { required: true },
  });

  return (
    <Grid templateColumns="3fr 1fr 2rem" gridGap={4} my={4}>
      <Input
        {...register(`good.barcodes.${idx}.barcode`, {
          required: 'true',
        })}
      />
      <Select
        options={barcodeTypes}
        value={value ? { label: value, value } : null}
        onChange={(newValue) => {
          if (newValue) {
            onChange(newValue.value);
          }
        }}
      />
      <IconButton
        colorScheme="red"
        icon={<FaTrash />}
        aria-label={t('global:delete')}
        onClick={() => {
          remove(idx);
        }}
      />
    </Grid>
  );
};

export const barcodeTypesRaw = [
  'UPC',
  'UPC-A',
  'UPC-E',
  'EAN',
  'EAN-13',
  'EAN-8',
  'JAN-13',
  'ISBN',
  'ISSN',
  'CODE 39',
  'CODE 128',
  'ITF',
  'CODE 93',
  'Codabar',
  'Ames Code',
  'NW-7',
  'Monarch',
  'Code 2 of 7',
  'Rationalized Codabar',
  'ANSI/AIM BC3-1995',
  'USD-4',
  'GS1 DataBar Omnidirectional',
  'GS1 DataBar Truncated',
  'GS1 DataBar Stacked',
  'GS1 DataBar Stacked Omnidirectional',
  'GS1 DataBar Expanded',
  'GS1 DataBar Expanded Stacked',
  'MSI Plessey',
  'QR Code',
  'Data Matrix Code',
  'Micro-Data Matrix Code',
  'PDF417',
  'Truncated PDF417',
  'Aztec',
];
