import { PropsWithChildren, ReactElement, useCallback } from 'react';

import { awaitCall } from 'app/Modules/Telephony/Assets/components/Dialer/store/dialer';
import { CallResponse } from 'app/Modules/Telephony/Assets/components/Dialer/types';
import { DialInterceptor } from 'app/Modules/Telephony/Assets/components/DialInterceptor';

import { offerEventBus } from './Events/case-event-bus';
import { useEventChanges } from './Events/use-event-changes';

export const CaseCallCatcher = ({
  children,
  caseID,
}: PropsWithChildren<{ caseID: number | null }>): ReactElement => {
  const { addCaseEvent } = useEventChanges(() => {
    /* Not used */
  }, caseID);

  const onDial = useCallback(
    (ev: CallResponse) => {
      awaitCall(ev).then((call) => {
        addCaseEvent({ type: 'call', callID: call.ID });
        offerEventBus.emit('tab/switch', 'events');
        offerEventBus.emit('call/new', call);
      });
    },
    [addCaseEvent]
  );

  return <DialInterceptor onDial={onDial}>{children}</DialInterceptor>;
};
