import { ReactElement, useCallback, useMemo } from 'react';
import {
  HStack,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AddButton } from '@bq/components/AddButton';

import { useDatasetFormContext } from '../form';
import { DatasetColumnRow } from './DatasetColumnRow';
import { FieldArrayContextProvider } from './field-array-context';

export const DatasetColumns = (): ReactElement => {
  const { t } = useTranslation();
  const headerColor = useColorModeValue('#F5F5F5', '#393939');
  const { control } = useDatasetFormContext();
  const fieldArrayReturn = useFieldArray({
    control,
    name: 'columns',
  });
  const { fields, append } = fieldArrayReturn;

  const visibleFields = useMemo(() => {
    return fields
      .map((field, index) => ({ ...field, index }))
      .filter((field) => !field.deleted);
  }, [fields]);

  const addRow = useCallback(() => {
    append({ title: '', type: 'text' });
  }, [append]);

  return (
    <FieldArrayContextProvider {...fieldArrayReturn}>
      <Table>
        <Thead bg={headerColor} borderBottom="2px" borderColor="brand.500">
          <Tr>
            <Th width="50%">{t('global:name')}</Th>
            <Th width="50%">{t('global:type')}</Th>
            <Th width="1%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {visibleFields.map((field) => (
            <DatasetColumnRow key={field.id} field={field} />
          ))}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={3}>
              <HStack justifyContent="end">
                <AddButton onClick={addRow} />
              </HStack>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
    </FieldArrayContextProvider>
  );
};
