import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useGetQueryParams(): () => URLSearchParams {
  const [queryParams] = useSearchParams();
  const queryParamsRef = useRef(queryParams);
  queryParamsRef.current = queryParams;

  return useCallback(() => {
    return queryParamsRef.current;
  }, []);
}
