import { FormControl, FormLabel } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaFileCsv, FaFileExcel } from 'react-icons/fa';

import { ExportFormFields } from './types';
import { RadioButton, RadioButtonGroup } from '../Radio';

export const FormatField = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { control } = useFormContext<ExportFormFields>();

  const controller = useController({ control, name: 'format' });

  return (
    <FormControl>
      <FormLabel>{t('global:format')}</FormLabel>
      <RadioButtonGroup
        defaultVal="xlsx"
        onChange={controller.field.onChange}
      >
        <RadioButton value="xlsx">
          <FaFileExcel />
          {'XLSX'}
        </RadioButton>
        <RadioButton value="ods">
          <FaFileExcel />
          {'ODS'}
        </RadioButton>
        <RadioButton value="csv">
          <FaFileCsv />
          {'CSV'}
        </RadioButton>
      </RadioButtonGroup>
    </FormControl>
  );
};
