import { ReactElement, useMemo } from 'react';
import DOMPurify from 'dompurify';

import { BaseSettings } from '../types';

export const FieldTitle = ({
  name,
  nameHTML: nameHTMLRaw,
}: Pick<BaseSettings, 'name' | 'nameHTML'>): ReactElement => {
  const nameHTML = useSafeHTML(nameHTMLRaw ?? null);

  if (nameHTML) {
    return <span dangerouslySetInnerHTML={{ __html: nameHTML }} />;
  }

  return <>{name}</>;
};

function useSafeHTML(unsafeHTML: string | null): string | null {
  return useMemo(() => {
    if (!unsafeHTML) {
      return null;
    }

    return DOMPurify.sanitize(unsafeHTML, purifyConfig);
  }, [unsafeHTML]);
}

const purifyConfig = {
  FORBID_TAGS: ['style'],
  ADD_ATTR: ['target'],
} satisfies DOMPurify.Config;
