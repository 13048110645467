import { useCallback, useState } from 'react';
import Color from 'tinycolor2';
import { useSettingsChanged } from './settings-events';

export type BrandColors = {
  background: string;
  backgroundDark: string;
  menuItem: string;
  menuItemHover: string;
  darkGray: string;
  mediumGray: string;
  lightGray: string;
  fontLight: string;
  fontDark: string;
  font: string;
  fontStaticDark: string;
  fontStaticLight: string;
  [key: number]: string;
};

export function useBrandColor(): string | null {
  const [brandColor, setBrandColor] = useState(
    window.Bootstrap.bootquery.brandColor
  );

  useSettingsChanged(
    useCallback((settings) => {
      const newColor = settings.Settings?.branding?.color;
      if (typeof newColor !== 'undefined') {
        window.Bootstrap.bootquery.brandColor = newColor;
        setBrandColor(newColor);
        syncBrandColorCssVars(newColor);
      }
    }, [])
  );

  return brandColor;
}

export function brandColors(brandColor: string | null): BrandColors {
  const brandBase = Color(brandColor || '#969892');

  return {
    background: '#F5F5F5',
    // This is the most readable dark color for BACKGROUND color
    fontStaticDark: '#0A0A0A',
    backgroundDark: '#414141',
    // This is the most readable light color for BACKGROUND DARK color
    fontStaticLight: '#BEBEBE',
    menuItem: brandBase.toString(),
    menuItemHover: brandBase.clone().lighten(10).brighten(10).toString(),
    darkGray: '#414141',
    mediumGray: '#515152',
    lightGray: '#F5F5F5',
    // This generates the most readable light color for BRAND color
    fontLight: Color.mostReadable(
      brandBase.toString(),
      generateLightColors()
    ).toString(),
    // This generates the most readable dark color for BRAND color
    fontDark: Color.mostReadable(
      brandBase.toString(),
      generateDarkColors()
    ).toString(),
    // This generates the most readable color for BRAND color
    font: Color.mostReadable(brandBase.toString(), [
      ...generateDarkColors(),
      ...generateLightColors(),
    ]).toString(),
    50: Color.mix(brandBase, 'white', 50).toString(),
    100: Color.mix(brandBase, 'white', 40).toString(),
    200: Color.mix(brandBase, 'white', 30).toString(),
    300: Color.mix(brandBase, 'white', 20).toString(),
    400: Color.mix(brandBase, 'white', 10).toString(),
    500: brandBase.toString(),
    600: Color.mix(brandBase, 'black', 10).toString(),
    700: Color.mix(brandBase, 'black', 20).toString(),
    800: Color.mix(brandBase, 'black', 30).toString(),
    900: Color.mix(brandBase, 'black', 40).toString(),
  };
}

export function syncBrandColorCssVars(brandColor: string | null) {
  if (brandColor) {
    Object.entries(brandColors(brandColor)).forEach(([colorName, color]) => {
      document.documentElement.style.setProperty(
        `--brand-color-${colorName}`,
        color
      );
    });
  } else {
    // Unset all brand color vars so we don't make the menu white-on-white
    [...document.documentElement.style].forEach((prop) => {
      if (prop.startsWith('--brand-color-')) {
        document.documentElement.style.removeProperty(prop);
      }
    });
  }
}

const generateLightColors = () => {
  const defaultLight = '#999999';
  let colors: string[] = [defaultLight];
  for (let i = 10; i <= 35; i += 5) {
    const darken = Color(defaultLight).darken(i).toString();
    const lighten = Color(defaultLight).lighten(i).toString();
    colors = [...colors, darken, lighten];
  }

  return colors;
};

const generateDarkColors = () => {
  const defaultDark = '#666666';
  let colors: string[] = [defaultDark];
  for (let i = 10; i <= 35; i += 5) {
    const darken = Color(defaultDark).darken(i).toString();
    const lighten = Color(defaultDark).lighten(i).toString();
    colors = [...colors, darken, lighten];
  }

  return colors;
};
