import { CallListCall } from '../CallList/types';
import { CallAction, CallActionsProvider } from './types';

export function getCallActions(call: CallListCall): CallAction[] {
  const providers =
    window.BootQuery.getProviders<CallActionsProvider>('callActions');

  return providers.reduce(
    (actions: CallAction[], provider) => [
      ...actions,
      ...provider.callActions(call),
    ],
    []
  );
}
