import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { ReactElement, memo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { stringify as stringifyQuery } from 'qs';
import { pick } from 'lodash-es';

interface Props {
  company: { ID: number; name: string };
}

function createQuery({ company }: Props): string {
  return stringifyQuery({
    defaults: { company: pick(company, ['ID', 'name']) },
  });
}

const Actions = memo(
  ({ company }: Props): ReactElement => (
    <ButtonGroup>
      <Tooltip hasArrow label="Add">
        <IconButton
          as={Link}
          to={`/phonebook/people/create?${createQuery({ company })}`}
          variant="outline"
          colorScheme="green"
          aria-label="Add"
          icon={<FaPlus />}
        />
      </Tooltip>
    </ButtonGroup>
  )
);
Actions.displayName = 'Actions';

export { Actions };
