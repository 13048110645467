import { lazyImport } from '@bq/components/lazy-import';
import { ReportsProvider } from 'app/Modules/Reporting/Assets/js/types';
import i18n from 'BootQuery/Assets/js/i18n';

const CallReportsEmbed = lazyImport(
  () => import('./CallReportsEmbed'),
  'CallReportsEmbed'
);

const QueueReports = lazyImport(() => import('./QueueReports'), 'QueueReports');

export const ProvidedReports: ReportsProvider = {
  callReports: {
    categoryTitle: () => i18n.t('Telephony:telephony_title'),
    title: () => i18n.t('Telephony:calls'),
    component: CallReportsEmbed,
  },
  queues: {
    categoryTitle: () => i18n.t('Telephony:telephony_title'),
    title: () => i18n.t('Telephony:queues'),
    component: QueueReports,
  },
};
