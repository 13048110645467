import {
  Box,
  chakra,
  CheckboxIcon,
  CheckboxProps,
  keyframes,
  SystemStyleObject,
  Text,
  useCheckbox,
  useColorModeValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';

type Props = CheckboxProps;

export const ButtonCheckbox = ({ children, ...props }: Props) => {
  const { state, getInputProps, getLabelProps, getCheckboxProps, htmlProps } =
    useCheckbox(props);
  const styles = useMultiStyleConfig('Checkbox', props);
  const defaultBorderColor = useColorModeValue('brand.50', 'brand.900');
  const checkedBorderColor = useColorModeValue('brand.200', 'brand.700');
  const hoverBg = useColorModeValue('lightGray', 'darkGray');

  return (
    <Box
      as="label"
      display="inline-flex"
      flexDirection="row"
      borderRadius="md"
      borderWidth="medium"
      borderStyle="solid"
      borderColor={state.isChecked ? checkedBorderColor : defaultBorderColor}
      _hover={{ background: hoverBg }}
      alignItems="center"
      cursor="pointer"
      px="3"
      py="2"
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <chakra.span
        __css={{ ...controlStyles, ...styles.control }}
        className="chakra-checkbox__control"
        {...getCheckboxProps()}
      >
        <CheckboxIcon
          isChecked={state.isChecked}
          __css={{ animation: `${checkAnim} 0.2s linear`, fontSize: '0.7em' }}
        />
      </chakra.span>
      &nbsp;
      <Text as="span" size="md" {...getLabelProps()}>
        {children}
      </Text>
    </Box>
  );
};

const controlStyles: SystemStyleObject = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  verticalAlign: 'top',
  userSelect: 'none',
  flexShrink: 0,
};

const checkAnim = keyframes({
  from: {
    opacity: 0,
    strokeDashoffset: 16,
    transform: 'scale(0.95)',
  },
  to: {
    opacity: 1,
    strokeDashoffset: 0,
    transform: 'scale(1)',
  },
});
