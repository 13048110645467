import { BaseItem, CloneHandler } from './types';

export const defaultCloneOriginal = <C>(prev: BaseItem<C>): BaseItem<C> => ({
  ...prev,
  id: uniqid(),
});

export const defaultCloneTarget = <C>(prev: BaseItem<C>): BaseItem<C> => ({
  ...prev,
  mode: 'move',
});

export const defaultCloneHandler: CloneHandler<unknown> = {
  cloneOriginal: defaultCloneOriginal,
  cloneTarget: defaultCloneTarget,
};

function uniqid(): string {
  return Math.random().toString(36).substring(2);
}
