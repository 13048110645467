import { useMemo } from 'react';
import { TagOption, TagOptionObject } from './types';

export function useFullOptions(inVal: TagOption[]): TagOptionObject[] {
  return useMemo(() => inVal.map(expandOption), [inVal]);
}

function expandOption(inVal: TagOption): TagOptionObject {
  return typeof inVal === 'string'
    ? { value: inVal, label: inVal }
    : { ...inVal, label: inVal.label ?? inVal.value };
}
