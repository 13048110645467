import { Box, BoxProps } from '@chakra-ui/react';
import {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useLayoutEffect,
  useMemo,
} from 'react';
import linkifyHtml from 'linkify-html';
import { useEnsureForwardedRef } from 'BootQuery/Assets/components/use-ensure-forwarded-ref';

interface Props extends BoxProps {
  html: string;
}

export const HTMLMessageContent = forwardRef(
  (props: Props, inRef: ForwardedRef<HTMLDivElement>): ReactElement => {
    const { html, ...containerProps } = props;

    const ref = useEnsureForwardedRef(inRef);
    const innerHTML = useMemo(() => ({ __html: linkifyHtml(html) }), [html]);

    useLayoutEffect(() => {
      if (!ref.current || ref.current.dataset.quotesSetup) {
        return;
      }

      ref.current.dataset.quotesSetup = 'true';
      setupQuoteCollapse(ref.current);
    }, [html, ref]);

    return (
      <Box className='html-message-content' {...containerProps} ref={ref} dangerouslySetInnerHTML={innerHTML} />
    );
  }
);
HTMLMessageContent.displayName = 'HTMLMessageContent';

function setupQuoteCollapse(rootEl: HTMLDivElement): void {
  const nodes = rootEl.querySelectorAll('.clickvox-quote-wrapper');
  nodes.forEach((el) => {
    if (!(el instanceof HTMLElement)) {
      return;
    }

    const btnEl = constructButtonEl();
    btnEl.firstElementChild?.addEventListener('click', (ev) => {
      if (!(ev instanceof MouseEvent)) {
        return;
      }

      ev.preventDefault();
      el.style.display = el.style.display === 'none' ? 'block' : 'none';
    });
    el.parentNode?.insertBefore(btnEl, el);
    el.style.display = 'none';
  });
}

function constructButtonEl() {
  return document.createRange().createContextualFragment(`
    <button type="button" class="btn btn-outline-primary btn-sm" style="line-height: 1;">
      <span class="fa fa-ellipsis-h"></span>
    </button>
  `);
}
