import { getNumberTypeById } from './number-types';

export function validateNationalNumber(typeId: string, value: string): boolean {
  if (!typeId || !value) {
    return true;
  }

  const validator = getNumberTypeById(typeId);
  if (!validator) {
    console.error('Unknown national number validator: ', validator);

    return true;
  }

  return validator.validator.validate(value).isValid;
}
