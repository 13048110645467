import { Box, HStack, Switch, Tooltip } from '@chakra-ui/react';
import { omit } from 'lodash-es';
import { ChangeEvent, ReactElement, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FaBell, FaHeadphones, FaMicrophone } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { UseMediaDevicesResult } from '../../softphone/use-media-devices';
import { DevicePresets } from '../../types';
import { DeviceInput } from '../DeviceInput';
import { usePresetIcon, usePresetLabel } from '../presets';

interface Props {
  preset: keyof DevicePresets;
  mediaDevices: UseMediaDevicesResult;
}

export const PresetFormRow = ({
  preset,
  mediaDevices,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { control } = useFormContext<DevicePresets>();
  const Icon = usePresetIcon(preset);

  const enabledController = useController({
    control,
    name: `${preset}.enabled`,
  });
  const onEnabledChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      console.log('Enabled Change: ', ev.target.checked);
      enabledController.field.onChange(ev.target.checked);
    },
    [enabledController]
  );

  const outputController = useController({
    control,
    name: `${preset}.audioOutput`,
  });
  const inputController = useController({
    control,
    name: `${preset}.audioInput`,
  });
  const ringController = useController({
    control,
    name: `${preset}.ring`,
  });

  const {
    audioOutputDevices,
    audioInputDevices,
    canSetOutputs,
    selectAudioOutputSupported,
  } = mediaDevices;
  const canSetOutput =
    (audioOutputDevices.length > 0 && canSetOutputs) ||
    selectAudioOutputSupported;

  return (
    <HStack spacing="5" mb="8">
      <Switch
        mb={0}
        isChecked={enabledController.field.value}
        onChange={onEnabledChange}
      />
      <Box fontSize="xl" px="3" py="2">
        <Tooltip hasArrow label={usePresetLabel(preset)}>
          <span>
            <Icon />
          </span>
        </Tooltip>
      </Box>
      {canSetOutput && (
        <Box flex="1 0 0" overflowX="hidden">
          <DeviceInput
            type="output"
            id="outputDevice"
            label={t('Telephony:settings.output_device')}
            icon={<FaHeadphones />}
            devices={audioOutputDevices}
            selectAudioOutputSupported={selectAudioOutputSupported}
            {...omit(outputController.field, 'ref')}
          />
        </Box>
      )}

      <Box flex="1 0 0" overflowX="hidden">
        <DeviceInput
          type="input"
          id="inputDevice"
          label={t('Telephony:settings.input_device')}
          icon={<FaMicrophone />}
          devices={audioInputDevices}
          {...omit(inputController.field, 'ref')}
        />
      </Box>

      {canSetOutput && (
        <Box flex="1 0 0" overflowX="hidden">
          <DeviceInput
            type="output"
            id="ringDevice"
            label={t('Telephony:settings.ring_device')}
            icon={<FaBell />}
            devices={audioOutputDevices}
            selectAudioOutputSupported={selectAudioOutputSupported}
            {...omit(ringController.field, 'ref')}
          />
        </Box>
      )}
    </HStack>
  );
};
