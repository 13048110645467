import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

import { BlacklistEdit } from './BlacklistEdit';
import { FormEdit } from './FormEdit';
import { OverviewsEdit } from './OverviewsEdit';

export const Settings = (): ReactElement => (
  <Box>
    <OverviewsEdit />
    &nbsp;
    <FormEdit />
    &nbsp;
    <BlacklistEdit />
  </Box>
);
