import { Box, Button, FormLabel, VStack } from '@chakra-ui/react';
import { ReactElement, useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { LinkedContactRow } from './LinkedContactRow';
import { CustomEventFormData } from './types';

export const LinkedContactsField = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { control } = useFormContext<CustomEventFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts',
  });

  const addContact = useCallback(() => {
    append({ contact: null });
  }, [append]);

  return (
    <Box>
      <FormLabel fontWeight="bold">{t('Events:contacts')}:</FormLabel>
      <VStack alignItems="stretch">
        {fields.map((field, idx) => (
          <LinkedContactRow key={field.id} idx={idx} onRemove={remove} />
        ))}
        <Button
          colorScheme="green"
          size="sm"
          variant="ghost"
          onClick={addContact}
        >
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('global:add')}
        </Button>
      </VStack>
    </Box>
  );
};
