import { Box } from '@chakra-ui/react';
import { chakraComponents, SingleValueProps } from 'chakra-react-select';
import { TicketStateSelectOption } from './types';

export const TicketValue = (
  props: SingleValueProps<TicketStateSelectOption>
) => {
  const { color, label } = props.data;

  return (
    <chakraComponents.SingleValue {...props}>
      <Box
        textAlign="center"
        flex="1 1 auto"
        whiteSpace="nowrap"
        color="white"
        bg={color}
        rounded="sm"
        py="0.5"
        px="2"
        fontWeight="bold"
        fontSize="xs"
      >
        {label}
      </Box>
    </chakraComponents.SingleValue>
  );
};
