import { useCallback, useEffect, useState } from 'react';
import * as LocalStorage from 'BootQuery/Assets/js/localStorage';

export function useLocalStorage<T>(
  key: string,
  initial: T
): [current: T, setValue: (val: T) => void] {
  const [localVal, setLocalVal] = useState(LocalStorage.get(key, initial));

  const setValue = useCallback(
    (newVal: T) => {
      setLocalVal(newVal);
      LocalStorage.set(key, newVal);
    },
    [key]
  );

  useEffect(() => {
    setLocalVal(LocalStorage.get(key, initial));
  }, [key, setLocalVal, initial]);

  return [localVal as T, setValue];
}
