import { useEffect, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import { UseFormReturn } from 'react-hook-form';

import { useValueHasChanged } from 'BootQuery/Assets/js/use-value-has-changed';

import { ImportSetupForm, PreviewRow } from '../types';
import { cellToText, genColumnsFromHeader, genEmptyColumns } from './util';

export interface UseSyncColumnsProps {
  rows: PreviewRow[] | undefined;
  header: PreviewRow | null;
  formMethods: UseFormReturn<ImportSetupForm>;
}

/**
 * Automatically set form's `columns` value based on column count from preview,
 * or fill title defaults from header if `firstRowIsHeader`
 */
export function useSyncColumns({
  rows,
  header,
  formMethods: { control, setValue, getValues },
}: UseSyncColumnsProps) {
  const dataColumnCount = header?.length ?? rows?.[0]?.length ?? 0;
  const headerTitles = useHeaderTitles(header);

  // Track changes in a ref, because if we get multiple re-renders, the effect
  // will only fire after last one, potentially missing the change
  const changedRef = useRef(false);
  if (useValueHasChanged(dataColumnCount, { valuesAreEqual: isEqual })) {
    changedRef.current = true;
  }
  if (useValueHasChanged(headerTitles, { valuesAreEqual: isEqual })) {
    changedRef.current = true;
  }

  useEffect(() => {
    if (!changedRef.current) {
      return;
    }
    changedRef.current = false;

    if (dataColumnCount === 0) {
      // Data not yet loaded
      return;
    }

    const currentColumns = getValues('columns');

    if (header) {
      if (!headerTitles) {
        throw new Error('Header and header titles are out of sync');
      }

      if (header.length === currentColumns.length) {
        // Just set titles
        setValue(
          'columns',
          currentColumns.map((col, idx) => ({
            ...col,
            title: headerTitles[idx],
          }))
        );
      } else {
        // Number of columns changed for some reason, reset all columns with
        // defaults from header
        setValue('columns', genColumnsFromHeader(header));
      }
    } else if (dataColumnCount !== currentColumns.length) {
      // Number of columns changed for some reason, reset all columns with
      // empty values
      setValue('columns', genEmptyColumns(dataColumnCount));
    } else {
      // Clear titles when header is removed
      setValue(
        'columns',
        currentColumns.map((col) => ({ ...col, title: '' }))
      );
    }
  });
}

function useHeaderTitles(header: PreviewRow | null): string[] | null {
  return useMemo(() => {
    if (!header) {
      return null;
    }

    return header.map(cellToText);
  }, [header]);
}
