import { useCallback } from 'react';

import { addEventToCase, removeEventFromCase } from './case-event';
import { EventData } from './types';
import { useFormEventChanges } from './use-form-event-changes';

export interface UseEventChangesResult {
  addCaseEvent: (event: EventData) => void;
  removeCaseEvent: (event: EventData) => void;
}

type RemoveEvent = (type: string, id: string) => void;

export function useEventChanges(
  removeEvent: RemoveEvent,
  caseID: number | null
): UseEventChangesResult {
  const { addFormEvent, removeFormEvent } = useFormEventChanges();

  const addCaseEvent = useCallback(
    (event: EventData) => {
      if (caseID) {
        addEventToCase(caseID, event);
      } else {
        addFormEvent(event);
      }
    },
    [caseID, addFormEvent]
  );

  const removeCaseEvent = useCallback(
    (event: EventData) => {
      if (caseID) {
        removeEventFromCase(caseID, event);
      } else {
        removeFormEvent(event);
      }

      switch (event.type) {
        case 'call':
          removeEvent(event.type, event.callID.toString());
          break;
        case 'mail':
          removeEvent(event.type, event.mailID.toString());
          break;
        case 'chat':
          removeEvent(event.type, event.chatID.toString());
          break;
        case 'custom':
          removeEvent(event.type, event.eventID.toString());
          break;
        case 'appointment':
          removeEvent(event.type, event.appointmentID.toString());
          break;
        default:
          break;
      }
    },
    [caseID, removeFormEvent, removeEvent]
  );

  return { addCaseEvent, removeCaseEvent };
}
