import {
  ColumnMap,
  DisplayMode,
  TableDensity,
} from 'BootQuery/Assets/components/TableMenu';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { SortValue } from '../Table/types';

export function useColumnVisibility<
  T extends string | number | symbol = string,
>(moduleName: string, viewName = 'table', defaultVal: ColumnMap<T> = {}) {
  return useUserSetting<ColumnMap<T>>(
    `${moduleName}.${viewName}.columns`,
    defaultVal,
    false
  );
}

export function useDisplayMode(
  moduleName: string,
  defaultMode: DisplayMode = 'table'
) {
  return useUserSetting<DisplayMode>(
    `${moduleName}.displayMode`,
    defaultMode,
    false
  );
}

export function useLimit(
  moduleName: string,
  viewName = 'table',
  defaultVal = 10
) {
  return useUserSetting(`${moduleName}.${viewName}.limit`, defaultVal, false);
}
export function useDensity(
  moduleName: string,
  viewName = 'table',
  defaultVal: TableDensity = 'md'
) {
  return useUserSetting(`${moduleName}.${viewName}.density`, defaultVal, false);
}

export function useSort(
  moduleName: string,
  viewName = 'table',
  defaultVal: SortValue[] = []
) {
  return useUserSetting<SortValue[]>(
    `${moduleName}.${viewName}.sort`,
    defaultVal,
    false
  );
}
