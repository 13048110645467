import { useMemo } from 'react';
import { SelectSettings } from './types';

export function parentPrefix(fullParentId: string): string | null {
  const path = fullParentId.split('.').slice(0, -1);

  return path.length ? path.join('.') : null;
}

export function prefixedSelects(fieldId: string, selects: SelectSettings[]) {
  const prefix = parentPrefix(fieldId);

  if (!prefix) {
    return selects;
  }

  return selects.map((select) => ({ ...select, id: `${prefix}.${select.id}` }));
}

export function usePrefixedSelects(fieldId: string, selects: SelectSettings[]) {
  return useMemo(() => prefixedSelects(fieldId, selects), [fieldId, selects]);
}
