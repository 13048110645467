import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getStates } from '../../api/State/getStates';
import { CaseState } from '../../schemas';

export function useStates(): UseQueryResult<ListingResponse<CaseState>> {
  return useQuery({
    queryKey: ['Sales.settings.states'],
    queryFn: () => getStates(),
  });
}
