import { ReactElement } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useFormDefinition } from '@bq/components/FormEditor';
import { Card, CardBody, CardHeader } from 'BootQuery/Assets/components/Card';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { useOperatorCallFilters } from './api';
import { ContactSettingsButton } from './ContactSettings';
import { OperatorCallFilters } from './OperatorCallFilters';

export const OperatorSettings = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { filters, setFilters } = useOperatorCallFilters();
  const customFields = useFormDefinition('Telephony.callForm');

  if (!filters || !customFields) {
    return <LoadingPage />;
  }

  return (
    <VStack width="full" spacing="6" alignItems="stretch">
      <Box>
        <ContactSettingsButton />
      </Box>
      <Card>
        <CardHeader>{t('Telephony:settings.call_filters')}</CardHeader>
        <CardBody>
          <OperatorCallFilters
            filters={filters}
            setFilters={setFilters}
            customFields={customFields}
          />
        </CardBody>
      </Card>
    </VStack>
  );
};
