import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal/use-modal';

import { patchProduct } from '../../API/Product/patchProduct';
import { postProduct } from '../../API/Product/postProduct';
import { LoadedItem, PartialItem } from '../../types';
import { useParseToRequestData } from '../../utils';
import { FormModalWrapper } from '../FormFields/FormModalWrapper';
import { EditProductModal } from './EditProductModal';

export const useProductModal = () => {
  const { addModal } = useModal();
  const parseToRequestData = useParseToRequestData();

  const productCreateModal = useCallback(
    (callback: (data: LoadedItem) => void) => {
      addModal({
        children: <FormModalWrapper mode="create" />,
        preventClose: {
          defaultPreventState: false,
        },
        componentProps: {
          modal: { size: '6xl' },
        },
        callback: async (data: LoadedItem) => {
          const newData = await postProduct<LoadedItem>({
            data: parseToRequestData(data),
          });
          callback(newData);
        },
      });
    },
    [addModal, parseToRequestData]
  );

  const productEditModalProvidedData = useCallback(
    (data: PartialItem, callback: (data: LoadedItem) => void) => {
      addModal({
        children: <FormModalWrapper mode="edit" data={data} />,
        preventClose: {
          defaultPreventState: false,
        },
        componentProps: {
          modal: { size: '6xl' },
        },
        callback: async (data: LoadedItem) => {
          const newData = await patchProduct<LoadedItem>({
            productID: data.ID as number,
            data: parseToRequestData(data),
          });
          callback(newData);
        },
      });
    },
    [addModal, parseToRequestData]
  );

  const productEditModal = useCallback(
    (ID: number, callback: (data: LoadedItem) => void) => {
      addModal({
        children: <EditProductModal ID={ID} />,
        preventClose: {
          defaultPreventState: false,
        },
        componentProps: {
          modal: { size: '6xl' },
        },
        callback: async (data: LoadedItem) => {
          const newData = await patchProduct<LoadedItem>({
            productID: data.ID as number,
            data: parseToRequestData(data),
          });
          callback(newData);
        },
      });
    },
    [addModal, parseToRequestData]
  );

  return { productEditModalProvidedData, productEditModal, productCreateModal };
};
