import { ReactElement, useCallback } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FaAddressBook } from 'react-icons/fa';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import {
  ApiContactCategory,
  ContactCategory,
} from '../../HopsOperator/HopsContacts/types';
import { categoriesWithFixedSizeContacts } from '../../HopsOperator/HopsContacts/util';
import { getGroups, getSettingsCategories } from './api';
import { ContactSettingsContent } from './ContactSettingsContent';
import { categoryForSave } from './util';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ContactSettingsModal = ({
  isOpen,
  onClose,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [, syncValue] = useDOMInputJSON<ApiContactCategory[]>(
    '#settings-form',
    'telephonyOperatorCategories'
  );
  const { data: categories } = useQuery({
    queryKey: ['Telephony.settings.operatorCategories'],
    queryFn: getSettingsCategories,
    enabled: isOpen,
  });
  const { data: groups } = useQuery({
    queryKey: ['Telephony.settings.userGroups'],
    queryFn: getGroups,
    enabled: isOpen,
  });

  const cancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const submit = useCallback(
    (categories: ContactCategory[]) => {
      console.log('Submit: ', categories);
      const toStore = categories.map(categoryForSave);
      syncValue(toStore);
      onClose();
    },
    [onClose, syncValue]
  );

  return (
    <>
      <Modal
        size="6xl"
        isOpen={isOpen}
        onClose={cancel}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent height="calc(90dvh - var(--navbar-height))">
          <ModalHeader display="flex" alignItems="center">
            <FaAddressBook />
            &nbsp;
            {t('Telephony:settings.contacts')}
          </ModalHeader>
          {categories && groups ? (
            <ContactSettingsContent
              initialCategories={categoriesWithFixedSizeContacts(categories)}
              groups={groups}
              onSubmit={submit}
              onCancel={cancel}
            />
          ) : (
            <ModalBody>
              <LoadingPage />
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
