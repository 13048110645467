import { renderReact } from 'BootQuery/Assets/js/render-react';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';
import mitt from 'mitt';
import {
  SelectTicketOptions,
  TicketPickerConnector,
  TicketSelectHandler,
} from '../components/TicketPickerModal';

export async function pickTicket(
  onSelect: TicketSelectHandler,
  options?: SelectTicketOptions
): Promise<void> {
  await ensureRootExists();

  pickTicketEventBus.emit('pickTicket', { onSelect, options });
}

export type PickTicketEventBus = {
  pickTicket: {
    onSelect: TicketSelectHandler;
    options?: SelectTicketOptions;
  };
};

export const pickTicketEventBus = mitt<PickTicketEventBus>();
export const usePickTicketEvent = createUseMitt(pickTicketEventBus);

async function ensureRootExists(): Promise<void> {
  const existing = document.querySelector('#ticket-picker-connector-root');
  if (existing) {
    return;
  }

  const root = document.createElement('div');
  root.id = 'ticket-picker-connector-root';
  document.body.append(root);

  await new Promise<void>((resolve) => {
    renderReact(root, TicketPickerConnector, {});
    setTimeout(() => {
      resolve();
    });
  });
}
