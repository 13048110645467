import { ReactElement } from 'react';

import { useFormDefinition } from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { PageWrapper } from '@bq/components/PageWrapper';
import { useNumericPathParam } from 'app/assets/js/use-path-param';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { DatasetEntryEditForm } from '../components/DatasetEntryForm';
import { useDatasetEntry } from '../components/DatasetEntryForm/use-dataset-entry';
import { useDataset } from '../components/DatasetForm/use-dataset';

export const DatasetEntryEdit = (): ReactElement => {
  const entryID = useNumericPathParam('entryID');
  const datasetID = useNumericPathParam('datasetID');

  const { data: dataset } = useDataset(datasetID);
  const { data: entry } = useDatasetEntry(datasetID, entryID);
  const formDefinition = useFormDefinition(`Datasets.${datasetID}`);

  const datasetTitle = dataset?.name ?? '...';
  const entryTitle = entry?.displayName ?? '...';
  useAppTitle(`Edit ${entryTitle} | ${datasetTitle} | Datasets`);

  return (
    <PageWrapper>
      {dataset && entry && formDefinition ? (
        <DatasetEntryEditForm
          entry={entry}
          dataset={dataset}
          formDefinition={formDefinition}
        />
      ) : (
        <LoadingPage />
      )}
    </PageWrapper>
  );
};
