import { ReactElement } from 'react';
import {
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DateInput } from '@bq/components/DateInput';

import { AppointmentAttendees } from './AppointmentAttendees';
import { AppointmentLocationField } from './AppointmentLocationField';
import { AppointmentRemindersField } from './AppointmentRemindersField';
import { useAppointmentFormContext } from './form';

export const AppointmentFormModalFields = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { formState, register, control } = useAppointmentFormContext();

  const startAtController = useController({ control, name: 'startAt' });
  const endAtController = useController({ control, name: 'endAt' });

  return (
    <VStack spacing="5" alignItems="stretch">
      <FormControl isInvalid={!!formState.errors.title}>
        <FormLabel fontWeight="bold">{t('global:title')}:</FormLabel>
        <Input {...register('title', { required: true })} />
        {formState.errors.startAt?.message && (
          <FormErrorMessage>
            {formState.errors.startAt.message}
          </FormErrorMessage>
        )}
      </FormControl>
      <HStack w="full">
        <FormControl isInvalid={!!formState.errors.startAt}>
          <FormLabel fontWeight="bold">
            {t('Events:appointment_start_at')}:
          </FormLabel>
          <DateInput
            showTime
            value={startAtController.field.value}
            onChange={(value) => startAtController.field.onChange(value)}
          />
          {formState.errors.startAt?.message && (
            <FormErrorMessage>
              {formState.errors.startAt.message}
            </FormErrorMessage>
          )}
        </FormControl>
        <FormControl isInvalid={!!formState.errors.endAt}>
          <FormLabel fontWeight="bold">
            {t('Events:appointment_end_at')}:
          </FormLabel>
          <DateInput
            showTime
            value={endAtController.field.value}
            onChange={(value) => endAtController.field.onChange(value)}
          />
          {formState.errors.endAt?.message && (
            <FormErrorMessage>
              {formState.errors.endAt.message}
            </FormErrorMessage>
          )}
        </FormControl>
      </HStack>
      <Divider />
      <AppointmentRemindersField />
      <Divider />
      <AppointmentLocationField />
      <Divider />
      <AppointmentAttendees />
    </VStack>
  );
};
