import { Dispatch, SetStateAction, useCallback } from 'react';
import { useDependencies } from './DependenciesContext';

export function useTicketExpanded(
  ticketID: number
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const { expandedTickets, setExpanded } = useDependencies();

  const isExpanded = expandedTickets[ticketID] ?? false;
  const setIsExpanded = useCallback(
    (expanded: SetStateAction<boolean>) => {
      setExpanded(ticketID, expanded);
    },
    [setExpanded, ticketID]
  );

  return [isExpanded, setIsExpanded];
}
