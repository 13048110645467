import { CSSProperties, memo, ReactElement, useMemo } from 'react';
import {
  TableProps,
  TableRowProps,
  Td,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import { Link } from 'react-router-dom';

import { getCellValue, getCellValueString } from './cell-value';
import {
  selectableText,
  tdStyle,
  tdStyleStatic,
  wholeRowAnchor,
} from './styles';
import type { CellProps, Column, LinkProps, Row } from './types';

interface Props<RowType = Row> extends TableRowProps {
  columns: Column<RowType>[];
  rowData: RowType;
  rowIdx: number;
  rowLink?: string | LinkProps | null;
  rowLinkTextSelect?: boolean;
  style?: CSSProperties;
  className?: string;
  tableSize?: TableProps['size'];
  isStatic?: boolean;
}

interface TableCellProps<RowType> {
  column: Column<RowType>;
  rowData: RowType;
  rowIdx: number;
  rowLink?: string | LinkProps | null;
  rowLinkClass: string;
  tableSize?: TableProps['size'];
  isStatic?: boolean;
}

export function CellValue<RT>(props: CellProps<RT>): ReactElement {
  if (props.column.Cell) {
    return <props.column.Cell {...props} />;
  }

  return <span>{getCellValue(props)}</span>;
}

interface RowLinkProps {
  rowLink: string | LinkProps;
  rowLinkClass: string;
}

const RowLink = ({ rowLink, rowLinkClass }: RowLinkProps): ReactElement => {
  const state = typeof rowLink === 'string' ? undefined : rowLink.state;
  const link = typeof rowLink === 'string' ? rowLink : rowLink.link;

  return (
    <Link
      to={link}
      state={state}
      className={rowLinkClass}
      tabIndex={-1}
      aria-hidden
    />
  );
};

function TableCellInner<RowType>({
  column,
  rowLink,
  rowLinkClass,
  rowData: row,
  rowIdx,
  tableSize,
  isStatic,
}: TableCellProps<RowType>): ReactElement {
  return (
    <Td
      transition="padding 0.3s"
      className={isStatic ? tdStyleStatic : tdStyle}
      title={getCellValueString({ column, row, rowIdx, tableSize })}
      {...(column.tdProps ?? {})}
    >
      {rowLink && (
        // These are hidden from screen readers, we should fallback to normal link on each row
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        <RowLink rowLink={rowLink} rowLinkClass={rowLinkClass} />
      )}
      <CellValue
        column={column}
        row={row}
        rowIdx={rowIdx}
        tableSize={tableSize}
      />
    </Td>
  );
}

const TableCell = memo(TableCellInner, isEqual) as typeof TableCellInner;

function TableRow<RowType>({
  columns,
  rowIdx,
  rowData,
  rowLink,
  style,
  className,
  rowLinkTextSelect = false,
  tableSize,
  isStatic,
  ...trProps
}: Props<RowType>): JSX.Element {
  const hoverBg = useColorModeValue('gray.50', 'gray.700');
  const rowLinkClass = rowLinkTextSelect
    ? `${wholeRowAnchor} ${selectableText}`
    : wholeRowAnchor;

  const hoverStyle = useMemo(
    () => (rowLink ? { bg: hoverBg } : undefined),
    [hoverBg, rowLink]
  );

  return (
    <Tr _hover={hoverStyle} {...trProps} className={className} style={style}>
      {columns.map((column) => (
        <TableCell
          isStatic={isStatic}
          key={column.key}
          column={column}
          rowData={rowData}
          rowIdx={rowIdx}
          rowLink={rowLink}
          rowLinkClass={rowLinkClass}
          tableSize={tableSize}
        />
      ))}
    </Tr>
  );
}

const TableRowMemo = memo(TableRow, isEqual) as typeof TableRow;

export { TableRowMemo as TableRow };
