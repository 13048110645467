import { ReactElement } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useToggle } from 'react-use';

import { GeneratedForm } from 'BootQuery/Assets/components/FormEditor';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { useCustomEventForm } from './custom-forms';
import { EventAtField } from './EventAtField';
import { EventTypeInput } from './EventTypeInput';
import { LinkedCasesField } from './LinkedCasesField';
import { LinkedContactsField } from './LinkedContactsField';
import { LinkedEventsField } from './LinkedEventsField';
import { LinkedTicketsField } from './LinkedTicketsField';
import { CustomEventFormData, CustomEventType } from './types';

interface Props {
  customEventTypes: CustomEventType[];
}

export const EventFormFields = ({ customEventTypes }: Props): ReactElement => {
  const { t } = useTranslation('Events');
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CustomEventFormData>();
  const [sidebarExpanded, toggleSidebarExpanded] = useToggle(false);
  const formType = useWatch({ control, name: 'type' });
  const customFields = useCustomEventForm(
    formType?.value ?? customEventTypes[0].ID
  );

  if (!customEventTypes) {
    return <LoadingPage />;
  }

  const sidebarCols = sidebarExpanded
    ? expandedSidebarCols
    : collapsedSidebarCols;

  return (
    <Grid gridTemplateColumns={sidebarCols}>
      <Box
        px={sidebarExpanded ? 3 : 1}
        py={2}
        position="relative"
        bg="gray.100"
        _dark={{ bg: 'gray.800' }}
      >
        <Heading
          as="h1"
          sx={{
            writingMode: sidebarExpanded ? 'horizontal-tb' : 'vertical-rl',
          }}
          textAlign="center"
          fontSize="lg"
        >
          {t('Events:event_links')}
        </Heading>
        <IconButton
          size="sm"
          minWidth="24px"
          position="absolute"
          top="calc(50% - 16px)"
          right="-25px"
          label={t('global:expand')}
          icon={sidebarExpanded ? <FaChevronLeft /> : <FaChevronRight />}
          onClick={toggleSidebarExpanded}
          zIndex={1}
        />
        <Box hidden={!sidebarExpanded}>
          <LinkedContactsField />
          <LinkedTicketsField />
          <LinkedEventsField />
          <LinkedCasesField />
        </Box>
      </Box>
      <VStack alignItems="stretch" px={3} py={2} pl="32px">
        <EventTypeInput availableTypes={customEventTypes} />
        <HStack>
          <Box flex="3 1 auto">
            <FormControl>
              <FormLabel fontWeight="bold">
                <>{t('global:name')}:</>
              </FormLabel>
              <Input
                {...register('title', {
                  required: true,
                })}
                isInvalid={!!errors.title}
                placeholder={t('global:name')}
              />
            </FormControl>
          </Box>
          <Box flex="1 1 auto">
            <EventAtField />
          </Box>
        </HStack>
        <Divider />
        {customFields ? (
          <GeneratedForm fields={customFields} />
        ) : (
          <LoadingPage />
        )}
      </VStack>
    </Grid>
  );
};

const collapsedSidebarCols = '32px 4fr';
const expandedSidebarCols = '1fr 4fr';
