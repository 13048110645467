import { ReactElement, useMemo } from 'react';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';
import { upperFirst } from 'lodash-es';
import { ErrorBoundary } from 'react-error-boundary';

import { Card } from 'BootQuery/Assets/components/Card';
import { CellProps, Column, Table } from 'BootQuery/Assets/components/Table';
import { TableDensity } from 'BootQuery/Assets/components/TableMenu';
import i18n from 'BootQuery/Assets/js/i18n';

import { useEventTypes } from '../event-types';
import { Event, EventTypeProvider } from '../types';
import { EventErrorFallback } from './EventErrorFallback';

interface Props {
  events: Event[];
  size?: TableDensity;
}

export const EventsTable = ({ events, size }: Props): ReactElement => {
  const columns = useColumns();

  return (
    <Card>
      <Table<Event> size={size} columns={columns} rows={events} />
    </Card>
  );
};

export const getColumns = (): Column<Event>[] => [
  {
    key: 'timestamp',
    title: i18n.t('Events:columns.timestamp'),
    getValue: ({ row }) => formatDate(row.timestamp),
    width: '200px',
  },
  {
    key: 'type',
    title: i18n.t('Events:columns.type'),
    width: '180px',
    Cell: makeEventCell('type'),
  },
  {
    key: 'event',
    title: i18n.t('Events:columns.event'),
    Cell: makeEventCell('event'),
  },
  {
    key: 'user',
    title: i18n.t('Events:columns.user'),
    width: '20%',
    Cell: makeEventCell('user'),
  },
  {
    key: 'contact',
    title: i18n.t('Events:columns.contact'),
    width: '20%',
    Cell: makeEventCell('contact'),
  },
  {
    key: 'actions',
    title: '',
    width: '192px',
    Cell: makeEventCell('actions'),
    tdProps: { py: 0, px: 1, textAlign: 'center' },
  },
];

const useColumns = () => useMemo(getColumns, []);

export function makeEventCell(cellKey: keyof EventTypeProvider['columns']) {
  const Cell = (props: CellProps<Event>) => {
    const eventTypes = useEventTypes();
    const eventDef = eventTypes[props.row.type];

    if (!eventDef) {
      throw new Error(`Unknown event type ${props.row.type}`);
    }

    const column = eventDef.columns[cellKey];
    if (!column) {
      return <></>;
    }

    if (column.Cell) {
      return <column.Cell {...props} />;
    }
    if (column.getValue) {
      return <>{column.getValue(props)}</>;
    }

    return <></>;
  };
  Cell.displayName = `${upperFirst(cellKey)}Cell`;

  const WrappedCell = (props: CellProps<Event>) => (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => (
        <EventErrorFallback
          view="table"
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      <Cell {...props} />
    </ErrorBoundary>
  );
  WrappedCell.displayName = `Wrapped${upperFirst(cellKey)}Cell`;

  return WrappedCell;
}

const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Pp', { locale: hr });
};
