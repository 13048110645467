import { ReactElement } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
} from '@chakra-ui/react';
import { phoneNumberValid } from 'app/assets/js/tsutil';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { useTranslation } from 'react-i18next';
import { NumberType, PhoneNumberRow } from './types';
import { useNumberTypes } from './use-number-types';

interface FormWithNumbers {
  phoneNumbers: PhoneNumberRow[];
}

interface NumberRowProps {
  idx: number;
  onRemove: () => void;
  numberTypes: NumberType[];
  isReadonly?: boolean;
}

function validateNumber(
  numberTypes: NumberType[],
  numRow: PhoneNumberRow
): boolean {
  const typeID = parseInt(numRow.type, 10);
  const type = numberTypes.find((numType) => numType.ID === typeID);

  if (!type) {
    return false;
  }

  if (!type.isFullNumber) {
    return true;
  }

  return phoneNumberValid(numRow.phoneNumber);
}

const NumberRow = ({
  idx,
  onRemove,
  numberTypes,
  isReadonly,
}: NumberRowProps): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<FormWithNumbers>();

  const numberErrors = errors?.phoneNumbers?.find
    ? errors.phoneNumbers.find((_row, errIdx) => errIdx === idx)
    : undefined;
  const isInvalid = !!numberErrors?.phoneNumber;

  return (
    <FormControl as={HStack} isDisabled={isReadonly} w="full" mb="0.5" py="3">
      <Input
        isInvalid={isInvalid}
        {...register(`phoneNumbers.${idx}.phoneNumber`, {
          validate: (val) => {
            if (!val) {
              return true;
            }
            const thisRow = getValues().phoneNumbers[idx];

            return validateNumber(numberTypes, thisRow);
          },
        })}
        placeholder={t('Phonebook:phone_number')}
      />
      <Select
        {...register(`phoneNumbers.${idx}.type`, {
          required: true,
          deps: [`phoneNumbers.${idx}.phoneNumber`],
        })}
      >
        {numberTypes.map((type) => (
          <option key={type.ID} value={type.ID.toString()}>
            {type.name}
          </option>
        ))}
      </Select>
      <DeleteButton isDisabled={isReadonly} onClick={onRemove} />
    </FormControl>
  );
};

export const FormPhoneNumbers = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const numberTypes = useNumberTypes();
  const { control } = useFormContext<FormWithNumbers>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'phoneNumbers',
  });

  if (!numberTypes) {
    return <LoadingPage />;
  }

  const addRow = () => {
    append({
      phoneNumber: '',
      type: numberTypes[0].ID.toString(),
    });
  };

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{t('Phonebook:phone_numbers')}:</FormLabel>

      {fields.map((field, idx) => (
        <NumberRow
          key={field.id}
          idx={idx}
          onRemove={() => remove(idx)}
          numberTypes={numberTypes}
          isReadonly={field.isReadonly}
        />
      ))}

      <Flex justifyContent="flex-end">
        <Button
          colorScheme="green"
          bg="#34CB7F"
          onClick={addRow}
          verticalAlign="middle"
          rightIcon={<FaPlus />}
        >
          {t('global:add')}
        </Button>
      </Flex>
    </FormControl>
  );
};
