import mitt from 'mitt';

import { CallForm, CallFormSubmit } from './types';

export type CallFormEvents = {
  callEdit: CallForm;
  submit: CallFormSubmit;
};

export const callFormEvents = mitt<CallFormEvents>();
