import { createContext, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { fetchAllSelectItems } from './api';
import { applyAllItemChanges, groupChangesBySelect } from './apply-item-changes';
import { SelectChangeMethods, useSelectMethods } from './select-methods';
import { findSelectedPath } from './selected-path';
import {
  ItemsBySelect,
  SelectedItem,
  SelectFieldEditorSettings,
} from './types';

interface ISelectFieldSettingsContext {
  settings: SelectFieldEditorSettings;
  methods: SelectChangeMethods;
  selectedItem: SelectedItem | null;
  setSelectedItem: (selected: SelectedItem | null) => void;
  items: ItemsBySelect | null;
}

const noContext = (): never => {
  throw new Error('DashboardContext not initialised');
};

export const SelectFieldSettingsContext = createContext<
  ISelectFieldSettingsContext
>({
  settings: { name: '', selects: [], required: false },
  methods: {
    addItem: noContext,
    deleteItem: noContext,
    updateItem: noContext,
    addSelect: noContext,
    deleteSelect: noContext,
    renameSelect: noContext,
  },
  selectedItem: null,
  setSelectedItem: noContext,
  items: null,
});

interface Props {
  initial: SelectFieldEditorSettings;
}

export const SelectFieldSettingsProvider = ({
  children,
  initial,
}: PropsWithChildren<Props>): ReactElement => {
  const [settings, methods] = useSelectMethods(initial);
  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
  const [items, setItems] = useState<ItemsBySelect | null>(null);

  useEffect(() => {
    fetchAllSelectItems(settings.selects).then(setItems);

    // Empty deps on purpose, we only want to fetch items on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Reset selected item when currently selected item or it's parent is deleted
  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    const changes = groupChangesBySelect(settings.selects);
    const allItems = applyAllItemChanges(items ?? {}, changes);
    const path = findSelectedPath(allItems, settings.selects, selectedItem);

    let shouldReset = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const segment of path) {
      const segSelectItems = allItems[segment.selectId];
      const itemExists = segSelectItems.some((item) => item.key === segment.itemId);
      if (!itemExists) {
        shouldReset = true;
        break;
      }

      if (shouldReset) {
        setSelectedItem(null);
      }
    }
  }, [settings.selects, selectedItem, items]);

  return (
    <SelectFieldSettingsContext.Provider
      value={{ methods, settings, items, selectedItem, setSelectedItem }}
    >
      {children}
    </SelectFieldSettingsContext.Provider>
  );
};
