import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import {
  ForwardHandler,
  MailEditor,
  mailEditorEventProviders,
  MailEditorMap,
  MailEditorsProvider,
  NewMessageParams,
  ReplyHandler,
  SentHandler,
  useMailEditors,
} from './MailEditor';
import { mailEventProvider } from './MailEvent';
import { MailEvent } from './MailEvent/types';

export type {
  MailEvent,
  MailEditor,
  MailEditorMap,
  NewMessageParams,
  ReplyHandler,
  ForwardHandler,
  SentHandler,
};

export { MailEditorsProvider, useMailEditors };

export const mailEventProviders: ModuleEventTypeProviders = {
  ...mailEventProvider,
  ...mailEditorEventProviders,
};
