import { ReactElement } from 'react';
import { Flex, Link, Text, Tooltip } from '@chakra-ui/react';
import { FaExclamation, FaUsers } from 'react-icons/fa';

import { useFeatureFlag } from '@bq/components/use-feature-flag';
import { formatNumber } from 'app/assets/js/tsutil';

import { useContactForNumber } from './hooks';
import { CallDisplayProps } from './types';
import { useCallDisplayProperties } from './use-call-display-properties';

export const RemotePartyDisplay = (
  callInfo: CallDisplayProps
): ReactElement => {
  const alwaysShowDisplayName = useFeatureFlag(
    'telephony_always_show_display_name'
  );

  const { phoneNumber, displayName, conference, error } =
    useCallDisplayProperties(callInfo);

  const { contact } = useContactForNumber(phoneNumber);
  const numberDisplay = phoneNumber ? formatNumber(phoneNumber) : 'Unknown';

  if (error) {
    return (
      <Flex
        noOfLines={1}
        color="red.400"
        justifyContent="center"
        alignItems="center"
      >
        <FaExclamation />
        &nbsp;
        {error}
      </Flex>
    );
  }

  if (conference) {
    return (
      <Tooltip label="Conference call">
        <Flex noOfLines={1} justifyContent="center" alignItems="center">
          <FaUsers />
          &nbsp;
          {conference.name}
        </Flex>
      </Tooltip>
    );
  }

  if (contact) {
    const { icon, link, displayName: contactName } = contact;
    const callerId = alwaysShowDisplayName
      ? displayName || contactName
      : contactName;

    return (
      <Flex justifyContent="center">
        {icon && link && (
          <>
            <Link href={link}>
              <span className={icon}></span>
            </Link>
            &nbsp;
          </>
        )}
        <Tooltip hasArrow label={numberDisplay}>
          <Text as="span">{callerId}</Text>
        </Tooltip>
      </Flex>
    );
  }

  if (displayName) {
    return (
      <Tooltip hasArrow label={numberDisplay}>
        <Text as="span" noOfLines={1}>
          {displayName}
        </Text>
      </Tooltip>
    );
  }

  return (
    <Text as="span" noOfLines={1}>
      {phoneNumber ?? 'Unknown'}
    </Text>
  );
};
