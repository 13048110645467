import { ContactSelectOption } from '../types';
import { arrayOfValidTypes, getCreateOption } from '../utils';
import { getContacts } from './getContacts';
import { SearchContactsProps } from './types';

export const searchContacts = async ({
  search,
  allowedTypes,
  nonCreatable = false,
  noManual = true,
}: SearchContactsProps): Promise<ContactSelectOption[]> => {
  const allowedTypesArray = arrayOfValidTypes(allowedTypes);

  const contacts = await getContacts(search, allowedTypesArray);

  const createLabels = allowedTypesArray
    .filter((item) => item !== 'user')
    .map((item) => {
      return getCreateOption(item, search);
    });

  const manualLabel: ContactSelectOption = {
    __isNew__: true,
    label: search,
    value: search,
    type: 'manual',
  };

  const parsedContacts = contacts.map((contact) => ({
    type: contact.type,
    label: contact.name,
    value: contact.ID,
  }));

  return search.length > 3
    ? [
        ...parsedContacts,
        ...(nonCreatable ? [] : createLabels),
        ...(noManual ? [] : [manualLabel]),
      ]
    : parsedContacts;
};
