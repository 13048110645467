import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { Department, DepartmentsTreeSettings } from '../DepartmentTreeView';

export async function getLocalCompanyDepartments(
  localCompanyID: number,
  departmentsTreeSettings: Partial<DepartmentsTreeSettings>
): Promise<ListingResponse<Department>> {
  const { data } = await Api.get<ListingResponse<Department>>(
    '/api/phonebook/departments',
    {
      params: {
        fields: ['ID', 'name', 'parentDepartment.ID'],
        filters: {
          'companyID:eq': localCompanyID,
        },
        limit: 'null',
        sort: departmentsTreeSettings.sort ?? 'name:asc',
      },
    }
  );

  return data;
}
