import { ReactElement } from 'react';
import { FormControl, useColorModeValue, VStack } from '@chakra-ui/react';

import { ProductItem } from '@bq/components/FormFields/Product';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { ProductItemSettings } from './types';

type Props = FieldValue<ProductItemSettings>;

export const ProductItemField = ({ id, settings }: Props): ReactElement => {
  const bg = useColorModeValue('brand.lightGray', '#494949');

  return (
    <FormControl>
      <FieldLabel {...settings} />
      <VStack px="4" py="2" background={bg} rounded="md">
        <ProductItem
          fieldName={id}
          required={settings.required}
          itemFields={settings.fields}
        />
      </VStack>
    </FormControl>
  );
};
