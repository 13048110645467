import tr from 'BootQuery/Assets/js/translate';

export default {
  install(Vue, _options) {
    Vue.mixin({
      methods: {
        tr(trString, context, moduleName = 'global') {
          const translation = tr(trString, moduleName, context);
          return translation;
        },
      },
    });
  },
};
