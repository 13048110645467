import { AnyExtension, Editor } from '@tiptap/react';

import { RichTextMenuItems } from './types';

/**
 * Maps through menuItems to remove duplicate extensions
 * @param {RichTextMenuItems} menuItems
 * @returns {AnyExtension[]}
 */
export const getExtensions = (
  menuItems: RichTextMenuItems[]
): AnyExtension[] => {
  const extensions = menuItems.map((item) => item.requiredExtensions);
  const extensionsFlat = extensions.flat();
  const uniqueItems = [...new Set(extensionsFlat)];

  return uniqueItems;
};

/**
 * Splits RichTextMenuItems into an array of arrays depending on their row attributes
 * @param {RichTextMenuItems[]} menuItems has an attribute of row on which this function
 * splits it into different arrays
 * @returns {RichTextMenuItems[][]}
 */

export const splitRows = (menuItems: RichTextMenuItems[]) => {
  const rows = [];
  rows[0] = menuItems.filter((item) => item.row === 1);
  rows[1] = menuItems.filter((item) => item.row === 2);
  rows[2] = menuItems.filter((item) => item.row === 3);

  return rows;
};

type EditorAction = (editor: Editor) => void;
type WrappedAction = (shouldFocus?: boolean) => void;
export function wrapAction(
  action: EditorAction,
  editor: Editor | null
): WrappedAction {
  return (shouldFocus = false) => {
    if (!editor) {
      return;
    }

    if (shouldFocus) {
      editor.commands.focus();
    }

    action(editor);
  };
}
