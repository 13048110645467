import { SelectOption } from '@bq/components/Select';
import i18n from 'BootQuery/Assets/js/i18n';

import { SalesState, StateOption, SubjectType } from './types';

export function stateToSelectOption(state: SalesState): StateOption {
  return {
    value: state.ID,
    label: state.name,
    color: state.color,
  };
}

export function subjectTypeToSelectOption(
  subjectType: SubjectType | null
): SelectOption | null {
  switch (subjectType) {
    case null:
      return null;
    case 'person':
      return { label: i18n.t('Sales:subject_types.person'), value: 'person' };
    case 'company':
      return { label: i18n.t('Sales:subject_types.company'), value: 'company' };
    case 'dataset':
      return { label: i18n.t('Sales:subject_types.dataset'), value: 'dataset' };
    default:
      throw new Error(`Unknown subject type ${subjectType}`);
  }
}

export function subjectTypeFromSelectOption(
  option: SelectOption | null
): SubjectType | null {
  if (!option) {
    return null;
  }

  switch (option.value) {
    case 'person':
      return 'person';
    case 'company':
      return 'company';
    case 'dataset':
      return 'dataset';
    default:
      throw new Error(`Unknown subject type ${option.value}`);
  }
}
