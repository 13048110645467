import { format } from 'date-fns';

import {
  FilterExpression,
  filterExpression,
  filterTypesWithGroup,
  FilterValue,
} from '@bq/components/FilterBar';

import { ticketFilterTypes } from '../filter-types';
import { ReportSettings } from './types';

interface ReportApiParams {
  from: string;
  to: string;
  filters?: FilterExpression[] | undefined;
  step: string;
}

export async function reportParams(
  settings: ReportSettings,
  filterValues: FilterValue[] = []
): Promise<ReportApiParams> {
  if (!settings.datePeriod) {
    throw new Error('Missing date period');
  }

  const filters = await getReportFilters(filterValues);

  return {
    filters,
    from: format(settings.datePeriod.from, 'y-MM-dd'),
    to: format(settings.datePeriod.to, 'y-MM-dd'),
    step: settings.step,
  };
}

async function getReportFilters(
  filters: FilterValue[] = []
): Promise<FilterExpression[] | undefined> {
  return filterExpression(filterTypesWithGroup(ticketFilterTypes), filters, '');
}
