import { ReactElement, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { SelectOption } from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';

import { useCaseTypeFormWrapper } from '../CaseTypeFormWrapper';
import { CaseTypesForm } from '../types';
import { subjectTypeFromSelectOption, subjectTypeToSelectOption } from './util';

export const SubjectTypeField = (): ReactElement => {
  const { control } = useFormContext<CaseTypesForm>();
  const { field } = useController({ control, name: 'subjectType' });
  const { mode } = useCaseTypeFormWrapper();

  const value = useMemo(() => {
    return subjectTypeToSelectOption(field.value ?? null);
  }, [field.value]);

  const onChange = useCallback(
    (option: SelectOption | null) => {
      field.onChange(subjectTypeFromSelectOption(option));
    },
    [field]
  );

  return (
    <Select
      isDisabled={mode === 'edit'}
      options={useSubjectTypeOptions()}
      value={value}
      onChange={onChange}
    />
  );
};

function useSubjectTypeOptions(): SelectOption[] {
  return useMemo(() => {
    const optionTypes = ['person', 'company', 'dataset'] as const;

    return optionTypes.map(subjectTypeToSelectOption) as SelectOption[];
  }, []);
}
