import axios, { InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

function isPrimitive(val: unknown): boolean {
  return ['string', 'number', 'bigint'].includes(typeof val) || val === null;
}

/**
 * Axios request interceptor that makes sure primitive values in bodies are sent as JSON.
 * Otherwise they would get some broken url-encoded encoding
 */
function fixStringBody(
  request: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  if (isPrimitive(request.data)) {
    const { headers } = request;
    headers['Content-Type'] = 'application/json';

    return {
      ...request,
      data: JSON.stringify(request.data),
      headers,
    };
  }

  return request;
}

const Api = axios.create({
  withCredentials: true,
  headers: { 'Content-Type': 'application/json' },
  paramsSerializer: {
    serialize: (params) => qs.stringify(params),
  },
});
Api.interceptors.request.use(fixStringBody);

export { Api };
