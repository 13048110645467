module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":60,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bootquery") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":59,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<form id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"edit_what") : depth0), depth0))
    + "-modify-form\" class=\"form-horizontal\" action=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"form_url") : depth0), depth0))
    + "\" method=\"post\" role=\"form\" data-form=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"edit_what") : depth0), depth0))
    + "\">\n			<div class=\"card embedable\">\n\n				<div class=\"card-header stick-top\" id=\"userform-header\">\n					<span class=\"fa fa-pencil-alt\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"label.edit_entry",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":49},"end":{"line":8,"column":75}}}))
    + "\n				</div>\n\n				<div class=\"card-body\">\n					<div id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"edit_what") : depth0), depth0))
    + "-modify-container\">\n						<input type=\"hidden\" name=\"action\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"form_action") : depth0), depth0))
    + "\" />\n						<div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"auth_provider_name")),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":7},"end":{"line":19,"column":14}}})) != null ? stack1 : "")
    + "							<div class=\"row\">\n								"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),lookupProperty(helpers,"lookup").call(alias3,((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"forms")),(depth0 != null ? lookupProperty(depth0,"edit_what") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":46}}}),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "							</div>\n						</div>\n					</div>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_telephony_settings") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":5},"end":{"line":29,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_sms_settings") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":5},"end":{"line":32,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"show_ticketing_settings") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":5},"end":{"line":35,"column":12}}})) != null ? stack1 : "")
    + "				</div>\n\n				<div class=\"card-footer stick-bottom d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":5},"end":{"line":43,"column":16}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isModal") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":5},"end":{"line":48,"column":12}}})) != null ? stack1 : "")
    + "					<div class=\"save-button\">\n						<span class=\"save-status\" hidden style=\"padding: 7px 15px;\" data-form=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"edit_what") : depth0), depth0))
    + "-modify-form\">\n						</span>\n						<button type=\"submit\" class=\"btn btn-success\" form=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"edit_what") : depth0), depth0))
    + "-modify-form\">\n							<span class=\"fa fa-check\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":53,"column":46},"end":{"line":53,"column":67}}}))
    + "\n						</button>\n					</div>\n				</div>\n			</div>\n		</form>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<div class=\"alert alert-info\" role=\"alert\">\n								"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.user_managed_at",{"name":"tr","hash":{"provider":((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"auth_provider_name"))},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":84}}})) != null ? stack1 : "")
    + "\n							</div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "					<div id=\"telephony-permissions-react-root\"></div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "					<div id=\"sms-permissions-react-root\"></div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "					<div id=\"ticketing-permissions-react-root\"></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"list_url") : depth0), depth0))
    + "\" class=\"btn btn-link form-cancel mr-auto\">\n						<span class=\"fa fa-arrow-left\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.back",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":41,"column":50},"end":{"line":41,"column":71}}}))
    + "\n					</a>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<a href=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"list_url") : depth0), depth0))
    + "\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n						<span class=\"fa fa-times\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":46,"column":45},"end":{"line":46,"column":68}}}))
    + "\n					</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":61,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})