import {
  FieldPath,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';

import { FormCampaign } from '../../schemas/Campaign';
import { useCampaignFormProvider } from './CampaignFormWrapper';

export function useCampaignFormController<K extends FieldPath<FormCampaign>>(
  name: K,
  props?: Omit<UseControllerProps<FormCampaign, K>, 'control' | 'name'>
): UseControllerReturn<FormCampaign, K> {
  const {
    methods: { control },
  } = useCampaignFormProvider();

  return useController<FormCampaign, K>({ ...props, control, name });
}
