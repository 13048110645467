import { parse, stringify } from 'flatted';

export function set(key: string, value: unknown): void {
  window.localStorage.setItem(key, stringify(value));
}

export function get<T = unknown>(key: string, defaultValue: T|null = null): T|null {
  const value = window.localStorage.getItem(key);
  if (value === null || value === undefined) {
    return defaultValue;
  }

  return parse(value);
}

export function remove(key: string): void {
  window.localStorage.removeItem(key);
}

export function keys(): string[] {
  return Object.keys(window.localStorage);
}
