import { ReactElement } from 'react';
import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

export const CancelButton = forwardRef<ButtonProps, 'button'>(
  (props, ref): ReactElement => {
    const { t } = useTranslation('global');

    return (
      <Button
        colorScheme="red"
        ref={ref}
        {...props}
        rightIcon={<FaTimes />}
        variant="outline"
      >
        {t('global:cancel')}
      </Button>
    );
  }
);

CancelButton.displayName = 'CancelButton';
