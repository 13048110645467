import { useMemo } from 'react';
import { memoize } from 'lodash-es';

import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';
import {
  customFormFilters,
  FieldValue,
  getFieldTypes,
} from '@bq/components/FormEditor';
import { makeBooleanFilter } from 'BootQuery/Assets/components/FilterBar/filters/Boolean';
import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import {
  makeDateFilter,
  makeDateToFilter,
} from 'BootQuery/Assets/components/FilterBar/filters/DateFilter';
import { numberFilterExpression } from 'BootQuery/Assets/components/FilterBar/filters/PhoneNumberFilter';
import { makeTextFilter } from 'BootQuery/Assets/components/FilterBar/filters/TextFilter';
import { makeTimeFilter } from 'BootQuery/Assets/components/FilterBar/filters/TimeFilter';
import {
  makeUserFilter,
  userFilterIDExpr,
} from 'BootQuery/Assets/components/FilterBar/filters/UserFilter';
import { FilterTypes } from 'BootQuery/Assets/components/FilterBar/types';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { Contact } from '../../types';
import { makeCountryFilter } from './CountryFilter';

interface Queue {
  ID: number;
  name: string;
}
type QueueVal = Pick<Queue, 'name'>;
type ContactVal = Pick<Contact, 'type' | 'ID'>;

interface Mailbox {
  name: string;
  num: string;
}
type MailboxVal = Pick<Mailbox, 'name'>;

function timezoneOffsetStr(): string {
  const offset = -new Date().getTimezoneOffset();
  const sign = offset < 0 ? '-' : '+';
  const absOffset = Math.abs(offset);

  const hours = Math.trunc(absOffset / 60);
  const minutes = absOffset % 60;

  const hoursStr = hours.toString().padStart(2, '0');
  const minuteStr = minutes.toString().padStart(2, '0');

  return `${sign}${hoursStr}:${minuteStr}`;
}

const startDate = makeDateFilter({
  name: () => i18n.t('Telephony:filters.call_start_date'),
  toFilter: makeDateToFilter('startAt'),
});

const startTime = makeTimeFilter({
  name: () => i18n.t('Telephony:filters.call_start_time'),
  toFilter: ({ operator, value }) => {
    if (!value) {
      return null;
    }

    return {
      [`startAt:${operator}:time`]: `${value}${timezoneOffsetStr()}`,
    };
  },
});

const phoneNumber = makeTextFilter({
  name: () => i18n.t('Telephony:filters.phone_number'),
  toFilter: ({ value, operator }) => {
    if (!value || !operator) {
      return null;
    }

    return {
      'numbers:$any': numberFilterExpression(
        value,
        operator,
        'phoneNumber.phoneNumberE164'
      ),
    };
  },
});

const fromNumber = makeTextFilter({
  name: () => i18n.t('Telephony:filters.from_number'),
  toFilter: ({ value, operator }) => {
    if (!value || !operator) {
      return null;
    }

    return {
      'numbers:$any': {
        partyType: 'from',
        ...numberFilterExpression(
          value,
          operator,
          'phoneNumber.phoneNumberE164'
        ),
      },
    };
  },
});

const toNumber = makeTextFilter({
  name: () => i18n.t('Telephony:filters.to_number'),
  toFilter: ({ operator, value }) => {
    if (!value || !operator) {
      return null;
    }

    return {
      'numbers:$any': {
        partyType: 'to',
        ...numberFilterExpression(
          value,
          operator,
          'phoneNumber.phoneNumberE164'
        ),
      },
    };
  },
});

const dialedNumber = makeTextFilter({
  name: () => i18n.t('Telephony:filters.raw_dialed_number'),
  toFilter: ({ operator, value }) => {
    if (!value || !operator) {
      return null;
    }

    return {
      [`dialedNumber:${operator}`]: value,
    };
  },
});

const answered = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.is_answered'),
  toFilter: ({ value }) => ({ answered: !!value }),
});

const userAnswered = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.is_user_answered'),
  toFilter: ({ value }) => ({ userAnswered: !!value }),
});

interface CallbackStatus {
  label: string;
  value: string;
}

interface CallbackStatusVal {
  value: string;
}

const getCallbackStatuses = memoize(() => [
  { value: 'none', label: i18n.t('Telephony:callback_status.none') },
  {
    value: 'outgoingAttempt',
    label: i18n.t('Telephony:callback_status.outgoing_attempt'),
  },
  {
    value: 'outgoingAnswered',
    label: i18n.t('Telephony:callback_status.outgoing_answered'),
  },
  {
    value: 'incomingAnswered',
    label: i18n.t('Telephony:callback_status.incoming_answered'),
  },
  { value: 'manual', label: i18n.t('Telephony:callback_status.manual') },
]);

const callbackStatus = makeComboboxFilter({
  name: () => i18n.t('Telephony:filters.callback_status'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    if (value.value === 'none') {
      return ['callbackStatus', 'null', operator !== 'neq'];
    }

    return ['callbackStatus', operator ?? 'eq', value.value];
  },
  extraProps: {
    cacheKey: 'callbackStatusCombobox',
    search: async (search: string) => {
      const callbackStatuses = getCallbackStatuses();

      if (search) {
        const lcSearch = search.toLowerCase();

        return callbackStatuses.filter((dir) => {
          const inValue = dir.value.includes(lcSearch);
          const inLabel = dir.label.toLowerCase().includes(lcSearch);

          return inValue || inLabel;
        });
      }

      return callbackStatuses;
    },
    itemToString: (item: CallbackStatus) => item.label,
    valueToItem: (value: CallbackStatusVal): CallbackStatus => {
      const dirItem = getCallbackStatuses().find(
        (dir) => dir.value === value.value
      );

      return dirItem ?? { value: value.value, label: value.value };
    },
    itemToValue: ({ value }: CallbackStatus): CallbackStatusVal => ({ value }),
    enableTextSearch: false,
  },
});

const totalDurationFilter = makeNumberFilter({
  name: () => i18n.t('Telephony:filters.duration_total_secs'),
  toFilter: ({ value, operator }) => {
    if (typeof value !== 'number' || !operator) {
      return null;
    }

    return {
      [`durationSecs:${operator}`]: value,
    };
  },
});
const talkDurationFilter = makeNumberFilter({
  name: () => i18n.t('Telephony:filters.duration_talk_secs'),
  toFilter: ({ value, operator }) => {
    if (typeof value !== 'number' || !operator) {
      return null;
    }

    return {
      [`talkTime:${operator}`]: value,
    };
  },
});
const callId = makeTextFilter({
  name: 'callId',
  toFilter: ({ value }) => {
    return value ? { callId: value } : null;
  },
  operators: [],
});

interface CallDirection {
  label: string;
  value: string;
}

interface DirectionVal {
  value: string;
}

const getDirections = memoize(() => [
  { value: 'incoming', label: i18n.t('Telephony:incoming') },
  { value: 'outgoing', label: i18n.t('Telephony:outgoing') },
  { value: 'internal', label: i18n.t('Telephony:internal') },
  { value: 'transit', label: i18n.t('Telephony:transit') },
]);

const direction = makeComboboxFilter({
  name: () => i18n.t('Telephony:filters.direction'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    return { [`direction:${operator ?? 'eq'}`]: value.value };
  },
  extraProps: {
    cacheKey: 'callDirectionCombobox',
    search: async (search: string) => {
      const directions = getDirections();

      if (search) {
        const lcSearch = search.toLowerCase();

        return directions.filter((dir) => {
          const inValue = dir.value.includes(lcSearch);
          const inLabel = dir.label.toLowerCase().includes(lcSearch);

          return inValue || inLabel;
        });
      }

      return directions;
    },
    itemToString: (item: CallDirection) => item.label,
    valueToItem: (value: DirectionVal): CallDirection => {
      const dirItem = getDirections().find((dir) => dir.value === value.value);

      return dirItem ?? { value: value.value, label: value.value };
    },
    itemToValue: ({ value }: CallDirection): DirectionVal => ({ value }),
    enableTextSearch: false,
  },
});
const hasRecording = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.has_recording'),
  toFilter: ({ value }) => {
    const op = value ? '$any' : '$none';

    return {
      [`recordings:${op}`]: true,
    };
  },
});
const hasVoicemail = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.has_voicemail'),
  toFilter: ({ value }) => {
    const existsExpr = {
      'destinations:$any': {
        'voicemailID:null': false,
      },
    };

    return value ? existsExpr : { $not: existsExpr };
  },
});
const hasUnlistenedVoicemail = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.has_unlistened_voicemail'),
  toFilter: ({ value }) => {
    const op = value ? '$any' : '$none';

    return {
      [`destinations:${op}`]: {
        'voicemailID:null': false,
        'destVoicemail.listens:$none': true,
      },
    };
  },
});
const fromUser = makeUserFilter({
  name: () => i18n.t('Telephony:filters.from_user'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    const listOp = operator === 'neq' ? '$none' : '$any';

    if (typeof value === 'string') {
      return {
        [`sourcePhonePoint.userPoint.pbxUser.users:${listOp}`]: {
          'username:contains:ci': value,
        },
      };
    }

    const userID = userFilterIDExpr(value);

    return {
      [`sourcePhonePoint.userPoint.pbxUser.users:${listOp}`]: {
        ID: userID,
      },
    };
  },
});
const toUser = makeUserFilter({
  name: () => i18n.t('Telephony:filters.to_user'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    const listOp = operator === 'neq' ? '$none' : '$any';

    if (typeof value === 'string') {
      return {
        'destinations:$any': {
          [`destPhonePoint.userPoint.pbxUser.users:${listOp}`]: {
            'username:contains:ci': value,
          },
        },
      };
    }
    const userID = userFilterIDExpr(value);

    return {
      'destinations:$any': {
        [`destPhonePoint.userPoint.pbxUser.users:${listOp}`]: {
          ID: userID,
        },
      },
    };
  },
});
const user = makeUserFilter({
  name: () => i18n.t('Telephony:filters.user'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    const listOp = operator === 'neq' ? '$none' : '$any';

    if (typeof value === 'string') {
      return {
        [`users:${listOp}`]: {
          'username:contains:ci': value,
        },
      };
    }

    const userID = userFilterIDExpr(value);

    return { [`users:${listOp}`]: { userID } };
  },
});

const toQueue = makeComboboxFilter<Queue, QueueVal>({
  name: () => i18n.t('Telephony:filters.queue'),
  operators: getDefaultComboboxOperators(true),
  toFilter: ({ value, operator }) => {
    // Nullability filters have no value
    if (operator === 'notnull') {
      return {
        'destinations:$any': { 'destQueue.ID:null': false },
      };
    }
    if (operator === 'null') {
      return {
        'destinations:$none': { 'destQueue.ID:null': false },
      };
    }

    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return {
        'destinations:$any': {
          [`destQueue.name:${containsOp}:ci`]: value,
        },
      };
    }

    return {
      'destinations:$any': {
        [`destQueue.name:${operator ?? 'eq'}`]: value.name,
      },
    };
  },
  extraProps: {
    cacheKey: 'queueCombobox',
    search: async (search: string) => {
      const { data } = await Api.get('/api/pbxState/queues', {
        params: {
          fields: ['ID', 'name'],
          filters: { 'name:contains:ci': search },
        },
      });

      return data as Queue[];
    },
    itemToString: (item: Queue) => item.name,
    valueToItem: async (value: QueueVal): Promise<Queue> => {
      const { data: contact } = await Api.get<Contact>(
        `/api/pbxState/queues/${value.name}`
      );

      return contact;
    },
    itemToValue: ({ name }: Queue): QueueVal => ({ name }),
  },
});

const mailbox = makeComboboxFilter<Mailbox, MailboxVal>({
  name: () => i18n.t('Telephony:filters.mailbox'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return {
        'destinations:$any': {
          [`destVoicemail.mailbox.name:${containsOp}:ci]`]: value,
        },
      };
    }

    return {
      'destinations:$any': {
        [`destVoicemail.mailbox.name:${operator ?? 'eq'}`]: value.name,
      },
    };
  },
  extraProps: {
    cacheKey: 'mailboxCombobox',
    search: async (search: string) => {
      const { data } = await Api.get<Mailbox[]>('/api/pbxState/mailboxes', {
        params: {
          filters: { 'name:contains:ci': search },
        },
      });

      return data;
    },
    itemToString: (item: Mailbox) => item.name,
    valueToItem: async (value: MailboxVal): Promise<Mailbox> => {
      const { data: mailbox } = await Api.get<Mailbox>(
        `/api/pbxState/mailboxes/${value.name}`
      );

      return mailbox;
    },
    itemToValue: ({ name }: Mailbox): MailboxVal => ({ name }),
  },
});

const contactValueToItem = async (value: ContactVal): Promise<Contact> => {
  const { data: contact } = await Api.get<Contact>(
    `/api/phonebook/contacts/${value.type}/${value.ID}`
  );

  return contact;
};
const contactItemToValue = ({ type, ID }: Contact): ContactVal => ({
  type,
  ID,
});

const toContact = makeComboboxFilter<Contact, ContactVal>({
  name: () => i18n.t('Telephony:filters.to_contact'),
  operators: getDefaultComboboxOperators(false),
  toFilter: async ({ value, operator }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }

    const contact = await contactValueToItem(value);
    if (!contact.phoneNumbers?.length) {
      // Workaround to return nothing if there are no numbers
      return ['ID', 'null', true];
    }

    const phoneNumbers = contact.phoneNumbers.map(
      (num) => num.phoneNumber.phoneNumberE164
    );

    const listOp = operator === 'neq' ? '$none' : '$any';

    return {
      [`numbers:${listOp}`]: {
        partyType: 'to',
        'phoneNumber.phoneNumberE164:inArray': phoneNumbers,
      },
    };
  },
  extraProps: {
    cacheKey: 'contactCombobox',
    search: async (search: string) => {
      const { data } = await Api.get('/api/phonebook/contacts', {
        params: {
          $search: search,
        },
      });

      return data as Contact[];
    },
    itemToString: (item: Contact) => item.name,
    enableTextSearch: false,
    itemToValue: contactItemToValue,
    valueToItem: contactValueToItem,
  },
});

const fromContact = makeComboboxFilter<Contact, ContactVal>({
  name: () => i18n.t('Telephony:filters.from_contact'),
  operators: getDefaultComboboxOperators(false),
  toFilter: async ({ value, operator }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }

    if (!value.phoneNumbers?.length) {
      // Workaround to return nothing if there are no numbers
      return ['ID', 'null', true];
    }

    const phoneNumbers = value.phoneNumbers.map(
      (num) => num.phoneNumber.phoneNumberE164
    );

    const listOp = operator === 'neq' ? '$none' : '$any';

    return {
      [`numbers:${listOp}`]: {
        partyType: 'from',
        'phoneNumber.phoneNumberE164:inArray': phoneNumbers,
      },
    };
  },
  extraProps: {
    cacheKey: 'contactCombobox',
    search: async (search: string) => {
      const { data } = await Api.get('/api/phonebook/contacts', {
        params: {
          $search: search,
        },
      });

      return data as Contact[];
    },
    itemToString: (item: Contact) => item.name,
    enableTextSearch: false,
    itemToValue: contactItemToValue,
    valueToItem: contactValueToItem,
  },
});
const contact = makeComboboxFilter<Contact, ContactVal>({
  name: () => i18n.t('Telephony:filters.contact'),
  operators: getDefaultComboboxOperators(false),
  toFilter: async ({ value, operator }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }

    const allNums = [
      ...(value.phoneNumbers ?? []),
      ...(value.person?.phoneNumbers ?? []),
    ];
    if (!allNums.length) {
      // Workaround to return nothing if there are no numbers
      return ['ID', 'null', true];
    }
    const phoneNumbers = allNums.map((num) => num.phoneNumber.phoneNumberE164);

    const listOp = operator === 'neq' ? '$none' : '$any';

    return {
      [`numbers:${listOp}`]: {
        'phoneNumber.phoneNumberE164:inArray': phoneNumbers,
      },
    };
  },
  extraProps: {
    cacheKey: 'contactCombobox',
    search: async (search: string) => {
      const { data } = await Api.get('/api/phonebook/contacts', {
        params: {
          $search: search,
        },
      });

      return data as Contact[];
    },
    itemToString: (item: Contact) => item.name,
    enableTextSearch: false,
    itemToValue: contactItemToValue,
    valueToItem: contactValueToItem,
  },
});

interface Tag {
  tag: string;
}

const tags = makeComboboxFilter<Tag, Tag>({
  name: () => i18n.t('Telephony:filters.tags'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    const filter =
      typeof value === 'string'
        ? { 'tags:$any': { 'tag.tag:eq': value.toLowerCase() } }
        : { 'tags:$any': { 'tag.tag:eq': value.tag } };

    return operator === 'notContains' ? { $not: filter } : filter;
  },
  operators: () => [
    { operator: 'contains', display: i18n.t('global:operators.contains') },
    {
      operator: 'notContains',
      display: i18n.t('global:operators.contains_not'),
    },
  ],
  extraProps: {
    cacheKey: 'callTagsFilter',
    search: async (search: string) => {
      const { data } = await Api.get<Tag[]>('/api/tags', {
        params: { search },
      });

      return data;
    },
    itemToString: (item) => item.tag,
    valueToItem: (value) => value,
    itemToValue: (item) => item,
  },
});

const fromCountry = makeCountryFilter({
  name: () => i18n.t('Telephony:filters.from_country'),
  operators: getDefaultComboboxOperators(false),
  toFilter: async ({ value, operator }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }

    const field =
      'sourcePhonePoint.trunkPoint.phoneNumber.countryCode.country.isoCode';

    return { [`${field}:${operator ?? 'eq'}`]: value.iso };
  },
});

const toCountry = makeCountryFilter({
  name: () => i18n.t('Telephony:filters.to_country'),
  operators: getDefaultComboboxOperators(false),
  toFilter: async ({ value, operator }) => {
    if (value === null || typeof value === 'string') {
      return null;
    }

    const field =
      'destPhonePoint.trunkPoint.phoneNumber.countryCode.country.isoCode';

    return {
      'destinations:$any': {
        [`${field}:${operator ?? 'eq'}`]: value.iso,
      },
    };
  },
});

const callFormFilled = makeBooleanFilter({
  name: () => i18n.t('Telephony:filters.call_form_filled'),
  toFilter: ({ value }) => ({ callFormFilled: !!value }),
});

const filterTypes = {
  startDate,
  startTime,
  phoneNumber,
  fromNumber,
  toNumber,
  dialedNumber,
  contact,
  toContact,
  fromContact,
  answered,
  userAnswered,
  totalDurationFilter,
  talkDurationFilter,
  hasRecording,
  hasVoicemail,
  hasUnlistenedVoicemail,
  mailbox,
  direction,
  callbackStatus,
  toQueue,
  fromUser,
  toUser,
  user,
  callFormFilled,
  tags,
  fromCountry,
  toCountry,
  callId,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { startDate: _sd, startTime: _st, ...reportFilters } = filterTypes;

export function getFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...filterTypes,
    ...customFilters,
  };
}

export function getReportFilterTypes(
  customFields: FieldValue[] = []
): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...reportFilters,
    ...customFilters,
  };
}

export function useFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  return useMemo(() => getFilterTypes(customFields), [customFields]);
}
