import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

export const NoteField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<ItemBaseFields>();

  return (
    <FormControl isInvalid={!!errors.note} {...formControlProps}>
      <FormLabel htmlFor="note">{t('Products:note')}</FormLabel>
      <Textarea
        {...register('note')}
        size={['md', 'md', 'sm']}
        isInvalid={!!errors.note}
        placeholder={t('Products:note')}
      />
    </FormControl>
  );
};
