import { formatNumber } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';
import { CompanyApiData, NationalNumberApiData } from '../types';
import { CompanyFormData } from './types';

function nationalNumberTypeFromApi(type?: NationalNumberApiData['type'] | null): string | null {
  return type
    ? `${type.countryISO}-${type.type}`
    : null;
}
export function companyFormFromApi(
  apiData: CompanyApiData
): Partial<CompanyFormData> {
  return {
    ...apiData,
    phoneNumbers: apiData.phoneNumbers.map((numRow) => ({
      ...numRow,
      phoneNumber: formatNumber(numRow.phoneNumber.phoneNumberE164),
      type: numRow.numberType.ID.toString(),
    })),
    emails: apiData.emails.map((mailRow) => ({
      ...mailRow,
      email: mailRow.email.email,
    })),
    addresses: apiData.addresses.map((address) => ({
      addressType: address.addressType,
      country: address.address.country?.name ?? '',
      city: address.address.city?.name ?? '',
      street: address.address.street?.name ?? '',
      streetNumber: address.address.streetNumber ?? '',
      postalCode: address.address.postalCode?.code ?? '',
    })),
    nationalNumber: {
      type: nationalNumberTypeFromApi(apiData.nationalNumber?.type) ?? 'HR-oib',
      nationalNumber: apiData.nationalNumber?.nationalNumber ?? '',
    },
  };
}

export async function getCompanyFormData(
  companyID: string
): Promise<Partial<CompanyFormData>> {
  const { data } = await Api.get<CompanyApiData>(
    `/api/phonebook/companies/${companyID}`,
    {
      params: {
        fields: [
          'ID',
          'name',
          ['phoneNumbers', ['phoneNumber.phoneNumberE164', 'numberType.ID', 'isReadonly']],
          'emails.email.email',
          ['addresses', [
            ['addressType', ['name', 'ID']],
            ['address', [
              'country.name',
              'city.name',
              'street.name',
              'postalCode.code',
              'streetNumber',
            ]],
          ]],
          ['nationalNumber', [
            ['type', ['type', 'countryISO']],
            'nationalNumber',
          ]],
          'data',
        ],
      },
    }
  );

  return companyFormFromApi(data);
}
