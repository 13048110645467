import i18n from 'BootQuery/Assets/js/i18n';

import { SeparatorIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { SeparatorField } from './SeparatorField';
import { SeparatorFieldSettings } from './SeparatorFieldSettings';

export const separatorField = (): FieldType => ({
  type: 'separator',
  name: i18n.t('global:form_editor.field_separator'),
  icon: SeparatorIcon,
  components: {
    FormField: SeparatorField,
    StaticFormField: SeparatorField,
    EditorFieldSettings: SeparatorFieldSettings,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
});
