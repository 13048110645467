import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  VStack,
} from '@chakra-ui/react';
import { ChangeEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseSettings, EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';

export interface NumberFieldSettings extends BaseSettings {
  min: number | null;
  max: number | null;
  decimals: number;
}

function parseIntInput(rawValue: string): number | null {
  if (rawValue.trim() === '') {
    return null;
  }

  const value = parseInt(rawValue, 10);

  return Number.isNaN(value) ? null : value;
}

function parseFloatInput(rawValue: string): number | null {
  if (rawValue.trim() === '') {
    return null;
  }

  const value = parseFloat(rawValue);

  return Number.isNaN(value) ? null : value;
}

interface NumberSettingProps extends NumberInputProps {
  title: string;
}

const NumberSetting = ({
  title,
  ...numProps
}: NumberSettingProps): ReactElement => (
  <FormControl size="sm">
    <FormLabel size="sm">{title}:</FormLabel>
    <NumberInput placeholder={title} size="sm" {...numProps}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  </FormControl>
);

export const NumberFieldSettingsComponent = (
  props: EditorFieldComponentProps<NumberFieldSettings>
): ReactElement => {
  const { name, required, min, max, decimals } = props.content.settings;
  const { setSetting } = useFieldSettings(props);
  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setRequired = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('required', ev.target.checked);
  };

  const setDecimals = (val: string) => {
    setSetting('decimals', parseIntInput(val) ?? 0);
  };
  const setMin = (val: string) => {
    setSetting('min', parseFloatInput(val));
  };
  const setMax = (val: string) => {
    setSetting('max', parseFloatInput(val));
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input placeholder="Name" value={name} onChange={setName} size="sm" />
      </FormControl>
      <Checkbox isChecked={required ?? false} onChange={setRequired}>
        {t('global:form_editor.required')}
      </Checkbox>

      <NumberSetting
        title="Decimals"
        step={1}
        min={0}
        value={decimals}
        onChange={setDecimals}
        isRequired
      />

      <NumberSetting
        title="Min"
        max={max ?? undefined}
        precision={1 / 10 ** decimals}
        step={1}
        value={min ?? undefined}
        onChange={setMin}
        size="sm"
      />

      <NumberSetting
        title="Max"
        min={min ?? undefined}
        precision={1 / 10 ** decimals}
        step={1}
        value={max ?? undefined}
        onChange={setMax}
        size="sm"
      />
    </VStack>
  );
};
