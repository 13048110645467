import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterMailSubject = makeTextFilter({
  name: () => i18n.t('Ticketing:filter.mail_message_subject'),
  toFilter: ({ operator, value }) => {
    if (!value) {
      return null;
    }

    return [
      'mailEvents',
      '$any',
      [['message.subject', operator ?? 'eq', value]],
    ];
  },
});
