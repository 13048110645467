import React, {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from 'react';

interface FocusContextValue {
  tabIndex: number;
  handleFocusingToolbar: () => void;
  handleLeavingToolbar: () => void;
  handleBlur: (e: React.FocusEvent<HTMLElement>) => void;
  handleEscapingToolbar: () => void;
  toolbarRef: MutableRefObject<HTMLDivElement>;
}
const FocusContext = createContext<FocusContextValue | null>(null);

export const FocusProvider = ({ children }: PropsWithChildren) => {
  const [tabIndex, setTabIndex] = useState(-1);

  const toolbarRef = useRef<HTMLDivElement>(
    null
  ) as MutableRefObject<HTMLDivElement>;

  const handleFocusingToolbar = () => {
    if (tabIndex === -1) {
      setTabIndex(0);
    }
  };

  const handleLeavingToolbar = () => {
    setTabIndex(-1);
  };

  const handleEscapingToolbar = () => {
    setTabIndex(-1);
    if (toolbarRef.current) {
      toolbarRef.current.focus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement;
    const toolbar = event.currentTarget.closest('.richtext-toolbar');

    if (toolbar && (!relatedTarget || !toolbar.contains(relatedTarget))) {
      handleLeavingToolbar();
    }
  };

  return (
    <FocusContext.Provider
      value={{
        tabIndex,
        handleFocusingToolbar,
        handleLeavingToolbar,
        handleBlur,
        toolbarRef,
        handleEscapingToolbar,
      }}
    >
      {children}
    </FocusContext.Provider>
  );
};

export const useFocusProvider = () => {
  const usedCtx = useContext(FocusContext);
  if (!usedCtx) {
    throw Error('Missing FocusProvider');
  }

  return usedCtx;
};
