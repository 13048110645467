import { ReactElement } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { LayoutBody, LayoutFooter } from '@bq/components/Layout';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'BootQuery/Assets/components/Breadcrumbs';
import { FormState } from 'BootQuery/Assets/components/form-state';
import { Layout } from 'BootQuery/Assets/components/Layout/Layout';
import { LayoutHeader } from 'BootQuery/Assets/components/Layout/LayoutHeader';

import { CompanyBreadcrumbs } from '../CompanyBreadcrumbs';
import { CompanyFormActions } from './CompanyFormActions';
import { CompanyFormFields } from './CompanyFormFields';

interface EditProps {
  mode: 'edit';
  companyID: string | number;
  name: string;
  formState: FormState;
}

interface CreateProps {
  mode: 'create';
  formState: FormState;
}

type Props = CreateProps | EditProps;

const CompanyCreateBreadcrumbs = (): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <Breadcrumbs>
      <Breadcrumb to="/phonebook/companies">
        {t('Phonebook:phonebook_title')}
      </Breadcrumb>
      <Breadcrumb to="/phonebook/companies">
        {t('Phonebook:companies')}
      </Breadcrumb>
      <Breadcrumb to="/phonebook/companies/create">
        {t('global:create')}
      </Breadcrumb>
    </Breadcrumbs>
  );
};

export const CompanyForm = ({ formState, ...props }: Props): ReactElement => {
  const cancelLink =
    props.mode === 'create'
      ? '/phonebook/companies'
      : `/phonebook/companies/${props.companyID}`;
  const headerColor = useColorModeValue('#F5F5F5', '#393939');

  return (
    <Layout h="fullNoNavbar">
      <LayoutHeader>
        {props.mode === 'edit' ? (
          <CompanyBreadcrumbs
            companyID={props.companyID}
            name={props.name}
            edit
          />
        ) : (
          <CompanyCreateBreadcrumbs />
        )}
      </LayoutHeader>
      <LayoutBody h="full">
        <CompanyFormFields />
      </LayoutBody>
      <LayoutFooter isSticky bg={headerColor} w="full">
        <CompanyFormActions
          state={formState}
          mode={props.mode}
          cancelLink={cancelLink}
        />
      </LayoutFooter>
    </Layout>
  );
};
