import { ReactElement, useMemo } from 'react';
import { Stack } from '@chakra-ui/react';
import { getFieldTypes } from './field-types';
import { FieldType, FieldValue } from './types';
import { GeneratedFormField } from './GeneratedFormField';
import { GeneratedFormProvider } from './GeneratedFormContext';

interface GeneratedFormProps {
  prefix?: string | null;
  fieldTypes?: FieldType[];
  fields: FieldValue[];
}

export const GeneratedForm = ({
  prefix = 'data',
  fieldTypes: suppliedFieldTypes,
  fields,
}: GeneratedFormProps): ReactElement => {
  const fieldTypes = useMemo(
    () => suppliedFieldTypes ?? getFieldTypes(),
    [suppliedFieldTypes]
  );

  return (
    <GeneratedFormProvider prefix={prefix} fieldTypes={fieldTypes}>
      <Stack>
        {fields.map((field) => (
          <GeneratedFormField
            key={field.id}
            prefix={prefix}
            field={field}
            fieldTypes={fieldTypes}
          />
        ))}
      </Stack>
    </GeneratedFormProvider>
  );
};
