import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { appointmentFormSchema, IAppointmentForm } from './schema';

type UseAppointmentFormProps = UseFormProps<IAppointmentForm>;

export function useAppointmentForm(
  props: UseAppointmentFormProps
): UseFormReturn<IAppointmentForm> {
  return useForm<IAppointmentForm>({
    ...props,
    resolver,
  });
}

export function useAppointmentFormContext(): UseFormReturn<IAppointmentForm> {
  return useFormContext<IAppointmentForm>();
}

const resolver = zodResolver(appointmentFormSchema);
