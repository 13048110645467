import { UniqueIdentifier } from '@dnd-kit/core';

export interface IdInfo {
  id: UniqueIdentifier;
  isContainer: boolean;
}

export function idInfo(id: UniqueIdentifier): IdInfo {
  if (typeof id === 'string') {
    const [maybeType, maybeId] = id.split(':');
    if (maybeType === 'container') {
      if (!maybeId) {
        throw new Error(`Error parsing id ${id}`);
      }

      return { id: maybeId, isContainer: true };
    }
  }

  return { id, isContainer: false };
}
