import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { squashOverviews } from '@bq/components/TreeOverviews';

import { useTicketingContext } from './TicketingContext';
import { TicketingOverview, TicketingRouteParams } from './types';

export function useSelectedOverview(): TicketingOverview | null {
  const { overviews } = useTicketingContext();
  const { overviewSlug } = useParams<TicketingRouteParams>();

  return useMemo(() => {
    const squashedOverviews = squashOverviews(overviews);

    const firstOverview = squashedOverviews[0];

    if (overviewSlug) {
      const selectedOverview = squashedOverviews.find(
        (item) => item.slug === overviewSlug
      );

      // Technically a missing overview is an error, but eh
      return selectedOverview ?? firstOverview;
    }

    return firstOverview;
  }, [overviews, overviewSlug]);
}
