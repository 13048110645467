import { Flex, HStack } from '@chakra-ui/react';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { ReactElement, useCallback } from 'react';
import { EditEventType } from './EditEventType';
import { useEventTypeSettings } from './EventTypeSettingsContext';
import { CustomEventTypeSettings } from './types';

type Props = CustomEventTypeSettings;

export const EventTypeListItem = ({ ID, name }: Props): ReactElement => {
  const { remove } = useEventTypeSettings();

  const removeMe = useCallback(() => {
    remove(ID);
  }, [remove, ID]);

  return (
    <Flex w="full" px="3" py="2" justifyContent="space-between" borderStyle="solid" borderBottomWidth="thin">
      {name}
      <HStack>
        <DeleteButton size="sm" onClick={removeMe} />
        <EditEventType eventTypeID={ID} />
      </HStack>
    </Flex>
  );
};
