import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

export function usePauseTypes(
  params: GetListingParams = {}
): UseQueryResult<string[]> {
  return useQuery({
    queryKey: ['Telephony.pauseTypes', params],
    queryFn: () => getPauseTypes(params),
  });
}

export async function getPauseTypes(
  params: GetListingParams = {}
): Promise<string[]> {
  const { data } = await Api.get<{ name: string }[]>(
    '/api/telephony/pauseTypes',
    { params }
  );

  return data.map((pause) => pause.name);
}
