import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';
import { Api } from 'BootQuery/Assets/js/api';
import { BackendReqestProductData } from './types';

interface PatchProductParams {
  productID: number;
  data: BackendReqestProductData;
  params?: GetOneParams;
}

export const patchProduct = async <ReturnDataType = { ID: number }>({
  data,
  productID,
  params,
}: PatchProductParams) => {
  const { data: apiData } = await Api.patch<ReturnDataType>(
    `/api/products/${productID}`,
    data,
    { params }
  );

  return apiData;
};
