import { get } from 'lodash-es';
import { handlebarsRender } from 'app/assets/js/util';

export default function translate(fullText, moduleName = 'global', params = {}) {
  const locale = get(window.Bootstrap, 'bootquery.session.locale') || window.Bootstrap.bootquery.locale;
  if (!window.Locales[locale]) {
    console.warn('Selected locale not found: ', locale);
    return fullText;
  }

  // Module can be explicitly specified in tr string
  const moduleSpec = fullText.split(':');
  let requestedModule = moduleName;
  let pathStr = fullText;
  if (moduleSpec.length > 1) {
    [requestedModule, pathStr] = moduleSpec;
  }
  const path = pathStr.split('.');
  const searchPaths = [[requestedModule, ...path], ['global', ...path]];

  const translated = searchPaths
    .map((searchPath) => get(window.Locales[locale], searchPath))
    .find((val) => typeof val !== 'undefined');
  if (translated) {
    if (typeof translated === 'function') {
      return handlebarsRender(translated, params);
    }
    return translated;
  }

  console.warn(`Unable to find translation string for ${fullText} in module ${requestedModule}`);
  return fullText;
}
