import { Flex, Tag } from '@chakra-ui/react';
import { CellProps } from 'BootQuery/Assets/components/Table';
import { ReactElement } from 'react';
import { CallListCall } from './types';

type Props = CellProps<CallListCall>;

export const CallTagsCell = ({ row: call }: Props): ReactElement => (
  <Flex
    w="full"
    flexDirection="row"
    alignItems="center"
    flexWrap="wrap"
    sx={{ gap: '16px' }}
  >
    {call.tags.map(({ tag }) => (
      <Tag key={tag.tag} fontWeight="bold" size="sm">
        {tag.tag}
      </Tag>
    ))}
  </Flex>
);
