import { CallCurrentState } from '../../store/calls-zustand';

export type ConnectionType = 'dialing' | 'ringing' | 'destination' | 'source';
export type CallAndConnection = {
  call: CallCurrentState;
  connection: ConnectionType | null;
};

export function getCallConnectedToDevice(
  call: CallCurrentState,
  deviceId: string
): CallAndConnection | null {
  const { currentCallees, currentDestination, sourcePhonePoint } = call;

  if (currentDestination?.destination?.type === 'phonePoint') {
    const dest = currentDestination.destination.point;
    if (dest.userPoint?.pbxDevice.deviceId === deviceId) {
      return { connection: 'destination', call };
    }
  }

  if (sourcePhonePoint.phonePoint.userPoint) {
    const src = sourcePhonePoint.phonePoint.userPoint.pbxDevice;
    if (src.deviceId === deviceId) {
      return { connection: 'source', call };
    }
  }

  const ringing = currentCallees.some((endpoint) => {
    const { userPoint } = endpoint.callPointPhone.phonePoint;
    if (!userPoint) {
      return false;
    }

    return userPoint.pbxDevice.deviceId === deviceId;
  });

  if (ringing) {
    return { connection: 'ringing', call };
  }

  return null;
}
