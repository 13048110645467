import { Box, BoxProps } from '@chakra-ui/react';
import { useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { omit } from 'lodash-es';
import { ReactElement } from 'react';
import { BaseItem } from '../DndContainers';
import { EditorField } from './EditorField';
import { EditorItem, ValueItem } from './types';

type Props = Omit<BoxProps, 'id' | 'children' | 'content'> & EditorItem;

export const EditorFields = (props: Props): ReactElement => {
  if (!props.hasChildren) {
    throw new Error('Editor container has no children');
  }

  const { id, children, ...boxProps } = omit(
    props,
    'content',
    'hasChildren',
    'mode'
  );
  const { setNodeRef } = useDroppable({ id: `container:${id}` });

  return (
    <SortableContext items={children} strategy={verticalListSortingStrategy}>
      <Box {...boxProps} ref={setNodeRef}>
        {(children as unknown as BaseItem<ValueItem>[]).map((val) => (
          <EditorField key={val.id} {...val} />
        ))}
      </Box>
    </SortableContext>
  );
};
