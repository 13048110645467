import { useCallback } from 'react';

import { useModal } from '../UseModal';
import { ConfirmationModalContent } from './ConfirmationModalContent';
import { ConfirmationModalProps } from './types';

export const useConfirmationModal = () => {
  const { addModal } = useModal();

  return useCallback(
    (props: ConfirmationModalProps) => {
      addModal({ children: <ConfirmationModalContent {...props}/> });
    },
    [addModal]
  );
};
