import {
  getOwnProperty,
  setOwnProperty,
  arrayToPath,
  splitNestedInputName,
  intersectionFromStart,
  microTemplate,
  handlebarsRender,
} from 'app/assets/js/util';
import {
  filter,
  forEach,
  uniq,
  last,
  map,
  partial,
  join,
  some,
  merge,
  reduce,
  upperFirst,
  nth,
  flow,
  isObject,
  isArray,
  isString,
} from 'lodash-es';
import createModal from 'app/assets/js/modals';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import {
  activatePopovers,
  setFormSaveStatus,
  getFormData,
  getTemplate,
  submitForm,
  activateElements,
} from './BootQuery';
import Quill from './quill';
import { quillOptions } from './quill/quill-options';
import SignatureField from './signature-field';

function getNestedFieldPath(pathArr, prefix) {
  const path = valueToDefinitionPath(pathArr);
  if (prefix) {
    if (path[0] === prefix) {
      path.shift();
    }
  }
  return `fields.${path.join('.fields.')}`;
}

function valueToDefinitionPath(valuePathArr) {
  return filter(valuePathArr, (part) => part.substr(0, 3) !== 'new' && !part.match(/^\d+$/));
}

function valuePathToBase(valuePathArr, baseArr) {
  const valuePath = valuePathArr.slice();
  const base = baseArr.slice();
  const newPath = [];
  forEach(base, (basePart) => {
    if (!valuePath.length) {
      return;
    }
    if (valuePath[0] == basePart) {
      newPath.push(valuePath.shift());
    }
    if (valuePath.length && valuePath[0].match(/^\d+$/)) {
      newPath.push(valuePath.shift());
    }
  });
  return newPath;
}

function rowToOption(option, fieldDefinition) {
  return {
    id: option[fieldDefinition.idColumn],
    text: option[fieldDefinition.textColumn],
    rowData: option,
  };
}

function getRecursiveDependants($form, field, processedDependants) {
  if (typeof processedDependants === 'undefined') {
    processedDependants = {};
  }
  const data = $form.data('form');
  let allDependants = [];
  forEach(field.dependants, (dependant) => {
    let fullFieldPath = null;
    if (dependant[0] == '/') {
      fullFieldPath = dependant.substr(1).split('.');
    } else {
      fullFieldPath = field.parentPath.concat(dependant.split('.'));
    }
    const fullFieldPathStr = fullFieldPath.join('.');
    allDependants.push(fullFieldPathStr);
    if (!processedDependants[fullFieldPathStr]) {
      const definitionPath = getNestedFieldPath(fullFieldPath, data.formDefinition.prefix);
      const dependantDef = getOwnProperty(data.formDefinition, definitionPath);
      const dependantDependants = getRecursiveDependants(
        $form,
        dependantDef,
        processedDependants,
      );
      allDependants = allDependants.concat(dependantDependants);
      processedDependants[fullFieldPathStr] = dependantDependants;
    } else {
      allDependants = allDependants.concat(processedDependants[fullFieldPathStr]);
    }
  });
  return uniq(allDependants);
}

function rebindForm($form, pathsToDiff) {
  const formData = getFormData($form);
  const pathsToBind = reduce(
    pathsToDiff,
    (paths, path) => {
      paths[path] = {
        definitionPath: valueToDefinitionPath(path.split('.')).join('.'),
      };
      return paths;
    },
    {},
  );
  const data = {
    module: window.Bootstrap.bootquery.moduleName,
    form: $form.data('form').formDefinition.nameWithModule,
    formData,
    pathsToBind,
  };
  if ($form.data('form').formDefinition.prefix) {
    data.formPrefix = $form.data('form').formDefinition.prefix;
  }
  Api.post('/api/rebindForm', data).then((rebinded) => {
    forEach(rebinded, (field, valuePath) => {
      const data = $form.data('form');
      const definitionPath = getNestedFieldPath(
        field.definitionPath.split('.'),
        data.formDefinition.prefix,
      );
      const fieldDef = getOwnProperty(data.formDefinition, definitionPath);
      const elName = arrayToPath(valuePath.split('.'));
      const $selectEl = $(`select[name="${elName}"]`);
      const optionFormatFunc = partial(rowToOption, partial.placeholder, fieldDef);
      const pickleOptions = map(field.newBinding.options, optionFormatFunc);

      data.disableOnChangeHandler = true;

      $selectEl.val(field.newBinding.value);
      $selectEl.pickle('options', pickleOptions);
      $selectEl.pickle('select', field.newBinding.value);
      $selectEl.pickle('disabled', !!field.newBinding.disabled);

      data.disableOnChangeHandler = false;
    });
  });
}

function rebindSelect($el, alsoRebindSelf) {
  const $form = formElementForControl($el);
  const data = $form.data('form');
  const formDef = data.formDefinition;
  if (data.disableOnChangeHandler) {
    return;
  }
  const elName = $el.attr('name');
  const fieldPath = splitNestedInputName(elName);
  const fieldDef = getOwnProperty(formDef, getNestedFieldPath(fieldPath, formDef.prefix));
  const fullFieldPath = fieldDef.parentPath.concat(fieldDef.key);

  const dependants = getRecursiveDependants($form, fieldDef);
  const joinWithDots = partial(join, partial.placeholder, '.');
  const fullDependencyPaths = flow(
    (dependant) => map(dependant, (dependant) => {
      const intersection = intersectionFromStart(fullFieldPath, dependant.split('.'));
      const valuePathBase = valuePathToBase(fieldPath, intersection.commonMatch);
      return valuePathBase.concat(intersection.arr2Remaining);
    }),
    (dependant) => filter(dependant, (val) => val.length),
    (dependant) => map(dependant, joinWithDots),
  )(dependants);

  if (alsoRebindSelf) {
    fullDependencyPaths.unshift(joinWithDots(fieldPath));
  }

  if (fullDependencyPaths.length) {
    rebindForm($form, fullDependencyPaths);
  }
}

function clearInvalidationState($form) {
  $form.find('.is-invalid').removeClass('is-invalid');
  $form.find('.form-error-info').remove();
}

function flattenValidationErrors(validationErrors, parentPath = []) {
  return Object.entries(validationErrors).reduce((errors, [fieldName, field]) => {
    const path = parentPath.concat([fieldName]);

    if (isArray(field) || isString(field)) {
      errors.push({
        fieldPath: path,
        errors: field,
      });
    } else if (isObject(field)) {
      errors = errors.concat(flattenValidationErrors(field, path));
    } else {
      throw new TypeError(
        `Expected error(string|array) or sub-fields (object), got${typeof field}`,
      );
    }

    return errors;
  }, []);
}

async function showValidationState($form, validationErrors) {
  clearInvalidationState($form);

  const errorInfoTemplate = await getTemplate('formValidationErrorInfo');

  const formDef = $form.data('form').formDefinition;
  const pathPrefix = formDef.prefix ? [formDef.prefix] : [];
  const flatErrors = flattenValidationErrors(validationErrors, pathPrefix);
  flatErrors.forEach((errorInfo) => {
    if (!errorInfo || errorInfo.length === 0) {
      return;
    }
    const elName = arrayToPath(errorInfo.fieldPath);
    const inputEl = $form.findElement(`[name="${elName}"]`);

    inputEl.addClass('is-invalid');
    const errorInfoEl = $.render(errorInfoTemplate, {
      errors: errorInfo.errors,
    });
    activatePopovers(errorInfoEl);
    inputEl
      .first()
      .parent()
      .append(errorInfoEl);
  });
}

export function handleFormSubmit($form) {
  setFormSaveStatus($form, 'validating');
  getValidationResult($form).then((allValidated) => {
    if (allValidated) {
      setFormSaveStatus($form, 'saving');
      submitForm($form, window.Bootstrap);
    } else {
      setFormSaveStatus($form, 'validation-error');
    }
  });
}

function activateForm($form, options) {
  if (!$form.is('form')) {
    const {
      name,
    } = options.formDefinition;
    const $potentialForm = $form
      .parents()
      .last()
      .find(`[data-form="${name}"]`);
    if ($potentialForm.length) {
      $form = $potentialForm;
    }
  }
  if ($form.data('form') && $form.data('form').activated) {
    return;
  }
  $form.data('form', {
    activated: true,
    newListRowCounts: {},
  });
  const data = $form.data('form');
  const formDef = options.formDefinition;
  data.formDefinition = formDef;

  let $actualForm = $form;
  if (!$actualForm.is('form')) {
    $actualForm = $actualForm.closest('form');
  }
  if ($actualForm.is('form')) {
    let validators = $actualForm.data('validators');
    if (!validators) {
      validators = {};
      $actualForm.data('validators', validators);
    }

    const validationErrorField = $actualForm.data('validationError');

    const formName = formDef.nameWithModule || formDef.name;
    validators[formName] = () => {
      const dataToValidate = {
        module: window.Bootstrap.bootquery.moduleName,
        formPrefix: formDef.prefix,
        form: formName,
        formData: getFormData($form),
      };

      return Api.post('/api/validateForm', dataToValidate).then((status) => {
        showValidationState($form, status.validationErrors);

        if ($actualForm.findElement(`input[name="${validationErrorField}"]`).length == 0) {
          $actualForm.append(
            $('<input/>', {
              type: 'hidden',
              name: validationErrorField,
              value: 'true',
            }),
          );
        }

        if (status.validationErrors.length !== 0 && validationErrorField) {
          $actualForm.findElement(`input[name="${validationErrorField}"]`).val('false');

          return true; // Allow submission, we'll just store the error state
        }
        return status.validationErrors.length === 0;
      });
    };
    $actualForm.ev('submit.form', (e) => {
      e.preventDefault();
      e.stopPropagation();

      handleFormSubmit($actualForm);

      return false;
    });
  }

  activateFormElements($form);

  $form
    .find('.listform-button-add')
    .off('click.form')
    .on('click.form', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const $el = $(e.currentTarget);
      const listPathStr = splitNestedInputName($el.attr('name'))
        .slice(0, -1)
        .join('.');
      const listDef = getListDefinitionForElement($el);
      getTemplate('formListRow').then((template) => {
        if (!data.newListRowCounts[listPathStr]) {
          data.newListRowCounts[listPathStr] = 0;
        }
        const newCount = ++data.newListRowCounts[listPathStr];
        const renderContext = $.extend(listDef.newRowFields, {
          $id: `new${newCount}`,
          $rowIndex: `new${newCount}`,
          $is_new_row: true,
          editable: true,
          key: listDef.key,
          parentPath: listDef.parentPath,
        });

        const $btnRow = $el.closest('.add-btn-row');
        const $rendered = $(handlebarsRender(template, renderContext));
        $rendered
          .insertBefore($btnRow)
          .hide()
          .slideDown();
        $rendered.addClass('addedrow');
        activateElements('.addedrow');
        activateFormElements($form, $rendered);
        $rendered.removeClass('addedrow');
      });
    });
}

export function getValidationResult($actualForm) {
  const validators = $actualForm.data('validators');
  const validationPromises = Object.values(validators).map((validator) => validator());
  return Promise
    .all(validationPromises)
    .then((validations) => validations.every((validated) => validated === true));
}

function activateFormElements($form, target) {
  if (!target) {
    target = $form;
  }
  const $target = $(target);
  const data = $form.data('form');
  const formDef = data.formDefinition;

  $target.findElement('.form-quill').each((_i, el) => {
    const $el = $(el);
    const valueInputName = $el.data('valueInputName');
    const valueInput = $target.findElement(`input[name="${valueInputName}"]`);
    const quill = new Quill($el[0], quillOptions);
    setTimeout(() => {
      quill.pasteHTML(valueInput.val());
      const contentHeight = $el.find('.ql-editor').prop('scrollHeight');
      $el.find('.ql-editor').css({
        height: Math.max(100, Math.min(contentHeight, 360)),
      });
      quill.on('text-change', () => {
        const htmlContent = quill.container.firstChild.innerHTML;
        valueInput.val(htmlContent);
      });
    }, 0);
  });

  $target.findElement('.form-pickle').each((_i, el) => {
    const $el = $(el);
    const elName = $el.attr('name');
    const path = splitNestedInputName(elName);
    const definition = getOwnProperty(formDef, getNestedFieldPath(path, formDef.prefix));
    const ownName = last(splitNestedInputName($el.attr('name')));
    const newOptionElName = arrayToPath(parentPath($el).concat([`${ownName}:$newOptionText`]));
    let initialOptions;
    if (definition && definition.options) {
      initialOptions = map(definition.options, (option) => rowToOption(option, definition));
    }
    $el.data('lastSearchString', '');
    $el.prop('defaultValue', $el.val());
    $el.pickle({
      results(searchString, results, callback) {
        const $el = $(this);
        if ($el.data('isQuerying')) {
          return;
        }
        $el.data('currentSearchString', searchString);
        if ($el.data('lastSearchString') === searchString) {
          $el.data('isQuerying', false);
          callback(results, searchString.length);
          return;
        }

        $el.data('isQuerying', true);
        const formData = getFormData($form);
        const searchStringPath = path.slice();
        const key = searchStringPath.pop();
        searchStringPath.push(`${key}:$searchString`);
        setOwnProperty(formData, searchStringPath, searchString);

        const pathsToBind = {};
        pathsToBind[path.join('.')] = {
          definitionPath: valueToDefinitionPath(path).join('.'),
        };
        const data = {
          module: window.Bootstrap.bootquery.moduleName,
          form: formDef.nameWithModule,
          formData,
          pathsToBind,
        };
        if (formDef.prefix) {
          data.formPrefix = formDef.prefix;
        }
        Api.post('/api/rebindForm', data).then((rebound) => {
          const newField = rebound[path.join('.')].newBinding;
          const options = map(
            newField.options,
            partial(rowToOption, partial.placeholder, newField),
          );
          $el.data('isQuerying', false);
          $el.data('lastSearchString', searchString);
          if (definition.newEntry) {
            const newDef = definition.newEntry;
            if (newDef.type === 'simple') {
              const hasExactMatches = some(options, (option) => {
                const text = option.rawText;
                if (!text || typeof text !== 'string') {
                  return false;
                }
                return text.toLowerCase() === searchString.toLowerCase();
              });
              if (!hasExactMatches && searchString.length) {
                options.push({
                  id: '$unselectedNewOption$',
                  isNewOption: true,
                  isNewTextualOption: true,
                  text: searchString.trim(),
                  searchString: searchString.trim(),
                });
              }
            } else if (newDef.type === 'modal') {
              options.push({
                id: '$createNewFromModal$',
                isNewOption: true,
                isNewFromModalOption: true,
              });
            }
          }
          callback(options, searchString.length);
          if (
            $el.data('currentSearchString', searchString)
						!== $el.data('lastSearchString')
          ) {
            $el.pickle('research');
          }
        });
      },

      formatOption(option) {
        const {
          text,
        } = option;
        if (option.isNewOption) {
          if (option.selectedNewOption) {
            return `<strong>${option.searchString}<strong>`;
          }
          if (option.isNewFromModalOption) {
            return '<strong>+ Dodaj novo</strong>';
          }
          return `Unesi <strong>"${option.searchString}"</strong> kao novo`;
        }
        if (option.rowData && option.rowData.formattedText) {
          return option.rowData.formattedText;
        }
        if (!text || !text.length) {
          return '&nbsp';
        }
        return text;
      },

      formatSelectedOption(option) {
        const {
          text,
        } = option;
        if (option.isNewOption && option.isNewTextualOption) {
          const $newOptionText = $(`input[name="${newOptionElName}"]`);
          return $newOptionText.length ? $newOptionText.val() : option.searchString;
        }
        if (option.rowData && option.rowData.selectedFormattedText) {
          return option.rowData.selectedFormattedText;
        }
        if (!text || !text.length) {
          return '&nbsp';
        }
        return text;
      },
      initialOptions,
    });
    if (definition.newEntry && definition.newEntry.type === 'modal') {
      const options = $el.pickle('options');
      options.push({
        id: '$createNewFromModal$',
        isNewOption: true,
        isNewFromModalOption: true,
      });
      $el.pickle('options', options);
    }
    $el.off('select.form').on('select.form', (e) => {
      let {
        value,
      } = e;
      let $newOptionText = $(`input[name="${newOptionElName}"]`);
      if (value === '$unselectedNewOption$' || value === '$newOption$') {
        let option = $el.pickle('option', e.value);
        if (e.value === '$unselectedNewOption$') {
          option = merge(option, {
            id: '$newOption$',
            persist: true,
            selectedNewOption: true,
          });
          $el.pickle('option', '$newOption$', option);
          value = '$newOption$';
          $el.val(value);
        }
        if (!$newOptionText.length) {
          const newElemParams = {
            type: 'hidden',
            value: option.searchString,
            name: newOptionElName,
          };
          $newOptionText = $('<input/>', newElemParams).insertAfter($el);
        }
        $newOptionText.val(option.searchString);
      } else if (e.value === '$createNewFromModal$') {
        const modalParams = definition.newEntry.modalParams || {};
        const params = {};
        forEach(modalParams, (value, key) => {
          params[`modal${upperFirst(key)}`] = value;
        });
        createModal(params, (modal) => {
          $(modal)
            .find('form')
            .on('succesfull-submit.form', (e) => {
              // let $modalForm = $(e.currentTarget);
              const newID = e.submit_info.mainFormID;
              $(modal).modal('hide');
              data.disableOnChangeHandler = true;
              $el.pickle('option', newID, {
                id: newID,
              });
              $el.pickle('select', newID);
              data.disableOnChangeHandler = false;
              rebindSelect($el, true);
            });
        });
      }

      if (value !== '$newOption$') {
        $newOptionText.remove();
      }
    });
  });

  $target.findElement('select.form-pickle').ev('change.form', (e) => {
    if (data.disableOnChangeHandler) {
      return;
    }
    rebindSelect($(e.currentTarget));
  });

  $target.findElement('.listform-button-edit').ev('click.form', (e) => {
    e.preventDefault();
    e.stopPropagation();

    const $el = $(e.currentTarget);
    const $row = $el.closest('.listform-row');
    getTemplate('formListRow').then((template) => {
      const $el = $(e.currentTarget);
      const rowIndex = getRowIndexForElement($el);
      const listDef = getListDefinitionForElement($el);
      const rowDef = listDef.rowsFields[rowIndex];

      const renderContext = $.extend(rowDef, {
        editable: true,
        isEditableReplacedRow: true,
        key: listDef.key,
        parentPath: listDef.parentPath,
      });
      const renderedRaw = handlebarsRender(template, renderContext);
      const $rendered = $(renderedRaw);
      $row.addClass('listform-row-edit-hidden').prop('hidden', true);
      $rendered.insertAfter($row);
      $rendered.addClass('addedrow');
      activateElements('.addedrow');
      activateFormElements($form, $rendered);
      $rendered.removeClass('addedrow');
    });
  });

  $target
    .findElement('.listform-button-cancel-edit')
    .off('click.form')
    .on('click.form', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const $el = $(e.currentTarget);
      const $row = $el.closest('.listform-row');
      const $originalRow = getOriginalRow($row);
      $originalRow.removeClass('listform-row-edit-hidden').prop('hidden', false);
      $row.remove();
    });

  $target
    .findElement('.listform-button-remove')
    .off('click.form')
    .on('click.form', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const $el = $(e.currentTarget);
      const $checkbox = $el.find('input[type=checkbox]');

      const idElementName = arrayToPath(parentPath($checkbox).concat(['$id']));
      const $row = $el.closest('.listform-row');
      const $originalRow = getOriginalRow($row);

      let afterHide;
      let $activeRow = $row;
      if ($originalRow.length) {
        $activeRow = $originalRow;
        afterHide = function afterHide() {
          $(this).remove();
        };
      }
      $activeRow.find(`input[name="${$checkbox.attr('name')}"]`).prop('checked', true);
      $activeRow.find(`input[name="${idElementName}"]`).prop('disabled', false);
      $row.slideUp('normal', afterHide);
    });

  $target
    .findElement('.listform-button-newrow-remove')
    .off('click.form')
    .on('click.form', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const $el = $(e.currentTarget);
      const $row = $el.closest('.listform-row');
      $row.slideUp('normal', () => {
        $row.remove();
      });
    });

  $target.findElement('.form-signature-field').each((_i, el) => {
    const sigField = new SignatureField(el);
    sigField.init();
  });

  const updateTextWithAddressLink = (input) => {
    const $input = $(input);
    const $link = $input.parent().find('.form-text-with-address-link');

    let val = ($input.val() || '').trim();
    const linkTemplate = $link.data('addressLinkTemplate');
    if (linkTemplate) {
      const formData = getFormData($link.closest('form'), {
        getSelectText: true,
      });
      val = microTemplate(linkTemplate, formData);
    }
    $link.toggleClass('disabled', val.length === 0);
    $link.attr('href', `https://maps.google.com?q=${val}`);

    return $input;
  };
  $target
    .findElement('.form-text-with-address')
    .ev('input.form', (ev) => updateTextWithAddressLink(ev.currentTarget))
    .each((_i, el) => updateTextWithAddressLink(el));
}

function getListDefinitionForElement($el) {
  const $form = formElementForControl($el);
  const formDef = $form.data('form').formDefinition;
  const path = splitNestedInputName($el.attr('name'));
  let listPath;
  if (last(path) === '$addBtn') {
    listPath = path.slice(0, -1);
  } else {
    listPath = path.slice(0, -2);
  }
  return getOwnProperty(formDef, getNestedFieldPath(listPath, formDef.prefix));
}

function getRowIndexForElement($el) {
  const path = splitNestedInputName($el.attr('name'));
  if (last(path) === '$addBtn') {
    return null;
  }
  return nth(path, path.length - 2);
}

function parentPath($el) {
  return splitNestedInputName($el.attr('name')).slice(0, -1);
}

function getOriginalRow($editableRow) {
  const listformRowID = $editableRow.data('listformRowId');
  if (!listformRowID) {
    return $([]);
  }
  return $(`.listform-row-edit-hidden[data-listform-row-id="${listformRowID}"]`);
}

function formElementForControl($el) {
  if ($el.closest('[data-form]').length) {
    return $el.closest('[data-form]');
  }
  return $el.closest('form');
}

$.fn.form = function jqActivateForm(options) {
  return this.each((_i, el) => {
    const settings = $.extend($.fn.form.defaults, options);
    activateForm($(el), settings);
    return this;
  });
};

$.fn.form.defaults = {};
