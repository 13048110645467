import { ReactElement } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';
import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { SingleFileInput } from '@bq/components/SingleFileInput';

interface Props<F extends FieldValues, N extends FieldPath<F> = FieldPath<F>> {
  name: N;
}

export const DatasetUploadField = <
  F extends FieldValues,
  N extends FieldPath<F> = FieldPath<F>,
>({
    name,
  }: Props<F, N>): ReactElement => {
  const { t } = useTranslation('Datasets');

  const {
    control,
    formState: { errors },
  } = useFormContext<F>();

  const error = errors[name];

  const fileControl = useController({
    control,
    name,
    rules: {
      required: true,
      validate(value) {
        if (!value) {
          return 'required';
        }
        if (value.type !== 'uploaded') {
          return 'still_uploading';
        }

        return undefined;
      },
    },
  });

  const highlightFileErrors = error && error.message !== 'still_uploading';

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel fontWeight="bold">{t('global:file')}:</FormLabel>
      <SingleFileInput
        accept={acceptedFileTypes}
        value={fileControl.field.value}
        onChange={fileControl.field.onChange}
        isInvalid={highlightFileErrors}
      />
      {error && <FormErrorMessage>{`${error?.message}`}</FormErrorMessage>}
      <FormHelperText>
        <Trans
          ns="Datasets"
          i18nKey="file_upload_helper_text"
          defaults="Supports tables in <strong>.xlsx</strong>, <strong>.ods</strong> or <strong>.csv</strong> format. <br />Ensure data is in a clean, tabular format. <br /> Multiple <strong>phone numbers</strong> and <strong>emails</strong> can be comma-seperated. <br /> Ensure <strong>phone numbers</strong> in a spreadsheet are treated as text before import and leading zeroes are not cut off."
        />
      </FormHelperText>
    </FormControl>
  );
};

const acceptedFileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xlsx',
  'application/vnd.oasis.opendocument.spreadsheet',
  '.ods',
  '.csv',
];
