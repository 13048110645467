import 'BootQuery/Assets/js/i18n';

import { Navigate } from 'react-router-dom';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

import { DatasetCreate } from '../pages/DatasetCreate';
import { DatasetEdit } from '../pages/DatasetEdit';
import { DatasetEntryCreate } from '../pages/DatasetEntryCreate';
import { DatasetEntryEdit } from '../pages/DatasetEntryEdit';
import { DatasetList } from '../pages/DatasetList';
import { DatasetView } from '../pages/DatasetView';

export const ReactRoutes: ReactRoute[] = [
  { path: '/datasets/list', component: DatasetList },
  {
    path: '/datasets/dataset/:datasetID',
    component: () => <Navigate to="entries" />,
  },
  { path: '/datasets/dataset/:datasetID/entries', component: DatasetView },
  {
    path: '/datasets/dataset/:datasetID/entries/create',
    component: DatasetEntryCreate,
  },
  {
    path: '/datasets/dataset/:datasetID/entries/:entryID',
    component: DatasetEntryEdit,
  },
  { path: '/datasets/dataset/:datasetID/edit', component: DatasetEdit },
  { path: '/datasets/create/*', component: DatasetCreate },
];
