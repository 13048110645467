import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';

import { FieldType } from '../../types';

export interface IStaticListCtx {
  prefix?: string | null;
  fieldTypes?: FieldType[];
  data?: Record<string, unknown>;
}

export const StaticListFieldContext = createContext<IStaticListCtx | null>(
  null
);

type ProviderProps = PropsWithChildren<IStaticListCtx>;

export const StaticListProvider = ({
  prefix,
  fieldTypes,
  data,
  children,
}: ProviderProps): ReactElement => {
  const value = useMemo(
    () => ({ data, prefix, fieldTypes }),
    [data, prefix, fieldTypes]
  );

  return (
    <StaticListFieldContext.Provider value={value}>
      {children}
    </StaticListFieldContext.Provider>
  );
};

export function useStaticListFieldContext(): IStaticListCtx {
  const ctx = useContext(StaticListFieldContext);
  if (!ctx) {
    throw new Error('Missing context StaticListFieldContext');
  }

  return ctx;
}
