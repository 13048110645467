import Handlebars from 'handlebars/runtime';
import handlebarsLayouts from 'handlebars-layouts';

import { get, mapValues } from 'lodash-es';
import phpToMomentDateFormat from 'BootQuery/Assets/js/phpToMoment';
import { arrayToPath, formatNumber } from 'app/assets/js/util';
import tr from 'BootQuery/Assets/js/translate';
import qs from 'qs';
import moment from 'moment';

handlebarsLayouts.register(Handlebars);

export function newGetTemplate(name, module, moduleMandatory = false) {
  const nameParts = name.split('.');
  if (nameParts.length === 2) {
    [module, name] = nameParts;
    if (module.endsWith('?')) {
      module = module.slice(0, -1);
      moduleMandatory = false;
    } else {
      moduleMandatory = true;
    }
  }
  if (!module) {
    module = 'global';
  }
  const fullName = `${module}.${name}`;
  let template = get(window.templates, fullName);
  if (!template && !moduleMandatory) {
    template = get(window.templates, `global.${name}`);
  }

  if (!template) {
    throw new Error(`Unable to get template ${name} for module ${module}`);
  }
  if (module) {
    template.$moduleName = module;
  }
  template.$templateName = name;
  return template;
}

const handlebarsResolvePartial = Handlebars.VM.resolvePartial;
Handlebars.VM.resolvePartial = function resolvePartial(partial, content, options) {
  if (!partial) {
    const moduleName = get(options, 'data.root.$moduleName');

    partial = newGetTemplate(options.name, moduleName);
    if (!partial) {
      throw new Error(`Partial resolver failed to load: ${options.name}`);
    }
  }
  return handlebarsResolvePartial.call(this, partial, content, options);
};

const handlebarsInvokePartial = Handlebars.VM.invokePartial;
Handlebars.VM.invokePartial = function invokePartial(partial, context, options) {
  if (!partial) {
    partial = Handlebars.VM.resolvePartial(partial, context, options);
  }
  if (partial.$moduleName) {
    options.data.$moduleName = partial.$moduleName;
  }
  return handlebarsInvokePartial.call(this, partial, context, options);
};

const lookupMap = function lookupMapHelper(lookupIn, lookupArr) {
  if (!lookupIn) {
    return null;
  }
  if (typeof lookupArr === 'object') {
    return mapValues(lookupArr, (value, key) => lookupMap(lookupIn, value));
  }
  if (typeof lookupArr === 'string') {
    let key = null;
    const lastCharIndex = lookupArr.length - 1;

    if (lookupArr.length > 2 && lookupArr[0] === '$' && lookupArr[lastCharIndex] === '$') {
      key = lookupArr.substring(1, lastCharIndex);
    }
    if (key) {
      return lookupIn[key];
    }
    return lookupArr;
  }
  return lookupArr;
};
Handlebars.registerHelper('lookupMap', lookupMap);
Handlebars.registerHelper('encode_query_string', (...params) => {
  const ctx = params.pop();
  const queryData = params[0] || {};

  return qs.stringify({ ...queryData, ...ctx.hash });
});

Handlebars.registerHelper('urlencode', (...params) => {
  const uriComponent = params[0] || '';

  const shouldSerialise = ['string', 'number', 'boolean'].includes(typeof uriComponent);
  return shouldSerialise ? encodeURIComponent(uriComponent) : '';
});

Handlebars.registerHelper('tr', function trHelper(...params) {
  const ctx = params.pop();
  const moduleHint = get(ctx, 'data.$moduleName') || get(ctx, 'data.root.$moduleName') || 'global';

  return tr(params.join('.'), moduleHint, { ...this, ...ctx.hash });
});

const isTruthy = (param) => !!param;
Handlebars.registerHelper('or', (...params) => params.slice(0, -1).some(isTruthy));

Handlebars.registerHelper('format_date', (date, format, sourceFormat) => {
  if (!date) {
    return null;
  }

  if (format) {
    format = phpToMomentDateFormat(format);
  } else {
    format = 'L. LTS';
  }

  let momentDate = null;
  if (sourceFormat) {
    momentDate = moment(date, phpToMomentDateFormat(sourceFormat));
  } else {
    momentDate = moment(date);
    momentDate.locale(
      window.Bootstrap.bootquery.session.locale.slice(
        0,
        window.Bootstrap.bootquery.session.locale.indexOf('_'),
      ),
    );
  }
  return momentDate.format(format);
});

Handlebars.registerHelper('format_time', (time, format, sourceFormat) => {
  if (!time) {
    return null;
  }
  let fromFormat;

  if (sourceFormat) {
    fromFormat = phpToMomentDateFormat(sourceFormat);
    return moment(time, fromFormat).formatPHP(format);
  }
  const momentDate = moment(time, 'HH:mm:ss');
  momentDate.locale(
    window.Bootstrap.bootquery.session.locale.slice(
      0,
      window.Bootstrap.bootquery.session.locale.indexOf('_'),
    ),
  );

  return momentDate.format('HH:mm:ss');
});

Handlebars.registerHelper('format_number', (number) => {
  if (number && typeof (number) == 'string') {
    return formatNumber(number);
  }
  return null;
});

Handlebars.registerHelper('encode_filters', (filters, tableName) => {
  const prefix = tableName ? `${tableName}-` : '';
  const components = filters.reduce((components, filter) => {
    const key = prefix + filter.key;
    if (filter.value) {
      components.push(`${key}=${encodeURIComponent(filter.value)}`);
    }
    return components;
  }, []);
  return components.join('&');
});
Handlebars.registerHelper('array_to_path', (...args) => arrayToPath(args.slice(0, -1)));
Handlebars.registerHelper('concat', (...args) => args.slice(0, -1).join(''));

Handlebars.registerHelper(
  'resolveAssetName',
  (assetName) => {
    if (window.build_manifest) {
      return window.build_manifest[assetName] || null;
    }

    return `/dist-dev/${assetName}`;
  },
);
Handlebars.registerHelper('render_string', () => {
  throw new Error('render_string helper only available server-side');
});

window.Handlebars = Handlebars;
export default Handlebars;
