import { ReactElement, useCallback } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DatasetUploadField } from './DatasetUploadField';
import { UploadFormStructure } from './types';

interface Props {
  id?: string;
  onSubmit?: (data: UploadFormStructure) => void | Promise<void>;
}

export const DatasetUploadForm = ({ id, onSubmit }: Props): ReactElement => {
  const { t } = useTranslation('Datasets');
  const formMethods = useForm<UploadFormStructure>({
    // Only validate on submit so we don't show the file still uploading error
    // unless user attempts to submit without waiting for upload
    mode: 'onSubmit',
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const handleSuccesfullSubmit = useCallback(
    async (data: UploadFormStructure) => {
      console.log('Submitting upload form: ', data);

      if (onSubmit) {
        await onSubmit(data);
      }
    },
    [onSubmit]
  );

  return (
    <form
      id={id}
      data-ignore-form-save
      onSubmit={handleSubmit(handleSuccesfullSubmit)}
    >
      <FormProvider {...formMethods}>
        <VStack spacing="6">
          <FormControl isInvalid={!!errors.name}>
            <FormLabel fontWeight="bold">{t('global:name')}:</FormLabel>
            <Input
              {...register('name', {
                required: true,
              })}
              isInvalid={!!errors.name}
              placeholder={t('global:name')}
            />
            {errors.name && (
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            )}
          </FormControl>

          <DatasetUploadField<UploadFormStructure> name="file" />
        </VStack>
      </FormProvider>
    </form>
  );
};
