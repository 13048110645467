import { ReactElement, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import { useModal } from '@bq/components/UseModal';

import { PhonebookOverviewEditorModal } from './PhonebookOverviewEditorModal';

export const EditOverviewsButton = (): ReactElement => {
  const { t } = useTranslation();

  const { addModal } = useModal();
  const editOverviews = useCallback(() => {
    addModal({
      children: <PhonebookOverviewEditorModal />,
      componentProps: {
        modal: {
          size: '6xl',
          scrollBehavior: 'inside',
          closeOnOverlayClick: false,
        },
      },
    });
  }, [addModal]);

  return (
    <Button onClick={editOverviews}>
      <FaList />
      &nbsp;
      {t('global:edit_overviews')}
    </Button>
  );
};
