import { ReactElement, useContext } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { FileValue } from '@bq/components/FileInput';
import { StaticFileDisplay } from '@bq/components/FileInput/StaticFileDisplay';

import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { FileFieldSettings } from './types';

export const StaticFileField = ({
  id,
  settings,
}: FieldValue<FileFieldSettings>): ReactElement => {
  const { t } = useTranslation('');
  const { formData } = useContext(StaticFormContext);

  const value = (get(formData, id) ?? []) as FileValue[];

  return (
    <>
      <FieldLabel {...settings} />
      {value.length === 0 ? (
        <Box>{t('global:no_files')}</Box>
      ) : (
        <Box px="2" py="2">
          <SimpleGrid columns={[1, 1, 2, 3]} gridGap={1}>
            {value.map((file, idx) => (
              <StaticFileDisplay key={idx} {...file} />
            ))}
          </SimpleGrid>
        </Box>
      )}
    </>
  );
};
