import { ChangeBadge } from '@bq/components/ChangeBadge';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';
import { Box, Flex, HStack, IconButton, useDisclosure, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUndo, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { useTicketingSettingsContext } from '../TicketSettingsContext';
import { StateModalForm } from './StateModalForm';
import { TicketStateExtended } from './types';

export const TicketStateSettings = () => {
  const { states } = useTicketingSettingsContext();
  const { t } = useTranslation('Ticketing');
  const [selected, setSelected] = useState<TicketStateExtended | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [modifiedStates, modify] = useSettingsCrud<TicketStateExtended>({
    parent: '#settings-form',
    inputName: 'ticketing[states]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: states,
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedStates.map((state) => (
          <HStack key={state.ID} w="full">
            <HStack w="full">
              <Box
                rounded="sm"
                py={0.5}
                px={3}
                color="white"
                bg={state.color}
                fontWeight="bold"
                mr={2}
                textDecor={state.change === 'del' ? 'line-through' : 'none'}
              >
                {state.name}
              </Box>
              <ChangeBadge type={state.change} />
            </HStack>
            <HStack>
              {state.change && (
                <IconButton
                  aria-label={t('global:undo_changes')}
                  icon={<FaUndo />}
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    modify.undo(state.ID);
                  }}
                />
              )}
              <IconButton
                aria-label={t('global:edit')}
                icon={<FaEdit />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  setSelected(state);
                  onOpen();
                }}
              />
              <IconButton
                aria-label={t('global:delete')}
                isDisabled={state.change === 'del'}
                size="sm"
                icon={<FaTrash />}
                variant="ghost"
                colorScheme="red"
                onClick={() => {
                  modify.del(state.ID);
                }}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <IconButton
          aria-label={t('global:add')}
          mr={2}
          icon={<FaPlus />}
          colorScheme="green"
          size="sm"
          onClick={() => {
            setSelected(null);
            onOpen();
          }}
        />
      </Flex>
      {isOpen && (
        <StateModalForm
          isOpen={isOpen}
          selected={selected}
          onClose={onClose}
          onChange={(modifications) => {
            if (modifications.change === 'add') {
              modify.add(modifications);
            }
            if (modifications.change === 'upd') {
              modify.upd(modifications);
            }
          }}
        />
      )}
    </>
  );
};
