import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Button,
} from '@chakra-ui/react';
import { Api } from 'BootQuery/Assets/js/api';
import { difference } from 'lodash-es';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';
import { dialerStore } from './store/pbx';

interface QueueMenuTextProps {
  queueCount: number;
}

export const QueueMenuText = ({
  queueCount: count,
}: QueueMenuTextProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  if (!count) {
    return t('Telephony:no_logged_in_queues');
  }

  return t('Telephony:logged_in_queues', { count });
};

export const QueueMenu = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { availableQueues, loggedInQueues } = dialerStore(
    ({ user, availableQueues }) => ({
      availableQueues,
      loggedInQueues: (user?.queues ?? []).map((queue) => queue.queue.name),
    })
  );
  const handleChange = (newVal: string | string[]) => {
    const val = typeof newVal === 'string' ? [newVal] : newVal;
    const toLogin = difference(val, loggedInQueues);
    const toLogout = difference(loggedInQueues, val);

    toLogin.forEach((queueName) => {
      Api.post('/api/telephony/me/queues', queueName);
    });
    toLogout.forEach((queueName) => {
      Api.delete(`/api/telephony/me/queues/${queueName}`);
    });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="link"
        color="gray.100"
        _active={{ color: 'white' }}
        rightIcon={<FaChevronDown />}
      >
        <QueueMenuText queueCount={loggedInQueues.length} />
      </MenuButton>
      <MenuList color="gray.900" zIndex="popover">
        <MenuOptionGroup
          title={t('Telephony:queues')}
          type="checkbox"
          value={loggedInQueues}
          onChange={handleChange}
        >
          {availableQueues.map((queue) => (
            <MenuItemOption key={queue.name} value={queue.name}>
              {queue.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
