import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ModalContent } from '@chakra-ui/react';
import {
  DeepPartial,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { Item, PartialItem } from '../types';
import { ProductFormCtxI, ProductFormData } from './types';
import { calculateWithTaxDefaults } from './utils';

const ProductFormCtx = createContext<ProductFormCtxI | null>(null);

export const ProductFormWrapper = (
  props: PropsWithChildren<ProductFormData>
) => {
  const methods = useForm<Item>({
    defaultValues: makeDefaults(props),
  });

  const {
    preventClose: { setState },
  } = useModalInstance();
  const isDirty = useMemo(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );
  const [formState, setFormState] = useFormState();
  useEffect(() => {
    setState(isDirty);
  }, [isDirty, setState]);

  return (
    <ProductFormCtx.Provider
      value={{ formState, setFormState, mode: props.mode }}
    >
      <FormProvider {...methods}>
        <ProductSubmitForm {...props} />
      </FormProvider>
    </ProductFormCtx.Provider>
  );
};

const ProductSubmitForm = (props: PropsWithChildren<ProductFormData>) => {
  const { closeWithCallback } = useModalInstance();
  const { handleSubmit } = useFormContext<Item>();
  const { setFormState } = useProductFormWrapper();

  return (
    <ModalContent
      as="form"
      data-ignore-form
      onSubmit={handleSubmit(
        (data) => {
          closeWithCallback(data, true);
          setFormState('saved');
        },
        (e) => {
          console.log('error', e);
          /** Set form state ERROR */
          setFormState('error');
        }
      )}
    >
      {props.children}
    </ModalContent>
  );
};

export const useProductFormWrapper = () => {
  const ctx = useContext(ProductFormCtx);
  if (!ctx) {
    throw Error('Missing Product Form Wrapper');
  }

  return ctx;
};

function makeDefaults(product: ProductFormData): DeepPartial<Item> {
  if (product.mode === 'edit') {
    return {
      ...product?.data,
      price: {
        ...product?.data?.price,
        withTax: calculateWithTaxDefaults(product?.data?.price),
      },
    } as DeepPartial<PartialItem>;
  }

  return { productType: 'good' };
}
