import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PrioritiesFormFields } from './PrioritiesFormFields';
import { PrioritiesFormData } from './types';
import { PriorityFormWrapper } from './PrioritiesFormWrapper';
import { FooterButtons } from './FooterButtons';

export const PrioritiesModal = (props: PrioritiesFormData) => {
  const { t } = useTranslation('Sales');

  return (
    <PriorityFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Sales:create_priority')
          : t('Sales:edit_priority')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <PrioritiesFormFields />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </PriorityFormWrapper>
  );
};
