import { slugifyCamelCase } from 'app/assets/js/slugify-camel-case';
import { uniqid } from 'app/assets/js/tsutil';

import { UpdateDataset } from '../types';
import { IDatasetForm } from './schema';

export function datasetFormToApi(data: IDatasetForm): UpdateDataset {
  return {
    ...data,
    columns: data.columns?.map((col) => ({
      ...col,
      key: col.key ?? (slugifyCamelCase(col.title) || uniqid()),
    })),
  };
}
