import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { uniqueId } from 'lodash-es';

import { ModalInstance } from './ModalInstance';
import { AddModalFnProps, ModalCtxProps, ModalInstaceProps } from './types';

const ModalCtx = createContext<ModalCtxProps | null>(null);

export const ModalProvider = ({ children }: PropsWithChildren) => {
  // We don't care about this
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [modals, setModals] = useState<ModalInstaceProps<any>[]>([]);
  const removeModal = useCallback((modalID: string) => {
    setModals((prev) => {
      return prev.filter((item) => item.modalID !== modalID);
    });
  }, []);

  const highestOrder = useMemo(() => {
    const orders = modals.map((item) => item.order ?? 0);

    const highestOrder = Math.max(...orders);
    if (highestOrder < 0) {
      return 0;
    }

    return highestOrder;
  }, [modals]);

  const addModal = useCallback(
    <CloseCallbackData, >(props: AddModalFnProps<CloseCallbackData>) => {
      const modalID = uniqueId();
      const getOrder = highestOrder + 1;
      const order = props.order ?? getOrder;
      setModals((prev) => [
        ...prev,
        {
          ...props,
          modalID,
          preventClose: props.preventClose ? props.preventClose : undefined,
          order,
          modalRemoveFn: () => {
            removeModal(modalID);
          },
        },
      ]);
    },
    [highestOrder, removeModal]
  );

  return (
    <ModalCtx.Provider value={{ addModal, removeModal, highestOrder }}>
      {children}
      {modals.map((item) => (
        <ModalInstance {...item} key={item.modalID} />
      ))}
    </ModalCtx.Provider>
  );
};

export const useModal = () => {
  const ctx = useContext(ModalCtx);
  if (!ctx) {
    throw Error('Missing Modal Context');
  }

  return ctx;
};
