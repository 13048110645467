import { CellProps } from 'BootQuery/Assets/components/Table';
import { ReactElement } from 'react';
import { CallStatus } from '../CallStatus';
import { CallListCall } from './types';
import { useCallListSettings } from './CallListSettingsContext';
import { CallStatus as UglyCallStatus } from '../HopsOperator/HopsCallList/CallStatus';

export const CallStatusCell = ({
  row: call,
}: CellProps<CallListCall>): ReactElement => {
  const { direction, endAt, answered } = call;
  const { useUglyIcons } = useCallListSettings();

  return useUglyIcons ? (
    <UglyCallStatus direction={direction} answered={answered} endAt={endAt} />
  ) : (
    <CallStatus direction={direction} endAt={endAt} answered={answered} />
  );
};
