import { Api } from 'BootQuery/Assets/js/api';
import { useQuery } from '@tanstack/react-query';
import { DepartmentsTreeSettings } from './types';

export const getDepartmentsTreeSettings =
  async (): Promise<DepartmentsTreeSettings> => {
    const { data } = await Api.get<DepartmentsTreeSettings>(
      '/api/phonebook/departmentsTreeSettings'
    );

    return data;
  };

export const useDepartmentsTreeSettings = () => {
  const useQueryResult = useQuery<DepartmentsTreeSettings>(
    ['departmentsTreeSettings'],
    async () => {
      const data = await getDepartmentsTreeSettings();

      return data;
    }
  );

  return useQueryResult;
};
