import { ReactElement } from 'react';

import { PhoneNumberList } from '@bq/components/PhoneNumberList';

import { ApiNumber } from './types';

interface NumberListProps {
  phoneNumbers: ApiNumber[];
  small?: boolean;
}

export const NumberList = ({
  phoneNumbers: apiPhoneNumbers,
  small = false,
}: NumberListProps): ReactElement => {
  const phoneNumbers = apiPhoneNumbers.map(
    ({ phoneNumber }) => phoneNumber.phoneNumberE164
  );

  return (
    <PhoneNumberList
      phoneNumbers={phoneNumbers}
      fontSize={small ? 'xs' : 'sm'}
    />
  );
};
