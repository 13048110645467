import { ReactElement, useMemo } from 'react';

import { CellProps, Column, Table } from '@bq/components/Table';

import { ImportColumnConfig, PreviewRow } from '../types';
import { HeaderCell } from './HeaderCell';
import { PreviewCell } from './PreviewCell';

interface Props {
  columns: ImportColumnConfig[];
  rows: PreviewRow[];
  isLoading?: boolean;
}

export const PreviewTable = ({
  columns,
  rows,
  isLoading,
}: Props): ReactElement => {
  return (
    <Table
      borderTop="2px"
      borderColor="brand.500"
      columns={useTableColumns(columns)}
      rows={rows}
      isLoading={isLoading}
    />
  );
};

function useTableColumns(columns: ImportColumnConfig[]): Column<PreviewRow>[] {
  return useMemo(() => {
    return columns.map(
      (col, colIdx): Column<PreviewRow> => ({
        key: col.key,
        title: col.title,
        children: <HeaderCell columnIndex={colIdx} />,
        Cell: makePreviewCell(colIdx, col),
      })
    );
  }, [columns]);
}

const makePreviewCell = (colIdx: number, config: ImportColumnConfig) => {
  const Cell = ({ row }: CellProps<PreviewRow>): ReactElement => {
    const value = row[colIdx];

    // When number of column changes (eg. after changing CSV delimiter), we may
    // get an intermediate render with a mismatch between number of columns and
    // actual columns from preview. Value will then be undefined, so just ignore
    // that for now
    if (!value) {
      return <></>;
    }

    return <PreviewCell value={value} column={config} />;
  };
  Cell.displayName = `PreviewCell_${colIdx}_${config.type}`;

  return Cell;
};
