import { MutableRefObject, useCallback } from 'react';

import {
  addItem,
  findItem,
  moveItem,
  MoveTarget,
  replaceItem,
} from '../../dnd-util';
import { BaseItem, CloneHandler, DraggedItemInfo } from '../types';

export interface AddOrMoveProps<C> {
  cloneHandler: CloneHandler<C>;
  currentlyDragged: MutableRefObject<DraggedItemInfo<C> | null>;
}

export type AddOrMove<C> = (
  items: BaseItem<C>[],
  item: BaseItem<C>,
  target: MoveTarget
) => BaseItem<C>[];

export function useAddOrMove<C>({
  cloneHandler,
  currentlyDragged,
}: AddOrMoveProps<C>): AddOrMove<C> {
  return useCallback(
    (items: BaseItem<C>[], item: BaseItem<C>, target: MoveTarget) => {
      if (item.mode === 'clone') {
        items = replaceItem(items, item.id, cloneHandler.cloneOriginal);
        item = cloneHandler.cloneTarget(item);
      }

      const existing = findItem(items, item.id);
      const modifiedItems = existing
        ? moveItem(items, item.id, target)
        : addItem(items, item, target);

      if (currentlyDragged.current?.item?.id !== item.id) {
        const found = findItem(modifiedItems, item.id);
        if (!found) {
          throw new Error(`Unable to find item after add or move: ${item.id}`);
        }

        currentlyDragged.current = {
          item,
          initialPosition: {
            position: found.position,
            path: found.path,
          },
        };
      }

      return modifiedItems;
    },
    [cloneHandler, currentlyDragged]
  );
}
