import { ReactElement, useRef } from 'react';
import { Button, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FieldValue } from '@bq/components/FormEditor';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { Card, CardBody } from 'BootQuery/Assets/components/Card';
import { ListWrapper } from 'BootQuery/Assets/components/ListWrapper';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { Column } from 'BootQuery/Assets/components/Table';

import { CallListPermissions } from '../call-list-permissions';
import { CallListContactModalProvider } from '../CallListContactModalProvider';
import { CallListHeading } from '../CallListHeading';
import { useFilterTypes } from '../filter-types';
import { formatCall } from '../format-call';
import { PageWithPlayer } from '../RecordingPlayer';
import { CallListCall } from '../types';
import { useInfiniteCalls } from '../use-calls';
import { useSelectedOverview } from '../use-selected-overview';
import { CardList } from './CardList';
import { useRefreshMethod } from './util';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const MobileCallCards = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const filterTypes = useFilterTypes(customFields);
  const cardRef = useRef<HTMLElement | null>(null);
  const overview = useSelectedOverview(overviews);
  const [refreshMethod, setRefreshMethod] = useRefreshMethod();
  const columns: Column<CallListCall>[] = [];
  const listingSettings = useListingSettings<CallListCall>({
    listingName: 'Telephony.CallList',
    viewName: 'mobile',
    filterTypes,
    columns,
    otherFilters: overview?.filters ?? [],
    customFields,
  });
  // Never remove the useTranslation to force Telephony translations to load
  const { t } = useTranslation('Telephony');

  const { filters, limit } = listingSettings;
  const { calls, refetch, isLoading, hasMore, loadMore, newRows } =
    useInfiniteCalls({
      limit,
      refreshMethod,
      filters: filters ?? [],
    });

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    noPagination: true,
    count: null,
  });
  const rows = calls ? calls.map(formatCall) : [];

  return (
    <PageWithPlayer>
      <CallListContactModalProvider>
        <ListWrapper spacing="5">
          <Card ref={cardRef}>
            <CardBody px="3" py="2">
              <CallListHeading
                {...headingProps}
                noPagination
                menuProps={{
                  ...headingProps.menuProps,
                  refreshMethod: {
                    value: refreshMethod,
                    onChange: setRefreshMethod,
                  },
                }}
                permissions={permissions}
                refetch={refetch}
                newRows={newRows}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
          {!rows.length ? (
            <Card>
              <CardBody py="16">
                <Heading color="gray.600" mb={0} textAlign="center">
                  {t('global:no_results')}
                </Heading>
              </CardBody>
            </Card>
          ) : (
            <>
              <CardList calls={rows} />
              {hasMore && (
                <Button
                  isLoading={isLoading}
                  onClick={loadMore}
                  colorScheme="blue"
                >
                  {t('global:load_more')}
                </Button>
              )}
            </>
          )}
        </ListWrapper>
      </CallListContactModalProvider>
    </PageWithPlayer>
  );
};
