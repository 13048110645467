import { useMemo } from 'react';
import { ChatFeedback } from '../types';

export const useAverageScore = (
  feedback: ChatFeedback | null
): number | null => {
  const ratings = feedback?.ratings ?? null;

  return useMemo(() => {
    if (!ratings) {
      return null;
    }

    return getAverageScore(ratings);
  }, [ratings]);
};

const getAverageScore = (ratings: Record<string, number>): number | null => {
  const scores = Object.values(ratings);
  if (scores.length === 0) {
    return null;
  }

  const sum = scores.reduce((total, score) => total + score);

  return sum / scores.length;
};
