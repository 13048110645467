import { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { fixedChakraFactory } from '@bq/components/fixed-chakra-factory';

import { useProductTotals } from './hooks/use-product-totals';
import { IProductFormItem } from './types';

export interface Props {
  /** The path to the root product list field. Eg. `something.products` */
  fieldName: string;
}

export const ProductItemTotals = ({ fieldName }: Props): ReactElement => {
  const { t } = useTranslation('Products');
  const { control } = useFormContext();
  const products: IProductFormItem[] | undefined = useWatch({
    control,
    name: fieldName,
  });

  const totals = useProductTotals(products ?? []);

  return (
    <Flex w="full" justifyContent="end">
      <TotalBox>
        {t('global:count')}: {totals.count}
      </TotalBox>
      <TotalBox>
        {t('Products:value')}: {totals.value}
      </TotalBox>
      <TotalBox>
        {t('Products:gross_weight')}: {totals.grossWeightKg}
        {'kg'}
      </TotalBox>
      <TotalBox>
        {t('Products:net_weight')}: {totals.netWeightKg}
        {'kg'}
      </TotalBox>
    </Flex>
  );
};

const TotalBox = fixedChakraFactory(Box, {
  baseStyle: {
    px: '2',
    py: '1',
    border: 'thin solid',
    borderRight: 'none',
    _first: {
      borderLeftRadius: 'sm',
    },
    _last: {
      borderRight: 'thin solid',
      borderRightRadius: 'sm',
    },
  },
});
