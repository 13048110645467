import { Fragment } from 'react';
import { ButtonGroup, Divider } from '@chakra-ui/react';

import { Image as ImageEmbed } from '../Buttons/Embeds/Image';
import { AddLink } from '../Buttons/Embeds/Link';
import { Fonts } from '../Buttons/Fonts/Fonts';
import { Lists } from '../Buttons/List/Lists';
import { Notation } from '../Buttons/Notation/Notation';
import { AddTable } from '../Buttons/Table/AddTable';
import { AlignMenu } from '../Menus/Align/AlignMenu';
import { HighlightColor } from '../Menus/Color/HighlightColor';
import { TextColor } from '../Menus/Color/TextColor';
import { FontFamily as FontFamilyComp } from '../Menus/FontFamily';
import { Heading } from '../Menus/Heading';
import { RichTextExtensions } from '../types';

interface Props {
  extensions?: RichTextExtensions[];
}
export const RTEToolbar = ({ extensions }: Props) => {
  return (
    <ButtonGroup isAttached size="sm" w="full" flexWrap="wrap">
      {extensions?.map((item) => (
        <Fragment key={item.key}>{item.component}</Fragment>
      ))}
      <Fonts showAdvanced />
      <Notation />
      <Divider orientation="vertical" borderWidth="1px" />

      <AlignMenu />
      <Divider orientation="vertical" borderWidth="1px" />

      <ImageEmbed />
      <AddLink />
      <Divider orientation="vertical" borderWidth="1px" />

      <Lists />
      <Divider orientation="vertical" borderWidth="1px" />

      <TextColor />
      <HighlightColor />
      <Divider orientation="vertical" borderWidth="1px" />

      <Heading />
      <FontFamilyComp />
      <Divider orientation="vertical" borderWidth="1px" />

      <AddTable />
    </ButtonGroup>
  );
};
