import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { CasePriority } from '../../schemas';

export async function getPriorities(params?: GetListingParams) {
  const { data } = await Api.get<ListingResponse<CasePriority>>(
    '/api/sales/priorities',
    {
      params,
    }
  );

  return data;
}
