import { Api } from 'BootQuery/Assets/js/api';
import {
  CompanyInfo,
  CompanyLocationInfo,
  ContactInfo,
  PersonInfo,
  UserInfo,
} from './types';

export async function getCompanyInfo(companyID: number): Promise<CompanyInfo> {
  const { data } = await Api.get<CompanyInfo>(
    `/api/phonebook/companies/${companyID}`,
    {
      params: {
        fields: [
          'ID',
          'name',
          'phoneNumbers.phoneNumber.phoneNumberE164',
          'emails.email.email',
        ],
      },
    }
  );

  return data;
}

export async function getPersonInfo(personID: number): Promise<PersonInfo> {
  const { data } = await Api.get<PersonInfo>(
    `/api/phonebook/people/${personID}`,
    {
      params: {
        fields: [
          'ID',
          'firstName',
          'lastName',
          'fullName',
          'phoneNumbers.phoneNumber.phoneNumberE164',
          'emails.email.email',
          ['company', ['ID', 'name']],
        ],
      },
    }
  );

  return data;
}

export async function getCompanyLocationInfo(
  locationID: number
): Promise<CompanyLocationInfo> {
  const { data } = await Api.get<CompanyLocationInfo>(
    `/api/phonebook/companies/0/locations/${locationID}`,
    {
      params: {
        fields: [
          'ID',
          'name',
          'phoneNumbers.phoneNumber.phoneNumberE164',
          'emails.email.email',
          ['company', ['ID', 'name']],
        ],
      },
    }
  );

  return data;
}

export async function getUserInfo(userID: number): Promise<UserInfo> {
  const { data } = await Api.get<UserInfo>(
    `/api/phonebook/contacts/user/${userID}`
  );

  return data;
}

export async function getContactInfo(
  type:
    | 'person'
    | 'company'
    | 'user'
    | 'companyLocation'
    | 'manual'
    | 'companyDepartment',
  id: number
): Promise<ContactInfo> {
  if (type === 'user') {
    return { type: 'user', contact: await getUserInfo(id) };
  }
  if (type === 'company') {
    return { type: 'company', contact: await getCompanyInfo(id) };
  }
  if (type === 'companyLocation') {
    return {
      type: 'companyLocation',
      contact: await getCompanyLocationInfo(id),
    };
  }

  return { type: 'person', contact: await getPersonInfo(id) };
}
