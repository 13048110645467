import { memo, ReactElement } from 'react';
import { chakra, Flex, useColorModeValue } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TypeItem } from './types';
import { BaseItem } from '../DndContainers';

type Props = BaseItem<TypeItem>;

const FieldListItem = (props: Props): ReactElement => {
  const { id, content } = props;
  if (content.itemType !== 'fieldType') {
    throw new Error(
      `Tried to render field list item from item of type ${content.itemType}`
    );
  }

  const { listeners, attributes, transition, transform, setNodeRef } =
    useSortable({ id, data: props });

  return (
    <FieldWrapper
      ref={setNodeRef}
      bg={useColorModeValue('brand.background', 'brand.backgroundDark')}
      style={{ transform: CSS.Transform.toString(transform), transition }}
      {...listeners}
      {...attributes}
    >
      <Flex p={1} pr={3} alignItems="center">
        <content.icon />
      </Flex>
      <Flex p={1} alignItems="center">
        {content.name}
      </Flex>
    </FieldWrapper>
  );
};

const FieldListItemMemo = memo(FieldListItem);

export { FieldListItemMemo as FieldListItem };

const FieldWrapper = chakra(Flex, {
  label: 'Field',
  baseStyle: {
    py: 2,
    px: 4,
    borderBottomWidth: 1,
    cursor: 'grab',
    _hover: { bg: 'gray.50' },
    _dark: {
      _hover: {
        bg: 'gray.800',
      },
    },
  },
});
