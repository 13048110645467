import { ReactElement } from 'react';
import { Box, Input } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColumnTypeSelect } from '../ColumnTypeSelect';
import { ImportSetupForm } from '../types';

interface Props {
  columnIndex: number;
}

export const HeaderCell = ({ columnIndex }: Props): ReactElement => {
  const { t } = useTranslation('Datasets');
  const { control, register } = useFormContext<ImportSetupForm>();

  const typeController = useController({
    control,
    name: `columns.${columnIndex}.type`,
  });

  return (
    <Box>
      <Input
        size="sm"
        placeholder={t('global:title')}
        mb="2"
        {...register(`columns.${columnIndex}.title`)}
      />
      <ColumnTypeSelect
        size="sm"
        value={typeController.field.value}
        onChange={typeController.field.onChange}
      />
    </Box>
  );
};
