import { FilterType, Operator } from './types';

export function getDefaultFilterValue<VALTYPE>(
  filter: FilterType<VALTYPE>
): VALTYPE | null {
  const { defaultValue } = filter;
  if (typeof defaultValue === 'function') {
    return (defaultValue as () => VALTYPE)();
  }

  if (defaultValue === undefined) {
    return null;
  }

  return defaultValue;
}

export function setValueAt<V>(
  items: V[],
  replaceIdx: number,
  replacer: (val: V) => V
): V[] {
  return items.map((item, idx) => (idx === replaceIdx ? replacer(item) : item));
}

export function removeValueAt<V>(items: V[], removeIdx: number): V[] {
  return items.filter((item, idx) => idx !== removeIdx);
}

export function nameStr(name: string | (() => string)): string {
  if (typeof name === 'function') {
    return name();
  }

  return name;
}

export function operatorsVal(
  operators: Operator[] | (() => Operator[])
): Operator[] {
  if (typeof operators === 'function') {
    return operators();
  }

  return operators;
}
