import { FilterTypes } from 'BootQuery/Assets/components/FilterBar';
import {
  makeUserFilter,
  userFilterIDExpr,
} from 'BootQuery/Assets/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { makeSendingAccountFilter } from './filters/sending-account-filter';

const senderUser = makeUserFilter({
  name: () => i18n.t('Sms:filters.sender_user'),
  toFilter: ({ value }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    const userID = userFilterIDExpr(value);

    return {
      sentByUserID: userID,
    };
  },
});

const groupAccount = makeSendingAccountFilter({
  toFilter: ({ value }) => {
    if (!value || typeof value === 'string') {
      return null;
    }

    return value.type === 'group'
      ? { 'group.name': value.name }
      : { 'account.senderId': value.phoneNumber };
  },
});

export const filterTypes: FilterTypes = {
  senderUser,
  groupAccount,
};
