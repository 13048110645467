import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { ReactElement, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface Props {
  companyID?: number;
}

const ActionsButton = memo(({ companyID }: Props): ReactElement => {
  const { t } = useTranslation();

  return (
    <ButtonGroup>
      <Tooltip hasArrow label={t('global:add')}>
        <IconButton
          as={Link}
          to={
            companyID
              ? `/phonebook/companies/${companyID}/locations/create`
              : '/phonebook/company-locations/create'
          }
          variant="outline"
          colorScheme="green"
          aria-label={t('global:add')}
          icon={<FaPlus />}
        />
      </Tooltip>
    </ButtonGroup>
  );
});
ActionsButton.displayName = 'Actions';

export { ActionsButton };
