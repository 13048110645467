import { ReactElement } from 'react';

import { FieldValue } from 'BootQuery/Assets/components/FormEditor';

import { CallFormModal } from './CallFormModal';
import { useCallFormModal } from './use-call-form-modal';

interface Props {
  customFields: FieldValue[];
}

export const CallFormProvider = ({ customFields }: Props): ReactElement => {
  const { modalProps } = useCallFormModal({ customFields });

  return <CallFormModal {...modalProps} />;
};
