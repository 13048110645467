module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card-group\">\n    <div class=\"card col-3 p-0 border-0\">\n        <div class=\"list-group formbuilder-available-fields\">\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_text",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":103},"end":{"line":4,"column":126}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"number\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":105},"end":{"line":5,"column":130}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"date\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_date",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":6,"column":103},"end":{"line":6,"column":126}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"time\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":103},"end":{"line":7,"column":126}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"datetime\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_datetime",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":107},"end":{"line":8,"column":134}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"select\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_dropdown",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":105},"end":{"line":9,"column":132}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"list\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_list",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":103},"end":{"line":10,"column":126}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"textarea\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_textarea",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":107},"end":{"line":11,"column":134}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"richtext\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_richtext",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":107},"end":{"line":12,"column":134}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"checkbox\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_checkbox",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":107},"end":{"line":13,"column":134}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"signature\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_signature",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":14,"column":108},"end":{"line":14,"column":136}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"separator\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_separator",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":15,"column":108},"end":{"line":15,"column":136}}}))
    + "</a>\n            <a href=\"#\" class=\"list-group-item list-group-item-action rounded-0\" data-form-type=\"note\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.type_note",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":16,"column":103},"end":{"line":16,"column":126}}}))
    + "</a>\n        </div>\n    </div>\n    <div class=\"card col-9 p-0\">\n        <div class=\"card-body formbuilder-form\">\n\n        </div>\n    </div>\n</div>";
},"useData":true})