module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"navbar px-0 py-0 menu-container\" >\n    <ul class=\"navbar-nav flex-column mt-0\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"modules"))) && lookupProperty(stack1,"Menu"))) && lookupProperty(stack1,"menus"))) && lookupProperty(stack1,"main")),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":5},"end":{"line":28,"column":17}}})) != null ? stack1 : "")
    + "\n        <li class=\"nav-item mt-auto menu-toggle-item\">\n            <a href=\"#\" class=\"nav-link py-1 px-3 text-center menu-toggle-btn\">\n                <span class=\"fa fa-chevron-left menu-toggle-icon-fold\"></span>\n                <span class=\"fa fa-chevron-right menu-toggle-icon-expand\"></span>\n            </a>\n        </li>\n    </ul>\n</nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":27,"column":21}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"nav-item menu-item "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"active") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":42},"end":{"line":6,"column":75}}})) != null ? stack1 : "")
    + "\" data-title=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trName") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":89},"end":{"line":6,"column":172}}})) != null ? stack1 : "")
    + "\">\n                <a class=\"nav-link px-3 py-2\" href=\"/"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"controller") : stack1), depth0))
    + "/"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"method") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":74},"end":{"line":7,"column":118}}})) != null ? stack1 : "")
    + "\" data-controller=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"controller") : stack1), depth0))
    + "\" data-method=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\" data-order=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"order") : depth0), depth0))
    + "\">\n                    <div class=\"row flex-nowrap\">\n                        <div class=\"col menu-item-icon\">\n                            <span class=\"icon-with-badge\">\n                                <span class=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span>\n                                <span class=\"badge badge-danger menu-item-notification-counter\" hidden></span>\n                            </span>\n                        </div>\n                        <div class=\"col px-2 menu-item-label\">\n                            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"trName") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":21,"column":35}}})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </div>\n                </a>\n            </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "active";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"trName") : depth0), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,lookupProperty(helpers,"concat").call(alias1,(depth0 != null ? lookupProperty(depth0,"module") : depth0),":menu_entries.",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":6,"column":126},"end":{"line":6,"column":163}}}),{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":6,"column":121},"end":{"line":6,"column":165}}}));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "/";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"trName") : depth0), depth0))
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,lookupProperty(helpers,"concat").call(alias1,(depth0 != null ? lookupProperty(depth0,"module") : depth0),":menu_entries.",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":74}}}),{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":32},"end":{"line":20,"column":76}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"report")),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":38,"column":11}}})) != null ? stack1 : "");
},"useData":true})