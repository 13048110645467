import { FaTags } from 'react-icons/fa';

import i18n from 'BootQuery/Assets/js/i18n';

import { BaseSettings, FieldType } from '../../types';
import { newFieldName } from '../../util';
import { ProductField } from './ProductField';
import { StaticProductField } from './StaticProductField';

export const productField = (): FieldType<BaseSettings> => ({
  type: 'product',
  name: i18n.t('global:form_editor.field_product'),
  icon: FaTags,
  components: {
    StaticFormField: StaticProductField,
    FormField: ProductField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  getFilters(_field) {
    return {};
  },
  getColumns(_field) {
    return [];
  },
});
