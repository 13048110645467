import { ReactElement, useMemo } from 'react';
import {
  Box,
  Divider,
  HStack,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { formatNumber } from 'app/assets/js/number-util';

import { Call, CallPointPhone } from '../../types';
import { useFormattedCall } from '../CallList/format-call';
import { CallListCall } from '../CallList/types';
import { ContactInput } from './ContactField';
import { NumberTypeField } from './NumberTypeField';

export const CallInfo = (call: Call): ReactElement => {
  const { t } = useTranslation('Telephony');

  const callInfo = useFormattedCall(call);
  const otherSide = useOtherSide(callInfo);

  // Nothing to show
  if (!otherSide && !callInfo.queue) {
    return <></>;
  }

  return (
    <>
      <Table size="sm">
        <Tbody>
          {otherSide && <NumberAndContactRows otherSide={otherSide} />}
          {callInfo.queue && (
            <Tr>
              <Th textAlign="right">{t('Telephony:columns.queue')}:</Th>
              <Td>{callInfo.queue}</Td>
            </Tr>
          )}
          {callInfo.direction === 'incoming' && (
            <Tr>
              <Th textAlign="right">
                {t('Telephony:columns.raw_dialed_number')}:
              </Th>
              <Td>{callInfo.dialedNumber}</Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <Divider />
    </>
  );
};

interface NumberAndContactProps {
  otherSide: CallPointPhone;
}

const NumberAndContactRows = ({
  otherSide,
}: NumberAndContactProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  return (
    <>
      <Tr>
        <Th whiteSpace="nowrap" width="1%" textAlign="right">
          {t('Telephony:phone_number')}:
        </Th>
        <Td>{formatNumber(otherSide?.phoneNumber)}</Td>
      </Tr>
      <Tr>
        <Th whiteSpace="nowrap" width="1%" textAlign="right">
          {t('Telephony:columns.contact')}:
        </Th>
        <Td>
          {otherSide.contact ? (
            otherSide.contact.name
          ) : (
            <HStack>
              <Box flex="1 1 75%">
                <ContactInput phoneNumber={otherSide.phoneNumber} />
              </Box>
              <Box flex="1 1 25%">
                <NumberTypeField />
              </Box>
            </HStack>
          )}
        </Td>
      </Tr>
    </>
  );
};

function useOtherSide(call: CallListCall): CallPointPhone | null {
  return useMemo(() => {
    if (
      call.direction === 'incoming' &&
      call.sourceInfo.source.type === 'phonePoint'
    ) {
      return call.sourceInfo.source.point;
    }
    if (
      call.direction === 'outgoing' &&
      call.destinationInfo.destination?.type === 'phonePoint'
    ) {
      return call.destinationInfo.destination.point;
    }

    return null;
  }, [call]);
}
