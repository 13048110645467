import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FieldValue } from '@bq/components/FormEditor';

import { CaseTypeFormWrapper } from './CaseTypeFormWrapper';
import { FooterButtons } from './FooterButtons';
import { TypeFormFields } from './TypeFormFields';
import { CaseTypesFormBase, ICaseTypesFormData } from './types';

export const CaseTypesModalContent = (
  props: ICaseTypesFormData & {
    formDefinition: FieldValue[];
    caseType: Partial<CaseTypesFormBase>;
  }
) => {
  const { t } = useTranslation();

  return (
    <CaseTypeFormWrapper {...props}>
      <ModalCloseButton />
      <ModalHeader>
        {props.mode === 'create'
          ? t('Sales:create_case_type')
          : t('Sales:edit_case_type')}
      </ModalHeader>

      <ModalBody>
        <TypeFormFields formMode={props.mode} />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </CaseTypeFormWrapper>
  );
};
