import { Link } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { ChatCustomer } from '../types';

export const CustomerDisplay = ({
  displayName,
  data,
}: ChatCustomer): ReactElement => {
  const email = data?.email || null;

  return (
    <>
      {displayName}
      {email && (
        <>
          {' <'}
          <Link href={`mailto:${email}`}>{email}</Link>
          {'>'}
        </>
      )}
    </>
  );
};
