import { createContext, useContext } from 'react';
import { IContactModalContext } from './types';

export const ContactModalContext = createContext<IContactModalContext | null>(null);

export const useContactModalContext = (): IContactModalContext => {
  const context = useContext(ContactModalContext);
  if (!context) {
    throw new Error('Missing contact modal context');
  }

  return context;
};
