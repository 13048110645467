module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedMode") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "fas fa-adjust";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span></span>\n                    <strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedMode") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</strong>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"spinner-grow spinner-grow-sm\"></span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"button\"\n                    class=\"dropdown-item user-status-set px-3\"\n                    data-color-mode=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\"\n                >\n                    <span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span>\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                </button>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"float-right pt-1 fa fa-check\"></span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"spinner-grow spinner-grow-sm\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"user-color-mode-menu\">\n    <a class=\"dropdown-item\" data-toggle=\"collapse\" href=\"#color-mode-collapse\" role=\"button\" aria-expanded=\"false\">\n        <div class=\"row no-gutters\">\n            <div class=\"col text-truncate\">\n                <span class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"selectedMode") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":103}}})) != null ? stack1 : "")
    + "\"></span>\n                Theme:\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selectedMode") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":12,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"col-auto text-top\">\n                <span class=\"fa fa-chevron-down\"></span>\n            </div>\n        </div>\n    </a>\n\n    <div class=\"collapse\" id=\"color-mode-collapse\">\n        <div class=\"mx-3 mt-1 border-left border-secondary color-mode-items\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"colorModes") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":35,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>";
},"useData":true})