import {
  Box,
  BoxProps,
  Portal as ChakraPortal,
  PortalProps,
  usePortalManager,
} from '@chakra-ui/react';

import { usePortalContainer } from './PortalContext';

interface AdditionalPortalProps {
  shouldWrapWithGlobalZIndexPortal?: boolean;
  containerProps?: BoxProps;
}

const defaultProps: BoxProps = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
};

export const Portal = ({
  children,
  shouldWrapWithGlobalZIndexPortal = true,
  containerProps,
  ...rest
}: PortalProps & AdditionalPortalProps) => {
  const portalRef = usePortalContainer();
  const manager = usePortalManager();

  return (
    <ChakraPortal containerRef={portalRef} {...rest}>
      <Box {...defaultProps} zIndex={manager?.zIndex} {...containerProps}>
        {children}
      </Box>
    </ChakraPortal>
  );
};
