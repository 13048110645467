import { useEffect, useMemo } from 'react';
import { FormControl, FormLabel, Input, SimpleGrid } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SelectOption,
  SelectOptionWithAdditionalData,
} from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';
import { useUnits } from 'app/Modules/Products/Assets/js/api';
import {
  BaseUnitGroup,
  useTranslateBaseUnitGroups,
} from 'app/Modules/Products/Assets/js/use-translate-base-unit-groups';

import { PartialGood } from '../../../types';
import { FieldProps } from '../../types';
import { parseNumberOrUndefined } from '../../utils';

type UnitGroupOption = SelectOptionWithAdditionalData<string, { key: string }>;

export const PackagingUnitField = ({ formControlProps }: FieldProps) => {
  const { data } = useUnits();
  const {
    control,
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext<PartialGood>();
  const { t } = useTranslation('Products');
  const unitGroupTranslations = useTranslateBaseUnitGroups();
  const unitGroups: UnitGroupOption[] = useMemo(() => {
    if (data) {
      return Object.keys(data).map((item) => ({
        key: item,
        value: item,
        label: unitGroupTranslations[item as BaseUnitGroup] ?? item,
      }));
    }

    return [];
  }, [data, unitGroupTranslations]);
  const packagingAmountUnitGroup = watch('good.packagingAmountUnitGroup');
  const units = useGroupUnitOptions(packagingAmountUnitGroup?.key);

  const { field: packagingAmountUnitGroupField } = useController<
    PartialGood,
    'good.packagingAmountUnitGroup'
  >({
    control,
    name: 'good.packagingAmountUnitGroup',
  });
  const { field: packagingAmountUnitField } = useController<
    PartialGood,
    'good.packagingAmountUnit'
  >({
    control,
    name: 'good.packagingAmountUnit',
  });

  useEffect(() => {
    setValue('good.packagingAmountUnit', undefined);
  }, [setValue, packagingAmountUnitGroup]);

  return (
    <SimpleGrid columns={[1, 1, 3, 3]} gridGap={4} w="full">
      <FormControl>
        <FormLabel htmlFor="good.packagingAmountUnitGroup'">
          {t('Products:packaging_amount_unit_group')}
        </FormLabel>
        <Select<string, { key: string }>
          size={['md', 'md', 'sm']}
          options={unitGroups}
          onChange={(val) => {
            if (val) {
              packagingAmountUnitGroupField.onChange(val);
            }
          }}
          value={
            packagingAmountUnitGroupField.value
              ? {
                  key: packagingAmountUnitGroupField.value.key,
                  value: packagingAmountUnitGroupField.value.key,
                  label:
                    unitGroupTranslations[
                      packagingAmountUnitGroupField.value.key as BaseUnitGroup
                    ] ?? packagingAmountUnitGroupField.value,
                }
              : undefined
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="good.packagingAmountUnit">
          {t('Products:packaging_amount_unit')}
        </FormLabel>
        <Select
          size={['md', 'md', 'sm']}
          queryKey={packagingAmountUnitGroupField.value?.key}
          options={units}
          isDisabled={!packagingAmountUnitGroup}
          onChange={(val) => {
            if (val) {
              packagingAmountUnitField.onChange({ symbol: val.value });
            }
          }}
          value={
            packagingAmountUnitField.value
              ? {
                  value: packagingAmountUnitField.value.symbol,
                  label: packagingAmountUnitField.value.symbol,
                }
              : undefined
          }
        />
      </FormControl>
      <FormControl
        isInvalid={!!errors.good?.packagingAmount}
        {...formControlProps}
      >
        <FormLabel htmlFor="packagingAmount">
          {t('Products:packaging_amount')}
        </FormLabel>
        <Input
          size={['md', 'md', 'sm']}
          type="number"
          {...register('good.packagingAmount', {
            setValueAs: parseNumberOrUndefined,
          })}
          step="any"
          isInvalid={!!errors.good?.packagingAmount}
          placeholder={t('Products:packaging_amount')}
        />
      </FormControl>
    </SimpleGrid>
  );
};

function useGroupUnitOptions(group?: string): SelectOption[] {
  const { data: units } = useUnits();

  return useMemo(() => {
    if (!units || !group) {
      return [];
    }

    if (units[group]) {
      return Object.values(units[group].units).map(({ symbol }) => ({
        label: symbol,
        value: symbol,
      }));
    }

    return [];
  }, [group, units]);
}
