import { Button, ButtonProps, useColorModeValue } from '@chakra-ui/react';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const TelLinkHandlerButton = (props: ButtonProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const btnColor = useColorModeValue('whiteAlpha', undefined);

  const registerHandler = useCallback(() => {
    navigator.registerProtocolHandler(
      'tel',
      `${window.location.origin}/telephony/dial?link=%s`
    );
  }, []);

  return (
    <Button onClick={registerHandler} colorScheme={btnColor} {...props}>
      {t('Telephony:register_tel_link_handler')}
    </Button>
  );
};
