import { FormEvent, ReactElement, useCallback, useState } from 'react';
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '@bq/components/FormActions';
import {
  FieldValue,
  FormEditor,
  useFormDefinition,
} from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useDOMInputJSON } from '@bq/components/use-dom-input';
import { useModalInstance } from '@bq/components/UseModal';

export const FormEditModal = (): ReactElement => {
  const formDef = useFormDefinition('Telephony.callForm', 'edit');

  if (!formDef) {
    return (
      <ModalContent>
        <LoadingPage />
      </ModalContent>
    );
  }

  return <FormModalContent formDefinition={formDef} />;
};

interface ContentProps {
  formDefinition: FieldValue[];
}

const FormModalContent = ({ formDefinition }: ContentProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { closeWithNoCallback } = useModalInstance();

  const [savedDef, setSavedDef] = useDOMInputJSON<FieldValue[]>(
    '#settings-form',
    'telephonyCallForm',
    formDefinition
  );
  const [value, onChange] = useState(savedDef);

  const submit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      ev.stopPropagation();

      setSavedDef(value);
      // setTimeout is used to wait a render cycle
      // for the useEffects to update the DOM value before closing
      setTimeout(closeWithNoCallback, 0);
    },
    [value, setSavedDef, closeWithNoCallback]
  );
  const cancel = useCallback(() => {
    onChange(savedDef);
    closeWithNoCallback();
  }, [savedDef, closeWithNoCallback]);

  return (
    <ModalContent
      onSubmit={(ev:FormEvent<HTMLFormElement>) => {
        submit(ev);
      }}
      as="form"
    >
      <ModalHeader>{t('Telephony:edit_form')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <FormEditor value={value} onChange={onChange} />
      </ModalBody>
      <ModalFooter>
        <FormActions onCancel={cancel} />
      </ModalFooter>
    </ModalContent>
  );
};
