import { memo, useMemo } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';

import { BulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  action: BulkAction<T>;
}
const BulkActionTextButton = <T extends OBJ>({ action }: Props<T>) => {
  const { selection, clearSelected } = useTableSelection<T>();
  const parsedSelection = useMemo(
    () => (action.parseSelection !== undefined
      ? action.parseSelection(selection)
      : selection),
    [action, selection]
  );

  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <Button
        onClick={() => {
          if (allowed) {
            action.handler(parsedSelection, selection).then(() => {
              if (action.onActionEnd !== undefined) {
                action.onActionEnd(selection);
                clearSelected();
              }
            });
          }
        }}
        isDisabled={!allowed}
        leftIcon={action.icon}
        size="sm"
      >
        {action.name}
      </Button>
    </Tooltip>
  );
};

const BulkActionTextButtonMemo = memo(
  BulkActionTextButton
) as typeof BulkActionTextButton;

export { BulkActionTextButtonMemo as BulkActionTextButton };
