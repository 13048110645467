import { RadioButtonGroup, RadioButton } from '@bq/components/Radio';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Item } from '../../../types';
import { FieldProps } from '../../types';

export const TypeField = ({ formControlProps }: FieldProps) => {
  const { control } = useFormContext<Item>();
  const { t } = useTranslation('Products');

  const {
    formState: { errors },
    field: { value, onChange },
  } = useController({
    name: 'productType',
    control,
    rules: { required: true },
  });

  return (
    <FormControl isInvalid={!!errors.productType} {...formControlProps}>
      <FormLabel>{t('Products:type')}</FormLabel>
      <RadioButtonGroup
        onChange={(val) => {
          onChange(val);
        }}
        defaultVal={value}
      >
        <RadioButton value="good">{t('Products:product')}</RadioButton>
        <RadioButton value="service">{t('Products:service')}</RadioButton>
      </RadioButtonGroup>
    </FormControl>
  );
};
