import { Dispatch, ReactElement, SetStateAction, useEffect } from 'react';
import { ModalBody, Progress } from '@chakra-ui/react';

import {
  BackgroundTask,
  useBackgroundTask,
} from '@bq/components/use-background-task';

import { ExportState } from './types';

interface Props {
  taskId: string;
  setExportState: Dispatch<SetStateAction<ExportState>>;
}

export const ExportBackgroundProgress = ({
  taskId,
  setExportState,
}: Props): ReactElement => {
  const { data: task } = useBackgroundTask(taskId);
  useEffect(() => {
    if (task?.status === 'completed') {
      if (!task.currentState) {
        throw new Error('Export task done without state data');
      }
      if (typeof task.currentState.stateData.downloadAt !== 'string') {
        throw new Error('Invalid export task result');
      }

      setExportState({
        type: 'done',
        downloadAt: task.currentState.stateData.downloadAt,
      });
    }
  }, [task, setExportState]);

  const progress = getTaskProgress(task);

  return (
    <ModalBody>
      {progress ? (
        <Progress
          size="lg"
          value={(progress.processed / progress.total) * 100}
        />
      ) : (
        <Progress size="lg" isIndeterminate />
      )}
    </ModalBody>
  );
};

interface TaskProgress {
  processed: number;
  total: number;
}

function getTaskProgress(
  task: BackgroundTask | undefined
): TaskProgress | null {
  if (task?.status !== 'running') {
    return null;
  }
  if (!task.currentState?.stateData) {
    return null;
  }

  const data = task.currentState.stateData;

  const processed = data.processed as number | undefined;
  const total = data.total as number | undefined;

  if (processed === undefined || total === undefined) {
    return null;
  }

  return { processed, total };
}
