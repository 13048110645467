import { useCallback, useEffect, useRef, useState } from 'react';
import {
  hashQueryKey,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useDebouncedRefetch } from './use-debounced-refetch';

export interface UseDebouncedQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'initialData'
  > {
  initialData?: () => undefined;
  duration?: number;
}

export function useDebouncedQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  queryKey,
  queryFn,
  ...options
}: UseDebouncedQueryOptions<
  TQueryFnData,
  TError,
  TData,
  TQueryKey
>): UseQueryResult<TData, TError> {
  const waiting = useRef(false);
  const timeoutRef = useRef<number | null>(null);
  const [currentQueryKey, setCurrentQueryKey] = useState(queryKey);
  const currentQueryKeyRef = useRef(currentQueryKey);
  const newQueryKey = useRef(queryKey);
  newQueryKey.current = queryKey;

  const result = useQuery({
    ...options,
    queryKey,
    queryFn,
  });

  const { refetch, refetchScheduled } = useDebouncedRefetch(
    result.refetch,
    waiting
  );

  const fetchIfNecesary = useCallback(() => {
    timeoutRef.current = null;
    waiting.current = false;

    if (
      hashQueryKey(currentQueryKeyRef.current ?? []) !==
      hashQueryKey(newQueryKey.current ?? [])
    ) {
      currentQueryKeyRef.current = newQueryKey.current;
      setCurrentQueryKey(newQueryKey.current);
    } else if (refetchScheduled.current) {
      refetch();
    }

    refetchScheduled.current = false;
  }, [refetch, refetchScheduled]);

  const duration = options?.duration ?? 1000;
  const startWaiting = useCallback(() => {
    waiting.current = true;

    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      waiting.current = false;
      fetchIfNecesary();
    }, duration);
  }, [fetchIfNecesary, duration]);

  // This effect ensures we wait at least `delay` after last `queryFn` finished
  // Before doing another fetch
  useEffect(() => {
    // After starting the fetch, disable fetching
    if (result.isFetching) {
      waiting.current = true;
    }

    // Only after we stop fetching, start the wait timer.
    // Ensures we wait for previous query to finish and only then wait
    if (!result.isFetching && waiting.current === true && !timeoutRef.current) {
      waiting.current = true;
      startWaiting();
    }
  }, [result.isFetching, startWaiting]);

  // This effect ensures we wait for the queryKey to stay still for at least `delay` miliseconds
  // Basically debounces `queryKey`
  useEffect(() => {
    if (waiting.current) {
      startWaiting(); // Prolong the waiting timer
    } else {
      setCurrentQueryKey(queryKey);
      currentQueryKeyRef.current = queryKey;
    }
    // Use hash of query key, should match react-query behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashQueryKey(queryKey ?? [])]);

  return {
    ...result,
    refetch,
  };
}
