import { ReportDefinition, ReportsProvider } from '../js/types';

export interface AvailableReport extends ReportDefinition {
  key: string;
  title: string;
  categoryTitle: string;
}

export type ReportsByCategory = Record<string, AvailableReport[]>;

export function useAvailableReports(): AvailableReport[] {
  const providers = window.BootQuery.getProviders<ReportsProvider>('reports');

  return providers.reduce((reports, provider) => {
    const providerReports = Object.entries(provider).map(([key, report]) => ({
      ...report,
      title: getCallbackString(report.title),
      categoryTitle: getCallbackString(report.categoryTitle),
      key,
    }));

    return [...reports, ...providerReports];
  }, [] as AvailableReport[]);
}

export function useReportsByCategory(): ReportsByCategory {
  const allReports = useAvailableReports();

  return allReports.reduce(
    (grouped, report) => ({
      ...grouped,
      [report.categoryTitle]: [
        ...(grouped[report.categoryTitle] ?? []),
        report,
      ],
    }),
    {} as ReportsByCategory
  );
}

export function useReport(key: string): AvailableReport | null {
  const reports = useAvailableReports();
  const report = reports.find((report) => report.key === key);

  return report ?? null;
}

function getCallbackString(name: string | (() => string)): string {
  if (typeof name === 'function') {
    return name();
  }

  return name;
}
