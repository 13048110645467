import { Link } from '@chakra-ui/react';
import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { TicketEvent } from './types';

export const TicketContactColumn: EventCellComponent<TicketEvent> = ({
  row: { data: ticket },
}) => {
  if (ticket.customerPerson) {
    return (
      <Link href={`/phonebook/people/${ticket.customerPerson.ID}`}>
        {ticket.customerPerson.firstName} {ticket.customerPerson.lastName}
      </Link>
    );
  }

  if (ticket.customerCompany) {
    return (
      <Link href={`/phonebook/companies/${ticket.customerCompany.ID}`}>
        {ticket.customerCompany.name}
      </Link>
    );
  }

  return <>-</>;
};
