import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getCountries,
  getCountry,
  getCurrentLanguage,
  getSearcher,
} from '@bq/components/country-utils';
import { Select } from '@bq/components/SelectWrappers';

import { Good } from '../../../types';
import { FieldProps } from '../../types';

export const CountryOfOriginField = ({ formControlProps }: FieldProps) => {
  const lang = useMemo(() => getCurrentLanguage(), []);

  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
  } = useFormContext<Good>();
  const { field } = useController<Good, 'good.countryOfOriginIso'>({
    control,
    name: 'good.countryOfOriginIso',
  });

  return (
    <FormControl
      isInvalid={!!errors.good?.countryOfOriginIso}
      {...formControlProps}
    >
      <FormLabel htmlFor="good.countryOfOriginIso">
        {t('Products:country_of_origin')}
      </FormLabel>
      <Select
        size={['md', 'md', 'sm']}
        onChange={(newValue) => {
          if (newValue) {
            field.onChange(newValue.value);
          } else {
            field.onChange(null);
          }
        }}
        value={
          field.value
            ? { value: field.value, label: getCountry(lang, field.value).name }
            : undefined
        }
        isClearable
        options={async (search = '') => {
          return searchRaw(search).map((item) => ({
            value: item.iso,
            label: item.name,
          }));
        }}
      />
    </FormControl>
  );
};

const searchRaw = (search: string) => {
  const lang = getCurrentLanguage();
  if (!search.trim()) {
    return getCountries(lang).slice(0, 30);
  }

  return getSearcher(lang)
    .search(search)
    .map((res) => res.item);
};
