import { ReactElement } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CaseCampaigns } from './CaseCampaigns/CaseCampaings';
import { CaseInterestLevelContent } from './CaseInterestLevel/CaseInterestLevel';
import { CasePriortiesContent } from './CasePriorities/CasePriorities';
import { CaseStateContent } from './CaseState/CaseStateSettings';
import { CaseTypes } from './CaseTypes';
import { DocumentTemplates } from './DocumentTemplates';
import { SalesOverviews } from './SalesOverviews';

export const Settings = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <VStack alignItems="start" px={4}>
        <SalesOverviews />
      </VStack>
      <Divider mb={0} />
      <Accordion w="full" p={0} allowMultiple>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:case_types')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pr={0}>
            <CaseTypes />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:case_priorities')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CasePriortiesContent />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:case_states')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CaseStateContent />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:case_interest_level')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CaseInterestLevelContent />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:case_campaigns')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <CaseCampaigns />
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border="none">
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left" fontWeight="bold">
              <Box>{t('Sales:document_templates')}</Box>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <DocumentTemplates />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};
