import { createContext, ReactNode, useContext } from 'react';

import {
  useDisplayMode,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { UseListingSettingsReturn } from '@bq/components/ListingSettings/types';

import { ticketFilterTypes } from './filter-types';
import { useTicketingContext } from './TicketingContext';
import { ticketColumns } from './TicketTable/Columns/columns';
import { defaultVisibleColumns } from './TicketTable/Columns/defaults';
import { Ticket } from './types';
import { useSelectedOverview } from './use-selected-overview';

const ListingSettingsCtx =
  createContext<UseListingSettingsReturn<Ticket> | null>(null);

interface TicketListingSettingsProviderProps {
  children: ReactNode;
}
export const TicketListingSettingsProvider = ({
  children,
}: TicketListingSettingsProviderProps) => {
  const [displayMode] = useDisplayMode('Ticketing.TicketList');
  const { allCustomFields } = useTicketingContext();
  const selectedOverview = useSelectedOverview();

  const listingSettings = useListingSettings<Ticket>({
    listingName: 'Ticketing.TicketList',
    viewName: displayMode,
    filterTypes: ticketFilterTypes,
    columns: ticketColumns(),
    otherFilters: selectedOverview?.filters ?? [],
    defaults: {
      visibleColumns: defaultVisibleColumns,
      sort: [{ direction: 'desc', sortBy: 'ID' }],
    },
    customFields: allCustomFields !== null ? allCustomFields : undefined,
  });

  return (
    <ListingSettingsCtx.Provider value={listingSettings}>
      {children}
    </ListingSettingsCtx.Provider>
  );
};

export const useListingSettingsContext = () => {
  const ctx = useContext(ListingSettingsCtx);
  if (!ctx) {
    throw Error('Missing Ticketing Listing Provider');
  }

  return ctx;
};
