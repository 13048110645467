import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';

import { IAppointment } from './schema';

export interface IAppointmentEvents {
  onAdd?: (appointment: IAppointment) => void;
  onModify?: (appointment: IAppointment) => void;
  onDelete?: (appointmentID: number) => void;
}

export const AppointmentEventsContext =
  createContext<IAppointmentEvents | null>(null);

export const AppointmentEventsProvider = ({
  children,
  onAdd,
  onModify,
  onDelete,
}: PropsWithChildren<IAppointmentEvents>): ReactElement => {
  const value = useMemo(
    () => ({ onAdd, onModify, onDelete }),
    [onAdd, onModify, onDelete]
  );

  return (
    <AppointmentEventsContext.Provider value={value}>
      {children}
    </AppointmentEventsContext.Provider>
  );
};

export function useAppointmentEvents(): IAppointmentEvents | null {
  return useContext(AppointmentEventsContext);
}
