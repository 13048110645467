import { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ExportModalProvider } from '@bq/components/Export';
import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { FilterExpression } from 'BootQuery/Assets/components/FilterBar';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  Table,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';
import { parseSort } from 'BootQuery/Assets/js/utils';

import { columns, personFilterTypes } from '../PersonList';
import { generateExport } from '../PersonList/generate-export';
import { getCardPeople } from '../PersonList/get-people';
import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData, TableSettings } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { sendSms } from './company-employee-list-actions';
import { phonebookDisplayModeOptions } from './display-settings';
import { ShowSubdepartmentEmployees } from './ShowPeopleFromSubDepartmentsToggle';

interface Props {
  company: { ID: number; name: string };
  settings?: Partial<TableSettings>;
  customFields?: FieldValue[];
  isStandalone?: boolean;
  department?: { ID: number; name: string };
  companyLocation?: { ID: number; name: string };
  listingName?: string;
  permissions: Permissions;
}

export const CompanyEmployeeTableList = ({
  company,
  customFields = [],
  department,
  listingName = 'Phonebook.CompanyEmployeeList',
  companyLocation,
  permissions,
}: Props): ReactElement => {
  const loaded = useLoadUserSettings('Departments', {});
  const overview = useSelectedOverview('employees');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName,
    viewName: 'table',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: overview?.filters ?? [],
    customFields,
  });

  const { columnsToShow, filters, limit, page, setSort, sort } =
    listingSettings;

  const { data, refetch } = useQuery({
    queryKey: [
      `${listingName}.table`,
      page,
      filters,
      limit,
      sort,
      company.ID,
      department?.ID,
    ],
    queryFn: () => {
      return getCardPeople({
        page,
        filters: getFiltersDeparatment({
          filters,
          companyID: company.ID,
          departmentID: department?.ID,
          companyLocationID: companyLocation?.ID,
        }),
        limit,
        sort: parseSort(sort),
      });
    },
    enabled: company !== undefined && filters !== undefined,
    keepPreviousData: true,
  });
  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      { actions: [sendSms(navigate)], styleProps: { colorScheme: 'brand' } },
    ],
    [navigate]
  );
  const itemCount = useMemo(() => data?.meta.count ?? 0, [data?.meta.count]);

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: itemCount,
    displayModeOptions: phonebookDisplayModeOptions,
    menuChildren: <ShowSubdepartmentEmployees />,
  });

  if (!data || !customFields || !loaded) {
    return <LoadingPage />;
  }

  const { data: people } = data;

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<PersonApiData>
        page={page}
        idColumn="ID"
        selectPage={() => people}
        selectAll={async () => {
          const { data } = await getCardPeople({
            limit: 99999,
            filters,
          });

          return data;
        }}
      >
        <Box>
          <Box
            mt={company.ID ? 4 : 9}
            mb={company.ID ? 6 : 14}
            px={company.ID ? 2 : 5}
          >
            <ExportModalProvider
              onExport={generateExport}
              rowCount={itemCount}
              filterExpression={filters ?? []}
            >
              <ListHeading
                {...headingProps}
                Actions={<Actions company={company} />}
              />
            </ExportModalProvider>
          </Box>
          <Table<PersonApiData>
            columns={columnsToShow}
            rows={people}
            createRowLink={creatRowLink}
            size="md"
            bulkActions={bulkActions}
            sort={{ onChange: setSort, value: sort }}
          />
        </Box>
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};

function creatRowLink(person: PersonApiData): string {
  return `/phonebook/people/${person.ID}`;
}

export const getFiltersDeparatment = ({
  filters,
  companyID,
  departmentID,
  companyLocationID,
}: {
  filters?: FilterExpression[];
  companyID?: number;
  departmentID?: number;
  companyLocationID?: number;
}) => {
  return [
    ...(filters ?? []),
    ...(companyID ? [{ companyID }] : []),
    ...(departmentID ? [{ 'department.ID': departmentID }] : []),
    ...(companyLocationID ? [{ 'companyLocation.ID': companyLocationID }] : []),
  ];
};
