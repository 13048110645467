import { Box, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import {
  pbxCallStateColors,
  usePbxCallStateLabels,
  softphoneSessionStateColors,
  useSoftphoneSessionStateLabels,
} from './labels-colors';
import { CallDisplayProps } from './types';

export const CallStateDisplay = (callInfo: CallDisplayProps): ReactElement => {
  let stateColor: string;
  let stateLabel: string;
  const pbxCallStateLabels = usePbxCallStateLabels();
  const softphoneSessionStateLabels = useSoftphoneSessionStateLabels();

  if (callInfo.type === 'softphone') {
    stateColor = softphoneSessionStateColors[callInfo.call.state];
    stateLabel = softphoneSessionStateLabels[callInfo.call.state];
  } else {
    const callState = callInfo.call.call.currentState?.callState ?? null;

    if (callState) {
      stateColor = pbxCallStateColors[callState];
      stateLabel = pbxCallStateLabels[callState];
    } else {
      stateColor = 'white';
      stateLabel = 'Unknown';
    }
  }

  return (
    <Box width="100%" textAlign="center">
      <Text as="strong" color={stateColor} fontWeight="bold" fontSize="sm">
        {stateLabel}
      </Text>
    </Box>
  );
};
