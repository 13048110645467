import { ReactElement, useMemo } from 'react';
import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CaseTypesForm } from '../types';
import { subjectTypeToSelectOption } from './util';

export const SubjectFieldsStatic = (): ReactElement => {
  const { t } = useTranslation('Sales');
  const { control } = useFormContext<CaseTypesForm>();

  const subjectType = useWatch({ control, name: 'subjectType' });
  const dataset = useWatch({ control, name: 'dataset' });

  const subjectTypeOption = useMemo(() => {
    return subjectTypeToSelectOption(subjectType ?? null);
  }, [subjectType]);

  return (
    <>
      <FormControl display="flex">
        <FormLabel my={0}>{t('Sales:subject_type')}:</FormLabel>
        <Box>{subjectTypeOption?.label}</Box>
      </FormControl>
      {subjectType === 'dataset' && (
        <FormControl display="flex">
          <FormLabel my={0}>{t('Sales:dataset')}:</FormLabel>
          <Text as="span">{dataset?.label}</Text>
        </FormControl>
      )}
    </>
  );
};
