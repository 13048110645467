import { ReactElement } from 'react';
import { Box, Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import { endOfDay, startOfDay } from 'date-fns';

import { useAutosizingInput } from 'BootQuery/Assets/components/use-autosizing-input';
import i18n from 'BootQuery/Assets/js/i18n';

import { DateInput, DateRange, formatDate } from '../../DateInput';
import { Jsonish } from '../../type-util';
import { FilterName } from '../FilterName';
import { FilterOperatorInput, FilterTagOperator } from '../FilterOperator';
import { FilterProps, FilterType, ParseQueryString } from '../types';

function parseFilter(
  value: ParseQueryString | Jsonish,
  operator: string | null
): Date | DateRange | null {
  if (!value) {
    return null;
  }

  if (operator === 'between') {
    if (typeof value !== 'object') {
      return null;
    }

    if (!('from' in value && 'to' in value)) {
      return null;
    }
    if (typeof value.from !== 'string' || typeof value.to !== 'string') {
      return null;
    }

    return {
      from: new Date(value.from),
      to: new Date(value.to),
    };
  }

  if (typeof value === 'string') {
    return new Date(value);
  }

  return null;
}

function isDateRange(value: Jsonish | DateRange | null): value is DateRange {
  if (!value || !(value instanceof Object)) {
    return false;
  }

  const hasFrom = 'from' in value && value.from instanceof Date;
  const hasTo = 'to' in value && value.to instanceof Date;

  return hasFrom && hasTo;
}

function getDateValue(
  value: Jsonish,
  operator: string | null | undefined
): Date | DateRange | null {
  if (!value) {
    return null;
  }

  if (value instanceof Date || isDateRange(value)) {
    return value;
  }

  return parseFilter(value, operator ?? null);
}

export const DateFilterTag = ({
  name,
  value,
  operator,
  operators,
  onChange,
  onOperatorChange,
  onRemove,
  isNew,
}: FilterProps<Date | DateRange>): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');
  const autosizeVal = value instanceof Date ? formatDate(value) : '';
  const inputEl = useAutosizingInput(autosizeVal);

  return (
    <Flex align="stretch">
      <FilterName name={name} />
      {operators.length > 0 && (
        <FilterTagOperator
          operators={operators}
          value={operator ?? null}
          onChange={onOperatorChange}
        />
      )}
      <Flex background={bgColor} height="8" borderRightRadius="md">
        <Box>
          <DateInput
            size="sm"
            inputRef={inputEl}
            mode={operator === 'between' ? 'range' : 'single'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            value={value as any}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={onChange as any}
            onRemove={onRemove}
            inputClass="filterbar-datepicker-tag"
            autoFocus={isNew}
          />
        </Box>
        <IconButton
          onClick={onRemove}
          variant="link"
          aria-label="Close"
          icon={<FaTimes />}
          size="xs"
        />
      </Flex>
    </Flex>
  );
};

export const DateFilterInput = ({
  value,
  operator,
  operators,
  onChange,
  onOperatorChange,
  onRemove,
}: FilterProps<Date | DateRange>): ReactElement => (
  <>
    {operators.length > 0 && (
      <FilterOperatorInput
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <DateInput
      mode={operator === 'between' ? 'range' : 'single'}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={value as any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={onChange as any}
      onRemove={onRemove}
      inputClass="filterbar-datepicker-input"
    />
  </>
);

type DateFilter = FilterType<Date | DateRange>;
type DateUserDef = Partial<DateFilter> & Pick<DateFilter, 'toFilter' | 'name'>;
export function makeDateFilter(filterDef: DateUserDef): DateFilter {
  return {
    tagComponent: DateFilterTag,
    inputComponent: DateFilterInput,
    operators: () => [
      { operator: 'gte', display: i18n.t('global:operators.from') },
      { operator: 'lte', display: i18n.t('global:operators.to') },
      { operator: 'between', display: i18n.t('global:operators.between') },
    ],
    fromQueryString: parseFilter,
    fromJSON: getDateValue,
    ...filterDef,
  };
}

/**
 * Returns a `toFilter` function for a date filter
 */
export function makeDateToFilter(field: string): DateFilter['toFilter'] {
  return ({ operator, value }) => {
    if (!value) {
      return null;
    }

    if (operator === 'between') {
      if (value instanceof Date) {
        return null;
      }

      return {
        $and: {
          [`${field}:gte`]: startOfDay(value.from).toISOString(),
          [`${field}:lte`]: endOfDay(value.to).toISOString(),
        },
      };
    }

    if (value instanceof Date) {
      const ts = operator === 'gte' ? startOfDay(value) : endOfDay(value);

      return { [`${field}:${operator}`]: ts.toISOString() };
    }

    return null;
  };
}
