module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-12\">\n    <div class=\"form-group row\">\n        <label class=\"col-12 col-md-2 col-form-label text-left text-md-right control-label\">\n            <span class=\"label-text\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span><span class=\"label-after\">:</span>\n        </label>\n        <div class=\"col-12 col-md-10\">\n            <div class=\"card subform-card\">\n                <div class=\"card-body formeditor-list-items\"></div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true})