import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CustomEventTypesEdit } from './CustomEventTypesEdit';
import { EventTypeSettingsProvider } from './EventTypeSettingsContext';
import { CustomEventTypeSettings } from './types';

interface Props {
  customEventTypes: CustomEventTypeSettings[];
}

export const Settings = ({ customEventTypes }: Props): ReactElement => {
  console.assert(
    Array.isArray(customEventTypes),
    'Got non-array customEventTypes value'
  );

  return (
    <Box>
      <EventTypeSettingsProvider initial={customEventTypes}>
        <CustomEventTypesEdit />
      </EventTypeSettingsProvider>
    </Box>
  );
};
