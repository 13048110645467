import { ReactElement } from 'react';
import { Menu, MenuButton, MenuList, IconButton } from '@chakra-ui/react';
import { FaEllipsisV } from 'react-icons/fa';
import { Portal } from 'BootQuery/Assets/components/Portal';
import { CallActionItem, getCallActions } from '../CallActions';
import { CallListCall } from '../CallList/types';

interface CallActionsMenuProps {
  call: CallListCall;
}

export const CallActionsMenu = ({
  call,
}: CallActionsMenuProps): ReactElement => {
  const actions = getCallActions(call);

  if (!actions.length) {
    return <></>;
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        size="sm"
        variant="ghost"
        aria-label="Call Actions"
        icon={<FaEllipsisV />}
      />
      <Portal>
        <MenuList>
          {actions.map((action, idx) => (
            <CallActionItem key={idx} action={action} />
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};
