import { Api } from 'BootQuery/Assets/js/api';
import {
  DefaultPatchReturn,
  DefaultPostReturn,
} from 'BootQuery/Assets/js/globalTypes';
import {
  PatchPerson,
  PostPerson,
} from './types';

export const patchPerson = <ReturnType = DefaultPatchReturn>({
  data,
  params,
  ID,
}: PatchPerson) => {
  return Api.patch<ReturnType>(`/api/phonebook/people/${ID}`, data, { params });
};

export const postPerson = <ReturnType = DefaultPostReturn>({
  data,
  params,
}: PostPerson) => {
  return Api.post<ReturnType>('/api/phonebook/people', data, { params });
};
