import { useMemo } from 'react';

import { Column } from '@bq/components/Table';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import i18n from 'BootQuery/Assets/js/i18n';

import { ImportColumnConfig } from '../types';
import { ActionsCell } from './cells/ActionsCell';
import { datasetFieldToColumn } from './field-to-column';
import { DatasetEntryListItem } from './schema';

export function getColumns(
  datasetColumns: ImportColumnConfig[]
): Column<DatasetEntryListItem>[] {
  const fieldColumns = datasetColumns
    .map(datasetFieldToColumn)
    // Show first 6 fields by default
    .map((field, idx) => ({ ...field, defaultVisible: idx < 6 }));

  return [
    ...fieldColumns,
    {
      key: 'createdAt',
      title: i18n.t('global:created_at'),
      getValue: ({ row }) => formatDate(row.lastCampaignAt, DateType.DateTime),
    },
    {
      key: 'lastCampaignAt',
      title: i18n.t('Datasets:last_campaign_at'),
      getValue: ({ row }) => formatDate(row.lastCampaignAt, DateType.DateTime),
      defaultVisible: false,
    },
    {
      key: 'lastCallStart',
      title: i18n.t('Datasets:last_call_start'),
      getValue: ({ row }) => formatDate(row.lastCallStart, DateType.DateTime),
      defaultVisible: false,
    },
    {
      tdProps: { py: 0 },
      key: 'action',
      width: '160px',
      title: '',
      Cell: ActionsCell,
    },
  ];
}

export function useColumns(
  datasetColumns: ImportColumnConfig[]
): Column<DatasetEntryListItem>[] {
  return useMemo(() => getColumns(datasetColumns), [datasetColumns]);
}
