export function capitalizeFirstLetter(value: string): string {
  if (value.length > 0) {
    return value
      .split(' ')
      .map((word) => (word[0]?.toUpperCase() ?? '') + word.slice(1).toLowerCase())
      .join(' ');
  }

  return '';
}
