import Fuse from 'fuse.js';
import Countries from 'i18n-iso-countries';
import CountriesDE from 'i18n-iso-countries/langs/de.json';
import CountriesEN from 'i18n-iso-countries/langs/en.json';
import CountriesHR from 'i18n-iso-countries/langs/hr.json';

import { FilterType } from '@bq/components/FilterBar';
import {
  ComboboxFilterInput,
  ComboboxFilterTag,
} from '@bq/components/FilterBar/filters/ComboboxFilter';
import {
  ComboboxFilterDef,
  makeComboboxFilter,
} from '@bq/components/FilterBar/filters/ComboboxFilter/make-combobox-filter';
import i18n from 'BootQuery/Assets/js/i18n';

Countries.registerLocale(CountriesHR);
Countries.registerLocale(CountriesEN);
Countries.registerLocale(CountriesDE);

Countries.getNames('hr');

interface CountryInfo {
  iso: string;
  name: string;
}

interface ValType {
  iso: string;
}

type CountryFilterProps = ComboboxFilterDef<CountryInfo, ValType>;

type Lang = 'hr' | 'en' | 'de';

const searchers: Partial<Record<Lang, Fuse<CountryInfo>>> = {};
function getCurrentLanguage(): Lang {
  return (i18n.language ?? 'hr') as Lang;
}
function getCountries(lang: Lang): CountryInfo[] {
  const countryMap = Countries.getNames(lang);

  return Object.entries(countryMap).map(([iso, name]) => ({ iso, name }));
}
function getSearcher(lang: Lang): Fuse<CountryInfo> {
  let searcher = searchers[lang];
  if (!searcher) {
    searcher = new Fuse(getCountries(lang), {
      keys: ['iso', 'name'],
    });

    searchers[lang] = searcher;
  }

  return searcher;
}

const countryFilterDefaults: Omit<CountryFilterProps, 'toFilter'> = {
  tagComponent: ComboboxFilterTag,
  inputComponent: ComboboxFilterInput,
  name: 'country',
  operators: [],
  extraProps: {
    enableTextSearch: false,
    cacheKey: 'countryCombobox',
    search: async (search: string) => {
      const lang = getCurrentLanguage();
      if (!search.trim()) {
        return getCountries(lang).slice(0, 30);
      }

      return getSearcher(lang)
        .search(search)
        .map((res) => res.item);
    },
    itemToString: (item: CountryInfo) => item.name,
    itemToValue: ({ iso }) => ({ iso }),
    valueToItem: async (val) => {
      const map = Countries.getNames(getCurrentLanguage());

      return { iso: val.iso, name: map[val.iso] };
    },
  },
};

export function makeCountryFilter(
  filterDef: Partial<CountryFilterProps> & Pick<CountryFilterProps, 'toFilter'>
): FilterType {
  return makeComboboxFilter({
    ...countryFilterDefaults,
    ...filterDef,
  });
}
