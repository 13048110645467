import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import { EmptyCell } from './EmptyCell';
import { MailEditorCard } from './MailEditorCard';

export type {
  MailEditor,
  MailEditorMap,
  NewMessageParams,
  ReplyHandler,
  ForwardHandler,
  SentHandler,
} from './types';
export { useMailEditors } from './use-mail-editors';
export { MailEditorsProvider } from './MailEditorsProvider';

export const mailEditorEventProviders: ModuleEventTypeProviders = {
  mailEditor: {
    components: {
      Card: MailEditorCard,
    },
    // Mail editors should never show in regular views/tables, should maybe even error
    columns: {
      type: { Cell: EmptyCell },
      event: { Cell: EmptyCell },
      user: { Cell: EmptyCell },
      contact: { Cell: EmptyCell },
    },
  },
};
