import { IOverviewItem } from 'BootQuery/Assets/components/Overviews';
import { useParams } from 'react-router-dom';

type RouterParams = {
    overview?: string;
  };

export function useSelectedOverview(
  overviews: IOverviewItem[]
): IOverviewItem | null {
  const { overview } = useParams<RouterParams>();

  if (overview) {
    const selected = overviews.find((ov) => ov.slug === overview);
    if (selected) {
      return selected;
    }
  }

  return overviews[0] ?? null;
}
