import { ButtonGroup, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPen, FaTrash } from 'react-icons/fa';

import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import { CellProps } from '@bq/components/Table';

import { deleteProduct } from '../API/Product/deleteProduct';
import { patchProduct } from '../API/Product/patchProduct';
import {
  handleDeleteProduct,
  handleEditProduct,
} from '../Events/product-events';
import { useProductModal } from '../Form/ProductModal/useProductModal';
import { LoadedItem } from '../types';
import { useParseToRequestData } from '../utils';

export const ActionsCell = ({ row }: CellProps<LoadedItem>) => {
  const { t } = useTranslation();
  const parseToRequestData = useParseToRequestData();
  const { productEditModalProvidedData } = useProductModal();
  const confirmAction = useConfirmationModal();

  return (
    <ButtonGroup variant="ghost" isAttached size="sm">
      <IconButton
        colorScheme="red"
        aria-label={t('Sales:delete_product')}
        icon={<FaTrash />}
        onClick={() => {
          confirmAction({
            results: [
              `${t('Sales:delete_product')}: ${row?.name}-#${row?.ID}`,
            ],
            onConfirm: async () => {
              const data = await deleteProduct({ productID: row.ID });
              handleDeleteProduct(data);
            },
          });
        }}
      />

      <IconButton
        aria-label={t('Sales:edit_product')}
        icon={<FaPen />}
        onClick={() => {
          productEditModalProvidedData(row, async (data: LoadedItem) => {
            const newData = await patchProduct<LoadedItem>({
              productID: data.ID,
              data: parseToRequestData(data),
              params: { fields: ['$full'] },
            });
            handleEditProduct(newData);
          });
        }}
      />
    </ButtonGroup>
  );
};
