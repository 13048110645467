import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';
import { Ticket } from '../../types';

interface LimitNullGetListingParams extends Omit<GetListingParams, 'limit'> {
  limit?: number | 'null';
}
export const getTickets = async <T = Ticket>(
  params: LimitNullGetListingParams = {}
) => {
  const data = await Api.get<ListingResponse<T>>('/api/ticketing/tickets', {
    params: {
      sort: defaultSort(params.sort),
      ...params,
    },
  });

  return data;
};

const defaultSort = (sort?: string | string[]) => {
  if (!sort || (sort && sort?.length >= 0)) {
    return ['ID:desc'];
  }

  return sort;
};
