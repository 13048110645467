import { useDisclosure } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageVisibilityModal } from './MessageVisibilityModal';

export const PermissionSettings = (): ReactElement => {
  const { t } = useTranslation('Sms');

  const modalState = useDisclosure();
  const groupID = window.Bootstrap.bootquery.parameters.groupID as string;

  return (
    <div className="mt-5">
      <h3>
        <span className="fas fa-sms"></span> {t('Sms:sms_title')}
      </h3>
      <hr />
      <div className="flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={modalState.onOpen}
        >
          <span className="fas fa-eye"></span>{' '}
          {t('Sms:permissions.message_visibility')}
        </button>{' '}
      </div>
      <MessageVisibilityModal
        groupID={groupID}
        isOpen={modalState.isOpen}
        onClose={modalState.onClose}
      />
    </div>
  );
};
