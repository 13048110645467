import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { CallFormPermissionsModal } from './CallFormPermissionsModal';

export function useOpenCallFormPermissionsSettings(groupID: number | string) {
  const { addModal } = useModal();

  return useCallback(() => {
    addModal({
      children: <CallFormPermissionsModal groupID={groupID} />,
      preventClose: {
        defaultPreventState: false,
      },
      componentProps: {
        modal: { size: '6xl' },
      },
    });
  }, [addModal, groupID]);
}
