import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react';
import { formatNumber } from 'app/assets/js/tsutil';
import { lazyImport } from '@bq/components/lazy-import';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { PopoverButton } from 'BootQuery/Assets/components/PopoverTrigger';
import { Portal } from 'BootQuery/Assets/components/Portal';
import { ReactElement, Suspense } from 'react';

interface NumberDisplayProps {
  phoneNumber: string;
  user?: { ID: number; username: string };
  fontSize?: string;
}

const NumberDetails = lazyImport(
  () => import('./NumberPopoverContent'),
  'NumberDetails'
);

export const NumberDisplay = ({
  phoneNumber,
  user,
  fontSize,
}: NumberDisplayProps): ReactElement => (
  <Popover isLazy>
    <PopoverTrigger>
      <PopoverButton userSelect="text" fontSize={fontSize ?? 'sm'}>
        {user ? user.username : formatNumber(phoneNumber)}&nbsp;
      </PopoverButton>
    </PopoverTrigger>
    <Portal>
      <FixedPopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody textAlign="center">
          <Suspense fallback={<LoaderScale />}>
            <NumberDetails phoneNumber={phoneNumber} user={user} />
          </Suspense>
        </PopoverBody>
      </FixedPopoverContent>
    </Portal>
  </Popover>
);
