import { z } from 'zod';

export const locationSchema = z.object({
  type: z.enum(['unknown', 'inPerson', 'video', 'phone']),
  location: z.string().optional(),
});

export type ILocation = z.infer<typeof locationSchema>;

export const appointmentReminderSchema = z.object({
  ID: z.number(),
  timestamp: z.coerce.date(),
  done: z.boolean(),
});

export const attendeeSchema = z.object({
  type: z.enum(['user', 'person', 'company']),
  ID: z.number(),
  name: z.string(),
});

export const appointmentSchema = z.object({
  ID: z.number(),
  title: z.string().min(1),
  startAt: z.coerce.date(),
  endAt: z.coerce.date(),
  data: z.record(z.any()),
  location: locationSchema.nullable(),
  reminders: z.array(appointmentReminderSchema),
  participants: z.array(attendeeSchema),
});

export type IAppointment = z.infer<typeof appointmentSchema>;

export const locationFormSchema = z.object({
  type: z.enum(['unknown', 'inPerson', 'video', 'phone']),
  location: z.string().optional(),
});

export const reminderFormSchema = z.object({
  ID: z.union([z.string(), z.number()]),
  timestamp: z.date().optional().nullable(),
});

export type IReminderForm = z.infer<typeof reminderFormSchema>;

export const remindersCrud = z.object({
  add: z.array(reminderFormSchema),
  upd: z.record(reminderFormSchema.shape.ID, reminderFormSchema),
  del: z.array(reminderFormSchema.shape.ID),
  defaults: z.array(reminderFormSchema),
});

export const attendeeFormSchema = z.object({
  type: z.enum(['user', 'person', 'company']),
  ID: z.number(),
  name: z.string(),
});

export const appointmentFormSchema = z.object({
  title: z.string().min(1),
  startAt: z.date(),
  endAt: z.date(),
  data: z.record(z.any()),
  location: locationFormSchema.nullable(),
  reminders: remindersCrud,
  attendees: z.array(attendeeFormSchema),
});

export type IAppointmentForm = z.infer<typeof appointmentFormSchema>;
