import { Optional } from 'app/assets/js/tsutil';
import i18n from 'BootQuery/Assets/js/i18n';

import { SignatureIcon } from '../icons';
import { FieldType } from '../types';
import { newFieldName } from '../util';
import { checkboxField } from './Checkbox';
import { dateField } from './DateField';
import { dateTimeField } from './DateTimeField';
import { emailField } from './EmailField';
import { fileField } from './FileField';
import { freetextField } from './FreetextField';
import { listField } from './ListField';
import { noteField } from './NoteField';
import { numberField } from './NumberField';
import { phoneField } from './PhoneField';
import { productField } from './ProductField';
import { productItemField } from './ProductItemField';
import { productItemsField } from './ProductItemsField';
import { richTextField } from './RichtextField';
import { selectField } from './SelectField';
import { separatorField } from './SeparatorField';
import { tagField } from './TagField';
import { textField } from './TextField';
import { timeField } from './TimeField';

const makeField = (def: Optional<FieldType, 'defaultSettings'>): FieldType => ({
  defaultSettings: { name: newFieldName(), required: false },
  ...def,
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        getValue: ({ row }) => {
          if (row.data) {
            return (
              ((row.data as Record<string, string | number>) ?? {})[field.id] ??
              '-'
            );
          }

          return '-';
        },
      },
    ];
  },
});

export const getFieldTypes = (): FieldType[] => [
  textField(),
  numberField() as unknown as FieldType,
  emailField(),
  phoneField(),
  dateField(),
  timeField(),
  dateTimeField(),
  selectField() as unknown as FieldType,
  listField() as unknown as FieldType,
  freetextField(),
  tagField() as unknown as FieldType,
  richTextField() as unknown as FieldType,
  checkboxField(),
  makeField({
    type: 'signature',
    name: i18n.t('global:form_editor.field_signature'),
    icon: SignatureIcon,
  }),
  separatorField(),
  noteField() as unknown as FieldType,
  fileField() as unknown as FieldType,
  productField(),
  productItemField(),
  productItemsField(),
];
