import { AsyncSelect, MultiValue } from 'chakra-react-select';
import { useMemo } from 'react';
import { useTicketingSettingsContext } from '../../Settings/TicketSettingsContext';
import { TicketOption } from './TicketOption';
import { TicketStateSelectOption } from './types';
import { loadOptions } from './utils';

interface Props {
  onChange: (newValue: MultiValue<TicketStateSelectOption>) => void;
  value: number[] | null;
}
export const TicketStateMultiSelect = ({ onChange, value }: Props) => {
  const { states } = useTicketingSettingsContext();

  const valToTicketStateOption = useMemo(
    () => value?.reduce((all, current) => {
      const isNumber = typeof current === 'number';
      if (isNumber) {
        const findThing = states.find((item) => item.ID === current);
        if (findThing !== undefined && typeof findThing.ID !== 'string') {
          return [
            ...all,
            {
              color: findThing.color,
              label: findThing.name,
              value: findThing.ID,
            },
          ];
        }
      }

      return all;
    }, [] as TicketStateSelectOption[]) ?? null,
    [states, value]
  );

  return (
    <AsyncSelect<TicketStateSelectOption, true>
      chakraStyles={{
        multiValue: (provided, state) => ({
          ...provided,
          background: state.data.color,
          color: 'white',
          fontSize: '16px',
        }),
      }}
      isMulti
      useBasicStyles
      value={valToTicketStateOption}
      components={{ Option: TicketOption }}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        onChange(newVal);
      }}
      loadOptions={(search) => {
        return loadOptions(search);
      }}
      selectedOptionColorScheme="brand"
    />
  );
};
