import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
} from 'BootQuery/Assets/js/globalTypes';
import { User } from '../../TicketingForm/Agent/types';

export const getTicketAgents = async (groupID: number, params: GetListingParams = {}) => {
  const { data } = await Api.get<User[]>(`/api/ticketing/groups/${groupID}/users`, {
    params,
  });

  return data;
};
