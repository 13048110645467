import { useQuery } from '@tanstack/react-query';

import { getCaseTypes } from './api/case-types';
import { CaseTypeFull, CaseTypesFormBase } from './types';
import { stateToSelectOption } from './util';

export function useSettingsCaseTypes() {
  return useQuery({
    queryFn: loadCaseTypes,
    queryKey: ['Sales.Settings.caseTypes'],
    suspense: true,
  });
}

async function loadCaseTypes(): Promise<CaseTypesFormBase[]> {
  const types = await getCaseTypes<CaseTypeFull>({ fields: ['$full'] });

  return types.map((type) => ({
    ...type,
    dataset: type.dataset
      ? { value: type.dataset.ID, label: type.dataset.name }
      : null,
    states: type.states.map(({ state }) => stateToSelectOption(state)),
  }));
}
