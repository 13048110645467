import { ReactElement, useCallback } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { CellProps } from '@bq/components/Table';

import { DeadlineIndicator } from '../../DeadlineIndicator';
import { useTicketExpanded } from '../../Dependencies';
import { TicketRow } from '../../types';

export const ExpandCell = ({
  row: {
    ID,
    hasDependencies,
    level,
    hasNextDep,
    hasChildren,
    resolveDeadlineExceededAt,
    respondDeadlineExceededAt,
  },
  tableSize,
}: CellProps<TicketRow>) => {
  return (
    <HStack spacing="3" alignContent="center">
      <ExpandIndicator
        ID={ID}
        hasDependencies={hasDependencies}
        level={level}
        hasNextDep={hasNextDep}
        hasChildren={hasChildren}
        tableSize={String(tableSize)}
      />
      &nbsp;
      <div>{ID}</div>
      <DeadlineIndicator
        resolveDeadlineExceededAt={resolveDeadlineExceededAt}
        respondDeadlineExceededAt={respondDeadlineExceededAt}
      />
    </HStack>
  );
};

type ExpandIndicatorProps = Pick<
  TicketRow,
  'ID' | 'hasDependencies' | 'level' | 'hasNextDep' | 'hasChildren'
> & { tableSize: string };

export const ExpandIndicator = ({
  ID,
  hasDependencies,
  level,
  hasNextDep,
  hasChildren,
  tableSize,
}: ExpandIndicatorProps) => {
  const [expanded, setExpanded] = useTicketExpanded(ID);
  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, [setExpanded]);

  if (level !== undefined) {
    return (
      <Box pl={`${10 + level * 16}px`}>
        <NestIndicator
          hasNext={hasNextDep ?? false}
          hasChildren={hasChildren ?? false}
          height={sizeHeights[tableSize] ?? 52}
        />
      </Box>
    );
  }

  if (!hasDependencies) {
    return <></>;
  }

  return (
    <IconButton
      onClick={toggleExpanded}
      size="xs"
      label="Show dependencies"
      zIndex={3}
      icon={expanded ? <FaChevronDown /> : <FaChevronRight />}
    />
  );
};

interface NestIndicatorProps {
  hasNext: boolean;
  hasChildren: boolean;
  height: string | number;
}

export const NestIndicator = ({
  hasNext,
  hasChildren,
  height,
}: NestIndicatorProps): ReactElement => (
  <svg
    style={{ height, width: '32px', opacity: 0.6 }}
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    viewBox="0 0 100 100"
  >
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M0,50 h100"
      vectorEffect="non-scaling-stroke"
      shapeRendering="crispEdges"
    />
    <path
      stroke="currentColor"
      strokeWidth="4"
      d={`M0,0 v${hasNext && !hasChildren ? 100 : 50}`}
      vectorEffect="non-scaling-stroke"
      shapeRendering="crispEdges"
    />
    {hasChildren && (
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M52,50 v50"
        vectorEffect="non-scaling-stroke"
        shapeRendering="crispEdges"
      />
    )}
  </svg>
);

const sizeHeights: Record<string, number> = {
  sm: 35,
  md: 52,
  lg: 64,
};
