import { Api } from 'BootQuery/Assets/js/api';
import {
  getCompanies,
  getPeople,
} from 'app/Modules/Phonebook/Assets/components/api';
import {
  ApiContactCategory,
  CategoryContactValue,
  ContactItem,
  ContactItemContact,
} from './types';

export async function getCategories(): Promise<ApiContactCategory[]> {
  const { data } = await Api.get<ApiContactCategory[]>(
    '/api/hops/contactCategories'
  );

  return data;
}

export async function loadFullContacts(
  contactValues: (CategoryContactValue | null)[]
): Promise<(ContactItem | null)[]> {
  const personIds = contactValues
    .filter((contact) => contact !== null && contact.type === 'person')
    .map(getContactID);
  const companyIds = contactValues
    .filter((contact) => contact !== null && contact.type === 'company')
    .map(getContactID);

  const [people, companies] = await Promise.all([
    loadPersonContacts(personIds),
    loadCompanyContacts(companyIds),
  ]);

  const contactMap = {
    person: makeContactMap(people),
    company: makeContactMap(companies),
  };

  return contactValues.map((contact) => {
    if (!contact) {
      return null;
    }

    return {
      color: contact.color,
      contact: contactMap[contact.type][contact.ID] ?? null,
    };
  });
}

function getContactID(contact: CategoryContactValue | null): number {
  if (!contact) {
    throw new Error('Unexpected null contact');
  }

  return contact.ID;
}

function makeContactMap(
  contacts: ContactItemContact[]
): Record<number, ContactItemContact> {
  return contacts.reduce(
    (prev, contact) => ({
      ...prev,
      [contact.ID]: contact,
    }),
    {}
  );
}

async function loadPersonContacts(
  personIds: number[]
): Promise<ContactItemContact[]> {
  if (personIds.length === 0) {
    return [];
  }

  const { data: people } = await getPeople({
    limit: 'null',
    filters: {
      'ID:inArray': personIds,
    },
    withCount: false,
    fields: [
      'ID',
      'firstName',
      'lastName',
      'phoneNumbers.phoneNumber.phoneNumberE164',
    ],
  });

  return people
    .filter((person) => person.phoneNumbers.length > 0)
    .map((person) => ({
      type: 'person',
      name: `${person.firstName ?? ''} ${person.lastName ?? ''}`.trim(),
      ID: person.ID,
      phoneNumber: person.phoneNumbers[0].phoneNumber.phoneNumberE164,
    }));
}

async function loadCompanyContacts(
  companyIds: number[]
): Promise<ContactItemContact[]> {
  if (companyIds.length === 0) {
    return [];
  }

  const { data: companies } = await getCompanies({
    limit: 'null',
    filters: {
      'ID:inArray': companyIds,
    },
    withCount: false,
    fields: ['ID', 'name', 'phoneNumbers.phoneNumber.phoneNumberE164'],
  });

  return companies
    .filter((company) => company.phoneNumbers.length > 0)
    .map((company) => ({
      type: 'company',
      name: company.name,
      ID: company.ID,
      phoneNumber: company.phoneNumbers[0].phoneNumber.phoneNumberE164,
    }));
}
