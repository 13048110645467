import { ForwardedRef, forwardRef, ReactNode } from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from './ErrorFallback';

interface Props extends BoxProps {
  children?: ReactNode;
}

const PageWrapper = forwardRef(
  ({ children, p = 3, ...props }: Props, ref: ForwardedRef<HTMLDivElement>) => (
    <Box
      minHeight="fullNoNavbar"
      bg={useColorModeValue('white', 'brand.backgroundDark')}
      p={p}
      ref={ref}
      {...props}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {children}
      </ErrorBoundary>
    </Box>
  )
);
PageWrapper.displayName = 'PageWrapper';

export { PageWrapper };
