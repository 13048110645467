import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { Button } from '@chakra-ui/react';
import {
  getBorderColor,
  getTextColor,
} from '../../HopsOperator/HopsContacts/util';

interface Props {
  id: string;
  name: string;
  color: string | null;
  setSelectedCategoryId: Dispatch<SetStateAction<string | null>>;
  isSelected?: boolean;
}

export const CategoryItem = ({
  id,
  name,
  color,
  setSelectedCategoryId,
  isSelected,
}: Props): ReactElement => {
  const ensuredColor = color ?? '#edf2f7';
  const borderHighlightColor = useMemo(
    () => getBorderColor(ensuredColor),
    [ensuredColor]
  );
  const borderColor = isSelected ? borderHighlightColor : ensuredColor;
  const textColor = getTextColor(ensuredColor);

  const select = useCallback(() => {
    setSelectedCategoryId(id);
  }, [id, setSelectedCategoryId]);

  return (
    <Button
      variant="unstyled"
      px="3"
      py="2"
      background={ensuredColor}
      onClick={select}
      borderStyle="solid"
      borderColor={borderColor}
      borderWidth="medium"
      color={textColor}
      height="auto"
      fontWeight={isSelected ? 'bold' : 'normal'}
    >
      {name}
    </Button>
  );
};
