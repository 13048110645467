import { memoize } from 'lodash-es';
import { stdnum, Validator } from 'stdnum';
import i18n from 'BootQuery/Assets/js/i18n';
import { NationalNumberType } from './types';

export const getNationalNumberTypesMemo = memoize(getNationalNumberTypes);

export function getNationalNumberTypes(): NationalNumberType[] {
  const baseTypes: NationalNumberType[] = [
    { value: '', label: i18n.t('Phonebook:national_number_type_other') },
  ];

  return Object.entries(stdnum).reduce(
    (prev, [country, types]) => [
      ...prev,
      ...Object.entries(types).map(([key, validator]) => ({
        value: `${country}-${key}`,
        label: numberTypeTitle(country, key, validator),
      })),
    ],
    baseTypes
  );
}

export interface ValidatorInfo {
  validatorKey: string;
  countryISO: string;
  validator: Validator;
}

export function getNumberTypeById(typeId: string): ValidatorInfo | null {
  if (!typeId) {
    return null;
  }

  const [countryISO, validatorKey] = typeId.split('-');
  const validator = stdnum[countryISO][validatorKey];

  return validator ? { validator, countryISO, validatorKey } : null;
}

export function getNumberTypeTitleById(typeId: string): string | null {
  const validator = getNumberTypeById(typeId);

  if (!validator) {
    return null;
  }

  return numberTypeTitle(
    validator.countryISO,
    validator.validatorKey,
    validator.validator
  );
}

function numberTypeTitle(
  country: string,
  validatorKey: string,
  { abbreviation, localName }: Validator
): string {
  const validatorName = abbreviation ?? localName ?? validatorKey;

  return `${country} ${validatorName}`;
}
