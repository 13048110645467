import { CallCurrentState } from '../../store/calls-zustand';
import { CallAndConnection } from './connected-calls';
import { softphoneState } from './store/softphone';
import { CallDisplayProps } from './types';

export function getCallLeg(call: CallAndConnection): 'a' | 'b' {
  const { connection } = call;

  return connection === 'destination' || connection === 'ringing' ? 'b' : 'a';
}

export function getIsRecording(call: CallAndConnection): boolean {
  const ourLeg = getCallLeg(call);
  const ourSide = ourLeg === 'a' ? 'caller' : 'callee';

  return call.call.recordings.some(
    (rec) => rec.side === ourSide && rec.isRecording
  );
}

export function canHangup(call: CallDisplayProps): boolean {
  if (call.type === 'pbx') {
    return call.call.call.currentState?.callState !== 'terminated';
  }

  return call.call.state !== 'ended';
}

export function canAccept(call: CallDisplayProps): boolean {
  if (call.type === 'pbx') {
    return false;
  }

  return call.call.state === 'ringing';
}

export function canConfirmTransfer(call: CallDisplayProps): boolean {
  if (call.type === 'pbx') {
    return false;
  }

  const callWaitingForTransfer =
    softphoneState.getState().sessions.find((other) => {
      return (
        other.waitingForAttendedTransfer &&
        other.sessionId !== call.call.sessionId
      );
    }) ?? null;

  return call.call.state === 'inCall' && callWaitingForTransfer !== null;
}

export function pbxCallBySipCallId(
  pbxCalls: CallCurrentState[],
  sipCallId: string
) {
  const pbxCall = pbxCalls.find((pbxCall) => {
    if (pbxCall.sourcePhonePoint.phonePoint.sipCallId === sipCallId) {
      return true;
    }

    return pbxCall.currentCallees.some((callee) => {
      const calleeSipCallId = callee.callPointPhone.phonePoint.sipCallId;

      return calleeSipCallId === sipCallId;
    });
  });

  return pbxCall ?? null;
}
