import { FilterTypes } from '@bq/components/FilterBar';
import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import { makeContactFilter } from '@bq/components/FilterBar/filters/ContactFilter';
import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';
import i18n from 'BootQuery/Assets/js/i18n';
import { makeCountryFilter } from 'app/Modules/Telephony/Assets/components/CallList/CountryFilter';

export const warrantyMonthsFilter = makeNumberFilter({
  name: () => i18n.t('Products:warranty_months'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.warrantyMonths:gte': value.from,
          'good.warrantyMonths:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.warrantyMonths', operator ?? 'eq', value];
    }

    return null;
  },
});

export const producerFilter = makeContactFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return {
        'good.producerCompany.name:contains:ci': value,
      };
    }
    if (typeof value === 'object') {
      const { ID } = value;

      return {
        'good.producerCompany.ID': ID,
      };
    }

    return null;
  },
  name: () => i18n.t('Products:producer'),
  operators: [],
  allowedContactTypes: {
    company: true,
    companyDepartment: false,
    companyLocation: false,
    person: false,
    user: false,
  },
});

export const countryOfOriginFilter = makeCountryFilter({
  name: () => i18n.t('Products:country_of_origin'),
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    return typeof value === 'string'
      ? ['good.countryOfOrigin.isoCode', 'eq', value]
      : ['good.countryOfOrigin.isoCode', 'eq', value.iso];
  },
  operators: [],
});

/** Barcode missing */

export const isComissionFilter = makeBooleanFilter({
  name: () => i18n.t('Products:is_comission'),
  toFilter: ({ value }) => {
    return { 'good.isComission': value };
  },
});
export const productFilters: FilterTypes = {
  warrantyMonthsFilter,
  producerFilter,
  countryOfOriginFilter,
  isComissionFilter,
};
