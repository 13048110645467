import { ReactElement, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileAlt } from 'react-icons/fa';

import { useModal } from '@bq/components/UseModal';

import { WorkFormEditModal } from './WorkFormEditModal';

export const WorkFormEdit = (): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const { addModal } = useModal();
  const editForm = useCallback(() => {
    addModal({
      children: <WorkFormEditModal />,
      componentProps: {
        modal: {
          size: '6xl',
          scrollBehavior: 'inside',
          closeOnOverlayClick: false,
        },
      },
    });
  }, [addModal]);

  return (
    <Button onClick={editForm}>
      <FaFileAlt />
      &nbsp;
      {t('global:edit_form')}
    </Button>
  );
};
