import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { SalesCase } from '../../schemas';

export const getManyCasesSelect = async <T = Pick<SalesCase, 'ID' | 'name'>>(
  params?: GetListingParams
) => {
  const { data } = await Api.get<ListingResponse<T>>('/api/sales/cases', {
    params,
  });

  return data;
};
