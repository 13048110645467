import Parchment from 'parchment';

function constructButtonEl() {
  return document.createRange().createContextualFragment(`
        <button type="button" class="btn btn-outline-primary btn-sm" style="line-height: 1; white-space: normal;">
            <span class="fa fa-ellipsis-h"></span>
        </button>
    `.trim());
}

class QuotedContent extends Parchment.Embed {
  static create(value) {
    const { type, content } = value;

    const node = super.create(value);
    node.dataset.contentType = type;

    const contentNode = document.createElement('blockquote');
    contentNode.classList.add('clickvox-quote-wrapper');
    contentNode.style.whiteSpace = 'normal';
    contentNode.setAttribute('contenteditable', 'false');
    if (type === 'html') {
      contentNode.innerHTML = content;
    } else {
      contentNode.innerText = content;
    }

    const btnEl = constructButtonEl();
    btnEl.firstElementChild.addEventListener('click', (ev) => {
      ev.preventDefault();
      $(contentNode).collapse('toggle');
    });
    node.appendChild(btnEl);
    node.appendChild(contentNode);
    contentNode.classList.add('collapse');
    $(contentNode).collapse('hide');
    return node;
  }

  static value(node) {
    const type = node.dataset.contentType;
    const contentNode = node.querySelector('blockquote');
    const content = type === 'html' ? contentNode.innerHTML : contentNode.innerText;
    return { type, content };
  }

  length() {
    return 1;
  }
}

QuotedContent.blotName = 'quoted-content';
QuotedContent.tagName = 'div';
QuotedContent.className = 'clickvox-quote-editor-outer';

export default QuotedContent;
