import { Box, HStack } from '@chakra-ui/react';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import {
  selectItemToValue,
  SelectOption,
  valueToSelectItem,
  selectStyles,
} from 'BootQuery/Assets/components/Select';
import { Api } from 'BootQuery/Assets/js/api';
import { ReactElement, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsyncSelect } from 'chakra-react-select';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';
import { CustomEventFormData } from './types';

interface Props {
  idx: number;
  onRemove: (idx: number) => void;
}

export const LinkedTicketRow = ({ idx, onRemove }: Props): ReactElement => {
  const { t } = useTranslation('Events');

  const { control } = useFormContext<CustomEventFormData>();
  const remove = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  return (
    <HStack>
      <Box flex="1 1 auto">
        <Controller
          control={control}
          name={`tickets.${idx}.ticket`}
          render={({ field }) => (
            <AsyncSelect<SelectOption, false>
              cacheOptions
              defaultOptions
              menuPortalTarget={document.body}
              chakraStyles={selectStyles}
              loadOptions={searchTickets}
              value={valueToSelectItem(field.value)}
              onChange={(val: SelectOption | null) => {
                field.onChange(selectItemToValue(val));
              }}
              formatOptionLabel={formatLabel}
              placeholder={t('Events:ticket')}
              styles={modalSelect}
              selectedOptionColorScheme="brand"
            />
          )}
        />
      </Box>
      <DeleteButton size="sm" onClick={remove} />
    </HStack>
  );
};

interface TicketsResponse {
  data: { ID: number; title: string }[];
  count: number;
}

const formatLabel = (opt: SelectOption) => `#${opt.value} - ${opt.label}`;

async function searchTickets(search: string): Promise<SelectOption[]> {
  const { data } = await Api.get<TicketsResponse>('/api/ticketing/tickets', {
    params: {
      fields: ['ID', 'title'],
      filters: search ? { $search: search } : undefined,
    },
  });

  return data.data.map((ticket) => ({
    value: ticket.ID.toString(),
    label: ticket.title,
  }));
}
