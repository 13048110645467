import { lazyImport } from '@bq/components/lazy-import';
import { ReportsProvider } from 'app/Modules/Reporting/Assets/js/types';
import i18n from 'BootQuery/Assets/js/i18n';

const TicketingReportsEmbed = lazyImport(
  () => import('./TicketingReportsEmbed'),
  'TicketingReportsEmbed'
);

export const ProvidedReports: ReportsProvider = {
  ticketReports: {
    categoryTitle: () => i18n.t('Ticketing:ticketing_title'),
    title: () => i18n.t('Ticketing:tickets'),
    component: TicketingReportsEmbed,
  },
};
