import { purgeReact, renderReact } from './render-react';
import { routeEvents } from './route-events';
import { PageContent } from '../components/PageContent';

export function activateReact(target) {
  const $container = target.findElement('#react-root-container');
  if ($container.length) {
    renderReactRoute($container, PageContent);
  }
}

export function renderReactRoute($container, component) {
  const container = $container[0];
  if (container.dataset.reactRoot) {
    console.log('EMIT!', `${window.location.pathname}${window.location.search}`);
    routeEvents.emit('routeChange', `${window.location.pathname}${window.location.search}`);
    return; // Don't double-render
  }
  setTimeout(() => {
    renderReact(container, component);
  }, 0);
}

export function renderReactRouteRoute() {
  let target = $('#react-root-container');
  if (target.length === 0) {
    const renderTo = $(window.targetElement);
    target = $('<div/>', { id: 'react-root-container' });
    purgeReact(renderTo[0]);

    // Purge vue
    renderTo.find('[data-vue-boundary]').each((_i, el) => {
      const vueInstance = el.__vue__; // eslint-disable-line no-underscore-dangle
      if (vueInstance) {
        vueInstance.$destroy();
      }
    });

    $(window.targetElement).html(target);
  }
  activateReact(target);
  $(document).trigger('activateElements', [$(window.targetElement), window.Bootstrap]);
}
