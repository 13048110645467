import { ReactElement, useMemo } from 'react';
import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { FilterValue } from '@bq/components/FilterBar';
import { useListingSettings } from '@bq/components/ListingSettings';
import { TableMenu } from '@bq/components/TableMenu';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { Table } from 'BootQuery/Assets/components/Table';
import { Api } from 'BootQuery/Assets/js/api';

import { getColumns } from './columns';
import { ExportLink } from './ExportLink';
import { genExportLink } from './gen-export-link';
import { reportParams } from './report-params';
import { useReportContext } from './ReportContext';
import { AgentReportRow, ReportSettings } from './types';

type LoadResult =
  | { error: null; data: AgentReportRow[] }
  | { error: 'missing_params'; data: null };

export const AgentReport = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { settings, filterTypes, filterVal } = useReportContext();

  const columns = useMemo(getColumns, [getColumns]);

  const listingSettings = useListingSettings<AgentReportRow>({
    listingName: 'Ticketing.Reports',
    viewName: 'agents',
    filterTypes,
    columns,
  });

  const {
    columnsToShow,
    density,
    allColumns,
    visibleColumns,
    setVisibleColumns,
    setDensity,
  } = listingSettings;

  const { data, status } = useQuery({
    queryKey: ['ticketingAgentsReport', settings, filterVal],
    queryFn: () => loadData(settings, filterVal),
  });

  const exportLink = genExportLink(
    '/api/ticketing/reports/agents/export',
    settings,
    columnsToShow
  );

  if (!data) {
    <LoadingPage />;
  }

  return (
    <VStack>
      <HStack w="full" justifyContent="space-between" px={5} py={3}>
        <Heading
          as="h2"
          display="flex"
          alignSelf="self-start"
          fontSize="lg"
          mb={0}
          textAlign="left"
        >
          {t('Ticketing:reports.agents_report_title')}
        </Heading>

        <TableMenu
          visibleColumns={{
            columns: allColumns,
            value: visibleColumns,
            onChange: setVisibleColumns,
          }}
          density={{ value: density, onChange: setDensity }}
        >
          {exportLink && <ExportLink link={exportLink} />}
        </TableMenu>
      </HStack>
      {data?.error ? (
        <>{t('global:error')}</>
      ) : (
        <Table<AgentReportRow>
          columns={columnsToShow}
          rows={data?.data ?? []}
          isLoading={status === 'loading'}
          size={density}
        />
      )}
    </VStack>
  );
};

async function loadData(
  settings: ReportSettings,
  filters: FilterValue[] = []
): Promise<LoadResult> {
  if (!settings.datePeriod) {
    return { error: 'missing_params', data: null };
  }

  const { data } = await Api.get<AgentReportRow[]>(
    '/api/ticketing/reports/agents',
    {
      params: await reportParams(settings, filters),
    }
  );

  return { data, error: null };
}
