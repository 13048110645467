import { Api } from 'BootQuery/Assets/js/api';

import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';
import { TicketWithDependencies } from './types';

export async function getDependencies(
  ticketID: number,
  params?: GetListingParams
): Promise<TicketWithDependencies[]> {
  const { data } = await Api.get(
    `/api/ticketing/tickets/${ticketID}/dependencies`,
    { params }
  );

  return data;
}
