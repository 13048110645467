import {
  Box,
  Divider,
  Grid,
  Heading,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PartialItem } from '../../types';
import { useProductFormWrapper } from '../ProductFormWrapper';
import { CodeField } from './ItemBaseFields/CodeField';
import { DescriptionField } from './ItemBaseFields/DescriptionField';
import { GroupField } from './ItemBaseFields/GroupField';
import { LongNameField } from './ItemBaseFields/LongNameField';
import { NameField } from './ItemBaseFields/NameField';
import { NoteField } from './ItemBaseFields/NoteField';
import { PackagingUnitField } from './ItemBaseFields/PackagingUnitField';
import { ProgramField } from './ItemBaseFields/ProgramField';
import { SubgroupField } from './ItemBaseFields/SubgroupField';
import { SupplierField } from './ItemBaseFields/SupplierField';
import { TypeField } from './ItemBaseFields/TypeField';
import { UnitField } from './ItemBaseFields/UnitField';
import { DimensionInput } from './LogisticalData/DimensionField';
import { VolumeField } from './LogisticalData/VolumeField';
import { WeightField } from './LogisticalData/WeightField';
import { WeightUnitInput } from './LogisticalData/WeightUnitField';
import { ActionPriceFields } from './Price/ActionPriceFields';
import { PriceFields } from './Price/PriceFields';
import { TaxRateField } from './Price/TaxRateField';
import { BarcodeFields } from './Product/BarcodeFields';
import { CountryOfOriginField } from './Product/CountryOfOriginField';
import { IsCommisionField } from './Product/IsCommisionField';
import { ProducerField } from './Product/ProducerField';
import { ModelNumberField } from './Product/SerialNumberField';
import { WarrantyMonthsField } from './Product/WarrantyMonthsField';
import { ServiceSupplier } from './Service/ServiceSupplier';

export const ProductFormFields = () => {
  const { watch } = useFormContext<PartialItem>();
  const { t } = useTranslation('Products');
  const type = watch('productType');

  const { mode } = useProductFormWrapper();

  return (
    <VStack spacing={4} w="full">
      {mode === 'create' && <TypeField />}

      <Grid templateColumns="1fr 2fr" w="full" gridGap={4}>
        <NameField />
        <LongNameField />
      </Grid>

      <DescriptionField />

      <SimpleGrid columns={[1, 1, 3, 3]} w="full" gridGap={4}>
        <ProgramField formControlProps={{ flex: '1 1 auto' }} />
        <GroupField formControlProps={{ flex: '1 1 auto' }} />
        <SubgroupField formControlProps={{ flex: '1 1 auto' }} />
      </SimpleGrid>

      <UnitField />

      {type === 'good' && <WarrantyMonthsField />}
      {type === 'good' && (
        <>
          <PackagingUnitField />
        </>
      )}
      {type === 'good' && (
        <SimpleGrid columns={[1, 1, 2, 2]} w="full" gridGap={4}>
          <ProducerField />
          <SupplierField />
        </SimpleGrid>
      )}
      <ServiceSupplier type={type} />
      <CodeField />
      {type === 'good' && (
        <>
          <CountryOfOriginField />
          <ModelNumberField />
          <BarcodeFields />
          <IsCommisionField />
        </>
      )}

      <NoteField />

      <>
        <Box w="full">
          <Divider />
        </Box>
        <Heading w="full" size="md">
          {t('Products:prices')}
        </Heading>
        <TaxRateField />
        <PriceFields />
        <ActionPriceFields />
      </>

      {type === 'good' && (
        <>
          <Box w="full">
            <Divider />
          </Box>

          <Heading w="full" size="md">
            {t('Products:logistical_data')}
          </Heading>
          <Grid templateColumns="2fr 1fr" w="full" gridGap={4}>
            <DimensionInput />
            <VolumeField />
          </Grid>
          <Grid templateColumns={['1fr', 'repeat(2, 1fr) 6rem']} w="full">
            <WeightField weightType="good.netWeightKg" />
            <WeightField weightType="good.grossWeightKg" />
            <WeightUnitInput />
          </Grid>
        </>
      )}
    </VStack>
  );
};
