import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';
import { MailEvent } from './types';

export const MailEventColumn: EventCellComponent<MailEvent> = ({
  row: { data: message },
}) => {
  const { t } = useTranslation('Mails');

  return (
    <>
      <FaArrowRight title={t('Mails:outgoing')} style={{ display: 'inline' }} />
      &nbsp;
      {message.subject}
    </>
  );
};
