module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"Mails.emailContact"),((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"Mails.emailContact","hash":{"address":(depth0 != null ? lookupProperty(depth0,"address") : depth0)},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span>\n        <div class=\"popover-content-element\" hidden>\n            <div class='text-left'>\n                <span class=\"fa fa-envelope\"></span> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n                <br><br>\n                <a class=\"btn btn-secondary btn-block noparse\" href=\"/phonebook/saveEmailToPerson/?email="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\"\n                    data-modal-link=\"/phonebook/saveEmailToPerson/?email="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n                    data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\">\n                    <span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Phonebook:button.save_email_to_person",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":58},"end":{"line":12,"column":105}}}))
    + "\n		        </a>\n                <a class=\"btn btn-secondary btn-block mt-1 noparse\" href=\"/phonebook/saveEmailToCompany/?email="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\"\n                    data-modal-link=\"/phonebook/saveEmailToCompany/?email="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n                    data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\">\n                    <span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Phonebook:button.save_email_to_company",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":106}}}))
    + "\n		        </a>\n            </div>\n        </div>\n\n        <a href=\"mailto:"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\"\n            class=\"noparse\"\n            data-toggle=\"popover\"\n            data-placement=\"right\"\n            data-container=\"body\"\n            data-trigger=\"click\"\n            data-html=\"true\"\n            data-popover-content-element=\"true\"\n            data-original-title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\"\n            tabindex=\"1\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n        </a>\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"contacts") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})