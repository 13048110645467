import { ReactElement, useCallback } from 'react';
import { FaExchangeAlt, FaHandsHelping, FaPhone } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@bq/components/IconButton';
import { ReadIcon } from '../../icons';
import { useDialerContext } from './DialerContext';
import { useCurrentCall } from './hooks';

export const SoftphoneAction = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { selectedAction, input, submitInput, inputMode } = useDialerContext();
  const currentCall = useCurrentCall();
  const emptyInput = input.length === 0;

  const handleClick = useCallback(() => {
    submitInput();
  }, [submitInput]);

  if (inputMode === 'dtmf') {
    return <ReadIcon />;
  }

  if (selectedAction === 'attended' && currentCall) {
    return (
      <IconButton
        colorScheme="blue"
        variant="solid"
        size="md"
        label={t('Telephony:attended_transfer')}
        onClick={handleClick}
        isDisabled={emptyInput}
        icon={<FaHandsHelping />}
      />
    );
  }
  if (selectedAction === 'blind' && currentCall) {
    return (
      <IconButton
        colorScheme="blue"
        variant="solid"
        size="md"
        label={t('Telephony:blind_transfer')}
        onClick={handleClick}
        isDisabled={emptyInput}
        icon={<FaExchangeAlt />}
      />
    );
  }

  return (
    <IconButton
      colorScheme="green"
      variant="solid"
      size="md"
      label={t('Telephony:dial')}
      onClick={handleClick}
      isDisabled={emptyInput}
      icon={<FaPhone />}
    />
  );
};
