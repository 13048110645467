import { useMemo } from 'react';

import { FilterTypes } from '../FilterBar';
import { customFormFilters } from '../FormEditor/custom-form-filters';
import { getFieldTypes } from '../FormEditor/field-types';
import { FieldValue } from '../FormEditor/types';

export function getFilterTypes(
  filterTypes: FilterTypes,
  customFields: FieldValue[] = []
): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...filterTypes,
    ...customFilters,
  };
}

export function useFilterTypes(
  filterTypes: FilterTypes,
  customFields: FieldValue[] = []
): FilterTypes {
  return useMemo(
    () => getFilterTypes(filterTypes, customFields),
    [customFields, filterTypes]
  );
}

export const sortWithUndefined = (a?: number, b?: number) => {
  /** If A has no order and B has, B is higher on order list */
  if (!a && b) {
    return -1;
  }
  /** If B has no order and A has, A is higher on order list */
  if (a && !b) {
    return 1;
  }
  /** If both have order see which is bigger */
  if (a && b) {
    return a - b;
  }

  /** Default return, will probably never trigger */
  return 0;
};
