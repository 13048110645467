import { ChangeEvent, ReactElement, useState } from 'react';
import {
  VStack,
  Divider,
  Button,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTags } from 'react-icons/fa';
import { Portal } from 'BootQuery/Assets/components/Portal';
import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { TagFieldSettings } from './types';
import { SettingsModal } from './SettingsModal';

type Props = EditorFieldComponentProps<TagFieldSettings>;

export const TagFieldSettingsComponent = (props: Props): ReactElement => {
  const [tagEditOpen, setTagEditOpen] = useState<boolean>(false);
  const { setSetting, onChange } = useFieldSettings(props);

  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input
          placeholder="Name"
          value={props.content.settings.name}
          onChange={setName}
          size="sm"
        />
      </FormControl>

      <Divider />

      <Button
        display="flex"
        alignItems="center"
        colorScheme="blue"
        w="full"
        onClick={() => setTagEditOpen(true)}
      >
        <FaTags />
        &nbsp;&nbsp; {t('global:form_editor.edit_tags')}
      </Button>
      <Portal>
        <SettingsModal
          isOpen={tagEditOpen}
          onClose={() => setTagEditOpen(false)}
          value={props.content.settings}
          onChange={(settings) => {
            onChange({ ...props.content, settings });
          }}
        />
      </Portal>
    </VStack>
  );
};
