module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<li>\n			<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"pdf_url") : stack1), depth0))
    + "\" class=\"noparse\" target=\"_blank\">\n				<span class=\"fa fa-file-pdf\" style=\"color: #F50000;\"></span> "
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"report.export_pdf",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":65},"end":{"line":9,"column":92}}}))
    + "\n			</a>\n		</li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<li>\n			<a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"print_url") : stack1), depth0))
    + "\" class=\"print-btn\">\n				<span class=\"fa fa-file-printer\"></span> "
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"report.print",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":45},"end":{"line":17,"column":67}}}))
    + "\n			</a>\n		</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"btn-group dropdown\">\n	<button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\">\n		<span class=\"fa fa-file-alt\"></span> "
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"report.report",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":62}}}))
    + " <span class=\"caret\"></span>\n	</button>\n	<ul class=\"pull-right dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"pdf_url")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":12,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"print_url")),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":20,"column":9}}})) != null ? stack1 : "")
    + "	</ul>\n</div>\n";
},"useData":true})