import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';

import {
  Event,
  EventMenuItem,
  EventMenus as IEventMenusProvider,
} from './types';

export const EventMenusContext = createContext<IEventMenusProvider>({});

export interface EventMenusProviderProps {
  menus: IEventMenusProvider;
}

type Props = PropsWithChildren<EventMenusProviderProps>;

export const EventMenusProvider = ({
  children,
  menus,
}: Props): ReactElement => {
  return (
    <EventMenusContext.Provider value={menus}>
      {children}
    </EventMenusContext.Provider>
  );
};

export const useEventMenus = <E extends Event = Event>(
  eventType: string
): EventMenuItem<E>[] => {
  const items = useContext(EventMenusContext)[eventType];

  return useMemo(() => {
    if (typeof items === 'function') {
      return items();
    }

    return items ?? [];
  }, [items]);
};
