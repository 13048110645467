import { memo, MouseEventHandler, ReactElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { CancelButton } from '@bq/components/CancelButton';
import { FormState } from '@bq/components/form-state';
import { SaveButton } from '@bq/components/SaveButton';

import { DuplicatePersonIndicator } from './DuplicatePersonIndicator';

interface BaseProps {
  onSubmit?: MouseEventHandler;
  state?: FormState;
  form?: string;
  mode: 'edit' | 'create';
}

interface CancelButtonProps {
  onCancel: () => void;
}

interface CancelLinkProps {
  cancelLink: string;
}

type CancelProps = CancelButtonProps | CancelLinkProps;
type Props = BaseProps & CancelProps;

export const PersonFormActions = memo(
  ({
    onSubmit,
    form,
    state = null,
    mode,
    ...cancelProps
  }: Props): ReactElement => {
    const { t } = useTranslation();

    return (
      <HStack justifyContent="flex-end">
        {state === 'error' && (
          <Text as="span" color="red.500">
            {t('global:save_error')}
          </Text>
        )}
        {state === 'saved' && (
          <Text as="span" color="green.500">
            {t('global:save_success')}
          </Text>
        )}

        {mode === 'create' && <DuplicatePersonIndicator />}

        <CancelAction {...cancelProps} />
        <SaveButton
          type="submit"
          form={form}
          isLoading={state === 'saving'}
          onClick={onSubmit}
        />
      </HStack>
    );
  }
);
PersonFormActions.displayName = 'PersonFormActions';

const CancelAction = memo((props: CancelProps): ReactElement => {
  if ('cancelLink' in props) {
    return <CancelButton as={RouterLink} to={props.cancelLink} />;
  }

  return <CancelButton onClick={props.onCancel} />;
});
CancelAction.displayName = 'CancelAction';
