import { ReactNode } from 'react';
import { DropdownCreateLabel } from './DropdownCreateLabel';
import { DropdownListItem, DropdownValue, SelectOption } from './types';

export function selectItemToValue(
  option: SelectOption | null
): DropdownValue | null {
  if (!option) {
    return null;
  }

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line no-underscore-dangle
  return option.__isNew__
    ? { $new: option.label }
    : { ID: parseInt(option.value, 10), name: option.label };
}

export function valueToSelectItem(
  val: DropdownValue | null
): SelectOption | null {
  if (!val) {
    return null;
  }

  if ('$new' in val) {
    return { label: val.$new, value: val.$new, __isNew__: true };
  }

  return { label: val.name, value: val.ID.toString() };
}

export function formatCreateLabel(label: string): ReactNode {
  return DropdownCreateLabel({ label });
}

export function itemToSelectOption({
  ID,
  name,
}: DropdownListItem): SelectOption {
  return {
    label: name,
    value: ID.toString(),
  };
}
