module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button type=\"button\" class=\"btn btn-link column-inline-edit-btn "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"class") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(helpers,"lookupMap").call(alias1,depths[1],(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"name":"lookupMap","hash":{},"data":data,"loc":{"start":{"line":2,"column":84},"end":{"line":2,"column":108}}}),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":76},"end":{"line":2,"column":144}}})) != null ? stack1 : "")
    + ">\n    <span class=\"fas fa-edit\"></span>\n</button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "=\""
    + alias2(alias1(depth0, depth0))
    + "\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inlineEdit") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true})