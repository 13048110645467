import { ReactElement, useCallback, useState } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import {
  IOverviewEditorItem,
  OverviewEditorModal,
} from '@bq/components/Overviews';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import { fetchOverviews } from '../Api/overviews';
import { filterTypes } from '../filter-types';

export const EditOverviews = (): ReactElement => {
  const { t } = useTranslation('Sms');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stored, setStored] = useDOMInputJSON<IOverviewEditorItem[] | null>(
    '#settings-form',
    'sms[overviews]',
    null
  );

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);
  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onChange = useCallback(
    (value: IOverviewEditorItem[]) => {
      setStored(value);
    },
    [setStored]
  );

  return (
    <>
      <Button type="button" onClick={open} leftIcon={<FaList />}>
        {t('global:edit_overviews')}
      </Button>
      <OverviewEditorModal
        value={stored ?? fetchOverviews}
        isOpen={isOpen}
        onClose={close}
        onChange={onChange}
        filterTypes={filterTypes}
        withGroupVisibility
      />
    </>
  );
};
