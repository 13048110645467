import { ReactElement, useCallback } from 'react';
import { MenuOptionGroup, MenuItemOption } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RefreshMethod } from './types';

interface Props {
  value: RefreshMethod;
  onChange: (val: RefreshMethod) => void;
}

export const RefreshMethodSelector = ({
  value,
  onChange,
}: Props): ReactElement => {
  const { t } = useTranslation();

  const handleRefreshMethod = useCallback(
    (val: string | string[]) => {
      if (val !== 'manual' && val !== 'auto') {
        console.warn('Got invalid refresh method from input: ', val);

        return;
      }

      onChange(val);
    },
    [onChange]
  );

  return (
    <MenuOptionGroup
      title={t('global:refresh_mode')}
      type="radio"
      value={value}
      onChange={handleRefreshMethod}
    >
      <MenuItemOption value="manual">
        {t('global:refresh_mode_manual')}
      </MenuItemOption>
      <MenuItemOption value="auto">
        {t('global:refresh_mode_auto')}
      </MenuItemOption>
    </MenuOptionGroup>
  );
};
