import { ReactElement, PropsWithChildren, memo } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useItemStyle } from './item-style';

export interface ButtonOverviewProps extends ButtonProps {
  title: string;
  isActive?: boolean;
}

const ButtonOverview = memo(
  ({
    children,
    title,
    isActive = false,
    ...btnProps
  }: PropsWithChildren<ButtonOverviewProps>): ReactElement => (
    <Button {...useItemStyle({ isActive })} {...btnProps}>
      {children ?? title}
    </Button>
  )
);
ButtonOverview.displayName = 'ButtonOverview';

export { ButtonOverview };
