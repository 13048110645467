import { useCallback, useState } from 'react';

import { useFormState } from 'BootQuery/Assets/components/form-state';
import { FieldValue, loadValues } from 'BootQuery/Assets/components/FormEditor';
import { useMitt } from 'BootQuery/Assets/js/use-mitt';

import { callListEvents } from '../CallList/call-list-events';
import { addNumberToContact } from '../ContactModal/api';
import { updateCallForm } from './api';
import { callFormEvents } from './call-form-events';
import { CallForm, CallFormModalProps, CallFormSubmit } from './types';

export interface UseCallFormModalProps {
  customFields: FieldValue[];
}

export interface UseCallFormModalResult {
  modalProps: CallFormModalProps;
}

export const useCallFormModal = ({
  customFields,
}: UseCallFormModalProps): UseCallFormModalResult => {
  const [call, setCall] = useState<CallForm | null>(null);
  const [formState, setFormState] = useFormState();

  const editCallForm = useCallback(
    (ev: CallForm) => {
      setFormState(null);
      setCall({
        ...ev,
        call: {
          ...ev.call,
          data: loadValues(customFields, ev.call.data),
        },
      });
    },
    [setFormState, customFields]
  );

  useMitt(callFormEvents, 'callEdit', editCallForm);

  const onSubmit = useCallback(
    async (submitted: CallFormSubmit) => {
      setFormState('saving');

      console.log('Call form submit: ', submitted);
      if (submitted.contact) {
        await addContact(submitted);
      }

      await updateCallForm(submitted.callId, submitted.data);

      callFormEvents.emit('submit', submitted);

      setFormState('saved');
      setCall(null);
    },
    [setFormState]
  );
  const onCancel = useCallback(() => {
    setFormState(null);
    setCall(null);
  }, [setFormState]);

  return {
    modalProps: {
      isOpen: call !== null,
      onSubmit,
      onCancel,
      callForm: call ?? undefined,
      formState,
      customFields,
    },
  };
};

async function addContact({
  phoneNumber,
  contact,
  numberType,
}: CallFormSubmit): Promise<void> {
  if (!contact) {
    throw new Error('Contact not set');
  }
  if (!phoneNumber) {
    throw new Error('Phone number not set');
  }

  if (contact.type === 'manual' || contact.type === 'user') {
    throw new Error(`Unsupported contact type: "${contact.type}"`);
  }
  if ('$new' in contact) {
    throw new Error('Creating new contacts not supported here');
  }

  const { ID, type, name } = contact;
  await addNumberToContact(type, ID, phoneNumber, numberType.value);

  callListEvents.emit('contactUpdated', {
    phoneNumber,
    contact: { ID, type, name },
  });
}
