export const CHANNEL_TYPES = {
  Email: 0,
  Phone: 1,
  Mobile: 2,
  Sms: 3,
  Mail: 4,
  Fax: 5,
  Other: 6,
};

export const SMS_CHANNELS = [CHANNEL_TYPES.Mobile, CHANNEL_TYPES.Sms];
