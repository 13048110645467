import { ReactElement } from 'react';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { EmailsList } from '../EmailsList';

interface Props<RowType> {
  getCellValue: (row: RowType, rowIdx: number) => string[] | null | undefined;
}

export function makeEmailsCell<RowType>({ getCellValue }: Props<RowType>) {
  const EmailsCell = ({ row, rowIdx }: CellProps<RowType>): ReactElement => {
    const value = getCellValue(row, rowIdx) ?? [];

    return <EmailsList emails={value} />;
  };
  EmailsCell.displayName = 'EmailsCell';

  return EmailsCell;
}
