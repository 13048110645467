import {
  makeUserFilter,
  userFilterIDExpr,
} from '@bq/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterSharedWithUser = makeUserFilter({
  name: () => i18n.t('Ticketing:filter.shared_with_user'),
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return {
        'userShares:$any': {
          'user.username:contains:ci': value || '',
        },
      };
    }

    const userID = userFilterIDExpr(value);

    return {
      'userShares:$any': { userID },
    };
  },
});
