import { ReactElement, useCallback, useMemo } from 'react';
import { DefaultValues, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { Card, CardBody, CardFooter, CardHeader } from '@bq/components/Card';
import { useFormState } from '@bq/components/form-state';
import { FormActions } from '@bq/components/FormActions';

import { Dataset } from '../types';
import { useCardBodyProps } from '../use-card-body-props';
import { datasetFormToApi } from './dataset-form-to-api';
import { DatasetFormFields } from './DatasetFormFields';
import { useDatasetForm } from './form';
import { columnSchema, IDatasetForm } from './schema';
import { useDataset, useUpdateDataset } from './use-dataset';

interface Props {
  dataset: Dataset;
}

export const DatasetEditForm = ({ dataset }: Props): ReactElement => {
  const { t } = useTranslation('Datasets');
  const { refetch } = useDataset(dataset.ID);
  const [formState, setFormState] = useFormState();
  const { mutateAsync: updateDataset } = useUpdateDataset();
  const formMethods = useDatasetForm({
    defaultValues: useDefaults(dataset),
  });
  const {
    reset,
    formState: { isDirty },
  } = formMethods;

  const submit = useCallback(
    async (data: IDatasetForm) => {
      setFormState('saving');
      await updateDataset({
        ...datasetFormToApi(data),
        datasetID: dataset.ID,
      });

      const { data: updated } = await refetch();
      if (!updated) {
        throw new Error('No data after refetching dataset');
      }
      reset(makeDefaults(updated));

      setFormState('saved');
    },
    [dataset.ID, reset, refetch, updateDataset, setFormState]
  );

  return (
    <form
      data-form-dirty={isDirty}
      data-ignore-form-save
      onSubmit={formMethods.handleSubmit(submit)}
    >
      <FormProvider {...formMethods}>
        <Card>
          <CardHeader>
            <Breadcrumbs>
              <Breadcrumb to="/datasets/list">
                {t('Datasets:datasets')}
              </Breadcrumb>
              <Breadcrumb to={`/datasets/dataset/${dataset.ID}`}>
                {dataset.name}
              </Breadcrumb>
              <Breadcrumb to={`/datasets/dataset/${dataset.ID}/edit`}>
                {t('global:edit')}
              </Breadcrumb>
            </Breadcrumbs>
          </CardHeader>
          <CardBody {...useCardBodyProps()}>
            <DatasetFormFields />
          </CardBody>
          <CardFooter>
            <FormActions state={formState} cancelLink="/datasets/list" />
          </CardFooter>
        </Card>
      </FormProvider>
    </form>
  );
};

export function useDefaults(dataset: Dataset): DefaultValues<IDatasetForm> {
  return useMemo(() => makeDefaults(dataset), [dataset]);
}

function makeDefaults(dataset: Dataset): IDatasetForm {
  return {
    name: dataset.name,
    displayNameTemplate: dataset.displayNameTemplate,
    columns: dataset.metadata.columns.map((c) => columnSchema.parse(c)),
  };
}
