import React, {
  PointerEvent as ReactPointerEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type ColumnResizePointerDownHandler = (
  ev: ReactPointerEvent<HTMLElement>,
  index: number
) => void;

export interface ResizableColumn {
  minWidth?: number;
  maxWidth?: number;
  initialWidth?: string;
  ref: RefObject<HTMLElement>;
}

export function useResizableHeaders(
  headers: ResizableColumn[]
): [
  number[] | null,
  React.Dispatch<React.SetStateAction<number[] | null>>,
  ColumnResizePointerDownHandler,
  string,
] {
  const activeDragIndex = useRef<number | null>(null);
  const [columnWidths, setColumnWidths] = useState<number[] | null>(null);
  const resizingPointerId = useRef<number | null>(null);
  const initialColumns = headers
    .map((item) => item.initialWidth ?? '1fr')
    .join(' 2px ');
  const onResizerPointerDown: ColumnResizePointerDownHandler = (ev, index) => {
    if (resizingPointerId.current) {
      return; // Messing around with multiple mice/fingers?
    }

    document.body.classList.add('no-pointer-events');

    resizingPointerId.current = ev.pointerId;
    ev.currentTarget.setPointerCapture(ev.pointerId);
    activeDragIndex.current = index;
  };

  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      const { clientX } = e;
      if (resizingPointerId.current !== e.pointerId) {
        return;
      }
      if (activeDragIndex?.current === null) {
        return;
      }

      const resizedColumn = headers[activeDragIndex.current] ?? null;
      const nextColumn = headers[activeDragIndex.current + 1] ?? null;
      if (!resizedColumn || !nextColumn) {
        return;
      }
      if (!resizedColumn.ref.current || !nextColumn.ref.current) {
        return;
      }

      const columnBounds = resizedColumn.ref.current.getBoundingClientRect();
      const nextColumnBounds = nextColumn.ref.current.getBoundingClientRect();

      const minWidth = resizedColumn.minWidth ?? 50;
      const nextMinWidth = nextColumn.minWidth ?? 50;

      const { maxWidth } = resizedColumn;

      const currentWidth = columnBounds.right - columnBounds.left;
      const currentNextColumnWidth =
        nextColumnBounds.right - nextColumnBounds.left;

      const width = Math.min(
        // Make sure column width not less than minimum
        Math.max(clientX - columnBounds.x, minWidth),
        // Don't let the column be so big that the next column is under it's min width
        nextColumnBounds.right - columnBounds.left - nextMinWidth,
        // Don't let the column be bigger than max
        maxWidth ?? 1000000
      );

      const nextColumnWidth = currentNextColumnWidth - (width - currentWidth);

      const gridColumns = headers.map((header, i) => {
        if (activeDragIndex.current !== null) {
          if (i === activeDragIndex.current) {
            return width;
          }
          if (i === activeDragIndex.current + 1) {
            return nextColumnWidth;
          }
        }

        // Otherwise return the previous width (no changes)
        return header.ref.current?.offsetWidth ?? 50;
      });

      setColumnWidths(gridColumns);
    },
    [activeDragIndex, headers]
  );

  const removeListeners = useCallback(() => {
    window.removeEventListener('pointermove', onPointerMove);
    window.removeEventListener('pointerup', removeListeners);
    window.removeEventListener('pointercancel', removeListeners);
  }, [onPointerMove]);

  const onPointerUp = useCallback(
    (ev: PointerEvent) => {
      if (ev.pointerId !== resizingPointerId.current) {
        return;
      }

      document.body.classList.remove('no-pointer-events');

      resizingPointerId.current = null;
      activeDragIndex.current = null;
      removeListeners();
    },
    [activeDragIndex, removeListeners]
  );

  useEffect(() => {
    window.addEventListener('pointermove', onPointerMove);
    window.addEventListener('pointerup', onPointerUp);
    window.addEventListener('pointercancel', onPointerUp);

    return () => {
      removeListeners();
    };
  }, [activeDragIndex, onPointerMove, onPointerUp, removeListeners]);

  return [columnWidths, setColumnWidths, onResizerPointerDown, initialColumns];
}
