import Module from 'BootQuery/Assets/js/module';
import { renderReact } from 'BootQuery/Assets/js/render-react';

import { PermissionSettings } from '../components/PermissionSettings';
import { Settings } from '../components/Settings';
import { ReactRoutes } from './react-routes';

export default class Sms extends Module {
  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  static matchReactRoute = '/sms/';

  activateElements(target, _data) {
    this.activateSettings(target);
    this.activatePermissionSettings(target);
  }

  activateSettings(target) {
    const $tgt = target.findElement('#sms-settings-root');
    if ($tgt.length > 0) {
      this.renderSettings($tgt);
    }
  }

  renderSettings($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, Settings);
    }, 0);
  }

  activatePermissionSettings(target) {
    const $tgt = target.findElement('#sms-permissions-react-root');
    if ($tgt.length > 0) {
      this.renderSmsPermissions($tgt);
    }
  }

  renderSmsPermissions($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, PermissionSettings);
    }, 0);
  }
}
