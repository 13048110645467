import { memo, useRef } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { useFocusProvider } from '../FocusContext';
import { useRichText } from '../RichtextContex';

export const FontFamilyMenu = () => {
  const Fonts = ['sans-serif', 'serif', 'monospace', 'cursive'];
  const { editor } = useRichText();
  const onClick = (font: string) => {
    editor?.chain().focus().setFontFamily(font).run();
    current.current = font;
  };

  const defaultColor = useColorModeValue('white', 'whiteAlpha.300');
  const activeColor = useColorModeValue('gray.200', 'gray.700');
  const { t } = useTranslation();
  const isActive = (item: string) => {
    return editor?.isActive('textStyle', { fontFamily: item });
  };
  const current = useRef<string | null>(Fonts[0]);

  const { tabIndex, handleBlur, handleEscapingToolbar } =
    useFocusProvider();

  return (
    <Menu>
      <MenuButton
        tabIndex={tabIndex}
        as={Button}
        fontFamily={current.current ?? 'inherit'}
        rightIcon={<FaChevronDown />}
        size="sm"
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.key === 'Escape') {
            handleEscapingToolbar();
          }
        }}
        onBlur={handleBlur}
      >
        {`${t('global:richtext.font_family')}: `}
        {current.current ?? Fonts[0]}
      </MenuButton>
      <MenuList>
        {Fonts.map((item) => (
          <MenuItem
            bg={isActive(item) ? activeColor : defaultColor}
            key={item}
            onClick={() => onClick(item)}
            fontFamily={item}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const FontFamily = memo(FontFamilyMenu);
