import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiDeleteBin2Line,
  RiDeleteColumn,
  RiDeleteRow,
  RiInsertColumnLeft,
  RiInsertColumnRight,
  RiInsertRowBottom,
  RiInsertRowTop,
  RiMergeCellsHorizontal,
  RiSplitCellsHorizontal,
  RiTableLine,
} from 'react-icons/ri';

import { useRichText } from '../../RichtextContex';
import RichTextButton from '../RichTextButton';
import { useTableToggles } from './ToggleHandlers';

interface Props {
  showAddButton?: boolean;
}
const TableButtonGroup = ({ showAddButton = false }: Props) => {
  const { editor } = useRichText();
  const { t } = useTranslation();
  const {
    insertTable,
    deleteTable,
    insertColumnBefore,
    insertColumnAfter,
    deleteColumn,
    insertRowBefore,
    insertRowAfter,
    deleteRow,
    mergeCells,
    splitCells,
  } = useTableToggles(editor);

  return (
    <>
      {showAddButton && (
        <RichTextButton
          key="insertTable"
          label={t('global:richtext.table.insert_table')}
          icon={<RiTableLine size="1.25em" />}
          action={insertTable}
        />
      )}
      <RichTextButton
        key="deleteTable"
        label={t('global:richtext.table.delete_table')}
        icon={<RiDeleteBin2Line size="1.25em" />}
        action={deleteTable}
      />
      <RichTextButton
        key="insertColumnBefore"
        label={t('global:richtext.table.add_column_before')}
        icon={<RiInsertColumnLeft size="1.25em" />}
        action={insertColumnBefore}
      />
      <RichTextButton
        key="insertColumnAfter"
        label={t('global:richtext.table.add_column_after')}
        icon={<RiInsertColumnRight size="1.25em" />}
        action={insertColumnAfter}
      />
      <RichTextButton
        key="deleteColumn"
        label={t('global:richtext.table.delete_column')}
        icon={<RiDeleteColumn size="1.25em" />}
        action={deleteColumn}
      />
      <RichTextButton
        key="insertRowBefore"
        label={t('global:richtext.table.add_row_before')}
        icon={<RiInsertRowTop size="1.25em" />}
        action={insertRowBefore}
      />
      <RichTextButton
        key="insertRowAfter"
        label={t('global:richtext.table.add_row_after')}
        icon={<RiInsertRowBottom size="1.25em" />}
        action={insertRowAfter}
      />
      <RichTextButton
        key="deleteRow"
        label={t('global:richtext.table.delete_row')}
        icon={<RiDeleteRow size="1.25em" />}
        action={deleteRow}
      />
      <RichTextButton
        key="mergeCells"
        label={t('global:richtext.table.merge_cells')}
        icon={<RiMergeCellsHorizontal size="1.25em" />}
        action={mergeCells}
      />
      <RichTextButton
        key="splitCells"
        label={t('global:richtext.table.split_cells')}
        icon={<RiSplitCellsHorizontal size="1.25em" />}
        action={splitCells}
      />
    </>
  );
};

export const Table = memo(TableButtonGroup);
