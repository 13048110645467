import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { deleteDataset } from '../Api/datasets';

type UseDeleteDatasetResult = UseMutationResult<
  { datasetID: number },
  unknown,
  number,
  unknown
>;

export function useDeleteDataset(): UseDeleteDatasetResult {
  return useMutation({
    mutationKey: ['Datasets.delete'],
    mutationFn: async (datasetID: number) => {
      await deleteDataset(datasetID);

      return { datasetID };
    },
  });
}
