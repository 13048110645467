module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span>\n        <div class=\"popover-content-element\" hidden>\n            <div class=\"text-left\">\n                <span class=\"fa fa-user\"></span>&nbsp;\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"username") : stack1), depth0))
    + "\n                <br>\n                <span class=\"fa fa-id-card\"></span>&nbsp;\n                <a href=\"/phonebook/people/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"personID") : depth0), depth0))
    + "\">\n                    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"data") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n                </a>\n                <br>\n                <span class=\"fa fa-envelope\"></span>&nbsp;\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n            </div>\n        </div>\n\n        <a href=\"/phonebook/people/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\"\n            class=\"noparse\"\n            data-toggle=\"popover\"\n            data-placement=\"right\"\n            data-container=\"body\"\n            data-trigger=\"click\"\n            data-html=\"true\"\n            data-popover-content-element=\"true\"\n            data-original-title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n            tabindex=\"1\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n        </a>\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPerson") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":85,"column":0}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span>\n        <div class=\"popover-content-element\" hidden>\n            <div class='text-left'>\n                <span class='fa fa-id-card'></span>&nbsp;\n                <a href='/phonebook/people/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'>\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                </a>\n                <br>\n                <span class=\"fa fa-envelope\"></span>&nbsp;\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n            </div>\n        </div>\n\n        <a href=\"/phonebook/people/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\"\n            class=\"noparse\"\n            data-toggle=\"popover\"\n            data-placement=\"right\"\n            data-container=\"body\"\n            data-trigger=\"click\"\n            data-html=\"true\"\n            data-popover-content-element=\"true\"\n            data-original-title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n            tabindex=\"1\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n        </a>\n    </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCompany") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":0},"end":{"line":85,"column":0}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span>\n        <div class=\"popover-content-element\" hidden>\n            <div class='text-left'>\n                <span class='fa fa-building'></span>&nbsp;\n                <a href='/phonebook/companies/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "'>\n                    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n                </a>\n                <br>\n                <span class=\"fa fa-envelope\"></span>&nbsp;\n                "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + "\n            </div>\n        </div>\n\n        <a href=\"/phonebook/companies/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"ID") : depth0), depth0))
    + "\"\n            class=\"noparse\"\n            data-toggle=\"popover\"\n            data-placement=\"right\"\n            data-container=\"body\"\n            data-trigger=\"click\"\n            data-html=\"true\"\n            data-popover-content-element=\"true\"\n            data-original-title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n            tabindex=\"1\">\n            "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n        </a>\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":85,"column":7}}})) != null ? stack1 : "");
},"useData":true})