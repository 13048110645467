import { Box, Button, Flex, HStack, Input, Text } from '@chakra-ui/react';
import { ChangeEvent, useCallback, useContext, useMemo, memo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { useTranslation } from 'react-i18next';
import { useAllItemsWithChanges } from './apply-item-changes';
import { useSelectedPath } from './selected-path';
import { SelectEditItem } from './SelectEditItem';
import { SelectFieldSettingsContext } from './SelectFieldSettingsContext';
import { SelectedItem, SelectEditorSettings, SelectItem } from './types';

interface SelectEditProps {
  select: SelectEditorSettings;
  parentSelectId?: string;
}

export const SelectEdit = memo(({ select }: SelectEditProps) => {
  const { t } = useTranslation();

  const {
    methods: { renameSelect, addItem, deleteSelect },
  } = useContext(SelectFieldSettingsContext);
  const selectedPath = useSelectedPath();

  const parentSelectId = useParentSelectId(select.id);
  const ourItems = useAllItemsWithChanges()[select.id] ?? [];
  const items = useFilteredItems(ourItems, parentSelectId);

  const parentItemId = useMemo(() => {
    if (parentSelectId) {
      return selectedForSelect(selectedPath, parentSelectId);
    }

    return undefined;
  }, [selectedPath, parentSelectId]);

  const onAdd = useCallback(() => {
    addItem(select.id, parentItemId);
  }, [addItem, select.id, parentItemId]);

  const onRename = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => renameSelect(select.id, ev.target.value),
    [select.id, renameSelect]
  );

  const onDelete = useCallback(() => {
    deleteSelect(select.id);
  }, [deleteSelect, select.id]);

  return (
    <Flex
      borderRight="1px"
      borderColor="gray.400"
      px="2"
      minW="xs"
      direction="column"
      h="50vh"
      minH="sm"
    >
      <HStack
        borderBottom="1px"
        borderColor="gray.200"
        py="2"
        px="2"
        alignItems="center"
      >
        <Input value={select.name} onChange={onRename} size="sm" />
        <DeleteButton size="xs" onClick={onDelete} />
      </HStack>
      <Box
        flexGrow={1}
        borderBottom="1px"
        borderColor="gray.200"
        overflowY="auto"
      >
        {items.length === 0 ? (
          <Box px="2" py="4">
            <Text textAlign="center" color="gray.500">
              {t('global:form_editor.no_items_added_yet')}
            </Text>
          </Box>
        ) : (
          <Box>
            {items.map((item) => (
              <SelectEditItem
                key={item.key}
                selectId={select.id}
                value={item}
              />
            ))}
          </Box>
        )}
      </Box>
      <Flex justifyContent="center" py="2">
        <Button
          colorScheme="green"
          display="flex"
          alignItems="center"
          size="sm"
          isDisabled={!!parentSelectId && !parentItemId}
          onClick={onAdd}
        >
          <FaPlus />
          &nbsp;&nbsp; {t('global:form_editor.add_item')}
        </Button>
      </Flex>
    </Flex>
  );
});

SelectEdit.displayName = 'SelectEdit';

function useFilteredItems(
  items: SelectItem[],
  parentSelectId: string | undefined
): SelectItem[] {
  const selectedPath = useSelectedPath();

  return useMemo(() => {
    if (!parentSelectId) {
      return items;
    }

    const parentItemId = selectedPath.find(
      (seg) => seg.selectId === parentSelectId
    )?.itemId;

    return items.filter((item) => item.parentItemId === parentItemId);
  }, [items, selectedPath, parentSelectId]);
}

function selectedForSelect(
  path: SelectedItem[],
  selectId: string
): string | undefined {
  const prevSegment = path.find((seg) => seg.selectId === selectId);

  return prevSegment?.itemId;
}

function useParentSelectId(selectId: string): string | undefined {
  const {
    settings: { selects },
  } = useContext(SelectFieldSettingsContext);

  const selectIdx = selects.findIndex((select) => select.id === selectId);
  if (selectIdx < 1) {
    // No previous selects
    return undefined;
  }

  return selects[selectIdx - 1].id;
}
