import Module from 'BootQuery/Assets/js/module';
import { popoverForTrigger } from 'app/assets/js/util';
import { getTemplate } from 'BootQuery/Assets/js/BootQuery';
import { renderReactRoute } from 'BootQuery/Assets/js/activate-react';
import { renderReact } from 'BootQuery/Assets/js/render-react';
import { FaFileAlt, FaPhone, FaCheck } from 'react-icons/fa';
import i18n from 'BootQuery/Assets/js/i18n';
import { dial } from '../components/Dialer/store/dialer';
import { Dialer } from '../components/Dialer';
import { PermissionSettings } from '../components/PermissionSettings';
import { dialerStore } from '../components/Dialer/store/pbx';
import { tabs } from '../store/index';
import { ReactRoutes } from './react-routes';
import { callEventProviders } from '../components/CallEvent';
import { editCallForm } from '../components/CallForm';
import { Settings } from '../components/Settings';
import { OperatorSettings } from '../components/OperatorSettings';
import { ProvidedReports } from '../components/Reports/provided-reports';
import { manuallyResolveCallbackStatus } from '../components/Api/callback-status';

export default class Telephony extends Module {
  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
      eventTypes: callEventProviders,
      reports: ProvidedReports,
      callActions: {
        callActions(call) {
          const callActions = [
            {
              type: 'action',
              text: i18n.t('Telephony:fill_form'),
              icon: FaFileAlt,
              onClick: () => {
                console.log('Fill out form: ', call);
                editCallForm(call);
              },
            },
          ];

          const isFinished = call.endAt !== null;
          const isMissedIncoming = call.direction === 'incoming' && !call.answered;
          const notContacted = call.callbackStatus === null || call.callbackStatus === 'outgoingAttempt';

          if (isFinished && isMissedIncoming && notContacted) {
            callActions.push({
              type: 'action',
              text: i18n.t('Telephony:manually_resolve_callback'),
              icon: FaCheck,
              onClick: () => {
                manuallyResolveCallbackStatus(call.callId);
              },
            });
          }

          return [
            {
              type: 'group',
              text: 'Poziv',
              icon: FaPhone,
              actions: callActions,
            },
          ];
        },
        priority: 20,
      },
      silencer: {
        canBeLoud() {
          if (window.softphone && window.softphone.sessions.length > 0) {
            return false;
          }

          return true;
        },
      },
    };
  }

  static matchReactRoute = '/telephony/';

  async init(data) {
    super.init();

    await tabs.getState().init();
    dialerStore.getState().init();
  }

  activateElements(target, data) {
    this.bindEvents(target);
    this.activateReact(target);
    this.activatePermissionSettings(target);
    this.activateSettings(target);
  }

  activateReact(target) {
    const $dialerContainer = target.findElement('.header-middle-container');
    // Don't render on mobile
    if ($dialerContainer.length && window.innerWidth >= 768) {
      renderReactRoute($dialerContainer, Dialer);
    }
  }

  activatePermissionSettings(target) {
    const $tgt = target.findElement('#telephony-permissions-react-root');
    if ($tgt.length > 0) {
      this.renderTelephonyPermissions($tgt);
    }
  }

  renderTelephonyPermissions($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, PermissionSettings);
    }, 0);
  }

  // Compat methods stolen from old Dialer until we get rid of all the legacy
  bindEvents(target = 'body') {
    $(document).ev('click', 'a[href^="tel:"]:not(.noparse, [data-toggle], [download])', (e) => {
      console.log('EV EV: ', e);
      const originalEv = e.originalEvent || e; // Unwrap jQuery-wrapped event
      if (originalEv.defaultPrevented) {
        return;
      }

      const $el = $(e.currentTarget);
      if ($el.data('dial-link-activated')) {
        return;
      }

      e.preventDefault();
      $el.data('dial-link-activated', true);
      const href = $el.attr('href');
      const phoneNumber = href.replace(/^tel:/, '');
      this.activateDialLinkPopover($el, phoneNumber, $el.text());
    });
  }

  isPhone() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  async activateDialLinkPopover(target, numberToDial, formattedNumber = null) {
    const $target = $(target);
    if (!formattedNumber) {
      formattedNumber = numberToDial;
    }
    numberToDial = numberToDial.replace(/\s+/g, '');

    $target.off('click');
    $target.ev('click.dialer', (ev) => {
      ev.preventDefault();
      $target.popover('toggle');
    });
    $target
      .ev('inserted.bs.popover', (e) => {
        popoverForTrigger(e.currentTarget).addClass('popover-opening');
      })
      .ev('shown.bs.popover', (e) => {
        const $popover = popoverForTrigger(e.currentTarget);
        $popover.removeClass('popover-opening');
        $popover.find('[data-dismiss=popover]').ev('click.popoverDismiss', (e) => {
          $(e.currentTarget)
            .closest('.popover')
            .popover('hide');
        });
      });

    const [contentTemplate, titleTemplate] = await Promise.all([
      getTemplate('Telephony.telLinkPopover'),
      getTemplate('Telephony.telLinkPopoverTitle'),
    ]);
    $target.popover({
      html: true,
      title: () => $.render(titleTemplate, {
        formattedNumber,
      }),
      content: () => {
        const $content = $.render(contentTemplate, {
          numberToDial,
          hasExtension: dialerStore.getState().currentDevice !== null,
        });
        $content.find('.clickvox-dial-btn').ev('click', async (e) => {
          e.preventDefault();

          const callResponse = await dial(numberToDial);

          const cvDialEv = new CustomEvent('clickvox.dial', {
            bubbles: true,
            detail: callResponse,
          });
          $target[0].dispatchEvent(cvDialEv);
        });
        return $content;
      },
    });
    $target.popover('show');
  }

  activateSettings(target) {
    const $tgt = target.findElement('#telephony-settings-root');
    if ($tgt.length > 0) {
      this.renderSettings($tgt);
    }

    const $opTgt = target.findElement('#telephony-operator-settings-root');
    if ($opTgt.length > 0) {
      this.renderOperatorSettings($opTgt);
    }
  }

  renderSettings($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, Settings, {});
    }, 0);
  }

  renderOperatorSettings($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, OperatorSettings, {});
    }, 0);
  }
}
