import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOptionWithAdditionalData } from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';
import { useUnits } from 'app/Modules/Products/Assets/js/api';

import { Good } from '../../../types';

export const WeightUnitInput = () => {
  const { control } = useFormContext<Good>();
  const { t } = useTranslation('Products');
  const weightUnits = useWeightUnitOptions();
  const { field } = useController({
    name: 'good.netWeightUnit',
    control,
  });

  const value = useMemo(() => {
    if (!field.value) {
      return null;
    }

    return {
      symbol: field.value.symbol,
      value: field.value.symbol,
      label: field.value.symbol,
    };
  }, [field.value]);

  return (
    <FormControl mb="5">
      <FormLabel htmlFor="weightUnit">{t('Products:unit')}</FormLabel>
      <Select
        size="sm"
        onChange={(val) => {
          if (val !== null) {
            field.onChange(val);
          }
        }}
        value={value}
        variant="outline"
        options={weightUnits}
      />
    </FormControl>
  );
};

type UnitOption = SelectOptionWithAdditionalData<string, { symbol: string }>;

function useWeightUnitOptions(): UnitOption[] {
  const { data: units } = useUnits();

  return useMemo(() => {
    if (!units) {
      return [];
    }

    return Object.values(units.mass.units).map(({ symbol }) => ({
      symbol,
      label: symbol,
      value: symbol,
    }));
  }, [units]);
}
