import { ReactElement, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { Card, CardBody, CardFooter, CardHeader } from '@bq/components/Card';
import { useFormState } from '@bq/components/form-state';
import { FormActions } from '@bq/components/FormActions';
import { FieldValue, GeneratedForm } from '@bq/components/FormEditor';

import { createDatasetEntry } from '../Api/dataset-entries';
import { Dataset } from '../types';
import { useCardBodyProps } from '../use-card-body-props';
import { useEntryForm } from './form';
import { IEntryForm } from './schema';

interface Props {
  dataset: Dataset;
  formDefinition: FieldValue[];
}

export const DatasetEntryCreateForm = ({
  dataset,
  formDefinition,
}: Props): ReactElement => {
  const { t } = useTranslation('Datasets');
  const [formState, setFormState] = useFormState();
  const navigate = useNavigate();
  const formMethods = useEntryForm({
    defaultValues: {
      data: {},
    },
  });
  const {
    formState: { isDirty },
  } = formMethods;

  const submit = useCallback(
    async (data: IEntryForm) => {
      setFormState('saving');
      const entryID = await createDatasetEntry(dataset.ID, data);
      setFormState('saved');

      navigate(`/datasets/dataset/${dataset.ID}/entries/${entryID}`, {
        replace: true,
      });
    },
    [dataset.ID, navigate, setFormState]
  );

  return (
    <form
      data-form-dirty={isDirty}
      data-ignore-form-save
      onSubmit={formMethods.handleSubmit(submit)}
    >
      <FormProvider {...formMethods}>
        <Card>
          <CardHeader>
            <Breadcrumbs>
              <Breadcrumb to="/datasets/list">
                {t('Datasets:datasets')}
              </Breadcrumb>
              <Breadcrumb to={`/datasets/dataset/${dataset.ID}/entries`}>
                {dataset.name}
              </Breadcrumb>
              <Breadcrumb to={`/datasets/dataset/${dataset.ID}/entries/create`}>
                {t('global:create')}
              </Breadcrumb>
            </Breadcrumbs>
          </CardHeader>
          <CardBody {...useCardBodyProps()}>
            <GeneratedForm fields={formDefinition} />
          </CardBody>
          <CardFooter>
            <FormActions state={formState} cancelLink="/datasets/list" />
          </CardFooter>
        </Card>
      </FormProvider>
    </form>
  );
};
