import { useCallback, useMemo } from 'react';
import { Select, Size } from 'chakra-react-select';

import { modalSelect, selectStyles } from '@bq/components/Select/select-styles';
import { SelectOption } from 'BootQuery/Assets/js/globalTypes';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  size?: Size;
}

export const CharsetSelect = ({ value, onChange, size }: Props) => {
  const options = useCharsetOptions();

  const fullValue = options.find((c) => c.value === value) ?? options[0];
  const handleChange = useCallback(
    (value: SelectOption | null) => {
      if (!value) {
        console.warn('Tried to select nothing');

        return;
      }

      onChange?.(value.value);
    },
    [onChange]
  );

  return (
    <Select
      size={size}
      value={fullValue}
      onChange={handleChange}
      options={options}
      chakraStyles={selectStyles}
      styles={modalSelect}
      menuPortalTarget={document.body}
    />
  );
};

function useCharsetOptions(): SelectOption[] {
  return useMemo(() => {
    return charsets.map((charset) => ({ value: charset, label: charset }));
  }, []);
}

export const charsets: string[] = [
  'UTF-8',
  'CP1250 (WINDOWS-1250)',
  'ASCII',
  'UCS-2',
  'UCS-2BE',
  'UCS-2LE',
  'UCS-4',
  'UCS-4BE',
  'UCS-4LE',
  'UTF-16',
  'UTF-16BE',
  'UTF-16LE',
  'UTF-32',
  'UTF-32BE',
  'UTF-32LE',
  'UTF-7',
  'UCS-2-INTERNAL',
  'UCS-2-SWAPPED',
  'UCS-4-INTERNAL',
  'UCS-4-SWAPPED',
  'C99',
  'JAVA',
  'ISO-8859 (LATIN1, CP819)',
  'ISO-8859-2 (LATIN2)',
  'ISO-8859-3 (LATIN3)',
  'ISO-8859-4 (LATIN4)',
  'ISO-8859-5 (CYRILLIC)',
  'ISO-8859-6 (ARABIC)',
  'ISO-8859-7 (GREEK)',
  'ISO8859-8 (HEBREW)',
  'ISO-8859-9 (LATIN5)',
  'ISO-8859-10 (LATIN6)',
  'ISO-8859-11',
  'ISO-8859-13 (LATIN7)',
  'ISO-8859-14 (LATIN8, ISO-CELTIC)',
  'ISO-8859-15 (LATIN-9) ',
  'ISO-8859-16 (LATIN10)',
  'KOI8-R  (CSKOI8R)',
  'KOI8-U',
  'KOI8-RU',
  'CP1251 (WINDOWS-1251)',
  'CP1252 (WINDOWS-1252)',
  'CP1253 (WINDOWS-1253)',
  'CP1254 (WINDOWS-1254)',
  'CP1255 (WINDOWS-1255)',
  'CP1256 (WINDOWS-1256)',
  'CP1257 WINBALTRIM WINDOWS-1257',
  'CP1258 WINDOWS-1258',
  '850 CP850 IBM850 CSPC850MULTILINGUAL',
  '862 CP862 IBM862 CSPC862LATINHEBREW',
  '866 CP866 IBM866 CSIBM866',
  'MAC MACINTOSH MACROMAN CSMACINTOSH',
  'MACCENTRALEUROPE',
  'MACICELAND',
  'MACCROATIAN',
  'MACROMANIA',
  'MACCYRILLIC',
  'MACUKRAINE',
  'MACGREEK',
  'MACTURKISH',
  'MACHEBREW',
  'MACARABIC',
  'MACTHAI',
  'ROMAN8',
  'NEXTSTEP',
  'ARMSCII-8',
  'GEORGIAN-ACADEMY',
  'GEORGIAN-PS',
  'KOI8-T',
  'CP154 (CYRILLIC-ASIAN)',
  'KZ-1048 RK1048 STRK1048-2002 CSKZ1048',
  'MULELAO-1',
  'CP1133 IBM-CP1133',
  'ISO-IR-166 TIS-620 TIS620 TIS620-0 TIS620.2529-1 TIS620.2533-0 TIS620.2533-1',
  'CP874 WINDOWS-874',
  'VISCII VISCII1.1-1 CSVISCII',
  'TCVN TCVN-5712 TCVN5712-1 TCVN5712-1:1993',
  'ISO-IR-14 ISO646-JP JIS_C6220-1969-RO JP CSISO14JISC6220RO',
  'JISX0201-1976 JIS_X0201 X0201 CSHALFWIDTHKATAKANA',
  'ISO-IR-87 JIS0208 JIS_C6226-1983 JIS_X0208 JIS_X0208-1983 JIS_X0208-1990 X0208 CSISO87JISX0208',
  'ISO-IR-159 JIS_X0212 JIS_X0212-1990 JIS_X0212.1990-0 X0212 CSISO159JISX02121990',
  'CN GB_1988-80 ISO-IR-57 ISO646-CN CSISO57GB1988',
  'CHINESE GB_2312-80 ISO-IR-58 CSISO58GB231280',
  'CN-GB-ISOIR165 ISO-IR-165',
  'ISO-IR-149 KOREAN KSC_5601 KS_C_5601-1987 KS_C_5601-1989 CSKSC56011987',
  'EUC-JP EUCJP EXTENDED_UNIX_CODE_PACKED_FORMAT_FOR_JAPANESE CSEUCPKDFMTJAPANESE',
  'MS_KANJI SHIFT-JIS SHIFT_JIS SJIS CSSHIFTJIS',
  'CP932',
  'ISO-2022-JP CSISO2022JP',
  'ISO-2022-JP-1',
  'ISO-2022-JP-2 CSISO2022JP2',
  'CN-GB EUC-CN EUCCN GB2312 CSGB2312',
  'GBK',
  'CP936 (WINDOWS-936)',
  'GB18030',
  'ISO-2022-CN',
  'ISO-2022-CN-EXT',
  'HZ-GB-2312',
  'EUC-TW',
  'BIG-5 (CN-BIG5)',
  'CP950',
  'BIG5-HKSCS:1999',
  'BIG5-HKSCS:2001',
  'BIG5-HKSCS BIG5-HKSCS:2004 BIG5HKSCS',
  'EUC-KR EUCKR CSEUCKR',
  'CP949 UHC',
  'CP1361 JOHAB',
  'ISO-2022-KR CSISO2022KR',
  'CP856',
  'CP922',
  'CP943',
  'CP1046',
  'CP1124',
  'CP1129',
  'CP1161 IBM-1161 IBM1161 CSIBM1161',
  'CP1162 IBM-1162 IBM1162 CSIBM1162',
  'CP1163 IBM-1163 IBM1163 CSIBM1163',
  'DEC-KANJI',
  'DEC-HANYU',
  '437 CP437 IBM437 CSPC8CODEPAGE437',
  'CP737',
  'CP775 IBM775 CSPC775BALTIC',
  '852 CP852 IBM852 CSPCP852',
  'CP853',
  '855 CP855 IBM855 CSIBM855',
  '857 CP857 IBM857 CSIBM857',
  'CP858',
  '860 CP860 IBM860 CSIBM860',
  '861 CP-IS CP861 IBM861 CSIBM861',
  '863 CP863 IBM863 CSIBM863',
  'CP864 IBM864 CSIBM864',
  '865 CP865 IBM865 CSIBM865',
  '869 CP-GR CP869 IBM869 CSIBM869',
  'CP1125',
  'EUC-JISX0213',
  'SHIFT_JISX0213',
  'ISO-2022-JP-3',
  'BIG5-2003',
  'ISO-IR-230 TDS565',
  'ATARI',
  'RISCOS-LATIN1',
];
