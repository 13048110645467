import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { ConvertResult, UnitGroupKey } from './types';
import { useUnits } from './api';
import { convertUnits } from './utils';

export type ConvertUnits = (
  unitGroup: UnitGroupKey,
  from: string,
  to: string,
  value: number
) => ConvertResult;

export const useConvertUnits = (): UseQueryResult<ConvertUnits> => {
  const { data: units } = useUnits();

  return useQuery({
    queryKey: ['useConvertUnits', units],
    enabled: units !== undefined,
    refetchOnMount: false,
    queryFn: () => {
      if (!units) {
        throw new Error(
          'useConvertUnits query function called before loading units'
        );
      }

      return (
        unitGroup: UnitGroupKey,
        from: string,
        to: string,
        value: number
      ) => {
        return convertUnits(units, unitGroup, from, to, value);
      };
    },
  });
};
