import { useCallback } from 'react';
import { SingleValue } from 'chakra-react-select';

import { UseContactCreateModalProps } from '@bq/components/ContactModals/types';
import { useContactCreateModal } from '@bq/components/ContactModals/useContactCreateModal';
import { splitName } from 'app/Modules/Phonebook/Assets/components/ContactSelector/util';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import { GenericCallbackData } from 'BootQuery/Assets/js/globalTypes';

import {
  ContactItem,
  ContactSelectCreateMode,
  ContactSelectOption,
} from './types';
import { createOne, singleSelectOptionToValue } from './utils';

type SingleSelectChangeHandler = (
  newValue: SingleValue<ContactSelectOption>,
  onChange: (value: ContactItem | null) => void,
  saveMode: ContactSelectCreateMode
) => void;

export const useHandleOnChangeSingleSelect = (): SingleSelectChangeHandler => {
  const openCreateModal = useContactCreateModal();

  return useCallback(
    (newValue, onChange, saveMode) => {
      // eslint-disable-next-line no-underscore-dangle
      if (newValue?.__isNew__) {
        // The "create new" option.
        createContactFromOption({
          newValue,
          onChange,
          saveMode,
          openCreateModal,
        });
      } else {
        // Normal value select, just update value
        onChange(singleSelectOptionToValue(newValue));
      }
    },
    [openCreateModal]
  );
};

interface ContactFromOptionProps {
  onChange: (value: ContactItem | null) => void;
  saveMode: ContactSelectCreateMode;
  newValue: ContactSelectOption;
  openCreateModal: (props: UseContactCreateModalProps) => void;
}

type CreateInModalProps = Omit<ContactFromOptionProps, 'saveMode'>;

function createContactFromOption({
  newValue,
  onChange,
  saveMode,
  openCreateModal,
}: ContactFromOptionProps): void {
  if (typeof newValue.value !== 'string') {
    throw new Error('New option value must be a string');
  }

  switch (newValue.type) {
    case 'company':
    case 'person':
      if (saveMode === 'saveOnApiModal') {
        createOptionInModal({ openCreateModal, newValue, onChange });
      } else if (saveMode === 'saveInForm') {
        onChange({ $new: newValue.value, type: newValue.type });
      } else if (saveMode === 'saveOnApi') {
        createOptionInApi(newValue.type, newValue.value, onChange);
      }
      break;
    case 'companyLocation':
    case 'companyDepartment':
      // These are always created in modal because we need info about which
      // company they belong to
      createOptionInModal({ openCreateModal, newValue, onChange });
      break;
    case 'manual':
      // No extra options, just store text as entered
      onChange({ $new: newValue.value, type: newValue.type });

      break;
    case 'user':
      console.error('Tried to create user from contact select: ', newValue);
      throw Error('Users are not meant to be created through Contact Select');

    default:
      throw new Error(`Unknown type: ${newValue.type}`);
  }
}

async function createOptionInApi(
  type: ContactType,
  value: string,
  onChange: (value: ContactItem | null) => void
): Promise<void> {
  const val = await createOne(type, value);

  onChange(singleSelectOptionToValue(val));
}

function createOptionInModal({
  openCreateModal,
  newValue,
  onChange,
}: CreateInModalProps) {
  if (typeof newValue.value !== 'string') {
    throw new Error('New option value must be a string');
  }

  switch (newValue.type) {
    case 'person':
      openCreateModal({
        type: 'person',
        defaults: { ...splitName(newValue.value) },
        closeCallback: ({ firstName, lastName, ID }) => {
          onChange({
            ID,
            name: `${firstName} ${lastName}`,
            type: 'person',
          });
        },
      });
      break;
    case 'company':
      openCreateModal({
        type: 'company',
        defaults: { name: newValue.value },
        closeCallback: ({ name, ID }) => {
          onChange({
            ID,
            name: name ?? '',
            type: 'company',
          });
        },
      });
      break;
    case 'companyLocation':
    case 'companyDepartment':
      openCreateModal({
        type: newValue.type,
        defaults: { name: newValue.value },
        closeCallback: (data: GenericCallbackData<{ name?: string }>) => {
          if ('name' in data && data.name) {
            onChange({
              type: newValue.type,
              ID: data.ID,
              name: data.name,
            });
          }
        },
      });
      break;
    default:
      throw new Error(
        `Contact of type ${newValue.type} cannot be created in a modal`
      );
  }
}
