import { Flex } from '@chakra-ui/react';
import { CellProps } from 'BootQuery/Assets/components/Table';
import { ReactElement } from 'react';
import { CallParty } from '../CallParty';
import { CallListCall } from './types';

type Props = CellProps<CallListCall>;
export const CallSourceCell = ({ row: call }: Props): ReactElement => (
  <Flex alignItems="center">
    <CallParty
      party={call.sourceInfo.source}
      phoneNumber={call.sourceInfo.phoneNumber}
    />
  </Flex>
);
