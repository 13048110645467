import { Editor } from '@tiptap/react';

import React, { ReactNode, useContext } from 'react';

interface IRichTextContext {
  editor: Editor | null;
}
export const RichTextCtx = React.createContext<IRichTextContext | null>(null);

interface IRichTextProvider {
  children: ReactNode;
  editor: Editor | null;
}

/**
 * Provides a RichTextContext around RichText components and content. This is already included in
 * the RichText component and you should not use it except when creating your own
 * RichText component.
 * @param {ReactNode} children components that this context wraps
 * @param {Editor} editor tiptap editor check the documentation at https://tiptap.dev/api/editor
 *
 */
// eslint-disable-next-line arrow-body-style
const NoMemoRichTextProvider = ({ children, editor }: IRichTextProvider) => {
  return <RichTextCtx.Provider value={{ editor }}>{children}</RichTextCtx.Provider>;
};
export const RichTextProvider = React.memo(NoMemoRichTextProvider);

/**
 * Hook for easier use of context
 * @returns {IRichTextContext}
 */
export function useRichText(): IRichTextContext {
  const context = useContext(RichTextCtx);
  if (context === null) {
    throw new Error('Editor Context not inited, critical error');
  } else {
    return context;
  }
}
