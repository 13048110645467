import { FaTable, FaThLarge } from 'react-icons/fa';

import { DisplayModeOption } from '@bq/components/TableMenu';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

export function useShowPeopleFromSubdepartments(
  defaultVal: 'true' | 'false' = 'false'
) {
  return useUserSetting(
    'Departments.showSubdepartmentEmployees',
    defaultVal,
    false
  );
}

export const phonebookDisplayModeOptions: DisplayModeOption[] = [
  { id: 'table', title: 'Table', icon: FaTable },
  { id: 'card', title: 'Card', icon: FaThLarge },
];
