import { ReactElement } from 'react';
import { Heading, Input } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonRadio } from '@bq/components/ButtonRadio';

import { useAppointmentFormContext } from './form';
import { useLocationTypes } from './location-types';

export const AppointmentLocationField = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { register, control } = useAppointmentFormContext();
  const locationTypes = useLocationTypes();
  const typeController = useController({ control, name: 'location.type' });
  const locationType = typeController.field.value ?? 'unknown';

  return (
    <>
      <Heading as="h2" fontSize="md">
        {t('Events:appointment_location')}:
      </Heading>
      <ButtonRadio
        name="location.type"
        options={locationTypes}
        value={locationType}
        onChange={typeController.field.onChange}
      />
      {locationType !== 'unknown' && (
        <Input
          placeholder={t('Events:appointment_location')}
          {...register('location.location')}
        />
      )}
    </>
  );
};
