import { makeComboboxFilter } from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { TicketType } from '../../js/types';

export const ticketFilterTicketType = makeComboboxFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return { 'ticketType.name:contains:ci': value };
    }
    if (typeof value === 'object') {
      const { ID } = value;

      return {
        'ticketType.ID': ID,
      };
    }

    return null;
  },
  extraProps: {
    cacheKey: 'ticketFilterType',
    search: async (search: string) => {
      const { data } = await Api.get<TicketType[]>('/api/ticketing/types', {
        params: {
          filters: search ? { $search: search } : {},
          fields: ['ID', 'name'],
        },
      });

      return data;
    },
    itemToString: (item: TicketType) => item.name,
    enableTextSearch: true,
    itemToValue: (item: TicketType) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<TicketType>(
        `/api/ticketing/types/${value.id}`,
        {
          params: { fields: ['ID', 'name'] },
        }
      );

      return data;
    },
  },

  name: () => i18n.t('Ticketing:form.ticket_type'),
  operators: [],
});
