import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { WithSeparator } from '@bq/components/WithSeparator';
import { SimpleContactDisplay } from '@bq/components/ContactDisplay/SimpleContactDisplay';

import { useDuplicateCompany } from './use-company-duplicate';

export const DuplicateCompanyIndicator = () => {
  const { data: companies } = useDuplicateCompany();
  const { t } = useTranslation();

  return (
    <>
      {companies && companies.length >= 1 && (
        <Box fontSize="md" color="yellow.500">
          {t('Phonebook:company_already_exists')}:
        </Box>
      )}
      {companies?.map((duplicate) => (
        <WithSeparator key={duplicate.ID} separator=",&nbsp;">
          <SimpleContactDisplay
            fontSize="md"
            contactID={duplicate.ID}
            contactName={`${duplicate.name}`}
            contactType="company"
          />
          &nbsp;
        </WithSeparator>
      ))}
    </>
  );
};
