import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { SelectOption } from '@bq/components/SelectWrappers';

import { getPauseTypes } from '../../Api/pause-types';
import { getCurrentPermissions } from '../util';
import { CallFormSettings } from './types';

export function useCallFormSettings(
  groupID: number | string
): UseQueryResult<CallFormSettings> {
  return useQuery({
    queryFn: () => getCallFormSettings(groupID),
    queryKey: ['Telephony.callFormPermissionSettings', groupID],
  });
}

async function getCallFormSettings(
  groupID: number | string
): Promise<CallFormSettings> {
  const [
    compulsoryCallForm,
    compulsoryCallFormPause,
    compulsoryCallFormDirection,
    compulsoryCallFormTrigger,
  ] = await Promise.all([
    getCompulsoryCallFormSetting(groupID),
    getCompulsoryCallFormPauseSetting(groupID),
    getCompulsoryCallFormDirectionSetting(groupID),
    getCompulsoryCallFormTriggerSetting(groupID),
  ]);

  return {
    compulsoryCallForm,
    compulsoryCallFormPause,
    compulsoryCallFormDirection,
    compulsoryCallFormTrigger,
  };
}

function getCompulsoryCallFormSetting(
  groupID: number | string
): Promise<boolean | null> {
  return getCurrentPermissions('compulsoryCallForm', groupID);
}

function getCompulsoryCallFormPauseSetting(
  groupID: number | string
): Promise<string | null> {
  return getCurrentPermissions('compulsoryCallFormPause', groupID);
}

function getCompulsoryCallFormDirectionSetting(
  groupID: number | string
): Promise<string | null> {
  return getCurrentPermissions('compulsoryCallFormDirection', groupID);
}

function getCompulsoryCallFormTriggerSetting(
  groupID: number | string
): Promise<string | null> {
  return getCurrentPermissions('compulsoryCallFormTrigger', groupID);
}

export async function getPauseTypeOptions(
  search: string
): Promise<SelectOption[]> {
  const pauses = await getPauseTypes({ filters: { $search: search } });

  return pauses.map((pause) => ({ label: pause, value: pause }));
}
