import { Api } from 'BootQuery/Assets/js/api';

import { APICustomEvent, CustomEvent, CustomEventType, Event } from './types';

export const getCustomEventTypes = async (): Promise<CustomEventType[]> => {
  const { data } = await Api.get<CustomEventType[]>(
    '/api/events/customEventTypes',
    {
      params: { withDeleted: 'true' },
    }
  );

  return data;
};

interface InsertResponse {
  ID: number;
}

export const getCustomEvent = async (
  eventID: number | string
): Promise<APICustomEvent> => {
  const { data } = await Api.get<APICustomEvent>(
    `/api/events/customEvents/${eventID}`
  );
  data.eventAt = new Date(data.eventAt);
  console.log(data);

  return data;
};

export const createCustomEvent = async (
  event: Omit<CustomEvent, 'ID'>
): Promise<InsertResponse> => {
  const { data } = await Api.post<InsertResponse>(
    '/api/events/customEvents',
    event
  );

  return data;
};

export const updateCustomEvent = async (
  id: number | string,
  event: Omit<CustomEvent, 'ID'>
): Promise<void> => {
  await Api.patch<InsertResponse>(`/api/events/customEvents/${id}`, event);
};

export interface EventFiltersParam {
  before?: string;
  [key: string]: unknown;
}

export interface EventParams {
  limit: number;
  filters?: EventFiltersParam;
}

export const listEvents = async (params: EventParams): Promise<Event[]> => {
  const { data } = await Api.get<Event[]>('/api/events/events', {
    params,
  });

  return data;
};
