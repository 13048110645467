import { mailsModule } from '../../../js/mails';
import { MailAccount } from '../../../js/types';

export async function findAccount(
  accountID: number
): Promise<MailAccount | null> {
  const allAccounts = await mailsModule().getAvailableAccounts({
    withDeleted: true,
    withDisallowed: true,
  });
  const availableAccounts = allAccounts.filter(
    (account) => account.available && !account.deleted
  );

  const availableAccount = availableAccounts.find(({ ID }) => ID === accountID);
  if (availableAccount) {
    return availableAccount;
  }

  const removedAccount = allAccounts.find(({ ID }) => ID === accountID);
  if (removedAccount) {
    // If account was deleted, but there is a new account with same address,
    // return that
    const newByEmail = accountByEmail(availableAccounts, removedAccount.email);
    if (newByEmail) {
      return newByEmail;
    }
  }

  // If account not found, return the first one that is available
  return availableAccounts[0] ?? null;
}

function accountByEmail(
  accounts: MailAccount[],
  email: string
): MailAccount | null {
  return accounts.find((acc) => acc.email === email) ?? null;
}
