import { get } from 'lodash-es';

import { CellProps, Row } from './types';

export function defaultCellValue<RowType>({
  row,
  column,
}: CellProps<RowType>): string | number {
  const recRow = row as unknown as Row;
  const autoVal = get(recRow, column.key) ?? null;
  if (typeof autoVal === 'string' || typeof autoVal === 'number') {
    return autoVal;
  }

  return '-';
}

export function getCellValueString<RT>({
  column,
  row,
  rowIdx,
  tableSize,
}: CellProps<RT>): string {
  const getVal = column.getValue ?? defaultCellValue;

  const val = getVal({ tableSize, row, rowIdx, column });
  const cleanVal = String(val ?? '').replace(/^[-]$/, '');

  return cleanVal;
}

export function getCellValue<RT>(props: CellProps<RT>): string | number {
  if (props.column.getValue) {
    return props.column.getValue(props);
  }

  return defaultCellValue<RT>(props);
}
