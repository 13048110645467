import Fuse from 'fuse.js';
import { memoize } from 'lodash-es';

import { SearchOptionFn, SelectOption } from './types';

export const searchStaticOptions: SearchOptionFn = async (
  search,
  optionsOrGroups,
  searchOn = ['label']
) => {
  const flattenGroups = memoize(() => {
    return optionsOrGroups.reduce((all, current) => {
      if ('options' in current) {
        return [
          ...all,
          ...current.options.map((item) => ({ ...item, group: current.label })),
        ];
      }

      return [...all, current];
    }, [] as SelectOption[]);
  });

  const fuse = memoize(() => {
    return new Fuse(flattenGroups(), {
      keys: searchOn,
      threshold: 0.15,
    });
  });

  if (!search || (search && search.length === 0)) {
    return optionsOrGroups;
  }

  const result = fuse().search(search);

  const reconstructGroups = result.reduce(
    (all, current) => {
      if ('group' in current.item) {
        const groupKey = current.item.group as string;
        const prevMembers = all[groupKey] ?? [];

        return { ...all, [groupKey]: [...prevMembers, current.item] };
      }

      const groupKey = '_noGroup';
      const prevMembers = all[groupKey] ?? [];

      return { ...all, [groupKey]: [...prevMembers, current.item] };
    },
    {} as { [key: string]: SelectOption[] }
  );

  const toSelectOptions = Object.entries(reconstructGroups)
    .map(([label, options]) => {
      if (label === '_noGroup') {
        return options;
      }

      return { label, options };
    })
    .flat();

  return toSelectOptions;
};
