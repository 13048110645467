import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterRespondUntil = makeDateFilter({
  name: () => i18n.t('Ticketing:form.respond_until'),
  toFilter: makeDateToFilter('respondUntil'),
});
