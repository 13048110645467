import { useMemo } from 'react';
import mime from 'mime';
import { AllowTypesSelectOptions } from './types';

export const commonFileExtensions = [
  'doc',
  'docx',
  'html',
  'odt',
  'pdf',
  'xls',
  'xls',
  'ods',
  'ppt',
  'pptx',
  'txt',
];

export const useGetCommonFileExtensionMimes = () => {
  return useMemo(() => {
    return commonFileExtensions.reduce((all: AllowTypesSelectOptions[], current) => {
      const foundMime = mime.getType(current);
      if (foundMime) {
        return [...all, { label: `.${current}`, value: foundMime }];
      }

      return all;
    }, []);
  }, []);
};
