import { useCallback, useState } from 'react';

import { useFormState } from 'BootQuery/Assets/components/form-state';

import { addNumberToContact } from './api';
import {
  ContactModalOnSubmit,
  ContactModalProps,
  IContactModalContext,
  OnContactAdded,
} from './types';

export interface UseContactModalProps {
  onAdded?: OnContactAdded;
}

export interface UseContactModalResult extends IContactModalContext {
  modalProps: ContactModalProps;
}

export const useContactModal = ({
  onAdded,
}: UseContactModalProps): UseContactModalResult => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [formState, setFormState] = useFormState();

  const assignContact = useCallback(
    (phoneNumber: string) => {
      setFormState(null);
      setPhoneNumber(phoneNumber);
    },
    [setFormState]
  );

  const onSubmit: ContactModalOnSubmit = useCallback(
    async (phoneNumber, contact, numberType, numberAdded = false) => {
      setFormState('saving');

      if (!numberAdded) {
        await addNumberToContact(
          contact.type,
          contact.ID,
          phoneNumber,
          numberType
        );
      }
      if (onAdded) {
        onAdded(phoneNumber, contact);
      }

      setFormState('saved');
      setPhoneNumber(null);
    },
    [setFormState, onAdded]
  );
  const onCancel = useCallback(() => {
    setFormState(null);
    setPhoneNumber(null);
  }, [setFormState]);

  return {
    assignContact,
    modalProps: {
      isOpen: phoneNumber !== null,
      onSubmit,
      onCancel,
      phoneNumber: phoneNumber ?? undefined,
      formState,
    },
  };
};
