import { Alert, Text } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { ReactElement, useContext } from 'react';
import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';
import { NoteFieldSettings } from './types';

export const StaticNoteField = ({
  id,
  settings,
}: FieldValue<NoteFieldSettings>): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id) as string | undefined;

  return (
    <>
      {value && (
        <Alert status={settings.type}>
          <Text as="span" fontWeight="bold">
            {settings.name}:
          </Text>
          {value}
        </Alert>
      )}
    </>
  );
};
