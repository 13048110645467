import { FaShoppingBasket } from 'react-icons/fa';

import i18n from 'BootQuery/Assets/js/i18n';

import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { ProductItemField } from './ProductItemField';
import { ProductItemSettings } from './types';

export type { ProductItemSettings };

export const productItemField = (): FieldType<ProductItemSettings> => ({
  type: 'productItem',
  name: i18n.t('global:form_editor.field_product_item'),
  icon: FaShoppingBasket,
  components: {
    FormField: ProductItemField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  getFilters(_field) {
    return {};
  },
  getColumns(_field) {
    return [];
  },
});
