import { Api } from 'BootQuery/Assets/js/api';
import {
  DefaultPatchReturn,
  DefaultPostReturn,
} from 'BootQuery/Assets/js/globalTypes';
import { PatchCompanyDepartment, PostCompanyDepartment } from './types';

export const patchCompanyDepartment = <ReturnType = DefaultPatchReturn>({
  data,
  params,
  ID,
}: PatchCompanyDepartment) => {
  return Api.patch<ReturnType>(
    `/api/phonebook/departments/${ID}`,
    data,
    {
      params,
    }
  );
};

export const postCompanyDepartment = <ReturnType = DefaultPostReturn>({
  data,
  params,
}: PostCompanyDepartment) => {
  if ('companyID' in data && data.companyID) {
    return Api.post<ReturnType>('/api/phonebook/departments', data, {
      params,
    });
  }
  throw Error('Tried to post Company Department without Company ID');
};
