import { useMemo } from 'react';

import { GroupBy } from '@bq/components/FormEditor';

import { useReportContext } from './ReportContext';

export const useGroupedBy = (): GroupBy[] => {
  const { groupBy, allGroupBy } = useReportContext();

  return useMemo(() => {
    return groupBy.reduce((allGroup: GroupBy[], group) => {
      const found = allGroupBy.find((item) => item.key === group);
      if (found !== undefined) {
        return [...allGroup, found];
      }

      return allGroup;
    }, []);
  }, [allGroupBy, groupBy]);
};
