import { BoxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends Omit<BoxProps, 'minWidth' | 'maxWidth'> {
  initialWidth?: string;
  minWidth?: number;
  maxWidth?: number;
  children?: ReactNode;
}
/**
 * Used to give info ListingGrid component about column sizing. Returns the same
 * element that was given to it and adds it additional props.
 * @param {string=} initialWidth - width string in any css format (e.g. rem, px, fr)
 * @param {number=} minWidth - minimal width of item in px, number. Defaults to 50px.
 * @param {number=} maxWidth - maximal width of item in px, number.
 * @param {ReactNode=} children - item children
 * @returns
 */
export const ListingGridItem = (props: Props) => <>{props.children}</>;
