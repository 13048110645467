import { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GroupBase } from 'chakra-react-select';

import { uniqid } from 'app/assets/js/tsutil';
import { OBJ, Primitive } from 'BootQuery/Assets/js/globalTypes';

import { usePortalContainer } from '../Portal/PortalContext';
import { useOptionGetters } from './option-getters';
import { getStyles } from './select-styles';
import { SelectOption, SelectProps } from './types';

export const useSelectProps = <
  ValType extends Primitive = Primitive,
  AdditionalData extends OBJ = OBJ,
  Option extends SelectOption<ValType> = SelectOption<ValType, AdditionalData>,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: SelectProps<ValType, AdditionalData, Option, Group>
  ) => {
  const { current: menuPortalTarget } = usePortalContainer();
  const queryClient = useQueryClient();

  const {
    options,
    onChange,
    value,
    isClearable,
    defaultValue,
    isInvalid,
    size,
    isDisabled,
    queryKey: rawQueryKey,
    placeholder,
    components,
    variant,
  } = props;

  // To ensure we always have a key, if queryKey is not suplied, we generate one
  // useMemo here ensures that once we have a key generated, we keep it
  const queryKey = useMemo(() => {
    return rawQueryKey ?? `Select.${uniqid()}`;
  }, [rawQueryKey]);

  const optionsGetter = useOptionGetters(options);

  const getOptions = useCallback(
    async (search: string) => {
      if (optionsGetter.type === 'async') {
        return optionsGetter.fn(search);
      }

      return optionsGetter.fn(search, optionsGetter.opts);
    },

    [optionsGetter]
  );

  const loadOptions = useCallback(
    async (search: string) => {
      return queryClient.fetchQuery({
        queryFn: () => getOptions(search),
        queryKey: [queryKey, search],
      });
    },
    [queryKey, queryClient, getOptions]
  );

  const { chakraSelectStyles, selectStyles } = useMemo(
    () => getStyles<ValType, AdditionalData, Option, Group>(),
    []
  );

  const defaultProps = useMemo(
    () => ({
      form: '_none',
      menuPortalTarget,
      useBasicStyles: true,
      colorScheme: 'brand',
      selectedOptionColorScheme: 'brand',
      styles: selectStyles,
      chakraStyles: chakraSelectStyles,
      cacheOptions: true,
      defaultOptions: true,
    }),
    [chakraSelectStyles, menuPortalTarget, selectStyles]
  );

  return {
    ...defaultProps,
    loadOptions,
    size: size ?? 'md',
    isClearable: isClearable ?? true,
    value,
    isInvalid: isInvalid ?? false,
    isDisabled: isDisabled ?? false,
    defaultValue: defaultValue ?? null,
    key: queryKey ?? null,
    onChange,
    placeholder: placeholder ?? undefined,
    components: components ?? undefined,
    variant: variant ?? undefined,
  };
};
