import { useMemo } from 'react';

import { useCustomEvents } from './CustomEventsContext';

/**
 * Get custom event types that can be created - those that haven't been deleted.
 *
 * We only soft delete event types and load them so we can show events if they
 * already exist. Alternative is data loss or throwing errors.
 */
export function useCreatableCustomEventTypes() {
  const { customEventTypes } = useCustomEvents();

  return useMemo(() => {
    if (!customEventTypes) {
      return [];
    }

    return customEventTypes.filter((type) => !type.deletedAt);
  }, [customEventTypes]);
}
