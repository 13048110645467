import { Dispatch, ReactElement, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Primitive } from 'BootQuery/Assets/js/globalTypes';

import { RadioButton, RadioButtonGroup } from '../Radio';

interface Props {
  value: boolean;
  onChange: Dispatch<SetStateAction<boolean>>;
}

export const DefaultInput = ({ value, onChange }: Props): ReactElement => {
  const { t } = useTranslation('global');

  const handleChange = useCallback(
    (newVal: Primitive) => {
      onChange(newVal === 'true');
    },
    [onChange]
  );

  return (
    <RadioButtonGroup onChange={handleChange} defaultVal={String(value)}>
      <RadioButton value="true">{t('global:yes')}</RadioButton>
      <RadioButton value="false">{t('global:no')}</RadioButton>
    </RadioButtonGroup>
  );
};
