import { ChangeEvent, useCallback } from 'react';
import { Button, ThemingProps } from '@chakra-ui/react';
import { useDropArea } from 'react-use';

import { FileErrorType, FileInfo } from '../FileInput/types';
import { validateFile } from '../FileInput/util';
import { fixedChakraFactory } from '../fixed-chakra-factory';
import { FileProgress } from './FileProgress';
import { handleUpload } from './handle-upload';
import { SingleFileInputContent } from './SingleFileContent';

type SetFile = (file: FileInfo | null) => void;

export interface SingleFileInputProps extends ThemingProps {
  value: FileInfo | null;
  onChange: SetFile;
  onError?: (value: FileErrorType) => void;
  accept?: string[] | string;
  maxSize?: number;
  isInvalid?: boolean;
}

export const SingleFileInput = ({
  value,
  onChange,
  onError,
  accept,
  maxSize,
  isInvalid,
  ...displayProps
}: SingleFileInputProps) => {
  const acceptStr = Array.isArray(accept) ? accept.join(',') : accept;
  const colorScheme = isInvalid ? 'red' : 'gray';

  const setFile = useCallback(
    (file: File) => {
      const errors = validateFile(file, maxSize ?? null, accept ?? null);
      if (errors) {
        console.warn('File rejected: ', errors, file);
        onError?.(errors);

        return;
      }

      handleUpload(file, onChange);
    },
    [onChange, maxSize, accept, onError]
  );

  const handleDrop = useCallback(
    (newFiles: File[] | null) => {
      if (!newFiles || newFiles.length === 0) {
        return;
      }

      setFile(newFiles[0]);
    },
    [setFile]
  );

  const [bond, { over }] = useDropArea({ onFiles: handleDrop });

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      if (!ev.target.files || ev.target.files.length === 0) {
        return;
      }

      setFile(ev.target.files[0]);
    },
    [setFile]
  );

  return (
    <Button
      w="full"
      position="relative"
      cursor="pointer"
      variant={over ? 'solid' : 'outline'}
      colorScheme={over ? 'blue' : colorScheme}
      {...displayProps}
    >
      <InputOverlay
        type="file"
        accept={acceptStr}
        onChange={handleChange}
        {...bond}
      />
      <SingleFileInputContent file={value} />
      <FileProgress file={value} />
    </Button>
  );
};

/**
 * Input styled so it's overlayed on top of the button-looking display component,
 * but invisible (opacity 0). This makes the clicks go to the `<input type="file" />`
 * which acts like a regular file input, while looking like a pretty button
 */
const InputOverlay = fixedChakraFactory('input', {
  baseStyle: {
    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    cursor: 'pointer',
    opacity: 0,
  },
});
