import { ReactElement, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { getPauseTypeOptions } from './api';
import { CallFormSettingsForm } from './types';

export const PauseTypeField = (): ReactElement => {
  const { control } = useFormContext<CallFormSettingsForm>();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'compulsoryCallFormPause' });

  const valueOption: SelectOption | null = useMemo(() => {
    if (value) {
      return { value, label: value };
    }

    return null;
  }, [value]);

  const handleChange = useCallback(
    (option: SelectOption | null) => {
      onChange(option ? option.value : null);
    },
    [onChange]
  );

  return (
    <Select
      value={valueOption}
      onChange={handleChange}
      options={getPauseTypeOptions}
    />
  );
};
