module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"controller") : depth0), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":67}}}))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"text") : depth0), depth0))
    + "</a>\n";
},"useData":true})