import { makeComboboxFilter } from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { TicketTag } from '../types';

export const ticketFilterTags = makeComboboxFilter({
  toFilter: ({ operator, value }) => {
    if (!value) {
      return null;
    }
    let baseFilter = null;
    if (typeof value === 'string') {
      baseFilter = {
        'tags:$any': { 'tag.tag:contains:ci': value },
      };
    }
    if (typeof value === 'object') {
      baseFilter = {
        'tags:$any': { 'tag.tag:eq:ci': value.tag },
      };
    }

    return operator === 'containsNot' ? { $not: baseFilter } : baseFilter;
  },
  name: () => i18n.t('Ticketing:tags'),
  operators: () => [
    { operator: 'contains', display: i18n.t('global:operators.contains') },
    {
      operator: 'containsNot',
      display: i18n.t('global:operators.contains_not'),
    },
  ],
  extraProps: {
    cacheKey: 'ticketingFilterTags',
    search: async (search: string) => {
      const {
        data: { data },
      } = await Api.get<ListingResponse<TicketTag>>('/api/ticketing/tags', {
        params: {
          filters: search ? { $search: search } : {},
        },
      });

      return data;
    },
    itemToString: (item: TicketTag) => item.tag,
    enableTextSearch: true,
    itemToValue: (item: TicketTag) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<TicketTag>(
        `/api/ticketing/tags/${value.id}`
      );

      return data;
    },
  },
});
