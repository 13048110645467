import { ReactElement, useCallback } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { DeleteButton } from '@bq/components/DeleteButton';

import {
  GeneratedFormProvider,
  useGeneratedFormContext,
} from '../../GeneratedFormContext';
import { GeneratedFormField } from '../../GeneratedFormField';
import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { ListFieldSettings } from './types';

type Props = FieldValue<ListFieldSettings>;

export const ListField = ({ id, settings }: Props): ReactElement => {
  const { t } = useTranslation('global');
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: id,
  });
  const bg = useColorModeValue('brand.lightGray', '#494949');

  const addRow = useCallback(() => {
    append({}, { shouldFocus: true });
  }, [append]);

  return (
    <FormControl>
      <FieldLabel {...settings} />
      {fields.length > 0 && (
        <Box bg={bg} p={2} rounded="md">
          {fields.map((row, idx) => (
            <ListRow
              key={row.id}
              prefix={`${id}.${idx}`}
              idx={idx}
              remove={remove}
              fields={settings.subfields}
            />
          ))}
        </Box>
      )}

      <Flex justifyContent="flex-end" mt={2}>
        <Button
          colorScheme="green"
          bg="#34CB7F"
          onClick={addRow}
          verticalAlign="middle"
          rightIcon={<FaPlus />}
        >
          {t('global:add')}
        </Button>
      </Flex>
    </FormControl>
  );
};

interface ListRowProps {
  prefix: string;
  idx: number;
  remove: UseFieldArrayRemove;
  fields: FieldValue[];
}

const ListRow = ({
  prefix,
  idx,
  fields,
  remove,
}: ListRowProps): ReactElement => {
  const { fieldTypes } = useGeneratedFormContext();
  const onRemove = useCallback(() => {
    remove(idx);
  }, [remove, idx]);
  const dividerColor = useColorModeValue('brand.fontMedium', 'brand.fontLight');

  return (
    <FormControl
      as={HStack}
      w="full"
      mb="0.5"
      py="3"
      alignItems="flex-end"
      borderBottom="1px solid"
      borderColor={dividerColor}
      _last={{ border: 'none' }}
    >
      <GeneratedFormProvider prefix={prefix} fieldTypes={fieldTypes}>
        {fields.map((field) => (
          <GeneratedFormField
            key={field.id}
            prefix={prefix}
            field={field}
            fieldTypes={fieldTypes}
          />
        ))}
      </GeneratedFormProvider>
      <DeleteButton onClick={onRemove} />
    </FormControl>
  );
};
