import { Flex } from '@chakra-ui/react';
import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { CallDestination } from '../CallDestination';
import { useFormattedCall } from '../CallList/format-call';
import { CallParty } from '../CallParty';
import { CallStatus } from '../CallStatus';
import { CallEvent } from './types';

export const CallEventColumn: EventCellComponent<CallEvent> = ({
  row: { data },
}) => {
  const call = useFormattedCall(data);
  const { direction, endAt, answered, destinations, destinationInfo } = call;

  return (
    <Flex>
      <CallParty
        party={call.sourceInfo.source}
        phoneNumber={call.sourceInfo.phoneNumber}
      />
      &nbsp;
      <CallStatus direction={direction} endAt={endAt} answered={answered} />
      &nbsp;
      <CallDestination
        destinations={destinations}
        destinationInfo={destinationInfo}
      />
    </Flex>
  );
};
