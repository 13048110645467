import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { getDataset, updateDataset } from '../Api/datasets';
import { Dataset, UpdateDataset } from '../types';

export function useDataset(datasetID: number): UseQueryResult<Dataset> {
  return useQuery<Dataset>({
    queryKey: ['datasets', datasetID],
    queryFn: () => getDataset(datasetID),
  });
}

export interface UpdateDatasetParams extends UpdateDataset {
  datasetID: number;
}
export type UpdateDatasetMutation = UseMutationResult<
  void,
  unknown,
  UpdateDatasetParams,
  unknown
>;
export function useUpdateDataset(): UpdateDatasetMutation {
  return useMutation({
    mutationKey: ['Datasets.update'],
    mutationFn: ({ datasetID, ...formData }: UpdateDatasetParams) => {
      return updateDataset(datasetID, formData);
    },
  });
}
