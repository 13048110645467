import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getInterestLevels } from '../../api/InterestLevel/getInterestLevels';
import { CaseInterestLevel } from '../../schemas';

export function useInterestLevel(): UseQueryResult<
  ListingResponse<CaseInterestLevel>> {
  return useQuery({
    queryKey: ['Sales.settings.interestLevel'],
    queryFn: () => getInterestLevels(),
  });
}
