import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { Campaign, PostCampaign } from '../../schemas/Campaign';

export const postCampaign = async <T = Campaign>(
  data: PostCampaign,
  params?: GetOneParams
) => {
  const { data: newData } = await Api.post<T>('/api/sales/campaigns', data, {
    params,
  });

  return newData;
};
