import { ReactElement } from 'react';
import { Flex, IconButton, Input, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import TimeField from 'react-simple-timefield';

import { PartialExceptFor } from '@bq/components/type-util';
import { useAutosizingInput } from 'BootQuery/Assets/components/use-autosizing-input';
import i18n from 'BootQuery/Assets/js/i18n';

import { FilterName } from '../FilterName';
import { FilterOperatorInput, FilterTagOperator } from '../FilterOperator';
import { FilterProps, FilterType } from '../types';

interface TimeFilterProps {
  value: string | null;
  onChange: (value: string | null) => void;
  onRemove: () => void;
  isNew?: boolean;
}

const TimeTagInput = ({
  value,
  onChange,
  onRemove,
  isNew,
}: TimeFilterProps) => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');
  const inputElRef = useAutosizingInput(value || '');

  return (
    <Flex background={bgColor} height="8" borderRightRadius="md">
      <TimeField
        value={value || '00:00'}
        onChange={(ev) => onChange(ev.target.value)}
        input={
          <Input
            value={value || '00:00'}
            ref={inputElRef}
            minWidth="4"
            border="none"
            background="none"
            borderLeftRadius={0}
            size="sm"
            autoFocus={isNew}
          />
        }
      />
      <IconButton
        onClick={onRemove}
        variant="link"
        aria-label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};

const TimeFilterInput = ({ value, onChange, onRemove }: TimeFilterProps) => (
  <TimeField
    value={value || '00:00'}
    onChange={(ev) => onChange(ev.target.value)}
    input={<Input value={value || '00:00'} />}
  />
);

export const TimeTag = ({
  name,
  value,
  operator,
  operators,
  onChange,
  onOperatorChange,
  onRemove,
  isNew,
}: FilterProps<string>): ReactElement => (
  <Flex>
    <FilterName name={name} />
    {operators.length > 0 && (
      <FilterTagOperator
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <TimeTagInput
      isNew={isNew}
      value={value as string}
      onChange={onChange as (val: string | null) => void}
      onRemove={onRemove}
    />
  </Flex>
);

export const TimeInput = ({
  value,
  operator,
  operators,
  onChange,
  onOperatorChange,
  onRemove,
}: FilterProps<string>): ReactElement => (
  <>
    {operators.length > 0 && (
      <FilterOperatorInput
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <TimeFilterInput
      value={value as string}
      onChange={onChange as (val: string | null) => void}
      onRemove={onRemove}
    />
  </>
);

type TimeFilter = FilterType<string>;
type TimeUserDef = PartialExceptFor<TimeFilter, 'toFilter' | 'name'>;
export function makeTimeFilter(filterDef: TimeUserDef): TimeFilter {
  return {
    tagComponent: TimeTag,
    inputComponent: TimeInput,
    operators: () => [
      { operator: 'gte', display: i18n.t('global:operators.from') },
      { operator: 'lte', display: i18n.t('global:operators.to') },
    ],
    ...filterDef,
  };
}
