import {
  ForwardedRef,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  StyleProps,
  ThemingProps,
  useColorModeValue,
  useMultiStyleConfig,
  usePopper,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCombobox } from 'downshift';
import { FaChevronDown } from 'react-icons/fa';

interface ComboboxProps<ItemType> {
  getResults: (search: string) => Promise<ItemType[]>;
  itemToString: (item: ItemType) => string;
  onEditEnd?: () => void;
  value: ItemType | string | null;
  onChange: (value: ItemType | string | null) => void;
  onInputValueChange?: (value: string) => void;
  cacheKey: string;
  /** If disabled, user must choose an item from the combobox,
   * partial text search is not allowed. Default is true */
  enableTextSearch?: boolean;
  inputStyle?: StyleProps & ThemingProps<'Input'>;
  inputRef?: ForwardedRef<HTMLInputElement>;
  renderItem?: (item: ItemType & JSX.IntrinsicAttributes) => ReactElement;
  isDisabled?: boolean;
  autoFocus?: boolean;
}

export const Combobox = <ItemType extends JSX.IntrinsicAttributes>({
  getResults,
  itemToString,
  value,
  onChange,
  onEditEnd,
  cacheKey,
  inputStyle,
  autoFocus,
  inputRef: outsideInputRef,
  onInputValueChange,
  renderItem: ItemDisplay,
  isDisabled = false,
  enableTextSearch = true,
}: ComboboxProps<ItemType>): ReactElement => {
  const [searchStr, setSearchStr] = useState<string>('');
  const lastChanged = useRef<'input' | 'select' | null>(null);
  const editEnd = useRef<boolean>(false);

  const { item: itemStyle, list: listStyle } = useMultiStyleConfig('Menu', {});
  const activeBg = useColorModeValue('gray.200', 'whiteAlpha.200');
  const activeStyle = useMemo(
    () => ({
      ...itemStyle,
      bg: activeBg,
    }),
    [itemStyle, activeBg]
  );

  const handleEditEnd = () => {
    if (
      lastChanged.current === 'input' &&
      !editEnd.current &&
      enableTextSearch
    ) {
      onChange(searchStr);
    }
    if (onEditEnd) {
      onEditEnd();
    }
  };

  const { data: results } = useQuery({
    queryKey: [cacheKey, searchStr],
    queryFn: () => getResults(searchStr),

  });
  const items = results ?? [];

  const getStringVal = useCallback(
    (item: ItemType | string | null): string => {
      if (typeof item === 'string') {
        return item;
      }
      if (item === null) {
        return '';
      }

      return itemToString(item);
    },
    [itemToString]
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem,
    openMenu,
  } = useCombobox({
    items,
    selectedItem: value,
    itemToString: getStringVal,
    onInputValueChange: ({ inputValue }) => {
      setSearchStr(inputValue || '');
      lastChanged.current = 'input';
      if (onInputValueChange) {
        onInputValueChange(inputValue ?? '');
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      lastChanged.current = 'select';
      editEnd.current = true;
      onChange(selectedItem || null);
      inputRef.current?.blur();
    },
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          // Don't auto select item on input blur
          return { ...changes, selectedItem: state.selectedItem };
        default:
          return changes; // Otherwise leave default behaviour
      }
    },
  });

  const {
    onBlur: inputOnBlur,
    ref: downshiftInputRef,
    ...inputProps
  } = getInputProps({ ref: inputRef });
  const { popperRef, referenceRef } = usePopper();

  return (
    <Flex direction="column" {...getComboboxProps()} zIndex={4}>
      <InputGroup>
        <Input
          minW="96px"
          {...inputProps}
          ref={(ref) => {
            downshiftInputRef(ref);
            inputRef.current = ref;
            referenceRef(ref);
            if (outsideInputRef) {
              if (typeof outsideInputRef === 'function') {
                outsideInputRef(ref);
              } else {
                outsideInputRef.current = ref;
              }
            }
          }}
          onBlur={(ev) => {
            inputOnBlur(ev);
            // Add a small delay because blur will be fired before
            // click when selecting an item, so this would remove the combobox element
            // before it had a chance to update the selection
            setTimeout(handleEditEnd, 100);
          }}
          onFocus={openMenu}
          isDisabled={isDisabled}
          autoFocus={autoFocus}
          {...inputStyle}
        />
        <InputRightElement h="100%">
          <FaChevronDown />
        </InputRightElement>
      </InputGroup>
      <Box ref={popperRef} display={isOpen ? undefined : 'none'}>
        <List {...getMenuProps()} sx={listStyle}>
          {items.map((item, index) => {
            const isActive = highlightedIndex === index;

            return (
              <ListItem
                key={index}
                {...getItemProps({
                  item,
                  index,
                  onMouseDown: () => {
                    selectItem(item);
                  },
                })}
                cursor="pointer"
                textAlign="left"
                sx={isActive ? activeStyle : itemStyle}
              >
                {ItemDisplay ? <ItemDisplay {...item} /> : itemToString(item)}
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Flex>
  );
};
