import {
  clone,
  endsWith,
  reduce,
  filter,
  isObjectLike,
  mapKeys,
  merge,
  lowerFirst,
  get,
  escape,
} from 'lodash-es';
import flatpickr from 'flatpickr';
import Inputmask from 'inputmask';

import {
  formatNumber,
  numberToE164,
  isInsideLinkOrButton,
  formatFileSize,
  parseQueryString,
  parseURL,
  sleep,
} from './tsutil';

export {
  formatNumber,
  numberToE164,
  isInsideLinkOrButton,
  formatFileSize,
  parseQueryString,
  parseURL,
  sleep,
};

export function handlebarsRender(template, data) {
  try {
    data = clone(data);
    if (typeof template !== 'function') {
      throw new Error(`Can only render pre-compiled templates. Tried to render: ${template}`);
    }

    if (template.$moduleName) {
      if (!data) {
        data = {};
      }
      data.$moduleName = template.$moduleName;
    }
    return template(data);
  } catch (err) {
    console.error('Failed to render template for', data, err);
    throw err;
  }
}

$.fn.findElement = function findElement(selector) {
  return this.filter(selector).add(this.find(selector));
};

$.fn.contenteditableChangeEvent = function contenteditableChangeEvent() {
  this.each((_index, element) => {
    $(element)
      .on('focus', () => {
        $(element).data('contenteditablePrev', $(element).html());
      })
      .on('blur', () => {
        if ($(element).html() !== $(element).data('contenteditablePrev')) {
          $(element).trigger('change');
        }
      });
  });
  return this;
};

$.fromHTML = (html) => $(parseHTML(html));
$.render = (template, data) => $.fromHTML(handlebarsRender(template, data).trim());

$.fn.ev = function ev(...args) {
  const offArgs = args.slice(0, -1);
  return this.off(...offArgs).on(...args);
};

export function activateFlatpickr(el, options) {
  const defaultOptions = {
    // Hacky way to update default day when date changes
    onOpen(_selectedDates, _dateStr, instance) {
      const realNow = new Date();
      if (compareDates(instance.now, realNow) !== 0) {
        instance.now = realNow;
        instance.redraw();
      }
    },
  };
  const shownFormat = options.altFormat || options.dateFormat;
  const dp = flatpickr(el, { ...defaultOptions, ...options });
  // eslint-disable-next-line no-underscore-dangle
  dp._input.addEventListener('input', () => {
    // eslint-disable-next-line no-underscore-dangle
    const { value } = dp._input;
    const parsedDate = dp.parseDate(value, shownFormat);
    const formattedDate = dp.formatDate(parsedDate, shownFormat);
    if (value === formattedDate) {
      dp.setDate(value, true, shownFormat);
    }
  }, true);
  if (options.altFormat) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'disabled') {
          dp.altInput.disabled = dp.element.disabled;
        }
      });
    });
    observer.observe(dp.element, { attributes: true });
  }

  return dp;
}

// Date and time pickers
$.fn.activateDateTimePicker = function activateDateTimePicker() {
  return $(this).each((_i, el) => {
    const element = $(el);

    if (element.is('.datepicker')) {
      const dp = activateFlatpickr(element[0], {
        dateFormat: 'Y-m-d',
        altFormat: 'd.m.Y.',
        altInput: true,
        allowInput: true,
      });
      Inputmask('datetime', {
        inputFormat: 'dd.mm.yyyy.',
        seperator: '.',
        placeholder: '__.__.____.',
        clearIncomplete: true,
      }).mask(dp._input); // eslint-disable-line no-underscore-dangle
    } else if (element.is('.datetimepicker')) {
      const dp = activateFlatpickr(element[0], {
        dateFormat: 'Z',
        altFormat: 'd.m.Y. H:i',
        altInput: true,
        allowInput: true,
        enableTime: true,
        time_24hr: true,
      });
      Inputmask('datetime', {
        inputFormat: 'dd.mm.yyyy. HH:MM',
        seperator: '.',
        placeholder: '__.__.____. __:__',
        clearIncomplete: true,
      }).mask(dp._input); // eslint-disable-line no-underscore-dangle
    } else if (element.is('.timepicker') || element.is('.durationpicker')) {
      const input = element.findElement('input').get(0);
      if (input) {
        Inputmask('datetime', {
          inputFormat: 'HH:MM:ss',
          seperator: ':',
          placeholder: '__:__:__',
          clearIncomplete: true,
        }).mask(input);
      }
    }
  });
};

export function htmlDecode(input) {
  const e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function secToTime(sec) {
  const secNum = parseInt(sec, 10); // don't forget the second param
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  // var time    = hours+':'+minutes+':'+seconds;
  const time = `${minutes}:${seconds}`;
  return time;
}

export function getParams(url) {
  return parseURL(url).paramsObj;
}

export function getURLComponents(url) {
  if (!url) {
    url = document.location.href;
  }
  const parsedURL = parseURL(url);
  const pathParts = filter(parsedURL.pathname.split('/'));

  return {
    controller: pathParts[0],
    method: pathParts.slice(1).join('/'),
    parameters: parsedURL.paramsObj,
    hash: parsedURL.hash,
  };
}

export function isFunction(functionToCheck) {
  const getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
}

export function formatTime(time) {
  let hours = Math.floor(time / 3600);
  let mins = Math.floor(time / 60);
  let secs = Math.floor(time - 60 * mins);

  if (hours < 10) { hours = `0${hours}`; }
  if (mins < 10) { mins = `0${mins}`; }
  if (secs < 10) { secs = `0${secs}`; }

  return `${hours}:${mins}:${secs}`;
}

export function hasProperty(propertyHolder, propertyString) {
  if (typeof propertyHolder === 'function' || typeof propertyHolder === 'object') {
    let obj = propertyHolder;
    const parts = propertyString.split('.');
    let i;
    for (i = 0; i < parts.length; i++) {
      if (typeof obj[parts[i]] !== 'undefined') {
        obj = obj[parts[i]];
      } else {
        break;
      }
    }

    if (i === parts.length) {
      return true;
    }
  }

  return false;
}

export function getOwnProperty(obj, path) {
  if (typeof obj !== 'function' && typeof obj !== 'object') {
    return null;
  }
  let parts = path;
  if (typeof parts === 'string') {
    parts = path.split('.');
  }
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (part in obj) {
      obj = obj[part];
    } else {
      return null;
    }
  }
  return obj;
}

export function setOwnProperty(obj, path, value) {
  if (typeof obj !== 'function' && typeof obj !== 'object') {
    return;
  }
  let parts = path;
  if (typeof parts === 'string') {
    parts = path.split('.');
  }
  const lastPart = parts.pop();
  let currentObj = obj;
  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (!currentObj[part]) {
      currentObj[part] = {};
    }
    currentObj = currentObj[part];
  }
  currentObj[lastPart] = value;
}

export function generateQueryString() {
  let queryString = '';

  queryString += `/${lowerFirst(window.Bootstrap.bootquery.controller)}`;
  queryString += `/${lowerFirst(window.Bootstrap.bootquery.method)}`;
  queryString += `/?${window.Bootstrap.bootquery.paramstr}`;

  return queryString;
}

// Stolen from: http://stackoverflow.com/a/38760984
export function microtime(getAsFloat) {
  let now;
  let multiplier;

  if (typeof performance !== 'undefined' && performance.now) {
    now = (performance.now() + performance.timing.navigationStart) / 1000;
    multiplier = 1e6; // 1,000,000 for microseconds
  } else {
    now = (Date.now ? Date.now() : new Date().getTime()) / 1000;
    multiplier = 1e3; // 1,000
  }

  // Getting microtime as a float is easy
  if (getAsFloat) {
    return now;
  }

  // Dirty trick to only get the integer part
  const s = now | 0; // eslint-disable-line no-bitwise
  return `${Math.round((now - s) * multiplier) / multiplier} ${s}`;
}

export function toUnderscoreCase(camelCase) {
  return camelCase.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`).replace(/^_/, '');
}

export function splitNestedInputName(inputName) {
  const nameParts = inputName.split(/[[\]]{1,2}/);
  if (nameParts.length > 1) {
    nameParts.splice(-1, 1);
  }
  return nameParts;
}

export function arrayToPath(args) {
  let flat = [];
  $.each(args, (i, arg) => {
    flat = flat.concat(arg);
  });
  const firstArg = flat.shift();
  const wrapped = $.map(flat, (arg) => `[${arg}]`);
  wrapped.unshift(firstArg);
  return wrapped.join('');
}

export function flattenFormData(formData) {
  return reduce(
    formData,
    (result, value, key) => {
      if (isObjectLike(value)) {
        let flat = flattenFormData(value);
        const splitKey = splitNestedInputName(key);
        flat = mapKeys(flat, (value, flatKey) => {
          const fullPath = splitKey.concat(splitNestedInputName(flatKey));
          return arrayToPath(fullPath);
        });
        result = merge(result, flat);
      } else {
        result[key] = value;
      }
      return result;
    },
    {},
  );
}

export function intersectionFromStart(arr1, arr2) {
  const shorterLength = Math.min(arr1.length, arr2.length);
  let lastMatched = -1;
  for (let i = 0; i < shorterLength; i++) {
    if (arr1[i] !== arr2[i]) {
      break;
    }
    lastMatched = i;
  }
  const ret = {
    commonMatch: [],
    arr1Remaining: clone(arr1),
    arr2Remaining: clone(arr2),
  };

  if (lastMatched !== -1) {
    ret.commonMatch = arr1.slice(0, lastMatched + 1);
    ret.arr1Remaining = arr1.slice(lastMatched + 1);
    ret.arr2Remaining = arr2.slice(lastMatched + 1);
  }
  return ret;
}

export function parsePartialName(partialName) {
  const parsed = {
    name: partialName,
    module: null,
    moduleIsMandatory: true,
  };
  const parts = partialName.split('.');
  if (parts.length == 2) {
    [parsed.module, parsed.name] = parts;
    if (endsWith(parsed.module, '?')) {
      parsed.module = parsed.module.substr(0, parsed.module.length - 1);
      parsed.moduleIsMandatory = false;
    }
  }
  return parsed;
}

export function popoverForTrigger(triggerEl) {
  return $($(triggerEl).data('bs.popover').tip);
}

export function parseHTML(html) {
  const template = document.createElement('template');
  template.innerHTML = html;
  return template.content.childNodes;
}

export function wsUrlBase() {
  const siteProto = window.location.protocol.match(/(.+):/)[1];
  const wsProto = siteProto === 'https' ? 'wss' : 'ws';
  const wsHost = window.location.host;
  return `${wsProto}://${wsHost}`;
}

/**
 * A really simple template function, replaces placeholders in format {placeholder} with
 * values on that key from context
 * @param {string} template String template. Placeholders in form of {placeholder}
 * @param {object} context Object with context containing key-value placeholders
 *
 * @returns {string} Template with replaced placeholders
 */
export function microTemplate(template, context) {
  return template.replace(
    /{(.+?)}/g,
    (_fullVar, varName) => get(context || {}, varName, '') || ''
  );
}

export function escapeHtml(strings, ...expressions) {
  return strings.reduce((out, str, i) => {
    const expr = expressions[i];
    return out + str + escape(expr || '');
  }, '');
}

export function printPHPDebugs(data) {
  /* eslint-disable no-underscore-dangle */
  if (!data || !data._php_debug) {
    return;
  }

  data._php_debug.forEach((debug) => {
    console.log(
      `%c[PHP] ${debug.file}:${debug.line}: `,
      'font-weight: bold; color: #777BB4;',
      ...debug.content,
    );
  });
}

export function isIframed() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Takes a GUID in the format {g-u-i-d} and returns it without "{" and "}"
 * @example
 * cleanGUID('{00000000-0000-0000-0000-000000000000}');
 * // Returns '00000000-0000-0000-0000-000000000000'
 * @param {string} guid GUID string, optionally inclosed in "{" and "}"
 * @returns {string} GUID with "{" and "}" braces trimmed
 */
export function cleanGUID(guid) {
  return guid.replace(/^{(.+)}$/, '$1');
}

export function compareDates(date1, date2, timeless = true) {
  if (timeless !== false) {
    return (
      new Date(date1.getTime()).setHours(0, 0, 0, 0)
			- new Date(date2.getTime()).setHours(0, 0, 0, 0)
    );
  }

  return date1.getTime() - date2.getTime();
}

/**
 * Removes leading and trailing "/" from path
 * @param {string} path Path to trim
 * @returns {string} Path without leading or trailing "/"
 */
export function trimPath(path) {
  return path.replace(/^[/]|[/]$/g, '');
}
