import { Badge, BadgeProps } from '@chakra-ui/react';
import i18n from 'BootQuery/Assets/js/i18n';
import { ChangeType } from '../SettingsCRUD';

interface Props extends BadgeProps {
  type?: ChangeType;
}
export const ChangeBadge = ({ type, ...props }: Props) => {
  if (type) {
    const info = getInfoForBadge(type);

    return (
      <Badge colorScheme={info.color} {...props}>
        {info.name()}
      </Badge>
    );
  }

  return <></>;
};

export const getInfoForBadge = (type: 'add' | 'upd' | 'del') => {
  switch (type) {
    case 'add':
      return {
        name: () => i18n.t('global:item_crud_status.new'),
        color: 'green',
      };
    case 'del':
      return {
        name: () => i18n.t('global:item_crud_status.deleted'),
        color: 'red',
      };
    case 'upd':
      return {
        name: () => i18n.t('global:item_crud_status.edited'),
        color: 'yellow',
      };
    default:
      return {
        name: () => i18n.t('global:item_crud_status.new'),
        color: 'green',
      };
  }
};
