import { Box, Heading, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileImport } from 'react-icons/fa';
import { Outlet, useParams } from 'react-router-dom';

import { Card, CardHeader } from '@bq/components/Card';
import { PageWrapper } from '@bq/components/PageWrapper';

import { ImportStepper } from './ImportStepper';
import { ImportRouteParams, ImportStepId } from './types';

export const DatasetImportLayout = () => {
  const { t } = useTranslation('Datasets');
  const step = useStep();

  return (
    <PageWrapper>
      <Card>
        <CardHeader as={HStack} spacing="8">
          <Heading
            as="h2"
            fontSize="lg"
            mb="0"
            textAlign="center"
            flex="0 1 auto"
          >
            <FaFileImport style={{ display: 'inline' }} />
            &nbsp; {t('Datasets:dataset_import')}
          </Heading>
          <Box flex="1 1 auto">
            <ImportStepper step={step} />
          </Box>
        </CardHeader>
        <Outlet />
      </Card>
    </PageWrapper>
  );
};

function useStep(): ImportStepId {
  const { uploadId, taskId } = useParams<ImportRouteParams>();

  if (taskId) {
    return 'import';
  }

  if (uploadId) {
    return 'setup';
  }

  return 'upload';
}
