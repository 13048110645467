import { useCallback, useMemo } from 'react';
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';
import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { BackgroundTask, TaskState, UnknownData } from './types';

export function useBackgroundTask<T = UnknownData>(
  taskId: string
): UseQueryResult<BackgroundTask<T>> {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ['backgroundTasks', taskId], [taskId]);

  const query = useQuery({
    queryKey,
    queryFn: () => getBackgroundTask<T>(taskId),
  });

  const handleState = useCallback(
    (ev: TaskState<T>) => {
      queryClient.setQueryData(queryKey, (prev?: BackgroundTask<T>) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          status: ev.status,
          currentState: ev,
        };
      });
    },
    [queryClient, queryKey]
  );

  useSocketEvents(
    backgroundTasksListener(),
    `backgroundTask_state_${taskId}`,
    handleState
  );

  return query;
}

async function getBackgroundTask<T = UnknownData>(
  taskId: string
): Promise<BackgroundTask<T>> {
  const { data } = await Api.get(`/api/backgroundTasks/${taskId}`);

  return data;
}

const backgroundTasksListener = makeListener('backgroundTasks');
