import { ReactElement, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CardBody, CardFooter } from '@bq/components/Card';

import { useStartImport } from '../../components/Api/import';
import { ImportSetup } from '../../components/ImportSetup';
import { SubmitNextButton } from '../../components/SubmitNextButton';
import { ImportConfig, ImportRouteParams } from '../../components/types';
import { useCardBodyProps } from '../../components/use-card-body-props';

export const CreateSetup = (): ReactElement => {
  const { uploadId } = useParams<ImportRouteParams>();
  const [searchParams] = useSearchParams();
  const startImport = useStartImport();
  const navigate = useNavigate();

  if (!uploadId) {
    throw new Error('Missing uploadId route param');
  }

  const handleSubmit = useCallback(
    (data: ImportConfig) => {
      startImport.mutate(data, {
        onSuccess: (taskId) => {
          navigate(
            `/datasets/create/import/${data.uploadId}/progress/${taskId}`
          );
        },
      });
    },
    [navigate, startImport]
  );

  return (
    <>
      <CardBody {...useCardBodyProps()} px={0}>
        <ImportSetup
          id="dataset-upload-form"
          uploadId={uploadId}
          defaultName={searchParams.get('name')}
          onSubmit={handleSubmit}
        />
      </CardBody>
      <CardFooter display="flex" justifyContent="end">
        <SubmitNextButton
          form="dataset-upload-form"
          isLoading={startImport.isLoading}
        />
      </CardFooter>
    </>
  );
};
