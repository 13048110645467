import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterIsAssigned = makeBooleanFilter({
  toFilter: ({ value }) => {
    return { 'assignedToUserID:null': !value };
  },
  name: () => i18n.t('Ticketing:is_assigned'),
  extraProps: {},
});
