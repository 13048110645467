import {
  useMemo,
  useContext,
  ReactNode,
  ReactElement,
  createContext,
} from 'react';
import { EnabledRecordingIndicators } from './types';

export interface ICallListSettings {
  useUglyIcons: boolean;
  enabledRecordingIndicators: EnabledRecordingIndicators;
}

export const CallListSettingsContext = createContext<ICallListSettings | null>(
  null
);

interface Props extends ICallListSettings {
  children?: ReactNode | undefined;
}

export function useCallListSettings(): ICallListSettings {
  const ctx = useContext(CallListSettingsContext);
  if (!ctx) {
    throw new Error('Missing CallListSettingsContext');
  }

  return ctx;
}

export const CallListSettingsProvider = ({
  useUglyIcons,
  enabledRecordingIndicators,
  children,
}: Props): ReactElement => {
  const value = useMemo(
    () => ({ useUglyIcons, enabledRecordingIndicators }),
    [useUglyIcons, enabledRecordingIndicators]
  );

  return (
    <CallListSettingsContext.Provider value={value}>
      {children}
    </CallListSettingsContext.Provider>
  );
};
