import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

interface PostActionPriceReasonParams {
  data: { name: string};
  params?: GetOneParams;
}
export const postActionPriceReason = async <ReturnDataType = { ID: number }>({
  data,
  params,
}: PostActionPriceReasonParams) => {
  const { data: apiData } = await Api.post<ReturnDataType>(
    '/api/actionPriceReasons',
    data,
    { params }
  );

  return apiData;
};
