module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/phonebook/people/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"person") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\">\n                <fa class=\"fa fa-id-card\"></fa>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"person") : depth0)) != null ? lookupProperty(stack1,"fullName") : stack1), depth0))
    + "\n            </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/phonebook/modifyCompanyLocation/?locationID="
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"locationID") : stack1), depth0))
    + "\">\n                <fa class=\"fa fa-building\"></fa>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/phonebook/companies/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"company") : depth0)) != null ? lookupProperty(stack1,"ID") : stack1), depth0))
    + "\">\n                <fa class=\"fa fa-building\"></fa>&nbsp;"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"company") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n            </a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <strong>\n            <span class=\"fas fa-building\"></span>\n            Tvrtka:\n        </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"person") : depth0)) != null ? lookupProperty(stack1,"companyID") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <br>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/phonebook/companies/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"person") : depth0)) != null ? lookupProperty(stack1,"companyID") : stack1), depth0))
    + "\">\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"person") : depth0)) != null ? lookupProperty(stack1,"companyName") : stack1), depth0))
    + "\n            </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            -\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <strong>\n            <span class=\"fas fa-building\"></span>\n            Tvrtka:\n        </strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"companyID") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":47,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n    <br>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"/phonebook/companies/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"companyID") : stack1), depth0))
    + "\">\n                "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0)) != null ? lookupProperty(stack1,"companyName") : stack1), depth0))
    + "\n            </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <h5>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"person") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"company") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":17,"column":15}}})) != null ? stack1 : "")
    + "    </h5>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"person") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"companyLocation") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":4},"end":{"line":50,"column":11}}})) != null ? stack1 : "")
    + "    <a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-phoneNumber_like_alnum="
    + alias3(lookupProperty(helpers,"urlencode").call(alias1,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":51,"column":158},"end":{"line":51,"column":183}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n        <span class=\"fa fa-filter\"></span>\n        &nbsp;"
    + alias3(lookupProperty(helpers,"tr").call(alias1,"Calls:button.show_calls_from_to_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":53,"column":14},"end":{"line":53,"column":62}}}))
    + "\n    </a>\n    <a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-callerPhoneNumber_like_alnum="
    + alias3(lookupProperty(helpers,"urlencode").call(alias1,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":55,"column":164},"end":{"line":55,"column":189}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n        <span class=\"fa fa-filter\"></span>\n        &nbsp;"
    + alias3(lookupProperty(helpers,"tr").call(alias1,"Calls:button.show_calls_from_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":57,"column":14},"end":{"line":57,"column":59}}}))
    + "\n    </a>\n    <a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-calleePhoneNumber_like_alnum="
    + alias3(lookupProperty(helpers,"urlencode").call(alias1,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":59,"column":164},"end":{"line":59,"column":189}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n        <span class=\"fa fa-filter\"></span>\n        &nbsp;"
    + alias3(lookupProperty(helpers,"tr").call(alias1,"Calls:button.show_calls_to_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":61,"column":14},"end":{"line":61,"column":57}}}))
    + "\n    </a>\n	<br>\n	<button class=\"btn btn-success btn-block text-center clickvox-dial-button\" data-number=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0), depth0))
    + "\">\n		<span class=\"fa fa-phone\"></span> "
    + alias3(lookupProperty(helpers,"tr").call(alias1,"Dialer:button.call",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":65,"column":36},"end":{"line":65,"column":63}}}))
    + " "
    + alias3(lookupProperty(helpers,"format_number").call(alias1,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":65,"column":64},"end":{"line":65,"column":93}}}))
    + "\n	</button>\n</div>\n";
},"usePartial":true,"useData":true})