module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"format_time").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"context") : depth0),(depth0 != null ? lookupProperty(depth0,"format") : depth0),(depth0 != null ? lookupProperty(depth0,"sourceFormat") : depth0),{"name":"format_time","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":47}}}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"format_time").call(alias1,(depth0 != null ? lookupProperty(depth0,"context") : depth0),lookupProperty(helpers,"tr").call(alias1,"format.time",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":44}}}),(depth0 != null ? lookupProperty(depth0,"sourceFormat") : depth0),{"name":"format_time","hash":{},"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":4,"column":59}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"format") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "");
},"useData":true})