import { CasesBulkFormModalBody } from './CasesBulkFormModalBody';
import { CasesBulkFormModalFooter } from './CasesBulkFormModalFooter';
import { CasesBulkFormModalHeader } from './CasesBulkFormModalHeader';
import { CasesBulkFormWrapper } from './CasesBulkFormWrapper';
import { CasesBulkFormWrapperProps } from './types';

export const CasesBulkModalContent = (props: CasesBulkFormWrapperProps) => {
  return (
    <CasesBulkFormWrapper {...props}>
      <CasesBulkFormModalHeader />
      <CasesBulkFormModalBody />
      <CasesBulkFormModalFooter />
    </CasesBulkFormWrapper>
  );
};
