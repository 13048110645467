import { ReactElement } from 'react';
import {
  Heading,
  HStack,
  LinkBox,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ImArrowRight2 } from 'react-icons/im';
import { Link as RouterLink } from 'react-router-dom';

import { Card, CardBody, CardHeader } from '@bq/components/Card';
import { PageWrapper } from '@bq/components/PageWrapper';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { useReportsByCategory } from '../components/use-available-reports';

export const ReportList = (): ReactElement => {
  const { t } = useTranslation('Reporting');
  useAppTitle(t('Reporting:reports_title'));
  const nameColor = useColorModeValue('black', 'white');
  const bodyColor = useColorModeValue('#F5F5F5', '#393939');
  const hoverColor = useColorModeValue('gray.200', 'brand.mediumGray');
  const groupedReports = Object.entries(useReportsByCategory());

  return (
    <PageWrapper>
      <Heading>{t('Reporting:reports')}</Heading>
      <VStack alignItems="stretch" spacing="5">
        {groupedReports.map(([group, reports]) => (
          <Card key={group}>
            <CardHeader>{group}</CardHeader>
            <CardBody>
              <VStack alignItems="left">
                {reports.map((report) => (
                  <LinkBox
                    key={report.key}
                    _hover={{ textDecoration: 'none', color: nameColor }}
                    color={nameColor}
                    display="block"
                    width="full"
                    as={RouterLink}
                    to={`/reporting/reports/${report.key}`}
                  >
                    <HStack
                      _hover={{ bg: hoverColor }}
                      boxShadow=" rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                      borderRadius="5px"
                      p={5}
                      h={7}
                      bg={bodyColor}
                      justifyContent="space-between"
                    >
                      <Text as="span">{report.title}</Text>
                      <ImArrowRight2 />
                    </HStack>
                  </LinkBox>
                ))}
              </VStack>
            </CardBody>
          </Card>
        ))}
      </VStack>
    </PageWrapper>
  );
};
