import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterIsResolved = makeBooleanFilter({
  name: () => i18n.t('Ticketing:is_resolved'),
  toFilter: ({ value }) => {
    return {
      'state.isResolved': value,
    };
  },
});
