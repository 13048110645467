module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"nav-item notifications-menu-item\">\n    <div class=\"dropdown-menu dropdown-menu-right p-0\">\n        <div class=\"notifications-menu\">\n            <div class=\"spinner text-center w-100 p-3\">\n                <span class=\"fa fa-spinner fa-spin\"></span>\n            </div>\n            <div class=\"notifications card w-100\" hidden>\n                <div class=\"card-header d-flex\">\n                    <div class=\"py-1\">\n                        <span class=\"fa fa-bell\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.notifications",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":57},"end":{"line":10,"column":85}}}))
    + "\n                    </div>\n                    <button type=\"button\" class=\"btn btn-sm btn-secondary ml-auto mark-all-as-seen-button\" disabled>\n                        "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.mark_all_as_seen",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":56}}}))
    + "\n                    </button>\n                </div>\n                <div class=\"list-group list-group-flush notification-list\">\n                    <div class=\"no-notifications-indicator text-center p-3\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.no_notifications",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":76},"end":{"line":17,"column":107}}}))
    + "</div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <a href=\"#\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\">\n        <span class=\"icon-with-badge\">\n            <span class=\"fa fa-bell\"></span>\n            <span class=\"badge badge-danger unseen-notification-counter\" hidden>0</span>\n        </span>\n    </a>\n</li>";
},"useData":true})