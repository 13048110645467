import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  GetOneParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { DatasetEntryListItem } from '../DatasetEntriesList/schema';
import { IDatasetEntryForm, UpdateDatasetEntry } from '../types';

export type ListDatasetEntriesResponse = ListingResponse<DatasetEntryListItem>;

export async function listDatasetEntries(
  datasetID: number,
  params: GetListingParams
): Promise<ListDatasetEntriesResponse> {
  const { data } = await Api.get<ListDatasetEntriesResponse>(
    `/api/datasets/datasets/${datasetID}/entries`,
    { params }
  );

  return data;
}

export async function getDatasetEntry<
  D extends Partial<DatasetEntryListItem> = DatasetEntryListItem,
>(datasetID: number, entryID: number, params: GetOneParams = {}): Promise<D> {
  const { data } = await Api.get<D>(
    `/api/datasets/datasets/${datasetID}/entries/${entryID}`,
    {
      params,
    }
  );

  return data;
}

export async function createDatasetEntry(
  datasetID: number,
  dataset: IDatasetEntryForm
): Promise<number> {
  const { data } = await Api.post<{ ID: number }>(
    `/api/datasets/datasets/${datasetID}/entries`,
    dataset
  );

  return data.ID;
}

export async function updateDatasetEntry(
  datasetID: number,
  entryID: number,
  changes: UpdateDatasetEntry
): Promise<void> {
  await Api.patch(
    `/api/datasets/datasets/${datasetID}/entries/${entryID}`,
    changes
  );
}

export async function deleteDatasetEntry(
  datasetID: number,
  entryID: number
): Promise<void> {
  await Api.delete(`/api/datasets/datasets/${datasetID}/entries/${entryID}`);
}
