import { mapValues } from 'lodash-es';

import type { Modifications } from '@bq/components/SettingsCRUD';

import { TicketPriority } from '../../types';
import {
  TicketPriorityForm,
  TicketPriorityFormBase,
  TicketPriorityInterval,
} from './types';

export function priorityToForm(
  priority: TicketPriority
): TicketPriorityFormBase {
  return {
    ID: priority.ID,
    name: priority.name,
    responseInterval: priority.responseInterval
      ? secsToInterval(priority.responseInterval)
      : null,
    resolutionInterval: priority.resolutionInterval
      ? secsToInterval(priority.resolutionInterval)
      : null,
  };
}

export function formToPriority(form: TicketPriorityFormBase): TicketPriority {
  return {
    ID: form.ID as number,
    name: form.name,
    responseInterval: form.responseInterval
      ? intervalToSecs(form.responseInterval)
      : null,
    resolutionInterval: form.resolutionInterval
      ? intervalToSecs(form.resolutionInterval)
      : null,
  };
}

export function modificationsForBackend(
  modifications: Modifications<TicketPriorityForm>
): Modifications<TicketPriority> {
  return {
    add: modifications.add.map(formToPriority),
    upd: mapValues(
      modifications.upd as Record<string, TicketPriorityForm>,
      formToPriority
    ),
    del: modifications.del as number[],
  };
}

export function intervalToSecs(interval: TicketPriorityInterval): number {
  const days = maybeParseInt(interval.days);
  const hours = maybeParseInt(interval.hours);
  const minutes = maybeParseInt(interval.minutes);

  return days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60;
}

export function secsToInterval(secs: number): TicketPriorityInterval {
  return {
    days: Math.floor(secs / (24 * 60 * 60)),
    hours: Math.floor((secs % (24 * 60 * 60)) / (60 * 60)),
    minutes: Math.floor((secs % (60 * 60)) / 60),
  };
}

function maybeParseInt(value: string | number | null): number {
  if (!value) {
    return 0;
  }

  if (typeof value === 'string') {
    const parsed = parseInt(value, 10);

    return Number.isNaN(parsed) ? 0 : parsed;
  }

  return value;
}
