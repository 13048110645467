import { ReactElement, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ContactItem, ContactSelect } from '@bq/components/FormFields';

import { CallFormForm } from './types';

interface Props {
  phoneNumber: string;
}

export const ContactInput = ({ phoneNumber }: Props): ReactElement => {
  const { control } = useFormContext<CallFormForm>();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'contact' });

  const {
    field: { onChange: setPhoneNumber },
  } = useController({ control, name: 'phoneNumber' });

  const handleChange = useCallback(
    (value: ContactItem | null) => {
      console.log('Change: ', value, phoneNumber);
      setPhoneNumber(phoneNumber);
      onChange(value);
    },
    [setPhoneNumber, onChange, phoneNumber]
  );

  return (
    <ContactSelect
      createMode="saveOnApiModal"
      hasDetails={false}
      isClearable
      isMulti={false}
      onChange={handleChange}
      value={value}
      allowedTypes={{
        company: true,
        companyLocation: true,
        person: true,
        user: false,
        companyDepartment: true,
      }}
    />
  );
};
