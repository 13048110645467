import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { Campaign } from '../../schemas';

export async function getManyCampaign(params?: GetListingParams) {
  const { data } = await Api.get<ListingResponse<Campaign>>(
    '/api/sales/campaigns',
    { params }
  );

  return data;
}
