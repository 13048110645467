import {
  filterExpression,
  FilterExpression,
} from 'BootQuery/Assets/components/FilterBar';
import { filterTypesWithGroup } from 'BootQuery/Assets/components/FilterBar/filters/GroupFilter';
import { Api } from 'BootQuery/Assets/js/api';
import { filterTypes } from '../message-filter-types';
import { ApiPermissionsValue, PermissionsValue } from './types';

interface InputPermissionsValue {
  filters: PermissionsValue;
  filterExpression: FilterExpression[] | null;
}

export async function getCurrentPermissions<T>(
  groupID: number | string
): Promise<T> {
  const saved = document.querySelector<HTMLInputElement>(
    'input[name="smsPermissions[messageVisibility]"]'
  );
  if (saved) {
    return JSON.parse(saved.value) as T;
  }

  const { data } = await Api.get<T>(
    `/api/sms/permissions/${groupID}/messageVisibility`
  );

  return data;
}

type PermissionsRet = ApiPermissionsValue | InputPermissionsValue;
export async function getCurrentFilterPermissions(
  groupID: number | string
): Promise<PermissionsValue> {
  const permissions = await getCurrentPermissions<PermissionsRet>(groupID);

  if (
    permissions &&
    typeof permissions === 'object' &&
    'filters' in permissions
  ) {
    return permissions.filters;
  }

  return permissions;
}

function valueToFilterExpression(
  value: PermissionsValue
): Promise<FilterExpression[] | null> {
  if (value === 'none') {
    return Promise.resolve(null);
  }

  return filterExpression(filterTypesWithGroup(filterTypes), value, '');
}

export function setInputValue(value: unknown) {
  const name = 'smsPermissions[messageVisibility]';
  let el = document.querySelector<HTMLInputElement>(`input[name="${name}"]`);
  if (!el) {
    el = document.createElement('input');
    el.type = 'hidden';
    el.name = name;
    document.querySelector('#group-modify-container')?.append(el);
  }

  el.value = JSON.stringify(value);
}

export async function setFilterInputValue(filters: PermissionsValue) {
  const filterExpression = await valueToFilterExpression(filters);

  const inputVal: InputPermissionsValue = { filters, filterExpression };
  setInputValue(inputVal);
}
