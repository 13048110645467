import { CampaignFormModalContent } from './CampaignFormModalContent';
import { CampaignFormWrapper } from './CampaignFormWrapper';
import { CampaignFormWrapperProps } from './types';

export const CampaignModalForm = (props: CampaignFormWrapperProps) => {
  return (
    <CampaignFormWrapper {...props}>
      <CampaignFormModalContent />
    </CampaignFormWrapper>
  );
};
