import { useGlobalHotkey } from 'BootQuery/Assets/js/use-global-hotkey';
import { useCallback } from 'react';
import { Api } from 'BootQuery/Assets/js/api';
import { routeEvents } from 'BootQuery/Assets/js/route-events';
import { softphoneState } from './store/softphone';
import { useCurrentCall } from './hooks';
import { useDialerContext } from './DialerContext';
import { useSoftphoneEvent } from './store/softphone-events';
import { dialerStore } from './store/pbx';

export function useAutoPreset() {
  const currentCall = useCurrentCall();
  const { input, submitInput } = useDialerContext();
  const hasInput = input.length > 0;

  const endCall = useCallback(() => {
    if (!currentCall) {
      return;
    }

    if (currentCall.type === 'softphone') {
      if (currentCall.call.state === 'ringing') {
        console.log('Not hanging up ringing call on handset down');
      } else {
        currentCall.call.end();
      }
    } else {
      const ourSide = currentCall.call.connection === 'source' ? 'a' : 'b';
      Api.delete(
        `/api/dialer/calls/${currentCall.call.call.callId}/${ourSide}`
      );
    }
  }, [currentCall]);

  const answerCall = useCallback(() => {
    console.log('Answer bre: ', hasInput, submitInput);
    if (hasInput) {
      submitInput();
    } else if (currentCall && currentCall.type === 'softphone') {
      currentCall.call.answer();
    }
  }, [currentCall, hasInput, submitInput]);

  const onHandsetPickup = useCallback(() => {
    const { preset, presets, setPreset } = softphoneState.getState().settings;

    // Switch to handphone if available when picked up
    if (preset !== 'handphone' && presets.handphone?.enabled) {
      setPreset('handphone');
    }

    answerCall();
  }, [answerCall]);
  const onHandsetHangup = useCallback(() => {
    const { preset, setPreset, lastPreset } =
      softphoneState.getState().settings;

    if (preset === 'handphone') {
      endCall();
    }
    setPreset(lastPreset ?? 'speaker');
  }, [endCall]);

  const onDial = useCallback(() => {
    const { pathname } = window.location;
    const isOnOperator = pathname.startsWith('/telephony/operator');
    const { operatorEnabled } = dialerStore.getState();
    if (operatorEnabled && !isOnOperator) {
      routeEvents.emit('routeChange', '/telephony/operator/');
    }
  }, []);

  useGlobalHotkey('F15', onHandsetPickup);
  useGlobalHotkey('F16', onHandsetHangup);
  useSoftphoneEvent('dial', onDial);
}
