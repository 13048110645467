import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';

import { CaseInterestLevel } from '../../schemas';
import { AddInterestLevelButton } from './AddInterestLevel';
import { InterestLevelItem } from './InterestLevelItem';
import { useInterestLevel } from './use-interest-level';

export const CaseInterestLevelContent = () => {
  const { data: interestLevels } = useInterestLevel();
  if (!interestLevels) {
    return <LoadingPage />;
  }

  return <CaseInterestLevelSettings caseInterestLevel={interestLevels?.data} />;
};

interface Props {
  caseInterestLevel: CaseInterestLevel[];
}

export const CaseInterestLevelSettings = ({ caseInterestLevel }: Props) => {
  const [modifiedStates, modify] = useSettingsCrud<CaseInterestLevel>({
    parent: '#settings-form',
    inputName: 'sales[interestLevels]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: caseInterestLevel,
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedStates.map((interestLevel) => (
          <InterestLevelItem
            key={interestLevel.ID}
            interestLevel={interestLevel}
            modify={modify}
          />
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <AddInterestLevelButton modify={modify} />
      </Flex>
    </>
  );
};
