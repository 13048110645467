import { FilterTypes } from '@bq/components/FilterBar';
import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const heightFilter = makeNumberFilter({
  name: () => i18n.t('Products:height_meters'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.heightMeters:gte': value.from,
          'good.heightMeters:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.heightMeters', operator ?? 'eq', value];
    }

    return null;
  },
});
export const widthFilter = makeNumberFilter({
  name: () => i18n.t('Products:width_meters'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.widthMeters:gte': value.from,
          'good.widthMeters:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.widthMeters', operator ?? 'eq', value];
    }

    return null;
  },
});
export const lengthFilter = makeNumberFilter({
  name: () => i18n.t('Products:length_meters'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.lengthMeters:gte': value.from,
          'good.lengthMeters:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.lengthMeters', operator ?? 'eq', value];
    }

    return null;
  },
});

export const netWeightFilter = makeNumberFilter({
  name: () => i18n.t('Products:net_weight_kg'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.netWeightKg:gte': value.from,
          'good.netWeightKg:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.netWeightKg', operator ?? 'eq', value];
    }

    return null;
  },
});
export const grossWeightFilter = makeNumberFilter({
  name: () => i18n.t('Products:gross_weight_kg'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'good.grossWeightKg:gte': value.from,
          'good.grossWeightKg:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['good.grossWeightKg', operator ?? 'eq', value];
    }

    return null;
  },
});

export const logisticalDataFilters: FilterTypes = {
  heightFilter,
  lengthFilter,
  widthFilter,
  netWeightFilter,
  grossWeightFilter,
};
