import { ReactElement, ChangeEvent, useCallback, memo } from 'react';
import { chakra, Flex, Input, useColorModeValue } from '@chakra-ui/react';
import { TagOptionObject } from 'BootQuery/Assets/components/TagInput';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { TagChangeMethods } from './tags-methods';
import { ColorInput } from './ColorInput';

interface Props {
  value: TagOptionObject;
  modify: TagChangeMethods;
}

export const TagListItem = memo(
  ({ value, modify }: Props): ReactElement => {
    const inputBg = useColorModeValue('white', 'gray.800');

    const id = value.value;
    const label = value.label ?? value.value;

    const handleDelete = useCallback(() => {
      modify.deleteTag(id);
    }, [modify, id]);
    const handleRename = useCallback(
      (ev: ChangeEvent<HTMLInputElement>) => {
        // Allow only alphanumeric characters and underscore. Allows non-ascii characters too.
        // \p{L} are all letters in any language, \p{N} are all numbers in any language
        // Spaces are replaced by underscores for typing convenience.
        const clean = ev.target.value
          .replaceAll(' ', '_')
          .replace(/[^\p{L}\p{N}_]+/gu, '');
        modify.renameTag(id, clean.toLowerCase());
      },
      [modify, id]
    );
    const handleColorChange = useCallback(
      (color: string) => {
        modify.changeTagColor(id, color);
      },
      [modify, id]
    );

    return (
      <ItemWrapper>
        <Input
          size="sm"
          bg={inputBg}
          flexGrow={1}
          value={label}
          onChange={handleRename}
        />
        <ColorInput color={value.color ?? '#666'} onChange={handleColorChange} />
        <DeleteButton
          size="sm"
          variant="ghost"
          flexShrink={1}
          onClick={handleDelete}
        />
      </ItemWrapper>
    );
  }
);
TagListItem.displayName = 'TagListItem';

const ItemWrapper = chakra(Flex, {
  baseStyle: {
    borderBottomWidth: 'thin',
    borderStyle: 'solid',
    px: '2',
    py: '1',
  },
});
