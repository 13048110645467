import { MouseEvent, ReactElement, useCallback, useRef, useState } from 'react';
import {
  Box,
  Divider,
  Flex,
  HStack,
  HTMLChakraProps,
  IconButton,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { FaCog } from 'react-icons/fa';

import { isInsideLinkOrButton, isInsideModal } from 'app/assets/js/tsutil';

import { CallDisplay } from './CallDisplay';
import { CallStateDisplay } from './CallStateDisplay';
import { CurrentCallDisplay } from './CurrentCallDisplay';
import { DeviceIndicator } from './DeviceIndicator';
import { DialerContext } from './DialerContext';
import { DialerInput } from './DialerInput/DialerInput';
import { GlobalCallForm } from './GlobalCallForm';
import { useCurrentCall, useOtherCalls } from './hooks';
import { PauseMenu } from './Pauses';
import { QueueMenu } from './Queues';
import { Settings } from './Settings';
import { SoftphoneAction } from './SoftphoneAction';

const headerStyle: HTMLChakraProps<'div'> = {
  sx: { position: 'absolute' },
  left: '50%',
  top: '0',
  width: '500px',
  marginLeft: '-250px',
  borderRadius: 'md',
};

const displayStyle: HTMLChakraProps<'div'> = {
  marginTop: '2px',
  height: '50px',
  cursor: 'pointer',
  borderTopRadius: 'md',
  borderBottomRadius: 'md',
};

export const Dialer = (): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const dialerRef = useRef(null);

  const toggleExpand = useCallback((ev: MouseEvent<HTMLDivElement>) => {
    if (!isInsideLinkOrButton(ev.target)) {
      setExpanded((expanded) => !expanded);
    }
  }, []);
  useOutsideClick({
    ref: dialerRef,
    handler: useCallback((ev: Event) => {
      if (!ev.target || !isInsideModal(ev.target)) {
        setExpanded(false);
      }
    }, []),
  });

  const currentCall = useCurrentCall();
  const otherCalls = useOtherCalls(currentCall);

  return (
    <DialerContext expanded={expanded} setExpanded={setExpanded}>
      <Flex
        {...headerStyle}
        direction="column"
        ref={dialerRef}
        color="brand.fontStaticLight"
        bg="brand.mediumGray"
        _hover={{
          color: 'brand.fontStaticLight',
          bg: 'brand.darkGray',
        }}
        _focus={{
          color: 'brand.fontStaticLight',
          bg: 'brand.backgroundDark',
        }}
        _active={{
          color: 'brand.fontStaticLight',
          bg: 'brand.backgroundDark',
        }}
      >
        <Flex
          {...displayStyle}
          alignItems="center"
          px="2"
          onClick={toggleExpand}
        >
          <Flex direction="column">
            <DeviceIndicator />
            {currentCall && (
              <Box fontSize="sm">
                <CallStateDisplay {...currentCall} />
              </Box>
            )}
          </Flex>
          <CurrentCallDisplay />
        </Flex>
        {expanded && (
          <VStack w="100%" bg="brand.darkGray">
            <Box w="100%">
              <HStack px="4" py="2">
                <DialerInput ref={inputRef} />
                <SoftphoneAction />
              </HStack>
              <Box px="4" py="2">
                {otherCalls.map((other, idx) => (
                  <CallDisplay key={idx} {...other} />
                ))}
              </Box>
            </Box>

            <Divider />
            <Flex w="100%" px="3" pb="2" justifyContent="space-between">
              <QueueMenu />
              <PauseMenu />
              <IconButton
                aria-label="Settings"
                icon={<FaCog />}
                isActive={settingsOpen}
                onClick={() => setSettingsOpen(!settingsOpen)}
                variant="ghost"
                color="brand.fontStaticLight"
                _hover={{
                  color: 'brand.fontStaticDark',
                  bg: 'brand.background',
                }}
                _focus={{
                  color: 'brand.fontStaticDark',
                  bg: 'brand.background',
                }}
                _active={{
                  color: 'brand.fontStaticDark',
                  bg: 'brand.background',
                }}
              />
            </Flex>
            {settingsOpen && (
              <Box w="100%" maxHeight="calc(100vh - 200px)" overflowY="auto">
                <Settings />
              </Box>
            )}
          </VStack>
        )}
      </Flex>
      <GlobalCallForm />
    </DialerContext>
  );
};
