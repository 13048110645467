module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return "				Content\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"head"),depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div id=\"content-wrapper\">\n	"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"header"),depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"menu"),depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "	<div id=\"main-container\">\n		<div id=\"php-debugs\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_php_debug") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":23},"end":{"line":6,"column":62}}})) != null ? stack1 : "")
    + "</div>\n		<div id=\"react-dummy\"></div>\n		<div class=\"bq-content bq-default paginated embedable "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"bq_content_class") : depth0), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"body",{"name":"block","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":3},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "			"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"globalWarningToast"),depth0,{"name":"globalWarningToast","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</div>\n	</div>\n</div>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"end"),depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})