module.exports = {"name":"Korisnici","menu_entries":{"userlist":"Korisnici","logout":"Odjava","user":"Korisnik","extensions":"Lokali"},"tab":{"users":"Korisnici","groups":"Grupe"},"table_header":{"user":"Korisnik","extension":"Lokal","groups":"Grupe","employee":"Zaposlenik","name":"Naziv","group_name":"Naziv"},"label":{"settings":"Postavke","display":"Prikaz","user":"Korisnik","local_extension":"Lokalna Lokal","login":"Prijava","user_managed_at":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Ovim korisnikom se upravlja kroz <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":53}}}) : helper)))
    + "</strong>. Nekim postavkama nije moguće upravljati ovdje.";
},"useData":true})},"button":{"login":"Prijava","login_with":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Prijava kroz "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":1,"column":13},"end":{"line":1,"column":25}}}) : helper)));
},"useData":true}),"change_password":"Promijeni lozinku"},"form":{"username":"Korisničko ime","password":"Lozinka","password_confirm":"Ponovite lozinku","current_password":"Trenutna lozinka","recovery_email":"E-mail za oporavak","stayLoggedIn":"Ostani ulogiran","email":"E-mail","extension":"Lokal","employee":"Zaposlenik","groups":"Grupe","group":"Grupa","name":"Naziv","language":"Jezik","theme":"Tema","compact_tables":"Kompaktne tablice","password_recovery":"Oporavak lozinke","send_email":"Pošalji E-mail","reset_password":"Postavi lozinku","user_unavailable":"Nedostupan"},"error":{"invalid_email":"Neispravna e-mail adresa","incorrect_username_or_password":"Neispravno korisničko ime ili lozinka"},"filter":{"username":"Korisničko ime","extension":"Lokal","group":"Grupa"}}