import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketHasDependencies = makeBooleanFilter({
  toFilter: ({ value }) => {
    return { hasDependencies: value };
  },
  name: () => i18n.t('Ticketing:has_dependencies'),
  extraProps: {},
});
