import { memo, useMemo } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

import './fix.css';

import { AllExtensions } from './Extensions/AllExtensions';
import { RichTextProvider } from './RichtextContex';
import { IRichText } from './types';

const RichTextViewOnly = ({
  content,
  onCreate,
  extensions,
}: Omit<IRichText, 'onChange'> & { onCreate?: () => void }) => {
  const externalExts = useMemo(
    () => extensions?.map((item) => item.extension) ?? [],
    [extensions]
  );
  const editor = useEditor({
    extensions: [...AllExtensions, ...externalExts],
    content,
    editable: false,
    onCreate() {
      if (onCreate) {
        onCreate();
      }
    },
  });

  return (
    <RichTextProvider editor={editor}>
      <EditorContent
        editor={editor}
        className="raw-content print"
        tabIndex={1}
      />
    </RichTextProvider>
  );
};

const RichTextMemo = memo(RichTextViewOnly);

export { RichTextMemo as ViewOnlyRichText };
