import type { ReactElement } from 'react';

import { ChangedField } from './ChangedField';
import { useDetailChanges } from './util';

export const LegacyCustomFormChanges = (
  details: Record<string, unknown>
): ReactElement => {
  const changes = useDetailChanges(details);

  return (
    <>
      {changes.map((change) => (
        <ChangedField key={change.key} field={change.key}>
          {change.value ?? '-'}
        </ChangedField>
      ))}
    </>
  );
};
