import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import Code from '@tiptap/extension-code';
import Color from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Focus from '@tiptap/extension-focus';
import FontFamily from '@tiptap/extension-font-family';
import Gapcursor from '@tiptap/extension-gapcursor';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Image from '@tiptap/extension-image';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import Strike from '@tiptap/extension-strike';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';

import { CustomTableCell } from '../Buttons/Table/CustomTableCell';
import Collapse from '../Collapse/Collapse';
import {
  ColoredHeading,
  ColoredOL,
  ColoredUL,
  ParagraphExtend,
} from '../ExtendToGetColors';

export const AllExtensions = [
  ParagraphExtend,
  Document,
  Text,
  Gapcursor,
  Collapse,
  Bold,
  Italic,
  Underline,
  Strike,
  Code,
  Blockquote,
  Focus.configure({
    className: 'has-focus',
    mode: 'all',
  }),
  Subscript,
  Superscript,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image'],
  }),
  ColoredOL,
  ColoredUL,
  ListItem,
  Color,
  TextStyle,
  Highlight.configure({
    multicolor: true,
  }),
  ColoredHeading,
  Image.configure({ allowBase64: true }),
  Link.configure({
    openOnClick: true,
  }),
  History,
  FontFamily,
  Table.configure({
    resizable: true,
    lastColumnResizable: false,
  }),
  TableRow,
  TableHeader,
  CustomTableCell,
];
