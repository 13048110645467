import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FileInput } from 'BootQuery/Assets/components/FileInput';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { FileFieldSettings } from './types';

type Props = FieldValue<FileFieldSettings>;

export const FileField = ({ id, settings }: Props) => {
  const { control, setError } = useFormContext();
  const { field, fieldState } = useController({ control, name: id });
  const { t } = useTranslation();

  return (
    <FormControl>
      <FieldLabel {...settings} />
      <FileInput
        value={field.value ?? []}
        onChange={field.onChange}
        accept={settings.accept}
        onError={() => {
          setError(id, {
            type: 'manual',
            message: t('global:form_editor.files_too_large'),
          });
        }}
        maxSize={settings.maxFileSize}
      />
      {fieldState.error && (
        <FormErrorMessage>{fieldState.error.message}</FormErrorMessage>
      )}
    </FormControl>
  );
};
