import { useCallback } from 'react';
import { get } from 'lodash-es';
import { v4 as uuid } from 'uuid';

import { MailsModule, Message } from '../../../js/types';
import { findAccount } from './find-account';
import {
  Addresses,
  MailEditor,
  NewMessageParams,
  ReplyDefaults,
  ReplyType,
} from './types';

export interface MailActions {
  newMail: (params?: NewMessageParams) => Promise<void>;
  replyMail: (
    type: ReplyType,
    prevMessage: Message,
    defaults?: ReplyDefaults
  ) => Promise<void>;
  forwardMail: (prevMessage: Message) => void;
}

type AddEditor = (editor: MailEditor) => void;

export function useMailActions(addEditor: AddEditor): MailActions {
  const newMail = useCallback(
    async (params: NewMessageParams = {}) => {
      const editorID = uuid();
      const mails = window.BootQuery.getModuleInstance<MailsModule>('Mails');

      const [availableAccounts, signatureTemplateData, templates] =
        await Promise.all([
          mails.getAvailableAccounts(),
          mails.getSignatureTemplateData(),
          mails.getTemplates(),
        ]);

      addEditor({
        editorID,
        createdAt: new Date(),
        editorData: {
          availableAccounts,
          signatureTemplateData,
          templates,
          ...params,
        },
        extraData: {
          inReplyToMessageID: params.inReplyToMessageID,
          forwardMessageID: params.forwardMessageID,
          threadID: params.threadID,
        },
      });
    },
    [addEditor]
  );

  const replyMail = useCallback(
    async (
      type: ReplyType,
      prevMessage: Message,
      defaults: ReplyDefaults = {}
    ) => {
      const account = await findAccount(prevMessage.mailbox.accountID);

      const addressIsAccount = (address: string) => {
        return address === account?.email || account?.aliases.includes(address);
      };

      const addresses: Addresses = {
        to: [],
        cc: [],
        bcc: [],
      };

      const replyingToSelf = addressIsAccount(prevMessage.fromMailAddress);
      const replyTo = get(prevMessage.headers, [
        'reply-to',
        'value',
        0,
        'address',
      ]);
      if (type === 'all') {
        if (!replyingToSelf) {
          if (replyTo) {
            addresses.to.push(replyTo);
          } else {
            addresses.to.push(prevMessage.fromMailAddress);
          }
        }

        prevMessage.addresses.forEach(({ addressType, mailAddress }) => {
          if (!replyingToSelf && addressIsAccount(mailAddress.email)) {
            return;
          }
          addresses[addressType].push(mailAddress.email);
        });
      } else if (replyingToSelf) {
        prevMessage.addresses
          .filter(({ addressType }) => addressType === 'to')
          .forEach(({ mailAddress }) => addresses.to.push(mailAddress.email));
      } else if (replyTo) {
        addresses.to.push(replyTo);
      } else {
        addresses.to.push(prevMessage.fromMailAddress);
      }

      let subject = defaults.subject || prevMessage.subject;
      if (!subject.toUpperCase().startsWith('RE:')) {
        subject = `Re: ${subject}`;
      }

      newMail({
        subject,
        ...addresses,
        accountID: account?.ID,
        fixedAccount: false,
        inReplyToMessageID: prevMessage.ID,
        quotedHtml: prevMessage.htmlBody,
        quotedText: prevMessage.textBody,
      });
    },
    [newMail]
  );

  const forwardMail = useCallback(
    async (prevMessage: Message) => {
      let { subject } = prevMessage;
      if (!subject.toUpperCase().startsWith('FWD:')) {
        subject = `Fwd: ${subject}`;
      }

      const account = await findAccount(prevMessage.mailbox.accountID);

      newMail({
        subject,
        accountID: account?.ID,
        forwardMessageID: prevMessage.ID,
        quotedHtml: prevMessage.htmlBody,
        quotedText: prevMessage.textBody,
      });
    },
    [newMail]
  );

  return { newMail, replyMail, forwardMail };
}
