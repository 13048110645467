import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams, OBJWithID } from 'BootQuery/Assets/js/globalTypes';

import { Campaign } from '../../schemas';

export const getOneCampaign = async <T extends OBJWithID = Campaign>(
  campaignID: number,
  params?: GetOneParams
) => {
  const { data } = await Api.get<T>(`/api/sales/campaigns/${campaignID}`, {
    params,
  });

  return data;
};
