import {
  IconButtonProps as ChakraIconButtonProps,
  StyleProps,
  useButtonGroup,
  useStyleConfig,
} from '@chakra-ui/react';

interface ButtonStyles extends StyleProps {
  _hover: StyleProps;
}

/**
 * Get style properties that override color in order to prevent global styles
 * from interfering with icon button's default look.
 *
 * This resolves problems that happen when using the button with as={Link}
 *
 * @param props Props that are passed to IconButton
 * @returns `color` style overrides
 */
export function useIconButtonStyles(
  props: Omit<ChakraIconButtonProps, 'aria-label'>
): ButtonStyles {
  const group = useButtonGroup();

  // We basically copied the way chakra internally generates these styles
  // Reference: https://github.com/chakra-ui/chakra-ui/blob/7961ae646a60f7e76bfbe2284f36dee693f3b07e/packages/components/src/button/button.tsx#L30
  const defaultStyles = useStyleConfig('Button', {
    ...group,
    ...props,
  }) as StyleProps;
  const defaultHoverStyles = getHoverStyles(defaultStyles);

  return {
    color: defaultStyles.color ?? 'currentColor',
    _hover: {
      // keep defaults so we don't override them by setting _hover
      ...defaultHoverStyles,
      color: defaultHoverStyles.color ?? defaultStyles.color ?? 'currentColor',
    },
  };
}

function getHoverStyles(styles: StyleProps): StyleProps {
  // Chakra's types are wrong here so we need to cast stuff
  // eslint-disable-next-line
  const defaultHover = (styles as any)._hover;

  return defaultHover ?? {};
}
