import { round } from 'lodash-es';
import {
  ValidValueTypes,
  PriceOptionsWithDefaults,
  SplitNumber,
} from './types';
import { bigIntAbs, parseDecimalPart } from './utils';

export const toInternal = (
  value: Exclude<ValidValueTypes, 'bigint'>,
  opts: PriceOptionsWithDefaults
): bigint | undefined => {
  /** If value is not nullish */
  if (value) {
    /** If value is already a number we leave it as number */
    let insideValue = typeof value === 'number' ? value : 0;
    /** Otherwise if its a string we convert it to number */
    if (typeof value === 'string') {
      insideValue = parseFloat(value);
    }
    /** We check if the transformed or initial value were NaN
     * if they were NaN we return undefined because
     * we don't know what it approximates to
     */
    if (Number.isNaN(insideValue)) {
      return undefined;
    }

    /** Otherwise we convert it to our internal number
     * that means we multiply it with 10 ** calulating digits we use
     * then we round the number, e.g. 129.99 = 130
     * we convert it to int to get rid of all other digits if they remain
     * then we convert it to BigInt
     */
    return BigInt(
      parseInt(`${round(insideValue * 10 ** opts.calculatingDecimals, 0)}`, 10)
    );
  }
  /** Special case if number is 0 */
  if (value === 0) {
    return 0n;
  }

  return undefined;
};

export const toNumberCalculating = (
  value: bigint,
  opts: PriceOptionsWithDefaults
): number => {
  /** We divide internal number to calulating digits */
  return round(
    Number(value) / 10 ** opts.calculatingDecimals,
    opts.calculatingDecimals
  );
};
export const toNumber = (
  value: bigint,
  opts: PriceOptionsWithDefaults
): number => {
  /** We divide internal number with calulating decimals to get rid of
   * extra decimals, we then round it to number of decimals we use to display
   */
  return round(
    Number(value) / 10 ** opts.calculatingDecimals,
    opts.roundingDecimals
  );
};

export const toSplitNumberRounding = (
  value: bigint /** 1249989 internaly */,
  opts: PriceOptionsWithDefaults /** rounding decimals: 2, calculating decimals: 4 */
  /** this approximates to 124.9989 in float */
): SplitNumber => {
  /** 10 ** 2 (calculating decimals: 4 - rounding decimals: 2) */
  /** We use this to get rid of non-rounding part */
  const firstDivisor =
    10n ** BigInt(opts.calculatingDecimals - opts.roundingDecimals);

  /** -12499 | 89 is lost */
  const convertedValue = value / firstDivisor;

  /** 10 ** 2 */
  const secondDivisor = BigInt(10 ** opts.roundingDecimals);

  /** -124 | 99 is lost */
  let wholeNumberPart = bigIntAbs(convertedValue / secondDivisor);

  /**
   * decimals digits length of calculating number
   * - calulation: Abs(-1249989) - 1240000
   * - equals to: 9989
   *
   */
  const decimalPartCalculating =
    bigIntAbs(value) - wholeNumberPart * firstDivisor * secondDivisor;

  /**
   * - value: 99
   * - lost: 89
   */
  let decimalPart = decimalPartCalculating / firstDivisor;

  /** get remainder for rounding purposes
   *
   * - value: 89  */
  const remainder = decimalPartCalculating % firstDivisor;
  /** get the first digit
   *
   * value: - 8  */
  const remainderFirstDigit = BigInt(String(remainder)?.[0] ?? '0');

  /** Round last digit 99 + 1 because 8 is bigger than 4
   *
   * - decimal part: 100
   */
  if (remainderFirstDigit > 4) {
    decimalPart += 1n;
  }
  /** Check if that overflowed it,
   * if it did we increse the whole number part
   * so current value:
   * - decimal part: 0
   * - whole number part: 125
   */
  if (decimalPart / secondDivisor > 0) {
    wholeNumberPart += 1n;
    decimalPart %= secondDivisor;
  }

  /**
   * - decimalPart: 0
   * - wholeNumberPart: 125
   * - decimalDivisor: 10 ** roundingDecimals = 10 ** 2 = 100
   * - isNegative: true
   */
  return {
    decimalPart,
    wholeNumberPart,
    decimalDivisor: secondDivisor,
    isNegative: value < 0n,
  };
};

export const toString = (
  value: SplitNumber,
  opts: PriceOptionsWithDefaults
) => {
  const { wholeNumberPart, decimalPart, isNegative, decimalDivisor } = value;
  if (value.decimalPart === 0n) {
    return `${wholeNumberPart}.${'0'.repeat(opts.roundingDecimals)}`;
  }

  return `${isNegative ? -wholeNumberPart : wholeNumberPart}.${parseDecimalPart(
    decimalPart,
    decimalDivisor
  )}`;
};
