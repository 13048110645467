import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const deliveryDateFilter = makeDateFilter({
  name: () => i18n.t('Sales:delivery_date'),
  toFilter: makeDateToFilter('deliveryDate'),
});
