import 'BootQuery/Assets/js/i18n';

import { ReactElement, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { routeEvents } from '../js/route-events';
import { useMitt } from '../js/use-mitt';

export const HistorySyncer = (): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const reactRoute = `${location.pathname}${location.search}`;

  const handleRouteChange = useCallback(
    (newRoute: string) => {
      if (reactRoute !== newRoute) {
        // Use replace because the URL was already updated externally, we're
        // just informing react-router about it and don't want a new history entry
        navigate(newRoute, { replace: true });
      }
    },
    [navigate, reactRoute]
  );

  useMitt(routeEvents, 'routeChange', handleRouteChange);

  return <></>;
};
