import { useState } from 'react';

import { FilterValue } from '@bq/components/FilterBar';

import { UseFiltersResult } from './types';

export function useStateFilters(): UseFiltersResult {
  const [filters, setFilters] = useState<FilterValue[]>([]);
  const [search, setSearch] = useState<string>('');

  return [filters, setFilters, search, setSearch];
}
