import { FilterStorage, UsePageResult } from './types';
import { useQueryPage } from './use-query-page';
import { useStatePage } from './use-state-page';

export function usePage(storage: FilterStorage): UsePageResult {
  const statePage = useStatePage();
  const queryPage = useQueryPage();

  return storage === 'query' ? queryPage : statePage;
}
