import { Box, Button, VStack } from '@chakra-ui/react';
import { FaChevronDown } from 'react-icons/fa';

import { ColorInputCustomTrigger } from '@bq/components/ColorInputCustomTrigger';

import { useFocusProvider } from '../FocusContext';
import { useRichText } from '../RichtextContex';
import { RichTextColorInterface } from '../types';

export const RichTextColor = ({
  inputHandler,
  clearHandler,
  clearable = false,
  property,
  icon,
  defaultValue,
}: RichTextColorInterface) => {
  const { editor } = useRichText();
  const { tabIndex, handleBlur, handleEscapingToolbar } =
    useFocusProvider();

  return (
    <ColorInputCustomTrigger
      onChange={inputHandler}
      onClear={clearHandler}
      value={defaultValue}
      clearable={clearable}
      customTrigger={
        <Button
          rightIcon={<FaChevronDown />}
          size="sm"
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Escape') {
              handleEscapingToolbar();
            }
          }}
          onBlur={handleBlur}
          tabIndex={tabIndex}
        >
          <VStack spacing={0}>
            <>{icon}</>
            <Box
              w={6}
              h={2}
              mt={0}
              pt={0}
              border="1px solid black"
              backgroundColor={
                editor?.getAttributes(property).color ?? defaultValue
              }
            />
          </VStack>
        </Button>
      }
    />
  );
};
