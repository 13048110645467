import { useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { debounce } from 'lodash-es';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select } from '@bq/components/SelectWrappers';

import { searchTaxRates } from '../../../API/TaxRates/searchTaxRates';
import { Item } from '../../../types';
import { FieldProps } from '../../types';
import { calculateWithTaxDefaults } from '../../utils';

export const TaxRateField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext<Item>();
  const { field: taxRate } = useController<Item, 'price.taxRate'>({
    control,
    name: 'price.taxRate',
  });
  const debouncedSearch = useMemo(() => {
    return debounce((search: string) => searchTaxRates({ search }), 300, {
      leading: true,
    });
  }, []);

  const value = useMemo(() => {
    if (!taxRate.value) {
      return null;
    }

    return {
      ...taxRate.value,
      value: taxRate.value.ID,
      label: ` ${taxRate.value.name} - ${taxRate.value.taxRate}%`,
    };
  }, [taxRate.value]);

  const price = useWatch({
    control,
    name: 'price',
  });

  return (
    <FormControl isInvalid={!!errors.taxRate?.name} {...formControlProps}>
      <FormLabel htmlFor="taxRate">{t('Products:tax_rate')}</FormLabel>
      <Select<number, { ID: number; taxRate: string }>
        size={['md', 'md', 'sm']}
        value={value}
        onChange={(value) => {
          taxRate.onChange(value);
          const newValues = calculateWithTaxDefaults(
            price,
            value?.taxRate ?? '0'
          );

          setValue('price.withTax', newValues);
        }}
        isClearable
        options={async (search) => {
          const data = (await debouncedSearch(search)) ?? [];

          return data.map((item) => ({
            ID: item.ID,
            taxRate: item.taxRate,
            value: item.ID,
            label: `${item.name} - ${item.taxRate}% (${item.country.isoCode})`,
            name: item.name,
          }));
        }}
      />
    </FormControl>
  );
};
