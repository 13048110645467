import { ReactElement, useEffect } from 'react';
import { Select } from 'chakra-react-select';
import { chakraSelectStyles } from 'BootQuery/Assets/components/Select';
import { getNationalNumberTypesMemo } from './number-types';
import { NationalNumberType } from './types';

interface Props {
  value: string;
  onChange: (value: string | undefined) => void;
  placeholder?: string;
  defaultValue?: string;
}

export const NumberTypeInput = ({
  value,
  onChange,
  placeholder,
  defaultValue = '',
}: Props): ReactElement => {
  const types = getNationalNumberTypesMemo();

  const selectedOption = types.find((type) => type.value === value);
  const handleChange = (newOption: NationalNumberType | null) => {
    onChange(newOption?.value);
  };
  useEffect(() => {
    if (value === undefined) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange, value]);

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={types}
      placeholder={placeholder}
      styles={chakraSelectStyles}
      selectedOptionColorScheme="brand"
    />
  );
};
