import { ReactElement, useRef } from 'react';
import { CardBody } from 'BootQuery/Assets/components/Card';
import { useToggle } from 'react-use';
import { Message as IMessage } from '../../js/types';
import { HTMLMessageContent } from './HTMLMessageContent';
import { TextMessageContent } from './TextMessageContent';
import { useOverflowing } from './use-overflowing';
import { ExpandToggle } from './ExpandToggle';

type Props = Pick<IMessage, 'htmlBody' | 'textBody'>;

export const MessageBody = ({ htmlBody, textBody }: Props): ReactElement => {
  const bodyStyle = htmlBody ? htmlBodyStyle : {};
  const bodyRef = useRef<HTMLDivElement>(null);
  const overflowing = useOverflowing(bodyRef);
  const [expanded, toggleExpanded] = useToggle(false);
  // Check for false specifically, we want to ignore null
  const maxHeight = expanded || overflowing === false ? undefined : '100px';

  return (
    <CardBody
      py="3"
      px="3"
      pb={expanded ? '10' : undefined}
      position="relative"
      {...bodyStyle}
    >
      {htmlBody ? (
        <HTMLMessageContent
          ref={bodyRef}
          maxHeight={maxHeight}
          overflow="hidden"
          html={htmlBody}
        />
      ) : (
        <TextMessageContent
          ref={bodyRef}
          maxHeight={maxHeight}
          overflow="hidden"
        >
          {textBody}
        </TextMessageContent>
      )}
      {overflowing && (
        <ExpandToggle expanded={expanded} onClick={toggleExpanded} />
      )}
    </CardBody>
  );
};

const htmlBodyStyle = {
  bg: 'white',
  color: 'gray.800',
  sx: {
    '& table': { color: 'gray.800' },
  },
};
