import { ItemProgram } from '../../types';
import { getSubgroups } from './getSubgroups';

interface SearchSubgroupParams {
  search?: string;
  parentID?: number;
}
export const searchSubgroups = async ({
  search,
  parentID,
}: SearchSubgroupParams): Promise<ItemProgram[]> => {
  const products = await getSubgroups({
    params: {
      filters: {
        $search: search,
        ...(parentID
          ? {
              'parentGroupID:eq': parentID,
            }
          : {}),
      },
    },
  });

  return products.data;
};
