import { Box, BoxProps, forwardRef, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { baseStickyProps } from './sticky-props';

interface CardHeaderProps extends BoxProps {
  title?: string;
  isSticky?: boolean;
}

const stickTopProps: BoxProps = {
  ...baseStickyProps,
  top: 0,
};

function stickyProps(isSticky = false) {
  return isSticky ? stickTopProps : {};
}

type HeaderContentProps = Pick<CardHeaderProps, 'title' | 'children'>;

function HeaderContent({ title, children }: HeaderContentProps): ReactNode {
  if (children) {
    return children;
  }

  if (title) {
    return (
      <Heading as="h2" fontSize="lg" mb="0" textAlign="center" width="100%">
        {title}
      </Heading>
    );
  }

  return <></>;
}

export const CardHeader = forwardRef<CardHeaderProps, typeof Box>(
  ({ title, children, isSticky, ...boxProps }, ref) => (
    <Box
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      px="6"
      py="4"
      borderBottomWidth="1px"
      {...stickyProps(isSticky)}
      {...boxProps}
    >
      {HeaderContent({ title, children })}
    </Box>
  )
);
