import { ReactElement, Suspense } from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { Layout, LayoutBody, LayoutHeader } from '@bq/components/Layout';
import { LoadingPage } from '@bq/components/LoadingPage';
import { PageWrapper } from '@bq/components/PageWrapper';

import { useReport } from '../components/use-available-reports';

type RouterParams = {
  reportKey: string;
};

export const ViewReport = (): ReactElement => {
  const { t } = useTranslation('Reporting');
  const { reportKey } = useParams<RouterParams>();
  if (!reportKey) {
    throw new Error('Missing reportKey from route');
  }
  const report = useReport(reportKey ?? '');

  if (!report) {
    return <Heading>{t('Reporting:report_not_found')}</Heading>;
  }

  return (
    <PageWrapper p="0">
      <Layout h="fullNoNavbar">
        <LayoutHeader>
          <Breadcrumbs>
            <Breadcrumb to="/reporting/reports">
              {t('Reporting:reports_title')}
            </Breadcrumb>
            <Breadcrumb to="/reporting/reports">
              {report.categoryTitle} - {report.title}
            </Breadcrumb>
          </Breadcrumbs>
        </LayoutHeader>
        <LayoutBody>
          <Suspense fallback={<LoadingPage />}>
            <report.component />
          </Suspense>
        </LayoutBody>
      </Layout>
    </PageWrapper>
  );
};
