export interface DurationSegments {
  hours: number;
  minutes: number;
  seconds: number;
}

export function formatDuration(
  rawSecs: number | null,
  forceHours = false,
  hideSeconds = false
): string {
  const { hours, minutes, seconds } = getDurationSegments(rawSecs);
  // If seconds won't be shown, round the them into minutes
  const secsRoundedMinute = seconds >= 30 ? 1 : 0;

  let segments: number[] = hideSeconds
    ? [minutes + secsRoundedMinute]
    : [minutes, seconds];

  if (hours > 0 || forceHours) {
    segments = [hours, ...segments];
  }

  return segments.map((seg) => seg.toString().padStart(2, '0')).join(':');
}

export function getDurationSegments(rawSecs: number | null): DurationSegments {
  if (!rawSecs) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  const hours = Math.floor(rawSecs / 3600);
  const minutes = Math.floor((rawSecs - hours * 3600) / 60);
  const seconds = Math.floor(rawSecs % 60);

  return { hours, minutes, seconds };
}
