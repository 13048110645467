import { ReactElement } from 'react';
import { get } from 'lodash-es';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { FieldValue } from '../../types';
import { TagList } from './TagList';
import { TagFieldSettings } from './types';

type Props = CellProps;

export function makeTagCell(field: FieldValue<TagFieldSettings>) {
  const TagCell = ({ row }: Props): ReactElement => {
    const tags = get(row, `data.${field.id}`) as string[] | undefined;
    if (!tags || !Array.isArray(tags)) {
      return <>-</>;
    }

    return <TagList value={tags} availableTags={field.settings.tags} />;
  };
  TagCell.displayName = `TagCellCustom-${field.id}`;

  return TagCell;
}
