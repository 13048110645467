function getOptionWithID(options, id) {
  let theChosenOne = null;

  if (id === null || id === '') {
    id = 'null';
  }

  for (let i = 0; i < options.length; i++) {
    const option = options[i];
    if (option.id === null || option.id === '') {
      option.id = 'null';
    }

    if (option.id == id) {
      theChosenOne = option;
      break;
    }
  }

  return theChosenOne;
}

const privateMethods = {
  render(element, optionalOptions) {
    const {
      pickleElement,
    } = element.data('pickle');
    const options = optionalOptions || methods.options(element);
    const values = methods.valuesArray(element);

    const isMultiple = methods.multiple(element);
    const formatFunction = element.data('pickle').options.formatOption.bind(element);
    let newHTML = '';
    for (let oi = 0; oi < options.length; oi++) {
      const option = options[oi];

      let skip = false;
      if (isMultiple) {
        for (let vi = 0; vi < values.length; vi++) {
          const value = values[vi];
          if (value == option.id) {
            skip = true;
          }
        }
      }

      if (!skip) {
        let formatted = formatFunction(option);
        if (formatted == '') {
          formatted = '&nbsp;';
        }

        newHTML += `
					<div class="dropdown-item pickle-option" data-pickle-option-id="${option.id}">
						${formatted}
					</div>
				`;
      }
    }
    pickleElement.find('.pickle-options-list')[0].innerHTML = newHTML;
  },

  renderSelected(element) {
    const {
      pickleElement,
    } = element.data('pickle');
    const values = methods.valuesArray(element);

    const isMultiple = methods.multiple(element);
    const formatFunction = element.data('pickle').options.formatSelectedOption.bind(element);

    let newContent = '';
    for (let i = 0; i < values.length; i++) {
      const value = values[i];
      const selectedOption = getOptionWithID(element.data('pickle').result, value);

      if (isMultiple) {
        // Multi select
        if (selectedOption) {
          const {
            id,
          } = selectedOption;
          const {
            text,
          } = selectedOption;
          newContent += `
						<span class="btn btn-dark btn-sm pickle-tag" data-pickle-value="${id}">
							${text}
							<span class="pickle-remove badge badge-default">&times;</span>
						</span>
					`;
        }
      } else {
        // Single select
        newContent = selectedOption ? formatFunction(selectedOption) : '\u00a0';
      }
    }
    pickleElement.find('.selected-option')[0].innerHTML = newContent;

    $(element).trigger({
      type: 'renderedSelected',
      newContent,
      selectedOptionElement: pickleElement.find('.selected-option'),
    });
  },

  results(element, term, callback) {
    const data = element.data('pickle');

    data.pickleElement.find('.pickle-spinner-icon').prop('hidden', false);
    data.pickleElement.find('.pickle-search-icon').prop('hidden', true);

    const resultsFunc = data.options.results.bind(element);

    resultsFunc(
      term,
      data.result,
      (results, keepCurrent) => {
        data.pickleElement.find('.pickle-spinner-icon').prop('hidden', true);
        data.pickleElement.find('.pickle-search-icon').prop('hidden', false);
        methods.options(element, results, keepCurrent);
        callback(results);
      },
      element,
    );
  },
};

let methods = {
  init(originalElement, userOptions) {
    originalElement.prop('hidden', true);
    originalElement.addClass('pickle-activated');

    if (originalElement.data('pickle') && originalElement.data('pickle').pickleElement) {
      originalElement.data('pickle').pickleElement.remove();
    }

    const options = $.extend(
      {
        initialOptions: null,

        results: function pickleDefaultResults(
          searchTerm,
          prevResults,
          callback,
          originalElement,
        ) {
          const results = originalElement.data('pickle').options.initialOptions;

          const matchedResults = results.filter(
            (result) => window.Pickle.defaultCompareResult(result, searchTerm)
          );
          callback(matchedResults, true);
        },
        searchable: $(originalElement).is('[data-searchable]') ? $(originalElement).data('searchable') : true,
        spinnerIconClass: 'glyphicon glyphicon-refresh pickle-spinning',
        searchIconClass: 'glyphicon glyphicon-search',
        multiple: $(originalElement).is('[multiple]'),
        disabled: $(originalElement).is(':disabled'),
        placeholder: '',
        formatOption: function formatOption(option) {
          return option.text;
        },
        formatSelectedOption: function formatSelectedOption(option) {
          return option.text;
        },
      },
      userOptions,
    );

    let {
      initialOptions,
    } = options;

    if (!initialOptions) {
      initialOptions = [];
      originalElement.find('option').each((_i, el) => {
        const option = $(el);
        const id = option.attr('value');
        const text = option.attr('data-html-text')
          ? option.attr('data-html-text')
          : option.html();

        initialOptions.push({
          id,
          text,
        });
      });
    }

    options.initialOptions = initialOptions;

    const attributes = {};
    $(originalElement[0].attributes).each((_i, el) => {
      attributes[el.name] = el.value;
    });

    const spinnerIconClass = `pickle-spinner-icon form-control-feedback ${
      options.spinnerIconClass
    }`;
    const searchIconClass = `pickle-search-icon form-control-feedback ${
      options.searchIconClass
    }`;

    const caret = options.multiple ? '' : '<span class="pickle-caret fa fa-caret-down"></span>';

    let buttonClass;
    if (options.multiple) {
      buttonClass = 'form-control pickle-button pickle-multi-select-button clearfix';
    } else {
      buttonClass = 'form-control text-left pickle-button pickle-single-select-button clearfix';
    }
    const btnType = options.multiple ? 'div' : 'a';

    let btnProp = '';
    if (options.disabled) {
      buttonClass += ' disabled';
    }
    if (btnType === 'a') {
      btnProp += ' href="#"';
    }

    const pickleElementSource = `
			<div class="pickle-container dropdown">
				<${btnType} class="${buttonClass}" tabindex="0" ${btnProp}>
					<span class="selected-option pull-left">&nbsp;</span>
					${caret}
				</${btnType}>
				<div class="pickle-options dropdown-menu">
					<div class="dropdown-item pickle-input-container">
						<div class="has-feedback">
						<input type="text" class="pickle-input form-control w-100" value=""/>
							<span class="${spinnerIconClass}" hidden></span>
							<span class="${searchIconClass}"></span>
						</div>
					</div>
					<div class="pickle-options-list"></div>
				</div>
			</div>
		`;

    const pickleElement = $(pickleElementSource).insertAfter(originalElement);
    pickleElement.find('.selected-option').attr('data-pickle-placeholder', options.placeholder);
    originalElement.data('pickle', {
      result: initialOptions,
      pickleElement,
      options,
    });

    pickleElement.off('input', '.pickle-input').on('input', '.pickle-input', (e) => {
      privateMethods.results(originalElement, $(e.currentTarget).val(), (matchedResults) => {
        privateMethods.render(originalElement, matchedResults);
      });
    });

    pickleElement.off('click', '.pickle-button').on('click', '.pickle-button', (e) => {
      e.preventDefault();

      methods.toggle(originalElement);
    });

    pickleElement.off('click', '.pickle-remove').on('click', '.pickle-remove', (e) => {
      e.preventDefault();

      const tagValue = $(e.currentTarget)
        .closest('.pickle-tag')
        .data('pickle-value');
      methods.unselect(originalElement, tagValue);
    });

    pickleElement.off('keydown').on('keydown', (e) => {
      if (methods.disabled(originalElement)) {
        return;
      }
      const code = e.keyCode || e.which;

      const buttonsThatTendToshowDropdowns = [10, 13, 32, 40];

      if (!pickleElement.is('.show')) {
        if (buttonsThatTendToshowDropdowns.indexOf(code) != -1) {
          e.stopPropagation();
          e.preventDefault();
          methods.open(originalElement);
        }
      } else {
        const resultElements = pickleElement.find('.pickle-option');
        if (code === 13) {
          e.preventDefault();
          e.stopPropagation();
          let currentlyActive = resultElements.filter('.active').eq(0);
          if (!currentlyActive.length) {
            currentlyActive = resultElements.eq(0);
          }
          const value = currentlyActive.data('pickle-option-id') || '';
          methods.select(originalElement, value);
          pickleElement.find('.pickle-button').focus();
          methods.close(originalElement);
        } else if (code === 27) {
          pickleElement.find('.pickle-button').focus();
          methods.close(originalElement);
        } else if (code === 9) {
          e.stopPropagation();
          e.preventDefault();

          const target = $(e.currentTarget);
          const input = target.find('.pickle-input');
          const indexOffset = e.shiftKey ? -2 : 1;

          $(`:input:eq(${$(':input').index(input) + indexOffset})`).focus();
          methods.close(originalElement);
        } else if (code === 38 || code === 40) {
          e.stopPropagation();
          e.preventDefault();

          if (!resultElements.filter('.active').length) {
            resultElements.eq(0).addClass('active');
          } else if (code == 38 || code == 40) {
            const currentlyActive = resultElements.filter('.active').eq(0);

            if (code === 38) {
              // UP ARROW KEY
              if (currentlyActive.prev('.pickle-option').length) {
                currentlyActive.prev('.pickle-option').addClass('active');
                currentlyActive.removeClass('active');
              }
            } else if (code === 40) {
              // DOWN ARROW KEY
              if (currentlyActive.next('.pickle-option').length) {
                currentlyActive.next('.pickle-option').addClass('active');
                currentlyActive.removeClass('active');
              }
            }

            const optionsContainer = pickleElement.find('.pickle-options');
            const inputContainer = optionsContainer.find('.pickle-input-container');

            const inputContainerHeight = inputContainer.height();
            const activeElementOffset = currentlyActive.position().top - inputContainerHeight;
            const containerScrollOffset = optionsContainer.scrollTop();
            const containerHeight = optionsContainer.height();
            const halfHeight = currentlyActive.innerHeight() / 2;

            if (code === 40 && activeElementOffset > containerHeight) {
              const actualOffset = activeElementOffset + containerScrollOffset;
              optionsContainer.scrollTop(actualOffset - containerHeight + halfHeight);
            } else if (code === 38 && activeElementOffset < inputContainerHeight) {
              const actualOffset = activeElementOffset + containerScrollOffset;
              optionsContainer.scrollTop(actualOffset - halfHeight);
            }
          }
        }
      }
    });

    pickleElement.off('mouseenter', '.pickle-option').on('mouseenter', '.pickle-option', (e) => {
      $(e.currentTarget)
        .parent()
        .children('.pickle-option')
        .removeClass('active');
      $(e.currentTarget).addClass('active');
    });

    pickleElement.off('click', '.pickle-option').on('click', '.pickle-option', (e) => {
      e.stopPropagation();
      e.preventDefault();

      const value = $(e.currentTarget).data('pickle-option-id') || '';
      methods.select(originalElement, value);

      $(pickleElement)
        .find('.pickle-button')
        .focus();
      methods.close(originalElement);
    });

    pickleElement
      .find('.pickle-options')
      .off('scroll')
      .on('scroll', (e) => {
        const target = $(e.currentTarget);
        target.children('.pickle-input-container').css({
          top: target.scrollTop(),
        });
      });

    originalElement.off('change').on('change', (e) => {
      privateMethods.renderSelected($(e.currentTarget));
    });

    methods.options(originalElement, initialOptions);

    return this;
  },

  open(element) {
    $('.pickle-activated')
      .not(element)
      .pickle('close');

    methods.refresh(element);

    const {
      pickleElement,
    } = element.data('pickle');
    pickleElement
      .find('.pickle-options')
      .addClass('show')
      .parent()
      .addClass('show');

    if (!methods.searchable(element)) {
      pickleElement.find('.pickle-input-container').prop('hidden', true);
    } else {
      pickleElement.find('.pickle-input-container').prop('hidden', false);
      pickleElement.find('.pickle-input').focus();
    }

    const inputContainerHeight = pickleElement.find('.pickle-input-container').outerHeight();
    pickleElement.find('.pickle-options').attr('style', '');
    const paddingTop = parseInt(pickleElement.find('.pickle-options').css('padding-top'), 10);

    pickleElement.find('.pickle-options').css({
      paddingTop: inputContainerHeight + paddingTop,
    });

    const children = pickleElement.find('.pickle-options').children('li');
    let theChosenOne = null;

    children.removeClass('active');
    if (element.val()) {
      const filtered = children.filter(`[data-pickle-option-id="${element.val()}"]`);
      if (filtered.length) {
        theChosenOne = filtered.eq(0);
      }
    }

    if (!theChosenOne) {
      theChosenOne = children.eq(0);
    }
    $(theChosenOne).addClass('active');
  },

  close(element) {
    const {
      pickleElement,
    } = element.data('pickle');
    pickleElement.find('.pickle-input').val('');
    pickleElement
      .find('.pickle-options')
      .removeClass('show')
      .parent()
      .removeClass('show');
    pickleElement.removeClass('show');
  },

  toggle(element) {
    const {
      pickleElement,
    } = element.data('pickle');

    if (pickleElement.is('.show')) {
      methods.close(element);
    } else {
      methods.open(element);
    }
  },

  disabled(element, newDisabled) {
    const {
      pickleElement,
    } = element.data('pickle');

    if (typeof newDisabled !== 'undefined') {
      element.prop('disabled', newDisabled);
      pickleElement.children('.pickle-button').toggleClass('disabled', newDisabled);
    }

    return element.is(':disabled');
  },

  persistentOptions(element) {
    const options = element.pickle('options');
    return options.filter((option) => option.persist);
  },

  options(element, newOptions, keepCurrent) {
    if (newOptions) {
      const oldOptions = methods.options(element);
      newOptions = newOptions.slice(0);
      const values = methods.valuesArray(element);

      if (keepCurrent) {
        for (let ko = 0; ko < oldOptions.length; ko++) {
          for (let kv = 0; kv < values.length; kv++) {
            const option = oldOptions[ko];
            const value = values[kv];

            if (option.id == value) {
              option.persistent_persist = option.persist;
              option.persist = true;
            }
          }
        }
      }

      for (let ko = 0; ko < oldOptions.length; ko++) {
        const oldOption = oldOptions[ko];
        if (oldOption.persist) {
          let exists = false;
          for (let no = 0; no < newOptions.length; no++) {
            const newOption = newOptions[no];
            if (oldOption.id == newOption.id) {
              exists = true;
              break;
            }
          }
          if (!exists) {
            if (typeof oldOption.persistent_persist !== 'undefined') {
              oldOption.persist = oldOption.persistent_persist;
            }
            newOptions.push(oldOption);
          }
        }
      }

      element.data('pickle').result = newOptions;
      const newHTML = newOptions.reduce((html, option) => {
        const persist = option.persist ? 'true' : 'false';
        return `
					${html}
					<option value="${option.id}" data-pickle-persist="${persist}">
							${option.text}
					</option>
				`;
      }, '');
      element[0].innerHTML = newHTML;

      const newValues = values.filter((value) => getOptionWithID(newOptions, value));
      const changed = newValues.length !== values.length;

      if (methods.multiple(element)) {
        element.val(newValues);
      } else if (values.length) {
        element.val(newValues[0]);
      } else {
        element.val('');
      }

      if (changed) {
        element.trigger('change');
      }

      privateMethods.render(element);
      privateMethods.renderSelected(element);
    }

    return element.data('pickle').result;
  },

  valuesArray(element) {
    let values = element.val();
    if (!values || typeof values !== 'object' || values.constructor !== Array) {
      values = [values];
    }

    return values;
  },

  searchable(element, newSearchable) {
    const searchableOption = element.data('pickle').options.searchable;
    let searchable = false;
    if (searchableOption) {
      if (typeof searchableOption === 'function') {
        const searchableFunc = searchableOption.bind(element);
        searchable = searchableFunc();
      } else {
        searchable = true;
      }
    }

    return searchable;
  },

  multiple(element, newMulti) {
    if (newMulti === false || newMulti === true) {
      element.data('pickle').isMulti = newMulti;
    }

    return element.data('pickle').options.multiple;
  },

  refresh(element) {
    privateMethods.results(element, '', (newOptions) => {
      methods.options(element, newOptions);
    });
  },

  research(element) {
    privateMethods.results(
      element,
      element
        .data('pickle')
        .pickleElement.find('.pickle-input')
        .val(),
      (newOptions) => {
        methods.options(element, newOptions);
      },
    );
  },

  option(element, optionID, newValue) {
    if (newValue) {
      const options = element.pickle('options');

      let option = getOptionWithID(options, optionID);
      if (option) {
        option = newValue;
      } else {
        options.push(newValue);
      }
      element.pickle('options', options);
    }

    return getOptionWithID(element.pickle('options'), optionID);
  },

  select(element, value) {
    if (methods.multiple(element)) {
      const values = methods.valuesArray(element);
      values.push(value);
      element.val(values);
    } else {
      element.val(value);
    }

    $(element).trigger({
      type: 'select',
      value,
    });
    $(element).trigger('change');
  },

  unselect(element, value) {
    if (methods.multiple(element)) {
      const values = methods.valuesArray(element);
      const index = values.indexOf(`${value}`); // I want a valid type system.
      if (index >= 0) {
        values.splice(index, 1);
      }
      element.val(values);
    } else {
      element.val('');
    }

    $(element).trigger({
      type: 'unselect',
      value,
    });
    $(element).trigger('change');
  },
};

$(document).on('click', (e) => {
  const target = $(e.target);

  let ignored = $([]);
  if (target.is('.pickle-container')) {
    ignored = target;
  } else if (target.closest('.pickle-container').length) {
    ignored = target.closest('.pickle-container');
  }

  const $toClose = $('.pickle-activated').filter((_i, el) => {
    const $el = $(el).data('pickle').pickleElement;
    return !ignored.is($el);
  });
  $toClose.pickle('close');
});

$.fn.pickle = function pickleEntry(methodOrOptions, ...args) {
  if (methods[methodOrOptions]) {
    if ($(this).length === 1) {
      args.unshift($(this));
      return methods[methodOrOptions].apply($(this), args);
    }
    $(this).each((_i, el) => {
      const newArgsToPass = args.splice(0);
      newArgsToPass.unshift($(el));
      methods[methodOrOptions].apply($(el), newArgsToPass);
    });
  } else {
    $(this).each((_i, el) => {
      methods.init($(el), methodOrOptions);
    });
  }
  return this;
};

window.Pickle = {};
window.Pickle.defaultCompareResult = function compareResult(result, searchTerm) {
  let text = result.text || '';
  text = $(`<span>${text}</span>`).text(); // Quick 'n' ugly way to get rid of HTML tags.
  text = text.toLowerCase().trim();

  searchTerm = searchTerm || '';
  searchTerm = searchTerm.toLowerCase().trim();

  return text.indexOf(searchTerm) !== -1;
};
