import { ReactElement } from 'react';
import { Flex, HTMLChakraProps } from '@chakra-ui/react';

import { PopoverLink } from 'BootQuery/Assets/components/PopoverTrigger';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

interface EmailListProps {
  emails: string[];
  fontSize?: HTMLChakraProps<'a'>['fontSize'];
}

export const EmailsList = ({
  emails,
  fontSize,
}: EmailListProps): ReactElement => (
  <Flex zIndex={3}>
    <WithSeparator separator=",&nbsp;">
      {emails.map((email) => (
        <PopoverLink
          fontSize={fontSize}
          fontWeight="normal"
          as="a"
          key={email}
          href={`mailto:${email}`}
          title={email}
        >
          {email}
        </PopoverLink>
      ))}
    </WithSeparator>
  </Flex>
);
