import { ModuleEventTypeProviders } from '../types';
import { CustomActionsColumn } from './CustomActionsColumn';
import { CustomContactColumn } from './CustomContactColumn';
import { CustomEventCard } from './CustomEventCard';
import { CustomEventColumn } from './CustomEventColumn';
import { CustomTypeColumn } from './CustomTypeColumn';
import { CustomUserColumn } from './CustomUserColumn';

export { CustomEventCard };

export const customEventProviders: ModuleEventTypeProviders = {
  custom: {
    components: {
      Card: CustomEventCard,
    },
    columns: {
      type: { Cell: CustomTypeColumn },
      event: { Cell: CustomEventColumn },
      user: { Cell: CustomUserColumn },
      contact: { Cell: CustomContactColumn },
      actions: { Cell: CustomActionsColumn },
    },
  },
};
