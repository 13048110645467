import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { StatesMultiselect } from './StatesMultiselect';
import { CaseTypesForm } from './types';

export const StatesMultiselectField = (): ReactElement => {
  const { control } = useFormContext<CaseTypesForm>();
  const { field } = useController({
    control,
    name: 'states',
  });

  return <StatesMultiselect onChange={field.onChange} value={field.value} />;
};
