import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';
import { CallActionsColumn } from './CallActionsColumn';
import { CallContactColumn } from './CallContactColumn';
import { CallEventCard } from './CallEventCard';
import { CallEventColumn } from './CallEventColumn';
import { CallTypeColumn } from './CallTypeColumn';
import { CallUserColumn } from './CallUserColumn';

export { CallEventCard };
export * from './types';

export const callEventProviders: ModuleEventTypeProviders = {
  call: {
    components: {
      Card: CallEventCard,
    },
    columns: {
      type: { Cell: CallTypeColumn },
      event: { Cell: CallEventColumn },
      user: { Cell: CallUserColumn },
      contact: { Cell: CallContactColumn },
      actions: { Cell: CallActionsColumn },
    },
  },
};
