import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';
import { ReactElement } from 'react';

const baseStickyProps: BoxProps = {
  zIndex: 4,
  position: 'sticky',
};

interface CardFooterProps extends BoxProps {
  isSticky?: boolean;
}

const stickBottomProps: BoxProps = {
  ...baseStickyProps,
  bottom: 0,
};

function stickyProps(isSticky = false) {
  return isSticky ? stickBottomProps : {};
}

export const LayoutFooter = ({
  isSticky,
  ...boxProps
}: CardFooterProps): ReactElement => {
  const headerColor = useColorModeValue('#F5F5F5', '#393939');

  return (
    <Box
      w="full"
      bg={headerColor}
      alignItems="center"
      justifyContent="space-between"
      px="6"
      py="4"
      borderTopWidth="1px"
      {...stickyProps(isSticky)}
      {...boxProps}
    />
  );
};
