import { useCallback } from 'react';
import { formatDistanceStrict } from 'date-fns';
import { hr } from 'date-fns/locale';

import { useGetDateLocale } from '@bq/components/use-get-date-locale';

import { formatDuration } from './util';

export const calcDuration = (start: Date, end: Date = new Date()): string => {
  const diffSecs = (end.getTime() - start.getTime()) / 1000;

  // Try to use fancy "human-readable" formats for durations over 24 hours
  if (diffSecs > 60 * 60 * 24) {
    return formatDistanceStrict(start, end, { locale: hr });
  }

  // Format HH:mm:ss for durations under 24h
  return formatDuration(diffSecs);
};

export const useCalcDuration = () => {
  const locale = useGetDateLocale();

  return useCallback(
    (start: Date, end: Date = new Date()): string => {
      const diffSecs = (end.getTime() - start.getTime()) / 1000;

      // Try to use fancy "human-readable" formats for durations over 24 hours
      if (diffSecs > 60 * 60 * 24) {
        return formatDistanceStrict(start, end, { locale });
      }

      // Format HH:mm:ss for durations under 24h
      return formatDuration(diffSecs);
    },
    [locale]
  );
};
