import Module from 'BootQuery/Assets/js/module';
import Vue from 'BootQuery/Assets/js/vue';
import { getUserSetting, getTemplate } from 'BootQuery/Assets/js/BootQuery';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import Socket from 'BootQuery/Assets/js/socket';
import { wsUrlBase } from 'app/assets/js/util';
import SoundMessage from 'app/assets/sounds/short.mp3';
import { pickTicket } from 'app/Modules/Ticketing/Assets/js/pick-ticket';
import { showNotification } from 'BootQuery/Assets/js/web-notifications';
import i18n from 'BootQuery/Assets/js/i18n';
import SoundNewCustomer from '../sounds/new_customer.mp3';
import store from './store';
import { chatEventProviders } from '../components/ChatEvent';

const ChatHistory = () => import('../components/ChatHistory.vue');
const CustomerChat = () => import('../components/CustomerChat.vue');

export default class Chat extends Module {
  get provides() {
    return {
      eventTypes: chatEventProviders,
    };
  }

  async init(_data) {
    super.init();
    this.messageSound = new Audio(SoundMessage);
    this.newCustomerSound = new Audio(SoundNewCustomer);
    console.log('Initialising chat');

    this.loaded = false;
    this.chatSocket = null;
    this.component = null;
    this.chatLimit = 4;
    this.messageFormat = 'text';

    const [groups, chatLimit, messageFormat] = await Promise.all([
      this.getGroups(),
      Api.get('/api/customerChat/chatLimit'),
      Api.get('/api/customerChat/messageFormat'),
    ]);
    this.chatLimit = chatLimit;
    this.messageFormat = messageFormat;

    this.groups = groups;
    if (this.groups.length) {
      const token = await this.getToken();
      this.token = token;
      this.openSocket();
      this.chats = await this.getChats();
      this.loaded = true;
      this.emit('loaded');
      this.updateChatNotifier();
    } else {
      this.chats = [];
      this.loaded = true;
      this.emit('loaded');
    }
  }

  openSocket() {
    this.chatSocket = new Socket(`${wsUrlBase()}/customerChatService/ws`);
    this.chatSocket.on('connect', () => {
      this.chatSocket.send('agentAuth', { token: this.token });
    });

    const messageHandlers = {
      chatAccepted: this.onChatAccepted,
      newCustomer: this.onNewCustomer,
      message: this.onMessage,
      chatEnd: this.onChatEnd,
      authResult: this.onAuthResult,
    };
    this.chatSocket.on('message', (msg) => {
      const { type, data } = JSON.parse(msg);
      const handler = messageHandlers[type];
      if (handler) {
        handler.bind(this)(data);
      } else {
        console.error('Unhandled chat message: ', type, data);
      }
    });

    this.chatSocket.connect();
  }

  onChatAccepted(ev) {
    const acceptedChat = this.chats.find((chat) => chat.ID === ev.chatID);
    if (!acceptedChat) {
      return;
    }
    acceptedChat.agentID = ev.agentID;
    acceptedChat.state = 'active';
    this.updateChatNotifier();
  }

  onNewCustomer(ev) {
    const chat = this.processChat(ev);
    this.chats.push(chat);
    this.updateChatNotifier();
    this.newCustomerSound.play();

    if (!document.hasFocus()) {
      this.showNewChatNotification(chat);
    }
  }

  onMessage(ev) {
    const chat = this.chats.find((chat) => chat.ID === ev.chatID);
    if (!chat) {
      return;
    }

    const message = this.processMessage(ev);
    chat.messages.push(message);

    this.updateChatNotifier();
    this.messageSound.play();
    if (!document.hasFocus()) {
      this.showNewMessageNotification(chat, message);
    }
  }

  onChatEnd(ev) {
    const chatIndex = this.chats.findIndex((chat) => chat.ID === ev.chatID);
    if (chatIndex === -1) {
      return;
    }

    const chat = this.chats[chatIndex];
    if (chat.agentID === window.Bootstrap.bootquery.session.userID) {
      chat.endDate = new Date();
      chat.state = 'ended';
    } else {
      this.chats.splice(chatIndex, 1);
    }
    this.updateChatNotifier();
  }

  onAuthResult(ev) {
    if (!ev.success) {
      console.error('Chat auth failed!');
      return;
    }
    this.sendGroupLogins();
  }

  updateChatNotifier() {
    const menuItem = $('.menu-container .nav-item > .nav-link[data-controller="customerChat"]');
    const menuItemBadge = menuItem.find('.menu-item-notification-counter');
    const waitingCount = this.chats.filter((chat) => chat.state === 'waiting').length;
    menuItemBadge.text(waitingCount);
    menuItemBadge.prop('hidden', waitingCount === 0);
    store.commit('setWaitingChatCount', waitingCount);
  }

  async sendGroupLogins() {
    const loggedInGroups = this.groups.filter((group) => group.loggedIn).map((group) => group.ID);
    this.chatSocket.send('loginGroups', { groups: loggedInGroups });
  }

  async getToken() {
    const resp = await Api.post('/api/customerChat/getAgentToken', {});
    if (!resp.success) {
      throw new Error(`Error getting agent token: ${resp.error}`);
    }
    return resp.token;
  }

  async getChats() {
    const chats = await Api.get('/customerChatService/api/chats');
    return chats.map(this.processChat.bind(this));
  }

  processChat(chat) {
    if (!chat.agentID) {
      // Computed properties fucky with undefineds in this case
      chat.agentID = null;
    }
    chat.waitStart = new Date(chat.waitStart);
    chat.messages = chat.messages.map(this.processMessage.bind(this));
    return chat;
  }

  processMessage(message) {
    message.timestamp = new Date(message.timestamp);
    return message;
  }

  async getGroups() {
    const availableGroups = await this.availableGroups();
    if (!availableGroups || !availableGroups.length) {
      return [];
    }
    let loggedInGroups = await getUserSetting('CustomerChat.loggedInGroups');

    if (loggedInGroups) {
      // PHP semi-randomly decides to return strings here sometimes...
      loggedInGroups = loggedInGroups.map((groupID) => parseInt(groupID, 10));
    } else {
      loggedInGroups = [];
    }

    return availableGroups.map((group) => {
      group.loggedIn = loggedInGroups.includes(group.ID);
      return group;
    });
  }

  availableGroups() {
    return Api.get('/api/customerChat/availableGroups');
  }

  activateElements(target, data) {
    const $chatContainer = target.findElement('#customer-chat-container');
    if ($chatContainer.length) {
      this.renderChatInterface($chatContainer);
    }

    target.findElement('.customerchat-groups-add-btn').ev('click.customerchat', (e) => {
      e.preventDefault();
      this.createGroup(target, data);
    });

    const $historyContainer = target.findElement('#customer-chat-history');
    if ($historyContainer.length) {
      this.renderHistoryInterface($historyContainer, window.Bootstrap.result);
    }

    target.findElement('.assign-chat-to-ticket-btn').ev('click.customerchat', (e) => {
      e.preventDefault();
      const chatID = parseInt($(e.currentTarget).data('chatId'), 10);
      this.openTicketPicker(chatID);
    });
  }

  async renderChatInterface($chatContainer) {
    const chatContainer = $chatContainer[0];
    setTimeout(() => {
      this.component = new Vue({
        el: chatContainer,
        render: (h) => h(CustomerChat),
      });
    }, 0);
  }

  async renderHistoryInterface($historyContainer, data) {
    const historyContainer = $historyContainer[0];
    data.messages = data.messages.map((message) => this.processMessage(message));
    setTimeout(() => {
      this.component = new Vue({
        el: historyContainer,
        render: (h) => h(ChatHistory, { props: data }),
      });
    }, 0);
  }

  async createGroup(target, data) {
    const groupSettingsCardTemplate = await getTemplate('CustomerChat.groupSettingsCard');
    const ownData = this.findTab(data).data;
    const groupsPane = target.find('#customerchat-groups');
    console.log('Groups pane: ', groupsPane);
    const rowAddBtn = groupsPane.find('.customerchat-groups-add-btn');
    console.log('Row add btn: ', rowAddBtn);

    const newID = `new${this.nextNewGroupID(groupsPane)}`;

    // Regex because otherwise only the first occurence is replaced...;
    const formDefJSON = JSON.stringify(ownData.newGroupFormBinding).replace(
      /\$groupID\$/g,
      newID,
    );
    const newFormDef = JSON.parse(formDefJSON);

    const element = $.render(groupSettingsCardTemplate, {
      ID: newID,
      name: 'New group',
      form: newFormDef,
      expand: true,
    });

    const formEl = element.findElement(`[data-form="customerchat-groups-${newID}"]`);
    formEl.form({
      formDefinition: newFormDef,
    });

    element.insertBefore(rowAddBtn);
  }

  nextNewGroupID(target) {
    let maxID = 0;
    target.find('[data-form^="customerchat-groups-new"]').each((i, form) => {
      const idstr = $(form)
        .attr('data-form')
        .match(/customerchat-groups-new(\d+)/)[1];
      if (!idstr) {
        return;
      }
      maxID = Math.max(parseInt(idstr, 10), maxID);
    });

    return maxID + 1;
  }

  findTab(data) {
    return data.result.tabs.find((tab) => tab.key === 'customerChat');
  }

  async openTicketPicker(chatID) {
    const onPick = async (ticket) => {
      await Api.post(`/api/ticketing/tickets/${ticket.ID}/customerChats`, chatID);
      const ticketRow = $.render(await getTemplate('chatTicketRow'), {
        ticketID: ticket.ID,
        ticketTitle: ticket.title,
      });
      const chatRow = $(`[data-datatable-row-id="${chatID}"]`);
      const ticketList = chatRow.find('.chat-tickets');
      ticketList.append(ticketRow);
      chatRow.find('.chat-tickets-popover-btn').prop('hidden', false);
    };

    pickTicket(onPick, {
      title: 'Pridruži chat ticketu',
    });
  }

  showNewChatNotification(chat) {
    const from = this.formatChatFrom(chat);
    const message = chat.messages[0]?.content ?? null;

    const bodyLines = [
      i18n.t('CustomerChat:from', { from }),
      message ? i18n.t('CustomerChat:message_content', { message }) : null,
    ];
    const body = bodyLines.filter((line) => !!line).join('\n');

    showNotification({ title: i18n.t('CustomerChat:new_waiting_chat_title'), body });
  }

  showNewMessageNotification(chat, message) {
    const from = this.formatChatFrom(chat);

    showNotification({
      title: i18n.t('CustomerChat:new_message_title', { from }),
      body: i18n.t('CustomerChat:message_content', { message: message.content }),
    });
  }

  formatChatFrom(chat) {
    const { customer } = chat;
    if (!customer) {
      return '(Unknown)';
    }

    if (customer.data?.email) {
      return `${customer.displayName} (${customer.data.email})`;
    }

    return customer.displayName;
  }
}
