import { MutableRefObject, useCallback, useRef } from 'react';
import { QueryObserverResult, UseQueryResult } from '@tanstack/react-query';

export interface UseDebouncedRefetchResult<TData, TError> {
  refetch: UseQueryResult<TData, TError>['refetch'];
  refetchScheduled: MutableRefObject<boolean>;
}

type ResolveRefetch<TData, TError> = (
  value:
    | QueryObserverResult<TData, TError>
    | PromiseLike<QueryObserverResult<TData, TError>>
) => void;

export function useDebouncedRefetch<TData, TError>(
  doRefetch: UseQueryResult<TData, TError>['refetch'],
  waiting: MutableRefObject<boolean>
): UseDebouncedRefetchResult<TData, TError> {
  const refetchScheduled = useRef(false);
  const waitingRefetches = useRef<ResolveRefetch<TData, TError>[]>([]);

  const refetch: UseQueryResult<TData, TError>['refetch'] = useCallback(
    (options) => {
      // Currently waiting, early return promise to be resolved later
      if (waiting.current) {
        refetchScheduled.current = true;
        const refetchPromise: Promise<QueryObserverResult<TData, TError>> =
          new Promise((resolve, reject) => {
            waitingRefetches.current.push(resolve);
          });

        return refetchPromise;
      }

      refetchScheduled.current = false;

      return doRefetch(options).then((res) => {
        // Resolve all waiter promises
        waitingRefetches.current.forEach((waiter) => waiter(res));

        return res;
      });
    },
    [doRefetch, waiting]
  );

  return {
    refetch,
    refetchScheduled,
  };
}
