module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"User.userMenuToggle"),depth0,{"name":"User.userMenuToggle","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":15,"column":14}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a class=\"dropdown-item\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"controller") : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\">\n					<span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,lookupProperty(helpers,"concat").call(alias3,(depth0 != null ? lookupProperty(depth0,"module") : depth0),":menu_entries.",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":13,"column":41},"end":{"line":13,"column":78}}}),{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":13,"column":36},"end":{"line":13,"column":80}}}))
    + "\n				</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":3},"end":{"line":26,"column":10}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"nav-item dropdown\">\n	<a id=\"user-dropdown\" class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" href=\"#\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"modules"))) && lookupProperty(stack1,"User")),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":5,"column":11}}})) != null ? stack1 : "")
    + "	</a>\n\n	<div class=\"dropdown-menu dropdown-menu-right\" style=\"width: 300px;\">\n		<h6 class=\"dropdown-header\">"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"label.settings",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":30},"end":{"line":9,"column":53}}}))
    + "</h6>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":2},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\n		"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"User.colorModeMenu"),depth0,{"name":"User.colorModeMenu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n		<div class=\"dropdown-divider\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":2},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "	</div>\n</li>";
},"usePartial":true,"useData":true})