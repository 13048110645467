import { ReactElement, useCallback, useState } from 'react';
import { ModalBody, ModalFooter, Progress } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { FormActions } from '@bq/components/FormActions';

import { FormatField } from './FormatField';
import { LimitField } from './LimitField';
import { ExportFormFields, ExportResponse, ExportState } from './types';
import { useExportModalContext } from './use-export-modal-context';

interface Props {
  onClose: () => void;
  setExportState: (state: ExportState) => void;
}

export const ExportModalForm = ({
  onClose,
  setExportState,
}: Props): ReactElement => {
  const { onExport, rowCount, filterExpression, defaultLimit } =
    useExportModalContext();
  const [formState, setFormState] = useFormState();
  const [isExporting, setIsExporting] = useState(false);
  const formMethods = useForm<ExportFormFields>({
    defaultValues: {
      format: 'xlsx',
      limit: Math.min(defaultLimit, rowCount),
    },
  });
  const { reset, handleSubmit } = formMethods;

  const onCancel = useCallback(() => {
    setFormState(null);
    setIsExporting(false);
    reset();
    onClose();
  }, [reset, setFormState, onClose]);
  const onSubmit = useCallback(
    async (data: ExportFormFields) => {
      console.log('Got em datas: ', data);
      setIsExporting(true);
      const response = await onExport({
        limit: data.limit,
        format: data.format,
        filters: filterExpression,
      });
      setIsExporting(false);
      reset();
      setExportState(stateFromResponse(response));
    },
    [reset, setExportState, filterExpression, onExport]
  );

  if (isExporting) {
    return (
      <ModalBody>
        <Progress size="lg" isIndeterminate />
      </ModalBody>
    );
  }

  return (
    <>
      <ModalBody>
        <FormProvider {...formMethods}>
          <form id="export-form" onSubmit={handleSubmit(onSubmit)}>
            <LimitField maxResults={rowCount} />
            <FormatField />
          </form>
        </FormProvider>
      </ModalBody>
      <ModalFooter>
        <FormActions state={formState} form="export-form" onCancel={onCancel} />
      </ModalFooter>
    </>
  );
};

function stateFromResponse(response: ExportResponse): ExportState {
  if (typeof response === 'string') {
    return { type: 'done', downloadAt: response };
  }
  if ('downloadAt' in response) {
    return { type: 'done', downloadAt: response.downloadAt };
  }
  if ('taskId' in response) {
    return { type: 'background', taskId: response.taskId };
  }

  console.error('Invalid export response:', response);
  throw new Error('Invalid export response');
}
