import { useMemo } from 'react';
import { Editor } from '@tiptap/react';
import { wrapAction } from '../../utils';

export const toggleBold = (editor: Editor) => {
  editor.commands.toggleBold();
};

export const toggleItalic = (editor: Editor) => {
  editor.commands.toggleItalic();
};

export const toggleUnderline = (editor: Editor) => {
  editor.commands.toggleUnderline();
};

export const toggleStrike = (editor: Editor) => {
  editor.commands.toggleStrike();
};

export const toggleCode = (editor: Editor) => {
  editor.commands.toggleCode();
};

export const toggleBlockquote = (editor: Editor) => {
  editor.commands.toggleBlockquote();
};

export const useFontToggles = (editor: Editor | null) => useMemo(
  () => ({
    toggleBold: wrapAction(toggleBold, editor),
    toggleItalic: wrapAction(toggleItalic, editor),
    toggleUnderline: wrapAction(toggleUnderline, editor),
    toggleStrike: wrapAction(toggleStrike, editor),
    toggleCode: wrapAction(toggleCode, editor),
    toggleBlockquote: wrapAction(toggleBlockquote, editor),
  }),
  [editor]
);
