import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { isEqual } from 'lodash-es';
import {
  Control,
  UseControllerReturn,
  UseFormGetValues,
} from 'react-hook-form';

import { FormSalesCase } from '../../../schemas';
import { useCaseFormController } from '../use-case-form-controller';
import { EventData } from './types';

export interface UseFormEventChangesResult {
  addFormEvent: (event: EventData) => void;
  removeFormEvent: (event: EventData) => void;
}

export const useFormEventChanges = (): UseFormEventChangesResult => {
  const eventValueContext = useContext(EventValueContext);

  const addFormEvent = useCallback(
    (event: EventData) => {
      if (!eventValueContext) {
        return;
      }

      const prev = eventValueContext.getValues().events ?? [];
      eventValueContext.controller.field.onChange([...prev, event]);
    },
    [eventValueContext]
  );
  const removeFormEvent = useCallback(
    (event: EventData) => {
      if (!eventValueContext) {
        return;
      }

      const prev = eventValueContext.getValues().events ?? [];
      eventValueContext.controller.field.onChange(
        prev.filter((val) => !isEqual(val, event))
      );
    },
    [eventValueContext]
  );

  return {
    addFormEvent,
    removeFormEvent,
  };
};

interface ReturnEventValueContext {
  controller: UseControllerReturn<FormSalesCase, 'events'>;
  getValues: UseFormGetValues<FormSalesCase>;
}

interface IEventValueProvider extends PropsWithChildren {
  control: Control<FormSalesCase>;
  getValues: UseFormGetValues<FormSalesCase>;
}

const EventValueContext = createContext<ReturnEventValueContext | null>(null);

export const EventValueProvider = ({
  children,
  control,
  getValues,
}: IEventValueProvider) => {
  const controller = useCaseFormController('events');

  return (
    <EventValueContext.Provider value={{ controller, getValues }}>
      {children}
    </EventValueContext.Provider>
  );
};
