import { ReactElement } from 'react';
import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  GeneratedForm,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { FormAddress } from '../FormAddress';
import { FormMails } from '../FormMails';
import { FormPhoneNumbers } from '../FormPhoneNumbers';
import { CompanyLocationFormData } from './types';
import { CompanyInput } from '../PersonForm/CompanyInput';

interface Props {
  companyID?: string | number;
}
export const CompanyLocationFormFields = ({
  companyID,
}: Props): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanyLocationFormData>();

  const customFields = useFormDefinition('Phonebook.companyLocation');

  return (
    <>
      <FormControl mb="5" isInvalid={!!errors.company}>
        <FormLabel fontWeight="bold">{t('Phonebook:company')}:</FormLabel>
        <CompanyInput isRequired companyID={companyID} />
      </FormControl>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">{t('Phonebook:name')}:</FormLabel>
        <Input
          {...register('name', { required: true })}
          isInvalid={!!errors.name}
          placeholder={t('Phonebook:name')}
        />
      </FormControl>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">{t('Phonebook:location_mark')}:</FormLabel>
        <Input
          {...register('data.locationMark', { required: false })}
          isInvalid={!!errors.data?.locationMark}
          placeholder={t('Phonebook:location_mark')}
        />
      </FormControl>
      <Box mb="5">
        <FormAddress name="address" />
      </Box>
      <Box mb="5">
        <FormMails />
      </Box>
      <Box mb="5">
        <FormPhoneNumbers />
      </Box>
      <Box mb="5">
        {customFields ? (
          <GeneratedForm fields={customFields} />
        ) : (
          <LoadingPage />
        )}
      </Box>
    </>
  );
};
