import mitt from 'mitt';

import { NewMessageParams } from 'app/Modules/Mails/Assets/components/EventTypes';
import { Call } from 'app/Modules/Telephony/Assets/types';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

export type TicketingEventTypes = {
  'mail/newEditor': NewMessageParams;
  'call/new': Call;
  'call/remove': number;
  'chat/remove': number;
  'tab/switch': string;
};

export const ticketingEventBus = mitt<TicketingEventTypes>();
export const useTicketingEvent = createUseMitt(ticketingEventBus);
