import { MenuItem } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFileExport } from 'react-icons/fa';

interface Props {
  link: string;
}

export const ExportLink = ({ link }: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  return (
    <MenuItem as="a" href={link} download>
      <FaFileExport />
      &nbsp; {t('global:export')}
    </MenuItem>
  );
};
