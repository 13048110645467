import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AgentPermissions, AgentsPermissionsResult } from '@bq/components/ReportAgentsPermissions';
import { getCurrentPermissions, setInputValue } from './util';

export function useAgentsPermissions(
  groupID: number | string
): AgentsPermissionsResult {
  return useQuery({
    queryFn: () => getCurrentPermissions('reportAgents', groupID),
    queryKey: ['Ticketing.reportAgentsPermission', groupID],
  });
}

export function useSetAgentPermissions(
  groupID: number | string
): (permissions: AgentPermissions) => void {
  const queryClient = useQueryClient();

  return useCallback(
    (permissions: AgentPermissions) => {
      setInputValue('reportAgents', permissions);
      queryClient.setQueryData(
        ['Ticketing.reportAgentsPermission', groupID],
        permissions
      );
    },
    [groupID, queryClient]
  );
}
