import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const billingStartDateFilter = makeDateFilter({
  name: () => i18n.t('Sales:billing_start_date'),
  toFilter: makeDateToFilter('billingStartDate'),
});
