import EventEmitter from 'events';
import Quill from 'BootQuery/Assets/js/quill';
import { quillOptions } from 'BootQuery/Assets/js/quill/quill-options';

export default class SignatureEditor extends EventEmitter {
  constructor(signature, availableTemplates = {}) {
    super();
    this.signature = signature;
    this.availableTemplates = availableTemplates;
  }

  async show(container) {
    this.editor = new Quill($(container).get(0), {
      ...quillOptions,
      placeholder: 'Signature...',
      modules: {
        ...quillOptions.modules,
        toolbar: [
          ...quillOptions.modules.toolbar,
          [{ template: Object.values(this.availableTemplates) }],
        ],
        template: this.templateNameMappings(),
      },
    });
    this.editor.setContents(this.signature);
  }

  data() {
    return this.editor.getContents();
  }

  templateNameMappings() {
    return Object.entries(this.availableTemplates).reduce((templates, [name, displayName]) => {
      templates[displayName] = name;
      return templates;
    }, {});
  }
}
