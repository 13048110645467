import { FormControl, FormLabel, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CaseTypeField } from './FormFields/CaseTypeFormField';
import { NameField } from './FormFields/NameFormField';

export const CampaignFormFields = () => {
  const { t } = useTranslation('Sales');

  return (
    <VStack>
      <FormControl>
        <FormLabel fontWeight="bold">{t('Sales:campaign.form.name')}</FormLabel>
        <NameField />
      </FormControl>
      <FormControl>
        <FormLabel fontWeight="bold">
          {t('Sales:campaign.form.case_type')}
        </FormLabel>
        <CaseTypeField />
      </FormControl>
    </VStack>
  );
};
