import { FilterType, FilterTypes } from 'BootQuery/Assets/components/FilterBar';
import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';

import { FieldValue } from '../../types';
import { SelectFieldSettings, SelectSettings } from './types';

type Field = FieldValue<SelectFieldSettings>;

interface Item {
  key: string;
  name: string;
}

function selectToFilter(field: Field, select: SelectSettings): FilterType {
  return makeComboboxFilter({
    name: select.name,
    toFilter: ({ value, operator }) => {
      // Nullability filters have no value
      if (operator === 'null') {
        return { [`data.${select.id}:null`]: true };
      }
      if (operator === 'notnull') {
        return { [`data.${select.id}:null`]: false };
      }

      // Other filters require a value
      if (!value) {
        return null;
      }

      const path = `data.${select.id}`;

      if (typeof value === 'string') {
        const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

        return { [`${path}:string:${containsOp}:ci`]: value || '' };
      }

      return { [`${path}:string:${operator ?? 'eq'}`]: value.key };
    },
    operators: getDefaultComboboxOperators(true),
    extraProps: {
      cacheKey: `$c-${field.id}-${select.id}`,
      search: async (search: string) => {
        const { data } = await Api.get(
          `/api/forms/customLists/${select.listID}/items`,
          {
            params: {
              fields: ['key', 'name'],
              filters: { 'name:contains:ci': search },
            },
          }
        );

        return data as Item[];
      },
      itemToString: (item: Item) => item.name,
      valueToItem: async (value: Item): Promise<Item> => value,
      itemToValue: (item: Item): Item => item,
    },
  });
}

export function getFilters(field: Field): FilterTypes {
  return field.settings.selects.reduce<FilterTypes>(
    (acc, select) => ({
      ...acc,
      [`$c-${field.id}-${select.id}`]: selectToFilter(field, select),
    }),
    {} as FilterTypes
  );
}
