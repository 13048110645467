import qs from 'qs';
import { Api } from 'BootQuery/Assets/js/api';
import { CHANNEL_TYPES } from './in2-constants';

export async function getEmbedderHost() {
  const { data: url } = await Api.get('/api/dynamics/dynamicsHost');

  return url;
}

async function loadCifLibrary() {
  const embedder = await getEmbedderHost();
  const scriptLocation = `${embedder}/webresources/Widget/msdyn_ciLibrary.js`;

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;

    script.type = 'text/javascript';
    script.dataset.crmurl = embedder;
    script.dataset.cifid = 'CIFMainLibrary';
    script.src = scriptLocation;
    document.head.appendChild(script);
  });
}

export async function getCIF() {
  if (!window.Microsoft || !window.Microsoft.CIFramework) {
    await loadCifLibrary();
  }

  return window.Microsoft.CIFramework;
}

export function generateODataQuery(params) {
  const { filter, select, expand } = params;

  const queryObj = {};
  if (select) {
    const selectStr = Array.isArray(select) ? select.join(',') : select;
    queryObj.$select = selectStr;
  }
  if (expand) {
    const expandStr = Array.isArray(expand) ? expand.join(',') : expand;
    queryObj.$expand = expandStr;
  }
  if (filter) {
    const filterStr = Array.isArray(filter) ? filter.join(',') : filter;
    queryObj.$filter = filterStr;
  }

  const queryStr = qs.stringify(queryObj);
  return `?${queryStr}`;
}

export function searchByNumberFilter(phoneNumber) {
  const cleanNum = phoneNumber.replace(/^00/, '+');

  return `
		in2_contact_in2_contact_data_Customer/any(c:
			c/in2_contact_value eq '${cleanNum}'
			and (
				c/in2_channel eq ${CHANNEL_TYPES.Phone}
				or c/in2_channel eq ${CHANNEL_TYPES.Mobile}
			)
		)
	`.trim().replace(/\s+/g, ' ');
}

export async function searchContacts(params) {
  const cif = await getCIF();
  const jsonRet = await cif.searchAndOpenRecords('contact', generateODataQuery(params), true);
  const ret = JSON.parse(jsonRet);

  return Object.values(ret);
}

/**
 * @param {string|string[]} contactid
 */
export async function openContact(contactIds) {
  if (!Array.isArray(contactIds)) {
    contactIds = [contactIds];
  }

  const contactFilters = contactIds.map((id) => `contactid eq '${id}'`);

  const cif = await getCIF();
  const query = generateODataQuery({
    select: ['contactid'],
    filter: [contactFilters.join(' or ')],
  });
  console.log('Open: ', query);

  return cif.searchAndOpenRecords('contact', query, false);
}

/**
 * @param {string|string[]} contactid
 */
export async function openContactForNumber(phoneNumber, forceSearch) {
  const cif = await getCIF();
  let query = generateODataQuery({
    select: ['contactid'],
    filter: searchByNumberFilter(phoneNumber),
  });
  if (forceSearch) {
    query += `&$search=${phoneNumber}`;
  }

  return cif.searchAndOpenRecords('contact', query, false);
}

/**
 * @param {string|string[]} contactid
 */
export async function getContact(contactId, select = ['contactid', 'fullname'], expand = []) {
  const cif = await getCIF();
  const query = generateODataQuery({
    select,
    expand,
    filter: `contactid eq '${contactId}'`,
  });

  const retJSON = await cif.searchAndOpenRecords('contact', query, true);
  return JSON.parse(retJSON)[0] || null;
}

/**
 *
 * @param {string} entityType
 * @param {boolean} quickCreate
 * @param {object} formData
 */
export async function openForm(entityType, quickCreate = true, formData = {}) {
  const formOptions = { entityName: entityType, useQuickCreateForm: quickCreate };
  const cif = await getCIF();

  return cif.openForm(
    JSON.stringify(formOptions),
    JSON.stringify(formData || {})
  );
}
