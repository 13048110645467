import i18n from 'BootQuery/Assets/js/i18n';
import { ItemColumn } from '../types';
import { StaticCheckboxCell } from '../Cells/StaticCheckboxCell';

export const serviceColumns = (): ItemColumn[] => [isThirdPartyServiceColumn()];

export const isThirdPartyServiceColumn = (): ItemColumn => ({
  key: 'isThirdPartyService',
  title: i18n.t('Products:is_third_party_service'),
  order: 210,
  Cell: (props) => {
    if (props.row.productType === 'service') {
      return (
        <StaticCheckboxCell
          value={props?.row?.service?.isThirdPartyService ?? false}
        />
      );
    }

    return <>-</>;
  },
});
