import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterMailAttachment = makeTextFilter({
  name: () => i18n.t('Ticketing:has_attachment'),
  toFilter: ({ operator, value }) => {
    return [
      'mailEvents',
      '$any',
      [['message.attachments', '$any', [['filename', operator, value || '']]]],
    ];
  },
});
