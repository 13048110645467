import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { Template } from './types';

export function useTemplates(): UseQueryResult<Template[]> {
  return useQuery({
    queryKey: ['Sales.settings.templates'],
    queryFn: getTemplates,
  });
}

export async function getTemplates(): Promise<Template[]> {
  const { data } = await Api.get<ListingResponse<Template>>(
    '/api/sales/offerDocumentTemplates',
    {
      params: {
        fields: ['$full'],
        limit: null,
        withCount: false,
      },
    }
  );

  return data.data;
}
