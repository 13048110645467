module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n	<td colspan=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"columnCount") : depth0), depth0))
    + "\">\n		<div class=\"d-flex justify-content-between align-items-baseline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"footer") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":9,"column":10}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"report")),{"name":"unless","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":3},"end":{"line":59,"column":14}}})) != null ? stack1 : "")
    + "		</div>\n	</td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"align-self-center\">\n				"
    + ((stack1 = container.invokePartial(lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,"footer",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":7,"column":7},"end":{"line":7,"column":29}}}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "			</div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasFooterActions") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":3},"end":{"line":58,"column":10}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"align-self-center ml-auto text-right d-flex\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"rowActions") : depth0)) != null ? lookupProperty(stack1,"custom") : stack1),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":23,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"rowActions") : depth0)) != null ? lookupProperty(stack1,"deletable") : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMultipleAddActions") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.program(18, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":56,"column":11}}})) != null ? stack1 : "")
    + "			</div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<button\n						data-table-action=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"action") : depth0), depth0))
    + "\"\n						data-table=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"tableName") : depths[1]), depth0))
    + "\"\n						class=\"btn btn-secondary ml-2\"\n						disabled\n					>\n						"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n					</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<button type=\"submit\" class=\"btn btn-danger table-delete-btn ml-2\" data-table=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "\" data-table-action=\"delete\"\n				 disabled>\n					<span class=\"fa fa-trash-alt\"></span>&nbsp;"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"button.delete",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":27,"column":48},"end":{"line":27,"column":70}}}))
    + "\n				</button>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<div class=\"dropdown\">\n					<button type=\"button\" class=\"btn btn-success dropdown-toggle ml-2\" data-toggle=\"dropdown\">\n						<span class=\"fa fa-plus\"></span>&nbsp;"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"button.add",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":44},"end":{"line":33,"column":63}}}))
    + "\n					</button>\n					<div class=\"dropdown-menu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"rowActions") : depth0)) != null ? lookupProperty(stack1,"add") : stack1),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":6},"end":{"line":46,"column":15}}})) != null ? stack1 : "")
    + "					</div>\n				</div>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,lookupProperty(helpers,"lookupMap").call(alias3,depths[1],(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"lookupMap","hash":{},"data":data,"loc":{"start":{"line":37,"column":81},"end":{"line":37,"column":107}}}),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":37,"column":59},"end":{"line":37,"column":109}}}))
    + "\" class=\"dropdown-item\"\n						 data-table-action=\"add\" "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,lookupProperty(helpers,"lookupMap").call(alias3,depths[1],(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"lookupMap","hash":{},"data":data,"loc":{"start":{"line":38,"column":39},"end":{"line":38,"column":65}}}),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":31},"end":{"line":38,"column":102}}})) != null ? stack1 : "")
    + ">\n							<span class=\"fa fa-plus\"></span>&nbsp;\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.program(16, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":40,"column":7},"end":{"line":44,"column":14}}})) != null ? stack1 : "")
    + "						</a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "=\""
    + alias2(alias1(depth0, depth0))
    + "\" ";
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "\n";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"rowActions") : depth0)) != null ? lookupProperty(stack1,"add") : stack1)) != null ? lookupProperty(stack1,"0") : stack1),{"name":"with","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":55,"column":13}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<a href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"method") : depth0), depth0))
    + "/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":51,"column":57},"end":{"line":51,"column":87}}}))
    + "\" class=\"btn btn-success ml-2\"\n				 data-table-action=\"add\" "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,lookupProperty(helpers,"lookupMap").call(alias3,depths[1],(depth0 != null ? lookupProperty(depth0,"params") : depth0),{"name":"lookupMap","hash":{},"data":data,"loc":{"start":{"line":52,"column":37},"end":{"line":52,"column":63}}}),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":29},"end":{"line":52,"column":100}}})) != null ? stack1 : "")
    + ">\n					<span class=\"fa fa-plus\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.add",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":53,"column":43},"end":{"line":53,"column":62}}}))
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":62},"end":{"line":53,"column":90}}})) != null ? stack1 : "")
    + "\n				</a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"shouldShowFooterRow") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":63,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})