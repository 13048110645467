import {
  As,
  chakra,
  ChakraComponent,
  ChakraStyledOptions,
  SystemStyleObject,
} from '@chakra-ui/react';

// This type wasn't exported from chakra so we duplicate it here
export type StyleResolverProps = SystemStyleObject & {
  __css?: SystemStyleObject;
  sx?: SystemStyleObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  theme: any;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export interface FixedChakraStyledOptions<P = {}>
  extends Omit<ChakraStyledOptions, 'baseStyle'> {
  baseStyle?:
    | SystemStyleObject
    | ((props: StyleResolverProps & P) => SystemStyleObject);
}

export type FixedChakraFactory = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  <T extends As, P extends object = {}>(
    component: T,
    options?: FixedChakraStyledOptions<P>
  ): ChakraComponent<T, P>;
};

/**
 * A replacement for the chakra(Component, options) function that passes
 * extra props to baseStyle callback
 *
 * @example
 *
 * interface TestProps { miscFlag: boolean; }
 * export const MyBox = fixedChakraFactory<typeof Box, TestProps>(Box, {
 *     baseStyle({ miscFlag }) {
 *         return {
 *         display: 'flex',
 *         color: miscFlag ? 'red.200' : 'gray.200',
 *         };
 *     },
 * });
 *
 *
 */
export const fixedChakraFactory: FixedChakraFactory =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (component, options) => chakra(component, options as any);
