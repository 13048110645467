import $ from 'jquery';

import { forEach, reduce } from 'lodash-es';

import Popper from 'popper.js';
import { templates, Handlebars } from './templates';

import Locales from './locales';

import 'bootstrap';
import 'flatpickr';
import 'app/assets/js/pickle';

import Socket from './socket';

import 'BootQuery/Assets/js/form';
import { matchReactRoute } from './react-routes';
import { renderReactRouteRoute } from './activate-react';

window.$ = $;
window.jQuery = $;

window.addEventListener('unhandledrejection', (e) => {
  e.preventDefault();
  if (e.detail && e.detail.reason) {
    throw e.detail.reason;
  } else if (e.reason) {
    throw e.reason;
  } else {
    console.error('Unhandled rejection event: ', e);
    throw new Error('Unhandled rejection, no idea what happened');
  }
});
window.Handlebars = Handlebars;
window.templates = templates;
window.Popper = Popper;
window.Locales = Locales;
window.Socket = Socket;

const importModules = (r) => reduce(
  r.keys(),
  (modules, key) => {
    const moduleName = key.split('/')[1];
    modules[moduleName] = r(key).default;
    return modules;
  },
  {},
);

const Modules = importModules(
  require.context('../../../app/Modules', true, /\.\/[^/]+\/Assets\/js\/index\.[jt]s$/),
);

class BootQuery {
  constructor() {
    this.moduleInstances = {};

    $(document).on('activateElements.bq', (e, target, data) => {
      forEach(this.moduleInstances, (moduleInstance, moduleName) => {
        if (typeof moduleInstance.activateElements === 'function') {
          moduleInstance.activateElements(target, data, e);
        }
      });
    });
  }

  getModuleInstance(moduleName) {
    if (!Modules[moduleName]) {
      return null;
    }
    if (!this.moduleInstances[moduleName]) {
      this.moduleInstances[moduleName] = new Modules[moduleName]();
    }
    return this.moduleInstances[moduleName];
  }

  getProviders(forWhat) {
    return Object.values(this.moduleInstances)
      .map((mod) => {
        if (mod && mod.provides && mod.provides[forWhat]) {
          return mod.provides[forWhat];
        }
        return null;
      })
      .filter((provider) => provider !== null)
      .sort((providerA, providerB) => providerA.priority - providerB.priority);
  }

  getFirstProvider(forWhat) {
    return this.getProviders(forWhat)[0] || null;
  }

  /**
	 * Lets the module with manual route handler manually handle the route
	 * @param {string} routePath
	 *
	 * @returns {boolean} true if route handled
	 */
  handleRouteManually(routePath, newBq) {
    return Object.entries(Modules).some(([moduleName, module]) => {
      // Modules without JS have their keys in there too
      if (!module) {
        return false;
      }

      const updateBqRoute = () => {
        const bq = window.Bootstrap.bootquery;
        bq.controller = newBq.controller;
        bq.method = newBq.method;
        bq.parameters = newBq.parameters;
      };

      if (module.canHandleRoute(routePath)) {
        updateBqRoute();

        const instance = this.getModuleInstance(moduleName);
        instance.handleRoute(routePath);
        return true;
      }

      if (module.matchReactRoute && matchReactRoute(module.matchReactRoute, routePath)) {
        this.getModuleInstance(moduleName); // Initialise module
        updateBqRoute();
        renderReactRouteRoute();
        return true;
      }
      return false;
    });
  }
}

window.BootQuery = new BootQuery();
