import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  GetOneParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { Dataset } from '../types';

export const listDatasets = async <T = Dataset>(
  params: GetListingParams = {}
): Promise<T[]> => {
  const { data } = await Api.get<ListingResponse<T>>('/api/datasets/datasets', {
    params,
  });

  return data.data;
};

export const getDataset = async <T = Dataset>(
  ID: number,
  params: GetOneParams = {}
): Promise<T> => {
  const { data } = await Api.get<T>(`/api/datasets/datasets/${ID}`, {
    params,
  });

  return data;
};
