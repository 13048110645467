import { Box, chakra, Checkbox } from '@chakra-ui/react';
import { useTableSelection } from 'BootQuery/Assets/components/Table';
import { OBJ } from 'BootQuery/Assets/components/Table/types';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';

export const ContactSelect = <T extends OBJ>(props: T): ReactElement => {
  const wrapperRef = useRef<HTMLDivElement>();
  const { selection, toggleSelected, idColumn } = useTableSelection<T>();
  const checked = selection.some((item) => item[idColumn] === props[idColumn]);
  const onChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      toggleSelected(props, ev.target.checked);
    },
    [toggleSelected, props]
  );
  const onCellClick = useCallback(
    (ev: MouseEvent<HTMLDivElement>) => {
      if (ev.target === wrapperRef.current) {
        toggleSelected(props);
      }
    },
    [toggleSelected, props]
  );

  return (
    <CellWrapper ref={wrapperRef} onClick={onCellClick} style={inlineAbsolute}>
      <Checkbox
        isChecked={checked}
        onChange={onChange}
        mb={0}
        zIndex={3}
        colorScheme="brand"
      />
    </CellWrapper>
  );
};

const CellWrapper = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    w: '20px',
  },
});

const inlineAbsolute = { position: 'absolute' };
