import Module from 'BootQuery/Assets/js/module';

// import Vue from 'BootQuery/Assets/js/vue';
import urlParse from 'url-parse';
import { v4 as uuid } from 'uuid';
import { Api } from 'BootQuery/Assets/js/api';
// import getRouter from './router';
// import DynamicsComponent from '../components/Dynamics.vue';
import { getCIF } from './cif-util';
import store from './store';
import { cleanGUID } from '../../../../assets/js/util';

// function getDialer() {
//   return window.BootQuery.getModuleInstance('Dialer');
// }

export default class Dynamics extends Module {
  async init() {
    super.init();

    const cif = await getCIF();
    cif.setClickToAct(true);
    cif.addHandler('onclicktoact', (eventJSON) => {
      const {
        format, value, entityLogicalName, entityId,
      } = JSON.parse(eventJSON);
      console.log('CLICK TO ACT: ', JSON.parse(eventJSON));

      if (format === 'Phone') {
        let actionID = null;
        if (entityLogicalName === 'phonecall') {
          actionID = `{${uuid()}}`;
          this.storeScheduledCall(entityId, actionID);
        }
        this.call(value, actionID);
      } else {
        console.warn('Unrecognised clickToAct format: ', value);
      }
    });
    cif.addHandler('onpagenavigate', (eventJSON) => {
      const { value: url } = JSON.parse(eventJSON);
      this.emit('dynamicsNavigate', url);
      this.setDynamicsContext(urlParse(url, true).query);
    });
    cif.getEnvironment().then((envJSON) => {
      const env = JSON.parse(envJSON);
      this.setDynamicsContext(env);
      store.commit('setDynamicsUser', {
        id: cleanGUID(env.userId),
        username: env.username,
      });
    });

    this.socketEvents.subscribeWebSocket('dynamics/phoneCallInserted', this.onPhoneCallInserted.bind(this));
  }

  onPhoneCallInserted(data) {
    console.log('Phone call inserted?', data);
    this.emit('phoneCallInserted', data);
  }

  storeScheduledCall(dynamicsCallId, originateUUID) {
    return Api.post('/api/dynamics/scheduledCall', { dynamicsCallId, originateUUID });
  }

  call(number, actionID = null) {
    // this.component.$router.push({
    //   path: '/cif/phone/',
    // });
    // return getDialer().dial(number, actionID);
  }

  newSms(toNumber) {
    // this.component.$router.push({
    //   path: '/cif/sms/conversations/new/',
    //   query: { toNumber },
    // });
  }

  setDynamicsContext(context) {
    store.dispatch('changeDynamicsContext', context);
  }

  activateElements(target, data) {
    const cifContainer = document.querySelector('#ms-dynamics-cif-widget');
    if (cifContainer) {
      this.renderCifInterface(cifContainer);
    }
  }

  handleRoute(route) {
    if (route.startsWith('/dynamics/cif')) {
      $(document).trigger('renderController', [window.targetElement, window.Bootstrap]);
      this.renderCifRoute();
      return;
    }
    throw new Error(`Don't know how to handle route: '${route}'`);
  }

  static canHandleRoute(route) {
    if (route.startsWith('/dynamics/cif')) {
      console.log('Can handle route from CIF');
      return true;
    }
    return false;
  }

  async renderCifInterface(cifContainer, routeChanged = false) {
    if (cifContainer.dataset.activated) {
      if (routeChanged) {
        const vueInstance = cifContainer.__vue__; // eslint-disable-line no-underscore-dangle
        const newRoute = `${window.location.pathname}${window.location.search}`.replace(/^\/dynamics/, '');
        vueInstance.$router.replace(newRoute);
      }
      return; // Don't double-render
    }
    cifContainer.dataset.activated = true;
    // this.component = new Vue({
    //   el: cifContainer,
    //   store,
    //   router: getRouter(),
    //   render: (h) => h(DynamicsComponent),
    // });
  }

  renderCifRoute() {
    const existing = document.querySelector('#ms-dynamics-cif-widget');
    if (existing) {
      this.renderCifInterface(existing, true);
    } else {
      const target = $('<div/>', { id: 'ms-dynamics-cif-widget' });
      $(window.targetElement).html(target);
      this.renderCifInterface(target[0], true);
    }
    $(document).trigger('activateElements', [$(window.targetElement), window.Bootstrap]);
  }
}
