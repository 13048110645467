module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div>\n                <small>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"detail") : depth0), depth0))
    + "</small>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n<html>\n<head>\n\n	<meta charset=\"utf-8\">\n	<meta name=\"viewport\" content=\"width=device-width, user-scalable=yes, initial-scale=1, maximum-scale=1\">\n	<link rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/logo_ast.png\" type=\"image/png\">\n	<title>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + "</title>\n\n    <link rel=\"stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias3,"fonts.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":10,"column":33},"end":{"line":10,"column":65}}}))
    + "\">\n    <link rel=\"stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias3,"theme_default.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":73}}}))
    + "\" class=\"theme-stylesheet\" title=\"default\">\n</head>\n\n<body class=\"bg-light\">\n<div class=\"container py-3\">\n    <div class=\"jumbotron text-center\">\n        <h1 class=\"display-4\">\n            <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"code") : depth0), depth0))
    + "</strong>: "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "\n        </h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"detail") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "        <a href=\"/\" class=\"noparse btn btn-lg btn-primary mt-3\">\n           <span class=\"fa fa-chevron-left\"></span> Vrati se\n        </a>\n    </div>\n</div>\n</body>";
},"useData":true})