import { Flex, Text } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NationalNumberApiData } from '../types';
import { getNumberTypeTitleById } from './number-types';

interface Props {
  nationalNumber?: NationalNumberApiData | null;
}

export const StaticNationalNumber = ({
  nationalNumber,
}: Props): ReactElement => {
  const { t } = useTranslation('Phonebook');

  const typeLabel = getTypeLabel(nationalNumber?.type ?? null) ?? t('Phonebook:national_number');

  return (
    <Flex alignItems="center">
      <Text as="strong" fontWeight="bold">{typeLabel}:</Text>&nbsp;
      {nationalNumber?.nationalNumber ?? '-'}
    </Flex>
  );
};

function getTypeLabel(numberType: NationalNumberApiData['type']): string | null {
  let typeLabel = null;
  if (numberType?.type && numberType.countryISO) {
    const { countryISO, type } = numberType;
    typeLabel = getNumberTypeTitleById(`${countryISO}-${type}`);
  }

  return typeLabel;
}
