import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getManyCampaign } from '../../api/Campaign/getManyCampaign';
import { Campaign } from '../../schemas';

export function useCampaigns(): UseQueryResult<ListingResponse<Campaign>> {
  return useQuery({
    queryKey: ['Sales.settings.campaigns'],
    queryFn: () => getManyCampaign(),
  });
}
