import { Api } from 'BootQuery/Assets/js/api';
import {
  DefaultPatchReturn,
  DefaultPostReturn,
} from 'BootQuery/Assets/js/globalTypes';
import { PatchCompany, PostCompany } from './types';

export const patchCompany = <ReturnType = DefaultPatchReturn>({
  data,
  params,
  ID,
}: PatchCompany) => {
  return Api.patch<ReturnType>(`/api/phonebook/companies/${ID}`, data, {
    params,
  });
};

export const postCompany = <ReturnType = DefaultPostReturn>({
  data,
  params,
}: PostCompany) => {
  return Api.post<ReturnType>('/api/phonebook/companies', data, { params });
};
