import { makeComboboxFilter } from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { TicketGroup } from '../types';

export const ticketFilterGroup = makeComboboxFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return { 'group.name:contains:ci': value };
    }
    if (typeof value === 'object') {
      const { ID } = value;

      return {
        'group.ID': ID,
      };
    }

    return null;
  },
  extraProps: {
    cacheKey: 'ticketFilterGroup',
    search: async (search: string) => {
      const { data } = await Api.get<TicketGroup[]>('/api/ticketing/groups', {
        params: {
          filters: search ? { $search: search } : {},
          fields: ['ID', 'name'],
        },
      });

      return data;
    },
    itemToString: (item: TicketGroup) => item.name,
    enableTextSearch: true,
    itemToValue: (item: TicketGroup) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<TicketGroup>(
        `/api/ticketing/groups/${value.id}`,
        {
          params: { fields: ['ID', 'name'] },
        }
      );

      return data;
    },
  },

  name: () => i18n.t('Ticketing:form.group'),
  operators: [],
});
