import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, TableRowProps } from '@chakra-ui/react';
import { difference, isEqual, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { NoDataMessage } from '@bq/components/NoDataMessage';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import {
  Table,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';
import {
  defaultDisplayModeOptions,
  RefreshMethod,
} from 'BootQuery/Assets/components/TableMenu';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

import { Call } from '../../../types';
import { generateExport, getCalls } from '../api';
import { CallListPermissions } from '../call-list-permissions';
import { CallListContactModalProvider } from '../CallListContactModalProvider';
import { CallListHeading } from '../CallListHeading';
import { useTableColumns } from '../columns';
import { useFilterTypes } from '../filter-types';
import { formatCall } from '../format-call';
import { PageWithPlayer } from '../RecordingPlayer';
import { CallListCall } from '../types';
import { useCalls } from '../use-calls';
import { useSelectedOverview } from '../use-selected-overview';
import { CallsTableFooter } from './CallsTableFooter';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const CallsTable = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const [refreshMethod, setRefreshMethod] = useRefreshMethod();
  const overview = useSelectedOverview(overviews);
  const prevRowIds = useRef<string[] | null>(null);
  const [highlightedRows, setHighlightedRows] = useState<string[]>([]);
  const columns = useTableColumns(customFields);
  const filterTypes = useFilterTypes(customFields);
  const listingSettings = useListingSettings<CallListCall>({
    listingName: 'Telephony.CallList',
    viewName: 'table',
    filterTypes,
    columns,
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
  });

  const { columnsToShow, page, limit, density, filters } = listingSettings;

  // Never remove the useTranslation to force Telephony translations to load
  const { t } = useTranslation('Telephony');

  const { calls, status, shownCallIds, newRowIds, refetch } = useCalls({
    page,
    limit,
    filters,
    refreshMethod,
  });
  const itemCount = calls?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    displayModeOptions: defaultDisplayModeOptions,
    count: itemCount,
    menuChildren: <ExportMenuItem />,
  });

  useEffect(() => {
    const rowDiff = prevRowIds.current
      ? difference(shownCallIds, prevRowIds.current)
      : [];
    const newHighlighted = uniq([...highlightedRows, ...rowDiff]);
    prevRowIds.current = shownCallIds;
    if (!isEqual(newHighlighted, highlightedRows)) {
      setHighlightedRows(newHighlighted);

      // Clear highlight after 1s
      setTimeout(() => {
        setHighlightedRows(
          highlightedRows.filter((hl) => !newHighlighted.includes(hl))
        );
      }, 1000);
    }

    return undefined;
  }, [shownCallIds, highlightedRows, setHighlightedRows]);
  const trProps = useCallback(
    (row: CallListCall): TableRowProps => ({
      bg: highlightedRows.includes(row.callId) ? 'green.100' : undefined,
      transition: 'background-color 0.5s ease-out',
    }),
    [highlightedRows]
  );

  let rows: CallListCall[] = [];
  if (status === 'success' && calls) {
    rows = calls.data.map(formatCall);
  }

  const footer = useMemo(
    () => <CallsTableFooter count={itemCount} />,
    [itemCount]
  );
  if (!rows) {
    return <LoadingPage />;
  }

  return (
    <PageWithPlayer>
      <CallListContactModalProvider>
        <TableSelectionProvider<Call>
          idColumn="ID"
          page={page}
          selectAll={async () => {
            const data = await getCalls({ limit: 9999, filters });

            return data.data;
          }}
        >
          <ExportModalProvider
            onExport={generateExport}
            rowCount={itemCount ?? 0}
            filterExpression={filters ?? []}
          >
            <Box mt={9} mb={10} px={5}>
              <CallListHeading
                {...headingProps}
                withExport
                refetch={refetch}
                permissions={permissions}
                newRows={newRowIds.length}
                menuProps={{
                  ...headingProps.menuProps,
                  refreshMethod: {
                    value: refreshMethod,
                    onChange: setRefreshMethod,
                  },
                }}
              />
            </Box>
            {status !== 'loading' && !rows.length ? (
              <NoDataMessage>{t('global:no_results')}</NoDataMessage>
            ) : (
              <Table<CallListCall>
                rowKey="ID"
                columns={columnsToShow}
                rows={rows}
                isLoading={status === 'loading'}
                placeholderRows={limit}
                footer={footer}
                size={density}
                rowProps={trProps}
              />
            )}
          </ExportModalProvider>
        </TableSelectionProvider>
      </CallListContactModalProvider>
    </PageWithPlayer>
  );
};

const useRefreshMethod = (defaultMethod: RefreshMethod = 'auto') => {
  return useUserSetting(
    'Telephony.CallList.table.refreshMethod',
    defaultMethod,
    false
  );
};
