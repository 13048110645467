import { ReactElement } from 'react';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'BootQuery/Assets/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';

interface Props {
  companyID: number | string;
  name: string;
  edit?: boolean;
}

export const CompanyBreadcrumbs = ({
  companyID,
  name,
  edit = false,
}: Props): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <Breadcrumbs>
      <Breadcrumb to="/phonebook/people">{t('Phonebook:phonebook_title')}</Breadcrumb>
      <Breadcrumb to="/phonebook/companies">{t('Phonebook:companies')}</Breadcrumb>
      <Breadcrumb to={`/phonebook/companies/${companyID}`}>{name}</Breadcrumb>
      {edit && (
        <Breadcrumb to={`/phonebook/companies/${companyID}/edit`}>
          {t('global:edit')}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  );
};
