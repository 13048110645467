import { useCallback, useEffect, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { Modifications, WithID } from '@bq/components/SettingsCRUD';
import { ModificationsWithDefaults } from '@bq/components/SettingsCRUD/types';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';
import { useValueHasChanged } from 'BootQuery/Assets/js/use-value-has-changed';

interface UseSyncCrudFieldProps<T extends WithID> {
  saveID?: string;
  field: {
    value: ModificationsWithDefaults<T>;
    onChange: (modifications: ModificationsWithDefaults<T>) => void;
  };
  clearModifications: () => void;
  modifications: Modifications<T>;
}

/**
 * Sync useCrud modifications to a controlled react-hook-form field.
 * Also handles clearing changes on form save.
 */
export function useSyncCrudField<T extends WithID>({
  saveID,
  field,
  modifications,
  clearModifications,
}: UseSyncCrudFieldProps<T>) {
  // Ignore changes once while clearing modifications
  const clearingModifications = useRef(false);

  const onAfterSave = useCallback(() => {
    clearModifications();
    clearingModifications.current = true;
  }, [clearModifications]);

  useChangeEffect(saveID, onAfterSave, [clearModifications]);

  const modificationsChanged = useValueHasChanged(modifications, {
    valuesAreEqual: isEqual,
  });

  // Put this in a ref so we can reliably get it inside useEffect
  // seems to somehow return old value otherwise
  const modificationsChangedRef = useRef(modificationsChanged);
  if (modificationsChanged) {
    modificationsChangedRef.current = modificationsChanged;
  }

  // useEffect without dependencies runs every time and we manually check if
  // modifications changed. Can't use modificationsChanged as dependency because
  // boolean useEffect dependencies behave weirdly
  useEffect(() => {
    if (modificationsChangedRef.current) {
      modificationsChangedRef.current = false;

      // After clearingModifications is set, we wait for the first change
      // and unset it after that, ignoring the change in this cycle
      if (clearingModifications.current) {
        clearingModifications.current = false;
      } else {
        field.onChange({ ...modifications, defaults: field.value.defaults });
      }
    }
  });
}
