import { memo, ReactElement, ReactNode } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export interface SideBarProps {
  children?: ReactNode;
}

const Sidebar = memo(({ children }: SideBarProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box
      background="#424343F2"
      color="#F5F5F5"
      shadow="xl"
      py="3"
      position="sticky"
      h={['fit-content', 'fit-content', 'fullNoNavbar']}
      maxH={['fit-content', 'fit-content', 'fullNoNavbar']}
      overflow={['none', 'auto']}
    >
      <Flex
        direction={['row', 'row', 'column', 'column']}
        justifyContent="space-between"
      >
        <Text
          fontWeight="bold"
          fontStyle="normal"
          fontSize="sm"
          textTransform="uppercase"
          ml={8}
          mt={2}
          mb={2}
        >
          {t('global:overviews.overviews')}
        </Text>
        {children}
      </Flex>
    </Box>
  );
});

Sidebar.displayName = 'Sidebar';
export { Sidebar };
