import { useContext } from 'react';
import { ExportModalContext } from './ExportModalContext';
import { IExportModalContext } from './types';

export function useExportModalContext(): IExportModalContext {
  const ctx = useContext(ExportModalContext);
  if (!ctx) {
    throw new Error('Missing ExportModalContext');
  }

  return ctx;
}
