import { ReactElement, useMemo } from 'react';
import {
  Box,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { FaCheckCircle, FaUpload } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';

import { ImportStepId } from './types';

interface Props {
  step: ImportStepId;
}

export const ImportStepper = ({ step }: Props): ReactElement => {
  const steps = useImportSteps();
  const activeIdx = steps.findIndex((s) => s.id === step);

  return (
    <Stepper size="lg" index={activeIdx}>
      {steps.map((step) => (
        <ImportStep key={step.id} {...step} />
      ))}
    </Stepper>
  );
};

type ImportStepProps = IStep;

const ImportStep = ({
  title,
  description,
  icon,
}: ImportStepProps): ReactElement => {
  const Icon = icon ?? StepNumber;

  return (
    <Step>
      <StepIndicator>
        <StepStatus
          complete={<Icon />}
          incomplete={<Icon />}
          active={<Icon />}
        />
      </StepIndicator>

      <Box flexShrink="0">
        <StepTitle>{title}</StepTitle>
        {description && <StepDescription>{description}</StepDescription>}
      </Box>

      <StepSeparator />
    </Step>
  );
};

interface IStep {
  id: ImportStepId;
  title: string;
  description?: string;
  icon?: IconType;
}

function useImportSteps(): IStep[] {
  const { t } = useTranslation('Datasets');

  return useMemo(
    () => [
      {
        id: 'upload',
        title: t('Datasets:steps.upload'),
        description: '',
        icon: FaUpload,
      },
      {
        id: 'setup',
        title: t('Datasets:steps.setup'),
        description: '',
        icon: FaGear,
      },
      {
        id: 'import',
        title: t('Datasets:steps.import'),
        description: '',
        icon: FaCheckCircle,
      },
    ],
    [t]
  );
}
