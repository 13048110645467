import { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge/ChangeBadge';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { setInputValue } from 'app/Modules/Settings/Assets/components/util';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { useTicketingSettingsContext } from '../TicketSettingsContext';
import { PrioritiesModalForm } from './PrioritiesModalForm';
import { TicketPriorityForm } from './types';
import { modificationsForBackend, priorityToForm } from './util';

export const TicketPriorities = () => {
  const { priorities } = useTicketingSettingsContext();
  const { t } = useTranslation('Ticketing');
  const [selected, setSelected] =
    useState<WithChange<TicketPriorityForm> | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [modifiedPriorities, modify, modifications] =
    useCrud<TicketPriorityForm>({
      defaultValue: { add: [], upd: {}, del: [] },
      items: priorities.map(priorityToForm),
    });

  // Sync to input manually so we can convert the interval format to seconds
  useChangeEffect(modifications, () => {
    setInputValue(
      '#settings-form',
      'ticketing[priorities]',
      modificationsForBackend(modifications)
    );
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedPriorities.map((priority) => (
          <HStack key={priority.ID} w="full">
            <HStack w="full">
              <Box
                fontWeight="bold"
                mr={2}
                textDecor={priority.change === 'del' ? 'line-through' : 'none'}
              >
                {priority.name}
              </Box>
              <ChangeBadge type={priority.change} />
            </HStack>
            <HStack>
              {priority.change && (
                <IconButton
                  aria-label={t('global:undo_changes')}
                  icon={<FaUndo />}
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    modify.undo(priority.ID);
                  }}
                />
              )}
              <IconButton
                aria-label={t('global:edit')}
                icon={<FaEdit />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  setSelected(priority);
                  onOpen();
                }}
              />
              <IconButton
                aria-label={t('global:delete')}
                isDisabled={priority.change === 'del'}
                size="sm"
                icon={<FaTrash />}
                variant="ghost"
                colorScheme="red"
                onClick={() => {
                  modify.del(priority.ID);
                }}
              />
            </HStack>
          </HStack>
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <IconButton
          aria-label={t('global:add')}
          mr={2}
          icon={<FaPlus />}
          colorScheme="green"
          size="sm"
          onClick={() => {
            setSelected(null);
            onOpen();
          }}
        />
      </Flex>
      {isOpen && (
        <PrioritiesModalForm
          onChange={(modifications) => {
            if (modifications.change === 'add') {
              modify.add(modifications);
            }
            if (modifications.change === 'upd') {
              modify.upd(modifications);
            }
          }}
          isOpen={isOpen}
          selected={selected}
          onClose={onClose}
        />
      )}
    </>
  );
};
