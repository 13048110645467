import { useMemo } from 'react';
import { Editor } from '@tiptap/react';

import { wrapAction } from '../../utils';

export const toggleSubscript = (editor: Editor) => {
  editor.commands.toggleSubscript();
};

export const toggleSuperscript = (editor: Editor) => {
  editor.commands.toggleSuperscript();
};

export const useNotationToggles = (editor: Editor | null) => useMemo(
  () => ({
    toggleSubscript: wrapAction(toggleSubscript, editor),
    toggleSuperscript: wrapAction(toggleSuperscript, editor),
  }),
  [editor]
);
