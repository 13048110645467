import { createContext, RefObject, useContext, useRef } from 'react';

export interface IPortalContext {
  containerRef: RefObject<HTMLElement | null> | null | undefined;
}

export const PortalContext = createContext<IPortalContext>({
  containerRef: undefined,
});

export const usePortalContainer = (): RefObject<HTMLElement> => {
  const ctx = useContext(PortalContext);
  const documentBodyRef = useRef(document.body);

  if (ctx && ctx.containerRef?.current) {
    return ctx.containerRef as RefObject<HTMLElement>;
  }

  return documentBodyRef;
};
