import { useQuery } from '@tanstack/react-query';

import { LoadingModal } from '@bq/components/UseModal/LoadingModal';

import { getProduct } from '../../API/Product/getProduct';
import { PartialItem } from '../../types';
import { FormModalWrapper } from '../FormFields/FormModalWrapper';

export const EditProductModal = ({ ID }: { ID: number }) => {
  const { data } = useQuery({
    queryKey: ['EditProductModal', ID],
    queryFn: () => {
      return getProduct<PartialItem>({ productID: ID });
    },
    refetchOnMount: true,
  });
  if (!data) {
    return <LoadingModal />;
  }

  return <FormModalWrapper mode="edit" data={data} />;
};
