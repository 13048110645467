import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { FieldValue } from '@bq/components/FormEditor';
import { IOverviewEditorItem, loadOverviews } from '@bq/components/Overviews';
import { Jsonish } from '@bq/components/type-util';
import { Api } from 'BootQuery/Assets/js/api';

import { getFilterTypes } from '../CallList/filter-types';

export async function getOverviews(
  customFields?: FieldValue[]
): Promise<IOverviewEditorItem[]> {
  const { data } = await Api.get<Jsonish>('/api/telephony/callListOverviews');

  return loadOverviews(data, getFilterTypes(customFields));
}

type UseOveriewsQueryOptions = UseQueryOptions<
  IOverviewEditorItem[],
  unknown,
  IOverviewEditorItem[],
  unknown[]
>;
interface UseOverviewsOptions
  extends Omit<UseOveriewsQueryOptions, 'queryFn' | 'queryKey'> {
  customFields?: FieldValue[] | null;
}

export function useOverviews({
  customFields,
  ...queryOptions
}: UseOverviewsOptions = {}): UseQueryResult<IOverviewEditorItem[]> {
  return useQuery({
    queryKey: ['callListOverviews', customFields] as unknown[],
    queryFn: () => getOverviews(customFields ?? undefined),
    ...queryOptions,
  });
}

export async function setOverviews(
  overviews: IOverviewEditorItem[]
): Promise<void> {
  await Api.post('/api/telephony/callListOverviews', overviews);
}
