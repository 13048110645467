import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { FilterExpression } from '../FilterBar/types';
import { ExportModal } from './ExportModal';
import { ExportModalContext } from './ExportModalContext';
import { ExportFn } from './types';

interface Props {
  rowCount: number;
  filterExpression: FilterExpression[];
  onExport: ExportFn;
  defaultLimit?: number;
}

export const ExportModalProvider = ({
  rowCount,
  filterExpression,
  defaultLimit = 10000,
  onExport,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  const [isOpen, setIsOpen] = useState(false);
  const value = useMemo(
    () => ({
      rowCount,
      isOpen,
      setIsOpen,
      filterExpression,
      defaultLimit,
      onExport,
    }),
    [rowCount, isOpen, setIsOpen, filterExpression, defaultLimit, onExport]
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <ExportModalContext.Provider value={value}>
      {children}
      <ExportModal isOpen={isOpen} onClose={closeModal} />
    </ExportModalContext.Provider>
  );
};
