import { ReactElement, useContext } from 'react';
import { get } from 'lodash-es';
import { FieldValue } from '../../types';
import { StaticFormContext } from '../../StaticFormContext';
import { usePrefixedSelects } from './util';
import { SelectFieldSettings } from './types';
import { GenericStaticFormField } from '../GenericStaticFormField';

type Props = FieldValue<SelectFieldSettings>;

export const StaticSelectField = ({ id, settings }: Props): ReactElement => {
  const prefixedSelects = usePrefixedSelects(id, settings.selects);

  return (
    <>
      {prefixedSelects.map((select) => (
        <StaticSelectInput key={select.id} id={select.id} name={select.name} />
      ))}
    </>
  );
};

interface InputProps {
  name: string;
  id: string;
}

type ValType = { name: string } | null;

const StaticSelectInput = ({ id, name }: InputProps): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id) as ValType;

  return <GenericStaticFormField value={value?.name ?? '-'} title={name} />;
};
