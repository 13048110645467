import { ReactElement, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { CallFormSettingsForm } from './types';

export const CompulsoryFormModeField = (): ReactElement => {
  const { control } = useFormContext<CallFormSettingsForm>();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'compulsoryCallForm' });
  const options = useOptions();

  const valueOption: SelectOption = useMemo(() => {
    const selected = options.find((option) => option.value === value);

    return selected ?? options[0];
  }, [value, options]);

  const handleChange = useCallback(
    (option: SelectOption | null) => {
      if (!option) {
        console.warn('Somehow selected null option');

        return;
      }
      onChange(option.value);
    },
    [onChange]
  );

  return (
    <Select
      isClearable={false}
      value={valueOption}
      onChange={handleChange}
      options={options}
    />
  );
};

function useOptions(): SelectOption[] {
  const { t } = useTranslation('Telephony');

  return useMemo(
    () => [
      {
        label: t('Telephony:permissions.compulsory_call_form_unset'),
        value: 'null',
      },
      {
        label: t('Telephony:permissions.compulsory_call_form_yes'),
        value: 'yes',
      },
      {
        label: t('Telephony:permissions.compulsory_call_form_no'),
        value: 'no',
      },
    ],
    [t]
  );
}
