module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"section-head\" style=\"margin-bottom: 5px;\">\n"
    + ((stack1 = lookupProperty(helpers,"block").call(depth0 != null ? depth0 : (container.nullContext || {}),"rows",{"name":"block","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div class=\"row\">\n		<div class=\"col-12 col-md\">\n			<form action=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,(depth0 != null ? lookupProperty(depth0,"filterlessParams") : depth0),{"name":"encode_query_string","hash":{},"data":data,"loc":{"start":{"line":6,"column":77},"end":{"line":6,"column":117}}}))
    + "\" method=\"get\" class=\"form form-horizontal filter-form\" id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-filter-form\" data-table=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "\">\n				<div class=\"row\">\n					<div id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-filter-row\" class=\"col-12 col-md pb-3 pb-lg-0\" style=\"align-content: center;\">\n						"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"filters"),depth0,{"name":"filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n					<div class=\"col-12 col-md-auto pb-3 pb-lg-0\">\n						"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"limitSelector"),depth0,{"name":"limitSelector","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "					</div>\n				</div>\n			</form>\n		</div>\n		<div class=\"col-12 col-md-auto\" id=\"header-section-right\">\n			<div class=\"row\">\n				<div class=\"col-12 col-md-auto\">\n"
    + ((stack1 = lookupProperty(helpers,"block").call(alias3,"headerButtons",{"name":"block","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":14}}})) != null ? stack1 : "")
    + "				</div>\n			</div>\n		</div>\n	</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"headerButtons"),depth0,{"name":"headerButtons","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<div style=\"position: relative; float: left; width: 100%; margin-bottom: 1em;\">\n		<div style=\"position: relative; float: left; width: 50%;\">\n			<h1>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + "</h1>\n			&nbsp;\n		</div>\n\n		<div style=\"position: relative; float: left; width: 50%; text-align: right;\">\n			"
    + alias2(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reporting:label.generated_at",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":37,"column":3},"end":{"line":37,"column":40}}}))
    + " "
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"datetime")), depth0))
    + "\n		</div>\n	</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"report")),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":40,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})