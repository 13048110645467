import { createContext, Dispatch, SetStateAction } from 'react';

import { MailEditor, MailEditorMap } from './types';

export interface IMailEditorsContext {
  mailEditors: MailEditorMap;
  setMailEditors: Dispatch<SetStateAction<MailEditorMap>>;
  addEditor: (editor: MailEditor) => void;
  removeEditor: (editorID: string) => void;
  onSent?: (sentID: number) => void;
}

export const MailEditorsContext = createContext<IMailEditorsContext | null>(
  null
);
