import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { SalesOverviews } from './types';

export function useOverviews(): UseQueryResult<SalesOverviews> {
  return useQuery({
    queryKey: ['Sales.settings.overviews'],
    queryFn: getOverviews,
  });
}

export async function getOverviews(): Promise<SalesOverviews> {
  const { data } = await Api.get<SalesOverviews>('/api/sales/overviews');

  return data;
}
