import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { ModalContent } from '@chakra-ui/react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useUniqueID } from '@bq/components/use-unique-id';
import { useModalInstance } from '@bq/components/UseModal';

import { SimpleFormSalesCase } from '../../schemas';
import {
  CasesBulkFormWrapperProps,
  CaseBulkFormContext as ICaseBulkFormContext,
  UseCaseBulkFormProviderReturn,
} from './types';

const CasesBulkFormContext = createContext<ICaseBulkFormContext | null>(null);
export const CasesBulkFormWrapper = ({
  mode,
  children,
  subjectType,
  data,
}: PropsWithChildren<CasesBulkFormWrapperProps>) => {
  const [saveID, resetSaveID] = useUniqueID();

  const methods = useForm<SimpleFormSalesCase>({ defaultValues: data });
  const [formState, setFormState] = useFormState();
  const { closeWithCallback } = useModalInstance();

  return (
    <CasesBulkFormContext.Provider
      value={{
        formState,
        setFormState,
        mode,
        resetSaveID,
        saveID,
        subjectType,
      }}
    >
      <FormProvider {...methods}>
        <ModalContent
          as="form"
          onSubmit={methods.handleSubmit(
            (data) => {
              closeWithCallback(data);
            },
            (e) => {
              console.log(e);
              setFormState('error');
            }
          )}
        >
          {children}
        </ModalContent>
      </FormProvider>
    </CasesBulkFormContext.Provider>
  );
};

export const useCasesBulkFormProvider = (): UseCaseBulkFormProviderReturn => {
  const methods = useFormContext<SimpleFormSalesCase>();
  const ctx = useContext(CasesBulkFormContext);
  if (!ctx) {
    throw Error('Missing CasesBulkFormContext');
  }

  return useMemo(() => ({ methods, ctx }), [ctx, methods]);
};
