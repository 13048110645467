module.exports = {"name":"Users","menu_entries":{"userlist":"Users","logout":"Logout","user":"User","extensions":"Extensions"},"tab":{"users":"Users","groups":"Groups"},"table_header":{"user":"User","extension":"Extension","groups":"Groups","employee":"Employee","name":"Name","group_name":"Name"},"label":{"settings":"Settings","display":"Display","user":"User","local_extension":"Local extension","login":"Login","user_managed_at":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "This user is managed through <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":49}}}) : helper)))
    + "</strong>. Some settings are not available here.";
},"useData":true})},"button":{"login":"Log in","login_with":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Log in with "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":24}}}) : helper)));
},"useData":true}),"change_password":"Change password"},"form":{"username":"Username","password":"Password","password_confirm":"Confirm password","current_password":"Current password","recovery_email":"Recovery email","stayLoggedIn":"Stay logged in","email":"e-mail","extension":"Extension","employee":"Employee","groups":"Groups","group":"Group","name":"Name","language":"Language","theme":"Theme","compact_tables":"Compact tables","password_recovery":"Password recovery","send_email":"Send e-mail","reset_password":"Reset password","user_unavailable":"Unavailable"},"error":{"invalid_email":"Invalid e-mail","incorrect_username_or_password":"Incorrect username or password"},"filter":{"username":"Username","extension":"Extension","group":"Group"}}