import { ReactElement } from 'react';
import { Box, Progress } from '@chakra-ui/react';

import { FileInfo } from '../FileInput/types';
import { fixedChakraFactory } from '../fixed-chakra-factory';

interface ProgressProps {
  file?: FileInfo | null;
}

export const FileProgress = ({ file }: ProgressProps): ReactElement => {
  if (!file || file.type === 'saved') {
    return <></>;
  }

  return (
    <ProgressContainer>
      <Progress
        value={file.type === 'uploading' ? file.progress : 100}
        colorScheme={file.type === 'uploading' ? 'blue' : 'green'}
        height="2px"
        bg="transparent"
      />
    </ProgressContainer>
  );
};

const ProgressContainer = fixedChakraFactory(Box, {
  baseStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});
