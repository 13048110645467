import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { Box, Input } from '@chakra-ui/react';
import { UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { LoadingPage } from '../LoadingPage';
import { ReportAgentEntity } from './ReportAgentEntity';
import { PermissionMap, ReportEntity } from './types';

interface Props {
  value: PermissionMap;
  onChange: Dispatch<SetStateAction<PermissionMap>>;
  useItems: (search: string) => UseQueryResult<ReportEntity[]>;
  gridAreaPrefix: string;
}

export const ReportAgentsEntities = ({
  value,
  onChange,
  useItems,
  gridAreaPrefix,
}: Props): ReactElement => {
  const { t } = useTranslation('global');

  const [search, setSearch] = useState('');
  const { data: items } = useItems(search);

  const onItemChange = useCallback(
    (itemID: number, value: SetStateAction<boolean | null>) => {
      onChange((prev) => {
        const prevItem = prev[itemID] ?? null;
        const newVal = typeof value === 'function' ? value(prevItem) : value;

        return {
          ...prev,
          [itemID]: newVal,
        };
      });
    },
    [onChange]
  );

  return (
    <>
      <Box gridArea={`${gridAreaPrefix}Search`}>
        <Input
          value={search}
          onChange={(ev) => setSearch(ev.currentTarget.value)}
          mb="3"
          size="sm"
          placeholder={t('global:search')}
        />
      </Box>
      <Box gridArea={`${gridAreaPrefix}Items`} overflowY="auto">
        {items ? (
          <>
            {items.map((item) => (
              <ReportAgentEntity
                key={item.ID}
                ID={item.ID}
                name={item.name}
                value={value[item.ID] ?? null}
                onItemChange={onItemChange}
              />
            ))}
          </>
        ) : (
          <LoadingPage />
        )}
      </Box>
    </>
  );
};
