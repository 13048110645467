import { ReactElement, useCallback } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileExport } from 'react-icons/fa';

import { useExportModalContext } from './use-export-modal-context';

export const ExportMenuItem = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { setIsOpen } = useExportModalContext();

  const openModal = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <MenuItem onClick={openModal}>
        <FaFileExport />
        &nbsp; {t('global:export')}
      </MenuItem>
    </>
  );
};
