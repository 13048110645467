import { ReactElement } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaClock } from 'react-icons/fa';

import { Ticket } from '../types';

type Props = Pick<
  Ticket,
  'resolveDeadlineExceededAt' | 'respondDeadlineExceededAt'
>;

export const DeadlineIndicator = (props: Props): ReactElement => {
  const labelText = useLabelText(props);

  if (!props.resolveDeadlineExceededAt && !props.respondDeadlineExceededAt) {
    return <></>;
  }

  return (
    <Tooltip label={labelText}>
      <Text as="span" color="red.500" zIndex={3} cursor="help">
        <FaClock />
      </Text>
    </Tooltip>
  );
};

function useLabelText({
  resolveDeadlineExceededAt,
  respondDeadlineExceededAt,
}: Props): string {
  const { t } = useTranslation('Ticketing');

  const lines = [];

  if (resolveDeadlineExceededAt) {
    lines.push(t('Ticketing:resolve_deadline_exceeded'));
  }

  if (respondDeadlineExceededAt) {
    lines.push(t('Ticketing:respond_deadline_exceeded'));
  }

  return lines.join('. ');
}
