import i18n from 'BootQuery/Assets/js/i18n';

import { FormattedTextIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { RichtextField } from './RichtextField';
import { SettingsComponent } from './SettingsComponent';
import { StaticFormField } from './StaticFormField';
import { RichtextFieldSettings } from './types';

export const richTextField = (): FieldType<RichtextFieldSettings> => ({
  type: 'richtext',
  name: i18n.t('global:form_editor.field_richtext'),
  icon: FormattedTextIcon,
  components: {
    StaticFormField,
    EditorFieldSettings: SettingsComponent,
    FormField: RichtextField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
    mode: 'advanced',
  }),
  getFilters(_field) {
    return {};
  },
  getColumns(_field) {
    return [];
  },
});
