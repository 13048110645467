import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterResolveUntil = makeDateFilter({
  name: () => i18n.t('Ticketing:form.resolve_until'),
  toFilter: makeDateToFilter('resolveUntil'),
});
