import {
  DeviceState,
  deviceState,
  ExtenStates,
} from 'app/Modules/Telephony/Assets/store/device-state';
import { useMemo, useEffect } from 'react';
import { ApiNumber } from './types';

function findExtens(phoneNumbers: ApiNumber[]): string[] {
  return phoneNumbers
    .map((numObj) => numObj.phoneNumber.phoneNumberE164)
    .filter((num) => num.length <= 6);
}

function stateForLocales(deviceStates: ExtenStates, extens: string[]): DeviceState {
  const ourStates = extens.reduce<DeviceState[]>((states, exten) => {
    const toAdd: DeviceState[] = [];
    Object.entries(deviceStates[exten]?.devices ?? {}).forEach(
      ([_devId, devState]) => {
        toAdd.push(devState.state);
      }
    );

    return [...states, ...toAdd];
  }, []);

  const stateOrder: DeviceState[] = ['busy', 'ringing', 'idle', 'unavailable'];
  // eslint-disable-next-line no-restricted-syntax
  for (const state of stateOrder) {
    if (ourStates.includes(state)) {
      return state;
    }
  }

  return 'unknown';
}

export function useExtenState(phoneNumbers: ApiNumber[]): DeviceState|null {
  const extens = useMemo(() => findExtens(phoneNumbers), [phoneNumbers]);
  const ourState: DeviceState = deviceState(
    ({ deviceStates }) => stateForLocales(deviceStates, extens)
  );

  useEffect(() => {
    if (extens.length > 0) {
      deviceState.getState().subscribe(extens);
    }
  }, [extens]);

  if (extens.length === 0) {
    return null;
  }

  return ourState;
}
