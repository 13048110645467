import { Box, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { IconButton } from '@bq/components/IconButton';
import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';
import { useModal } from '@bq/components/UseModal';

import { CaseState } from '../../schemas';
import { StateModal } from './StateModal';

interface Props {
  state: WithChange<CaseState>;
  modify: SettingsMethods<CaseState>;
}

export const StateItem = ({ state, modify }: Props) => {
  const { t } = useTranslation();
  const { addModal } = useModal();

  return (
    <VStack w="full" pr={0} spacing={0}>
      <HStack key={state.ID} w="full">
        <HStack w="full">
          <Box
            rounded="sm"
            py={0.5}
            px={3}
            color="white"
            bg={state.color}
            fontWeight="bold"
            mr={2}
            textDecor={state.change === 'del' ? 'line-through' : 'none'}
          >
            {state.name}
          </Box>
          <ChangeBadge type={state?.change} />
        </HStack>
        <HStack>
          {state.change && (
            <IconButton
              label={t('global:undo_changes')}
              icon={<FaUndo />}
              variant="ghost"
              size="sm"
              onClick={() => {
                modify.undo(state.ID);
              }}
            />
          )}
          <IconButton
            label={t('global:edit')}
            icon={<FaEdit />}
            variant="ghost"
            size="sm"
            onClick={() => {
              addModal({
                children: <StateModal mode="edit" data={state} />,
                preventClose: {
                  defaultPreventState: false,
                },
                componentProps: {
                  modal: { size: '6xl', scrollBehavior: 'outside' },
                },
                callback: modify.upd,
              });
            }}
          />
          <IconButton
            label={t('global:delete')}
            isDisabled={state.change === 'del'}
            size="sm"
            icon={<FaTrash />}
            variant="ghost"
            colorScheme="red"
            onClick={() => {
              modify.del(state.ID);
            }}
          />
        </HStack>
      </HStack>
    </VStack>
  );
};
