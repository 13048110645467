import { MenuGroup } from '@chakra-ui/react';
import { useCallback, ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonRadio } from '../ButtonRadio';

interface LimitSelectorProps {
  value: number;
  onChange: (value: number) => void;
  limits?: number[];
}

const defaultLimits = [10, 20, 30, 40, 50];
export const LimitSelector = ({
  value,
  onChange,
  limits = defaultLimits,
}: LimitSelectorProps): ReactElement => {
  const { t } = useTranslation();

  const options = useMemo(
    () => limits.map((limit) => limit.toString()),
    [limits]
  );
  const handleChange = useCallback(
    (val: string) => {
      onChange(parseInt(val, 10));
    },
    [onChange]
  );
  const valStr = useMemo(() => value.toString(), [value]);

  return (
    <MenuGroup title={t('global:results_per_page')}>
      <ButtonRadio
        name="limitSelector"
        options={options}
        value={valStr}
        onChange={handleChange}
      />
    </MenuGroup>
  );
};
