import { FilterTypes } from '@bq/components/FilterBar';
import { idFilter } from './id-filter';
import { nameFilter } from './name-filter';
import { createdByUserFilter } from './created-by-user-filter';
import { createdAtFilter } from './created-at-filter';
import { offerDateFilter } from './offer-date-filter';
import { updatedAtFilter } from './updated-at-filter';
import { billingStartDateFilter } from './billing-start-date-filter';
import { buyerFilter } from './buyer-filter';
import { deliveryDateFilter } from './delivery-date-filter';
import { validUntilFilter } from './valid-until-filter';
import { offererFilter } from './offerer-filter';
import { responsibleUserFilter } from './responsible-user';

export const offerFilterTypes: FilterTypes = {
  idFilter,
  nameFilter,
  createdAtFilter,
  updatedAtFilter,
  createdByUserFilter,
  offerDateFilter,
  validUntilFilter,
  deliveryDateFilter,
  billingStartDateFilter,
  buyerFilter,
  offererFilter,
  responsibleUserFilter,
};
