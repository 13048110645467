import { lazyImport } from '@bq/components/lazy-import';

import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const Phonebook = lazyImport(() => import('../pages/Phonebook'), 'Phonebook');

export const ReactRoutes: ReactRoute[] = [
  {
    path: '/phonebook/*',
    component: Phonebook,
  },
];
