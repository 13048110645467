import { createContext, useContext } from 'react';
import { BaseSettings, FieldType, ValueItem } from './types';

export type FieldChangeFn<ST extends BaseSettings = BaseSettings> = (
  prev: ValueItem<ST>
) => ValueItem<ST>;
export type ModifyField<ST extends BaseSettings = BaseSettings> = (
  id: string | number,
  changes: Partial<ValueItem<ST>> | FieldChangeFn<ST>
) => void;

export interface IFormEditorContext {
  fieldTypes: FieldType[];
  modifyField: ModifyField;
  removeField: (id: string | number) => void;
}

export const FormEditorContext = createContext<IFormEditorContext | null>(null);

export function useFormEditorContext(): IFormEditorContext {
  const ctx = useContext(FormEditorContext);
  if (!ctx) {
    throw new Error('Missing context');
  }

  return ctx;
}
