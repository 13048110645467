import { useCallback } from 'react';

import { Modifications, SettingsMethods } from '@bq/components/SettingsCRUD';
import { useCrud } from '@bq/components/SettingsCRUD/use-crud';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import { BlacklistItem } from './types';

export interface UseBlacklistCRUDResult {
  saveChanges: () => void;
  items: BlacklistItem[];
  modify: SettingsMethods<BlacklistItem>;
  modifications: Modifications<BlacklistItem>;
}

export function useBlacklistCRUD(
  initial: BlacklistItem[]
): UseBlacklistCRUDResult {
  const [savedModifications, setSavedModifications] = useDOMInputJSON<
    Modifications<BlacklistItem>
  >('#settings-form', 'telephonyBlacklistModifications', {
    add: [],
    upd: {},
    del: [],
  });
  const [items, modify, modifications] = useCrud({
    defaultValue: savedModifications,
    items: initial,
  });

  const saveChanges = useCallback(() => {
    setSavedModifications(modifications);
  }, [setSavedModifications, modifications]);

  return {
    saveChanges,
    items,
    modify,
    modifications,
  };
}
