import { useEffect } from 'react';
import { Input } from '@chakra-ui/react';

import { CaseSubject } from '../../../schemas';
import { useCaseFormProvider } from '../CaseFormWrapper';

interface Props {
  subjectValue: CaseSubject | null | undefined;
}

export const NameField = ({ subjectValue }: Props) => {
  const {
    methods: { register, setValue, getValues, formState },
  } = useCaseFormProvider();

  useEffect(() => {
    if (formState.isDirty) {
      if (!getValues('name') && subjectValue) {
        setValue('name', getAssignedUserType(subjectValue));
      }
    }
  }, [subjectValue, getValues, setValue, formState]);

  return (
    <Input
      {...register('name', { required: 'required' })}
      size={['lg', 'lg', 'sm']}
    />
  );
};

const getAssignedUserType = (value: CaseSubject | null | undefined) => {
  switch (value?.type) {
    case 'person':
      return value.name;
    case 'company':
      return value.name;
    case 'dataset':
      return value.name;
    default:
      return '';
  }
};
