import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { appointmentSchema, IAppointment } from '../schema';

export async function getAppointment<T = IAppointment>(
  appointmentID: number,
  params: GetOneParams = {}
): Promise<T> {
  const { data } = await Api.get<T>(`/api/appointments/${appointmentID}`, {
    params,
  });

  return data;
}

export function useAppointment(
  appointmentID: number
): UseQueryResult<IAppointment> {
  return useQuery({
    queryKey: ['Appointment', appointmentID],
    queryFn: async () => {
      const appointment = await getAppointment(appointmentID);

      return appointmentSchema.parse(appointment);
    },
  });
}
