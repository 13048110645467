import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiTableLine } from 'react-icons/ri';

import { useRichText } from '../../RichtextContex';
import RichTextButton from '../RichTextButton';
import { useTableToggles } from './ToggleHandlers';

const AddTableButton = () => {
  const { editor } = useRichText();
  const { t } = useTranslation();
  const { insertTable } = useTableToggles(editor);

  return (
    <RichTextButton
      key="insertTable"
      label={t('global:richtext.table.insert_table')}
      icon={<RiTableLine size="1.25em" />}
      action={insertTable}
    />
  );
};

export const AddTable = memo(AddTableButton);
