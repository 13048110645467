import { Column } from '@bq/components/Table';
import { formatDuration } from 'app/assets/js/tsutil';
import i18n from 'BootQuery/Assets/js/i18n';

import { AgentReportRow } from './types';
import { userDisplayName } from './utils';

export function getColumns(): Column<AgentReportRow>[] {
  return [
    {
      key: 'user',
      title: i18n.t('Ticketing:form.agent'),
      getValue: ({ row }) => userDisplayName(row),
      exportFormat: 'agentName',
      exportValue: 'user',
    },
    {
      key: 'received',
      title: i18n.t('Ticketing:reports.received'),
    },
    {
      key: 'closed',
      title: i18n.t('Ticketing:reports.closed'),
    },
    {
      key: 'reopened',
      title: i18n.t('Ticketing:reports.reopened'),
    },
    {
      key: 'firstResponse.count',
      title: i18n.t('Ticketing:reports.first_response_count'),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.avg',
      title: i18n.t('Ticketing:reports.first_response_avg'),
      getValue: ({ row }) => formatDuration(row.firstResponse.avg),
    },
    {
      key: 'firstResponse.min',
      title: i18n.t('Ticketing:reports.first_response_min'),
      getValue: ({ row }) => formatDuration(row.firstResponse.min),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.max',
      title: i18n.t('Ticketing:reports.first_response_max'),
      getValue: ({ row }) => formatDuration(row.firstResponse.max),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.median',
      title: i18n.t('Ticketing:reports.first_response_median'),
      getValue: ({ row }) => formatDuration(row.firstResponse.median),
      defaultVisible: true,
    },
    {
      key: 'responses.count',
      title: i18n.t('Ticketing:reports.responses_count'),
      defaultVisible: true,
    },
    {
      key: 'responses.avg',
      title: i18n.t('Ticketing:reports.responses_avg'),
      getValue: ({ row }) => formatDuration(row.responses.avg),
      defaultVisible: true,
    },
    {
      key: 'responses.min',
      title: i18n.t('Ticketing:reports.responses_min'),
      getValue: ({ row }) => formatDuration(row.responses.min),
      defaultVisible: false,
    },
    {
      key: 'responses.max',
      title: i18n.t('Ticketing:reports.responses_max'),
      getValue: ({ row }) => formatDuration(row.responses.max),
      defaultVisible: false,
    },
    {
      key: 'responses.median',
      title: i18n.t('Ticketing:reports.responses_median'),
      getValue: ({ row }) => formatDuration(row.responses.median),
      defaultVisible: true,
    },
  ];
}
