import { Link } from '@chakra-ui/react';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CustomEvent } from './types';

type Props = Pick<CustomEvent, 'contacts'>;

export const CustomEventCardContacts = ({ contacts }: Props): ReactElement => {
  if (!contacts.length) {
    return <>-</>;
  }

  return (
    <WithSeparator separator=",&nbsp;">
      {contacts.map((contact) => {
        const link =
          contact.type === 'person'
            ? `/phonebook/people/${contact.ID}`
            : `/phonebook/companies/${contact.ID}`;

        return (
          <Link key={`${contact.type}-${contact.ID}`} as={RouterLink} to={link}>
            {contact.name}
          </Link>
        );
      })}
    </WithSeparator>
  );
};
