import { HStack, Text } from '@chakra-ui/react';
import { FaPhone, FaUsers } from 'react-icons/fa';
import { SendingAccVal } from './filters';

const iconForContactType = (type: SendingAccVal['type']) => {
  if (type === 'account') { return <FaPhone style={{ display: 'inline' }} />; }

  return <FaUsers style={{ display: 'inline' }} />;
};
export const SendingAccountItem = (item: SendingAccVal) => (
  <HStack alignItems="center">
    {iconForContactType(item.type)}
    <Text>{item.type === 'account' ? item.phoneNumber : item.name}</Text>
  </HStack>
);
