import { Api } from 'BootQuery/Assets/js/api';
import { useQuery } from '@tanstack/react-query';
import { LocalCompany } from './types';

export const getLocalCompany = async () => {
  const data = await Api.get<LocalCompany>('/api/phonebook/localCompany');

  return data;
};

export const useLocalCompany = () => {
  const useQueryResult = useQuery<LocalCompany>(['localCompany'], async () => {
    const data = await getLocalCompany();

    return data.data;
  });

  return useQueryResult;
};
