import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from '@bq/components/FilterBar/filters/ComboboxFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { getPriority, getTicketPriorities } from '../Api';
import { TicketPriority } from '../types';

export const ticketFilterPriority = makeComboboxFilter({
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return { [`priority.name:${containsOp}:ci`]: value };
    }

    return {
      [`priorityID:${operator ?? 'eq'}`]: value.ID,
    };
  },
  extraProps: {
    cacheKey: 'ticketFilterPriority',
    search: async (search: string) => {
      return getTicketPriorities({ filters: { $search: search } });
    },
    itemToString: (item: TicketPriority) => item.name,
    enableTextSearch: true,
    itemToValue: (item: TicketPriority) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await getPriority(value.id);

      return data;
    },
  },

  name: () => i18n.t('Ticketing:form.priority'),
  operators: getDefaultComboboxOperators(false),
});
