import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { get } from 'lodash-es';
import { ReactElement, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation, TFunction } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';
import { translateAddressType } from './address-types';
import { AddressTypeInput } from './AddressTypeInput';
import { AddressFormData } from './types';

interface Props {
  name: string;
  onDelete?: () => void;
  hasType?: boolean;
}

export const FormAddress = ({
  name,
  onDelete,
  hasType,
}: Props): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control, register, formState } = useFormContext();
  const address = useWatch({ control, name }) ?? {};
  const [editable, setEditable] = useState<boolean>(address.$isNew);

  // This should be nicer in react-hook-form v8
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errors = get(formState.errors, name) ?? ({} as any);
  const toggleEditable = () => setEditable(!editable);

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{t('Phonebook:address')}:</FormLabel>
      <HStack spacing="3">
        <Input
          isDisabled
          isReadOnly
          value={formatAddress(t, address, hasType)}
        />
        {onDelete && <DeleteButton onClick={onDelete} />}
        <Button
          onClick={toggleEditable}
          verticalAlign="middle"
          colorScheme="blackAlpha"
          bg="brand.darkGray"
          color="brand.lightGray"
          rightIcon={<FaEdit />}
        >
          {t('global:edit')}
        </Button>
      </HStack>
      <Collapse animateOpacity in={editable}>
        <VStack px={4} py={2}>
          {hasType && (
            <FormControl mb="3">
              <FormLabel>{t('Phonebook:address_type')}</FormLabel>
              <AddressTypeInput name={`${name}.addressType`} />
            </FormControl>
          )}
          <FormControl mb="3">
            <FormLabel>{t('Phonebook:country')}</FormLabel>
            <Input
              {...register(`${name}.country`)}
              placeholder={t('Phonebook:country')}
              isInvalid={!!errors?.country}
            />
          </FormControl>
          <FormControl mb="3">
            <FormLabel>{t('Phonebook:city')}</FormLabel>
            <Input
              {...register(`${name}.city`)}
              placeholder={t('Phonebook:city')}
              isInvalid={!!errors?.city}
              disabled={!address.country}
            />
          </FormControl>
          <FormControl mb="3">
            <FormLabel>{t('Phonebook:postal_code')}</FormLabel>
            <Input
              {...register(`${name}.postalCode`, { pattern: /^[0-9 ]+$/ })}
              isInvalid={!!errors?.postalCode}
              placeholder={t('Phonebook:postal_code')}
              disabled={!address.city}
            />
          </FormControl>
          <FormControl mb="3">
            <FormLabel>{t('Phonebook:street')}</FormLabel>
            <Input
              {...register(`${name}.street`)}
              placeholder={t('Phonebook:street')}
              isInvalid={!!errors?.street}
              disabled={!address.city}
            />
          </FormControl>
          <FormControl mb="3">
            <FormLabel>{t('Phonebook:street_number')}</FormLabel>
            <Input
              {...register(`${name}.streetNumber`)}
              placeholder={t('Phonebook:street_number')}
              isInvalid={!!errors?.streetNumber}
              disabled={!address.street}
            />
          </FormControl>
        </VStack>
      </Collapse>
    </FormControl>
  );
};

type Translate = TFunction<'Phonebook', undefined>;
function formatAddress(
  t: Translate,
  address: Partial<AddressFormData>,
  withType = false
): string {
  let typeStr = '';
  if (withType && address.addressType) {
    typeStr =
      '$new' in address.addressType
        ? address.addressType.$new
        : translateAddressType(t, address.addressType.name);
  }

  if (!address.country) {
    return typeStr ? `${typeStr}: -` : '';
  }

  let cityPart = '';
  if (address.city) {
    cityPart = address.postalCode
      ? `${address.postalCode} ${address.city}`
      : address.city;
  }
  let addressPart = '';
  if (address.street) {
    addressPart = address.streetNumber
      ? `${address.street} ${address.streetNumber}`
      : address.street;
  }

  const addrParts = [addressPart, cityPart, address.country];

  const addrStr = addrParts.filter((part) => !!part).join(', ');

  return typeStr ? `${typeStr}: ${addrStr}` : addrStr;
}
