import { ReactElement } from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

interface SwitchInputProps {
    label: string;
    id?: string;
    value: boolean;
    onChange: (value: boolean) => void;
}

export const SwitchInput = ({
  label, id, value, onChange,
}: SwitchInputProps): ReactElement => (
  <FormControl display="flex" alignItems="center" mb="3">
    <Switch
      id={id}
      isChecked={value}
      onChange={(ev) => onChange(ev.target.checked)}
      mb="0"
    />
    <FormLabel htmlFor={id} ml="2" mb="0">
      {label}
    </FormLabel>
  </FormControl>
);
