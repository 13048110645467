import { stringify } from 'qs';
import { Column } from 'BootQuery/Assets/components/Table';
import { format } from 'date-fns';
import { ReportSettings } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyColumn = Column<any>;

export function genExportLink(
  base: string,
  settings: ReportSettings,
  columns: AnyColumn[],
  groupBy: string[] = []
): string | null {
  if (!settings.datePeriod) {
    return null;
  }

  const query = stringify(
    {
      from: format(settings.datePeriod.from, 'Y-MM-dd'),
      to: format(settings.datePeriod.to, 'Y-MM-dd'),
      step: settings.step,
      columns: columns.map((col) => ({
        valuePath: col.exportValue ?? col.key,
        title: col.title,
        format: col.exportFormat,
      })),
      groupBy,
    },
    { addQueryPrefix: false }
  );

  return `${base}?${query}`;
}
