import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { CaseStateFormFields } from './CaseStateFormFields';
import { FooterButtons } from './FooterButtons';
import { StateFormWrapper } from './StateFormWrapper';
import { StateFormData } from './types';

export const StateModal = (props: StateFormData) => {
  const { t } = useTranslation('Sales');

  return (
    <StateFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Sales:create_state')
          : t('Sales:edit_state')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <CaseStateFormFields />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </StateFormWrapper>
  );
};
