import i18n from 'BootQuery/Assets/js/i18n';
import { NumberIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { NumberFieldSettings, NumberFieldSettingsComponent } from './NumberFieldSettings';

export const numberField = (): FieldType<NumberFieldSettings> => ({
  type: 'number',
  name: i18n.t('global:form_editor.field_number'),
  icon: NumberIcon,
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
    min: 0,
    max: null,
    decimals: 0,
  }),
  components: {
    EditorFieldSettings: NumberFieldSettingsComponent,
  },
});
