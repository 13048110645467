import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import { SoftphoneError, SoftphoneState } from '../types';

export type SoftphoneEvents = {
  dial: string;
  error: SoftphoneError;
  stateChange: SoftphoneState;
};

export const softphoneEvents = mitt<SoftphoneEvents>();

export const useSoftphoneEvent = createUseMitt(softphoneEvents);
