import { ReactElement, useMemo } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { ExportModalProvider } from '@bq/components/Export';
import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { setColValues } from 'app/Modules/Ticketing/Util/GetColumns';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { TableSelectionProvider } from 'BootQuery/Assets/components/Table';
import { CardBulkActionMenu } from 'BootQuery/Assets/components/Table/CardBulkActionMenu';

import { columns, personFilterTypes } from '../PersonList';
import { generateExport } from '../PersonList/generate-export';
import { getCardPeople } from '../PersonList/get-people';
import { PersonCard } from '../PersonList/PersonCard';
import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { useSplitColumnsFeatures } from './columns';
import { sendSms } from './company-employee-list-actions';
import { getFiltersDeparatment } from './CompanyEmployeeTableList';
import { phonebookDisplayModeOptions } from './display-settings';
import { ShowSubdepartmentEmployees } from './ShowPeopleFromSubDepartmentsToggle';

interface Props {
  company: { ID: number; name: string };
  department?: { ID: number; name: string };
  customFields?: FieldValue[];
  listingName?: string;
  companyLocation?: { ID: number; name: string };
  permissions: Permissions;
}

export const CompanyEmployeeCardList = ({
  customFields,
  company,
  department,
  listingName = 'Phonebook.CompanyEmployeeList',
  companyLocation,
  permissions,
}: Props): ReactElement => {
  const overview = useSelectedOverview('employees');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName,
    viewName: 'card',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { filters, page, limit, density, columnsToShow } = listingSettings;

  const { columns: customColumns, features } = useSplitColumnsFeatures(
    permissions,
    columnsToShow
  );

  const { data, refetch } = useQuery({
    queryKey: [
      `${listingName}.card`,
      page,
      filters,
      limit,
      company.ID,
      department?.ID,
    ],
    queryFn: () => {
      return getCardPeople({
        page,
        filters: getFiltersDeparatment({
          companyID: company.ID,
          departmentID: department?.ID,
          filters,
          companyLocationID: companyLocation?.ID,
        }),
        limit,
      });
    },
    enabled: company !== undefined && filters !== undefined,
    keepPreviousData: true,
  });

  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      { actions: [sendSms(navigate)], styleProps: { colorScheme: 'brand' } },
    ],
    [navigate]
  );
  const itemCount = data?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    displayModeOptions: phonebookDisplayModeOptions,
    listingProps: listingSettings,
    count: itemCount,
    menuChildren: <ShowSubdepartmentEmployees />,
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Box
        mt={company.ID ? 0 : 9}
        mb={company.ID ? 0 : 4}
        px={company.ID ? 0 : 4}
      >
        <ExportModalProvider
          onExport={generateExport}
          rowCount={itemCount}
          filterExpression={filters ?? []}
        >
          <ListHeading
            {...headingProps}
            Actions={<Actions company={company} />}
          />
        </ExportModalProvider>
      </Box>

      <PhonebookListContext.Provider value={{ refetch }}>
        <TableSelectionProvider<PersonApiData>
          page={page}
          idColumn="ID"
          selectAll={async () => {
            const { data } = await getCardPeople({
              limit: 'null',
              filters: [...(filters ?? []), { companyID: company.ID }],
            });

            return data;
          }}
          selectPage={() => data.data ?? []}
        >
          <SimpleGrid m="10px" gridGap={4} columns={setColValues(density)}>
            {data.data.map((person) => (
              <PersonCard
                key={person.ID}
                person={person}
                customColumns={customColumns}
                features={features}
              />
            ))}
          </SimpleGrid>

          <CardBulkActionMenu<PersonApiData> actionGroups={bulkActions} />
        </TableSelectionProvider>
      </PhonebookListContext.Provider>
    </>
  );
};
