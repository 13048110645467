import { useMemo } from 'react';
import { MenuItem, Tooltip } from '@chakra-ui/react';

import { BulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  action: BulkAction<T>;
}
export const MobileBulkActionStandardButton = <T extends OBJ>({
  action,
}: Props<T>) => {
  const { selection, clearSelected } = useTableSelection<T>();
  const parsedSelection = useMemo(() => {
    return action.parseSelection !== undefined
      ? action.parseSelection(selection)
      : selection;
  }, [action, selection]);

  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <MenuItem
        aria-label="action.name"
        onClick={() => {
          if (allowed) {
            action.handler(parsedSelection, selection).then(() => {
              if (action.onActionEnd !== undefined) {
                action.onActionEnd(selection);
                clearSelected();
              }
            });
          }
        }}
        isDisabled={!allowed}
        icon={action.icon}
      >
        {action.name}
      </MenuItem>
    </Tooltip>
  );
};
