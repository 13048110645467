import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaBold,
  FaCode,
  FaItalic,
  FaQuoteLeft,
  FaStrikethrough,
  FaUnderline,
} from 'react-icons/fa';

import { useRichText } from '../../RichtextContex';
import RichTextButton from '../RichTextButton';
import { useFontToggles } from './ToggleHandlers';

export interface IFonts {
  showAdvanced?: boolean;
}
const FontsButtonGroup = ({ showAdvanced = false }: IFonts) => {
  const { t } = useTranslation();
  const { editor } = useRichText();
  const {
    toggleBold,
    toggleItalic,
    toggleUnderline,
    toggleStrike,
    toggleCode,
    toggleBlockquote,
  } = useFontToggles(editor);

  return (
    <>
      <RichTextButton
        key="bold"
        label={t('global:richtext.bold')}
        icon={<FaBold />}
        action={toggleBold}
        property="bold"
      />
      <RichTextButton
        key="italic"
        label={t('global:richtext.italic')}
        icon={<FaItalic />}
        action={toggleItalic}
        property="italic"
      />
      <RichTextButton
        key="underline"
        label={t('global:richtext.underline')}
        icon={<FaUnderline />}
        action={toggleUnderline}
        property="underline"
      />
      {showAdvanced && (
        <>
          <RichTextButton
            key="strike"
            label={t('global:richtext.strikethrough')}
            icon={<FaStrikethrough />}
            action={toggleStrike}
            property="strike"
          />
          <RichTextButton
            key="code"
            label={t('global:richtext.code')}
            icon={<FaCode />}
            action={toggleCode}
            property="code"
          />
          <RichTextButton
            key="blockquote"
            label={t('global:richtext.blockquote')}
            icon={<FaQuoteLeft />}
            action={toggleBlockquote}
            property="blockquote"
          />
        </>
      )}
    </>
  );
};

export const Fonts = memo(FontsButtonGroup);
