module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"container-fluid py-3\" data-settings-form=\"displaySettings\" data-form=\"displaySettings\">\n    <div class=\"row\">\n        <div class=\"col-12\">\n            <h3>\n                <strong>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"username") : depth0), depth0))
    + "</strong>\n            </h3>\n            <hr class=\"my-3\">\n            <div class=\"form-group row\">\n                <label class=\"col-12 col-form-label  col-md-2 control-label text-left text-md-right\" for=\"recoveryEmail\">\n                    <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.recovery_email",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":45},"end":{"line":10,"column":73}}}))
    + "</span>\n                    <span class=\"label-after\">: </span>\n                </label>\n                <div class=\"col-12 col-md-10\">\n                    <input class=\"form-control\" type=\"email\" name=\"userSettings[recoveryEmail]\" id=\"recoveryEmail\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"recoveryEmail") : depth0), depth0))
    + "\">\n                </div>\n            </div>\n\n            <div class=\"form-group row\">\n                <label class=\"col-12 col-form-label  col-md-2 control-label text-left text-md-right\" for=\"recoveryEmail\">\n                    <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":67}}}))
    + "</span>\n                    <span class=\"label-after\">: </span>\n                </label>\n                <div class=\"col-12 col-md-10\">\n                    <button type=\"button\" class=\"btn btn-primary user-change-pass-btn\" data-toggle=\"collapse\" data-target=\"#password-change-collapse\">\n                        <span class=\"fa fa-lock\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"button.change_password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":57},"end":{"line":25,"column":88}}}))
    + "\n                    </button>\n\n                    <div class=\"collapse\" id=\"password-change-collapse\">\n                        <div class=\"card card-body border-primary\">\n                            <input type=\"hidden\" name=\"userSettings[passwordChanged]\" value=\"false\">\n                            <div class=\"form-group\">\n                                <div class=\"form-group row\">\n                                    <label class=\"col-12 col-form-label  col-md-2 control-label text-left text-md-right\" for=\"previousPassword\">\n                                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.current_password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":34,"column":65},"end":{"line":34,"column":95}}}))
    + "</span>\n                                        <span class=\"label-after\">: </span>\n                                    </label>\n                                    <div class=\"col-12 col-md-10\">\n                                        <input class=\"form-control\" type=\"password\" name=\"userSettings[previousPassword]\" id=\"previousPassword\" disabled>\n                                    </div>\n                                </div>\n                                <div class=\"form-group row\">\n                                    <label class=\"col-12 col-form-label  col-md-2 control-label text-left text-md-right\" for=\"newPassword\">\n                                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.password",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":43,"column":65},"end":{"line":43,"column":87}}}))
    + "</span>\n                                        <span class=\"label-after\">: </span>\n                                    </label>\n                                    <div class=\"col-12 col-md-10\">\n                                        <input class=\"form-control\" type=\"password\" name=\"userSettings[newPassword]\" id=\"newPassword\" disabled>\n                                    </div>\n                                </div>\n                                <div class=\"form-group row\">\n                                    <label class=\"col-12 col-form-label  col-md-2 control-label text-left text-md-right\" for=\"newPasswordConfirm\">\n                                        <span class=\"label-text\">"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"form.password_confirm",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":52,"column":65},"end":{"line":52,"column":95}}}))
    + "</span>\n                                        <span class=\"label-after\">: </span>\n                                    </label>\n                                    <div class=\"col-12 col-md-10\">\n                                        <input class=\"form-control\" type=\"password\" name=\"userSettings[newPasswordConfirm]\" id=\"newPasswordConfirm\" disabled>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true})