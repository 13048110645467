import { EventMenu } from 'app/Modules/Events/Assets/components';
import { EventCardComponent } from 'app/Modules/Events/Assets/components/types';
import { ReactElement, useMemo } from 'react';
import { RealTimeCallCard } from '../CallCard';
import { formatCall } from '../CallList/format-call';
import { CallEvent } from './types';

export const CallEventCard: EventCardComponent<CallEvent> = (
  event
): ReactElement => {
  const call = useMemo(() => formatCall(event.data), [event.data]);

  return <RealTimeCallCard call={call} rightMenu={<EventMenu {...event} />} />;
};
