import { memo, ReactElement, ReactNode, useMemo } from 'react';
import { Box, Divider, Flex, useColorModeValue } from '@chakra-ui/react';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';
import ReactFastCompare from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaPhone } from 'react-icons/fa';

import { Card, CardBody, CardHeader } from 'BootQuery/Assets/components/Card';

import { Call } from '../../types/call';
import { CallActionsMenu } from '../CallList/CallActionsMenu';
import { CallDetailsModal } from '../CallList/CallDetailsModal';
import { useCallListSettings } from '../CallList/CallListSettingsContext';
import { CallRecordingIndicators } from '../CallList/CallRecordingIndicators';
import { CallTickets } from '../CallList/CallTickets';
import { formatCall } from '../CallList/format-call';
import { CallParty } from '../CallParty';
import { DurationCounter } from '../DurationCounter';
import { CallStatus as UglyCallStatus } from '../HopsOperator/HopsCallList/CallStatus';
import { useCalcDuration } from '../use-calc-duration';
import { CallStatus } from './CallStatus';

export interface CallCardProps {
  call: Call;
  verboseStatus?: boolean;
  rightMenu?: ReactNode;
}

export const CallCard = memo(
  ({ call, rightMenu, verboseStatus }: CallCardProps): ReactElement => {
    const callInfo = useMemo(() => formatCall(call), [call]);
    const { useUglyIcons } = useCallListSettings();
    const calcDuration = useCalcDuration();

    const blue = useColorModeValue('blue.600', 'blue.400');

    const startAt = new Date(call.startAt);
    const endAt = call.endAt ? new Date(call.endAt) : null;

    const { t } = useTranslation('Telephony');

    const color = useColorModeValue('brand.darkGray', 'brand.lightGray');

    const hasTicketing = 'Ticketing' in window.Bootstrap.modules;

    return (
      <Card
        shadow="base"
        borderBottom="1px solid"
        borderTop="1px solid"
        borderColor="brand.500"
        borderRadius={0}
        bg={useColorModeValue('brand.background', 'brand.backgroundDark')}
        color={color}
      >
        <CardHeader
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex alignItems="center">
            <FaPhone />
            &nbsp;
            {useUglyIcons ? (
              <UglyCallStatus
                answered={callInfo.answered}
                direction={callInfo.direction}
                endAt={callInfo.endAt}
              />
            ) : (
              <CallStatus
                finished={callInfo.endAt !== null}
                direction={callInfo.direction}
                answered={callInfo.answered}
                verbose={verboseStatus}
              />
            )}
          </Flex>
          <Flex alignItems="center">
            <Box>{formatDate(callInfo.startAt)}</Box>
            <Box>{rightMenu}</Box>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <CallParty
                party={callInfo.sourceInfo.source}
                phoneNumber={callInfo.sourceInfo.phoneNumber}
              />
              &nbsp;&nbsp;
              <FaArrowRight />
              &nbsp;&nbsp;
              <CallParty
                party={callInfo.destinationInfo.destination}
                phoneNumber={callInfo.destinationInfo.phoneNumber}
              />
            </Flex>
            <CallDetailsModal call={callInfo} />
          </Flex>
          <Divider my="2" />
          <Flex justifyContent="space-between" alignItems="center">
            <Box mt="2">
              {t('Telephony:columns.duration_total')}:{' '}
              {endAt ? (
                calcDuration(startAt, endAt)
              ) : (
                <DurationCounter from={startAt} color={blue} />
              )}
            </Box>
            <Flex>
              <CallRecordingIndicators call={callInfo} />
              {hasTicketing && <CallTickets call={callInfo} />}
              {hasTicketing && <CallActionsMenu call={callInfo} />}
              <CallDetailsModal call={callInfo} />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  },
  ReactFastCompare
);
CallCard.displayName = 'CallCard';

const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Ppp', { locale: hr });
};
