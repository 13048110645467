import { Button, Divider, useColorModeValue, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useFilteredLink } from '@bq/components/FilterLink';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';

interface AgentProps {
  contactID: number;
  contactType: ContactType;
}

export const AgentContactDisplayFields = ({
  contactID,
  contactType,
}: AgentProps) => {
  const assignedUserLink = useFilteredLink({
    filter: 'assignedUser',
    value: { type: contactType, ID: contactID },
  });
  const createdUserLink = useFilteredLink({
    filter: 'createdByUser',
    value: { type: contactType, ID: contactID },
  });
  const { t } = useTranslation('Ticketing');
  const color = useColorModeValue('gray.700', 'gray.400');

  return (
    <>
      <Divider />
      <VStack>
        <Button
          as={Link}
          to={assignedUserLink}
          leftIcon={<FaFilter />}
          color={color}
        >
          {t('Ticketing:assigned_tickets')}
        </Button>

        <Button
          as={Link}
          to={createdUserLink}
          leftIcon={<FaFilter />}
          color={color}
        >
          {t('Ticketing:created_tickets')}
        </Button>
      </VStack>
    </>
  );
};
