import { Button, chakra } from '@chakra-ui/react';

export const ColorButton = chakra(Button, {
  baseStyle: {
    padding: 0,
    flex: '1 0 auto',
    width: '8',
    height: '8',
    minWidth: 0,
    transition: 'all 0.3s',
    transform: 'scale(0.9)',
    borderStyle: 'dotted',
    borderWidth: 'thin',
    borderColor: 'transparent',
  },
});
