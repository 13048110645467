import {
  CreateNotificationMessage,
  NotificationClickMessage,
  NotificationClosedMessage,
  StructuredMessage,
} from './types';

function isStructuredMessage(message: unknown): message is StructuredMessage {
  return Boolean(
    message &&
      typeof message === 'object' &&
      'type' in message &&
      typeof message.type === 'string'
  );
}

export function isNotificationClickMessage(
  message: unknown
): message is NotificationClickMessage {
  return isStructuredMessage(message) && message.type === 'notificationClick';
}

export function isNotificationClosedMessage(
  message: unknown
): message is NotificationClosedMessage {
  return isStructuredMessage(message) && message.type === 'notificationClosed';
}

export function isCreateNotificationMessage(
  message: unknown
): message is CreateNotificationMessage {
  return isStructuredMessage(message) && message.type === 'createNotification';
}
