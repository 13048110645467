interface ElOptions {
  multiple: boolean;
  accept?: string[] | null;
}

function createPickerEl({ accept, multiple }: ElOptions): HTMLInputElement {
  const inputEl = document.createElement('input');
  inputEl.setAttribute('type', 'file');
  if (multiple) {
    inputEl.multiple = multiple;
  }
  if (accept) {
    inputEl.accept = accept.join(',');
  }

  // We can't click it with display: none, so hide it like this
  inputEl.style.cssText = `
        visibility: hidden;
        width: 0;
        height: 0;
        overflow: hidden;
    `;

  return inputEl;
}

type FileHandler = (files: File[] | null) => void;

function handleFiles(input: HTMLInputElement): File[] | null {
  if (input.files && input.files.length > 0) {
    return [...input.files];
  }

  return null;
}

function openPicker(elOptions: ElOptions, onFiles: FileHandler): void {
  const input = createPickerEl(elOptions);

  let didBlur = false;
  const done = false;

  let cleanup = () => {
    /* Init later, we need to define some stuff first */
  };

  input.addEventListener('input', (ev) => {
    onFiles(handleFiles(input));
    cleanup();
  });

  const onWindowBlur = () => {
    didBlur = true;
  };
  const onWindowFocus = () => {
    if (didBlur) {
      return;
    }

    // MS Edge seems to focus before change sometimes
    // So we just hope this ugly timeout is enough...
    setTimeout(() => {
      // Change has occured in the meantime, nothing to do here
      if (done) {
        return;
      }
      onFiles(handleFiles(input));
      cleanup();
    }, 300);
  };

  window.addEventListener('focus', onWindowFocus);
  window.addEventListener('blur', onWindowBlur);

  cleanup = () => {
    window.removeEventListener('focus', onWindowFocus);
    window.removeEventListener('blur', onWindowBlur);
  };

  input.focus();
  input.click();
}

interface FilePickerOptions {
  multiple?: boolean;
  accept?: string | string[];
}

export async function pickFiles(
  options: FilePickerOptions = {}
): Promise<File[] | null> {
  const accept =
    typeof options.accept === 'string' ? [options.accept] : options.accept;
  const multiple = options.multiple === true;

  return new Promise((resolve) => {
    openPicker({ accept, multiple }, resolve);
  });
}
