import { v4 as uuid4 } from 'uuid';
import { get as apiGet } from 'BootQuery/Assets/js/apiRequest';
// eslint-disable-next-line import/no-cycle
import {
  activateElements,
  setUserSetting,
  getTemplate,
  renderTemplate,
} from 'BootQuery/Assets/js/BootQuery';
import { sleep } from 'app/assets/js/util';
import { xor } from 'lodash-es';
import { SOCKET_STATE } from './socket';

export default class Datatable {
  constructor(table) {
    this.tablePartial = '';
    this.rowPartial = '';
    this.paginationPartial = '';
    this.controller = '';
    this.method = '';
    this.module = '';
    this.defaultSort = '';
    this.idColumn = '';
    this.table = table;
    this.tableName = '';
    this.parameters = {};
    this.eventList = [];
    this.shownTable = {};
    this.currentTable = {};
    this.currentBq = {};
    this.boundEvents = {};
    this.lastFetchRequest = null;
    this.currentFetch = {
      request: null,
      requestStart: null,
    };

    if (window.socket) {
      if (window.socket.readyState === SOCKET_STATE.OPEN) {
        this.onSocketConnect(window.socket);
      }

      const reconnector = () => this.onSocketConnect(window.socket);
      window.socket.on('connect', reconnector);
      this.boundEvents.connect = reconnector;
    }
  }

  init(target, data) {
    this.setDatatableParams(this.table);
    const { tableName } = this;

    this.currentTable = data.tables[tableName];
    this.shownTable = this.currentTable;
    this.currentBq = data.bootquery;

    const radioSelector = `input.datatable-refresh-toggle[data-datatable-name="${
      tableName
    }"]`;
    const refreshSelector = `.datatable-refresh-button[data-datatable-name="${
      tableName
    }"]`;
    const btnSelector = `[data-table-action][data-table="${tableName}"]`;

    const checkboxSelector = `.datatable-column-display-checkbox[data-table="${
      tableName
    }"]`;

    target.findElement(`#${tableName}-limit-selector`)
      .ev('change.datatable', (ev) => {
        const $select = $(ev.currentTarget);
        const limit = parseInt($select.val(), 10);
        if (limit) {
          this.setSetting('limit', limit);
        }
        $(ev.currentTarget).closest('form').submit();
      });

    target
      .findElement(`.sort[data-${tableName}-sort-link]`)
      .ev('click.datatable', (ev) => {
        const link = $(ev.currentTarget);
        const sort = link.data(`${tableName}SortLink`);
        const [column, direction] = sort.split(':');
        this.setSetting('sortby', column);
        this.setSetting('sortdir', direction);
      });

    $(document).ev('change', checkboxSelector, (e) => {
      e.preventDefault();
      e.stopPropagation();

      const visibleColumns = {};
      $(`.datatable-column-display-checkbox[data-table="${this.tableName}"]`).each(
        (_index, checkbox) => {
          const columnKey = $(checkbox).data('key');
          visibleColumns[columnKey] = $(checkbox).prop('checked');
        },
      );
      this.setSetting('visibleColumns', visibleColumns);

      // Re-render the table
      const table = data.tables[this.tableName];
      table.columns.forEach((column) => {
        column.visible = visibleColumns[column.key];
      });
      table.columnCount = table.columns.filter((col) => col.visible).length;
      if (table.rowCheckbox) {
        table.columnCount++;
      }

      getTemplate(this.tablePartial, this.module).then((tablePartial) => {
        const renderData = data.tables[this.tableName];
        renderData.bootquery = window.Bootstrap.bootquery;
        renderTemplate(tablePartial, renderData, `#${this.table.attr('id')}`, null);
      });
    });
    $(document).ev('click', '.datatable-dropdown>.dropdown-item', (e) => {
      e.preventDefault();
      e.stopPropagation();

      const row = $(e.currentTarget);
      const checkbox = row.find('input');
      checkbox.prop('checked', !checkbox.prop('checked'));
      checkbox.trigger('change');
    });

    target.findElement(btnSelector).ev('click', (e) => {
      e.preventDefault();
      const action = $(e.currentTarget).data('tableAction');
      const $filterForm = $(`#${this.tableName}-filter-form`);
      $filterForm.data('submittedByAction', action);
      $filterForm.submit();
    });

    // If no events, there's nothing to do!
    if (this.eventList.length > 0) {
      this.socketToken = `Datatable:${uuid4()}`;
      this.refreshUpdateStatus(target);

      $(document).ev('change', radioSelector, (e) => {
        e.preventDefault();
        e.stopPropagation();

        const radio = e.currentTarget;
        if (radio.checked) {
          const refreshType = $(radio).val();
          this.refreshUpdateStatus();
          this.displayNewRows();
          this.setSetting('autoRefresh', refreshType === 'auto');
        }
      });

      $(document).ev('click', refreshSelector, (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.displayNewRows();
      });

      this.subscribe();
      this.bindEvents();
    } else {
      target
        .findElement(`.datatable-refresh[data-datatable-name="${this.tableName}"]`)
        .remove();
    }
  }

  setSetting(key, value) {
    const tableSettingPrefix = `Datatables.${this.module}.${this.tableName}`;
    return setUserSetting(`${tableSettingPrefix}.${key}`, value);
  }

  bindEvents() {
    if (window.socket && window.socket.readyState === SOCKET_STATE.OPEN) {
      this.eventList.forEach((eventName) => {
        const listener = (data) => {
          if (data.for.indexOf(this.socketToken) !== -1) {
            this.refreshDatatable();
          }
        };
        window.socket.addListener(eventName, listener);
        this.boundEvents[eventName] = listener;
      });
    }
  }

  setDatatableParams(table) {
    const tableData = $(table).data();

    this.tablePartial = tableData.datatableTablePartial || 'table';
    this.rowPartial = tableData.datatableRowPartial || 'tableRow';
    this.paginationPartial = tableData.datatablePaginationPartial || 'pagination';
    this.controller = tableData.datatableController || window.Bootstrap.bootquery.controller;
    this.method = tableData.datatableMethod || window.Bootstrap.bootquery.method;
    this.module = tableData.datatableModule || null;

    this.parameters = tableData.datatableParameters || window.Bootstrap.bootquery.parameters || [];
    // Kind of a weird hack but sometimes we accidentally submit forms when getting stuff
    // Because someone thought it's a good idea to merge GET and POST params in the backend
    // So we will for now delete just this field that in most cases means we need to update
    delete this.parameters.action;

    this.defaultSort = tableData.datatableDefaultsort;
    this.idColumn = tableData.datatableIdColumn || 'ID';

    if (tableData.datatableEvents) {
      this.eventList = tableData.datatableEvents.split(',');
    }

    this.table = table;
    this.tableName = table.attr('id').replace('-table', '');
  }

  getDatatableRefreshType() {
    return $(`.datatable-refresh[data-datatable-name="${this.tableName}"`)
      .find('input[type="radio"]:checked')
      .val();
  }

  async refreshDatatable() {
    console.log(`Refreshing datatable ${this.tableName}`);
    if (this.currentFetch.request || this.preWait) {
      this.lastFetchRequest = new Date();
      return;
    }

    this.preWait = true;
    await sleep(250);
    this.preWait = false;

    const req = apiGet(`/${this.controller}/${this.method}.json`, this.parameters);
    this.currentFetch.request = req;
    this.currentFetch.requestStart = new Date();

    const data = await req;
    this.currentTable = data.tables[this.tableName];
    this.currentBq = data.bootquery;
    this.refreshUpdateStatus();

    const refreshType = this.getDatatableRefreshType();
    const autorefresh = refreshType == 'auto';
    if (autorefresh) {
      this.displayNewRows();
    }

    await sleep(250);
    const { requestStart } = this.currentFetch;
    this.currentFetch.request = null;
    this.currentFetch.requestStart = null;

    if (this.lastFetchRequest && this.lastFetchRequest > requestStart) {
      this.refreshDatatable();
    }
  }

  async refreshUpdateStatus(target = 'body') {
    const $target = $(target);
    const diffCount = this.rowDiff(true).length;
    if (this.getDatatableRefreshType() === 'auto') {
      $target
        .findElement('.datatable-refresh-button')
        .removeClass('btn-primary')
        .addClass('btn-success')
        .addClass('disabled')
        .attr('disabled', 'disabled');
      $target
        .findElement('.datatable-refresh-button .datatable-update-count')
        .text('A');
    } else {
      $target
        .findElement('.datatable-refresh-button')
        .removeClass('btn-success')
        .addClass('btn-primary')
        .toggleClass('disabled', diffCount === 0)
        .prop('disabled', diffCount === 0);
      $target
        .findElement('.datatable-refresh-button .datatable-update-count')
        .text(diffCount);
    }
  }

  async displayNewRows(force = false) {
    const newRows = this.rowDiff();
    const hasAddedOrRemovedRows = newRows.length > 0 || this.fullDiff().length > 0;
    if (!hasAddedOrRemovedRows && !force) {
      return;
    }

    const template = await getTemplate(this.tablePartial, this.module);

    // 	Inject the bootquery object so the table can access controller and session info
    this.currentTable.bootquery = this.currentBq;
    const rendered = $.render(template, this.currentTable);
    const renderedTable = rendered.findElement(`#${this.table.attr('id')}`);
    this.table.empty().append(renderedTable.html());
    activateElements(this.table.parent());

    const rowSel = (row) => `tr[data-datatable-row-id="${row}"]`;
    newRows.forEach((row) => {
      $(rowSel(row))
        .addClass('animate-row')
        .addClass('table-success');
    });

    // Hold the new-row color for 1 second
    setTimeout(() => {
      // Start fading
      newRows.forEach((row) => {
        $(rowSel(row)).removeClass('table-success');
      });

      // After 1 more second when fading is done, remove the transition thing
      setTimeout(() => {
        newRows.forEach((row) => {
          $(rowSel(row)).removeClass('animate-row');
        });
      }, 1000);
    }, 1000);

    this.shownTable = this.currentTable;
    this.refreshUpdateStatus();
  }

  rowDiff(onlyNew) {
    if (!this.currentTable || !this.shownTable) {
      return [];
    }

    const indexById = (arr) => arr.reduce((byId, row) => {
      byId[row.ID] = row;
      return byId;
    }, {});
    const shownById = indexById(this.shownTable.result);
    const diff = new Set();

    this.currentTable.result.forEach((row) => {
      const { ID } = row;
      row = { ...row };

      delete row.rowIndex;
      let shownRow = shownById[ID];
      if (shownRow) { // Existing row, diff
        shownRow = { ...shownRow };
        delete shownRow.rowIndex;
        if (!onlyNew && JSON.stringify(row) !== JSON.stringify(shownRow)) {
          diff.add(ID);
        }
      } else { // New row
        diff.add(ID);
      }
    });

    return [...diff];
  }

  fullDiff() {
    if (!this.currentTable || !this.shownTable) {
      return [];
    }

    const currentIds = this.currentTable.result.map(({ ID }) => ID);
    const prevIds = this.shownTable.result.map(({ ID }) => ID);

    return xor(currentIds, prevIds);
  }

  onSocketConnect(socket) {
    this.subscribe();
    this.bindEvents();
  }

  unload() {
    this.unsubscribe();
  }

  subscribe() {
    if (this.eventList.length === 0) {
      return;
    }
    if (window.socket.readyState === WebSocket.OPEN) {
      window.socket.send('subscribe', {
        events: this.eventList,
        token: this.socketToken,
      });
    }
  }

  unsubscribe() {
    Object.entries(this.boundEvents).forEach(([eventName, listener]) => {
      window.socket.removeListener(eventName, listener);
    });

    if (this.eventList.length > 0 && window.socket.readyState === WebSocket.OPEN) {
      window.socket.send('unsubscribe', {
        events: this.eventList,
        token: this.socketToken,
      });
    }
  }
}

export function deinitDatatables(data = {}) {
  Object.entries(window.datatables).forEach(([tableName, datatable]) => {
    if (data.tables && data.tables[tableName] && data.tables[tableName].datatable) {
      return;
    }
    datatable.unload();
    delete window.datatables[tableName];
  });
}

export function initDatatables(target, data) {
  if (data.bootquery?.isModal) {
    console.log('Not messing with datatables because modal');
    return;
  }
  if (!window.datatables) {
    window.datatables = {};
  }

  deinitDatatables(data);

  target.findElement('.datatable').each((_i, el) => {
    const table = $(el);
    const tableName = table.attr('id').replace('-table', '');

    if (
      data.tables[tableName] !== undefined
			&& typeof data.tables[tableName].datatable === 'object'
    ) {
      return;
    }

    // Create a new datatable
    const datatable = new Datatable(table);

    data.tables[tableName].datatable = datatable;
    datatable.init(target, data);
    window.datatables[tableName] = datatable;
  });
}
