import { memo, RefObject, useEffect, useRef } from 'react';

import {
  Event,
  EventCardComponent,
} from 'app/Modules/Events/Assets/components/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Vue from 'BootQuery/Assets/js/vue';

import { submitEditor } from './submit-editor';
import { MailEditor } from './types';
import { useMailEditors } from './use-mail-editors';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const MailEditorCardWrapper = () => import('./MailEditorCardWrapper.vue');

export interface MailEditorEvent extends Event {
  data: MailEditor;
}

interface EditorVueProps extends MailEditor {
  onSubmit(editorID: string, data: unknown): void;
  onCancel(data: string): void;
}

const MailEditorCardInner: EventCardComponent<MailEditorEvent> = ({ data }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const vueInstance = useRef<Vue>(null);
  const { removeEditor, onSent } = useMailEditors();

  useEffect(() => {
    scrollToEditor(containerRef);
  }, []);
  useEffect(() => {
    let justRendered = false;
    if (containerRef.current && !vueInstance.current) {
      justRendered = true;
      vueInstance.current = renderVueEditor(containerRef.current, {
        ...data,
        async onSubmit(editorID, data) {
          const { sentID } = await submitEditor(data);

          onSent?.(sentID);
          removeEditor(editorID);
        },
        onCancel(editorID) {
          removeEditor(editorID);
        },
      });
      setTimeout(() => {
        scrollToEditor(containerRef);
      });
    }

    return () => {
      if (!justRendered && vueInstance.current) {
        vueInstance.current.$destroy();
        vueInstance.current = null;
      }
    };
  }, [data, onSent, removeEditor]);

  return <div ref={containerRef}>...</div>;
};

export const MailEditorCard = memo(
  MailEditorCardInner
) as typeof MailEditorCardInner;

function renderVueEditor(containerEl: HTMLElement, props: EditorVueProps): Vue {
  return new Vue({
    el: containerEl,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(MailEditorCardWrapper, { props }),
  });
}

function scrollToEditor(containerRef: RefObject<HTMLElement>): void {
  if (!containerRef.current) {
    return;
  }

  containerRef.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
}
