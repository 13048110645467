import { FaFile } from 'react-icons/fa';

import i18n from 'BootQuery/Assets/js/i18n';

import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { FileField } from './FileField';
import { FileFieldSettingsComponent } from './FileFieldSettings';
import { StaticFileField } from './StaticFileField';
import { FileFieldSettings } from './types';

export const fileField = (): FieldType<FileFieldSettings> => ({
  type: 'file',
  name: i18n.t('global:form_editor.field_file'),
  icon: () => {
    return FaFile({});
  },
  components: {
    FormField: FileField,
    EditorFieldSettings: FileFieldSettingsComponent,
    StaticFormField: StaticFileField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
    accept: '*',
  }),
  getFilters(_field) {
    return {};
  },
  getColumns(_field) {
    return [];
  },
});
