import { useCallback, useMemo } from 'react';

import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { CaseType } from '../../../schemas';
import { getCaseTypes } from '../../../Settings/CaseTypes/api/case-types';
import { SubjectType } from '../../../Settings/CaseTypes/types';
import { useCampaignFormProvider } from '../CampaignFormWrapper';
import { useCampaignFormController } from '../use-campaign-form-controller';

export type CaseOptionAdditionalData = {
  isSystem: boolean;
  subjectType: SubjectType;
  datasetID?: number | null;
};

export type CaseTypeSelectOption = SelectOption<
  number,
  CaseOptionAdditionalData
>;
export const CaseTypeField = () => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useCampaignFormController('caseType', {
    rules: { required: 'required' },
  });

  const {
    ctx: { mode },
  } = useCampaignFormProvider();

  const selectToCaseTypeValue = useCallback(
    (val: CaseTypeSelectOption | null | undefined): CaseType | null => {
      if (!val) {
        return null;
      }

      return {
        ID: val.value,
        name: val.label,
        datasetID: val.datasetID ?? null,
        isSystem: val.isSystem,
        subjectType: val.subjectType,
      };
    },
    []
  );

  const caseTypeToSelect = useCallback(
    (val: CaseType | null | undefined): CaseTypeSelectOption | null => {
      if (!val) {
        return null;
      }

      return {
        label: val.name,
        value: val.ID,
        datasetID: val.datasetID,
        isSystem: val.isSystem,
        subjectType: val.subjectType,
      };
    },
    []
  );
  const selectValue = useMemo(
    () => caseTypeToSelect(value),
    [caseTypeToSelect, value]
  );

  return (
    <Select<number, CaseOptionAdditionalData>
      options={async (search) => {
        const data = await getCaseTypes({ filters: { $search: search } });

        return data.map((item) => ({
          value: item.ID,
          label: item.name,
          isSystem: item.isSystem,
          subjectType: item.subjectType,
          datasetID: item.datasetID ?? null,
        }));
      }}
      value={selectValue}
      onChange={(val) => {
        onChange(selectToCaseTypeValue(val));
      }}
      isInvalid={!!error?.message}
      size={{ sm: 'lg', md: 'lg', lg: 'sm' }}
      isDisabled={mode === 'edit'}
    />
  );
};
