import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD/use-settings-crud';

import { CasePriority } from '../../schemas';
import { AddPriorityButton } from './AddPrioritiesButton';
import { PriorityItem } from './PriorityItem';
import { usePriorities } from './use-priorities';

export const CasePriortiesContent = () => {
  const { data: priorities } = usePriorities();
  if (!priorities) {
    return <LoadingPage />;
  }

  return <CasePriorities priorities={priorities.data} />;
};

interface Props {
  priorities: CasePriority[];
}

export const CasePriorities = ({ priorities }: Props) => {
  const [modifiedPriorities, modify] = useSettingsCrud<CasePriority>({
    parent: '#settings-form',
    inputName: 'sales[priorities]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: priorities,
  });

  return (
    <>
      <VStack w="full" pr={0} spacing={0}>
        {modifiedPriorities.map((priority) => (
          <PriorityItem key={priority.ID} priority={priority} modify={modify} />
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <AddPriorityButton modify={modify} />
      </Flex>
    </>
  );
};
