import { Button, HStack } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight, FaReply, FaReplyAll } from 'react-icons/fa';
import { Message as IMessage } from '../../js/types';

export interface IMessageActions {
  reply: (message: IMessage) => void;
  replyAll: (message: IMessage) => void;
  forward: (message: IMessage) => void;
}

interface Props extends IMessageActions {
  message: IMessage;
}

export const MessageActions = ({
  reply,
  replyAll,
  forward,
  message,
}: Props): ReactElement => {
  const { t } = useTranslation('Mails');

  const actions = useMemo(() => ({
    reply: () => reply(message),
    replyAll: () => replyAll(message),
    forward: () => forward(message),
  }), [message, forward, reply, replyAll]);

  return (
    <HStack justifyContent="end">
      <Button type="button" colorScheme="blue" onClick={actions.reply}>
        <FaReply style={{ display: 'inline' }} />&nbsp;
        {t('Mails:reply')}
      </Button>
      <Button type="button" colorScheme="blue" onClick={actions.replyAll}>
        <FaReplyAll style={{ display: 'inline' }} />&nbsp;
        {t('Mails:reply_all')}
      </Button>
      <Button type="button" colorScheme="gray" onClick={actions.forward}>
        <FaArrowRight style={{ display: 'inline' }} />&nbsp;
        {t('Mails:forward')}
      </Button>
    </HStack>
  );
};
