import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { CaseState } from '../../schemas';

export const getStates = async (params?: GetListingParams) => {
  const { data } = await Api.get<ListingResponse<CaseState>>(
    '/api/sales/states',
    {
      params,
    }
  );

  return data;
};
