import { ReactElement, ReactNode, useCallback, ChangeEvent } from 'react';
import {
  Select,
  FormLabel,
  FormControl,
  HStack,
  Text,
  Box,
  ButtonProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { FaEdit, FaUndo } from 'react-icons/fa';
import { DeviceInfo } from '../types';

type ButtonColorScheme = ButtonProps['colorScheme'];

interface BaseDeviceInputProps {
  label: string;
  icon?: ReactNode;
  id?: string;
  devices: DeviceInfo[];
  value: DeviceInfo | null | undefined;
  onChange: (newValue: DeviceInfo | null) => void;
  buttonColorScheme?: ButtonColorScheme;
}

interface InputDeviceInputProps extends BaseDeviceInputProps {
  type: 'input';
}

interface OutputDeviceInputProps extends BaseDeviceInputProps {
  type: 'output';
  selectAudioOutputSupported: boolean;
}

type DeviceInputProps = InputDeviceInputProps | OutputDeviceInputProps;

const optionStyle = {
  color: 'black',
  background: 'white',
};

export const DeviceInput = (props: DeviceInputProps): ReactElement => {
  const { label, icon, id, devices, value, onChange, buttonColorScheme } =
    props;

  const useButton = props.type === 'output' && props.selectAudioOutputSupported;

  return (
    <FormControl display="flex" flexDir="column">
      <FormLabel display="flex" alignItems="center" htmlFor={id} mb="0">
        {icon && <>{icon}&nbsp;</>}
        {label}:
      </FormLabel>
      {useButton ? (
        <DeviceSelectButton
          onChange={onChange}
          value={value ?? null}
          buttonColorScheme={buttonColorScheme}
        />
      ) : (
        <DeviceSelectDropdown
          id={id}
          devices={devices}
          value={value ?? null}
          onChange={onChange}
        />
      )}
    </FormControl>
  );
};

interface DeviceSelectButtonProps {
  onChange: (newValue: DeviceInfo | null) => void;
  value: DeviceInfo | null;
  buttonColorScheme?: ButtonColorScheme;
}

const DeviceSelectButton = ({
  onChange,
  value,
  buttonColorScheme,
}: DeviceSelectButtonProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  const selectAudioOutput = useCallback(async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mediaDevices = navigator.mediaDevices as any;
      const { kind, deviceId, label }: MediaDeviceInfo =
        await mediaDevices.selectAudioOutput();
      onChange({ kind, deviceId, label });
    } catch (err: unknown) {
      console.warn('Cancelled selecting audio output', err);
    }
  }, [onChange]);

  const reset = useCallback(() => {
    onChange(null);
  }, [onChange]);

  const label = value?.label ?? `(${t('Telephony:settings.system_default')})`;

  return (
    <HStack justifyContent="space-between">
      <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
        <Text title={label} as="span" fontWeight="bold">
          {label}
        </Text>
      </Box>
      <HStack>
        {value && (
          <IconButton
            flex="0 1 auto"
            type="button"
            size="sm"
            label="Reset to default"
            icon={<FaUndo />}
            onClick={reset}
          />
        )}
        <IconButton
          flex="0 1 auto"
          type="button"
          size="sm"
          label={t('Telephony:settings.select_device')}
          onClick={selectAudioOutput}
          colorScheme={buttonColorScheme}
          icon={<FaEdit />}
        />
      </HStack>
    </HStack>
  );
};

interface DeviceSelectDropdownProps {
  id?: string;
  devices: DeviceInfo[];
  value: DeviceInfo | null;
  onChange: (newValue: DeviceInfo | null) => void;
}

const DeviceSelectDropdown = ({
  id,
  devices,
  value,
  onChange,
}: DeviceSelectDropdownProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLSelectElement>) => {
      const val = ev.target.value;
      if (val === 'null') {
        onChange(null);

        return;
      }

      const selected = devices.find((dev) => dev.deviceId === val);
      if (!selected) {
        throw new Error(`Failed to find selected device ${val}`);
      }
      onChange(selected);
    },
    [onChange, devices]
  );

  return (
    <Select id={id} value={value?.deviceId ?? 'null'} onChange={handleChange}>
      <option value="null" style={optionStyle}>
        ({t('Telephony:settings.system_default')})
      </option>
      {devices.map((dev) => (
        <option key={dev.deviceId} value={dev.deviceId} style={optionStyle}>
          {dev.label}
        </option>
      ))}
    </Select>
  );
};
