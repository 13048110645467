import { makeNumberFilter } from '@bq/components/FilterBar/filters/NumberFilter';

export const idFilter = makeNumberFilter({
  name: '#',
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }
    if (operator === 'between') {
      if (typeof value === 'number') {
        return null;
      }

      return {
        $and: {
          'ID:gte': value.from,
          'ID:lte': value.to,
        },
      };
    }

    if (typeof value === 'number') {
      return ['ID', operator ?? 'eq', value];
    }

    return null;
  },
});
