import { FieldType, FieldValue } from './types';
import { findType } from './util';

export function loadDefinitions(
  types: FieldType[],
  definition: FieldValue[]
): FieldValue[] {
  return definition.map((field) => {
    const fieldType = findType(types, field.type);

    if (fieldType.loadDefinition) {
      return {
        ...field,
        settings: fieldType.loadDefinition(field.settings, types),
      };
    }

    return field;
  });
}
