import {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
} from 'react';
import {
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';

import { ButtonCheckbox } from '@bq/components/ButtonCheckbox';

import { GroupVisibilityMap } from '../types';
import { useGroups } from './groups';

interface Props {
  value: GroupVisibilityMap;
  onChange: Dispatch<SetStateAction<GroupVisibilityMap>>;
}

export const GroupVisibilitySettings = ({
  value,
  onChange,
}: Props): ReactElement => {
  const { t } = useTranslation('global');
  const groups = useGroups();

  const selectAll = useCallback(() => {
    const allSelected = groups.reduce(
      (prevMap: GroupVisibilityMap, group) => ({
        ...prevMap,
        [group.ID]: true,
      }),
      {}
    );

    onChange(allSelected);
  }, [groups, onChange]);

  const selectNone = useCallback(() => {
    const noneSelected = groups.reduce(
      (prevMap: GroupVisibilityMap, group) => ({
        ...prevMap,
        [group.ID]: false,
      }),
      {}
    );

    onChange(noneSelected);
  }, [groups, onChange]);

  return (
    <VStack alignItems="stretch">
      <Flex justifyContent="space-between">
        <FormLabel>{t('global:overviews.group_visibility')}:</FormLabel>
        <ButtonGroup isAttached>
          <Button onClick={selectAll}>
            {t('global:selector.select_all')}&nbsp;
            <FaRegCheckSquare />
          </Button>
          <Button onClick={selectNone}>
            {t('global:selector.select_none')}&nbsp;
            <FaRegSquare />
          </Button>
        </ButtonGroup>
      </Flex>

      <HStack width="full" wrap="wrap">
        {groups.map((group) => (
          <GroupCheck
            key={group.ID}
            ID={group.ID}
            name={group.name}
            isVisible={value[group.ID] !== false}
            onGroupVisibilityChange={onChange}
          />
        ))}
      </HStack>
    </VStack>
  );
};

interface GroupCheckProps {
  ID: number;
  name: string;
  isVisible: boolean;
  onGroupVisibilityChange: Dispatch<SetStateAction<GroupVisibilityMap>>;
}

const GroupCheck = ({
  ID,
  name,
  isVisible,
  onGroupVisibilityChange,
}: GroupCheckProps): ReactElement => {
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onGroupVisibilityChange((prev) => ({ ...prev, [ID]: ev.target.checked }));
    },
    [ID, onGroupVisibilityChange]
  );

  return (
    <ButtonCheckbox isChecked={isVisible} onChange={handleChange} mb={0}>
      {name}
    </ButtonCheckbox>
  );
};
