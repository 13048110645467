import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterFromEmailAddress = makeTextFilter({
  name: () => i18n.t('Ticketing:from_mail_address'),
  toFilter: ({ operator, value }) => {
    if (!operator || !value) {
      return null;
    }

    return { [`fromMailAddress.email:${operator}`]: value };
  },
});
