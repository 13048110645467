import { ReactElement, useCallback } from 'react';
import {
  FormControl,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useDOMInputJSON } from '@bq/components/use-dom-input';
import { useModalInstance } from '@bq/components/UseModal';

import { useCallFormSettings } from './api';
import { CallDirectionField } from './CallDirectionField';
import { CallFormTriggerField } from './CallFormTriggerField';
import { CompulsoryFormModeField } from './CompulsoryFormModeField';
import { PauseTypeField } from './PauseTypeField';
import { CallFormSettings, CallFormSettingsForm } from './types';

interface Props {
  groupID: number | string;
}

export const CallFormPermissionsModal = ({ groupID }: Props): ReactElement => {
  const { data: settings } = useCallFormSettings(groupID);

  if (!settings) {
    return (
      <ModalContent as="form">
        <LoadingPage />
      </ModalContent>
    );
  }

  return <SettingsModalContent {...settings} />;
};

const SettingsModalContent = (defaults: CallFormSettings): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { closeWithNoCallback } = useModalInstance();
  const [storedValue, setStoredValue] = useDOMInputJSON<CallFormSettings>(
    '#group-modify-container',
    'telephonyPermissions[callForm]',
    defaults
  );

  const formMethods = useForm<CallFormSettingsForm>({
    defaultValues: permissionsToForm(storedValue),
  });

  const submit = useCallback(
    (data: CallFormSettingsForm) => {
      setStoredValue(permissionsFromForm(data));
      setTimeout(closeWithNoCallback, 0);
    },
    [closeWithNoCallback, setStoredValue]
  );

  const cancel = useCallback(() => {
    closeWithNoCallback();
  }, [closeWithNoCallback]);

  return (
    <FormProvider {...formMethods}>
      <ModalContent
        as="form"
        data-ignore-form-save
        onSubmit={formMethods.handleSubmit(submit)}
      >
        <ModalHeader>{t('Telephony:permissions.call_form')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel>
                {t('Telephony:permissions.call_form_compulsory')}: &nbsp;
                <Tooltip
                  colorScheme="blue"
                  label={t('Telephony:permissions.call_form_compulsory_tip')}
                >
                  <Text as="span" color="blue.500">
                    <FaInfoCircle style={{ display: 'inline' }} />
                  </Text>
                </Tooltip>
              </FormLabel>
              <CompulsoryFormModeField />
            </FormControl>

            <FormControl>
              <FormLabel>{t('Telephony:direction')}:</FormLabel>
              <CallDirectionField />
            </FormControl>

            <FormControl>
              <FormLabel>
                {t('Telephony:permissions.call_form_trigger')}:
              </FormLabel>
              <CallFormTriggerField />
            </FormControl>

            <FormControl>
              <FormLabel>
                {t('Telephony:permissions.call_form_pause_type')}:
              </FormLabel>
              <PauseTypeField />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <FormActions onCancel={cancel} />
        </ModalFooter>
      </ModalContent>
    </FormProvider>
  );
};

function permissionsToForm(
  permissions: CallFormSettings
): CallFormSettingsForm {
  const parseCallForm = (value: boolean | null) => {
    if (value === true) {
      return 'yes';
    }
    if (value === false) {
      return 'no';
    }

    return 'null';
  };

  const parseDirection = (value: string | null) => {
    if (!value) {
      return 'both';
    }

    if (value === 'incoming' || value === 'outgoing' || value === 'both') {
      return value;
    }

    console.warn('Invalid direction: ', value);

    return 'both';
  };

  const parseTrigger = (value: string | null) => {
    if (!value) {
      return 'hangup';
    }

    if (value === 'hangup' || value === 'answer') {
      return value;
    }

    console.warn('Invalid trigger: ', value);

    return 'hangup';
  };

  return {
    compulsoryCallForm: parseCallForm(permissions.compulsoryCallForm),
    compulsoryCallFormPause: permissions.compulsoryCallFormPause,
    compulsoryCallFormTrigger: parseTrigger(
      permissions.compulsoryCallFormTrigger
    ),
    compulsoryCallFormDirection: parseDirection(
      permissions.compulsoryCallFormDirection
    ),
  };
}

function permissionsFromForm(form: CallFormSettingsForm): CallFormSettings {
  const parseCallForm = (value: string | null) => {
    if (value === 'yes') {
      return true;
    }
    if (value === 'no') {
      return false;
    }

    return null;
  };

  return {
    compulsoryCallForm: parseCallForm(form.compulsoryCallForm),
    compulsoryCallFormPause: form.compulsoryCallFormPause,
    compulsoryCallFormDirection: form.compulsoryCallFormDirection,
    compulsoryCallFormTrigger: form.compulsoryCallFormTrigger,
  };
}
