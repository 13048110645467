import { Dispatch, ReactElement } from 'react';
import { Box, chakra, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FileDisplay } from './FileDisplay';
import { FileStateAction } from './files-state';
import { FileInfo } from './types';

const OverlayText = chakra(Box, {
  baseStyle: {
    color: 'gray.400',
    w: 'full',
    textAlign: 'center',
    p: '2',
    pointerEvents: 'none',
  },
});

interface FileListProps {
  files: FileInfo[];
  dispatch: Dispatch<FileStateAction>;
}

export const FileList = ({ files, dispatch }: FileListProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Box px="2" py="2">
      <OverlayText w="full">{t('global:file_list_instruction')}</OverlayText>
      <SimpleGrid columns={[1, 1, 2, 3]} gridGap={1}>
        {files.map((file, idx) => (
          <FileDisplay key={idx} {...file} dispatch={dispatch} />
        ))}
      </SimpleGrid>
    </Box>
  );
};
