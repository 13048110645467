import { ReactElement, useCallback } from 'react';
import { Box, Divider, HStack, VStack } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AddButton } from '@bq/components/AddButton';
import { DeleteButton } from '@bq/components/DeleteButton';
import { WithSeparator } from '@bq/components/WithSeparator';

import { ProductItem } from './ProductItem';
import { ProductItemTotals } from './ProductItemTotals';
import { ItemFields } from './types';

interface Props {
  /** The path to the root product list field. Eg. `something.products` */
  fieldName: string;
  itemFields?: ItemFields;
}

export const ProductItems = ({
  fieldName,
  itemFields,
}: Props): ReactElement => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const addItem = useCallback(() => {
    append({
      product: null,
      quantity: '1',
      value: '',
      grossWeightKg: '',
      netWeightKg: '',
    });
  }, [append]);

  const handleDelete = useCallback(
    (idx: number) => {
      remove(idx);
    },
    [remove]
  );

  return (
    <VStack w="full" alignItems="stretch">
      <WithSeparator separator={<Divider />}>
        {fields.map((field, idx) => (
          <ProductListItem
            key={field.id}
            idx={idx}
            fieldName={`${fieldName}.${idx}`}
            onDelete={handleDelete}
          />
        ))}
        <AddButton onClick={addItem} alignSelf="end" />
        <ProductItemTotals fieldName={fieldName} />
      </WithSeparator>
    </VStack>
  );
};

interface ProductListItemProps {
  idx: number;
  fieldName: string;
  onDelete: (idx: number) => void;
  itemFields?: ItemFields;
}

const ProductListItem = ({
  idx,
  fieldName,
  itemFields,
  onDelete,
}: ProductListItemProps): ReactElement => {
  const handleDelete = useCallback(() => {
    onDelete(idx);
  }, [onDelete, idx]);

  return (
    <HStack alignItems="end">
      <ProductItem fieldName={fieldName} itemFields={itemFields} required />
      <Box pl="4">
        <DeleteButton onClick={handleDelete} />
      </Box>
    </HStack>
  );
};
