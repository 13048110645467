import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useUpdateEffect } from 'react-use';

type ParentDef = string | HTMLElement;

export const useDOMInput = (
  parent: ParentDef,
  inputName: string,
  defaultVal = ''
): [value: string, setValue: Dispatch<SetStateAction<string>>] => {
  // Read the value from input initially, but after that
  // only keep it in state, syncing to input on change
  const initialVal = useMemo(
    () => getInputEl(parent, inputName)?.value ?? defaultVal,
    [parent, inputName, defaultVal]
  );

  const [internalValue, setInternalValue] = useState(initialVal);
  useSyncInputValue(parent, inputName, internalValue, initialVal);

  return [internalValue, setInternalValue];
};

export const useDOMInputJSON = <T>(
  parent: ParentDef,
  inputName: string,
  defaultVal?: T
): [value: T, setValue: Dispatch<SetStateAction<T>>] => {
  const outDefault = typeof defaultVal === 'undefined' ? null : defaultVal;
  const [strVal, setStrVal] = useDOMInput(parent, inputName);
  const [value, setInternalValue] = useState<T>(
    strVal ? JSON.parse(strVal) : outDefault
  );

  const setValue = useCallback(
    (dispatch: SetStateAction<T>) => {
      setInternalValue((prevValue) => {
        const newVal =
          typeof dispatch === 'function'
            ? (dispatch as (prev: T) => T)(prevValue)
            : dispatch;
        setStrVal(JSON.stringify(newVal));

        return newVal;
      });
    },
    [setStrVal]
  );

  useUpdateEffect(() => {
    console.log('Changin in effect: ', value);
    setStrVal(JSON.stringify(value));
  }, [value, setStrVal]);

  return [value, setValue];
};

const useSyncInputValue = (
  parent: ParentDef,
  inputName: string,
  value: string,
  initialValue: string
): void => {
  useUpdateEffect(() => {
    const inputEl = getInputEl(parent, inputName, true);
    if (!inputEl) {
      throw new Error(`Unable to find input "${inputName}" to set value`);
    }

    inputEl.value = value;
  }, [parent, inputName, value, initialValue]);
};

const getInputEl = (
  parent: ParentDef,
  name: string,
  createMissing = false
): HTMLInputElement | null => {
  const parentEl =
    typeof parent === 'string' ? document.querySelector(parent) : parent;

  if (!parentEl) {
    if (createMissing) {
      throw new Error(
        `Missing parent element while trying to get input "${name}"`
      );
    }

    return null;
  }

  let el = parentEl.querySelector<HTMLInputElement>(`input[name="${name}"]`);
  if (!el && createMissing) {
    el = document.createElement('input');
    el.type = 'hidden';
    el.name = name;
    parentEl.append(el);
  }

  return el;
};
