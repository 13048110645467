import { ReactElement } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import {
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { setColValues } from 'app/Modules/Ticketing/Util/GetColumns';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { TableSelectionProvider } from 'BootQuery/Assets/components/Table';
import { CardBulkActionMenu } from 'BootQuery/Assets/components/Table/CardBulkActionMenu';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { PhonebookListContext } from '../PhonebookListContext';
import { CompanyApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns, companyCardFeatures } from './columns';
import { useCompanyBulkActions } from './company-list-bulk-actions';
import { CompanyCard } from './CompanyCard';
import { filterTypes } from './filter-types';
import { generateExport } from './generate-export';
import { getCardCompanies } from './get-companies';

interface Props {
  customFields?: FieldValue[];
}

export const CompanyCardList = ({ customFields }: Props): ReactElement => {
  const overview = useSelectedOverview('companies');

  const listingSettings = useListingSettings<CompanyApiData>({
    listingName: 'Phonebook.CompanyList',
    viewName: 'card',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { columnsToShow, filters, page, limit, density } = listingSettings;

  const { columns: customColumns, features } = useSplitColumnsFeatures(
    columnsToShow,
    companyCardFeatures
  );

  const { data, refetch } = useQuery({
    queryKey: ['Phonebook.CompanyList.card', page, filters, limit],
    queryFn: () => getCardCompanies({ page, filters, limit }),
    enabled: filters !== undefined,
    keepPreviousData: true,
  });
  const bulkActions = useCompanyBulkActions(refetch);
  const itemCount = data?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    displayModeOptions: phonebookDisplayModeOptions,
    menuChildren: <ExportMenuItem />,
    count: itemCount,
  });

  if (!data) {
    return <LoadingPage />;
  }

  const {
    data: companies,
    meta: { count },
  } = data;

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<CompanyApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardCompanies({
            fields: [
              'ID',
              'name',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],
            limit: 99999,
            filters,
          });

          return data;
        }}
        selectPage={() => companies}
      >
        <Box mt={9} mb={4} px={4}>
          <ExportModalProvider
            onExport={generateExport}
            rowCount={count ?? 0}
            filterExpression={filters ?? []}
          >
            <ListHeading {...headingProps} Actions={<Actions />} />
          </ExportModalProvider>
        </Box>
        <SimpleGrid m="0.625rem" gridGap={4} columns={setColValues(density)}>
          {companies.map((company) => (
            <CompanyCard
              key={company.ID}
              company={company}
              customColumns={customColumns}
              features={features}
            />
          ))}
        </SimpleGrid>

        <CardBulkActionMenu<CompanyApiData> actionGroups={bulkActions} />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
