import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getPriorities } from '../../api/Priority/getPriorities';
import { CasePriority } from '../../schemas';

export function usePriorities(): UseQueryResult<ListingResponse<CasePriority>> {
  return useQuery({
    queryKey: ['Sales.settings.priorities'],
    queryFn: () => getPriorities(),
  });
}
