import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp, FaChevronDown, FaUndo } from 'react-icons/fa';

import { Column, SortDirection, SortValue } from '@bq/components/Table/types';
import { useSortableColumns } from '@bq/components/use-sortable-columns';

interface Props<T> {
  sort: SortValue[];
  setSort: Dispatch<SetStateAction<SortValue[]>>;
  columns: Column<T>[];
  fontSize?: string;
  color?: string;
}
export const SortMenu = <T, >({
  sort,
  setSort,
  columns,
  fontSize = 'xs',
  color,
}: Props<T>) => {
  const { t } = useTranslation('Ticketing');
  const sortableColumns = useSortableColumns<T>(columns);
  const [order, setOrder] = useState<SortDirection>(
    sort[0]?.direction ?? 'desc'
  );
  const [column, setColumn] = useState<string | undefined>(
    sort[0]?.sortBy ?? undefined
  );
  const columnName = useMemo(() => {
    return sortableColumns.find((item) => item.sort === column)?.name;
  }, [column, sortableColumns]);

  const trimedColumn =
    columnName && columnName?.length > 20
      ? `${columnName?.substring(0, 20)}...`
      : columnName;

  useEffect(() => {
    if (column) {
      setSort([{ sortBy: column, direction: order }]);
    } else {
      setSort([{ sortBy: 'ID', direction: 'desc' }]);
    }
  }, [column, order, setSort]);

  return (
    <Menu preventOverflow closeOnSelect={false}>
      <MenuButton
        leftIcon={order === 'asc' ? <FaArrowUp /> : <FaArrowDown />}
        rightIcon={<FaChevronDown />}
        color={color}
        _active={{ bg: 'brand.100' }}
        as={Button}
        variant="ghost"
        fontSize={fontSize}
      >
        {trimedColumn}
      </MenuButton>

      <Portal>
        <MenuList>
          <MenuOptionGroup
            defaultValue="asc"
            title={t('global:sort.order')}
            type="radio"
            value={order}
            onChange={(value) => {
              if (
                typeof value === 'string' &&
                (value === 'asc' || value === 'desc')
              ) {
                setOrder(value);
              }
            }}
          >
            <MenuItemOption icon={<FaArrowUp />} value="asc">
              {t('global:sort.ascending')}
            </MenuItemOption>
            <MenuItemOption icon={<FaArrowDown />} value="desc">
              {t('global:sort.descending')}
            </MenuItemOption>
          </MenuOptionGroup>
          <MenuDivider />
          <MenuOptionGroup
            title={t('global:column')}
            type="radio"
            value={column}
            onChange={(value) => {
              if (typeof value === 'string') {
                setColumn(value);
              }
            }}
          >
            {sortableColumns.map((column) => (
              <MenuItemOption key={column.sort} value={column.sort}>
                {column.name}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
          <MenuItem
            icon={<FaUndo />}
            onClick={() => {
              setOrder('desc');
              setColumn(undefined);
              setSort([]);
            }}
          >
            {t('Ticketing:reset_defaults')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
