import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GroupSelect } from '../../TicketingForm/Group/GroupSelect';
import { TicketStateMultiSelect } from '../../TicketingForm/State/TicketStateMultiSelect';
import { DefaultTabSelect } from './DefaultTabSelect';
import { TicketTypesForm } from './types';

export const TypeFormFields = () => {
  const { register, control } = useFormContext<TicketTypesForm>();
  const { t } = useTranslation();
  const { field } = useController({ control, name: 'defaultTicketGroupID' });

  const { field: states } = useController<TicketTypesForm, 'states'>({
    control,
    name: 'states',
  });

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('global:name')}</FormLabel>
        <Input {...register('name')} size="sm"></Input>
      </FormControl>
      <SimpleGrid columns={[1, 1, 3, 3]} gridGap={8} w="full">
        <FormControl w="full">
          <FormLabel>{t('Ticketing:form.default_tab')}</FormLabel>
          <DefaultTabSelect />
        </FormControl>
        <FormControl w="full">
          <FormLabel>{t('Ticketing:form.default_group')}</FormLabel>
          <GroupSelect
            value={field.value ? field.value : null}
            onChange={(newValue) => {
              field.onChange(newValue?.value ?? null);
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{t('Ticketing:form.slug')}</FormLabel>
          <Input {...register('slug')} size="sm"></Input>
        </FormControl>
      </SimpleGrid>
      <FormControl>
        <FormLabel>{t('Ticketing:states')}</FormLabel>
        <TicketStateMultiSelect
          onChange={(newValue) => {
            states.onChange(
              newValue.map((item) => ({
                state: {
                  ID: item.value,
                },
              }))
            );
          }}
          value={states.value?.map((state) => state.state.ID) ?? null}
        />
      </FormControl>
      <FormControl>
        <Checkbox {...register('canHaveDependencies')} id="canHaveDependencies">
          {t('Ticketing:form.can_have_dependencies')}
        </Checkbox>
      </FormControl>
      <FormControl>
        <Checkbox {...register('canHaveWorks')} id="canHaveWorks">
          {t('Ticketing:form.can_have_works')}
        </Checkbox>
      </FormControl>
    </VStack>
  );
};
