import { useRef } from 'react';

export interface UseValueChangedOptions {
  /**
   * Defaults to true. We won't be watching for changes unless it's true
   *
   * Set it to false for example if waiting for some value to load and want to
   * ignore changes until then
   */
  enabled?: boolean;
  /**
   * Allows providing a custom comparison function, eg. for deep equality.
   *
   * If not specified,, values are compared using ===
   */
  valuesAreEqual?: (prev: unknown, current: unknown) => void;
}

/**
 * Returns true when a value has changed from it's initial value.
 */
export function useValueHasChanged(
  value: unknown,
  props: UseValueChangedOptions = {}
): boolean {
  const enabled = props.enabled ?? true;
  const prevValue = useRef<unknown>(value);
  const isInitialised = useRef(false);

  // Use caller-provided function if available, otherwise compare using ===
  const compare = (prev: unknown, current: unknown) => {
    if (props.valuesAreEqual) {
      return props.valuesAreEqual(prev, current);
    }

    return prev === current;
  };

  if (!enabled) {
    return false;
  }

  const didChange = isInitialised.current && !compare(prevValue.current, value);
  prevValue.current = value;
  if (enabled) {
    isInitialised.current = true;
  }

  return didChange;
}
