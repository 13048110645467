import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FooterButtons } from './FooterButtons';
import { TemplateFormFields } from './TemplateFormFields';
import { TemplateFormWrapper } from './TemplateFormWrapper';
import { TemplateFormData } from './types';

export const TemplateModal = (props: TemplateFormData) => {
  const { t } = useTranslation('Mails');

  return (
    <TemplateFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Mails:add_template')
          : t('Mails:edit_template')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <TemplateFormFields />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </TemplateFormWrapper>
  );
};
