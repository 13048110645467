module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<span class=\"text-danger\">\n			"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"Telephony:label.cant_call_without_extension",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":3},"end":{"line":8,"column":55}}}))
    + "\n		</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"text-center\">\n	<button class=\"btn btn-success btn-block clickvox-dial-btn\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasExtension") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":61},"end":{"line":2,"column":104}}})) != null ? stack1 : "")
    + ">\n		<span class=\"fas fa-phone\"></span>\n		"
    + container.escapeExpression(lookupProperty(helpers,"tr").call(alias1,"Telephony:button.call",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":32}}}))
    + "\n	</button>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasExtension") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":1},"end":{"line":10,"column":12}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true})