module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-phoneNumber_like_alnum="
    + alias2(lookupProperty(helpers,"urlencode").call(alias3,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":2,"column":155},"end":{"line":2,"column":180}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n		<span class=\"fa fa-filter\"></span>\n		&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Calls:button.show_calls_from_to_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":56}}}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-callerPhoneNumber_like_alnum="
    + alias2(lookupProperty(helpers,"urlencode").call(alias3,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":6,"column":161},"end":{"line":6,"column":186}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n		<span class=\"fa fa-filter\"></span>\n		&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Calls:button.show_calls_from_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":53}}}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"controller")), depth0))
    + "/"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"method")), depth0))
    + "/?"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-calleePhoneNumber_like_alnum="
    + alias2(lookupProperty(helpers,"urlencode").call(alias3,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":10,"column":161},"end":{"line":10,"column":186}}}))
    + "&"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"encode_url"),depth0,{"name":"encode_url","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "page=1\" class=\"w-100 text-left\">\n		<span class=\"fa fa-filter\"></span>\n		&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Calls:button.show_calls_to_number",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":12,"column":51}}}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/phonebook/saveNumberToPerson/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,{"name":"encode_query_string","hash":{"fullPhoneNumber":(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)},"data":data,"loc":{"start":{"line":14,"column":89},"end":{"line":14,"column":140}}}))
    + "\" style=\"width: 100%; text-align: left;\"\n		data-modal-link=\"/phonebook/saveNumberToPerson/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,{"name":"encode_query_string","hash":{"fullPhoneNumber":(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)},"data":data,"loc":{"start":{"line":15,"column":50},"end":{"line":15,"column":101}}}))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n		data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\" data-modal-reload=\"true\">\n		<span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Phonebook:button.save_number_to_person",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":17,"column":40},"end":{"line":17,"column":88}}}))
    + "\n	</a>\n	<a class=\"btn btn-secondary btn-block text-center\" href=\"/phonebook/saveNumberToCompany/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,{"name":"encode_query_string","hash":{"fullPhoneNumber":(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)},"data":data,"loc":{"start":{"line":19,"column":90},"end":{"line":19,"column":141}}}))
    + "\" style=\"width: 100%; text-align: left;\"\n		data-modal-link=\"/phonebook/saveNumberToCompany/?"
    + alias2(lookupProperty(helpers,"encode_query_string").call(alias3,{"name":"encode_query_string","hash":{"fullPhoneNumber":(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0)},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":102}}}))
    + "\" data-modal-link-module=\"Phonebook\" data-modal-heading=\".bq-content .card > .card-header\"\n		data-modal-body=\".bq-content .card > .card-body\" data-modal-footer=\".bq-content .card > .card-footer\" data-modal-reload=\"true\">\n		<span class=\"fa fa-save\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Phonebook:button.save_number_to_company",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":89}}}))
    + "\n	</a>\n	<br>\n	<button class=\"btn btn-success btn-block text-center clickvox-dial-button\" data-number=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0), depth0))
    + "\">\n		<span class=\"fa fa-phone\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias3,"Dialer:button.call",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":26,"column":63}}}))
    + " "
    + alias2(lookupProperty(helpers,"format_number").call(alias3,(depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":26,"column":64},"end":{"line":26,"column":93}}}))
    + "\n	</button>\n</div>";
},"usePartial":true,"useData":true})