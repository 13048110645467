import { ReactElement } from 'react';

import { FilterOperatorInput } from '../../FilterOperator';
import { FilterComboboxInput } from './ComboboxInput';
import { ComboboxFilterProps, ValBase } from './types';

export const ComboboxFilterInput = <
  ItemType extends ValBase,
  ValueType extends ValBase,
>(
    props: ComboboxFilterProps<ItemType, ValueType>
  ): ReactElement => {
  const { operator, operators, onOperatorChange } = props;
  const isNullOperator = operator === 'null' || operator === 'notnull';

  return (
    <>
      {operators.length > 0 && (
        <FilterOperatorInput
          operators={operators}
          value={operator ?? null}
          onChange={onOperatorChange}
        />
      )}
      {!isNullOperator && (
        <FilterComboboxInput<ItemType, ValueType>
          {...props}
          buttonStyle={{ variant: 'outline' }}
        />
      )}
    </>
  );
};
