import {
  Context,
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import {
  FieldArrayPath,
  FieldValues,
  UseFieldArrayReturn,
} from 'react-hook-form';

/**
 * Generated type-safe context, useContext and provider for useFieldArray.
 * Useful for passing the field array methods to list item components.
 *
 * @example
 * ```tsx
 * const {
 *   useFieldArrayContext,
 *   FieldArrayContextProvider
 * } = makeFieldArrayContext<FormType, 'items'>();
 *
 * // In ListCompontent.tsx
 * const fieldArrayMethods = useFieldArray({ control, name: 'items' });
 *
 * return (
 *   <FieldArrayContextProvider
 *     {...fieldArrayMethods}
 *   >
 *     {fields.map((field) => <ItemComponent key={field.id} field={field} />}
 *   </FieldArrayContextProvider>
 * );
 *
 * // In ItemCompontent.tsx
 * const { remove, update } = useFieldArrayContext();
 * ```
 */
export function makeFieldArrayContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends
    FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
>(): MakeFieldArrayContextResult<TFieldValues, TFieldArrayName, TKeyName> {
  type TContext = IFieldArrayContext<TFieldValues, TFieldArrayName, TKeyName>;
  const FieldArrayContext = createContext<TContext | null>(null);

  const useFieldArrayContext = (): TContext => {
    const context = useContext(FieldArrayContext);
    if (!context) {
      throw new Error('Missing FieldArrayContext');
    }

    return context as unknown as TContext;
  };

  const FieldArrayContextProvider = ({
    children,
    ...value
  }: PropsWithChildren<TContext>): ReactElement => {
    return (
      <FieldArrayContext.Provider value={value}>
        {children}
      </FieldArrayContext.Provider>
    );
  };

  return {
    FieldArrayContext,
    useFieldArrayContext,
    FieldArrayContextProvider,
  };
}

export type IFieldArrayContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends
    FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
  TKeyName extends string = 'id',
> = UseFieldArrayReturn<TFieldValues, TFieldArrayName, TKeyName>;

export interface MakeFieldArrayContextResult<
  TFieldValues extends FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues>,
  TKeyName extends string,
> {
  FieldArrayContext: Context<IFieldArrayContext<
    TFieldValues,
    TFieldArrayName,
    TKeyName
  > | null>;
  useFieldArrayContext: () => IFieldArrayContext<
    TFieldValues,
    TFieldArrayName,
    TKeyName
  >;
  FieldArrayContextProvider: (
    props: PropsWithChildren<
      IFieldArrayContext<TFieldValues, TFieldArrayName, TKeyName>
    >
  ) => ReactElement;
}
