import { ReactElement } from 'react';
import { CellProps } from 'BootQuery/Assets/components/Table';
import { ApiEmail } from './types';
import { EmailList } from './EmailList';

interface EmailCell {
  emails: ApiEmail[];
}

export const EmailsCell = <T extends EmailCell>({
  row,
}: CellProps<T>): ReactElement => {
  if (row.emails.length === 0) {
    return <>-</>;
  }

  return <EmailList emails={row.emails} />;
};
