import { useFormContext, useWatch } from 'react-hook-form';

import { roundTo } from 'app/assets/js/tsutil';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { Product } from '../types';

/**
 * Automatically updates some item row properties on change:
 * - Reset quantity, value and weights on product change
 * - Calculate total value and weight on quantity change
 *
 * @param fieldName - Root form field name/path. Eg. `something.products.3`
 */
export function useAutoItemValues(fieldName: string): void {
  useResetFieldsOnProductChange(fieldName);
  useUpdateValueOnQuantityChange(fieldName);
}

function useResetFieldsOnProductChange(fieldName: string): void {
  const { setValue } = useFormContext();
  const product: Product | null = useWatch({
    name: `${fieldName}.product`,
  });

  useChangeEffect(
    product?.ID,
    () => {
      if (!product) {
        console.warn('Product cleared');

        return;
      }

      setValue(`${fieldName}.quantity`, '1');
      if (product.price?.retailPrice) {
        setValue(`${fieldName}.value`, product.price.retailPrice.toFixed(2));
      } else {
        setValue(`${fieldName}.value`, '0.00');
      }

      if (product.productType === 'good') {
        const { good } = product;
        setValue(
          `${fieldName}.netWeightKg`,
          good.netWeightKg?.toString() ?? ''
        );
        setValue(
          `${fieldName}.grossWeightKg`,
          good.grossWeightKg?.toString() ?? ''
        );
      } else {
        setValue(`${fieldName}.netWeightKg`, '');
        setValue(`${fieldName}.grossWeightKg`, '');
      }
    },
    [setValue, fieldName]
  );
}

function useUpdateValueOnQuantityChange(fieldName: string): void {
  const { setValue, getValues } = useFormContext();
  const quantity: string = useWatch({ name: `${fieldName}.quantity` });

  useResetFieldsOnProductChange(fieldName);

  useChangeEffect(
    quantity,
    () => {
      const product: Product | null = getValues(`${fieldName}.product`);
      if (!product) {
        return;
      }

      const quantityNum = parseFloat(quantity);
      if (!quantityNum || Number.isNaN(quantityNum)) {
        console.warn('Invalid/non-numeric quantity:', quantity, quantityNum);

        return;
      }

      if (product.price?.retailPrice) {
        const totalPrice = product.price.retailPrice * quantityNum;
        setValue(`${fieldName}.value`, totalPrice.toFixed(2));
      }

      if (product.productType === 'good') {
        const {
          good: { netWeightKg, grossWeightKg },
        } = product;

        // Use roundTo to cut off ugly floating point values like
        // 3.3000000000000003

        if (netWeightKg) {
          const totalNetWeight = roundTo(netWeightKg * quantityNum, 12);
          setValue(`${fieldName}.netWeightKg`, totalNetWeight.toString());
        }

        if (grossWeightKg) {
          const totalGrossWeight = roundTo(grossWeightKg * quantityNum, 12);
          setValue(`${fieldName}.grossWeightKg`, totalGrossWeight.toString());
        }
      }
    },
    [setValue, fieldName, getValues]
  );
}
