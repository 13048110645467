import { z } from 'zod';

import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { CreateWorkRequest } from '../TicketWorks/types';
import { TicketWork } from '../types';

const zNullableDate = z.union([z.null(), z.coerce.date()]);

export const ticketWorkSchema = z.object({
  ID: z.number().int(),
  title: z.string().nullable(),
  createdAt: z.coerce.date(),
  workAt: z.coerce.date(),
  data: z.record(z.any()),
  durationSecs: z.number(),
  inProgress: z.boolean(),
  pendingTimeSince: zNullableDate,
  lastWorkAt: z.coerce.date(),
  ticketID: z.number(),
  createdByUser: z
    .object({
      ID: z.number(),
      username: z.string(),
      person: z
        .object({
          fullName: z.string(),
        })
        .nullable(),
    })
    .nullable(),
});

const ticketWorkListSchema = z.array(ticketWorkSchema);

export async function getTicketWorks(ticketID: number): Promise<TicketWork[]> {
  const { data } = await Api.get<ListingResponse<unknown>>(
    `/api/ticketing/tickets/${ticketID}/works`,
    {
      params: {
        limit: 'null',
      },
    }
  );

  return ticketWorkListSchema.parse(data.data);
}

export async function createTicketWork(
  ticketID: number,
  work: CreateWorkRequest
): Promise<TicketWork> {
  const { data } = await Api.post<TicketWork>(
    `/api/ticketing/tickets/${ticketID}/works`,
    work,
    { params: { fields: ['$default'] } }
  );

  return ticketWorkSchema.parse(data);
}

export async function updateTicketWork(
  ticketID: number,
  workID: number,
  changes: Partial<CreateWorkRequest>
): Promise<TicketWork> {
  const { data } = await Api.patch(
    `/api/ticketing/tickets/${ticketID}/works/${workID}`,
    changes,
    { params: { fields: ['$default'] } }
  );

  return ticketWorkSchema.parse(data);
}

export function startTicketWork(
  ticketID: number,
  workID: number
): Promise<TicketWork> {
  return updateTicketWork(ticketID, workID, { inProgress: true });
}

export function stopTicketWork(
  ticketID: number,
  workID: number
): Promise<TicketWork> {
  return updateTicketWork(ticketID, workID, { inProgress: false });
}

export async function deleteTicketWork(
  ticketID: number,
  workID: number
): Promise<void> {
  await Api.delete(`/api/ticketing/tickets/${ticketID}/works/${workID}`);
}
