import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { ErrorFallback } from './ErrorFallback';

/**
 * A route-wrapping error boundary that allows navigating away from the page
 * after an error. This is meant to be used as a layout route and contains
 * an `<Outlet />`.
 */
export const RouteErrorWrapper = (): ReactElement => (
  // ErrorFallback will reset the error boundary when location changes,
  // so we can navigate away from this page
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Outlet />
  </ErrorBoundary>
);
