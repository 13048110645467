import { memo, ReactElement } from 'react';
import { Divider, Flex, Text } from '@chakra-ui/react';

import { CardBody } from 'BootQuery/Assets/components/Card';
import {
  FieldValue,
  useCustomFormColumns,
} from 'BootQuery/Assets/components/FormEditor';
import { CellValue } from 'BootQuery/Assets/components/Table';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

interface Props {
  formDefinition: FieldValue[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
}

function CustomEventContentInner({
  formDefinition,
  data,
}: Props): ReactElement {
  const columns = useCustomFormColumns(formDefinition);
  if (!columns.length) {
    return <></>;
  }

  return (
    <CardBody>
      <WithSeparator separator={<Divider />}>
        {columns.map((column) => (
          <Flex key={column.key} w="full" justifyContent="start">
            {column.title && <Text as="span">{column.title}:&nbsp;</Text>}
            <Text
              as="strong"
              fontWeight="bold"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              <CellValue row={{ data }} rowIdx={1} column={column} />
            </Text>
          </Flex>
        ))}
      </WithSeparator>
    </CardBody>
  );
}

export const CustomEventContent = memo(
  CustomEventContentInner
) as typeof CustomEventContentInner;
