import { ReactElement } from 'react';
import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Link,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import { FieldError, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPhone } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { numberToE164, phoneNumberValid } from 'app/assets/js/number-util';

import { BaseSettings, FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';

export const PhoneField = ({
  id,
  settings,
}: FieldValue<BaseSettings>): ReactElement => {
  const { t } = useTranslation('global');

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const ourError = get(errors, id) as FieldError | undefined;
  const hasError = !!ourError;

  const value = useWatch({ control, name: id });
  const link = !hasError && value ? `tel:${numberToE164(value)}` : undefined;

  return (
    <FormControl isInvalid={hasError}>
      <FieldLabel {...settings} />
      <InputGroup>
        <Input
          type="tel"
          {...register(id, {
            required: settings.required,
            validate: (val) => {
              if (!val) {
                return true;
              }

              return phoneNumberValid(val);
            },
          })}
        />
        <InputRightElement>
          <IconButton
            as={Link}
            href={link}
            isDisabled={hasError || !value}
            label={t('global:form_editor.dial_number')}
            icon={<FaPhone />}
          />
        </InputRightElement>
      </InputGroup>
      {ourError && (
        <FormErrorMessage>{ourError.message || ourError.type}</FormErrorMessage>
      )}
    </FormControl>
  );
};
