import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { CustomEventTypeSettings } from './types';
import {
  EventTypeSettingsMethods,
  useEventTypeSettingsMethods,
} from './use-event-type-settings';

export type IEventTypeSettingsContext = EventTypeSettingsMethods & {
  eventTypes: CustomEventTypeSettings[];
};

export const EventTypeSettingsContext =
  createContext<IEventTypeSettingsContext | null>(null);

export const useEventTypeSettings = (): IEventTypeSettingsContext => {
  const context = useContext(EventTypeSettingsContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
};

interface ProviderValueProps {
  initial: CustomEventTypeSettings[];
}

type ProviderProps = PropsWithChildren<ProviderValueProps>;

export const EventTypeSettingsProvider = ({
  children,
  initial,
}: ProviderProps): ReactElement => {
  const [eventTypes, methods] = useEventTypeSettingsMethods(initial);

  return (
    <EventTypeSettingsContext.Provider value={{ eventTypes, ...methods }}>
      {children}
    </EventTypeSettingsContext.Provider>
  );
};
