module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<nav class=\"navbar navbar-expand sticky-top main-top-nav justify-content-between py-0\">\n	<button class=\"navbar-toggler menu-toggle-btn mobile-menu-toggle-btn\" type=\"button\">\n		<span class=\"navbar-toggler-icon\"></span>\n	</button>\n	<a class=\"navbar-brand my-2\" href=\"/\">\n		<img style=\"height: 100%;\" src=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"darkLogoPath")), depth0))
    + "\" />\n	</a>\n\n"
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"header-middle",{"name":"block","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "\n	<ul class=\"navbar-nav my-2\">\n"
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"header-right",{"name":"block","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":49,"column":12}}})) != null ? stack1 : "")
    + "	</ul>\n</nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "	<div class=\"header-middle-container my-2\"></div>\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"ID")),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":16,"column":3},"end":{"line":48,"column":10}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"modules"))) && lookupProperty(stack1,"Menu"))) && lookupProperty(stack1,"menus"))) && lookupProperty(stack1,"header")),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":40,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"template") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":18,"column":5},"end":{"line":39,"column":12}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						"
    + ((stack1 = container.invokePartial(lookupProperty(helpers,"concat").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"key")),".",(depth0 != null ? lookupProperty(depth0,"template") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":19,"column":9},"end":{"line":19,"column":35}}}),depth0,{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "						<li class=\"nav-item dropdown\">\n							<a class=\"nav-link dropdown-toggle\" data-toggle=\"dropdown\" href=\"#\">\n								<span class=\"fa fa-user\"></span>\n								<span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "</span>\n							</a>\n\n							<div class=\"dropdown-menu dropdown-menu-right\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":7},"end":{"line":36,"column":16}}})) != null ? stack1 : "")
    + "							</div>\n						</li>\n";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "								<a class=\"dropdown-item\" href=\"/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"controller") : stack1), depth0))
    + "/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"entry") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":30,"column":9},"end":{"line":34,"column":16}}})) != null ? stack1 : "")
    + "								</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<span class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span> "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "										<span class=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"icon") : depth0), depth0))
    + "\"></span> "
    + alias1(lookupProperty(helpers,"tr").call(alias2,lookupProperty(helpers,"concat").call(alias2,(depths[1] != null ? lookupProperty(depths[1],"module") : depths[1]),":menu_entries.",(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":33,"column":46},"end":{"line":33,"column":86}}}),{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":33,"column":41},"end":{"line":33,"column":88}}}))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "				<li class=\"nav-item\">\n					<a href=\"/user/login\" class=\"nav-link\">\n						<span class=\"fas fa-sign-in-alt\"></span>\n						Prijava\n					</a>\n				</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"report")),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":52,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})