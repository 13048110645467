module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n				    	<!-- "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"user") : depth0), depth0),{"name":"user","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":82,"column":23}}})) != null ? stack1 : "")
    + " -->\n					    <!-- ";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n\n							<!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"is_hidden") : depth0), depth0),{"name":"is_hidden","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":74,"column":32}}})) != null ? stack1 : "")
    + " -->\n\n					        <!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"is_hidden") : depth0), depth0),{"name":"is_hidden","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":18},"end":{"line":80,"column":32}}})) != null ? stack1 : "")
    + " -->\n\n					    <!-- ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n					        <div class=\"form-group\">\n\n					            <label class=\"col-sm-2 control-label\" for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + ":</label>\n\n					            <div class=\"col-sm-10\">\n\n					                <!-- "
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"is_dropdown") : depth0), depth0),{"name":"is_dropdown","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":26},"end":{"line":44,"column":42}}})) != null ? stack1 : "")
    + " -->\n\n					                <!-- "
    + ((stack1 = alias3.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"is_dropdown") : depth0), depth0),{"name":"is_dropdown","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":46,"column":26},"end":{"line":68,"column":42}}})) != null ? stack1 : "")
    + " -->\n\n					            </div>\n\n					        </div>\n\n					        <!-- ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n\n					                "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"select"),depth0,{"name":"select","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n					                <!-- ";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.hooks.blockHelperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n\n					                <!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"has_addon") : depth0), depth0),{"name":"has_addon","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":26},"end":{"line":52,"column":40}}})) != null ? stack1 : "")
    + " -->\n\n					                    <input type=\""
    + alias3(alias1((depth0 != null ? lookupProperty(depth0,"input_type") : depth0), depth0))
    + "\" class=\"form-control "
    + alias3(alias1((depth0 != null ? lookupProperty(depth0,"input_class") : depth0), depth0))
    + "\" name=\""
    + alias3(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" id=\""
    + alias3(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" title=\""
    + alias3(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" maxlength=\"11\" value=\""
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0),{"name":"value","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":158},"end":{"line":54,"column":187}}})) != null ? stack1 : "")
    + "\">\n\n					                    <!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"has_addon") : depth0), depth0),{"name":"has_addon","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":30},"end":{"line":62,"column":44}}})) != null ? stack1 : "")
    + " -->\n\n					                <!-- "
    + ((stack1 = alias2.call(depth0,alias1((depth0 != null ? lookupProperty(depth0,"has_addon") : depth0), depth0),{"name":"has_addon","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":26},"end":{"line":66,"column":40}}})) != null ? stack1 : "")
    + " -->\n\n					                <!-- ";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n\n					                <div class=\"input-group "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_group_class") : depth0), depth0))
    + "\">\n\n					                <!-- ";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n					                    <span class=\"input-group-addon\">\n\n					                    	<span class=\"glyphicon "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"addon_icon") : depth0), depth0))
    + "\"></span>\n\n					                    </span>\n					                    <!-- ";
},"13":function(container,depth0,helpers,partials,data) {
    return " -->\n					                </div>\n					                <!-- ";
},"15":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " -->\n\n					        <input type=\"hidden\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\">\n\n					        <!-- ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"head"),depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div id=\"content-wrapper\">\n\n	<div class=\"container\" id=\"main-container\">\n\n	    "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"header"),depth0,{"name":"header","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	    <div class=\"row\">\n\n	        "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"menu"),depth0,{"name":"menu","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n	        <div class=\"col-md-12 col-lg-12 bq-content bq-default\">\n\n	            <form id=\"user-modify-form\" class=\"form-horizontal\" action=\"/user/userlist\" method=\"post\" role=\"form\">\n\n	            <div class=\"panel panel-default\">\n\n				    <div class=\"panel-heading\">\n\n				    	<span class=\"glyphicon glyphicon-edit\"></span>&nbsp;Uredi korisnika\n\n				    </div>\n\n		    	    <div class=\"panel-body\">\n\n					    <div class=\"col-md-10 col-md-offset-1 alert alert-success\" style=\"display: none;\">Changes saved</div>\n				    	<div class=\"col-md-10 col-md-offset-1 alert alert-danger\" style=\"display: none;\">Error saving data</div>\n\n						<!-- "
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"form") : depth0), depth0),{"name":"form","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":11},"end":{"line":83,"column":23}}})) != null ? stack1 : "")
    + " -->\n\n					    </div>\n\n					    <div class=\"panel-footer\">\n\n					    	<a href=\"/user/userlist/\" class=\"btn btn-info\"><span class=\"glyphicon glyphicon-chevron-left\"></span> Natrag</a>\n\n					        <!-- <div class=\"pull-right\"> -->\n\n					        	<button type=\"submit\" class=\"btn btn-success pull-right\"><span class=\"far fa-save\"></span> Spremi</button>\n\n					    	<!-- </div> -->\n\n					    	<!-- <div class=\"clearfix\"></div> -->\n\n					    </div>\n\n		        </div>\n\n		        </form>\n\n	        </div>\n\n	    </div>\n\n	</div>\n\n</div>\n\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"end"),depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})