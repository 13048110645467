import { uniq } from 'lodash-es';

import { numberToE164 } from 'app/assets/js/tsutil';

import { FilterExpression } from '../types';

export function numberFilterExpression(
  value: string,
  operator: string,
  field = 'phoneNumber.phoneNumberE164'
): FilterExpression | null {
  if (!value || !operator) {
    return null;
  }

  operator = operator.replace(':ci', '');

  value = value.replaceAll(/(\s|[/-])+/g, '');
  const possibleValues = uniq([value, numberToE164(value)]);

  const conditions = possibleValues.reduce<FilterExpression[]>(
    (conds, val) => [...conds, [field, operator, val]],
    []
  );
  const groupType = isNegativeOperator(operator) ? '$and' : '$or';

  return { [groupType]: conditions };
}

function isNegativeOperator(operator: string): boolean {
  const opParts = operator.split(':');

  return opParts.includes('not') || opParts.includes('neq');
}
