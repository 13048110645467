import { ReactElement } from 'react';
import { Checkbox, FormControl } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';

export const CheckboxField = ({ id, settings }: FieldValue): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const ourError = errors[id]?.type;

  return (
    <FormControl isInvalid={!!ourError}>
      <FieldLabel {...settings} />
      <Checkbox {...register(id, { required: settings.required })} />
    </FormControl>
  );
};
