import { ReactElement } from 'react';
import { CallDisplay } from './CallDisplay';
import { useCurrentCall } from './hooks';
import { StateDisplay } from './StateDisplay';

export const CurrentCallDisplay = (): ReactElement => {
  const callInfo = useCurrentCall();

  return callInfo ? <CallDisplay {...callInfo} /> : <StateDisplay />;
};
