import { ReactElement } from 'react';
import { FieldType, FieldValue } from './types';
import { DefaultFieldComponent } from './field-types/DefaultFieldComponent';
import { findType } from './util';

interface GeneratedFieldProps {
    fieldTypes: FieldType[];
    field: FieldValue;
    prefix?: string | null;
}

export const GeneratedFormField = ({
  field,
  fieldTypes,
  prefix = 'data',
}: GeneratedFieldProps): ReactElement => {
  const fieldType = findType(fieldTypes, field.type);
  const Component = fieldType.components?.FormField ?? DefaultFieldComponent;

  const id = prefix ? `${prefix}.${field.id}` : field.id;

  return <Component {...field} id={id} />;
};
