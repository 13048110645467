import { ReactElement, useEffect, useRef } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { PageWrapper } from './PageWrapper';

export const ErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): ReactElement => {
  // Reset error boundary when location changes
  // otherwise we would get stuck with error
  const location = useLocation();
  const errorLocation = useRef(location.key);
  useEffect(() => {
    if (location.key !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.key, resetErrorBoundary]);

  return (
    <PageWrapper>
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>
          {'Error: '} {error.name}
        </AlertTitle>
        <AlertDescription>{error.message}</AlertDescription>
      </Alert>
    </PageWrapper>
  );
};
