import { CloneHandler, defaultCloneOriginal } from '../DndContainers';
import { BaseSettings, EditorItem, EditorValue } from './types';
import { newFieldId } from './util';

export const cloneHandler: CloneHandler<EditorValue> = {
  cloneOriginal: defaultCloneOriginal,
  cloneTarget: (item: EditorItem): EditorItem => {
    if (item.content.itemType !== 'fieldType') {
      return { ...item, mode: 'move' };
    }

    const maybeChildren = item.content.hasChildren
      ? { hasChildren: true, children: [] }
      : {};

    return {
      id: item.id,
      mode: 'move',
      content: {
        type: item.content.type,
        id: newFieldId(),
        itemType: 'fieldValue',
        settings: getDefaultSettings(item.content.defaultSettings),
      },
      ...maybeChildren,
    };
  },
};

function getDefaultSettings<T extends BaseSettings>(
  defaultSettings: T | (() => T)
): T {
  if (typeof defaultSettings === 'function') {
    return defaultSettings();
  }

  return defaultSettings;
}
