import { FormActions } from '@bq/components/FormActions';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';

import { usePersonFormWrapper } from './PersonFormWrapper';

export const PersonModalFooter = () => {
  const { formState } = usePersonFormWrapper();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <FormActions
      state={formState}
      onCancel={() => {
        closeWithNoCallback();
      }}
    />
  );
};
