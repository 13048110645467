import { slugifyCamelCase } from 'app/assets/js/slugify-camel-case';
import { uniqid } from 'app/assets/js/tsutil';

import {
  ImportColumnConfig,
  ImportConfig,
  ImportSetupForm,
  PreviewResult,
  PreviewRow,
} from '../types';

export interface PreviewData {
  header: PreviewRow | null;
  rows: PreviewRow[];
}

/**
 * Split row into header and rows, depending on `firstRowIsHeader`.
 * We always try to return 10 rows, which is possible even with header because
 * we purposely return more rows than shown from backend.
 */
export function splitAndTruncatePreviewRows(
  preview: PreviewRow[] | undefined,
  firstRowIsHeader: boolean
): PreviewData {
  if (!preview || preview.length === 0) {
    return {
      header: null,
      rows: [],
    };
  }

  if (firstRowIsHeader) {
    return {
      header: preview[0],
      rows: preview.slice(1, 11),
    };
  }

  return {
    header: null,
    rows: preview.slice(0, 10),
  };
}

export function genEmptyColumns(columnCount: number): ImportColumnConfig[] {
  return Array(columnCount)
    .fill(undefined)
    .map(() => ({
      key: uniqid(),
      title: '',
      type: 'text',
      options: {},
    }));
}

export function cellToText(cell: PreviewResult): string {
  if (cell.status === 'ok') {
    if (typeof cell.value === 'string') {
      return cell.value.trim();
    }
    if (typeof cell.rawValue === 'string') {
      return cell.rawValue.trim();
    }
  }

  // We can just ignore parsing errors in headers, we treat them as just text
  if (cell.status === 'error' && typeof cell.rawValue === 'string') {
    return cell.rawValue.trim();
  }

  return '';
}

export function genColumnsFromHeader(header: PreviewRow): ImportColumnConfig[] {
  return header.map((cell) => ({
    key: uniqid(),
    title: cellToText(cell),
    type: 'text',
    options: {},
  }));
}

export function getColumnCount(previewRows: PreviewRow[] | undefined): number {
  if (!previewRows) {
    return 0;
  }

  if (previewRows.length === 0) {
    console.warn('Preview rows loaded but empty');

    return 0;
  }

  // Return column count of first row
  return previewRows[0].length;
}

export function formToConfig(
  uploadId: string,
  form: ImportSetupForm
): ImportConfig {
  return {
    uploadId,
    name: form.name,
    columns: form.columns.map((column) => ({
      ...column,
      key: slugifyCamelCase(column.title),
    })),
    options: {
      skipHeader: form.firstRowIsHeader,
      ...(form.csvOptions ?? {}),
    },
  };
}
