import { ReactElement } from 'react';
import { VStack } from '@chakra-ui/react';

import { EditOverviews } from './EditOverviews';

export const Settings = (): ReactElement => {
  return (
    <VStack alignItems="start" px={4}>
      <EditOverviews />
    </VStack>
  );
};
