import { EventEmitter } from 'events';
import { v4 as uuid4 } from 'uuid';
import {
  get, setWith, isString, isObject, isArray, cloneDeep, reduce, some,
} from 'lodash-es';
import { SOCKET_STATE } from './socket';

export declare interface EventSubscription {
    [key: string]: EventSubscription | true;
}

export declare type RawSubscription = string | string[] | EventSubscription;

export declare type eventHandlerCb<EVT = unknown> = (data: EVT, event: EventSubscription) => void;

declare interface SocketEvent {
    type: string;
    data: {
        for: string[],
        fullPath: EventSubscription,
        data: unknown,
    };
}

export declare interface EventHandler<EVT = unknown> {
    event: EventSubscription,
    callback: eventHandlerCb<EVT>,
    handle: string,
}

function parsePath(path: string): string[] {
  return path.split('/').filter((segment) => segment.length);
}

function hasSubscription(subscriptions: EventSubscription, event: EventSubscription): boolean {
  return some(event, (val, key) => {
    if (subscriptions[key] === true) {
      return true;
    }

    const sub = subscriptions[key];
    if (isObject(sub) && isObject(val)) {
      return hasSubscription(sub, val);
    }

    return false;
  });
}

function normalizeSubscription(subscription: RawSubscription): EventSubscription {
  if (isString(subscription)) {
    return setWith({}, parsePath(subscription), true, Object);
  }

  if (isArray(subscription)) {
    return subscription.reduce((events, event) => {
      const eventPath = parsePath(event);

      const currentPath = [];
      for (let i = 0; i < eventPath.length; i++) {
        currentPath.push(eventPath[i]);
        if (get(events, currentPath) === true) {
          return events;
        }
      }
      setWith(events, eventPath, true, Object);

      return events;
    }, {});
  }

  if (isObject(subscription)) {
    return subscription;
  }

  throw new Error('Expected string, array or object for subscription');
}

function getRemovableSubscriptionParts(
  subscription: EventSubscription,
  othersArr: EventSubscription[]
) {
  return reduce(
    subscription,
    (unsubs: EventSubscription, val, key) => {
      const others = othersArr
        .map((other) => other[key])
        .filter((other) => other !== undefined);

      if (others.length) {
        if (isObject(val)) {
          const subparts = getRemovableSubscriptionParts(val, others as EventSubscription[]);
          if (Object.keys(subparts).length) {
            unsubs[key] = getRemovableSubscriptionParts(val, others as EventSubscription[]);
          }
        }
      } else {
        unsubs[key] = val;
      }

      return unsubs;
    },
    {},
  );
}

export default class SocketEventListener extends EventEmitter {
  name: string | null;

  didInit: boolean;

  websocketSubscriptions: EventHandler[];

  socketToken: string;

  private onMessage: (data: string) => void;

  constructor(name: string | null = null) {
    super();
    this.name = name;
    this.websocketSubscriptions = [];
    this.socketToken = this.generateWebSocketToken();
    this.onMessage = this.messageHandler.bind(this);

    this.didInit = true;
    if (window.socket) {
      if (window.socket.readyState === SOCKET_STATE.OPEN) {
        this.onSocketConnect();
      }
      window.socket.on('connect', () => this.onSocketConnect());
    }
  }

  generateWebSocketToken(): string {
    const uuid = uuid4();

    return this.name ? `${this.name}:${uuid}` : uuid;
  }

  subscribeWebSocket<EVT>(event: RawSubscription, callback: eventHandlerCb<EVT>): string {
    const handle = uuid4();
    const subscription: EventHandler<EVT> = {
      event: normalizeSubscription(event),
      callback,
      handle,
    };

    this.websocketSubscriptions.push(subscription as EventHandler<unknown>);
    if (window.socket && window.socket.readyState === SOCKET_STATE.OPEN) {
      this.doSubscribe(subscription as EventHandler<unknown>);
    }

    return handle;
  }

  unsubscribeWebSocket(handle: string): void {
    if (!this.didInit) {
      throw new Error(`Init in parent not called for event emitter ${this.socketToken}`);
    }
    this.websocketSubscriptions.forEach((subscription, index, subscriptions) => {
      if (subscription.handle === handle) {
        subscriptions.splice(index, 1);
        this.doUnsubscribe(subscription);
      }
    });
  }

  unsubscribeWebSocketAll(event: RawSubscription | null = null): void {
    let toUnsub;
    if (event === null) {
      toUnsub = this.websocketSubscriptions;
    } else {
      const normalEvent = normalizeSubscription(event);
      toUnsub = this.websocketSubscriptions.filter(
        (sub) => hasSubscription(normalEvent, sub.event),
      );
    }

    toUnsub.slice(0).forEach((sub) => {
      this.unsubscribeWebSocket(sub.handle);
    });
  }

  onSocketConnect(): void {
    window.socket?.off('message', this.onMessage);
    window.socket?.on('message', this.onMessage);

    this.websocketSubscriptions.forEach((subscription) => {
      this.doSubscribe(subscription);
    });
  }

  messageHandler(msg: string): void {
    const parsed: SocketEvent = JSON.parse(msg);
    // For me?
    if (parsed.data.for.includes(this.socketToken)) {
      this.onEvent(parsed.data.fullPath, parsed.data.data);
    }
  }

  onEvent(event: EventSubscription, data: unknown): void {
    this.websocketSubscriptions.forEach((subscription) => {
      if (hasSubscription(subscription.event, event)) {
        subscription.callback(cloneDeep(data), event);
      }
    });
  }

  doUnsubscribe(subscription: EventHandler): void {
    const otherSubscriptionEvents = this.websocketSubscriptions.map((sub) => sub.event);
    const toUnsubscribe = getRemovableSubscriptionParts(
      subscription.event,
      otherSubscriptionEvents,
    );
    if (Object.keys(toUnsubscribe).length) {
      if (window.socket && window.socket.readyState === SOCKET_STATE.OPEN) {
        window.socket?.send('unsubscribe', {
          events: toUnsubscribe,
          token: this.socketToken,
        });
      }
    }
  }

  doSubscribe(subscription: EventHandler): void {
    window.socket?.send('subscribe', {
      events: subscription.event,
      token: this.socketToken,
    });
  }
}

export function makeListener(name: string | null = null): (() => SocketEventListener) {
  let listener: SocketEventListener|null = null;

  return () => {
    if (!listener) {
      listener = new SocketEventListener(name);
    }

    return listener;
  };
}
