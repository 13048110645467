import { EventEmitter } from 'events';

export enum SOCKET_STATE {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3
}

export interface ReconnectIntervalOptions {
    initial: number;
    max: number;
}

interface ReconnectInterval extends ReconnectIntervalOptions {
    current: number;
}

export interface Options {
    reconnectInterval?: ReconnectIntervalOptions;
    protocols?: string | string[];
    debug?: boolean;
}

export declare interface ReconnectingWebsocket {
    on(
        event: string,
        listener: (message: string | Blob | ArrayBuffer) => void
    ): this;
    on(
        event: 'connectionStateChange',
        listener: (state: SOCKET_STATE) => void
    ): this;
}

export default class Socket extends EventEmitter {
  url: string;

  socket: WebSocket | null;

  lastState: SOCKET_STATE;

  waitingToReconnect: boolean;

  reconnectInterval: ReconnectInterval;

  public debug: boolean;

  protocols?: string | string[];

  shouldBeConnected: boolean;

  constructor(url: string, options: Options = {}) {
    super();

    this.url = url;
    this.socket = null;
    this.lastState = SOCKET_STATE.CLOSED;
    this.waitingToReconnect = false;
    this.debug = options.debug ?? false;
    this.protocols = options.protocols;
    this.shouldBeConnected = false;

    const initialReconnectInterval = options.reconnectInterval?.initial ?? 1 * 1000;
    const maxReconnectInterval = options.reconnectInterval?.max ?? 30 * 1000;
    this.reconnectInterval = {
      current: initialReconnectInterval,
      initial: initialReconnectInterval,
      max: maxReconnectInterval,
    };
  }

  public send(type: string, data: unknown): void {
    if (this.debug) {
      console.debug('SOCKET -->', type, data);
    }
    this.socket?.send(JSON.stringify({ type, data }));
  }

  public connect(): void {
    this.shouldBeConnected = true;
    console.log('Socket connecting');
    this.socket?.close();
    this.socket = new WebSocket(this.url, this.protocols);
    this.setState(SOCKET_STATE.CONNECTING);
    this.socket.onopen = () => {
      // console.log('Socket connected');
      this.setState(SOCKET_STATE.OPEN);
      this.reconnectInterval.current = this.reconnectInterval.initial;
      this.emit('connect');
    };

    this.socket.onclose = (e) => {
      console.warn('Socket close: ', e);
      this.setState(SOCKET_STATE.CLOSED);
      if (this.shouldBeConnected) {
        this.reconnect();
      }
    };

    this.socket.onerror = (error) => {
      console.error('Socket Error: ', error);
    };

    this.socket.onmessage = (msgEvent) => {
      if (this.debug) {
        console.debug('SOCKET <--', msgEvent.type, msgEvent.data);
      }
      this.emit('message', msgEvent.data);
    };
  }

  public disconnect(): void {
    this.shouldBeConnected = false;
    this.socket?.close();
    this.socket = null;
  }

  get readyState(): SOCKET_STATE {
    return this.socket?.readyState || SOCKET_STATE.CLOSED;
  }

  private setState(state: SOCKET_STATE) {
    this.lastState = state;
    this.emit('connectionStateChange', state);
  }

  private reconnect() {
    if (this.waitingToReconnect) {
      return;
    }
    console.log(`Reconnect in ${this.reconnectInterval.current}ms`);
    setTimeout(() => {
      if (!this.shouldBeConnected) {
        return;
      }
      this.reconnectInterval.current = Math.min(
        this.reconnectInterval.current * 2,
        this.reconnectInterval.max,
      );
      this.waitingToReconnect = false;
      this.connect();
    }, this.reconnectInterval.current);
  }
}
