import { ReactElement, useCallback, useMemo } from 'react';
import {
  FieldValues,
  Path,
  useController,
  useFormContext,
} from 'react-hook-form';

import { Select, SelectOption } from '@bq/components/SelectWrappers';

import { CaseTypesFormBase } from '../CaseTypes/types';
import { caseTypeLoadOptions } from './utils';

interface Props<T extends FieldValues> {
  caseTypes: CaseTypesFormBase[];
  isDisabled?: boolean;
  name: Path<T>;
}

export const CaseTypeField = <T extends FieldValues>({
  caseTypes,
  isDisabled,
  name,
}: Props<T>): ReactElement => {
  const { control } = useFormContext<T>();
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const valueOption = useMemo((): SelectOption | null => {
    const type = caseTypes.find((type) => type.ID === value);
    if (!type) {
      return null;
    }

    return { value: type.ID, label: type.name };
  }, [caseTypes, value]);

  const handleChange = useCallback(
    (value: SelectOption | null) => {
      if (value) {
        onChange(value.value);
      }
    },
    [onChange]
  );

  return (
    <Select
      isDisabled={isDisabled}
      value={valueOption}
      onChange={handleChange}
      isClearable
      options={caseTypeLoadOptions}
    />
  );
};
