import { FilterTypes } from '@bq/components/FilterBar';
import { IOverviewEditorItem, loadOverviews } from '@bq/components/Overviews';
import { Jsonish } from '@bq/components/type-util';
import { Api } from 'BootQuery/Assets/js/api';

export async function getOverviewSettings(
  filters: FilterTypes
): Promise<IOverviewEditorItem[]> {
  const { data } = await Api.get<Jsonish>(
    '/api/ticketing/overviewSettings',
    {}
  );

  return loadOverviews(data, filters);
}
