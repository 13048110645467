import { createContext, PropsWithChildren, useContext } from 'react';

import { TicketMutateProps } from '../Layout/types';

const TicketMutateContext = createContext<TicketMutateProps | null>(null);

export const TicketMutationProvider = ({
  children,
  ...rest
}: TicketMutateProps & PropsWithChildren) => {
  return (
    <TicketMutateContext.Provider
      value={{
        ...rest,
      }}
    >
      {children}
    </TicketMutateContext.Provider>
  );
};

export const useTicketMutationContext = () => {
  const usedCtx = useContext(TicketMutateContext);
  if (!usedCtx) {
    throw Error('Ticket Info Context not inited');
  }

  return usedCtx;
};
