import { ReactElement } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
  Heading,
  Link,
  Progress,
  Text,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import { fixedChakraFactory } from '@bq/components/fixed-chakra-factory';
import { LoaderScale } from '@bq/components/Loader';
import { BackgroundTask } from '@bq/components/use-background-task';

import { ImportState } from './types';

type Props = BackgroundTask<ImportState>;

export const ImportTaskState = ({
  status,
  currentState,
}: Props): ReactElement => {
  const { t } = useTranslation('Datasets');

  if (status === 'pending') {
    return (
      <Flex px="6" py="6" alignItems="center" direction="column">
        <Heading>{t('Datasets:import_state.pending')}</Heading>
        <LoaderScale height="32" />
      </Flex>
    );
  }

  switch (currentState?.status) {
    case 'running':
      return (
        <Flex px="6" py="6" alignItems="center" direction="column">
          <Heading>{t('Datasets:import_state.running')}</Heading>
          <Progress size="lg" isIndeterminate w="50%" my="5" />
          {!!currentState.stateData.importedRows && (
            <Text as="span">
              <Trans
                ns="Datasets"
                i18nKey="imported_n_rows"
                defaults="Imported <strong>{{importedRows}}</strong> rows..."
                values={{ importedRows: currentState.stateData.importedRows }}
              />
            </Text>
          )}
        </Flex>
      );
    case 'completed':
      return (
        <LargeAlert status="success" py="6">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t('Datasets:import_state.successful')}
          </AlertTitle>
          <AlertDescription maxWidth="sm" mt="5">
            {currentState.stateData.datasetID && (
              <Link
                fontSize="xl"
                as={RouterLink}
                to={`/datasets/dataset/${currentState.stateData.datasetID}`}
              >
                {t('Datasets:continue_to_dataset')}
                <FaArrowRight style={{ display: 'inline' }} />
              </Link>
            )}
          </AlertDescription>
        </LargeAlert>
      );
    case 'failed':
      return (
        <LargeAlert status="error">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {t('Datasets:import_state.failed')}
          </AlertTitle>
          {currentState.stateData?.error && (
            <AlertDescription maxWidth="sm">
              {currentState.stateData.error}
            </AlertDescription>
          )}
        </LargeAlert>
      );
    case 'canceled':
      return (
        <Alert status="warning">
          <AlertIcon />
          {t('Datasets:import_state.canceled')}
        </Alert>
      );
    default:
      throw new Error('Unknown task state');
  }
};

const LargeAlert = fixedChakraFactory(Alert, {
  baseStyle: {
    variant: 'subtle',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
