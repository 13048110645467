import { ReactElement, ForwardedRef, useMemo, forwardRef, memo } from 'react';
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  VStack,
  useColorModeValue,
  chakra,
  Text,
  Divider,
} from '@chakra-ui/react';
import { Card } from 'BootQuery/Assets/components/Card';
import { FaBuilding, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Column } from 'BootQuery/Assets/components/Table';
import ReactFastCompare from 'react-fast-compare';
import { PersonApiData } from '../types';
import { EditButton } from '../EditButton';
import { NumberList } from '../NumberList';
import { EmailList } from '../EmailList';
import { PersonCardFeatures } from './types';
import { useExtenState } from '../use-exten-state';
import { ExtenStateIndicator } from '../ExtenStateIndicator';
import { ContactSelect } from '../ContactSelect';
import { CardCustomColumns } from './CardCustomColumns';
import { PhonebookCardBody } from '../PhonebookCardBody';

interface PersonCardProps {
  person: PersonApiData;
  features?: Partial<PersonCardFeatures>;
  topRight?: (props: PersonCardProps) => ReactElement;
  cardLink?: boolean;
  cardProps?: BoxProps;
  customColumns?: Column<PersonApiData>[];
}

const defaultFeatures: PersonCardFeatures = {
  company: true,
  phoneNumbers: true,
  emails: true,
  department: true,
  companyLocation: true,
  edit: true,
  select: true,
};

const OneLineLink = chakra(Link, {
  baseStyle: {
    width: 'full',
    color: 'inherit',
    float: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '& svg': {
      display: 'inline',
    },
  },
});

const PersonCardTitle = ({
  person,
  cardLink = true,
}: PersonCardProps): ReactElement => {
  const color = useColorModeValue('#3C485C', '#F5F5F5');
  const heading = (
    <Heading
      fontSize="lg"
      mb={0}
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      color={color}
      ml="26px"
    >
      {person.firstName} {person.lastName}
    </Heading>
  );

  return cardLink ? (
    <LinkOverlay
      as={RouterLink}
      to={`/phonebook/people/${person.ID}`}
      overflow="hidden"
      w="full"
    >
      {heading}
    </LinkOverlay>
  ) : (
    <Link
      as={RouterLink}
      to={`/phonebook/people/${person.ID}`}
      overflow="hidden"
      w="full"
    >
      {heading}
    </Link>
  );
};

const PersonCardContent = (props: PersonCardProps): ReactElement => {
  const { person, customColumns, features = {}, topRight: TopRightEl } = props;

  const { t } = useTranslation('Phonebook');
  const devState = useExtenState(person.phoneNumbers);

  const feats: PersonCardFeatures = useMemo(
    () => ({ ...defaultFeatures, ...features }),
    [features]
  );
  const bg = useColorModeValue('white', 'brand.backgroundDark');
  const color = useColorModeValue('#3C485C', '#F5F5F5');

  return (
    <PhonebookCardBody p={4} bg={bg} color={color}>
      <VStack spacing="2">
        <Flex
          w="full"
          justifyContent="space-between"
          alignItems="center"
          pos="relative"
        >
          {feats.select && <ContactSelect<PersonApiData> {...person} />}
          <PersonCardTitle {...props} />
          <HStack alignItems="center">
            {feats.edit && (
              <EditButton href={`/phonebook/people/${person.ID}/edit`} />
            )}
            {TopRightEl && <TopRightEl {...props} />}
          </HStack>
        </Flex>
        <Divider
          borderColor="brand.600"
          color="brand.600"
          borderWidth="1px"
        />
        {feats.company && (
          <Box w="full">
            {person.company ? (
              <OneLineLink
                as={RouterLink}
                to={`/phonebook/companies/${person.company.ID}`}
                title={t('Phonebook:company')}
                fontFamily="brand"
              >
                <FaBuilding style={{ display: 'inline' }} />
                &nbsp;
                {person.company?.name}
              </OneLineLink>
            ) : (
              <>&nbsp;</>
            )}
          </Box>
        )}
        {feats.phoneNumbers && (
          <Flex w="full" alignItems="center" overflow="hidden">
            <FaPhone />
            &nbsp;
            {person.phoneNumbers.length > 0 ? (
              <NumberList phoneNumbers={person.phoneNumbers} />
            ) : (
              <Text as="span" color="gray.500">
                {t('Phonebook:no_phone_numbers')}
              </Text>
            )}
          </Flex>
        )}
        {feats.emails && (
          <Flex w="full" alignItems="center" overflow="hidden">
            <FaEnvelope />
            &nbsp;
            {person.emails.length > 0 ? (
              <EmailList emails={person.emails} />
            ) : (
              <Text as="span" color="gray.500">
                {t('Phonebook:no_email_addresses')}
              </Text>
            )}
          </Flex>
        )}
        {devState && (
          <Flex w="full" justifyContent="flex-end" marginTop="0 !important">
            <ExtenStateIndicator state={devState} />
          </Flex>
        )}
      </VStack>
      {customColumns && (
        <VStack w="full" mt="4">
          <CardCustomColumns data={person} columns={customColumns} />
        </VStack>
      )}
    </PhonebookCardBody>
  );
};

const PersonCardInner = (
  props: PersonCardProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement => {
  const hoverBgColor = useColorModeValue('blue.50', 'blue.900');
  const { cardProps, cardLink = true } = props;

  return cardLink ? (
    <LinkBox
      ref={ref}
      as={Card}
      shadow="base"
      _hover={{ bg: hoverBgColor }}
      {...cardProps}
      borderRadius={0}
    >
      <PersonCardContent {...props} />
    </LinkBox>
  ) : (
    <Card ref={ref} {...cardProps} borderRadius={0}>
      <PersonCardContent {...props} />
    </Card>
  );
};

export const PersonCard = memo(forwardRef(PersonCardInner), ReactFastCompare);
