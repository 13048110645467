import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';

import { findItem } from '@bq/components/dnd-util';

import { BaseItem, DraggedItemInfo, OnDragHandler } from '../types';
import { ItemData } from './types';

export interface HandleDragStartProps<C> {
  setActiveId: Dispatch<SetStateAction<UniqueIdentifier | null>>;
  currentlyDragged: MutableRefObject<DraggedItemInfo<C> | null>;
  itemsRef: MutableRefObject<BaseItem<C>[]>;
  onDrag?: OnDragHandler<C>;
}

export type DragStartHandler = (ev: DragStartEvent) => void;

export function useHandleDragStart<C>({
  setActiveId,
  currentlyDragged,
  onDrag,
  itemsRef,
}: HandleDragStartProps<C>): DragStartHandler {
  return useCallback(
    (ev: DragStartEvent) => {
      setActiveId(ev.active.id);
      const item = ev.active.data.current as ItemData<C>;

      const foundItem = findItem(itemsRef.current, ev.active.id);
      if (!foundItem) {
        throw new Error(`Dragged item ${ev.active.id} not found`);
      }

      const initialPosition = {
        position: foundItem.position,
        path: foundItem.path,
      };

      currentlyDragged.current = {
        item,
        initialPosition,
      };

      if (onDrag) {
        onDrag(item, initialPosition);
      }
    },
    [setActiveId, currentlyDragged, itemsRef, onDrag]
  );
}
