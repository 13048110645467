import { memo, ReactElement } from 'react';
import { ModalBody, ModalContent } from '@chakra-ui/react';

import { LoadingPage } from './LoadingPage';

export const LoadingModalContent = memo(
  (): ReactElement => (
    <ModalContent>
      <ModalBody>
        <LoadingPage />
      </ModalBody>
    </ModalContent>
  )
);
LoadingModalContent.displayName = 'LoadingModalContent';
