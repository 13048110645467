import { intersection } from 'lodash-es';
import { getTicketStates } from '../../Api';
import { getTicketStatesForType } from '../../Api/state/statesForType';
import { TicketState, TicketType } from '../../types';
import { TicketStateSelectOption } from './types';

export const loadOptions = async (search: string, type?: number) => {
  const data =
    type !== undefined
      ? await getTicketStatesForType(type, { filters: { $search: search } })
      : await getTicketStates({
        filters: {
          $search: search,
        },
        fields: ['ID', 'name', 'color'],
      });

  return ticketStateToFilterVal(data);
};

export const ticketStateToFilterVal = (
  states: TicketState[]
): TicketStateSelectOption[] => {
  return states.map((state) => ({
    value: state.ID,
    label: state.name,
    color: state.color,
  }));
};

export const intersectTypes = (types: TicketType[]) => {
  //
  const allStatesIDs = types.map((item) => {
    return item.states.map((state) => state.state.ID);
  });
  const allStatesObject = types.map((type) => type.states).flat();
  const intersectTypes = intersection(...allStatesIDs);

  const foundIntersectedTypes = intersectTypes.map((item) => {
    const foundType = allStatesObject.find((type) => type.state.ID === item);
    if (!foundType) {
      throw Error('Intersected Type not found ');
    }

    return foundType.state;
  });

  return ticketStateToFilterVal(foundIntersectedTypes);
};
