import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = () => {
  return definePartsStyle({
    table: {
      th: {
        borderColor: 'gray.200',
      },
      td: {
        borderColor: 'gray.200',
      },
      _dark: {
        th: {
          borderColor: 'blackAlpha.300',
        },
        td: {
          borderColor: 'blackAlpha.300',
        },
        thead: {
          bg: '#393939',
        },
      },
      thead: {
        bg: '#F5F5F5',
      },
    },
  });
};

export const tableTheme = () => {
  return defineMultiStyleConfig({ baseStyle: baseStyle() });
};
