import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaList, FaPlus } from 'react-icons/fa';
import { SelectEdit } from './SelectEdit';
import {
  SelectFieldSettingsContext,
  SelectFieldSettingsProvider,
} from './SelectFieldSettingsContext';
import { SelectFieldEditorSettings } from './types';

interface SettingsModalProps {
  value: SelectFieldEditorSettings;
  onChange: (newVal: SelectFieldEditorSettings) => void;
  isOpen: boolean;
  onClose: () => void;
}

interface SettingsProps {
  value: SelectFieldEditorSettings;
  onChange: (newVal: SelectFieldEditorSettings) => void;
}

const Settings = ({ onChange }: SettingsProps): ReactElement => {
  const { t } = useTranslation();

  const {
    items,
    settings,
    methods: { addSelect },
  } = useContext(SelectFieldSettingsContext);

  useEffect(() => {
    onChange(settings);
  }, [settings, onChange]);

  if (!items) {
    return (
      <Flex justifyContent="center" py="4">
        <LoaderScale height={32} />
      </Flex>
    );
  }

  return (
    <Flex>
      {settings.selects.map((select, idx) => (
        <SelectEdit
          key={select.id}
          select={select}
          parentSelectId={settings.selects[idx - 1]?.id}
        />
      ))}
      <Box py="2" px="2">
        <IconButton
          label={t('global:form_editor.add_dropdown_level')}
          icon={<FaPlus />}
          colorScheme="green"
          size="sm"
          onClick={addSelect}
        />
      </Box>
    </Flex>
  );
};

export const SettingsModal = ({
  value,
  onChange,
  isOpen,
  onClose,
}: SettingsModalProps): ReactElement => {
  const { t } = useTranslation();

  const [tmpValue, setTmpValue] = useState(value);

  const submit = () => {
    onChange(tmpValue);
    onClose();
  };

  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaList />
          &nbsp;
          {t('global:form_editor.edit_select_items')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowX="auto">
          <SelectFieldSettingsProvider initial={value}>
            <Settings value={tmpValue} onChange={setTmpValue} />
          </SelectFieldSettingsProvider>
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions onSubmit={submit} onCancel={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
