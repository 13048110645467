import { ReactElement } from 'react';
import {
  Accordion,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TemplateField } from './TempateField';
import { TemplateHeaderFooterField } from './TemplateHeaderFooterField';
import { Template } from './types';

export const TemplateFormFields = (): ReactElement => {
  const { t } = useTranslation('Sales');
  const {
    register,
    formState: { errors },
  } = useFormContext<Partial<Template>>();

  return (
    <VStack spacing={4} w="full">
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">{t('Sales:name')}</FormLabel>
        <Input
          {...register('name', { required: true })}
          isInvalid={!!errors.name}
          placeholder={t('Sales:name')}
        />
      </FormControl>
      <Accordion w="full" allowMultiple allowToggle>
        <TemplateHeaderFooterField type="headerHTML" />
        <TemplateHeaderFooterField type="footerHTML" />
      </Accordion>

      <TemplateField />
    </VStack>
  );
};
