import { ReactElement } from 'react';
import { MenuGroup, MenuItem } from '@chakra-ui/react';

import { CallAction } from './types';

export interface CallActionItemProps {
  action: CallAction;
}

export const CallActionItem = ({
  action,
}: CallActionItemProps): ReactElement => {
  if (action.type === 'action') {
    return (
      <MenuItem onClick={action.onClick}>
        {action.icon && (
          <>
            <action.icon />
            &nbsp;
          </>
        )}
        {action.text}
      </MenuItem>
    );
  }
  if (action.type === 'link') {
    return (
      <MenuItem as="a" href={action.href}>
        {action.icon && (
          <>
            <action.icon />
            &nbsp;
          </>
        )}
        {action.text}
      </MenuItem>
    );
  }

  // Group
  return (
    <MenuGroup title={action.text}>
      {action.actions.map((action, idx) => (
        <CallActionItem key={idx} action={action} />
      ))}
    </MenuGroup>
  );
};
