import {
  Box,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

import { PersonFormFields } from 'app/Modules/Phonebook/Assets/components/PersonForm';

import { PersonFormWrapper } from './PersonFormWrapper';
import { PersonModalFooter } from './PersonModalFooter';
import { PersonModalContentProps } from './types';
import { useLabelForPersonModal } from './utils';

export const PersonModalContent = (props: PersonModalContentProps) => {
  const label = useLabelForPersonModal(props.mode);

  return (
    <PersonFormWrapper {...props}>
      <ModalHeader>
        <HStack>
          <Box>
            <FaPlus />
          </Box>
          <Box>{label}</Box>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <PersonFormFields />
      </ModalBody>
      <ModalFooter>
        <PersonModalFooter />
      </ModalFooter>
    </PersonFormWrapper>
  );
};
