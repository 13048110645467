import { memo, useMemo } from 'react';
import { Accordion } from '@chakra-ui/react';
import isEqual from 'react-fast-compare';

import { OpenItems } from './OpenItemsContext';
import { OverviewsProvider } from './OverviewsContext';
import { TreeButton } from './TreeButton';
import { TreeLink } from './TreeLink';
import { TreeOverviewProps } from './types';

/**
 *
 * @param {string} path - complete path to item e.g. phonebook/people or telephony
 * @param {IOverviewEditorItem} overviews - Load items fetched from backend overviews here
 * @param {Omit<Partial<IOverviewEditorItem>, 'slug'>& {title: string;}} parent
 * - use this when you need to group overviews fetched
 * from backend. e.g. You fetch all People overviews and want to group them and not
 * show items as classic overviews. See Phonebook/Assets/components/Overviews.tsx
 * and Telephony/Assets/components/CallList.tsx for examples.
 * for more details
 * @param {boolean} [searchable=false] - If true enables search bar and searching
 * through elements. Defaults to false.
 * @returns
 */
export const TreeOverviews = memo(
  ({
    path,
    overviews,
    parent,
    searchable = false,
    mode = 'url',
    selectedOverview,
    childlessFirst = true,
    getQueryParams,
  }: TreeOverviewProps) => {
    console.log('Parent?', parent);
    const changedOverviews = useMemo(() => {
      if (parent !== undefined) {
        return [
          {
            filters: parent.filters ?? [],
            title: parent.title,
            slug: '',
            global: parent.global ?? true,
            type: parent.type ?? 'group',
            visible: parent.visible ?? true,
            overviews,
          },
        ];
      }

      return overviews;
    }, [overviews, parent]);

    return (
      <OverviewsProvider
        getQueryParams={getQueryParams}
        mode={mode}
        selectedOverview={selectedOverview}
        childlessFirst={childlessFirst}
      >
        {changedOverviews && (
          <Accordion
            w="full"
            p={0}
            m={0}
            defaultIndex={changedOverviews.map((_overview, idx) => idx)}
            allowMultiple
          >
            {changedOverviews.map((overview) => {
              if ((overview.overviews ?? []).length > 0) {
                return (
                  <OpenItems key={overview.slug}>
                    <TreeButton
                      parentPath={parent?.path ?? path}
                      overview={overview}
                      path={path}
                      searchable={searchable}
                    />
                  </OpenItems>
                );
              }

              return (
                <TreeLink
                  slug={overview.slug}
                  title={overview.title}
                  path={parent?.path ?? path}
                  key={overview.slug}
                  pl="8"
                  py="2"
                />
              );
            })}
          </Accordion>
        )}
      </OverviewsProvider>
    );
  },
  isEqual
);
TreeOverviews.displayName = 'TreeOverviews';
