import { ChangeEvent, ReactElement, useMemo } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  HStack,
  Select,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Select as ReactSelect } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FaFilter, FaObjectGroup, FaTimes } from 'react-icons/fa';

import { IconButton } from 'BootQuery/Assets/components/IconButton';

import { DeleteButton } from '../../DeleteButton';
import { loadOverviews } from '../../Overviews/load-overviews';
import { chakraSelectStyles } from '../../Select';
import { Jsonish } from '../../type-util';
import { filterExpression } from '../filter-expression';
import { FilterName } from '../FilterName';
import {
  FilterProps,
  FilterType,
  FilterTypes,
  FilterTypesWithGroup,
  FilterValue,
} from '../types';
import {
  getDefaultFilterValue,
  nameStr,
  operatorsVal,
  removeValueAt,
  setValueAt,
} from '../util';

export type BooleanOperator = '$and' | '$or';

interface GroupFilterProps extends FilterProps<FilterValue[]> {
  headless?: boolean;
}

interface OperatorSelectProps {
  value: BooleanOperator;
  onChange: (value: BooleanOperator) => void;
}

const OperatorSelect = ({
  value,
  onChange,
}: OperatorSelectProps): ReactElement => {
  const { t } = useTranslation();

  const handleChange = (ev: ChangeEvent<HTMLSelectElement>): void => {
    const { value } = ev.target;
    if (value !== '$and' && value !== '$or') {
      throw new Error(`Got invalid boolean operator "${value}" from select`);
    }

    onChange(value);
  };

  return (
    <Select value={value} onChange={handleChange}>
      <option value="$and">{t('global:filter.group_all')}</option>
      <option value="$or">{t('global:filter.group_any')}</option>
    </Select>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FProps = FilterProps<any>;

interface FilterSelectorProps {
  onFilterChange: (filter: string) => void;
  filter: string;
  filterTypes: FilterTypes;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface FilterRowProps extends FProps, FilterSelectorProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FilterComponent: (props: FProps) => ReactElement;
  extraProps?: Omit<
    FProps,
    keyof FProps | 'component' | 'toFilter' | 'name' | 'operators'
  >;
}

const FilterSelector = ({
  filterTypes,
  filter,
  onFilterChange,
}: FilterSelectorProps): ReactElement => {
  const filterList = Object.entries(filterTypes).map(([key, filter]) => ({
    value: key,
    label: typeof filter.name === 'function' ? filter.name() : filter.name,
  }));

  const selectedType = filterList.find((type) => type.value === filter);
  if (!selectedType) {
    throw new Error(`Got unknown filter type: ${filter}`);
  }

  return (
    <Box w="full">
      <ReactSelect<{
        label: string | (() => string);
        value: string;
      }>
        options={filterList.filter((filter) => filter.value !== '$group')}
        onChange={(val) => {
          if (val && typeof val !== 'string') {
            onFilterChange(val.value);
          }
        }}
        value={selectedType}
        styles={chakraSelectStyles}
        selectedOptionColorScheme="brand"
      />
    </Box>
  );
};

const FilterRow = ({
  FilterComponent,
  extraProps,
  filter,
  onFilterChange,
  ...filterProps
}: FilterRowProps): ReactElement => (
  <>
    <HStack m={0} p={0} w="full">
      {filter !== '$group' && (
        <Grid templateColumns="1fr 1fr 1fr" w="100%" gap={2}>
          <FilterSelector
            filterTypes={filterProps.filterTypes}
            filter={filter}
            onFilterChange={onFilterChange}
          />
          <FilterComponent {...filterProps} {...(extraProps ?? {})} />
        </Grid>
      )}
      {filter === '$group' && (
        <Box w="full">
          <FilterComponent {...filterProps} {...(extraProps ?? {})} />
        </Box>
      )}

      {filter !== '$group' && (
        <DeleteButton onClick={filterProps.onRemove} alignSelf="flex-end" />
      )}
    </HStack>
    <Divider />
  </>
);

type FilterListProps = Pick<
  GroupFilterProps,
  'filterTypes' | 'value' | 'onChange'
>;
const FilterList = ({
  filterTypes,
  value,
  onChange,
}: FilterListProps): ReactElement => {
  const filterValues = value ?? [];

  return (
    <>
      {filterValues.map((value, idx) => {
        const filter = filterTypes[value.filter] ?? null;
        const operators = operatorsVal(filter.operators);

        if (!filter) {
          throw new Error(`Unknown filter type ${value.filter}`);
        }
        const FilterComponent = filter.inputComponent;
        const rowProps: FilterRowProps = {
          FilterComponent,
          filterTypes,
          filter: value.filter,
          name: nameStr(filter.name),
          value: value.value,
          operator: value.operator,
          operators,
          extraProps: filter.extraProps,
          onChange: (newVal) => {
            const setVal = (val: FilterValue) => ({ ...val, value: newVal });
            onChange(setValueAt(filterValues, idx, setVal));
          },
          onOperatorChange: (newOp) => {
            const setOp = (val: FilterValue) => ({ ...val, operator: newOp });
            onChange(setValueAt(filterValues, idx, setOp));
          },
          onRemove: () => {
            onChange(removeValueAt(filterValues, idx));
          },
          onFilterChange: (filter) => {
            if (filter === value.filter) {
              return;
            }

            const filterType = filterTypes[filter];
            if (!filterType) {
              throw new Error(`Selected unknown filter type ${filter}`);
            }

            const newFilter = {
              filter,
              value: getDefaultFilterValue(filterType),
              operator: operatorsVal(filterType.operators)[0]?.operator ?? null,
            };
            // Re-create filter with defaults because type changed
            onChange(setValueAt(filterValues, idx, () => newFilter));
          },
        };

        return <FilterRow key={idx} {...rowProps} />;
      })}
    </>
  );
};

export const GroupFilter = ({
  operator,
  filterTypes: filterTypesWithoutGroup,
  value,
  onChange,
  onOperatorChange,
  onRemove,
  headless = false,
}: GroupFilterProps): ReactElement => {
  const { t } = useTranslation();

  const filterTypes = useMemo(
    () => filterTypesWithGroup(filterTypesWithoutGroup),
    [filterTypesWithoutGroup]
  );
  if (operator !== '$and' && operator !== '$or') {
    throw new Error(
      `GroupFilter operator should be $and or $or, got ${operator}`
    );
  }
  const filterValues = (value ?? []) as FilterValue[];

  const addFilterGroup = () => {
    onChange([
      ...filterValues,
      {
        value: [],
        filter: '$group',
        operator: '$and',
      },
    ]);
  };
  const addFilter = () => {
    const filter = Object.keys(filterTypes).filter(
      (key) => key !== '$group'
    )[0];
    const filterType = filterTypes[filter];
    if (!filterType) {
      throw new Error(`Tried to add unknown filter ${filter}`);
    }

    const operator = operatorsVal(filterType.operators)[0]?.operator ?? null;
    const value = getDefaultFilterValue(filterType);

    onChange([...filterValues, { value, operator, filter }]);
  };

  return (
    <VStack w="full">
      {headless || (
        <VStack w="full">
          <Flex justifyContent="space-between" w="full">
            <HStack>
              <Box>{t('global:filter_match')} </Box>
              <OperatorSelect
                value={operator ?? '$and'}
                onChange={onOperatorChange}
              />{' '}
              <Box>{t('global:filter_conditions')}:</Box>
            </HStack>
            <DeleteButton onClick={onRemove} />
          </Flex>
        </VStack>
      )}
      <VStack w="full" p="4">
        <FilterList
          filterTypes={filterTypes}
          value={filterValues}
          onChange={onChange}
        />
        <HStack justifyContent="flex-end" w="100%">
          <Button onClick={addFilterGroup}>
            <Flex alignItems="center">
              <FaObjectGroup />
              &nbsp;
              {t('global:add_filter_group')}
            </Flex>
          </Button>

          <Button onClick={addFilter}>
            <Flex alignItems="center">
              <FaFilter />
              &nbsp;
              {t('global:add_filter')}
            </Flex>
          </Button>
        </HStack>
      </VStack>
    </VStack>
  );
};

export const GroupFilterTag = ({
  name,
  onRemove,
}: GroupFilterProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');

  return (
    <Flex background={bgColor} height="8" borderRadius="md">
      <FilterName name={name} />
      <IconButton
        onClick={onRemove}
        variant="link"
        label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};

function parseFilters(value: Jsonish, filterTypes: FilterTypes): FilterValue[] {
  const processed = loadOverviews([{ filters: value }], filterTypes);

  // We gave it one overview to process, should always return exactly one
  return processed[0].filters;
}

export function filterTypesWithGroup(
  filterTypes: FilterTypes
): FilterTypesWithGroup {
  const $group: FilterType<FilterValue[]> = {
    tagComponent: GroupFilterTag,
    inputComponent: GroupFilter,
    toFilter: async ({ value, operator }) => {
      if (value.length > 0 && operator) {
        const subExpr = await filterExpression(
          { $group, ...filterTypes },
          value,
          ''
        );

        return { [operator]: subExpr };
      }

      return null;
    },
    name: 'Advanced filters',
    fromJSON(value, _operator, filterTypes) {
      return parseFilters(value, filterTypes);
    },
    fromQueryString(value, _operator, filterTypes) {
      return parseFilters(value as Jsonish, filterTypes);
    },
    operators: [],
    selectable: false,
  };

  return {
    $group,
    ...filterTypes,
  };
}

export function useFilterTypesWithGroup(
  filterTypes: FilterTypes
): FilterTypesWithGroup {
  return useMemo(() => filterTypesWithGroup(filterTypes), [filterTypes]);
}
