import { ReactElement, useMemo } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { FieldValue } from '@bq/components/FormEditor';
import { ListHeading } from '@bq/components/ListHeading';
import {
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { LoadingPage } from '@bq/components/LoadingPage';
import { TableSelectionProvider } from '@bq/components/Table';
import { CardBulkActionMenu } from '@bq/components/Table/CardBulkActionMenu';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { setColValues } from 'app/Modules/Ticketing/Util/GetColumns';

import { phonebookDisplayModeOptions } from '../../CompanyEmployeeList/display-settings';
import { ActionsButton } from '../../CompanyList/ActionsButton';
import { CompanyCardFeatures } from '../../CompanyList/types';
import { PhonebookListContext } from '../../PhonebookListContext';
import { CompanyLocationApiData } from '../../types';
import { useSelectedOverview } from '../../use-selected-overview';
import { columns, companyLocationsCardFeatures } from '../columns';
import {
  deleteItem,
  sendSms,
  sendSmsEmployees,
} from '../company-locations-bulk-actions';
import { filterTypes } from '../filter-types';
import { pickCorrectQuery } from '../utils';
import { CompanyLocationCard } from './CompanyLocationCard';

interface Props {
  companyID?: number;
  customFields?: FieldValue[];
  features?: CompanyCardFeatures;
}

export const CompanyLocationsCardList = ({
  companyID,
  customFields,
  features,
}: Props): ReactElement => {
  const overview = useSelectedOverview('companyLocations');
  const listingSettings = useListingSettings<CompanyLocationApiData>({
    listingName: companyID
      ? 'Phonebook.CompanyLocationListEmbed'
      : 'Phonebook.CompanyLocationList',
    viewName: 'card',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });
  const { filters, page, limit, density, columnsToShow } = listingSettings;

  const { columns: customColumns, features: customFeatures } =
    useSplitColumnsFeatures(columnsToShow, companyLocationsCardFeatures);

  const { data, refetch } = useQuery({
    queryKey: ['Phonebook.CompanyLocationList.card', page, filters, limit],
    queryFn: () => {
      return pickCorrectQuery(
        {
          page,
          limit,
          filters,
        },
        companyID
      );
    },
    enabled: filters !== undefined,
    keepPreviousData: true,
  });

  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      {
        actions: [sendSms(navigate), sendSmsEmployees(navigate)],
        styleProps: { colorScheme: 'brand', size: 'sm' },
      },
      {
        actions: [deleteItem(refetch)],
        styleProps: {
          colorScheme: 'red',
          variant: 'outline',
          style: {
            marginLeft: 'auto',
          },
        },
      },
    ],

    [navigate, refetch]
  );
  const itemCount = data?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    displayModeOptions: phonebookDisplayModeOptions,
    listingProps: listingSettings,
    count: itemCount,
  });

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<CompanyLocationApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await pickCorrectQuery(
            {
              page: 1,
              limit: 'null',
              filters,
              fields: [
                'ID',
                'name',
                'phoneNumbers.phoneNumber.phoneNumberE164',
              ],
            },
            companyID
          );

          return data;
        }}
        selectPage={() => data?.data ?? []}
      >
        <Box mt={9} mb={4} px={4}>
          <ListHeading
            {...headingProps}
            Actions={<ActionsButton companyID={companyID} />}
          />
        </Box>
        <SimpleGrid m="10px" gridGap={4} columns={setColValues(density)}>
          {data.data.map((location) => (
            <CompanyLocationCard
              location={location}
              key={location.ID}
              companyID={companyID ?? location.company.ID}
              customColumns={customColumns}
              features={{ ...customFeatures, ...features }}
            />
          ))}
        </SimpleGrid>

        <CardBulkActionMenu<CompanyLocationApiData>
          actionGroups={bulkActions}
        />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
