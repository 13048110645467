import { ReactElement, memo, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import {
  FieldValue,
  StaticFormProvider,
  StaticGeneratedFormField,
  getFieldTypes,
} from '@bq/components/FormEditor';
import { CustomData } from 'BootQuery/Assets/js/globalTypes';

import { ChangedItem } from './ChangedItem';
import { ChangeFrom } from './ChangeFrom';
import { ChangeTo } from './ChangeTo';
import { ChangeArrow } from './ChangeArrow';

interface Props {
  customFields: FieldValue[];
  from: CustomData;
  to: CustomData;
}

export const CustomFormChanges = memo(
  ({ customFields, from, to }: Props): ReactElement => {
    const changedFields = useMemo(
      () => changedCustomFields(customFields, from, to),
      [customFields, from, to]
    );
    const fieldTypes = useMemo(getFieldTypes, []);

    return (
      <>
        {changedFields.map((field) => (
          <ChangedItem key={field.id} label={field.settings.name}>
            <ChangeFrom>
              <StaticFormProvider formData={from}>
                <StaticGeneratedFormField
                  field={field}
                  fieldTypes={fieldTypes}
                  prefix={null}
                />
              </StaticFormProvider>
            </ChangeFrom>
            <ChangeArrow />
            <ChangeTo>
              <StaticFormProvider formData={to}>
                <StaticGeneratedFormField
                  field={field}
                  fieldTypes={fieldTypes}
                  prefix={null}
                />
              </StaticFormProvider>
            </ChangeTo>
          </ChangedItem>
        ))}
      </>
    );
  }
);
CustomFormChanges.displayName = 'CustomFormChanges';

function changedCustomFields(
  customFields: FieldValue[],
  from: CustomData,
  to: CustomData
) {
  return customFields.filter((field) => !isEqual(from[field.id], to[field.id]));
}
