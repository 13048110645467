import { ReactElement } from 'react';

import { PageWrapper } from '@bq/components/PageWrapper';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

export const Products = (): ReactElement => {
  useAppTitle('Products');

  return <PageWrapper p={5}>{'Products'}</PageWrapper>;
};
