import { FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  changeCallback: (newVal: string | null) => void;
  submitHandler: () => void;
  value: string;
}
export const URLtab = ({ changeCallback, submitHandler, value }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl p="4">
      <FormLabel form="url">{t('global:url')}</FormLabel>
      <Input
        type="url"
        id="url"
        value={value}
        onChange={(e) => {
          changeCallback(typeof e.currentTarget.value === 'string' ? e.currentTarget.value : '');
        }}
      />
      <Button w="full" onClick={submitHandler} mt={2}>
        {t('global:send')}
      </Button>
    </FormControl>
  );
};
