import { ComponentType, lazy, LazyExoticComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Comp = ComponentType<any>;

type Components<O> = {
  [P in keyof O]: O[P] extends Comp ? P : never;
}[keyof O];

const toDefaultExport = <O, K extends Components<O>>(
  imported: O,
  key: K
): { default: O[K] } => {
  return { default: imported[key] };
};

const defaultExtractor = <O, K extends Components<O> = Components<O>>(
  key: K
): ((imported: O) => { default: O[K] }) => {
  return (imported: O) => toDefaultExport(imported, key);
};

export const lazyImport = <O, K extends Components<O>, T extends Comp & O[K]>(
  imp: () => Promise<O>,
  key: K
): LazyExoticComponent<T> => {
  return lazy(() => {
    return imp().then(defaultExtractor<O, K>(key) as () => { default: T });
  }) as LazyExoticComponent<T>;
};
