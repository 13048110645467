import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

import { FieldListItem } from './FieldListItem';
import { EditorItem } from './types';
import { assertIsType } from './util';

interface Props {
  items: EditorItem[];
}

export const FieldList = ({ items }: Props): ReactElement => (
  <Box>
    {items.map((item) => {
      assertIsType(item);

      return <FieldListItem key={item.id} {...item} />;
    })}
  </Box>
);
