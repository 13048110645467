import { useMemo } from 'react';

import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { customFormColumns, getFieldTypes } from '../FormEditor';
import { FieldValue } from '../FormEditor/types';
import { Column } from '../Table';

export function useCustomColumns<T extends OBJ>(
  customFields: FieldValue[] = []
): Column<T>[] {
  return useMemo(() => {
    const customColumns = customFormColumns(
      customFields,
      getFieldTypes()
    ) as unknown as Column<T>[];

    return customColumns;
  }, [customFields]);
}

export function useLoadColumns<T extends OBJ>(
  columns: (() => Column<T>[]) | Column<T>[]
): Column<T>[] {
  return useMemo(() => {
    const columnsF = typeof columns === 'function' ? columns() : columns;

    return columnsF;
  }, [columns]);
}

type FeatureName = string;
type FeatureMap<RowType> = Partial<{
  [columnKey in Column<RowType>['key']]: FeatureName;
}>;
export function useSplitColumnsFeatures<
  RowType extends OBJ,
  Features extends OBJ,
>(columns: Column<RowType>[], features: FeatureMap<RowType>) {
  return useMemo(() => {
    return {
      /** Remove found */
      columns: columns.filter((column) => features[column.key] === undefined),
      /** Check if in list */
      features: Object.entries(features).reduce((all, current) => {
        const isInColumns = columns.some((column) => column.key === current[0]);
        if (!current[1]) {
          return all;
        }

        return { ...all, [current[1]]: isInColumns };
      }, {} as Features),
    };
  }, [columns, features]);
}
