import {
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '../FormActions';
import { LoadingPage } from '../LoadingPage';
import { ReportAgentsPermission } from './ReportAgentsPermission';
import {
  AgentPermissions,
  AgentsPermissionsResult,
  SetAgentsPermissions,
} from './types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  agentsPermissions: AgentsPermissionsResult;
  setAgentsPermissions: SetAgentsPermissions;
}

export const ReportAgentsPermissionModal = ({
  isOpen,
  onClose,
  agentsPermissions,
  setAgentsPermissions,
}: Props): ReactElement => {
  const { t } = useTranslation('global');
  const [value, setValue] = useState<AgentPermissions | null>(null);

  const cancel = useCallback(() => {
    onClose();
  }, [onClose]);
  const submit = useCallback(() => {
    if (value) {
      setAgentsPermissions(value);
    }
    onClose();
  }, [onClose, value, setAgentsPermissions]);

  const { data: permissions } = agentsPermissions;
  useEffect(() => {
    if (permissions !== undefined) {
      setValue(permissions);
    }
  }, [permissions]);

  const setValueChecked = useCallback(
    (change: SetStateAction<AgentPermissions>) => {
      setValue((prev) => {
        if (!prev) {
          throw new Error('Tried to set permissions before they were loaded');
        }

        return typeof change === 'function' ? change(prev) : prev;
      });
    },
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancel}
      size="6xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('global:report_agents_permissions')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody flexBasis="fullModalHeight" overflow="hidden">
          {value !== null ? (
            <ReportAgentsPermission value={value} onChange={setValueChecked} />
          ) : (
            <LoadingPage />
          )}
        </ModalBody>
        {value !== null && (
          <ModalFooter justifyContent="flex-end">
            <FormActions onSubmit={submit} onCancel={cancel} />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
