import { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Flex, Td } from '@chakra-ui/react';

import { Modifications, SettingsMethods } from '@bq/components/SettingsCRUD';
import { AddButton } from '@bq/components/AddButton';
import { uniqid } from 'app/assets/js/tsutil';

import { BlacklistItem } from './types';
import { BlacklistRow } from './BlacklistRow';

interface Props {
  value: BlacklistItem[];
  modify: SettingsMethods<BlacklistItem>;
  modifications: Modifications<BlacklistItem>;
}

export const BlacklistEditor = ({
  value,
  modify,
  modifications,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');

  const add = useCallback(() => {
    modify.add({
      ID: `$new-${uniqid()}`,
      phoneNumber: '',
      note: '',
      direction: 'both',
    });
  }, [modify]);

  const shownItems = useMemo(() => {
    return value.filter((val) => !modifications.del.includes(val.ID)).reverse();
  }, [value, modifications.del]);

  return (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>{t('Telephony:phone_number')}</Th>
          <Th>{t('Telephony:note')}</Th>
          <Th>
            <Flex justifyContent="space-between">
              <span>{t('Telephony:direction')}</span>
              <AddButton size="sm" onClick={add} />
            </Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {shownItems.length > 0 ? (
          shownItems.map((item) => (
            <BlacklistRow key={item.ID} item={item} modify={modify} />
          ))
        ) : (
          <Tr>
            <Td colSpan={3} textAlign="center">
              {t('global:no_results')}
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
};
