import {
  FilterType,
  FilterTypes,
} from 'BootQuery/Assets/components/FilterBar/types';
import { makeTextFilter } from 'BootQuery/Assets/components/FilterBar/filters/TextFilter';
import { numberFilterExpression } from 'BootQuery/Assets/components/FilterBar/filters/PhoneNumberFilter';
import { useMemo } from 'react';
import {
  customFormFilters,
  FieldValue,
  getFieldTypes,
} from 'BootQuery/Assets/components/FormEditor';
import { makeComboboxFilter } from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';
import { ListItem } from '../types';

interface CompanyResponse {
  data: ListItem[];
}

export const filterTypes: Record<string, FilterType> = {
  name: makeTextFilter({
    name: () => i18n.t('global:name'),
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return ['name', operator ?? 'contains:ci', value];
    },
  }),
  company: makeComboboxFilter({
    name: () => i18n.t('Phonebook:company'),
    operators: [],
    toFilter: ({ value }) => {
      if (!value) {
        return null;
      }

      return typeof value === 'string'
        ? { 'company.name:contains:ci': value }
        : { 'company.ID': value.ID };
    },
    extraProps: {
      cacheKey: 'phonebookCompanyFilter',
      search: async (search: string) => {
        const {
          data: { data: companies },
        } = await Api.get<CompanyResponse>('/api/phonebook/companies', {
          params: {
            fields: ['ID', 'name'],
            filters: search ? { $search: search } : {},
            withCount: false,
          },
        });

        return companies;
      },
      itemToString: (item: ListItem) => item.name,
      itemToValue: (company: ListItem) => company,
      valueToItem: (company: ListItem) => company,
    },
  }),
  phoneNumber: makeTextFilter({
    name: () => i18n.t('Phonebook:phone_number'),
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return {
        'phoneNumbers:$any': numberFilterExpression(value, operator ?? 'eq'),
      };
    },
  }),
  locationMark: makeTextFilter({
    name: () => i18n.t('Phonebook:location_mark'),
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      const op = operator ?? 'contains:ci';

      return ['data.locationMark', `string:${op}`, value];
    },
  }),
};

export function useFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  return useMemo(() => {
    const customFilters = customFormFilters(customFields, getFieldTypes());

    return {
      ...filterTypes,
      ...customFilters,
    };
  }, [customFields]);
}

export function getFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...filterTypes,
    ...customFilters,
  };
}
