import i18n from 'BootQuery/Assets/js/i18n';

import { ItemColumn } from '../types';

export const baseColumns = (): ItemColumn[] => [
  idColumn(),
  nameColumn(),
  longNameColumn(),
  descriptionColumn(),
  itemProgramColumn(),
  itemGroupColumn(),
  itemSubgroupColumn(),
  codeColumn(),
  defaultAmountUnitColumn(),
  amountUnitGroupColumn(),
  noteColumn(),
  supplierColumn(),
];

export const idColumn = (): ItemColumn => ({
  key: 'ID',
  title: '#',
  width: '60px',
  order: 102,
  sort: 'ID',
});

export const nameColumn = (): ItemColumn => ({
  key: 'name',
  title: i18n.t('Products:name'),
  sort: 'name',
  order: 201,
  getValue: ({ row }) => {
    if (!row.name) {
      return '-';
    }

    return row.name;
  },
});

export const longNameColumn = (): ItemColumn => ({
  key: 'longName',
  title: i18n.t('Products:long_name'),
  getValue: ({ row }) => {
    if (!row.longName) {
      return '-';
    }

    return row.longName;
  },
  order: 202,
});
export const descriptionColumn = (): ItemColumn => ({
  key: 'description',
  title: i18n.t('Products:description'),
  getValue: ({ row }) => {
    if (!row.description) {
      return '-';
    }

    return row.description;
  },
  order: 203,
});
export const itemProgramColumn = (): ItemColumn => ({
  key: 'itemProgram',
  title: i18n.t('Products:program'),
  order: 204,
  getValue: ({ row }) => row.program?.name ?? '-',
});
export const itemGroupColumn = (): ItemColumn => ({
  key: 'itemGroup',
  title: i18n.t('Products:group'),
  order: 205,
  getValue: ({ row }) => row.group?.name ?? '-',
});
export const itemSubgroupColumn = (): ItemColumn => ({
  key: 'itemSubgroup',
  title: i18n.t('Products:subgroup'),
  order: 206,
  getValue: ({ row }) => row.subgroup?.name ?? '-',
});
export const defaultAmountUnitColumn = (): ItemColumn => ({
  key: 'defaultAmountUnit',
  title: i18n.t('Products:default_amount_unit'),
  order: 207,
  getValue: ({ row }) => {
    if (!row?.defaultAmountUnit?.symbol) {
      return '-';
    }

    return row?.defaultAmountUnit.symbol;
  },
});
export const amountUnitGroupColumn = (): ItemColumn => ({
  key: 'amountUnitGroup',
  title: i18n.t('Products:amount_unit'),
  order: 208,
  getValue: ({ row }) => {
    if (!row.amountUnitGroup) {
      return '-';
    }

    return row.amountUnitGroup.key;
  },
});

/** 209 - 211 Defined in other files */
/** 209 is packagineAmmount for Products */
/** 210 is third party service boolean for Services */
/** 210 is warranty for Products */
/** 211 is producer for Products */

export const supplierColumn = (): ItemColumn => ({
  key: 'supplier',
  title: i18n.t('Products:supplier'),
  order: 212,
});

/** We group all serial, code etc data under 150 order, not too important what order */
export const codeColumn = (): ItemColumn => ({
  key: 'code',
  title: i18n.t('Products:code'),
  getValue: ({ row }) => {
    if (!row.code) {
      return '-';
    }

    return row.code;
  },
  order: 250,
});
export const noteColumn = (): ItemColumn => ({
  key: 'note',
  title: i18n.t('Products:note'),
  /** Whatever last in 1 category */
  order: 299,
});
