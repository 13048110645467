import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams, Tag } from 'BootQuery/Assets/js/globalTypes';

export const getManyTags = async (params: GetListingParams = {}) => {
  const { data } = await Api.get<Tag[]>('/api/tags', {
    params,
  });

  return data;
};
