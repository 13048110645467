import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Api } from 'BootQuery/Assets/js/api';
import { UnitData } from './types';

async function getUnits(): Promise<UnitData> {
  const { data } = await Api.get<UnitData>('/api/units');

  return data;
}

export function useUnits(): UseQueryResult<UnitData> {
  return useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });
}
