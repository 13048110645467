import { Api } from 'BootQuery/Assets/js/api';
import { Contact } from 'app/Modules/Phonebook/Assets/js/types';

export const getContacts = async (search: string, allowedTypes: string[]) => {
  const { data } = await Api.get<Contact[]>('/api/phonebook/contacts', {
    params: {
      $search: search,
      type: allowedTypes,
    },
  });

  return data;
};
