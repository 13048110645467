import { memo, useEffect, useState } from 'react';
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
} from '@chakra-ui/react';
import { debounce } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { IOverviewEditorItem } from '../Overviews';
import { useOpenItems } from './OpenItemsContext';
import { TreeItem } from './TreeItem';
import { TreeLink } from './TreeLink';
import { TreeSearchBar } from './TreeSearchBar';
import { OverviewSearchResult, SearchInterface } from './types';
import { buildOpenItems, searchTree } from './utils';

interface Props {
  overview: IOverviewEditorItem;
  path: string;
  searchable: boolean;
  parentPath: string;
}

const TreeButton = memo(({ overview, path, searchable, parentPath }: Props) => {
  const { setOpenItems } = useOpenItems();
  const [items, setItems] = useState<OverviewSearchResult[]>(
    overview.overviews ?? []
  );
  const { overview: selectedOverview } = useParams();
  const methods = useForm<SearchInterface>();
  const [search, setSearch] = useState<string | null>(null);
  const onSubmit = (data: SearchInterface) => {
    if (data.search === '') {
      methods.reset();
    }
  };
  const searchVal = methods.watch('search') ?? '';

  const debounceSearchVal = debounce(
    (search: string) => setSearch(search),
    1000
  );

  useEffect(() => {
    debounceSearchVal(searchVal);
  }, [debounceSearchVal, searchVal]);

  useEffect(() => {
    if (searchable && search !== null) {
      setItems(
        searchTree(overview.overviews ?? [], search, selectedOverview ?? null)
      );
    }
  }, [overview.overviews, search, searchable, selectedOverview]);

  useEffect(() => {
    if (search !== '') {
      setOpenItems(
        buildOpenItems(
          overview.slug,
          items,
          search ?? '',
          selectedOverview ?? null,
          (_isOpen: boolean) => {
            /* */
          }
        )
      );
    }
  }, [items, overview.slug, search, selectedOverview, setOpenItems]);

  return (
    <FormProvider {...methods}>
      <form
        data-ignore-form
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={() => {
          setSearch('');
          setItems(overview.overviews ?? []);
        }}
      >
        <AccordionItem border="none" w="full">
          {({ isExpanded }) => (
            <>
              <AccordionButton pl="0.75rem">
                <AccordionIcon
                  fontSize={['3xl', '3xl', 'xl', 'xl']}
                  transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
                  _hover={{
                    color: 'brand.100',
                  }}
                />
                <TreeLink
                  path={parentPath}
                  slug={overview.slug}
                  title={overview.title}
                />
              </AccordionButton>
              <AccordionPanel
                w="full"
                p={0}
                m={0}
                boxSizing="border-box"
                bg="#3E3F3FF2"
                shadow="inner"
              >
                <Box w="full" h="4" />
                <VStack
                  bg="transparent"
                  ml="24px"
                  overflowY="auto"
                  boxSizing="border-box"
                  borderLeft="1px solid var(--chakra-colors-brand-300)"
                >
                  {searchable && <TreeSearchBar />}
                  {items && (
                    <TreeItem
                      items={items}
                      path={path}
                      parent={overview.slug}
                    />
                  )}
                </VStack>
                <Box w="full" h="4" />
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </form>
    </FormProvider>
  );
});

TreeButton.displayName = 'TreeButton';

export { TreeButton };
