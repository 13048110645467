import { ReactElement, Suspense } from 'react';
import { useQuery } from '@tanstack/react-query';

import { lazyImport } from '@bq/components/lazy-import';
import { LoadingModalContent } from '@bq/components/LoadingModalContent';
import { useFormDefinition } from 'BootQuery/Assets/components/FormEditor';

import { usePhonebookOverviews } from '../../overviews';

const importContent = () => import('./OverviewEditorModalContent');
const OverviewEditorModalContent = lazyImport(
  importContent,
  'OverviewEditorModalContent'
);

export const PhonebookOverviewEditorModal = (): ReactElement => {
  const { data: overviews } = usePhonebookOverviews();

  const personFields = useFormDefinition('Phonebook.person');
  const companyFields = useFormDefinition('Phonebook.company');
  const locationFields = useFormDefinition('Phonebook.companyLocation');
  // Attempt to preload the component in parallel
  const { isLoading: contentLoading } = useQuery({
    queryKey: ['Phonebook.OverviewEditorComponent'],
    queryFn: importContent,
  });

  if (
    !personFields ||
    !companyFields ||
    !locationFields ||
    !overviews ||
    contentLoading
  ) {
    return <LoadingModalContent />;
  }

  return (
    <Suspense fallback={<LoadingModalContent />}>
      <OverviewEditorModalContent
        customPersonFields={personFields}
        customCompanyFields={companyFields}
        customLocationFields={locationFields}
        defaultOverviews={overviews}
      />
    </Suspense>
  );
};
