import Parchment from 'parchment';

class RawHtml extends Parchment.Embed {
  static create(value) {
    const node = super.create(value);
    node.style.whiteSpace = 'normal';
    node.setAttribute('contenteditable', 'false');
    node.innerHTML = value;
    return node;
  }

  static value(node) {
    return node.innerHTML;
  }

  length() {
    return 1;
  }
}

RawHtml.blotName = 'raw-html';
RawHtml.tagName = 'div';
RawHtml.className = 'clickvox-raw-html';

export default RawHtml;
