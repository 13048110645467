import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColorInput } from '@bq/components/ColorInput';

import { CaseState } from '../../schemas';

type CaseStateFormProps = Omit<CaseState, 'ID'>;

export const CaseStateFormFields = () => {
  const { t } = useTranslation('');
  const { register, control } = useFormContext<CaseStateFormProps>();
  const {
    field: colorField,
    fieldState: { error },
  } = useController({
    control,
    name: 'color',
    rules: {
      required: {
        value: true,
        message: t('Sales:errors.color_is_required'),
      },
    },
  });

  return (
    <VStack w="full">
      <HStack w="full" alignItems="center" spacing={5}>
        <FormControl w="full">
          <FormLabel w="full">{t('global:name')}</FormLabel>
          <Input
            {...register('name', { required: true })}
            size="sm"
            w="full"
          ></Input>
        </FormControl>
        <FormControl w="min-content">
          <FormLabel w="full">{t('global:color')}</FormLabel>
          <ColorInput
            size="34px"
            onChange={(color) => {
              colorField.onChange(color);
            }}
            value={colorField.value}
          />
          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </HStack>
    </VStack>
  );
};
