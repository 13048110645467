import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterTitle = makeTextFilter({
  name: () => i18n.t('Ticketing:form.title'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return ['title', operator ?? 'contains:ci', value];
  },
});
