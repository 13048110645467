import { ReactElement } from 'react';
import {
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
} from '@chakra-ui/react';
import { Link, LinkProps } from 'react-router-dom';

interface BreadcrumbProps extends BreadcrumbItemProps {
  to: LinkProps['to'];
}

export const Breadcrumb = ({
  children,
  to,
  ...itemProps
}: BreadcrumbProps): ReactElement => (
  <BreadcrumbItem overflow="hidden" {...itemProps}>
    <BreadcrumbLink
      overflow="hidden"
      textOverflow="ellipsis"
      as={Link}
      to={to}
    >
      {children}
    </BreadcrumbLink>
  </BreadcrumbItem>
);
