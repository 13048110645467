import { Api } from 'BootQuery/Assets/js/api';

import { ItemsBySelect, SelectEditorSettings, SelectItem } from './types';

interface SelectItemsResult {
  selectId: string;
  items: SelectItem[];
}

function itemsToMap(items: SelectItemsResult[]): ItemsBySelect {
  return items.reduce(
    (itemMap, select) => ({
      ...itemMap,
      [select.selectId]: select.items,
    }),
    {}
  );
}

type SelectInfo = Pick<SelectEditorSettings, 'id' | 'listID' | 'new'>;

interface ApiSelectItem {
  name: string;
  key: string;
  parentItem: { key: string } | null;
}

export async function fetchSelectItems(
  select: SelectInfo
): Promise<SelectItemsResult> {
  const { id, listID } = select;

  const { data: items } = await Api.get<ApiSelectItem[]>(
    `/api/forms/customLists/${listID}/items`,
    {
      params: {
        fields: ['key', 'name', 'parentItem.key'],
        // Arbitary limit, but is wise to put at least *some* limit.
        // We had some stuff synced from other systems with 100k+ items
        limit: 1000,
      },
    }
  );

  return {
    selectId: id,
    items: items.map((item) => ({
      key: item.key,
      name: item.name,
      parentItemId: item.parentItem?.key,
      extraData: {},
    })),
  };
}

export async function fetchAllSelectItems(
  selects: SelectInfo[]
): Promise<ItemsBySelect> {
  const itemReqs = selects
    .filter((select) => !select.new)
    .map(fetchSelectItems);

  const allItems = await Promise.all(itemReqs);

  return itemsToMap(allItems);
}
