import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { ContactSelect } from '@bq/components/FormFields';
import { useMemo } from 'react';
import { Contact } from 'app/Modules/Telephony/Assets/types';
import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

interface Props {
  isDisabled?: boolean;
}
export const SupplierField = ({
  formControlProps,
  isDisabled,
}: FieldProps & Props) => {
  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
  } = useFormContext<ItemBaseFields>();
  const {
    field: { value, onChange },
  } = useController({
    name: 'suppliers',
    control,
  });

  /** 🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡  */
  const parseStupidValue: Contact[] = useMemo(() => {
    if (value) {
      return value.map((item) => {
        if ('name' in item) {
          return { ID: item.ID, name: item.name, type: 'company' };
        }

        return {
          ID: item.supplierCompany.ID,
          name: item.supplierCompany.name,
          type: 'company',
        };
      });
    }

    return [];
  }, [value]);

  return (
    <FormControl isInvalid={!!errors.suppliers} {...formControlProps}>
      <FormLabel htmlFor="supplier">{t('Products:suppliers')}</FormLabel>
      <ContactSelect
        isMulti={true}
        createMode="saveInForm"
        hasDetails={true}
        hasEditModal={true}
        isClearable={true}
        onChange={(value) => {
          onChange(value);
        }}
        value={parseStupidValue}
        allowedTypes={{
          company: true,
          person: false,
          companyDepartment: false,
          companyLocation: false,
          user: false,
        }}
        isDisabled={isDisabled}
      />
    </FormControl>
  );
};
