import { Box, HStack, Text } from '@chakra-ui/react';
import { FaCog, FaComments, FaEnvelope, FaPhone } from 'react-icons/fa';

import { CellProps } from '@bq/components/Table/types';

import { Ticket } from '../../../types';
import { translateReportMethodName } from '../../../utils';

export const TicketReportMethodCell = (props: CellProps<Ticket>) => {
  if (props.row.reportMethod) {
    const { isSystem, name } = props.row.reportMethod;
    if (isSystem) {
      return (
        <HStack>
          {IconForSystemReportMethod(name)}
          <Text>{translateReportMethodName(name)}</Text>
        </HStack>
      );
    }

    return <Box>{name}</Box>;
  }

  return <>-</>;
};

const IconForSystemReportMethod = (name: string) => {
  switch (name) {
    case 'email':
      return <FaEnvelope />;
    case 'phone':
      return <FaPhone />;
    case 'chat':
      return <FaComments />;
    default:
      return <FaCog />;
  }
};
