import { HStack, MenuGroup, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useListingSettingsContext } from '../../TicketingListingSettingsProvider';
import { SortMenu } from './SortMenu';

interface Props {
  isMobile: boolean;
  displayMode: string;
}

export const CardViewSort = ({ isMobile, displayMode }: Props) => {
  const listingSettings = useListingSettingsContext();
  const { setSort, sort, allColumns } = listingSettings;
  const { t } = useTranslation();
  const color = useColorModeValue('blackAlpha.800', 'whiteAlpha.700');

  if (isMobile || displayMode === 'card') {
    return (
      <MenuGroup title={t('Ticketing:sort_by')}>
        <HStack w="full">
          <SortMenu
            color={color}
            fontSize="sm"
            sort={sort}
            setSort={setSort}
            columns={allColumns}
          />
        </HStack>
      </MenuGroup>
    );
  }

  return <></>;
};
