import { matchFileType } from 'BootQuery/Assets/js/helpers';

import { FileError, FileErrorType } from './types';

export function validateFile(
  file: File,
  maxSize: number | null,
  accept: string[] | string | null
): FileErrorType | null {
  if (maxSize && file.size > maxSize) {
    return 'too_large';
  }

  if (accept && !matchFileType(accept, file)) {
    return 'wrong_format';
  }

  return null;
}

interface FileValidationResults {
  valid: File[];
  invalid: FileError[];
}

export function validateFiles(
  files: File[],
  maxSize: number | null,
  accept: string[] | string | null
): FileValidationResults {
  const initial: FileValidationResults = {
    valid: [],
    invalid: [],
  };

  return files.reduce((results: FileValidationResults, file: File) => {
    const error = validateFile(file, maxSize, accept);
    if (error) {
      const withError = { file, error };

      return {
        valid: results.valid,
        invalid: [...results.invalid, withError],
      };
    }

    return {
      valid: [...results.valid, file],
      invalid: results.invalid,
    };
  }, initial);
}

// Match a dot followed by a sequence of anything that is not a dot
// Used to match the content after the last dot.
const MATCH_AFTER_LAST_DOT = /[.]([^.]+)$/;

export const getFileExtension = (filename: string): string | null => {
  const matched = filename.match(MATCH_AFTER_LAST_DOT);

  return matched?.[1] ?? null;
};
