module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<option value=\"10\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"limit10") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":51}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.10_results_per_page",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":87}}}))
    + "</option>\n		<option value=\"20\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"limit20") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":21},"end":{"line":5,"column":51}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.20_results_per_page",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":52},"end":{"line":5,"column":87}}}))
    + "</option>\n		<option value=\"30\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"limit30") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":21},"end":{"line":6,"column":51}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.30_results_per_page",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":87}}}))
    + "</option>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n	<select id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-limit-selector\" class=\"pickle limit-selector\" data-searchable=\"false\" name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "Limit\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"resultdata") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "	</select>\n</div>\n";
},"useData":true})