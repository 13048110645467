// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactNode } from 'react';

import { IconButton } from 'BootQuery/Assets/components/IconButton';

import { useFocusProvider } from '../FocusContext';
import { useRichText } from '../RichtextContex';
import { RichTextButtonInterface } from '../types';

/**
 * Template for creating RichText buttons, it is advised to use this when you create new buttons for
 * RichText editor. This covers most of the cases and if you need something not covered by this
 * you should create your custom button.
 * @param {string} label Descripitive text displayed when button is hovered, usually
 * translation provided by const {t} = useTranslation();
 * @param {ReactNode} icon Icon that is displayed inside the button, usually imported
 * from react-icons
 * @param {string=} property https://tiptap.dev/api/editor#is-active name of active property, optional
 * @param {boolean=} [disabled=false] Is the button disabled
 * @param {(shouldFocus: boolean)=>void} action function that trigger when the button is pressed,
 * e.g. () => editor.commands.toggleBold(). Check the documentation of tiptap for Editor Commands,
 * should always provide function that takes in should focus, the non focus version is used for
 * keyboard commands, while the focus version is used for onclick version
 * that you need. Documentation for aforementioned Bold can be found at https://tiptap.dev/api/marks/bold#commands
 */
const RichTextButton = ({
  label,
  icon,
  property,
  disabled = false,
  action,
}: RichTextButtonInterface) => {
  const { editor } = useRichText();
  const { tabIndex, handleBlur, handleEscapingToolbar } =
    useFocusProvider();

  return (
    <IconButton
      tabIndex={tabIndex}
      onMouseDown={() => {
        action(true);
      }}
      onKeyDown={(event) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
          action(false);
        }
        if (event.key === 'Escape') {
          handleEscapingToolbar();
        }
      }}
      onBlur={handleBlur}
      isDisabled={disabled}
      isActive={(property && editor?.isActive(property)) || false}
      label={label}
      icon={icon}
    />
  );
};

export default React.memo(RichTextButton);
