import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, ReactElement, useCallback } from 'react';
import { FaGripVertical } from 'react-icons/fa';
import { DeleteButton } from '../DeleteButton';
import { EditorFieldTitle } from './EditorFieldTitle';
import { SettingsPopover } from './FieldSettings';
import { useFormEditorContext } from './FormEditorContext';
import { BaseSettings, EditorFieldComponentProps } from './types';
import { useFieldType } from './use-field-type';

const EditorField = <ST extends BaseSettings>(
  props: EditorFieldComponentProps<ST>
): ReactElement => {
  const { id, content } = props;
  const fieldType = useFieldType<ST>(content.type);
  const { removeField } = useFormEditorContext();
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    data: props,
  });
  const opacity = isDragging ? 0.5 : 1.0;

  const onRemove = useCallback(() => {
    removeField(id);
  }, [removeField, id]);

  return (
    <Flex
      borderBottomWidth="thin"
      alignItems="center"
      bg={useColorModeValue('brand.background', 'brand.backgroundDark')}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity,
      }}
    >
      <Box
        flex="0 1 auto"
        px="2"
        py="2"
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        <FaGripVertical />
      </Box>
      {fieldType.components?.EditorFieldOuter ? (
        <fieldType.components.EditorFieldOuter {...props} />
      ) : (
        <>
          <Flex flex="1 0 auto" px="2" py="2">
            <EditorFieldTitle
              {...(props as unknown as EditorFieldComponentProps<BaseSettings>)}
            />
          </Flex>
          <Flex flex="1 0 auto">
            {fieldType.components?.Editor && (
              <fieldType.components.Editor {...props} />
            )}
          </Flex>
        </>
      )}
      <Box flex="0 0 auto" px="2" py="2">
        <DeleteButton size="sm" onClick={onRemove} />
        &nbsp;
        <SettingsPopover {...props} />
      </Box>
    </Flex>
  );
};

const EditorFieldMemo = memo(EditorField) as typeof EditorField;

export { EditorFieldMemo as EditorField };
