import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  VStack,
} from '@chakra-ui/react';

import { IOverviewEditorItem } from '../Overviews';
import { useOpenItems } from './OpenItemsContext';
import { useOverviewsContext } from './OverviewsContext';
import { TreeLink } from './TreeLink';
import { OverviewSearchResult } from './types';

interface Props {
  items: OverviewSearchResult[];
  path: string;
  parent: string;
}
export interface SplitItems {
  withChild: OverviewSearchResult[];
  noChild: OverviewSearchResult[];
}

interface ItemProps extends OverviewSearchResult {
  toggleAccordionManually: (isExpanded: boolean, idx: number) => void;
  parentPath: string;
  accordionIdx: number;
}

interface AccordionIdxItem extends OverviewSearchResult {
  accordionIdx?: number;
}

function hasChildren(item: IOverviewEditorItem): boolean {
  return !!item.overviews && item.overviews.length > 0;
}

const RenderItem = memo(
  ({
    parentPath,
    accordionIdx,
    toggleAccordionManually,

    ...item
  }: ItemProps): ReactElement => {
    if (hasChildren(item)) {
      return (
        <AccordionItem key={item.slug} border="none" px={0} ml="-8px">
          {({ isExpanded }) => (
            <>
              <AccordionButton
                px={0}
                m={0}
                onClick={() => {
                  toggleAccordionManually(isExpanded, accordionIdx);
                }}
              >
                <AccordionIcon
                  color="brand.300"
                  fontSize={['3xl', '3xl', 'xl', 'xl']}
                  transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
                  _hover={{
                    color: 'brand.50',
                  }}
                />
                <TreeLink
                  path={parentPath}
                  title={item.title}
                  slug={item.slug}
                  h="full"
                  w="full"
                  pl={2}
                />
              </AccordionButton>
              <AccordionPanel
                m={0}
                p={0}
                ml="10px"
                borderLeft="1px solid var(--chakra-colors-brand-300)"
              >
                {item.overviews && (
                  <TreeItem
                    items={item.overviews}
                    path={parentPath}
                    parent={item.slug}
                  />
                )}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      );
    }

    return (
      <Box key={item.slug} mb={0} pl="15px">
        <TreeLink
          slug={item.slug}
          title={item.title}
          path={parentPath}
          variant="list-item"
        />
      </Box>
    );
  }
);
RenderItem.displayName = 'RenderItem';

function useIndexedAccordionItems(
  items: OverviewSearchResult[]
): AccordionIdxItem[] {
  return useMemo(() => {
    let accordionCounter = 0;

    return items.map((item): AccordionIdxItem => {
      if (hasChildren(item)) {
        return { ...item, accordionIdx: accordionCounter++ };
      }

      return item;
    });
  }, [items]);
}

const TreeItem = memo(({ items, path, parent }: Props) => {
  const { childlessFirst } = useOverviewsContext();
  const { openItems, setOpenItems } = useOpenItems();
  const [localOpenItems, setLocalOpenItems] = useState(openItems[parent] ?? []);
  const splitItems = useMemo(() => {
    const initial: SplitItems = {
      withChild: [],
      noChild: [],
    };

    return items.reduce((result, element) => {
      result[hasChildren(element) ? 'withChild' : 'noChild'].push(element);

      return result;
    }, initial);
  }, [items]);

  const toggleAccordionManually = useCallback(
    (isExpanded: boolean, idx: number) => {
      if (isExpanded) {
        setLocalOpenItems((prev) => prev.filter((item) => item !== idx));
        setOpenItems((prev) => {
          const prevVal = prev[parent] ?? [];

          return { ...prev, [parent]: prevVal.filter((item) => item !== idx) };
        });
      } else {
        setLocalOpenItems((prev) => [...prev, idx]);
        setOpenItems((prev) => {
          const prevVal = prev[parent] ?? [];

          return { ...prev, [parent]: [...prevVal, idx] };
        });
      }
    },
    [parent, setOpenItems]
  );
  useEffect(() => {
    setLocalOpenItems(openItems[parent] ?? []);
  }, [openItems, parent]);

  const allItems: AccordionIdxItem[] = useMemo(() => {
    if (childlessFirst) {
      return [...splitItems.noChild, ...splitItems.withChild];
    }

    return items;
  }, [childlessFirst, items, splitItems]);
  const indexedItems = useIndexedAccordionItems(allItems);

  return (
    <VStack w="full" boxSizing="border-box">
      <Accordion
        allowMultiple
        w="full"
        p={0}
        m={0}
        pl={4}
        index={localOpenItems}
      >
        {indexedItems.map((item) => (
          <RenderItem
            key={item.slug}
            {...item}
            accordionIdx={item.accordionIdx ?? 0}
            parentPath={path}
            toggleAccordionManually={toggleAccordionManually}
          />
        ))}
      </Accordion>
    </VStack>
  );
});
TreeItem.displayName = 'TreeItem';

export { TreeItem };
