import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import { CustomData } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { DateIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { DateTimeField } from './DateTimeField';
import { StaticDateTimeField } from './StaticDateTimeField';

function loadVal(val: unknown): Date | null {
  if (typeof val === 'string' && val !== '') {
    return new Date(val);
  }

  return val instanceof Date ? val : null;
}

export const dateTimeField = (): FieldType => ({
  type: 'datetime',
  name: i18n.t('global:form_editor.field_datetime'),
  icon: DateIcon,
  components: {
    FormField: DateTimeField,
    StaticFormField: StaticDateTimeField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  loadValue: (field, formValues) => ({
    ...formValues,
    [field.id]: loadVal(formValues[field.id]),
  }),
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        sort: `data.${field.id}`,
        getValue: ({ row }) => {
          if (!row.data) {
            return '-';
          }

          const value = (row.data as CustomData)[field.id];
          if (!value) {
            return '-';
          }

          return formatDate(value, DateType.DateTime);
        },
      },
    ];
  },
  getFilters(field) {
    return {
      [`$custom-${field.id}`]: makeDateFilter({
        name: field.settings.name,
        // Since ISO strings are purposely designed to be properly ordered
        // in string format, we can just let the backend compare strings directly
        toFilter: makeDateToFilter(`data.${field.id}`),
      }),
    };
  },
});
