import { useEffect, useState } from 'react';
import { ActionMeta, AsyncSelect } from 'chakra-react-select';

import { selectStyles } from '@bq/components/Select';

import { searchContacts } from './API/searchContacts';
import { ContactMultiValue } from './Components/ContactMultiValue';
import { ContactOption } from './Components/ContactOption';
import { ContactValue } from './Components/ContactValue';
import { MultiContactSelectProvider } from './Components/MultiContactSelectCtx';
import {
  ContactSelectMultiProps,
  ContactSelectMultiValue,
  ContactSelectOption,
} from './types';
import { useHandleOnChangeSingleSelect } from './use-handle-on-change-single-select';
import { loadMultiValue } from './utils';

export const MultiContactSelect = (props: ContactSelectMultiProps) => {
  const {
    onChange,
    value,
    allowedTypes = {
      company: true,
      person: true,
      companyLocation: false,
      companyDepartment: false,
      user: false,
    },
    createMode = 'saveInForm',
    isDisabled,
    isClearable,
    isInvalid = false,
    hasManualOption = false,
  } = props;
  /* const { data, isLoading } = useQuery({
    queryKey: [value],
    queryFn: async () => loadMultiValue(value),
  }); */
  const [currentItems, setCurrentItems] =
    useState<ContactSelectMultiValue>(value);

  useEffect(() => {
    onChange(currentItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItems]);

  const handleChange = useHandleOnChangeSingleSelect();

  return (
    <MultiContactSelectProvider {...props}>
      <AsyncSelect<ContactSelectOption, true>
        isMulti={true}
        isInvalid={isInvalid}
        selectedOptionColorScheme="brand"
        isDisabled={isDisabled}
        value={loadMultiValue(value)}
        size={{ base: 'lg', md: 'sm', lg: 'sm' }}
        useBasicStyles
        components={{
          Option: ContactOption,
          SingleValue: ContactValue,
          MultiValue: ContactMultiValue,
        }}
        isClearable={isClearable}
        isSearchable
        defaultOptions
        cacheOptions
        loadOptions={async (search: string) => {
          const data = await searchContacts({
            search,
            allowedTypes,
            nonCreatable: createMode === 'none',
            noManual: !hasManualOption,
          });

          return data;
        }}
        chakraStyles={selectStyles}
        onChange={(_newValue, actionMeta) => {
          if (actionMeta.action === 'clear') {
            setCurrentItems([]);
          }
          if (
            actionMeta.action === 'deselect-option' ||
            actionMeta.action === 'pop-value' ||
            actionMeta.action === 'remove-value'
          ) {
            setCurrentItems((prev) => handleDeleting(prev, actionMeta));
          }
          if (actionMeta.action === 'select-option') {
            if (actionMeta.option) {
              handleChange(
                actionMeta.option,
                (newVal) => {
                  if (newVal) {
                    setCurrentItems((prev) => [...prev, newVal]);
                  }
                },
                createMode
              );
            }
          }
        }}
      />
    </MultiContactSelectProvider>
  );
};

const handleDeleting = (
  prev: ContactSelectMultiValue,
  actionMeta: ActionMeta<ContactSelectOption>
) => {
  return prev.filter((item) => {
    const deleting: ContactSelectOption[] = [
      ...(actionMeta.removedValue ? [actionMeta.removedValue] : []),
      ...(actionMeta.removedValues ? actionMeta.removedValues : []),
    ];
    const shouldFilter = deleting.reduce((all, current) => {
      if (
        '$new' in item &&
        current.value === item.$new &&
        current.type === item.type
      ) {
        return all + 1;
      }
      if (
        !('$new' in item) &&
        current.value === item.ID &&
        current.type === item.type
      ) {
        return all + 1;
      }

      return all;
    }, 0);

    return shouldFilter === 0;
  });
};
