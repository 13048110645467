import VueRouter from 'vue-router';
import { trim } from 'lodash-es';

const ChatList = () => import(/* webpackChunkName: "facebook-chat" */ '../components/ChatList.vue');
const ChatView = () => import(/* webpackChunkName: "facebook-chat" */ '../components/ChatView.vue');

// Export as factory function so we construct a new instance every time
// Otherwise, other app links won't refresh the router's internal stuff
// And it will think it's on another page
export default function getRouter() {
  const router = new VueRouter({
    mode: 'history',
    base: '/facebookChat/',
    routes: [
      {
        path: '/chats/:chatID?',
        components: {
          chatList: ChatList,
          chatView: ChatView,
        },
      },
    ],
  });

  router.afterEach((to, _from) => {
    const method = trim(to.path, '/');
    window.Bootstrap.bootquery.method = method;
    window.Bootstrap.bootquery.parameters = to.query;
  });

  return router;
}
