/* eslint-disable no-underscore-dangle */
import { SystemProps, useMultiStyleConfig } from '@chakra-ui/react';

export function useInputStyle(): SystemProps {
  const style = useMultiStyleConfig('Input', {});
  const inputStyle = style.field as SystemProps;

  return {
    _hover: inputStyle._hover,
    _focusWithin: inputStyle._focus,
    border: inputStyle.border as string,
    borderColor: inputStyle.borderColor as string,
  };
}
