import { PropsWithChildren, ReactElement } from 'react';
import { Tag, TagLeftIcon } from '@chakra-ui/react';
import { AiOutlineMinus } from 'react-icons/ai';

export const ChangeFrom = ({ children }: PropsWithChildren): ReactElement => (
  <Tag colorScheme="red">
    <TagLeftIcon as={AiOutlineMinus} />
    {children}
  </Tag>
);
