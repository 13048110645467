import {
  ColumnMap,
  RefreshMethod,
} from 'BootQuery/Assets/components/TableMenu';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

export function useColumnVisibility(defaultVal: ColumnMap = {}) {
  return useUserSetting('Telephony.CallList.card.columns', defaultVal, false);
}

export function useLimit(defaultLimit = 10) {
  return useUserSetting('Telephony.CallList.card.limit', defaultLimit, false);
}

export function useRefreshMethod(defaultMethod: RefreshMethod = 'auto') {
  return useUserSetting(
    'Telephony.CallList.card.refreshMethod',
    defaultMethod,
    false
  );
}
