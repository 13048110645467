import { ReactElement } from 'react';
import { Button, ButtonProps, chakra } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';

export interface ExpandToggleProps extends ButtonProps {
  expanded: boolean;
}

export const ExpandToggle = ({
  expanded,
  ...props
}: ExpandToggleProps): ReactElement => {
  const { t } = useTranslation('Mails');

  return expanded ? (
    <CollapseButton variant="unstyled" {...props}>
      {t('global:hide')}&nbsp;
      <FaArrowUp />
    </CollapseButton>
  ) : (
    <ExpandButton variant="unstyled" {...props}>
      {t('global:show')}&nbsp;
      <FaArrowDown />
    </ExpandButton>
  );
};

const baseButtonStyle = {
  position: 'absolute',
  display: 'flex',
  bottom: 0,
  left: 0,
  right: 0,
  borderRadius: 0,
  width: 'full',
};

const ExpandButton = chakra(Button, {
  baseStyle: {
    ...baseButtonStyle,
    height: 'full',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)',
    _hover: {
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%)',
    },
  },
});

const CollapseButton = chakra(Button, {
  baseStyle: {
    ...baseButtonStyle,
    _hover: {
      background: 'rgba(0, 0, 0, 0.2)',
    },
  },
});
