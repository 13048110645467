import { FC, CSSProperties, useMemo } from 'react';
import { Box, MenuOptionGroup, MenuOptionGroupProps } from '@chakra-ui/react';

export const ScrollableMenuOptionGroup: FC<MenuOptionGroupProps> = ({
  children,
  ...props
}) => {
  // ChakraUI applies the style from <MenuOptionGroup /> on the header element
  // instead of the actual container
  const style = useMemo<CSSProperties>(
    () => ({
      position: 'sticky',
      top: 0,
    }),
    []
  );

  return (
    <Box overflowY="auto">
      <MenuOptionGroup style={style} {...props}>
        {children}
      </MenuOptionGroup>
    </Box>
  );
};
