import { ReactElement } from 'react';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';

import { CellProps } from 'BootQuery/Assets/components/Table';

interface Props<RowType> {
  getCellValue: (row: RowType, rowIdx: number) => boolean | null | undefined;
}

export function makeCheckboxCell<RowType>({ getCellValue }: Props<RowType>) {
  const CheckboxCell = ({ row, rowIdx }: CellProps<RowType>): ReactElement => {
    const value = getCellValue(row, rowIdx);

    return value ? (
      <FaRegCheckSquare style={svgStyle} />
    ) : (
      <FaRegSquare style={svgStyle} />
    );
  };
  CheckboxCell.displayName = 'CheckboxCell';

  return CheckboxCell;
}

const svgStyle = { display: 'inline' };
