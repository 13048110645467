import { Fragment, ReactElement, useCallback, useMemo } from 'react';
import { Box, Center, Text } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Card, CardBody } from '@bq/components/Card';
import {
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { ListWrapper } from '@bq/components/ListWrapper';
import { useScrollEvents } from '@bq/components/scroll-events';
import { CardBulkActionMenu } from '@bq/components/Table/CardBulkActionMenu';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { TableSelectionProvider } from 'BootQuery/Assets/components/Table';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { columns, personFilterTypes } from '../PersonList';
import { personCardFeatures } from '../PersonList/columns';
import { getCardPeople } from '../PersonList/get-people';
import { deleteItem } from '../PersonList/person-list-bulk-actions';
import { PersonCard } from '../PersonList/PersonCard';
import { PersonCardFeatures } from '../PersonList/types';
import { usePhonebookContext } from '../PhonebookContext';
import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { sendSms } from './company-employee-list-actions';
import { getFiltersDeparatment } from './CompanyEmployeeTableList';
import { phonebookDisplayModeOptions } from './display-settings';

interface Props {
  company: { ID: number; name: string };
  customFields?: FieldValue[];
  department?: { ID: number; name: string };
  cardFeatures?: Partial<PersonCardFeatures>;
  listingName?: string;
  companyLocation?: { ID: number; name: string };
  permissions: Permissions;
}

export const MobileCompanyEmployeeList = ({
  company,
  customFields = [],
  cardFeatures,
  department,
  listingName = 'Phonebook.CompanyEmployeeList',
  companyLocation,
  permissions,
}: Props): ReactElement => {
  const loaded = useLoadUserSettings(listingName, {});
  const { localCompany } = usePhonebookContext();
  const overViewTree = useSelectedOverview('employees');
  const { t } = useTranslation('Phonebook');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName,
    viewName: 'mobile',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: overViewTree?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { filters, limit, columnsToShow, page } = listingSettings;
  const { columns: customColumns, features } = useSplitColumnsFeatures(
    columnsToShow,
    personCardFeatures
  );

  if (company === undefined && localCompany === undefined) {
    throw Error('No company');
  }

  const { data, refetch, fetchNextPage } = useInfiniteQuery({
    queryKey: [
      `${listingName}.mobile`,
      page,
      filters,
      limit,
      company.ID,
      department?.ID,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const data = await getCardPeople({
        page: pageParam,
        filters: getFiltersDeparatment({
          companyID: company.ID,
          departmentID: department?.ID,
          filters,
          companyLocationID: companyLocation?.ID,
        }),
        limit,
      });
      const maxPages = Math.ceil((data?.meta.count ?? 1) / limit);

      const shouldFetchMore = pageParam < maxPages;

      return { ...data, page: pageParam, shouldFetchMore };
    },

    enabled: filters !== undefined,
    keepPreviousData: true,
    getNextPageParam: (lastPage) => lastPage.page + 1,
  });

  const noPages = data?.pages.length ?? 1;
  const shouldFetchMore = data?.pages[noPages - 1]?.shouldFetchMore;

  useScrollEvents(
    'scrollBottom',
    useCallback(() => {
      fetchNextPage();
    }, [fetchNextPage])
  );

  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      { actions: [sendSms(navigate)], styleProps: { colorScheme: 'brand' } },
      {
        actions: [deleteItem(refetch)],
        styleProps: {
          colorScheme: 'red',
          variant: 'outline',
          style: {
            marginLeft: 'auto',
          },
        },
      },
    ],
    [navigate, refetch]
  );
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: null,
    noPagination: true,
    displayModeOptions: phonebookDisplayModeOptions,
  });

  if (!data || !customFields || !loaded) {
    return <LoadingPage />;
  }

  if (company === undefined && localCompany === undefined) {
    throw Error('No Company');
  }

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<PersonApiData>
        page={1}
        idColumn="ID"
        selectPage={() => data?.pages[page]?.data ?? []}
        selectAll={async () => {
          const { data } = await getCardPeople({
            fields: [
              'ID',
              'firstName',
              'lastName',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],
            page: 1,
            limit: 99999,
            filters,
          });

          return data;
        }}
      >
        <ListWrapper w="full" spacing="5">
          <Card>
            <CardBody>
              <ListHeading
                {...headingProps}
                menuProps={{
                  ...headingProps.menuProps,
                  displayMode: undefined,
                  density: undefined,
                }}
                Actions={
                  <Actions
                    company={
                      // this WILL NEVER show but TS is retarded so leaving it
                      company ?? localCompany ?? { ID: 0, name: 'DEFAULT' }
                    }
                  />
                }
              />
            </CardBody>
          </Card>

          <Box
            display="flex"
            w="full"
            left="0"
            position="fixed"
            bottom="75px"
            zIndex="4"
          >
            <CardBulkActionMenu<PersonApiData> actionGroups={bulkActions} />
          </Box>

          {data.pages.map((page, idx) => (
            <Fragment key={idx}>
              {page.data.map((person) => (
                <PersonCard
                  key={person.ID}
                  person={person}
                  customColumns={customColumns}
                  features={{ ...features, ...cardFeatures }}
                />
              ))}
            </Fragment>
          ))}
          {!shouldFetchMore && (
            <Center pt="2" m={0} mb={4}>
              <Text
                color="GrayText"
                textAlign="center"
                fontWeight="bold"
                fontSize="xl"
                m={0}
              >
                {`${t('global:no_more_results')}...`}
              </Text>
            </Center>
          )}
        </ListWrapper>
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
