module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"template") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <tr>\n            <td>\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n            </td>\n            <td>\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"price") : depth0), depth0))
    + "\n            </td>\n          </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<html lang='en'>\n  <head>\n    <meta charset='UTF-8' />\n    <meta name='viewport' content='width=device-width, initial-scale=1.0' />\n    <title>Test ugovor</title>\n  </head>\n  <body>\n    <h1>Test ugovor</h1>\n    Kompanija:\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"firma") : stack1), depth0))
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias3,(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "    <table border='1'>\n      <thead>\n        <tr>\n          <th>Ime</th>\n          <th>Cijena</th>\n        </tr>\n      </thead>\n      <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"result") : depth0)) != null ? lookupProperty(stack1,"proizvodovi") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":8},"end":{"line":32,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n\n    </table>\n    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"template") : depth0), depth0))
    + "\n  </body>\n</html>";
},"useData":true})