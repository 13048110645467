import { UseQueryResult, useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { BlacklistItem } from './types';

export function useBlacklistItems(): UseQueryResult<BlacklistItem[]> {
  return useQuery({
    queryFn: getBlacklistItems,
    queryKey: ['Telephony.settings.blacklistItems'],
  });
}

export async function getBlacklistItems(): Promise<BlacklistItem[]> {
  const { data } = await Api.get<BlacklistItem[]>('/api/telephony/blacklist');

  return data;
}
