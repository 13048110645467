import {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FieldValue, useFormDefinition } from '@bq/components/FormEditor';
import { FilterEditor } from 'BootQuery/Assets/components/FilterBar/FilterEditor';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { useFilterTypes } from '../CallList/filter-types';
import { PermissionsValue } from './types';
import { getCurrentFilterPermissions, setFilterInputValue } from './util';

interface Props {
  groupID: number | string;
  isOpen: boolean;
  onClose: () => void;
}

interface EditorContentProps {
  value: PermissionsValue;
  onChange: Dispatch<SetStateAction<PermissionsValue | null>>;
  customFields: FieldValue[];
}

const EditorContent = ({
  value,
  onChange,
  customFields,
}: EditorContentProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  const filterTypes = useFilterTypes(customFields);
  const setNone = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(ev.target.checked ? [] : 'none');
    },
    [onChange]
  );

  return (
    <>
      <Checkbox isChecked={value !== 'none'} onChange={setNone}>
        {t('Telephony:permissions.can_access_recordings')}
      </Checkbox>
      {value !== 'none' && (
        <>
          <Heading mt="3" as="h1" fontSize="xl">
            {t('Telephony:permissions.recording_filter')}:
          </Heading>
          <FilterEditor
            filterTypes={filterTypes}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};

export const RecordingPermissionsModal = ({
  isOpen,
  onClose,
  groupID,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const customFields = useFormDefinition('Telephony.callForm');

  const [value, setValue] = useState<PermissionsValue | null>(null);
  const cancel = () => {
    console.debug('cancel');
    setValue(null);
    onClose();
  };
  const submit = () => {
    console.debug('submit', value);
    if (value) {
      setFilterInputValue('recordings', value);
    }
    onClose();
  };

  const noValue = value === null;
  useEffect(() => {
    if (noValue) {
      getCurrentFilterPermissions('recordings', groupID).then(setValue);
    }
  }, [groupID, noValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancel}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('Telephony:permissions.recording_visibility')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {value !== null && customFields ? (
            <EditorContent
              value={value}
              onChange={setValue}
              customFields={customFields}
            />
          ) : (
            <LoadingPage />
          )}
        </ModalBody>
        {value !== null && (
          <ModalFooter justifyContent="flex-end">
            <FormActions onSubmit={submit} onCancel={cancel} />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
