import mitt from 'mitt';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

export interface CloseNoCallbackEventData {
  modalID: string;
  noPreventClose?: boolean;
}

export interface CloseEventData<CallbackData> {
  modalID: string;
  data: CallbackData;
  noPreventClose?: boolean;
}

export interface NoCloseCallback<CallbackData> {
  modalID: string;
  data: CallbackData;
}

export type ModalEvents<CallbackData> = {
  closeWithCallback: CloseEventData<CallbackData>;
  closeNoCallback: CloseNoCallbackEventData;
  noCloseCallback: NoCloseCallback<CallbackData>;
};
/** We don't really care about the type of this because when we actually use it we type it */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const modalEvents = mitt<ModalEvents<any>>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useModalEvents = createUseMitt<ModalEvents<any>>(modalEvents);

export const onCloseEvent = <CallbackData>(
  callbackData: CloseEventData<CallbackData>
) => {
  modalEvents.emit('closeWithCallback', callbackData);
};
export const onCloseWithNoCallbackEvent = (
  callbackData: CloseNoCallbackEventData
) => {
  modalEvents.emit('closeNoCallback', callbackData);
};

export const noCloseCallbackEvent = <CallbackData>(
  callbackData: CloseEventData<CallbackData>
) => {
  modalEvents.emit('noCloseCallback', callbackData);
};
