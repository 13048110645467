import { ReactElement, ForwardedRef, useMemo, forwardRef, memo } from 'react';
import {
  Flex,
  BoxProps,
  Heading,
  HStack,
  VStack,
  Link,
  LinkBox,
  LinkOverlay,
  useColorModeValue,
  Text,
  Divider,
} from '@chakra-ui/react';
import { Card } from 'BootQuery/Assets/components/Card';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { Column } from 'BootQuery/Assets/components/Table';
import ReactFastCompare from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { CompanyLocationApiData } from '../../types';
import { useExtenState } from '../../use-exten-state';
import { PhonebookCardBody } from '../../PhonebookCardBody';
import { ContactSelect } from '../../ContactSelect';
import { EditButton } from '../../EditButton';
import { NumberList } from '../../NumberList';
import { EmailList } from '../../EmailList';
import { ExtenStateIndicator } from '../../ExtenStateIndicator';
import { CompanyCardFeatures } from '../../CompanyList/types';
import { CardCustomColumns } from '../../PersonList/CardCustomColumns';

interface CompanyCardProps {
  location: CompanyLocationApiData;
  companyID: number | undefined;
  features?: Partial<CompanyCardFeatures>;
  topRight?: (props: CompanyCardProps) => ReactElement;
  cardLink?: boolean;
  cardProps?: BoxProps;
  customColumns?: Column<CompanyLocationApiData>[];
}

const defaultFeatures: CompanyCardFeatures = {
  phoneNumbers: true,
  emails: true,
  edit: true,
  select: true,
  name: true,
};

const CompanyLocationCardTitle = ({
  location,
  companyID,
  cardLink = true,
}: CompanyCardProps): ReactElement => {
  const color = useColorModeValue('#3C485C', '#F5F5F5');
  const heading = (
    <Heading
      fontSize="lg"
      mb={0}
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      color={color}
      ml="26px"
    >
      {location.name}
    </Heading>
  );

  return cardLink ? (
    <LinkOverlay
      as={RouterLink}
      to={`/phonebook/company-locations/${location.ID}`}
      overflow="hidden"
      w="full"
    >
      {heading}
    </LinkOverlay>
  ) : (
    <Link
      as={RouterLink}
      to={`/phonebook/company-locations/${location.ID}`}
      overflow="hidden"
      w="full"
    >
      {heading}
    </Link>
  );
};

const CompanyCardContent = (props: CompanyCardProps): ReactElement => {
  const {
    location,
    customColumns,
    features,
    companyID = {},
    topRight: TopRightEl,
  } = props;
  const { t } = useTranslation('Phonebook');

  const devState = useExtenState(location.phoneNumbers);

  const feats: CompanyCardFeatures = useMemo(
    () => ({ ...defaultFeatures, ...features }),
    [features]
  );
  const bg = useColorModeValue('white', 'brand.backgroundDark');
  const color = useColorModeValue('#3C485C', '#F5F5F5');

  return (
    <PhonebookCardBody bg={bg} color={color} p={4}>
      <VStack w="full" spacing="2">
        <Flex
          w="full"
          justifyContent="space-between"
          alignItems="center"
          pos="relative"
        >
          {feats.select && (
            <ContactSelect<CompanyLocationApiData> {...location} />
          )}
          <CompanyLocationCardTitle {...props} />
          <HStack alignItems="center">
            {feats.edit && (
              <EditButton
                href={`/phonebook/companies/${companyID}/locations/${location.ID}/edit`}
              />
            )}
            {TopRightEl && <TopRightEl {...props} />}
          </HStack>
        </Flex>
        <Divider
          borderColor="brand.600"
          color="brand.600"
          borderWidth="1px"
        />
        {feats.phoneNumbers && (
          <Flex w="full" alignItems="center" overflow="hidden">
            <FaPhone />
            &nbsp;
            {location.phoneNumbers.length > 0 ? (
              <NumberList phoneNumbers={location.phoneNumbers} />
            ) : (
              <Text as="span" color="gray.500">
                {t('Phonebook:no_phone_numbers')}
              </Text>
            )}
          </Flex>
        )}
        {feats.emails && (
          <Flex w="full" alignItems="center" overflow="hidden">
            <FaEnvelope />
            &nbsp;
            {location.emails.length > 0 ? (
              <EmailList emails={location.emails} />
            ) : (
              <Text as="span" color="gray.500">
                {t('Phonebook:no_email_addresses')}
              </Text>
            )}
          </Flex>
        )}
        {devState && (
          <Flex w="full" justifyContent="flex-end" marginTop="0 !important">
            <ExtenStateIndicator state={devState} />
          </Flex>
        )}
      </VStack>
      {customColumns && (
        <VStack w="full" mt="5">
          <CardCustomColumns data={location} columns={customColumns} />
        </VStack>
      )}
    </PhonebookCardBody>
  );
};

const CompanyCardInner = (
  props: CompanyCardProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement => {
  const hoverBgColor = useColorModeValue('blue.50', 'blue.900');
  const { cardProps, cardLink = true } = props;

  return cardLink ? (
    <LinkBox
      ref={ref}
      as={Card}
      shadow="base"
      _hover={{ bg: hoverBgColor }}
      {...cardProps}
      borderRadius={0}
    >
      <CompanyCardContent {...props} />
    </LinkBox>
  ) : (
    <Card shadow="base" ref={ref} {...cardProps} borderRadius={0}>
      <CompanyCardContent {...props} />
    </Card>
  );
};

export const CompanyLocationCard = memo(
  forwardRef(CompanyCardInner),
  ReactFastCompare
);
