import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { OBJ } from 'BootQuery/Assets/js/globalTypes';
import { ItemPlaceholdersComponent } from './ItemPlaceholdersComponent';
import { ItemPlaceholderOptions, ItemPlaceholderStorage, Key } from './types';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    itemPlaceholders: {
      /**
       * Comments will be added to the autocomplete.
       */
      toggleShowValues: () => ReturnType;
      addItem: <T extends OBJ = OBJ>(key: Key, config?: T) => ReturnType;
    };
  }
}

export const ItemPlaceholders = Node.create<
  ItemPlaceholderOptions,
  ItemPlaceholderStorage
>({
  name: 'itemPlaceholder',
  addOptions() {
    return {
      templateElements: [],
      values: {},
      inline: true,
      defaultShowValues: false,
    };
  },
  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },
  addStorage() {
    return { showValues: this.options.defaultShowValues };
  },
  addAttributes() {
    return {
      'data-key': {
        default: '',
      },
      'data-config': {
        default: undefined,
      },
      'data-real-values': {
        default: 'false',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'item-placeholder',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['item-placeholder', mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ItemPlaceholdersComponent, { as: 'span' });
  },
  addCommands() {
    return {
      toggleShowValues: () => {
        return ({ state, chain }) => {
          this.storage.showValues = !this.storage.showValues;

          return chain()
            .focus()
            .selectAll()
            .updateAttributes('itemPlaceholder', {
              'data-real-values': String(this.storage.showValues),
            })
            .setTextSelection(state.selection)
            .run();
        };
      },
      addItem: <T>(key: Key, config?: T) => {
        return ({ commands }) => {
          const wasFound = this.options.templateElements.find(
            (item) => item.key === key
          );
          if (!wasFound) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: {
              'data-key': key,
              'data-real-values': String(this.storage.showValues),
              ...(config ? { 'data-config': JSON.stringify(config) } : {}),
            },
          });
        };
      },
    };
  },
});
