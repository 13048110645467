import { Api } from 'BootQuery/Assets/js/api';

import { EventData } from '../types';

export async function addEventToTicket(
  ticketID: number,
  event: EventData
): Promise<void> {
  // Only used to throw the error
  const eventType = event.type;

  switch (event.type) {
    case 'mail':
      await Api.post(`/api/ticketing/tickets/${ticketID}/mails`, event.mailID);
      break;
    case 'call':
      await Api.post(`/api/ticketing/tickets/${ticketID}/calls`, event.callID);
      break;
    case 'chat':
      await Api.post(
        `/api/ticketing/tickets/${ticketID}/customerChats`,
        event.chatID
      );
      break;
    case 'custom':
      await Api.post(
        `/api/ticketing/tickets/${ticketID}/customEvents`,
        event.eventID
      );
      break;
    case 'appointment':
      await Api.post(
        `/api/ticketing/tickets/${ticketID}/appointments`,
        event.appointmentID
      );
      break;
    default:
      throw new Error(`Unknown ticket event type ${eventType}`);
  }
}

export async function removeEventFromTicket(
  ticketID: number,
  event: EventData
): Promise<void> {
  // Only used to throw the error
  const eventType = event.type;

  switch (event.type) {
    case 'mail':
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/mails/${event.mailID}`
      );
      break;
    case 'call':
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/calls/${event.callID}`
      );
      break;
    case 'chat':
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/customerChats/${event.chatID}`
      );
      break;
    case 'custom':
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/customEvents/${event.eventID}`
      );
      break;
    case 'appointment':
      await Api.delete(
        `/api/ticketing/tickets/${ticketID}/appointments/${event.appointmentID}`
      );
      break;
    default:
      throw new Error(`Unknown ticket event type ${eventType}`);
  }
}
