import { Box, Input, Tooltip } from '@chakra-ui/react';
import { ChangeEvent, ReactElement } from 'react';
import { useAutosizingInput } from '../use-autosizing-input';
import { EditorFieldComponentProps } from './types';
import { useFieldType } from './use-field-type';
import { useFieldSettings } from './util';

export function EditorFieldTitle(props: EditorFieldComponentProps): ReactElement {
  const fieldType = useFieldType(props.content.type);

  const { name } = props.content.settings;
  const inputEl = useAutosizingInput(name);

  const { setSetting } = useFieldSettings(props);
  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };

  return (
    <>
      <Tooltip label={fieldType.name} hasArrow>
        <Box>
          <fieldType.icon />
        </Box>
      </Tooltip>
      &nbsp;&nbsp;
      <Input
        ref={inputEl}
        aria-label="Field name"
        onChange={setName}
        value={name}
        variant="unstyled"
        borderStyle="dashed"
        borderWidth="thin"
        borderColor="gray.300"
        _hover={{
          borderColor: 'gray.500',
        }}
        _focus={{
          borderColor: 'gray.900',
        }}
        px="2"
      />
    </>
  );
}
