import { useCallback } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FaCog, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import { DeleteButton } from '@bq/components/DeleteButton';
import { IconButton } from '@bq/components/IconButton';
import { CellProps } from '@bq/components/Table';

import { DatasetListItem } from '../schema';
import { USE_DATASET_LIST_KEY } from '../use-dataset-list';
import { useDeleteDataset } from '../use-delete-dataset';

export const ActionsCell = ({ row }: CellProps<DatasetListItem>) => {
  const { t } = useTranslation('Datasets');
  const queryClient = useQueryClient();
  const confirmAction = useConfirmationModal();
  const { mutateAsync } = useDeleteDataset();

  const deleteAction = useCallback(() => {
    confirmAction({
      results: [`${t('global:delete')}: ${row?.name}`],
      onConfirm: async () => {
        await mutateAsync(row.ID);
        queryClient.refetchQueries({ queryKey: [USE_DATASET_LIST_KEY] });
      },
    });
  }, [confirmAction, t, row, mutateAsync, queryClient]);

  return (
    <ButtonGroup size="sm" zIndex="3" float="right">
      <DeleteButton variant="ghost" icon={<FaTrash />} onClick={deleteAction} />

      <IconButton
        as={Link}
        variant="solid"
        label={t('global:edit')}
        icon={<FaCog />}
        to={`/datasets/dataset/${row.ID}/edit`}
      />
    </ButtonGroup>
  );
};
