import {
  isNotificationClickMessage,
  isNotificationClosedMessage,
} from './is-notification-message';
import { notificationRegistry } from './notification-registry';
import {
  CreateNotificationMessage,
  NotificationHandle,
  NotificationProps,
  TypedNotificationAction,
  WorkerNotificationMeta,
} from './types';

export async function showWorkerNotification(
  handle: NotificationHandle,
  { title, onClick, onClose, ...options }: NotificationProps
): Promise<void> {
  const worker = await maybeGetServiceWorkerRegistration();
  if (!worker || !worker.active) {
    throw new Error('No service worker available');
  }

  // When we create a notification from a service worker, we need to somehow
  // communicate with it because the worker will track the lifecycle
  const channel = new BroadcastChannel('notifications');

  const onMessage = (ev: MessageEvent) => {
    if (isNotificationClickMessage(ev.data) && ev.data.data.handle === handle) {
      onClick?.(ev.data.action);

      return;
    }

    if (
      isNotificationClosedMessage(ev.data) &&
      ev.data.data.handle === handle
    ) {
      delete notificationRegistry[handle];
      channel.removeEventListener('message', onMessage);
      onClose?.();
    }
  };
  channel.addEventListener('message', onMessage);

  // Send the message directly to the worker so it can read which tab (client id)
  // send it and focus it on click.
  worker.active.postMessage({
    type: 'createNotification',
    notification: {
      ...options,
      title,
      data: {
        handle,
        focusingActions: getFocusingActions(options.actions ?? []),
      },
    },
  } satisfies CreateNotificationMessage);

  notificationRegistry[handle] = { type: 'worker', handle };
}

export async function dismissWorkerNotification(
  notificationMeta: WorkerNotificationMeta
) {
  const worker = await maybeGetServiceWorkerRegistration();
  const notifications = await worker?.getNotifications();
  notifications?.forEach((notification) => {
    if (notification.data.handle === notificationMeta.handle) {
      notification.close();
    }
  });
}

export async function checkCanUseWorkers(): Promise<boolean> {
  const worker = await maybeGetServiceWorkerRegistration();

  const hasWorker = !!worker?.active;
  const hasBroadcastChannel = typeof window.BroadcastChannel === 'function';

  return hasWorker && hasBroadcastChannel;
}

async function maybeGetServiceWorkerRegistration(): Promise<ServiceWorkerRegistration | null> {
  if ('serviceWorker' in navigator) {
    return navigator.serviceWorker.ready;
  }

  return null;
}

function getFocusingActions(
  actions: TypedNotificationAction[]
): Record<string, boolean> {
  return actions.reduce(
    (map, action) => ({
      ...map,
      [action.action]: action.focusTabOnClick ?? false,
    }),
    {}
  );
}
