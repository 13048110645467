import {
  makeUserFilter,
  userFilterIDExpr,
} from '@bq/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const responsibleUserFilter = makeUserFilter({
  name: () => i18n.t('Sales:responsible_person'),
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return {
        'responsibleUser.username:contains:ci': value || '',
      };
    }

    const userID = userFilterIDExpr(value);

    return { 'responsibleUser.ID': userID };
  },
});
