import { memo } from 'react';
import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  ComponentWithAs,
  forwardRef,
  Placement,
  Tooltip,
} from '@chakra-ui/react';

import { useIconButtonStyles } from './styles';

export interface IconButtonProps
  extends Omit<ChakraIconButtonProps, 'aria-label'> {
  label: string;
  icon: React.ReactElement;
  tooltipPlacement?: Placement;
  hasArrow?: boolean;
}

// eslint-disable-next-line react/display-name
const IconButtonInner = forwardRef<IconButtonProps, 'div'>(
  ({ label, tooltipPlacement, hasArrow = true, ...props }, ref) => (
    <Tooltip hasArrow={hasArrow} label={label} placement={tooltipPlacement}>
      <ChakraIconButton
        {...useIconButtonStyles(props)}
        {...props}
        aria-label={label}
        ref={ref}
      />
    </Tooltip>
  )
);

export const IconButton = memo(IconButtonInner) as ComponentWithAs<
  'div',
  IconButtonProps
>;
