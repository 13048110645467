import { ReactElement } from 'react';
import { Icon } from '@chakra-ui/react';
import { BsArrowRight } from 'react-icons/bs';

import { useIsMobile } from '@bq/components/use-is-mobile';

export const ChangeArrow = (): ReactElement => {
  const isMobile = useIsMobile();

  return isMobile ? <></> : <Icon as={BsArrowRight} />;
};
