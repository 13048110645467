import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { DndContextProps, UniqueIdentifier } from '@dnd-kit/core';

import {
  BaseItem,
  CloneHandler,
  DraggedItemInfo,
  OnDragHandler,
  OnDropHandler,
} from '../types';
import { useHandleDragEnd } from './use-handle-drag-end';
import { useHandleDragOver } from './use-handle-drag-over';
import { useHandleDragStart } from './use-handle-drag-start';

export interface UseDragHandlersProps<C> {
  setActiveId: Dispatch<SetStateAction<UniqueIdentifier | null>>;
  currentlyDragged: MutableRefObject<DraggedItemInfo<C> | null>;
  onChange: Dispatch<SetStateAction<BaseItem<C>[]>>;
  cloneHandler: CloneHandler<C>;
  itemsRef: MutableRefObject<BaseItem<C>[]>;
  onDrag?: OnDragHandler<C>;
  onDrop?: OnDropHandler<C>;
}

export type DragHandlers = Pick<
  DndContextProps,
  'onDragStart' | 'onDragOver' | 'onDragEnd'
>;

export function useDragHandlers<C>({
  setActiveId,
  currentlyDragged,
  onChange,
  cloneHandler,
  itemsRef,
  onDrag,
  onDrop,
}: UseDragHandlersProps<C>): DragHandlers {
  return {
    onDragStart: useHandleDragStart({
      setActiveId,
      currentlyDragged,
      itemsRef,
      onDrag,
    }),
    onDragOver: useHandleDragOver({
      currentlyDragged,
      cloneHandler,
      onChange,
    }),
    onDragEnd: useHandleDragEnd({
      setActiveId,
      currentlyDragged,
      itemsRef,
      onChange,
      onDrop,
    }),
  };
}
