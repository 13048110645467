import {
  ChangeEvent,
  Dispatch,
  memo,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import {
  Box,
  chakra,
  Flex,
  HStack,
  Link,
  Progress,
  SimpleGrid,
} from '@chakra-ui/react';
import { DefaultExtensionType, defaultStyles, FileIcon } from 'react-file-icon';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaEye } from 'react-icons/fa';

import { formatFileSize } from 'app/assets/js/tsutil';

import { DeleteButton } from '../DeleteButton';
import { IconButton } from '../IconButton';
import { WithSeparator } from '../WithSeparator';
import { FilenameInput } from './FilenameInput';
import { FileStateAction } from './files-state';
import { removeUpload } from './remove-upload';
import { FileInfo } from './types';
import { UploadedIcon } from './UploadedIcon';
import { getFileExtension } from './util';

type FileDisplayProps = FileInfo & { dispatch: Dispatch<FileStateAction> };

const FileDisplayBox = chakra(Box, {
  baseStyle: {
    rounded: 'base',
    shadow: 'base',
    bg: 'white',
    mt: '1',
    py: '1',
    px: '3',
    position: 'relative',
    cursor: 'default',
  },
});

const ProgressContainer = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const FileDisplay = ({ dispatch, ...file }: FileDisplayProps): ReactElement => {
  const { t } = useTranslation();

  const link = file.type !== 'uploading' ? file.link : null;
  const remove = useCallback(() => {
    removeUpload(file, dispatch);
  }, [dispatch, file]);

  const onNameChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      dispatch({
        type: 'renameFile',
        fileId: file.fileId,
        name: ev.target.value,
      });
    },
    [dispatch, file.fileId]
  );
  const ext = useMemo(() => getFileExtension(file.name), [file.name]);

  const defaultFileStyle = useMemo(() => {
    return ext && ext in defaultStyles
      ? defaultStyles[ext as DefaultExtensionType]
      : {};
  }, [ext]);

  return (
    <FileDisplayBox>
      <HStack>
        {ext && (
          <Box w="52px">
            <FileIcon extension={ext ?? ''} {...defaultFileStyle} />
          </Box>
        )}
        <SimpleGrid w="full" columns={1}>
          <FilenameInput
            w="inherit"
            value={file.name}
            onChange={onNameChange}
          />

          <Flex
            justifyContent="space-between"
            fontSize="sm"
            color="gray.400"
            alignItems="center"
          >
            <HStack>
              <WithSeparator separator="&nbsp;•&nbsp;">
                {formatFileSize(file.size)}
                {file.type === 'uploading' && `${Math.round(file.progress)}%`}
                {file.type === 'uploaded' && <UploadedIcon />}
              </WithSeparator>
            </HStack>
          </Flex>
        </SimpleGrid>
        <Flex alignItems="center">
          <DeleteButton
            size="sm"
            variant="ghost"
            onClick={remove}
            color="red.600"
          />
          {link && (
            <Link href={`${link}?download=true`} target="_blank" download>
              <IconButton
                color="gray.700"
                size="sm"
                variant="ghost"
                label={t('global:download')}
                icon={<FaDownload />}
              />
            </Link>
          )}
          {link && (
            <Link href={link} target="_blank">
              <IconButton
                color="gray.700"
                size="sm"
                variant="ghost"
                label={t('global:open_new_tab')}
                icon={<FaEye />}
              />
            </Link>
          )}
        </Flex>
      </HStack>
      {file.type === 'uploading' && (
        <ProgressContainer>
          <Progress
            value={file.progress}
            colorScheme="green"
            height="2px"
            bg="transparent"
          />
        </ProgressContainer>
      )}
    </FileDisplayBox>
  );
};
const FileDisplayMemo = memo(FileDisplay);
FileDisplayMemo.displayName = 'FileDisplay';

export { FileDisplayMemo as FileDisplay };
