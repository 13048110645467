import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslateSymbols = () => {
  const { t } = useTranslation('Products');

  return useCallback(
    (symbol?: string) => {
      if (!symbol) {
        return '-';
      }

      return t(`Products:base_units.symbols.${symbol}`);
    },
    [t]
  );
};
