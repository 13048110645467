import { ReactElement, useEffect, useRef } from 'react';
import { Box, Center } from '@chakra-ui/react';
import { FallbackProps } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

interface EventErrorFallbackProps extends FallbackProps {
  view: 'table' | 'card';
}
export const EventErrorFallback = ({
  resetErrorBoundary,
  error,
  view,
}: EventErrorFallbackProps): ReactElement => {
  const location = useLocation();

  const errorLocation = useRef(location.key);
  useEffect(() => {
    if (location.key !== errorLocation.current) {
      resetErrorBoundary();
    }
  }, [location.key, resetErrorBoundary]);

  return (
    <Center
      overflow='hidden'
      w="100%"
      h="100%"
      p={view === 'table' ? 0 : 2}
      bg="red.100"
      borderRadius={view === 'table' ? 'none' : 'md'}
    >
      <Box overflow='hidden' textOverflow='ellipsis' color="red.800">{error.message}</Box>
    </Center>
  );
};
