import { Api } from 'BootQuery/Assets/js/api';

import { EventData } from './types';

export async function addEventToCase(
  caseID: number,
  event: EventData
): Promise<void> {
  // Only used to throw the error
  const eventType = event.type;

  switch (event.type) {
    case 'mail':
      await Api.post(`/api/sales/cases/${caseID}/mails`, event.mailID);
      break;
    case 'call':
      await Api.post(`/api/sales/cases/${caseID}/calls`, event.callID);
      break;
    case 'chat':
      await Api.post(`/api/sales/cases/${caseID}/customerChats`, event.chatID);
      break;
    case 'custom':
      await Api.post(`/api/sales/cases/${caseID}/customEvents`, event.eventID);
      break;
    case 'appointment':
      await Api.post(
        `/api/sales/cases/${caseID}/appointments`,
        event.appointmentID
      );
      break;
    default:
      throw new Error(`Unknown ticket event type ${eventType}`);
  }
}

export async function removeEventFromCase(
  caseID: number,
  event: EventData
): Promise<void> {
  // Only used to throw the error
  const eventType = event.type;

  switch (event.type) {
    case 'mail':
      await Api.delete(`/api/sales/cases/${caseID}/mails/${event.mailID}`);
      break;
    case 'call':
      await Api.delete(`/api/sales/cases/${caseID}/calls/${event.callID}`);
      break;
    case 'chat':
      await Api.delete(
        `/api/sales/cases/${caseID}/customerChats/${event.chatID}`
      );
      break;
    case 'custom':
      await Api.delete(
        `/api/sales/cases/${caseID}/customEvents/${event.eventID}`
      );
      break;
    case 'appointment':
      await Api.delete(
        `/api/sales/cases/${caseID}/appointments/${event.appointmentID}`
      );
      break;
    default:
      throw new Error(`Unknown ticket event type ${eventType}`);
  }
}
