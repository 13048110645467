import { ReactElement, memo } from 'react';
import { DeviceState } from 'app/Modules/Telephony/Assets/store/device-state';
import { Tooltip, Text } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

const stateColors: Record<DeviceState, string> = {
  unknown: 'gray.400',
  unavailable: 'gray.400',
  idle: 'green.400',
  ringing: 'orange.400',
  busy: 'red.400',
};

interface Props {
  state: DeviceState;
}

const ExtenStateIndicator = memo(
  ({ state }: Props): ReactElement => (
    <Tooltip label={state}>
      <Text as="span" color={stateColors[state]}>
        <FaCircle />
      </Text>
    </Tooltip>
  )
);
ExtenStateIndicator.displayName = 'ExtenStateIndicator';

export { ExtenStateIndicator };
