import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { CaseCampaign } from '../../schemas';

export const campaignFilter = makeComboboxFilter({
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return { [`campaign.name:${containsOp}:ci`]: value };
    }

    if (typeof value === 'object') {
      const { ID } = value;

      return {
        [`campaign.ID:${operator ?? 'eq'}`]: ID,
      };
    }

    return null;
  },
  extraProps: {
    cacheKey: 'CaseFilterCampaign',
    search: async (search: string) => {
      const { data } = await Api.get<ListingResponse<CaseCampaign>>(
        '/api/sales/states',
        {
          params: {
            filters: search ? { $search: search } : {},
            fields: ['ID', 'name'],
          },
        }
      );

      return data.data;
    },
    itemToString: (item: CaseCampaign) => item.name,
    enableTextSearch: true,
    itemToValue: (item: CaseCampaign) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<CaseCampaign>(
        `/api/sales/campaigns/${value.id}`,
        { params: { field: ['ID', 'name'] } }
      );

      return data;
    },
  },

  name: () => i18n.t('Sales:case.campaign'),
  operators: getDefaultComboboxOperators(false),
});
