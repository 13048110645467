import i18n from 'BootQuery/Assets/js/i18n';

import { NoteIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { NoteFieldSettingsComponent } from './NoteFieldSettings';
import { StaticNoteField } from './StaticNoteField';
import { NoteFieldSettings } from './types';

export const noteField = (): FieldType<NoteFieldSettings> => ({
  type: 'note',
  name: i18n.t('global:form_editor.field_note'),
  icon: NoteIcon,
  defaultSettings: () => ({
    name: newFieldName(),
    type: 'warning',
    required: false,
  }),
  components: {
    EditorFieldSettings: NoteFieldSettingsComponent,
    StaticFormField: StaticNoteField,
  },
});
