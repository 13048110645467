import { getNumberTypeById } from './number-types';

export function formatNationalNumber(typeId: string, value: string): string {
  if (!typeId || !value) {
    return value;
  }

  const validator = getNumberTypeById(typeId);
  if (!validator) {
    console.error('Unknown national number validator: ', validator);

    return value;
  }

  return validator.validator.format(value);
}
