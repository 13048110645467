import { ReactElement, useCallback, useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { DropdownValue } from '@bq/components/Select';
import { CaseBaseInfo, CaseSelect } from 'app/Modules/Sales/Assets';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';

import { CustomEventFormData } from './types';

interface Props {
  idx: number;
  onRemove: (idx: number) => void;
}

export const LinkedCasesRow = ({ idx, onRemove }: Props): ReactElement => {
  const { control } = useFormContext<CustomEventFormData>();
  const { field } = useController({ control, name: `cases.${idx}.salesCase` });
  const remove = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  const valToCase = useCallback((val: DropdownValue | null) => {
    if (!val) {
      return null;
    }
    if ('$new' in val) {
      return null; // We don't handle $new here
    }

    return { ID: val.ID, name: val.name };
  }, []);

  const caseToVal = useCallback(
    (val: CaseBaseInfo | null): DropdownValue | null => {
      if (!val) {
        return null;
      }

      return { ID: val.ID, name: val.name };
    },
    []
  );

  const val = useMemo(() => valToCase(field.value), [field.value, valToCase]);

  return (
    <HStack>
      <Box flex="1 1 auto">
        <CaseSelect
          value={val}
          onChange={(newVal) => {
            field.onChange(caseToVal(newVal));
          }}
        />
      </Box>
      <DeleteButton size="sm" onClick={remove} />
    </HStack>
  );
};
