import { ReactElement } from 'react';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { ProductSelect } from '@bq/components/FormFields/Product';

import { BaseSettings, FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';

type Props = FieldValue<BaseSettings>;

export const ProductField = ({ id, settings }: Props): ReactElement => {
  const { control } = useFormContext();
  const { field, fieldState } = useController({
    control,
    name: id,
    rules: { required: settings.required },
  });

  return (
    <FormControl isInvalid={fieldState.invalid}>
      <FieldLabel {...settings} />
      <ProductSelect
        isInvalid={fieldState.invalid}
        isClearable={!settings.required}
        value={field.value}
        onChange={field.onChange}
      />
      <FormErrorMessage>
        {fieldState.error?.message || fieldState.error?.type}
      </FormErrorMessage>
    </FormControl>
  );
};
