import { useDisclosure } from '@chakra-ui/react';
import {
  IconButton,
  IconButtonProps,
} from 'BootQuery/Assets/components/IconButton';
import { ReactElement } from 'react';
import { FaCog } from 'react-icons/fa';
import { PresetSettings } from './PresetSettings';

type Props = Partial<IconButtonProps>;

export const PresetSettingsButton = (props: Props): ReactElement => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();

  return (
    <>
      <IconButton
        size="sm"
        icon={<FaCog />}
        label="Preset settings"
        {...props}
        {...getButtonProps()}
      />
      <PresetSettings isOpen={isOpen} onClose={onClose} />
    </>
  );
};
