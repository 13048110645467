import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useLocalStorage } from '@bq/components/use-local-storage';

import { useCurrentCall, useIsWebrtc } from './hooks';
import { dialerStore } from './store/pbx';
import { softphoneState } from './store/softphone';
import {
  InputMode,
  SelectedSoftphoneAction,
  SoftphoneError,
  TransferMode,
} from './types';
import { useAutoPreset } from './use-auto-preset';
import { useSoftphoneError } from './use-softphone-error';
import { useSoftphoneErrorToast } from './use-softphone-error-toast';

export interface IDialerContext {
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  selectedAction: SelectedSoftphoneAction | null;
  setSelectedAction: (action: SelectedSoftphoneAction | null) => void;
  submitInput: (overrideInput?: string) => void;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  focusInput: () => void;
  sendDTMF: (dtmf: string) => void;
  inputMode: InputMode;
  previousDial: string | null;
  error: SoftphoneError | null;
}

const DialerCtx = createContext<IDialerContext | null>(null);

type Props = Partial<Pick<IDialerContext, 'expanded' | 'setExpanded'>>;

export const DialerContext = ({
  children,
  expanded: inExpanded,
  setExpanded: inSetExpanded,
}: PropsWithChildren<Props>): ReactElement => {
  const [input, setInput] = useState<string>('');

  const error = useSoftphoneError(2000);
  useSoftphoneErrorToast();

  const [previousDial, setPreviousDial] = useLocalStorage<string>(
    'Telephony.previousDial',
    ''
  );
  const [selectedAction, setSelectedAction] =
    useState<SelectedSoftphoneAction | null>(null);

  const expanded = inExpanded ?? false;
  const setExpanded = useCallback(
    (val: SetStateAction<boolean>) => {
      if (inSetExpanded) {
        inSetExpanded(val);
      }
    },
    [inSetExpanded]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const isWebrtc = useIsWebrtc();

  const currentCall = useCurrentCall();

  // Clear selected action when call ends/new call starts
  useEffect(() => {
    setSelectedAction(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!currentCall, setSelectedAction]);

  const currentCallInProgress = useMemo(() => {
    if (!currentCall) {
      return false;
    }

    if (currentCall.type === 'pbx') {
      return currentCall.call.call.currentState?.callState === 'up';
    }

    return currentCall.call.state === 'inCall';
  }, [currentCall]);

  const inputMode = useMemo((): InputMode => {
    if (currentCall && currentCallInProgress && !selectedAction) {
      return 'dtmf';
    }

    return 'call';
  }, [currentCall, currentCallInProgress, selectedAction]);

  const call = (dst: string) => {
    if (isWebrtc) {
      softphoneState.getState().call(dst);
    } else {
      dialerStore.getState().call(dst);
    }
  };

  const transfer = (mode: TransferMode) => {
    if (!currentCall) {
      throw new Error('No current call to transfer');
    }

    if (currentCall.type === 'softphone') {
      currentCall.call.transfer(input, mode);
    } else {
      const { connection } = currentCall.call;
      const leg =
        connection === 'destination' || connection === 'ringing' ? 'a' : 'b';
      dialerStore.getState().transfer(currentCall.call.call.callId, input, leg);
    }
  };

  const submitInput = (overrideInput?: string) => {
    const submitted = overrideInput ?? input;
    if (submitted.length === 0) {
      return;
    }

    console.log('SUBMIT: ', selectedAction);

    if (selectedAction === 'attended' || selectedAction === 'blind') {
      transfer(selectedAction);
    } else {
      call(submitted);
    }

    setPreviousDial(submitted);
    setInput('');
    setSelectedAction(null);
  };

  const sendDTMF = (dtmf: string) => {
    if (currentCall?.type === 'softphone') {
      currentCall.call.sendDtmf(dtmf);
    }
  };

  const focusInput = useCallback(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  return (
    <DialerCtx.Provider
      value={{
        selectedAction,
        setSelectedAction,
        input,
        setInput,
        submitInput,
        focusInput,
        sendDTMF,
        inputMode,
        expanded,
        setExpanded,
        previousDial,
        error,
      }}
    >
      <AutoPreset />
      {children}
    </DialerCtx.Provider>
  );
};

export const useDialerContext = () => {
  const ctx = useContext(DialerCtx);
  if (ctx === null) {
    throw Error('Dialer CTX not initialized');
  }

  return ctx;
};

const AutoPreset = (): ReactElement => {
  useAutoPreset();

  return <></>;
};
