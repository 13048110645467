import { GroupBy } from '@bq/components/FormEditor';
import { Column } from '@bq/components/Table';
import { formatDuration } from 'app/assets/js/format-duration';
import i18n from 'BootQuery/Assets/js/i18n';

import { ReportRow } from './types';

export function useGroupBys(): GroupBy[] {
  return [
    {
      name: i18n.t('Ticketing:form.ticket_type'),
      key: 'ticketType',
      columnKey: 'ticketType',
    },
    {
      name: i18n.t('Ticketing:group'),
      key: 'group',
      columnKey: 'group',
    },
    {
      name: i18n.t('Ticketing:form.report_method'),
      key: 'reportMethod',
      columnKey: 'reportMethod',
    },
  ];
}

export function getGroupOnlyColumns(): Column<ReportRow>[] {
  return [
    {
      title: i18n.t('Ticketing:form.ticket_type'),
      key: 'ticketType',
      defaultVisible: false,
    },
    {
      key: 'group',
      title: i18n.t('Ticketing:group'),
      defaultVisible: false,
    },
    {
      key: 'reportMethod',
      title: i18n.t('Ticketing:form.report_method'),
      defaultVisible: false,
    },
  ];
}

export function getColumns(): Column<ReportRow>[] {
  return [
    {
      key: 'created',
      title: i18n.t('Ticketing:reports.created'),
    },
    {
      key: 'closed',
      title: i18n.t('Ticketing:reports.closed'),
    },
    {
      key: 'reopened',
      title: i18n.t('Ticketing:reports.reopened'),
    },
    {
      key: 'reassign',
      title: i18n.t('Ticketing:reports.reassign'),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.count',
      title: i18n.t('Ticketing:reports.first_response_count'),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.avg',
      title: i18n.t('Ticketing:reports.first_response_avg'),
      getValue: ({ row }) => formatDuration(row.firstResponse.avg),
    },
    {
      key: 'firstResponse.min',
      title: i18n.t('Ticketing:reports.first_response_min'),
      getValue: ({ row }) => formatDuration(row.firstResponse.min),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.max',
      title: i18n.t('Ticketing:reports.first_response_max'),
      getValue: ({ row }) => formatDuration(row.firstResponse.max),
      defaultVisible: false,
    },
    {
      key: 'firstResponse.median',
      title: i18n.t('Ticketing:reports.first_response_median'),
      getValue: ({ row }) => formatDuration(row.firstResponse.median),
      defaultVisible: true,
    },
    {
      key: 'responses.count',
      title: i18n.t('Ticketing:reports.responses_count'),
      defaultVisible: true,
    },
    {
      key: 'responses.avg',
      title: i18n.t('Ticketing:reports.responses_avg'),
      getValue: ({ row }) => formatDuration(row.responses.avg),
      defaultVisible: true,
    },
    {
      key: 'responses.min',
      title: i18n.t('Ticketing:reports.responses_min'),
      getValue: ({ row }) => formatDuration(row.responses.min),
      defaultVisible: false,
    },
    {
      key: 'responses.max',
      title: i18n.t('Ticketing:reports.responses_max'),
      getValue: ({ row }) => formatDuration(row.responses.max),
      defaultVisible: false,
    },
    {
      key: 'responses.median',
      title: i18n.t('Ticketing:reports.responses_median'),
      getValue: ({ row }) => formatDuration(row.responses.median),
      defaultVisible: true,
    },
  ];
}
