import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import { AppointmentColumnEvent } from './AppointmentColumnEvent';
import { AppointmentColumnSubject } from './AppointmentColumnSubject';
import { AppointmentColumnType } from './AppointmentColumnType';
import { AppointmentColumnUser } from './AppointmentColumnUser';
import { AppointmentEventCard } from './AppointmentEventCard';

export const appointmentEventProvider: ModuleEventTypeProviders = {
  appointment: {
    components: {
      Card: AppointmentEventCard,
    },
    columns: {
      type: { Cell: AppointmentColumnType },
      event: { Cell: AppointmentColumnEvent },
      user: { Cell: AppointmentColumnUser },
      contact: { Cell: AppointmentColumnSubject },
    },
  },
};
