import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { entryFormSchema, IEntryForm } from './schema';

type UseEntryFormProps = UseFormProps<IEntryForm>;

export function useEntryForm(
  props: UseEntryFormProps
): UseFormReturn<IEntryForm> {
  return useForm<IEntryForm>({
    ...props,
    resolver,
  });
}

export function useEntryFormContext(): UseFormReturn<IEntryForm> {
  return useFormContext<IEntryForm>();
}

const resolver = zodResolver(entryFormSchema);
