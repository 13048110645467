import { ReactElement } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaArrowRight } from 'react-icons/fa';

export const SubmitNextButton = (props: ButtonProps): ReactElement => {
  const { t } = useTranslation('Datasets');

  return (
    <Button type="submit" colorScheme="green" {...props}>
      {t('global:continue')} &nbsp;
      <FaArrowRight />
    </Button>
  );
};
