import { ReactElement } from 'react';
import { Badge, Box, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaUser } from 'react-icons/fa';

import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { FilterType } from '../types';
import {
  ComboboxFilterInput,
  ComboboxFilterTag,
  makeComboboxFilter,
} from './ComboboxFilter';
import { ComboboxFilterDef } from './ComboboxFilter/make-combobox-filter';

interface User {
  ID: number;
  username: string;
  person: {
    ID: number;
    fullName: string;
  } | null;
}

type ItemType = { type: 'user'; user: User } | { type: 'currentUser' };
type ValType = { ID: number } | { ID: '$currentUser' };

type UserFilterProps = ComboboxFilterDef<ItemType, ValType>;

function formatName(user: User): string {
  return user.person?.fullName ?? user.username;
}

const UserFilterItem = (item: ItemType): ReactElement => {
  const { t } = useTranslation();

  if (item.type === 'currentUser') {
    return <Badge colorScheme="blue">{t('global:filter.current_user')}</Badge>;
  }

  return (
    <HStack alignItems="center">
      <FaUser style={{ display: 'block' }} />
      <Box>{formatName(item.user)}</Box>
    </HStack>
  );
};

const userFilterDefaults: Omit<UserFilterProps, 'toFilter'> = {
  tagComponent: ComboboxFilterTag,
  inputComponent: ComboboxFilterInput,
  name: 'Korisnik',
  operators: [],
  extraProps: {
    cacheKey: 'userCombobox',
    search: async (search: string) => {
      const { data } = await Api.get<ListingResponse<User>>('/api/users', {
        params: search ? { filters: { $search: search } } : {},
      });

      return [
        ...data.data.map((user) => ({ type: 'user', user })),
        { type: 'currentUser' },
      ] as ItemType[];
    },
    itemToString: (item: ItemType) => {
      if (item.type === 'currentUser') {
        return '$currentUser';
      }

      return formatName(item.user);
    },
    renderItem: UserFilterItem,
    itemToValue: (item) => {
      if (item.type === 'currentUser') {
        return { ID: '$currentUser' };
      }

      return { ID: item.user.ID };
    },
    valueToItem: async (val) => {
      if (val.ID === '$currentUser') {
        return { type: 'currentUser' };
      }

      const { data: user } = await Api.get<User>(`/api/users/${val.ID}`);

      return { type: 'user', user };
    },
  },
};

export function makeUserFilter(
  filterDef: Partial<UserFilterProps> & Pick<UserFilterProps, 'toFilter'>
): FilterType {
  return makeComboboxFilter({
    ...userFilterDefaults,
    ...filterDef,
  });
}

type CurrentUserPlaceholder = { $var: 'currentUserID' };
export function userFilterIDExpr(
  value: ItemType
): number | CurrentUserPlaceholder {
  if (value.type === 'currentUser') {
    return { $var: 'currentUserID' };
  }

  return value.user.ID;
}
