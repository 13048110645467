import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';

import { findItem, moveItem } from '../../dnd-util';
import { BaseItem, DraggedItemInfo, OnDropHandler } from '../types';
import { ItemData } from './types';

export interface HandleDragEndProps<C> {
  setActiveId: Dispatch<SetStateAction<UniqueIdentifier | null>>;
  currentlyDragged: MutableRefObject<DraggedItemInfo<C> | null>;
  onChange: Dispatch<SetStateAction<BaseItem<C>[]>>;
  itemsRef: MutableRefObject<BaseItem<C>[]>;
  onDrop?: OnDropHandler<C>;
}

export type DragEndHandler = (ev: DragEndEvent) => void;

export function useHandleDragEnd<C>({
  setActiveId,
  currentlyDragged,
  itemsRef,
  onChange,
  onDrop,
}: HandleDragEndProps<C>): DragEndHandler {
  const handleDropCallback = useCallback(
    (
      itemId: UniqueIdentifier,
      items: BaseItem<C>[],
      dragged: DraggedItemInfo<C>
    ) => {
      const item = findItem(items, itemId);
      if (!item) {
        throw new Error(`Unable to find dropped item ${itemId}`);
      }
      if (onDrop) {
        const toPos = { position: item.position, path: item.path };
        onDrop(item.item, dragged.initialPosition, toPos);
      }
    },
    [onDrop]
  );

  return useCallback(
    (ev: DragEndEvent) => {
      setActiveId(null);
      const dragged = currentlyDragged.current;
      if (!dragged) {
        throw new Error('No dragged item');
      }
      currentlyDragged.current = null;

      if (!ev.over) {
        handleDropCallback(ev.active.id, itemsRef.current, dragged);

        return;
      }

      const overItemData = ev.over.data.current as ItemData<C>;
      if (!overItemData?.sortable) {
        handleDropCallback(ev.active.id, itemsRef.current, dragged);

        return;
      }

      onChange((prev) => {
        if (overItemData.sortable.index === -1) {
          console.warn('Got item with negative index', overItemData);

          return prev;
        }

        const moved = moveItem(prev, ev.active.id, {
          position: overItemData.sortable.index,
        });

        handleDropCallback(ev.active.id, moved, dragged);

        return moved;
      });
    },
    [setActiveId, currentlyDragged, onChange, handleDropCallback, itemsRef]
  );
}
