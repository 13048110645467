import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketState } from '../../types';

interface LimitNullGetListingParams extends Omit<GetListingParams, 'limit'> {
  limit?: number | 'null';
}
export const getTicketStates = async <T = TicketState>(
  params: LimitNullGetListingParams = {}
) => {
  const { data } = await Api.get<T[]>('/api/ticketing/states', {
    params,
  });

  return data;
};
