import { PropsWithChildren, ReactElement, useCallback } from 'react';

import { ContactModalProvider, OnContactAdded } from '../ContactModal';
import { callListEvents } from './call-list-events';

export const CallListContactModalProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const onNumberAssigned: OnContactAdded = useCallback(
    (phoneNumber, contact) => {
      callListEvents.emit('contactUpdated', { phoneNumber, contact });
    },
    []
  );

  return (
    <ContactModalProvider onAdded={onNumberAssigned}>
      {children}
    </ContactModalProvider>
  );
};
