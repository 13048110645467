import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  GetOneParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { DatasetListItem } from '../DatasetList/schema';
import { Dataset, UpdateDataset } from '../types';

export type ListDatasetsResponse = ListingResponse<DatasetListItem>;

export async function listDatasets(
  params: GetListingParams
): Promise<ListDatasetsResponse> {
  const { data } = await Api.get<ListDatasetsResponse>(
    '/api/datasets/datasets',
    { params }
  );

  return data;
}

export async function getDataset<D extends Partial<Dataset> = Dataset>(
  datasetID: number,
  params: GetOneParams = {}
): Promise<D> {
  const { data } = await Api.get<D>(`/api/datasets/datasets/${datasetID}`, {
    params,
  });

  return data;
}

export async function updateDataset(
  datasetID: number,
  changes: UpdateDataset
): Promise<void> {
  await Api.patch(`/api/datasets/datasets/${datasetID}`, changes);
}

export async function deleteDataset(datasetID: number): Promise<void> {
  await Api.delete(`/api/datasets/datasets/${datasetID}`);
}
