import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { SettingsMethods } from '@bq/components/SettingsCRUD';
import { useModal } from '@bq/components/UseModal/use-modal';

import { Campaign } from '../../schemas';
import { CampaignModal } from './CampaignModal';

interface Props {
  modify: SettingsMethods<Campaign>;
}

export const AddCampaignButton = ({ modify }: Props): ReactElement => {
  const { t } = useTranslation('Sales');
  const { addModal } = useModal();

  return (
    <IconButton
      colorScheme="green"
      icon={<FaPlus />}
      label={t('global:add')}
      size="sm"
      onClick={() => {
        addModal({
          children: <CampaignModal mode="create" />,
          preventClose: {
            defaultPreventState: false,
          },
          componentProps: {
            modal: { size: '6xl', scrollBehavior: 'inside' },
          },
          callback: modify.add,
        });
      }}
    />
  );
};
