import { useMemo } from 'react';
import { findItem, FindItemResult } from '../dnd-util';
import { BaseItem } from './types';
import { useDndContainers } from './use-dnd-containers';

export function useActiveItem<C>(): FindItemResult<BaseItem<C>> | null {
  const { activeId, items } = useDndContainers<C>();

  return useMemo(() => {
    if (!activeId) {
      return null;
    }

    const item = findItem(items, activeId);
    if (!item) {
      throw new Error(`Unable to find active item ${activeId}`);
    }

    return item;
  }, [activeId, items]);
}
