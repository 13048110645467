import { memo, MouseEventHandler, ReactElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { CancelButton } from './CancelButton';
import { FormState } from './form-state';
import { SaveButton } from './SaveButton';

interface BaseProps {
  onSubmit?: MouseEventHandler;
  state?: FormState;
  form?: string;
}

interface CancelButtonProps {
  onCancel: () => void;
  cancellable?: boolean;
}

interface CancelLinkProps {
  cancelLink: string;
  cancellable?: boolean;
}

interface NoCancelProps {
  cancellable: false;
}

type CancelProps = CancelButtonProps | CancelLinkProps | NoCancelProps;
type Props = BaseProps & CancelProps;

export const FormActions = memo(
  ({ onSubmit, form, state = null, ...cancelProps }: Props): ReactElement => {
    const { t } = useTranslation('global');

    return (
      <HStack justifyContent="flex-end">
        {state === 'error' && (
          <Text as="span" color="red.500">
            {t('global:save_error')}
          </Text>
        )}
        {state === 'saved' && (
          <Text as="span" color="green.500">
            {t('global:save_success')}
          </Text>
        )}
        <CancelAction {...cancelProps} />
        <SaveButton
          type="submit"
          form={form}
          isLoading={state === 'saving'}
          onClick={onSubmit}
        />
      </HStack>
    );
  }
);
FormActions.displayName = 'FormActions';

const CancelAction = memo((props: CancelProps): ReactElement => {
  if ('cancellable' in props && !props.cancellable) {
    return <></>;
  }

  if ('cancelLink' in props) {
    return <CancelButton as={RouterLink} to={props.cancelLink} />;
  }

  if ('onCancel' in props) {
    return <CancelButton onClick={props.onCancel} />;
  }

  throw new Error('Invalid cancel props');
});
CancelAction.displayName = 'CancelAction';
