import { Link } from '@chakra-ui/react';
import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { TicketEvent } from './types';

export const TicketEventColumn: EventCellComponent<TicketEvent> = ({
  row: { data: ticket },
}) => (
  <Link href={`/ticketing/edit/${ticket.ID}`}>
    &nbsp; #{ticket.ID} - {ticket.title}
  </Link>
);
