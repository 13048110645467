import Quill from 'quill/core';
import TemplateBlot from './template-blot';

class templateModule {
  constructor(quill, options) {
    this.templates = options;
    quill.getModule('toolbar').addHandler('template', (value) => {
      if (value) {
        const cursorPosition = quill.getSelection().index;
        if (this.templates[value]) {
          value = {
            name: this.templates[value],
            label: value,
          };
        }
        quill.insertEmbed(cursorPosition, 'template', value, Quill.sources.USER);
        quill.setSelection(cursorPosition + 1, Quill.sources.SILENT);
      }
    });
  }

  static register() {
    Quill.register('formats/template', TemplateBlot, true);
  }
}

export default templateModule;
