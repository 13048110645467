import { ReactElement, useCallback } from 'react';

import { SettingsMethods } from '@bq/components/SettingsCRUD';
import { useModal } from '@bq/components/UseModal';

import { TemplateModal } from './TemplateModal';
import { MailTemplate } from './types';

interface Props {
  template: MailTemplate;
  modify: SettingsMethods<MailTemplate>;
}

export const TemplateListItem = ({ template, modify }: Props): ReactElement => {
  const { addModal } = useModal();

  const edit = useCallback(() => {
    addModal({
      children: <TemplateModal mode="edit" data={template} />,
      preventClose: {
        defaultPreventState: false,
      },
      componentProps: {
        modal: { size: '6xl' },
      },
      callback: modify.upd,
    });
  }, [addModal, template, modify.upd]);

  const remove = useCallback(() => {
    modify.del(template.ID);
  }, [template.ID, modify]);

  return (
    <li className="list-group-item py-2">
      <div className="row">
        <div className="col">{template.name}</div>
        <div className="col-auto">
          <button
            type="button"
            className="btn btn-sm btn-danger"
            onClick={remove}
          >
            <span className="fa fa-trash-alt"></span>
          </button>
          &nbsp;
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={edit}
          >
            <span className="fa fa-cog"></span>
          </button>
        </div>
      </div>
    </li>
  );
};
