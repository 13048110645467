import { ModificationsNoID } from '@bq/components/SettingsCRUD/types';
import { Api } from 'BootQuery/Assets/js/api';
import { CustomData, GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { IAppointment } from '../schema';

export interface PatchAppointment {
  title: string;
  startAt: Date | string;
  endAt: Date | string;
  data?: CustomData;
  location: {
    type: 'unknown' | 'inPerson' | 'video' | 'phone';
    location?: string | undefined;
  } | null;
  reminders: ModificationsNoID<{ timestamp: Date | string }>;
  participants: {
    type: 'user' | 'person' | 'company';
    ID: number;
  }[];
}

export const updateAppointment = async <T = IAppointment>(
  appointmentID: number,
  data: PatchAppointment,
  params?: GetOneParams
) => {
  const { data: newData } = await Api.patch<T>(
    `/api/appointments/${appointmentID}`,
    data,
    { params }
  );

  return newData;
};
