import { Input } from '@chakra-ui/react';
import {
  ReactElement, ForwardedRef, ChangeEvent,
  forwardRef, useCallback,
} from 'react';

interface DTMFInputProps {
    onInput: (input: string) => void;
}

type InputRefType = ForwardedRef<HTMLInputElement>;
const DTMFInputInner = ({ onInput }: DTMFInputProps, ref: InputRefType): ReactElement => {
  const handleInput = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    onInput(ev.target.value);
  }, [onInput]);

  return (
    <Input
      minWidth="4"
      flexGrow={1}
      flexShrink={1}
      placeholder="DTMF"
      bg="white"
      color="gray.600"
      onChange={handleInput}
      value=""
      ref={ref}
    />
  );
};

export const DTMFInput = forwardRef(DTMFInputInner);
