import { chakra, forwardRef, Link, LinkProps, Text } from '@chakra-ui/react';
import { PopoverIcon } from './PopoverIcon';

export const PopoverLink = forwardRef<LinkProps, 'a'>(
  ({ children, ...props }, ref) => (
    <LinkWrapper {...props} ref={ref}>
      <PopoverIcon />
      <Text as="span" overflow="hidden" textOverflow="ellipsis">
        {children}
      </Text>
    </LinkWrapper>
  )
);

const LinkWrapper = chakra(Link, {
  baseStyle: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
    display: 'flex',
    flexDir: 'row-reverse',
    justifyContent: 'start',
    minWidth: 0,
  },
});
