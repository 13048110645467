import { ReactElement, useEffect, useRef } from 'react';
import { FormControl, FormErrorMessage, Textarea } from '@chakra-ui/react';
import autosize from 'autosize';
import { get } from 'lodash-es';
import { FieldError, useFormContext } from 'react-hook-form';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { TextFieldSettings } from '../TextField/types';

export const FreetextField = ({
  id,
  settings,
}: FieldValue<TextFieldSettings>): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const ourError = get(errors, id) as FieldError | undefined;
  useEffect(() => {
    const currentTextarea = textareaRef.current;

    if (currentTextarea) {
      autosize(currentTextarea);
    }

    return () => {
      if (currentTextarea) {
        autosize.destroy(currentTextarea);
      }
    };
  }, []);

  const registerFields = register(id, {
    required: settings.required,
    minLength: settings.minLength ?? undefined,
    maxLength: settings.maxLength ?? undefined,
  });

  return (
    <FormControl isInvalid={!!ourError}>
      <FieldLabel {...settings} />
      <Textarea
        {...registerFields}
        ref={(e) => {
          registerFields.ref(e);
          textareaRef.current = e;
        }}
      />
      {ourError && (
        <FormErrorMessage>{ourError.message || ourError.type}</FormErrorMessage>
      )}
    </FormControl>
  );
};
