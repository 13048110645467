import { ReactElement } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactSelect } from '@bq/components/FormFields';

import { useAppointmentFormContext } from './form';

export const AppointmentAttendees = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { control } = useAppointmentFormContext();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'attendees' });

  return (
    <FormControl>
      <FormLabel fontWeight="bold">
        {t('Events:appointment_attendees')}:
      </FormLabel>
      <ContactSelect
        isMulti
        value={value ?? []}
        onChange={onChange}
        createMode="saveOnApiModal"
        hasManualOption={false}
        allowedTypes={{
          user: true,
          person: true,
          company: true,
          companyLocation: false,
          companyDepartment: false,
        }}
      />
    </FormControl>
  );
};
