import { ComponentType, useMemo } from 'react';

export type ReactRouteMatcher = string | string[] | RegExp;

export interface ReactRoute {
  path: string;
  component: ComponentType;
}

export interface ReactRoutesProvider {
  ReactRoutes: ReactRoute[];
}

export function getRouteProviders(): ReactRoutesProvider[] {
  return window.BootQuery.getProviders<ReactRoutesProvider>('reactRoutes');
}

export function useReactRouteProviders(): ReactRoutesProvider[] {
  return useMemo(getRouteProviders, []);
}

export function useReactRoutes(): ReactRoute[] {
  const providers = useReactRouteProviders();

  return useMemo(() => {
    const initial = [] as ReactRoute[];

    return providers.reduce(
      (routes, provider) => [...routes, ...provider.ReactRoutes],
      initial
    );
  }, [providers]);
}

export function matchReactRoute(
  matcher: ReactRouteMatcher,
  route: string
): boolean {
  if (matcher instanceof RegExp) {
    return matcher.test(route);
  }

  if (typeof matcher === 'string') {
    matcher = [matcher];
  }

  return matcher.some((prefix) => route.startsWith(prefix));
}
