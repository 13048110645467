import { ReactElement } from 'react';

import { useDisplayMode } from '@bq/components/ListingSettings';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useFormDefinition } from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { usePermissions } from '../use-permissions';
import { MobilePersonCardList } from './MobilePersonCardList';
import { PersonCardList } from './PersonCardList';
import { PersonTableList } from './PersonTableList';

export const PersonList = (): ReactElement => {
  const customFields = useFormDefinition('Phonebook.person');
  const isMobile = useIsMobile();
  const settingsLoaded = useLoadUserSettings('Phonebook.PersonList', {});
  const [displayMode] = useDisplayMode('Phonebook.PersonList');
  const { data: permissions } = usePermissions();

  if (!settingsLoaded || !customFields || !permissions) {
    return <LoadingPage />;
  }

  if (isMobile) {
    return (
      <MobilePersonCardList
        permissions={permissions}
        customFields={customFields}
      />
    );
  }

  return displayMode === 'card' ? (
    <PersonCardList permissions={permissions} customFields={customFields} />
  ) : (
    <PersonTableList permissions={permissions} customFields={customFields} />
  );
};
