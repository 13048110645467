import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { FreeTextIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { TextFieldSettingsComponent } from '../TextField/TextFieldSettingsComponent';
import { TextFieldSettings } from '../TextField/types';
import { FreetextField } from './FreetextField';

export const freetextField = (): FieldType<TextFieldSettings> => ({
  type: 'freetext',
  name: i18n.t('global:form_editor.field_freetext'),
  icon: FreeTextIcon,
  components: {
    FormField: FreetextField,
    EditorFieldSettings: TextFieldSettingsComponent,
  },
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        defaultVisible: false,
        exportValue: `data.${field.id}`,
        getValue: ({ row }) => {
          if (!row.data) {
            return '-';
          }

          const val = ((row.data as Record<string, string | number>) ?? {})[
            field.id
          ];
          if (typeof val !== 'string') {
            return '-';
          }

          return val.replace('\n', '. ');
        },
      },
    ];
  },
  getFilters(field) {
    return {
      [field.id]: makeTextFilter({
        name: field.settings.name,
        toFilter({ value, operator }) {
          if (!value) {
            return null;
          }

          const op = operator ?? 'contains:ci';

          return [`data.${field.id}`, `string:${op}`, value];
        },
      }),
    };
  },
  getGroupBy(field) {
    return [
      {
        name: field.settings.name,
        key: `data.${field.id}`,
        exportValue: `data.${field.id}`,
        columnKey: `$custom-${field.id}`,
      },
    ];
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
});
