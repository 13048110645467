module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"content").call(depth0 != null ? depth0 : (container.nullContext || {}),"body",{"name":"content","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":43,"column":16}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"card h-100\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"with","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":41,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"card-header\">\n            <span class=\"fa fa-cog\"></span>\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,lookupProperty(helpers,"concat").call(alias1,"label.",((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":65}}}),{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":67}}}))
    + "\n        </div>\n        <div class=\"card-block settings-card-block\">\n            <form method=\"POST\" action=\"/settings/"
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")), depth0))
    + "\" id=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")), depth0))
    + "-form\" class=\"h-100\">\n                <input type=\"hidden\" name=\"action\" value=\"update\">\n                <div class=\"row no-gutters h-100 settings-container-row\">\n                    <div class=\"col-12 col-md-3\">\n                        <div class=\"list-group settings-menu-list-group h-100\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabs") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":17,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"col-12 col-md-9\">\n                        <div class=\"tab-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tabs") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":28},"end":{"line":26,"column":37}}})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n            </form>\n        </div>\n        <div class=\"card-footer d-flex\">\n            <div class=\"save-button ml-auto\">\n                <span class=\"save-status\" style=\"padding: 7px 15px;\" data-form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")), depth0))
    + "-form\" hidden=\"\">\n                </span>\n                <button type=\"submit\" class=\"btn btn-success\" form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")), depth0))
    + "-form\" data-form=\""
    + alias2(alias3(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"which_settings")), depth0))
    + "-form\">\n                    <span class=\"fa fa-check\"></span>&nbsp;Spremi\n                </button>\n            </div>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"#setting-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" class=\"list-group-item list-group-item-action "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":101},"end":{"line":16,"column":128}}})) != null ? stack1 : "")
    + "\" data-toggle=\"list\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</a>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "active";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div id=\"setting-"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" class=\"tab-pane fade "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":75},"end":{"line":23,"column":107}}})) != null ? stack1 : "")
    + "\">\n                                "
    + ((stack1 = container.invokePartial(lookupProperty(helpers,"lookup").call(alias1,depth0,"view",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":24,"column":35},"end":{"line":24,"column":55}}}),(depth0 != null ? lookupProperty(depth0,"data") : depth0),{"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "show active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"extend").call(depth0 != null ? depth0 : (container.nullContext || {}),"main",{"name":"extend","hash":{"bq_content_class":"bq-settings"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":44,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})