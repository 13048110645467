import {
  Children, cloneElement, ReactElement, ReactNode,
} from 'react';

type Separator = string | ReactElement;

interface WithSeparatorProps {
  separator: Separator;
  children: ReactNode | ReactNode[];
}

function keySeparator(separator: Separator, idx: number): Separator {
  if (typeof separator === 'string') {
    return separator;
  }

  return cloneElement(separator, { key: `$sep-${idx}` });
}

export const WithSeparator = ({
  children,
  separator,
}: WithSeparatorProps): ReactElement => {
  const childList = Children.toArray(children);
  const separatedChildren: ReactNode[] = [];
  for (let i = 0; i < childList.length; i++) {
    separatedChildren.push(childList[i]);
    if (i < childList.length - 1) {
      const sepEl = keySeparator(separator, i);
      separatedChildren.push(sepEl);
    }
  }

  return <>{separatedChildren}</>;
};
