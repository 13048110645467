import { useCallback, useMemo } from 'react';
import { AsyncSelect, SingleValue } from 'chakra-react-select';

import { getTickets } from './Api';
import { TicketBaseInfo } from './types';

interface SelectOption {
  label: string;
  value: number;
  title: string;
}
interface Props {
  onChange: (newValue: SingleValue<TicketBaseInfo>) => void;
  value: TicketBaseInfo | null;
  excludedTicketIds?: number | number[];
  isInvalid?: boolean;
}

export const TicketSelect = ({
  onChange,
  value,
  excludedTicketIds,
  isInvalid,
}: Props) => {
  const handleChange = useCallback(
    (value: SingleValue<SelectOption>) => {
      if (value) {
        onChange({ ID: value.value, title: value.title });
      }
    },
    [onChange]
  );

  return (
    <AsyncSelect
      isInvalid={isInvalid}
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={useTicketSelectValue(value)}
      cacheOptions
      defaultOptions
      onChange={handleChange}
      loadOptions={useLoadOptions(excludedTicketIds)}
      selectedOptionColorScheme="brand"
    />
  );
};

const loadOptions = async (
  search: string,
  excludedTicketIds?: number | number[]
): Promise<SelectOption[]> => {
  let extraFilters = {};
  if (excludedTicketIds) {
    const excluded = Array.isArray(excludedTicketIds)
      ? excludedTicketIds
      : [excludedTicketIds];
    if (excluded.length > 0) {
      extraFilters = { 'ID:notInArray': excluded };
    }
  }

  const { data } = await getTickets<TicketBaseInfo>({
    filters: {
      $search: search,
      ...extraFilters,
    },
    fields: ['ID', 'title'],
  });

  return data.data.map(ticketToSelectValue);
};

function useLoadOptions(
  excludedTicketIds?: number | number[]
): (search: string) => Promise<SelectOption[]> {
  return useCallback(
    (search: string) => loadOptions(search, excludedTicketIds),
    [excludedTicketIds]
  );
}

const ticketToSelectValue = (ticket: TicketBaseInfo): SelectOption => ({
  value: ticket.ID,
  title: ticket.title,
  label: `#${ticket.ID} - ${ticket.title}`,
});

function useTicketSelectValue(
  ticket: TicketBaseInfo | null
): SelectOption | null {
  return useMemo((): SelectOption | null => {
    if (!ticket) {
      return null;
    }

    return ticketToSelectValue(ticket);
  }, [ticket]);
}
