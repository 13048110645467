import { useMediaQuery } from '@chakra-ui/react';

const MOBILE_MEDIA = '(max-width: 768px)';

export const useIsMobile = () => {
  const [isMobile] = useMediaQuery(MOBILE_MEDIA, {
    fallback: window.matchMedia(MOBILE_MEDIA).matches,
  });

  return isMobile;
};
