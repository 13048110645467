import {
  FieldPath,
  FieldValues,
  PathValue,
  UseFormReturn,
  useWatch,
} from 'react-hook-form';

export function useWatchWithDefaults<
  T extends FieldValues = FieldValues,
  N extends FieldPath<T> = FieldPath<T>,
>(
  formMethods: Pick<UseFormReturn<T>, 'control' | 'getValues'>,
  name: N
): PathValue<T, N> {
  const { control, getValues } = formMethods;

  return useWatch({ control, name }) ?? getValues(name);
}
