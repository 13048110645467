import { AsyncSelect, MultiValue } from 'chakra-react-select';

import { getSalesStates } from './api/states';
import { StateOptionComponent } from './StateOptionComponent';
import { StateOption } from './types';
import { stateToSelectOption } from './util';

interface Props {
  onChange: (value: MultiValue<StateOption>) => void;
  value?: StateOption[];
}

export const StatesMultiselect = ({ onChange, value }: Props) => {
  return (
    <AsyncSelect<StateOption, true>
      chakraStyles={{
        multiValue: (provided, state) => ({
          ...provided,
          background: state.data.color,
          color: 'white',
          fontSize: '16px',
        }),
      }}
      isMulti
      useBasicStyles
      value={value}
      components={{ Option: StateOptionComponent }}
      cacheOptions
      defaultOptions
      onChange={onChange}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};

async function loadOptions(search: string): Promise<StateOption[]> {
  const data = await getSalesStates({
    filters: {
      $search: search,
    },
  });

  return data.map(stateToSelectOption);
}
