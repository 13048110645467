import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
} from '@chakra-ui/react';
import {
  useController,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaTrash } from 'react-icons/fa';

import { DateInput } from '@bq/components/DateInput';
import { Select } from '@bq/components/SelectWrappers';
import { parseToDate } from 'BootQuery/Assets/js/dateUtils';

import { postActionPriceReason } from '../../../API/ActionPriceReasons/postActionPriceReason';
import { searchActionPriceReasons } from '../../../API/ActionPriceReasons/searchActionPriceReason';
import { Prices } from '../../../types';

interface Props {
  idx: number;
  remove: UseFieldArrayRemove;
}
export const ActionPriceField = ({ idx, remove }: Props) => {
  const { register, control } = useFormContext<Prices>();
  const {
    field: { value: validUntilValue, onChange: validUntilOnChange },
  } = useController<Prices, `actionPrices.${number}.validUntil`>({
    name: `actionPrices.${idx}.validUntil`,
    control,
  });
  const {
    field: { value: reasonValue, onChange: reasonOnChange },
  } = useController<Prices, `actionPrices.${number}.reason`>({
    name: `actionPrices.${idx}.reason`,
    control,
  });
  const { t } = useTranslation('Products');

  return (
    <HStack w="full" alignItems="center" spacing={4}>
      <FormControl>
        <FormLabel htmlFor={`actionPrices.${idx}.reason`}>
          {t('Products:action_price_reason')}
        </FormLabel>
        <Select<number>
          isCreatable
          options={async (search) => {
            const data = await searchActionPriceReasons({ search });

            return data.map((item) => ({ label: item.name, value: item.ID }));
          }}
          isClearable
          size={['md', 'md', 'sm']}
          creatableProps={{
            isValidNewOption: (value) => {
              return value !== undefined && value.length > 2;
            },
            onCreateOption: async (inputValue) => {
              if (inputValue) {
                const { ID } = await postActionPriceReason({
                  data: { name: inputValue },
                });
                reasonOnChange({ ID, name: inputValue });
              }
            },
          }}
          value={
            reasonValue
              ? { label: reasonValue.name, value: reasonValue.ID }
              : null
          }
          onChange={(newValue) => {
            if (newValue) {
              reasonOnChange({ ID: newValue.value, name: newValue.label });
            }
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor={`actionPrices.${idx}.price`}>
          {t('Products:action_price')}
        </FormLabel>
        <Input
          min={0}
          type="number"
          step={0.0001}
          {...register(`actionPrices.${idx}.price`, { valueAsNumber: true })}
          size={['md', 'md', 'sm']}
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor={`actionPrices.${idx}.validUntil`}>
          {t('Products:valid_until')}
        </FormLabel>
        <DateInput
          showTime
          mode="single"
          value={parseToDate(validUntilValue)}
          onChange={(newVal: Date | null) => {
            validUntilOnChange(newVal?.toISOString());
          }}
          onRemove={() => {
            validUntilOnChange(null);
          }}
        />
      </FormControl>

      <IconButton
        alignSelf="flex-end"
        colorScheme="red"
        icon={<FaTrash />}
        aria-label={t('global:delete')}
        onClick={() => {
          remove(idx);
        }}
      />
    </HStack>
  );
};
