module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":35},"end":{"line":52,"column":65}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <tr>\n                    <th style=\"text-align: left;\">CC: </th>\n                    <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":24},"end":{"line":62,"column":33}}})) != null ? stack1 : "")
    + "                    </td>\n                </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":39},"end":{"line":61,"column":69}}})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"htmlBody") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <pre style=\"white-space: pre-wrap;\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"textBody") : stack1), depth0))
    + "</pre>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n<html lang=\"en\">\n\n<head>\n    <meta charset=\"UTF-8\">\n    <meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\">\n    <meta http-equiv=\"X-UA-Compatible\" content=\"ie=edge\">\n    <title>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</title>\n\n    <style media=\"print\">\n        @page {\n            size: auto;\n            margin: 0;\n        }\n\n        html {\n            margin: 0;\n            padding: 0;\n        }\n\n        body {\n            margin: 0;\n            padding: 10px 15px;\n        }\n\n        .clickvox-quote-wrapper {\n            display: none;\n        }\n    </style>\n</head>\n\n<body>\n    <h2>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</h2>\n    <table border=\"0\">\n        <tbody>\n            <tr>\n                <th style=\"text-align: left;\">Subject: </th>\n                <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n                <th style=\"text-align: left;\">Date: </th>\n                <td>"
    + alias2(lookupProperty(helpers,"format_date").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),lookupProperty(helpers,"tr").call(alias3,"format.datetime_s",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":42,"column":47},"end":{"line":42,"column":71}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":42,"column":74}}}))
    + "</td>\n            </tr>\n            <tr>\n                <th style=\"text-align: left;\">From: </th>\n                <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"fromMailAddress") : stack1), depth0))
    + "</td>\n            </tr>\n            <tr>\n                <th style=\"text-align: left;\">To: </th>\n                <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"to") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":20},"end":{"line":53,"column":29}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":65,"column":19}}})) != null ? stack1 : "")
    + "        </tbody>\n    </table>\n    <hr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? lookupProperty(stack1,"htmlBody") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":73,"column":11}}})) != null ? stack1 : "")
    + "</body>\n\n</html>";
},"useData":true})