import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
} from 'BootQuery/Assets/js/globalTypes';
import { TicketReportMethod } from '../../types';

export const getReportMethods = async (params: GetListingParams = {}) => {
  const { data } = await Api.get<TicketReportMethod[]>('/api/ticketing/reportMethods', {
    params,
  });

  return data;
};
