import { PropsWithChildren, ReactElement } from 'react';
import { HStack, Text } from '@chakra-ui/react';

interface Props {
  label: string;
}

export const ChangedItem = ({
  label,
  children,
}: PropsWithChildren<Props>): ReactElement => (
  <HStack
    flexDirection={['column', 'column', 'column', 'row']}
    alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
    px={3}
    py={2}
    spacing={3}
    w="full"
  >
    <Text as="span">{`${label}:`}</Text>
    {children}
  </HStack>
);
