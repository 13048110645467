import { uniqid } from 'app/assets/js/tsutil';

import {
  dismissLocalNotification,
  showLocalNotification,
} from './local-notifications';
import { notificationRegistry } from './notification-registry';
import { NotificationHandle, NotificationProps } from './types';
import {
  checkCanUseWorkers,
  dismissWorkerNotification,
  showWorkerNotification,
} from './worker-notifications';

/**
 * Show a Web Notification and handle it's callbacks and lifecycle.
 *
 * A handle is returned allowing you to identify and manually hide the
 * notification using {@link dismissNotification}
 *
 * @returns A notification handle
 */
export async function showNotification<ActionType extends string = string>(
  props: NotificationProps<ActionType>
): Promise<NotificationHandle> {
  await Notification.requestPermission();

  const handle = uniqid();
  const canUseWorkers = await checkCanUseWorkers();
  const hasActions = !!props.actions?.length;

  // Actions are only supported for notifications fired from service workers.
  // So if we have actions, try to use a service worker notification.
  // Otherwise, use a normal, local notification
  if (hasActions && canUseWorkers) {
    showWorkerNotification(handle, castProps(props));
  } else {
    showLocalNotification(handle, castProps(props));
  }

  return handle;
}

/**
 * Dismiss a notification created using {@link showNotification}
 *
 * @param handle The handle returned from {@link showNotification}
 */
export async function dismissNotification(
  handle: NotificationHandle
): Promise<void> {
  const notification = notificationRegistry[handle];
  delete notificationRegistry[handle];
  if (!notification) {
    console.warn(`Tried to dismiss gone notification: ${handle}`);

    return;
  }

  if (notification.type === 'worker') {
    await dismissWorkerNotification(notification);
  } else {
    dismissLocalNotification(notification);
  }
}

function castProps<A extends string>(
  props: NotificationProps<A>
): NotificationProps<string> {
  return props as unknown as NotificationProps<string>;
}
