import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowTypesSelectOptions } from './types';

export const useFileFieldDefaultOptions = () => {
  const { t } = useTranslation();

  const defaultOptions: AllowTypesSelectOptions[] = useMemo(
    () => [
      { label: t('global:file_types.application'), value: 'application/*' },
      { label: t('global:file_types.audio'), value: 'audio/*' },
      { label: t('global:file_types.font'), value: 'font/*' },
      { label: t('global:file_types.image'), value: 'image/*' },
      { label: t('global:file_types.model'), value: 'model/*' },
      { label: t('global:file_types.text'), value: 'text/*' },
      { label: t('global:file_types.video'), value: 'video/*' },
    ],
    [t]
  );

  return defaultOptions;
};
