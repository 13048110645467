import { ChangeEvent, ReactElement, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaList } from 'react-icons/fa';

import { Portal } from 'BootQuery/Assets/components/Portal';

import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { SettingsModal } from './SettingsModal';
import { SelectFieldEditorSettings } from './types';

export const SelectFieldSettingsComponent = (
  props: EditorFieldComponentProps<SelectFieldEditorSettings>
): ReactElement => {
  const { name, required, canCreateNew = true } = props.content.settings;
  const { setSetting, onChange } = useFieldSettings(props);
  const [itemEditOpen, setItemEditOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setRequired = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('required', ev.target.checked);
  };
  const setCanCreateNew = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('canCreateNew', ev.target.checked);
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input placeholder="Name" value={name} onChange={setName} size="sm" />
      </FormControl>
      <Checkbox isChecked={required ?? false} onChange={setRequired}>
        {t('global:form_editor.required')}
      </Checkbox>
      <Checkbox isChecked={canCreateNew} onChange={setCanCreateNew}>
        {t('global:form_editor.can_create_new')}
      </Checkbox>

      <Divider />

      <Button
        display="flex"
        alignItems="center"
        colorScheme="blue"
        w="full"
        onClick={() => setItemEditOpen(true)}
      >
        <FaList />
        &nbsp;&nbsp; {t('global:form_editor.edit_select_items')}
      </Button>
      <Portal>
        <SettingsModal
          isOpen={itemEditOpen}
          onClose={() => setItemEditOpen(false)}
          value={props.content.settings}
          onChange={(settings) => {
            onChange({ ...props.content, settings });
          }}
        />
      </Portal>
    </VStack>
  );
};
