import { ItemProgram } from '../../types';
import { getGroups } from './getGroups';

interface SearchGroupsParams {
  parentID?: number;
  search?: string;
}
export const searchGroups = async ({
  search,
  parentID,
}: SearchGroupsParams): Promise<ItemProgram[]> => {
  const products = await getGroups({
    params: {
      filters: {
        $search: search,
        ...(parentID
          ? {
              'parentProgramID:eq': parentID,
            }
          : {}),
      },
    },
  });

  return products.data;
};
