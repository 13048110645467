import { Box, HStack } from '@chakra-ui/react';

import { iconForContactType } from '@bq/components/ContactDisplay/util';
import { CellProps } from '@bq/components/Table';

import { CustomerContactDisplay } from '../../../TicketingContactDisplay/CustomerContactDisplay';
import { Ticket } from '../../../types';

export const TicketCustomerCell = (props: CellProps<Ticket>) => {
  if (props.row.customer) {
    const { type, ID } = props.row.customer;
    const Icon = iconForContactType(type);

    return (
      <HStack rounded="sm" w="full">
        <Box w="fit-content">
          <Icon />
        </Box>
        <CustomerContactDisplay
          valueFontWeight="normal"
          contactID={ID}
          contactType={type}
          contactName={
            type === 'company'
              ? props.row.customer.name
              : props.row.customer.fullName
          }
        />
      </HStack>
    );
  }

  return <>-</>;
};
