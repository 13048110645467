import i18n from 'BootQuery/Assets/js/i18n';
import { TemplateElement } from '@bq/components/RichText/Placeholders';
import { PartialLoadedOffer } from '../../types';

export const contactNameTemplate = (
  translationKey: string,
  valueKey: 'offerer' | 'buyer'
): TemplateElement<PartialLoadedOffer> => ({
  key: `${valueKey}.name`,
  title: () => i18n.t(translationKey),
  getValue: (foundValue) => {
    const specificValue = foundValue[valueKey];
    if (specificValue) {
      return specificValue.name ?? '-';
    }

    return '-';
  },
});

export const offererNameTemplate = () => {
  return contactNameTemplate('Sales:offerer', 'offerer');
};
export const buyerNameTemplate = () => {
  return contactNameTemplate('Sales:buyer', 'buyer');
};
