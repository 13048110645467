import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import { ChatEventCard } from './ChatEventCard';
import {
  ChatContactColumn,
  ChatEventColumn,
  ChatTypeColumn,
  ChatUserColumn,
} from './columns';

export const chatEventProviders: ModuleEventTypeProviders = {
  customerChat: {
    components: {
      Card: ChatEventCard,
    },
    columns: {
      type: { Cell: ChatTypeColumn },
      event: { Cell: ChatEventColumn },
      user: { Cell: ChatUserColumn },
      contact: { Cell: ChatContactColumn },
    },
  },
};
