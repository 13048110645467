import { ReactElement } from 'react';

import { EmailsList } from '@bq/components/EmailsList';

import { ApiEmail } from './types';

interface EmailListProps {
  emails: ApiEmail[];
}

export const EmailList = ({
  emails: apiEmails,
}: EmailListProps): ReactElement => {
  const emails = apiEmails.map(({ email }) => email.email);

  return <EmailsList emails={emails} />;
};
