export const bigIntAbs = (value: bigint) => {
  return value < 0n ? -value : value;
};

export const parseDecimalPart = (value: bigint, decimalDivisor: bigint) => {
  const divisorToString = String(decimalDivisor);
  const valueToString = String(value);

  const padWithZero = valueToString.padStart(
    divisorToString.length - valueToString.length,
    '0'
  );

  return padWithZero;
};
