import { forwardRef, ReactElement, useCallback } from 'react';
import { Box, chakra, Input, InputProps } from '@chakra-ui/react';

import { useAutosizingInput } from './use-autosizing-input';

const InlineEditInput = chakra(Input, {
  baseStyle: {
    borderStyle: 'dashed',
    borderWidth: 'thin',
    borderColor: 'gray.300',
    _hover: {
      borderColor: 'gray.500',
    },
    _focus: {
      borderColor: 'gray.900',
    },
    px: '2',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const InlineEdit = forwardRef<HTMLInputElement, InputProps>(
  (props, ref): ReactElement => {
    const strVal = (props.value ?? '').toString();
    const autosizeRef = useAutosizingInput(strVal);
    const setRefs = useCallback(
      (el: HTMLInputElement) => {
        if (ref) {
          if (typeof ref === 'function') {
            ref(el);
          } else {
            ref.current = el;
          }
        }

        autosizeRef(el);
      },
      [autosizeRef, ref]
    );

    return (
      <Box display="flex">
        <InlineEditInput variant="unstyled" {...props} ref={setRefs} />
      </Box>
    );
  }
);
InlineEdit.displayName = 'InlineEdit';
