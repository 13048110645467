import { useMemo } from 'react';
import { Box, ButtonGroup, HStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AsyncSelect } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { usePortalContainer } from '@bq/components/Portal/PortalContext';
import { modalSelect, selectStyles } from '@bq/components/Select/select-styles';

import { searchContacts } from './API/searchContacts';
import { ContactOption } from './Components/ContactOption';
import { ContactSelectDetails } from './Components/ContactSelectDetails';
import { ContactValue } from './Components/ContactValue';
import {
  ContactSelectOption,
  ContactSelectSingleProps,
  ContactSelectSingleValue,
  RawContactSelectOption,
} from './types';
import { useContactEditButton } from './use-edit-button';
import { useHandleOnChangeSingleSelect } from './use-handle-on-change-single-select';
import { isEditableType, loadSingleValue } from './utils';

export const SingleContactSelect = ({
  onChange,
  value,
  allowedTypes = {
    company: true,
    person: true,
    companyLocation: false,
    companyDepartment: false,
    user: false,
  },
  createMode = 'saveInForm',
  isDisabled,
  isClearable,
  isInvalid = false,
  hasDetails,
  contactDetilsChildren,
  hasManualOption = false,
  hasEditModal = false,
  size = { base: 'lg', md: 'sm', lg: 'sm' },
}: ContactSelectSingleProps) => {
  /** In some cases we only use only contact ID as value */
  /** We are missing name, so we have to load it from api */
  /** In most cases this useQuery will just instanly return value */
  /** We won't fetch from api */
  const { data, isLoading } = useQuery({
    queryKey: [value],
    queryFn: async () => loadSingleValue(value),
  });
  const { t } = useTranslation();

  const handleChange = useHandleOnChangeSingleSelect();

  const isEditModalVisible = useMemo(
    () => editModalVisible({ hasEditModal, value }),
    [hasEditModal, value]
  );

  const editButtonFn = useContactEditButton(value, onChange);

  return (
    <HStack w="full" spacing={0}>
      <Box flex="1 1 auto" overflowX="hidden">
        <AsyncSelect<ContactSelectOption, false>
          selectedOptionColorScheme="brand"
          isDisabled={isDisabled}
          isLoading={isLoading}
          isInvalid={isInvalid}
          value={data ?? null}
          size={size ?? { base: 'lg', md: 'sm', lg: 'sm' }}
          useBasicStyles
          components={{ Option: ContactOption, SingleValue: ContactValue }}
          isClearable={isClearable}
          isSearchable
          defaultOptions
          cacheOptions
          menuPortalTarget={usePortalContainer().current}
          loadOptions={async (search: string) => {
            const data = await searchContacts({
              search,
              allowedTypes,
              nonCreatable: createMode === 'none',
              noManual: !hasManualOption,
            });

            return data;
          }}
          styles={modalSelect}
          chakraStyles={selectStyles}
          onChange={(newValue) => {
            handleChange(newValue, onChange, createMode);
          }}
        />
      </Box>
      <ButtonGroup isAttached size={size} flex="0 1 auto">
        {isEditModalVisible && (
          <IconButton
            icon={<FaEdit />}
            label={t('global:edit')}
            borderLeftRadius={0}
            onClick={() => {
              editButtonFn();
            }}
          />
        )}
        {validTypeForContactDetails(value) && hasDetails && (
          <ContactSelectDetails contactID={value.ID} contactType={value.type}>
            {contactDetilsChildren}
          </ContactSelectDetails>
        )}
      </ButtonGroup>
    </HStack>
  );
};

const editModalVisible = ({
  hasEditModal = false,
  value,
}: {
  hasEditModal?: boolean;
  value?: ContactSelectSingleValue;
}): boolean => {
  return (
    value !== null &&
    value !== undefined &&
    isEditableType(value?.type) &&
    hasEditModal
  );
};

const validTypeForContactDetails = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
): value is RawContactSelectOption => {
  return (
    value !== null &&
    value !== undefined &&
    typeof value === 'object' &&
    !('$new' in value) &&
    value.type !== 'manual' /* &&
    'ID' in value &&
    value.ID !== undefined &&
    typeof value.ID === 'number' &&
    'name' in value &&
    value.name !== undefined &&
    typeof value.name === 'string' &&
    'type' in value &&
    value.type !== undefined */
  /** This commented code are stricter checks , idk should I leave it */
  /** This might be uncommented if we get errors on this */
  );
};
