module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col\">\n        "
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"icon",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":35}}})) != null ? stack1 : "")
    + "\n        <span class=\"notification-title\">\n            "
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"title",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":40}}})) != null ? stack1 : "")
    + "\n        </span>\n    </div>\n    <div class=\"col-auto\">\n        <small>\n            "
    + container.escapeExpression(lookupProperty(helpers,"format_date").call(alias1,(depth0 != null ? lookupProperty(depth0,"date") : depth0),lookupProperty(helpers,"tr").call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":53}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":55}}}))
    + "\n        </small>\n    </div>\n</div>\n<div class=\"notification-body\">\n    "
    + ((stack1 = lookupProperty(helpers,"block").call(alias1,"body",{"name":"block","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":31}}})) != null ? stack1 : "")
    + "\n</div>";
},"useData":true})