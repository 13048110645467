import { toInternal } from './converters';
import { PriceOptionsWithDefaults, ValidValueTypes } from './types';

/** Very simple we just add multiple big ints
 * in most cases this should not overflow bigint
 * if the value is so big it would, it would
 * overflow either way
 */
export const addition = (
  baseValue: bigint,
  terms: ValidValueTypes[],
  opts: PriceOptionsWithDefaults
) => {
  return terms.reduce((all: bigint, current) => {
    const internal = toInternal(current, opts);
    if (internal) {
      return all + internal;
    }

    return all;
  }, baseValue);
};

/** Same as above */
export const subtraction = (
  baseValue: bigint,
  terms: ValidValueTypes[],
  opts: PriceOptionsWithDefaults
) => {
  return terms.reduce((all: bigint, current) => {
    const internal = toInternal(current, opts);
    if (internal) {
      return all - internal;
    }

    return all;
  }, baseValue);
};

/** Here is where it gets complicated
 * if we multiply many big ints, it will very quickly overflow
 * so we have to slow multiply
 */
export const multiplication = (
  baseValue: bigint,
  terms: ValidValueTypes[],
  opts: PriceOptionsWithDefaults
) => {
  return terms.reduce((all: bigint, current) => {
    const internal = toInternal(current, opts);
    if (internal || internal === 0n) {
      /** When we convert to internal we multiply it by 10 ** calculating
       * here we divide it by that so we don't overflow
       */
      const temp =
        (all * internal) /
        10n ** BigInt(parseInt(`${opts.calculatingDecimals}`, 10));

      return temp;
    }

    return all;
  }, baseValue);
};

export const division = (
  baseValue: bigint,
  terms: ValidValueTypes[],
  opts: PriceOptionsWithDefaults
) => {
  return terms.reduce((all: bigint, current) => {
    const internal = toInternal(current, opts);
    if (internal) {
      const wholeNumberPart =
        (all / internal) *
        10n ** BigInt(parseInt(`${opts.calculatingDecimals}`, 10));
      const decimalPart =
        ((all % internal) *
          10n ** BigInt(parseInt(`${opts.calculatingDecimals}`, 10))) /
        internal;

      return wholeNumberPart + decimalPart;
    }

    return all;
  }, baseValue);
};
