import { useParams } from 'react-router-dom';

export function usePathParam(paramName: string): string {
  const param = useParams()[paramName];
  if (!param) {
    throw new Error(`Missing route param "${paramName}"`);
  }

  return param;
}

export function useNumericPathParam(paramName: string): number {
  const param = usePathParam(paramName);

  const paramNum = parseInt(param, 10);
  if (Number.isNaN(paramNum)) {
    throw new Error(`Invalid numeric route param "${paramName}"`);
  }

  return paramNum;
}
