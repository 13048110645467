import { getCaseTypes } from '../../api/CaseTypes/getCaseTypes';
import { CaseType } from '../../schemas';
import { CampaignTypeOption } from './types';

export const caseTypeLoadOptions = async (search: string) => {
  const data = await getCaseTypes({
    filters: {
      $search: search,
    },
    fields: ['ID', 'name'],
  });

  return caseTypesFilterVal(data.data);
};

export const caseTypesFilterVal = (types: CaseType[]): CampaignTypeOption[] => {
  return types.map((type) => ({
    label: type.name,
    value: type.ID,
  }));
};
