/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { translations as inTranslations, rawLocale as inRawLocale } from './locales-TRANSLATION_SOURCE';
import { TranslationCollection } from './types';

export const translations = inTranslations as TranslationCollection[];
export const rawLocale = inRawLocale as boolean;
