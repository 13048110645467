import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

export const NameField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<ItemBaseFields>();

  return (
    <FormControl isInvalid={!!errors.name} {...formControlProps}>
      <FormLabel htmlFor="name">{t('Products:name')}</FormLabel>
      <Input
        size={['md', 'md', 'sm']}
        {...register('name', { required: true })}
        isInvalid={!!errors.name}
        placeholder={t('Products:name')}
      />
    </FormControl>
  );
};
