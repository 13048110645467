import { ExportParams, ExportResponse } from '@bq/components/Export';
import { Api } from 'BootQuery/Assets/js/api';

export async function generateExport(
  params: ExportParams
): Promise<ExportResponse> {
  const { data } = await Api.get<ExportResponse>(
    '/api/ticketing/tickets/export',
    { params }
  );

  return data;
}
