import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CaseInterestLevel } from '../../schemas';

type InterestLeveFormProps = Omit<CaseInterestLevel, 'ID'>;

export const InterestLevelFormFields = () => {
  const { register } = useFormContext<InterestLeveFormProps>();
  const { t } = useTranslation();

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('global:name')}</FormLabel>
        <Input {...register('name')} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('global:value')}</FormLabel>
        <Input {...register('value')} />
      </FormControl>
    </VStack>
  );
};
