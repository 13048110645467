import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useFormDefinition } from '@bq/components/FormEditor';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { Api } from 'BootQuery/Assets/js/api';

import { Contact } from '../../types';
import { PinnedContact } from '../HopsOperator/types';
import { SpeedDialEdit } from './SpeedDialEdit';
import { setInputValue } from './util';

interface Props {
  groupID: number | string;
  isOpen: boolean;
  onClose: () => void;
}

export const OperatorSpeedDialModal = ({
  isOpen,
  onClose,
  groupID,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const customFields = useFormDefinition('Telephony.callForm');
  const { data: contacts } = usePinnedContacts(groupID);

  const [value, setValue] = useState<Contact[]>(contacts ?? []);
  const cancel = () => {
    onClose();
  };
  const submit = () => {
    if (value) {
      const pinnedVal = value.map(({ ID, type }) => ({ ID, type }));
      setInputValue('operatorPinedContacts', pinnedVal);
    }
    onClose();
  };

  //   const noValue = value === null;
  useEffect(() => {
    if (contacts !== undefined) {
      setValue(contacts);
    }
  }, [contacts]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancel}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('Telephony:permissions.operator_pinned_contacts')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {contacts && customFields ? (
            <SpeedDialEdit value={value} onChange={setValue} />
          ) : (
            <LoadingPage />
          )}
        </ModalBody>
        {value !== null && (
          <ModalFooter justifyContent="flex-end">
            <FormActions onSubmit={submit} onCancel={cancel} />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

async function getPinnedContacts(groupID: number | string): Promise<Contact[]> {
  const { data: contactList } = await Api<PinnedContact[]>(
    `/api/telephony/operator/groupPinnedContacts/${groupID}`
  );

  return fetchFullContacts(contactList);
}

export async function fetchFullContacts(
  contacts: PinnedContact[]
): Promise<Contact[]> {
  const personIds = contacts
    .filter((contact) => contact.type === 'person')
    .map((contact) => contact.ID);
  const companyIds = contacts
    .filter((contact) => contact.type === 'company')
    .map((contact) => contact.ID);

  const [personMap, companyMap] = await Promise.all([
    getContactMap('person', personIds),
    getContactMap('company', companyIds),
  ]);
  const contactMap = {
    person: personMap,
    company: companyMap,
  };

  return contacts.map((val) => contactMap[val.type][val.ID]);
}

type ContactMap = Record<number, Contact>;

async function getContactMap(type: string, ids: number[]): Promise<ContactMap> {
  const contacts = await getContactList(type, ids);

  return contacts.reduce(
    (prev, contact) => ({
      ...prev,
      [contact.ID]: contact,
    }),
    {}
  );
}

async function getContactList(type: string, ids: number[]): Promise<Contact[]> {
  if (ids.length === 0) {
    return [];
  }

  const { data } = await Api.get<Contact[]>('/api/phonebook/contacts', {
    params: {
      type,
      filters: {
        'ID:inArray': ids,
      },
    },
  });

  return data;
}

function usePinnedContacts(
  groupID: number | string
): UseQueryResult<Contact[]> {
  return useQuery({
    queryKey: ['Telephony.operator.groupPinnedContacts', groupID],
    queryFn: () => getPinnedContacts(groupID),
  });
}
