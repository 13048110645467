import { ReactElement } from 'react';
import { FormLabel } from '@chakra-ui/react';

import { BaseSettings } from '../types';
import { FieldTitle } from './FieldTitle';

type NameSettings = Pick<BaseSettings, 'name' | 'nameHTML'>;
interface Props extends NameSettings {
  id?: string;
}

export const FieldLabel = ({ name, nameHTML, id }: Props): ReactElement => (
  <FormLabel fontWeight="bold" id={id}>
    <FieldTitle name={name} nameHTML={nameHTML} />:
  </FormLabel>
);
