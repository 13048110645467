import { Center, Divider, Flex, IconButton } from '@chakra-ui/react';
import { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';
import { FilterBarAdvancedButton } from './FilterBarAdvancedButton';
import { FilterBarInput } from './FilterbarInput';
import { useFilterTypesWithGroup } from './filters/GroupFilter';
import { FilterBarProps } from './types';

const FilterBar = memo(
  ({ filterTypes, ...props }: FilterBarProps): ReactElement => {
    const { t } = useTranslation();
    const allFilterTypes = useFilterTypesWithGroup(filterTypes);

    return (
      <Flex flexGrow={1}>
        <FilterBarInput {...props} filterTypes={allFilterTypes} />
        <FilterBarAdvancedButton {...props} filterTypes={allFilterTypes} />
        <Center height="40px" bg="brand.500" py='2.5' w='2px'>
          <Divider orientation="vertical" />
        </Center>
        <IconButton
          aria-label={t('global:filters.submit_filters')}
          icon={<FaSearch />}
          borderLeftRadius={0}
          colorScheme="brand"
          color='brand.font'
        />
      </Flex>
    );
  }
);
FilterBar.displayName = 'FilterBar';

export { FilterBar };
