import { useContext } from 'react';

import { IMailEditorsContext, MailEditorsContext } from './MailEditorsContext';

export function useMailEditorsContext(): IMailEditorsContext {
  const context = useContext(MailEditorsContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
}
