import { FormControl, FormLabel, Checkbox } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FieldProps } from '../../types';
import { Good } from '../../../types';

export const IsCommisionField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const { register } = useFormContext<Good>();

  return (
    <FormControl {...formControlProps}>
      <FormLabel htmlFor="good.isComission">{t('Products:is_comission')}</FormLabel>
      <Checkbox {...register('good.isComission')}></Checkbox>
    </FormControl>
  );
};
