import { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import {
  FilterBar,
  FilterTypes,
  FilterValue,
} from 'BootQuery/Assets/components/FilterBar';
import { Pagination } from 'BootQuery/Assets/components/Pagination';
import {
  TableMenu,
  TableMenuProps,
} from 'BootQuery/Assets/components/TableMenu';

export interface BaseHeadingProps {
  count?: number | null;
  Actions?: ReactElement;
  menuProps?: TableMenuProps;
  filterProps: {
    filterTypes: FilterTypes;
    filters: FilterValue[];
    setFilters: (filters: FilterValue[]) => void;
    search?: string;
    setSearch?: (search: string) => void;
  };
}

export interface PaginatedHeadingProps extends BaseHeadingProps {
  noPagination?: false;
  limit: number;
  page: number;
  setPage: (page: number) => void;
}

export interface UnpaginatedHeadingProps extends BaseHeadingProps {
  noPagination: true;
  limit?: null | number;
  page?: number;
  setPage?: (page: number) => void;
}

export type HeadingProps = PaginatedHeadingProps | UnpaginatedHeadingProps;

export const ListHeading = ({
  count,
  limit,
  filterProps,
  menuProps,
  Actions,
  noPagination,
  page,
  setPage,
}: HeadingProps): ReactElement => (
  <Flex
    w="full"
    justifyContent="center"
    alignItems="center"
    flexWrap="wrap"
    sx={{ gap: '16px' }}
    position="relative"
    zIndex="4"
  >
    <FilterBar
      value={filterProps.filters}
      onChange={filterProps.setFilters}
      search={filterProps.search}
      onSearchChange={filterProps.setSearch}
      filterTypes={filterProps.filterTypes}
    />
    {Actions}
    {!noPagination && (
      <Pagination
        page={page}
        pageCount={count ? Math.ceil(count / limit) : 1}
        onPageChange={setPage}
      />
    )}
    {menuProps && <TableMenu {...menuProps} />}
  </Flex>
);
