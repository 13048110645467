import { ReactElement, useCallback, useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPhone } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';
import { FieldValue, GeneratedForm } from '@bq/components/FormEditor';
import { NumberType } from 'app/Modules/Phonebook/Assets/components/types';
import { useNumberTypes } from 'app/Modules/Phonebook/Assets/components/use-number-types';

import { Call } from '../../types';
import { CallInfo } from './CallInfo';
import { FormModalHeader } from './FormModalHeader';
import { CallFormForm, CallFormModalProps } from './types';

export const CallFormModal = ({
  isOpen,
  onSubmit,
  onCancel,
  callForm,
  formState,
  customFields,
}: CallFormModalProps): ReactElement => {
  const numberTypes = useNumberTypes();
  const forbidCancel = callForm?.forbidCancel ?? false;

  const submit = useCallback(
    (data: CallFormForm) => {
      if (!callForm) {
        throw new Error('Submitted call form without anything to edit');
      }

      onSubmit({ ...data, callId: callForm.call.callId });
    },
    [callForm, onSubmit]
  );

  const cancel = useCallback(() => {
    if (forbidCancel) {
      console.warn('Preventing call form cancel.');

      return;
    }

    onCancel();
  }, [onCancel, forbidCancel]);

  return (
    <Modal size="6xl" scrollBehavior="inside" isOpen={isOpen} onClose={cancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaPhone style={{ display: 'inline' }} />
          &nbsp;
          {callForm && <FormModalHeader {...callForm.call} />}
        </ModalHeader>
        <ModalBody>
          {callForm && numberTypes && (
            <CallFormContent
              onSubmit={submit}
              call={callForm.call}
              customFields={customFields}
              numberTypes={numberTypes}
            />
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions
            form="call-form"
            cancellable={!forbidCancel}
            onCancel={cancel}
            state={formState}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface CallFormContentProps {
  call: Call;
  onSubmit: (data: CallFormForm) => void;
  customFields: FieldValue[];
  numberTypes: NumberType[];
}

const CallFormContent = ({
  call,
  customFields,
  numberTypes,
  onSubmit,
}: CallFormContentProps): ReactElement => {
  const formMethods = useForm<CallFormForm>({
    mode: 'onBlur',
    defaultValues: {
      data: call.data,
      numberType: { value: numberTypes[0].ID, label: numberTypes[0].name },
      phoneNumber: null,
    },
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset({
      data: call.data,
      numberType: { value: numberTypes[0].ID, label: numberTypes[0].name },
      phoneNumber: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(call), reset]);

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ width: '100%' }}
        id="call-form"
        data-ignore-form
        onSubmit={handleSubmit(onSubmit)}
      >
        <CallInfo {...call} />
        <GeneratedForm fields={customFields} />
      </form>
    </FormProvider>
  );
};
