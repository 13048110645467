import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreatableSelect } from 'chakra-react-select';
import {
  chakraSelectStyles,
  formatCreateLabel,
  selectItemToValue,
  SelectOption,
  valueToSelectItem,
} from 'BootQuery/Assets/components/Select';
import { translateAddressTypeOption, useAddressTypes } from './address-types';

interface Props {
  name: string;
}

export const AddressTypeInput = ({ name }: Props): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control } = useFormContext();

  const addressTypes = useAddressTypes();

  if (!addressTypes) {
    return <>...</>;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        const value = valueToSelectItem(field.value);
        const trValue = value ? translateAddressTypeOption(t, value) : value;

        return (
          <CreatableSelect
            options={addressTypes}
            isClearable
            value={trValue}
            onChange={(val: SelectOption | null) => {
              field.onChange(selectItemToValue(val));
            }}
            formatCreateLabel={formatCreateLabel}
            placeholder={t('Phonebook:address_type')}
            styles={chakraSelectStyles}
            selectedOptionColorScheme="brand"
          />
        );
      }}
    />
  );
};
