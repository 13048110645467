import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const offerDateFilter = makeDateFilter({
  name: () => i18n.t('Sales:created_date'),
  toFilter: makeDateToFilter('offerDate'),
});
