import { ColumnMap } from '@bq/components/TableMenu';

export const defaultVisibleColumns: ColumnMap = {
  ID: true,
  state: true,
  title: true,
  customer: true,
  group: true,
  reportMethod: false,
  ticketType: false,
  tags: false,
  priority: true,
  assignedUser: true,
  createdByUser: false,
  createdAt: true,
  updatedAt: false,
  reportedAt: true,
  reminders: true,
};

export const defaultMinimalVisibleColumns: ColumnMap = {
  ID: false,
  state: true,
  title: true,
  customer: false,
  group: false,
  reportMethod: false,
  ticketType: false,
  tags: false,
  priority: false,
  assignedUser: false,
  createdByUser: false,
  createdAt: false,
  updatedAt: true,
  reportedAt: false,
  reminders: false,
};
