import mitt from 'mitt';

import { renderReact } from 'BootQuery/Assets/js/render-react';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

import {
  CasePickerConnector,
  CaseSelectHandler,
  SelectCaseOptions,
} from '../components/Cases/Form/Events/CasePickerModal';

export async function pickCase(
  onSelect: CaseSelectHandler,
  options?: SelectCaseOptions
): Promise<void> {
  await ensureRootExists();

  pickCaseEventBus.emit('pickCase', { onSelect, options });
}

export type PickCaseEventBus = {
  pickCase: {
    onSelect: CaseSelectHandler;
    options?: SelectCaseOptions;
  };
};

export const pickCaseEventBus = mitt<PickCaseEventBus>();
export const usePickCaseEvent = createUseMitt(pickCaseEventBus);

async function ensureRootExists(): Promise<void> {
  const existing = document.querySelector('#case-picker-connector-root');
  if (existing) {
    return;
  }

  const root = document.createElement('div');
  root.id = 'case-picker-connector-root';
  document.body.append(root);

  await new Promise<void>((resolve) => {
    renderReact(root, CasePickerConnector, {});
    setTimeout(() => {
      resolve();
    });
  });
}
