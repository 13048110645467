import mitt from 'mitt';

import { Call } from 'app/Modules/Telephony/Assets/types';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

export type OfferEventTypes = {
  'call/new': Call;
  'call/remove': number;
  'chat/remove': number;
  'tab/switch': string;
};

export const offerEventBus = mitt<OfferEventTypes>();
export const useCaseEvents = createUseMitt(offerEventBus);
