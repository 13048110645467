import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

interface LimitNullGetListingParams extends Omit<GetListingParams, 'limit'> {
  limit?: number | null;
}
export interface MailInfo {
  ID: number;
  email: string;
}
export const getEmailForGroups = async <T = MailInfo>(
  params: LimitNullGetListingParams = {}
) => {
  const data = await Api.get<T[]>('/api/mails/searchAccounts', {
    params,
  });

  return data;
};
