import { ReactElement, useCallback } from 'react';
import { Flex, IconButton, Input, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import { useAutosizingInput } from 'BootQuery/Assets/components/use-autosizing-input';
import i18n from 'BootQuery/Assets/js/i18n';

import { FilterName } from '../FilterName';
import { FilterOperatorInput, FilterTagOperator } from '../FilterOperator';
import { FilterProps, FilterType } from '../types';

interface TextInputProps {
  value?: string | null;
  onChange?: (value: string) => void;
  onRemove: () => void;
  isNew?: boolean;
}

const FilterTextTag = ({
  value = '',
  onChange,
  onRemove,
  isNew,
}: TextInputProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');
  const handleChange = useCallback(
    (value: string) => onChange && onChange(value),
    [onChange]
  );

  const inputEl = useAutosizingInput(value || '');

  return (
    <Flex background={bgColor} height="8" borderRightRadius="md">
      <Input
        value={value || ''}
        onChange={(ev) => handleChange(ev.target.value)}
        ref={inputEl}
        minWidth="4"
        border="none"
        background="none"
        borderLeftRadius={0}
        size="sm"
        autoFocus={isNew}
      />
      <IconButton
        onClick={onRemove}
        variant="link"
        aria-label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};

const FilterTextInput = ({
  value = '',
  onChange,
  onRemove,
}: TextInputProps): ReactElement => {
  const handleChange = useCallback(
    (value: string) => onChange && onChange(value),
    [onChange]
  );

  return (
    <Input
      value={value || ''}
      onChange={(ev) => handleChange(ev.target.value)}
    />
  );
};

export const TextTag = ({
  name,
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
  isNew,
}: FilterProps<string>): ReactElement => (
  <Flex>
    <FilterName name={name} />
    {operators.length > 0 && (
      <FilterTagOperator
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterTextTag
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      isNew={isNew}
    />
  </Flex>
);

export const TextInput = ({
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
}: FilterProps<string>): ReactElement => (
  <>
    {operators.length > 0 && (
      <FilterOperatorInput
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterTextInput value={value} onChange={onChange} onRemove={onRemove} />
  </>
);

type TextFilter = FilterType<string>;
type TextUserDef = Partial<TextFilter> & Pick<TextFilter, 'toFilter' | 'name'>;
export function makeTextFilter(filterDef: TextUserDef): TextFilter {
  return {
    tagComponent: TextTag,
    inputComponent: TextInput,
    operators: () => [
      { operator: 'contains:ci', display: i18n.t('global:operators.contains') },
      { operator: 'eq:ci', display: i18n.t('global:operators.is') },
      {
        operator: 'startsWith:ci',
        display: i18n.t('global:operators.starts_with'),
      },
      {
        operator: 'endsWith:ci',
        display: i18n.t('global:operators.ends_with'),
      },
      {
        operator: 'contains:not:ci',
        display: i18n.t('global:operators.contains_not'),
      },
      { operator: 'neq:ci', display: i18n.t('global:operators.is_not') },
      {
        operator: 'startsWith:not:ci',
        display: i18n.t('global:operators.does_not_start_with'),
      },
      {
        operator: 'endsWith:not:ci',
        display: i18n.t('global:operators.does_not_end_with'),
      },
    ],
    defaultValue: '',
    ...filterDef,
  };
}
