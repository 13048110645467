import { DisplayMode } from 'BootQuery/Assets/components/TableMenu';
import { useUserSetting } from 'BootQuery/Assets/js/user-settings';

export function useDisplayMode(defaultMode: DisplayMode = 'table') {
  return useUserSetting<DisplayMode>(
    'Telephony.CallList.displayMode',
    defaultMode,
    false
  );
}
