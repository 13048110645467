import {
  Box,
  Button,
  HStack,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  UnorderedList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { useModalInstance } from '../UseModal';
import { ConfirmationModalProps } from './types';

export const ConfirmationModalContent = ({
  children,
  icon,
  results,
  onConfirm,
  onDecline,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader as={HStack} spacing={6}>
        <Box>{icon}</Box>
        <Box>{`${t('global:confirm_action')}`}</Box>
      </ModalHeader>
      <ModalBody>
        <Box fontWeight="bold" fontSize="md">
          {t('global:this_will_result_in')}:
        </Box>
        <UnorderedList>
          {results?.map((item) => <ListItem key={item}>{item}</ListItem>)}
          {children && children}
        </UnorderedList>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outline"
          colorScheme="red"
          mr={3}
          onClick={() => {
            if (onDecline) {
              onDecline();
            }
            closeWithNoCallback();
          }}
          leftIcon={<FaTimes />}
        >
          {t('global:cancel')}
        </Button>
        <Button
          variant="outline"
          leftIcon={<FaCheck />}
          colorScheme="green"
          onClick={() => {
            if (onConfirm) {
              onConfirm();
            }
            closeWithNoCallback();
          }}
        >
          {t('global:submit')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
