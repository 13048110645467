import { ReactElement } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { DurationCounter } from '../DurationCounter';
import { useCalcDuration } from '../use-calc-duration';
import { CallListCall } from './types';

export const CallDurationCell = ({
  row: call,
}: CellProps<CallListCall>): ReactElement => {
  const blue = useColorModeValue('blue.400', 'blue.600');
  const calcDuration = useCalcDuration();

  const startAt = new Date(call.startAt);
  const endAt = call.endAt ? new Date(call.endAt) : null;

  if (!endAt) {
    return <DurationCounter color={blue} from={startAt} />;
  }

  return <Box as="span">{calcDuration(startAt, endAt)}</Box>;
};
