import Module from 'BootQuery/Assets/js/module';
import { ReactRoutes } from './react-routes';

export default class Reporting extends Module {
  static matchReactRoute = '/reporting/';

  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  activateElements(target, data) {
    target
      .findElement('.reporting-print-btn')
      .ev('click.reporting', (e) => {
        e.preventDefault();
        const link = $(e.currentTarget).attr('href');
        const iframe = $('<iframe>');
        iframe.attr('src', link).css({ display: 'none' });
        $('body').append(iframe);
        iframe.get(0).onload = () => {
          const cw = iframe.get(0).contentWindow;
          cw.print();
          // Sometimes this kind of breaks?
          cw.onafterprint = () => {
            setTimeout(() => {
              iframe.remove();
            }, 100);
          };
        };
      });
  }
}
