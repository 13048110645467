import { ReactElement, useEffect, useState } from 'react';
import hr from 'date-fns/locale/hr';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import { Input, useColorMode } from '@chakra-ui/react';

import { ChakraDateInput } from '../ChakraDateInput/ChakraDateInput';
import {
  DateInputProps,
  DateRangeInputProps,
  SingleDateInputProps,
} from './types';

registerLocale('hr', hr);
setDefaultLocale('hr');

interface InternalDateRange {
  from: Date | null;
  to: Date | null;
}

function dateFormat(time: boolean): string {
  return time ? 'dd. MM. yyyy. HH:mm' : 'dd. MM. yyyy.';
}

export const SingleDateInput = ({
  value,
  onChange,
  onBlur,
  isClearable,
  autoFocus,
  inputClass,
  size,
  showTime = false,
  inputRef,
  withPortal,
}: Omit<SingleDateInputProps, 'mode'>): ReactElement => {
  return (
    <ChakraDateInput
      inputRef={inputRef}
      showTime={showTime}
      size={size}
      inputClass={inputClass ?? 'datepicker-input'}
      value={value}
      onBlur={onBlur}
      isClearable={isClearable}
      autoFocus={autoFocus}
      withPortal={withPortal}
      onChange={(date) => {
        if (date instanceof Date) {
          onChange(date);
        } else if (isClearable && !date) {
          onChange(null);
        }
      }}
    />
  );
};

export const DateRangeInput = ({
  value,
  onChange,
  onBlur,
  inputClass,
  isClearable,
  autoFocus,
  showTime = false,
  withPortal,
  size,
}: Omit<DateRangeInputProps, 'mode'>): ReactElement => {
  const { colorMode } = useColorMode();
  const [internalValue, setInternalValue] = useState<InternalDateRange>({
    from: null,
    to: null,
  });

  useEffect(() => {
    if (!value) {
      return;
    }

    const currentlyPicking =
      (internalValue?.from === null) !== (internalValue?.to === null);
    const valuesChanged =
      internalValue.from !== value.from || internalValue.to !== value.to;
    if (!currentlyPicking && valuesChanged) {
      setInternalValue(value);
    }
  }, [value, internalValue]);

  const onValueChange = (val: InternalDateRange) => {
    setInternalValue(val);
    const { from, to } = val;
    if (from && to) {
      onChange({ from, to });
    } else if (!from && !to) {
      onChange(null);
    }
  };

  return (
    <DatePicker
      selectsRange={true}
      startDate={internalValue.from}
      endDate={internalValue.to}
      locale="hr"
      dateFormat={dateFormat(showTime)}
      className={inputClass ?? 'datepicker-input'}
      calendarClassName={colorMode}
      onBlur={onBlur}
      isClearable={isClearable}
      showTimeInput={showTime}
      autoFocus={autoFocus}
      customInput={<Input size={size} />}
      withPortal={withPortal}
      onChange={(newVal) => {
        if (newVal instanceof Date) {
          throw new Error('Got single date in date range picker');
        }

        const [from, to] = newVal || [null, null];
        onValueChange({ from, to });
      }}
    />
  );
};

export const DateInput = (props: DateInputProps): ReactElement => {
  if (props.mode === 'range') {
    // Make sure it's always date range
    const fixedVal = !(props.value instanceof Date) ? props.value : null;

    return <DateRangeInput {...props} value={fixedVal} />;
  }

  // Make sure it's always a date
  return <SingleDateInput {...props} />;
};
