import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import { MailContactColumn } from './MailContactColumn';
import { MailEventCard } from './MailEventCard';
import { MailEventColumn } from './MailEventColumn';
import { MailTypeColumn } from './MailTypeColumn';
import { MailUserColumn } from './MailUserColumn';

export type { MailEvent } from './types';

export { MailEventCard };

export const mailEventProvider: ModuleEventTypeProviders = {
  mail: {
    components: {
      Card: MailEventCard,
    },
    columns: {
      type: { Cell: MailTypeColumn },
      event: { Cell: MailEventColumn },
      user: { Cell: MailUserColumn },
      contact: { Cell: MailContactColumn },
    },
  },
};
