module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "border-primary\n	";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"syncError") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":3,"column":1},"end":{"line":5,"column":1}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "border-danger\n	";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"syncNotSynced") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":1},"end":{"line":5,"column":1}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"text-primary fas fa-spinner fa-spin\"></span>\n					Ažuriranje u tijeku...\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"syncOk") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":13,"column":5},"end":{"line":22,"column":5}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"text-success fas fa-check\"></span>\n					Registar je ažuran.\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"syncError") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":16,"column":5},"end":{"line":22,"column":5}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"text-danger fas fa-exclamation\"></span>\n					Greška pri zadnjoj sinkronizaciji.\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"syncNotSynced") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":5},"end":{"line":22,"column":5}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "					<span class=\"text-danger fas fa-clock\"></span>\n					Registar nije ažuriran u zadnja 24 sata.\n					";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<small class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isFreshEnough") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":75}}})) != null ? stack1 : "")
    + "\">\n					Zadnje uspješno ažuriranje: "
    + container.escapeExpression(lookupProperty(helpers,"format_date").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"lastSuccesfullSync") : depth0)) != null ? lookupProperty(stack1,"syncEnd") : stack1),lookupProperty(helpers,"tr").call(alias1,"format.datetime",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":27,"column":74},"end":{"line":27,"column":96}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":27,"column":33},"end":{"line":27,"column":98}}}))
    + "\n				</small>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "text-muted";
},"22":function(container,depth0,helpers,partials,data) {
    return "text-danger";
},"24":function(container,depth0,helpers,partials,data) {
    return "				<small class=\"text-danger\">\n					Registar nikada nije ažuriran\n				</small>\n";
},"26":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"doNotCall-sync-status\" class=\"card mb-3\n	"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":1},"end":{"line":5,"column":8}}})) != null ? stack1 : "")
    + "\">\n	<div class=\"card-body\">\n		<div class=\"row\">\n			<div class=\"col\">\n				<h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":10,"column":5},"end":{"line":22,"column":12}}})) != null ? stack1 : "")
    + "				</h4>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastSuccesfullSync") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "				</small>\n			</div>\n			<div class=\"col-auto d-flex align-items-center\">\n				<button type=\"button\" class=\"btn btn-primary donotcall-sync-btn\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"syncInProgress") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":69},"end":{"line":37,"column":107}}})) != null ? stack1 : "")
    + "> <span\n				 class=\"fas fa-sync-alt\"></span>\n					Ažuriraj\n				</button>\n			</div>\n		</div>\n	</div>\n</div>";
},"useData":true})