import {
  FieldValue,
  getFormDefinition,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';
import { QueryClient } from '@tanstack/react-query';

type ID = number|string;

export const getCustomEventForm = (
  queryClient: QueryClient,
  typeID: ID
): Promise<FieldValue[]> => getFormDefinition(queryClient, `CustomEventTypes.${typeID}`);

export const useCustomEventForm = (typeID: ID): FieldValue[] | null => useFormDefinition(`CustomEventTypes.${typeID}`);
