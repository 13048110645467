import { ReactElement, SetStateAction, useContext } from 'react';
import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { applySetState } from 'app/assets/js/apply-set-state';

import { FilterEditor } from '../../FilterBar/FilterEditor';
import { FilterTypes } from '../../FilterBar/types';
import { GroupVisibilityMap, IOverviewEditorItemId } from '../types';
import { GroupVisibilitySettings } from './GroupVisibilitySettings';
import { OverviewEditorContext } from './OverviewEditorContext';
import { findOverview, genSlug, setOverview } from './util';

interface OverviewSettingsProps {
  filterTypes: FilterTypes;
}

export const OverviewSettings = ({
  filterTypes,
}: OverviewSettingsProps): ReactElement => {
  const { t } = useTranslation();

  const { tempOverviews, setTempOverviews, selectedId, withGroupVisibility } =
    useContext(OverviewEditorContext);
  const selected = selectedId ? findOverview(tempOverviews, selectedId) : null;
  if (!selectedId || !selected) {
    return <h1>{t('global:overviews.select_overview_to_edit')}</h1>;
  }

  const set = (changes: Partial<IOverviewEditorItemId>) => {
    setTempOverviews(
      setOverview(tempOverviews, selectedId, { ...selected, ...changes })
    );
  };
  const setTitle = (title: string) => {
    set({
      title,
      slug: genSlug(tempOverviews, title, selected.id),
    });
  };
  const setGroupVisibility = (change: SetStateAction<GroupVisibilityMap>) => {
    set({
      groupVisibility: applySetState(selected.groupVisibility ?? {}, change),
    });
  };

  return (
    <Box w="full">
      <VStack>
        <FormControl id="overview-title">
          <Flex alignContent="center">
            <FormLabel w="20%" h="10" textAlign="right">
              {t('global:name')}:{' '}
            </FormLabel>
            <Box w="80%">
              <Input
                value={selected.title}
                onChange={(ev) => setTitle(ev.target.value)}
              />
            </Box>
          </Flex>
        </FormControl>
        <FormControl display="flex" alignContent="center" id="overview-visible">
          <FormLabel w="20%" textAlign="right">
            {t('global:overviews.visible')}:{' '}
          </FormLabel>
          <Box w="80%" h="10">
            <Switch
              isChecked={selected.visible}
              onChange={(ev) => set({ visible: ev.target.checked })}
            />
          </Box>
        </FormControl>
      </VStack>
      {withGroupVisibility && (
        <>
          <Divider />
          <Box px="4">
            <GroupVisibilitySettings
              value={selected.groupVisibility ?? {}}
              onChange={setGroupVisibility}
            />
          </Box>
        </>
      )}
      <Divider />
      <FilterEditor
        filterTypes={filterTypes}
        value={selected.filters}
        onChange={(filters) => set({ filters })}
      />
    </Box>
  );
};
