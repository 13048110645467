import { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  Table,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { PhonebookListContext } from '../PhonebookListContext';
import { CompanyApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns } from './columns';
import { useCompanyBulkActions } from './company-list-bulk-actions';
import { filterTypes } from './filter-types';
import { generateExport } from './generate-export';
import { getCardCompanies } from './get-companies';

interface Props {
  customFields?: FieldValue[];
}

export const CompanyTableList = ({ customFields }: Props): ReactElement => {
  const overview = useSelectedOverview('companies');

  const listingSettings = useListingSettings<CompanyApiData>({
    listingName: 'Phonebook.CompanyList',
    viewName: 'table',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    customFields,
  });

  const { filters, density, columnsToShow, page, limit } = listingSettings;

  const { data, refetch } = useQuery({
    queryKey: ['Phonebook.CompanyList.table', page, filters, limit],
    queryFn: () => getCardCompanies({ page, filters, limit }),
    enabled: filters !== undefined,
    keepPreviousData: true,
  });

  const bulkActions = useCompanyBulkActions(refetch);
  const itemCount = useMemo(() => data?.meta.count ?? 0, [data?.meta.count]);
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: itemCount,
    menuChildren: <ExportMenuItem />,
    displayModeOptions: phonebookDisplayModeOptions,
  });

  if (!data || !customFields) {
    return <LoadingPage />;
  }

  const {
    data: companies,
    meta: { count },
  } = data;

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<CompanyApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardCompanies({
            fields: [
              'ID',
              'name',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],

            limit: 99999,
            filters,
          });

          return data;
        }}
        selectPage={() => companies}
      >
        <Box my={9} px={5}>
          <ExportModalProvider
            onExport={generateExport}
            rowCount={count ?? 0}
            filterExpression={filters ?? []}
          >
            <ListHeading {...headingProps} Actions={<Actions />} />
          </ExportModalProvider>
        </Box>

        <Table<CompanyApiData>
          columns={columnsToShow}
          rows={companies}
          createRowLink={creatRowLink}
          size={density}
          bulkActions={bulkActions}
        />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};

function creatRowLink(company: CompanyApiData): string {
  return `/phonebook/companies/${company.ID}`;
}
