import { createContext, PropsWithChildren, useContext } from 'react';
import { ModalContent } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useModalInstance } from '@bq/components/UseModal';
import { CompanyLocationFormData } from 'app/Modules/Phonebook/Assets/components/CompanyLocationForm';

import { patchCompanyLocation, postCompanyLocation } from './api';
import {
  CompanyLocationFormCtxI,
  CompanyLocationModalContentProps,
} from './types';

const CompanyLocationFormCtx = createContext<CompanyLocationFormCtxI | null>(
  null
);

export const CompanyLocationFormWrapper = (
  props: PropsWithChildren<CompanyLocationModalContentProps>
) => {
  const [formState, setFormState] = useFormState();
  const methods = useForm({ defaultValues: props.data });

  return (
    <CompanyLocationFormCtx.Provider
      value={{ formState, mode: props.mode, setFormState }}
    >
      <FormProvider {...methods}>
        <CompanyLocationModalForm {...props} />
      </FormProvider>
    </CompanyLocationFormCtx.Provider>
  );
};

const CompanyLocationModalForm = (
  props: PropsWithChildren<CompanyLocationModalContentProps>
) => {
  const { handleSubmit } = useFormContext<CompanyLocationFormData>();
  const { setFormState } = useCompanyLocationFormWrapper();
  const { closeWithCallback } = useModalInstance();

  const { mutateAsync } = useMutation({
    mutationFn: (data: Partial<CompanyLocationFormData>) => {
      const { company, ...otherData } = data;
      if (props.mode === 'create') {
        return postCompanyLocation({
          data: { ...otherData, companyID: company?.ID },
        });
      }

      return patchCompanyLocation({
        data: { ...otherData, companyID: company?.ID },
        ID: props.CompanyLocationID,
      });
    },
  });

  return (
    <ModalContent
      as="form"
      onSubmit={handleSubmit(
        async (data) => {
          setFormState('saving');
          const { data: newData } = await mutateAsync(data);
          setFormState('saved');
          closeWithCallback({ ...data, ...newData });
        },
        (e) => {
          console.log(e);
          setFormState('error');
        }
      )}
    >
      {props.children}
    </ModalContent>
  );
};

export const useCompanyLocationFormWrapper = () => {
  const ctx = useContext(CompanyLocationFormCtx);
  if (!ctx) {
    throw Error('Missing CompanyLocationFormWrapper');
  }

  return ctx;
};
