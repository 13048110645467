import { createContext, useCallback, useContext } from 'react';
import { ModalInstanceCtxProps, ModalInstanceProviderProps } from './types';
import {
  noCloseCallbackEvent,
  onCloseEvent,
  onCloseWithNoCallbackEvent,
} from './modal-events';

export const ModalInstanceCtx = createContext<ModalInstanceCtxProps | null>(
  null
);

export const ModalInstanceProvider = ({
  children,
  modalID,
  preventClose,
  preventReason,
}: ModalInstanceProviderProps) => {
  const closeWithCallback = useCallback(
    <CloseCallbackData, >(data: CloseCallbackData, noPreventClose?: boolean) => {
      onCloseEvent<CloseCallbackData>({ modalID, data, noPreventClose });
    },
    [modalID]
  );
  const closeWithNoCallback = useCallback(
    (noPreventClose?: boolean) => {
      onCloseWithNoCallbackEvent({ modalID, noPreventClose });
    },
    [modalID]
  );
  const noCloseCallback = useCallback(
    <CloseCallbackData, >(data: CloseCallbackData) => {
      noCloseCallbackEvent<CloseCallbackData>({ modalID, data });
    },
    [modalID]
  );

  return (
    <ModalInstanceCtx.Provider
      value={{
        noCloseCallback,
        closeWithCallback,
        closeWithNoCallback,
        preventClose,
        preventReason,
      }}
    >
      {children}
    </ModalInstanceCtx.Provider>
  );
};

export const useModalInstance = () => {
  const ctx = useContext(ModalInstanceCtx);
  if (!ctx) {
    throw Error('Missing Modal Instance Ctx');
  }

  return ctx;
};
