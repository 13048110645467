import parsePhoneNumberFromString, {
  CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js';

export function phoneNumberValid(number: string): boolean {
  return isValidPhoneNumber(number, getPhoneNumberCountryCode());
}

export function getPhoneNumberCountryCode(): CountryCode {
  // 2 letter country ISO code. HR, DE, US.
  const { locale, phoneNumberRegion } = window.Bootstrap.bootquery;
  const [, shortLocale] = locale.split('_');

  return (phoneNumberRegion || shortLocale) as CountryCode;
}

export function numberToE164(number: string): string {
  const parsed = parsePhoneNumberFromString(
    number,
    getPhoneNumberCountryCode()
  );

  // Failed to parse phone number, just return string as-is
  if (!parsed) {
    return number;
  }

  return parsed.format('E.164');
}

export function formatNumber(number: string): string {
  const regionCode = getPhoneNumberCountryCode();
  const parsed = parsePhoneNumberFromString(number, regionCode);

  // Failed to parse phone number, just return string as-is
  if (!parsed || !parsed.isValid()) {
    return number;
  }

  if (parsed.country === regionCode) {
    return parsed.formatNational();
  }

  return parsed.formatInternational();
}
