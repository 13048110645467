import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { Campaign } from '../../schemas';
import { PatchCampaign } from '../../schemas/Campaign';

export const patchCampaign = async <T = Campaign>(
  campaignID: number,
  data: PatchCampaign,
  params?: GetOneParams
) => {
  const { data: newData } = await Api.patch<T>(
    `/api/sales/campaigns/${campaignID}`,
    data,
    { params }
  );

  return newData;
};
