import {
  QueryClient,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';

import { getFormDefinition } from 'BootQuery/Assets/components/FormEditor';
import { loadOverviews } from 'BootQuery/Assets/components/Overviews/load-overviews';
import { Jsonish } from 'BootQuery/Assets/components/type-util';
import { Api } from 'BootQuery/Assets/js/api';

import { getLocalCompanyDepartments } from './Api/departments';
import { useShowPeopleFromSubdepartments } from './CompanyEmployeeList';
import { getFilterTypes as getCompanyFilters } from './CompanyList';
import { getFilterTypes as getCompanyLocationFilters } from './CompanyLocationsList';
import {
  DepartmentsTreeSettings,
  useDepartmentsTreeSettings,
} from './DepartmentTreeView';
import { useLocalCompany } from './DepartmentTreeView/use-local-company';
import { makeDepartmentTree } from './DepartmentTreeView/utils';
import { getFilterTypes as getPersonFilters } from './PersonList';
import { PhonebookOverviews } from './types';

interface RawPhonebookOverviews {
  people: Jsonish;
  companies: Jsonish;
  companyLocations: Jsonish;
}

interface PhonebookOverviewsOptions {
  localCompanyID?: number | null;
  showSubdepartmentEmployees?: boolean;
  departmentsTreeSettings?: Partial<DepartmentsTreeSettings>;
}

export interface UsePhonebookOverviewsOptions {
  /** react-query's enabled */
  queryEnabled?: boolean;
}

export function usePhonebookOverviews({
  queryEnabled = true,
}: UsePhonebookOverviewsOptions = {}): UseQueryResult<PhonebookOverviews> {
  const queryClient = useQueryClient();
  const { data: localCompany, isLoading: isLoadingLocalCompany } =
    useLocalCompany();
  const [
    showSubdepartmentEmployees,
    _setShowSubdepartmentEmployees,
    showSubdepartmentEmployeesLoaded,
  ] = useShowPeopleFromSubdepartments();
  const { data: departmentsTreeSettings } = useDepartmentsTreeSettings();

  return useQuery<PhonebookOverviews>({
    queryKey: [
      'Phonebook.overviews',
      localCompany?.ID,
      showSubdepartmentEmployees,
      departmentsTreeSettings,
    ],
    queryFn: () => {
      return fetchOverviews(queryClient, {
        localCompanyID: localCompany?.ID,
        showSubdepartmentEmployees: showSubdepartmentEmployees === 'true',
        departmentsTreeSettings,
      });
    },
    enabled:
      queryEnabled &&
      !isLoadingLocalCompany &&
      showSubdepartmentEmployeesLoaded,
  });
}

async function fetchOverviews(
  queryClient: QueryClient,
  {
    localCompanyID,
    showSubdepartmentEmployees = false,
    departmentsTreeSettings = {},
  }: PhonebookOverviewsOptions
): Promise<PhonebookOverviews> {
  const [overviews, personForm, companyForm, companyLocationForm, departments] =
    await Promise.all([
      Api.get<RawPhonebookOverviews>('/api/phonebook/overviews'),
      getFormDefinition(queryClient, 'Phonebook.person'),
      getFormDefinition(queryClient, 'Phonebook.company'),
      getFormDefinition(queryClient, 'Phonebook.companyLocation'),
      localCompanyID
        ? getLocalCompanyDepartments(localCompanyID, departmentsTreeSettings)
        : null,
    ]);

  const personFilters = getPersonFilters(personForm);
  const companyFilters = getCompanyFilters(companyForm);
  const companyLocationFilters = getCompanyLocationFilters(companyLocationForm);

  return {
    people: loadOverviews(overviews.data.people, personFilters),
    companies: loadOverviews(overviews.data.companies, companyFilters),
    companyLocations: loadOverviews(
      overviews.data.companyLocations,
      companyLocationFilters
    ),
    employees: departments
      ? makeDepartmentTree(departments.data, showSubdepartmentEmployees)
      : [],
  };
}

export function saveOverviews(overviews: PhonebookOverviews): Promise<void> {
  return Api.post('/api/phonebook/overviews', overviews);
}
