import { ReactElement } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import { FaSyncAlt } from 'react-icons/fa';

interface Props {
    newRows: number;
    isRefreshing?: boolean;
    onRefresh?: () => void;
}

export const TableRefresh = ({ newRows, onRefresh, isRefreshing = false }: Props): ReactElement => {
  const handleRefresh = () => onRefresh && onRefresh();
  const label = newRows ? `${newRows} new rows. Click to refresh.` : 'No new rows';

  return (
    <Tooltip label={label}>
      <Button
        aria-label={label}
        variant="solid"
        colorScheme="blue"
        isDisabled={newRows === 0 || isRefreshing}
        onClick={handleRefresh}
      >
        <FaSyncAlt />
        &nbsp;
        {newRows}
      </Button>
    </Tooltip>
  );
};
