import { useFormContext } from 'react-hook-form';

import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';

import { PersonApiData } from '../types';
import { PersonFormData } from './types';

export const useDuplicatePerson = () => {
  const { watch } = useFormContext<PersonFormData>();
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const { data } = useDebouncedQuery({
    queryKey: ['PersonChecker', firstName, lastName],
    queryFn: () => {
      return getDuplicatePerson(firstName, lastName);
    },
  });

  return { data };
};

const getDuplicatePerson = async (firstName = '', lastName = '') => {
  if (firstName.length <= 2 || lastName.length <= 2) {
    return [];
  }
  const {
    data: { data },
  } = await Api.get<ListingResponse<PersonApiData>>('/api/phonebook/people', {
    params: {
      filters: {
        'firstName:contains:ci': firstName,
        'lastName:contains:ci': lastName,
      },
      fields: ['ID', 'firstName', 'lastName'],
      limit: 'null',
    },
  });

  return data;
};
