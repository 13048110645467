import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useModalInstance } from '@bq/components/UseModal';

import { CampaignFormFields } from './CampaignFormFields';
import { CampaignFormFooter } from './CampaignFormFooter';
import { useCampaignFormProvider } from './CampaignFormWrapper';
import { handleCampaignFormSubmit } from './handle-campaign-form-submit';

export const CampaignFormModalContent = () => {
  const { t } = useTranslation();
  const {
    ctx: { setFormState, mode, campaignID },
    methods: { handleSubmit },
  } = useCampaignFormProvider();
  const { closeWithCallback } = useModalInstance();

  return (
    <ModalContent
      as="form"
      data-ignore-form-save
      onSubmit={handleSubmit(
        async (data) => {
          const newData = await handleCampaignFormSubmit(
            data,
            mode,
            campaignID
          );
          closeWithCallback(newData);
        },
        (err) => {
          setFormState('error');
          console.log(err);
        }
      )}
    >
      <ModalHeader>
        {mode === 'create'
          ? t('Sales:campaign.form.title')
          : t('Sales:edit_campaign')}
      </ModalHeader>
      <ModalBody>
        <CampaignFormFields />
      </ModalBody>
      <CampaignFormFooter />
    </ModalContent>
  );
};
