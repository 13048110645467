import {
  FieldPath,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';

import { FormSalesCase } from '../../schemas';
import { useCaseFormProvider } from './CaseFormWrapper';

export function useCaseFormController<K extends FieldPath<FormSalesCase>>(
  name: K,
  props?: Omit<UseControllerProps<FormSalesCase, K>, 'control' | 'name'>
): UseControllerReturn<FormSalesCase, K> {
  const {
    methods: { control },
  } = useCaseFormProvider();

  return useController<FormSalesCase, K>({ ...props, control, name });
}
