import { ReactElement } from 'react';
import { HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaBuilding, FaIdCard, FaMapMarkerAlt } from 'react-icons/fa';

import { SettingsFormEditButton } from '@bq/components/SettingsFormEdit';

import { EditOverviewsButton } from './Overviews';

export const Settings = (): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <VStack alignItems="start">
      <EditOverviewsButton />
      <HStack>
        <SettingsFormEditButton
          inputName="phonebookForms[person]"
          formName="Phonebook.person"
        >
          <FaIdCard />
          &nbsp; {t('Phonebook:edit_person_form')}
        </SettingsFormEditButton>
        <SettingsFormEditButton
          inputName="phonebookForms[company]"
          formName="Phonebook.company"
        >
          <FaBuilding />
          &nbsp; {t('Phonebook:edit_company_form')}
        </SettingsFormEditButton>
        <SettingsFormEditButton
          inputName="phonebookForms[companyLocation]"
          formName="Phonebook.companyLocation"
        >
          <FaMapMarkerAlt />
          &nbsp; {t('Phonebook:edit_company_location_form')}
        </SettingsFormEditButton>
      </HStack>
    </VStack>
  );
};
