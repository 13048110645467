import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { FieldValue, FormEditor } from 'BootQuery/Assets/components/FormEditor';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FaCog } from 'react-icons/fa';
import { useCustomEventForm } from '../custom-forms';
import { useEventTypeSettings } from './EventTypeSettingsContext';
import { IDType } from './types';

interface Props {
  eventTypeID: IDType;
}

export const EditEventType = ({ eventTypeID }: Props): ReactElement => {
  const { t } = useTranslation('Events');
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        hasArrow
        size="sm"
        icon={<FaCog />}
        label={t('Events:edit_event_type')}
        onClick={onOpen}
      />
      <Modal
        size="6xl"
        scrollBehavior="inside"
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <EventTypeEditModalContent
            eventTypeID={eventTypeID}
            onClose={onClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
};

interface ModalProps {
  onClose: () => void;
  eventTypeID: IDType;
}

export const EventTypeEditModalContent = ({
  eventTypeID,
  onClose,
}: ModalProps): ReactElement => {
  const initial = useCustomEventForm(eventTypeID);
  const { eventTypes, modify } = useEventTypeSettings();
  const settings = eventTypes.find((evType) => evType.ID === eventTypeID);
  if (!settings) {
    throw new Error(`Unable to find event type ${eventTypeID}`);
  }

  const value: FormData | null = useMemo(() => {
    if (!initial) {
      return null;
    }

    return { customForm: initial, ...settings };
  }, [settings, initial]);

  const submit = useCallback(
    (data: FormData) => {
      modify(eventTypeID, data);
      onClose();
    },
    [eventTypeID, modify, onClose]
  );

  const cancel = useCallback(() => {
    onClose();
  }, [onClose]);

  if (!value) {
    return (
      <ModalBody>
        <LoadingPage />
      </ModalBody>
    );
  }

  return <EventFormContent value={value} onCancel={cancel} onSubmit={submit} />;
};

interface FormData {
  name: string;
  customForm: FieldValue[];
}

interface EventFormEditProps {
  value: FormData;
  onSubmit: (data: FormData) => void;
  onCancel: () => void;
}

export const EventFormContent = ({
  value,
  onSubmit,
  onCancel,
}: EventFormEditProps): ReactElement => {
  const { t } = useTranslation('Events');
  const [name, setName] = useState(value.name);
  const [customForm, setCustomForm] = useState(value.customForm);

  const handleNameChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
    setName(ev.target.value);
  }, []);

  const submit = () => {
    onSubmit({ name, customForm });
  };

  return (
    <>
      <ModalBody px={0}>
        <Box px={3} py={2}>
          <FormControl>
            <FormLabel>{t('global:name')}:</FormLabel>
            <Input value={name} onChange={handleNameChange} />
          </FormControl>
        </Box>
        <Divider />
        <FormEditor value={customForm} onChange={setCustomForm} />
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <FormActions onSubmit={submit} onCancel={onCancel} />
      </ModalFooter>
    </>
  );
};
