import {
  Box,
  Button,
  chakra,
  Flex,
  Image,
  StyleProps,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { matchFileType } from 'BootQuery/Assets/js/helpers';
import { pickFiles } from 'BootQuery/Assets/js/pick-files';
import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUpload } from 'react-icons/fa';
import { useDrop, useDropArea } from 'react-use';

interface Props {
  value?: File | string | null;
  changeCallback: (newVal: File | null | undefined) => void;
  defaultValue?: string | null;
  children?: ReactNode;
}
type AllProps = Props & StyleProps;

export const ImageInput = ({
  value = null,
  changeCallback,
  defaultValue,
  children,
  ...styleProps
}: AllProps) => {
  const imageSrc = useMemo(() => srcFromValue(value, defaultValue), [value, defaultValue]);
  const { t } = useTranslation();
  const { over: isDragging } = useDrop();
  const onFiles = useCallback(
    (files: File[] | null) => {
      if (!files || !files.length) {
        return;
      }

      const file = files[0];
      if (!matchFileType(ALLOWED_TYPES, file)) {
        return;
      }
      changeCallback(file);
    },
    [changeCallback]
  );
  const [bond, { over }] = useDropArea({ onFiles });
  const wrapperStyle = useWrapperStyle({ over, isDragging });

  const openPicker = useCallback(() => {
    pickFiles({
      accept: ALLOWED_TYPES,
      multiple: false,
    }).then(onFiles);
  }, [onFiles]);

  return (
    <Flex sx={{ gap: '16px' }} alignItems="center" flexDirection="column">
      <FileInputWrapper {...wrapperStyle} {...styleProps} {...bond} onClick={openPicker}>
        {!imageSrc && (
          <VStack>
            <FaUpload />
            <Text>{t('global:drag_and_drop')}</Text>
            <Text>{t('global:or')}</Text>
            <Button onClick={openPicker}>{t('global:richtext.browse_files')}</Button>
          </VStack>
        )}
        {imageSrc && <Image src={imageSrc} width="full" height="full" objectFit="cover" />}
        {children}
      </FileInputWrapper>
    </Flex>
  );
};

const ALLOWED_TYPES = ['image/*'];

const FileInputWrapper = chakra(Box, {
  baseStyle: {
    display: 'flex',
    borderRadius: 'md',
    padding: '1',
    width: 'auto',
    cursor: 'pointer',
    position: 'relative',
    borderStyle: 'dashed',
    borderWidth: 'medium',
    borderColor: 'gray.200',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function useWrapperStyle({ isDragging, over }: { isDragging: boolean; over: boolean }) {
  const [draggingColor, hoveredColor] = useColorModeValue(
    ['blue.200', 'blue.500'],
    ['blue.800', 'blue.500']
  );

  if (over) {
    return { borderColor: hoveredColor, borderStyle: 'solid' };
  }
  if (isDragging) {
    return { borderColor: draggingColor };
  }

  return {};
}

function srcFromValue(
  value: File | string | null,
  defaultValue: string | undefined | null
): string | undefined {
  if (defaultValue === null) {
    return '';
  }
  if (!value) {
    return defaultValue;
  }
  if (typeof value === 'string') {
    return value;
  }

  if (value instanceof File) {
    return window.URL.createObjectURL(value);
  }

  return '';
}
