import { zodResolver } from '@hookform/resolvers/zod';
import {
  useForm,
  useFormContext,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

import { datasetFormSchema, IDatasetForm } from './schema';

type UseDatasetFormProps = UseFormProps<IDatasetForm>;

export function useDatasetForm(
  props: UseDatasetFormProps
): UseFormReturn<IDatasetForm> {
  return useForm<IDatasetForm>({
    ...props,
    resolver,
  });
}

export function useDatasetFormContext(): UseFormReturn<IDatasetForm> {
  return useFormContext<IDatasetForm>();
}

const resolver = zodResolver(datasetFormSchema);
