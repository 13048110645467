import { ReactElement } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DatasetColumns } from './DatasetColumns';
import { useDatasetFormContext } from './form';

export const DatasetFormFields = (): ReactElement => {
  const { t } = useTranslation('Datasets');
  const {
    register,
    formState: { errors },
  } = useDatasetFormContext();

  return (
    <>
      <VStack>
        <FormControl isInvalid={!!errors.name}>
          <FormLabel htmlFor="name">{t('global:name')}</FormLabel>
          <Input
            {...register('name', { required: true })}
            isInvalid={!!errors.name}
            placeholder={t('global:name')}
            id="name"
          />
        </FormControl>

        <FormControl isInvalid={!!errors.displayNameTemplate}>
          <FormLabel htmlFor="displayNameTemplate">
            {t('Datasets:display_name_template')}
          </FormLabel>
          <Input
            {...register('displayNameTemplate')}
            isInvalid={!!errors.displayNameTemplate}
            placeholder={t('Datasets:display_name_template')}
            id="displayNameTemplate"
          />
        </FormControl>
      </VStack>

      <Divider mt="8" />

      <Heading textAlign="center" as="h2" size="lg">
        {t('Datasets:fields')}
      </Heading>
      <Box mx="-6">
        <DatasetColumns />
      </Box>
    </>
  );
};
