import { useMemo } from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { debounce } from 'lodash-es';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { chakraSelectStyles } from '@bq/components/Select';

import { CompanyDepartmentFormData } from './types';
import { filToVal, searchValidParents, valToFilter } from './utils';

export const ParentSelect = () => {
  const { control } = useFormContext<CompanyDepartmentFormData>();
  const companyID = useWatch<CompanyDepartmentFormData>({ name: 'company.ID' });
  const { departmentID } = useParams();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'parentDepartment' });
  const { t } = useTranslation('Phonbook');

  const debouncedSearch = useMemo(() => {
    return debounce(
      async (search) => {
        return searchValidParents(companyID, search, departmentID);
      },
      300,
      { leading: true }
    );
  }, [companyID, departmentID]);

  return (
    <AsyncSelect
      key={`DepartmentParentSelect-${companyID}`}
      styles={chakraSelectStyles}
      isSearchable
      isClearable
      loadOptions={(search) => debouncedSearch(search)}
      defaultOptions
      isDisabled={!companyID}
      value={valToFilter(value)}
      onChange={(val) => {
        onChange(filToVal(val));
      }}
      placeholder={t('Phonebook:no_parent_department')}
    />
  );
};
