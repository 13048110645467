import { FilterTypes } from '@bq/components/FilterBar';
import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

export const isThirdPartyServiceFilter = makeBooleanFilter({
  name: () => i18n.t('Products:is_third_party_service'),
  toFilter: ({ value }) => {
    return { 'service.isThirdPartyService': value };
  },
});
export const serviceFilters: FilterTypes = { isThirdPartyServiceFilter };
