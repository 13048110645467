import {
  Box,
  Button,
  chakra,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaList, FaPlus } from 'react-icons/fa';
import { TagListItem } from './TagListItem';
import { TagChangeMethods, useTagsMethods } from './tags-methods';
import { TagFieldSettings } from './types';

interface SettingsModalProps {
  value: TagFieldSettings;
  onChange: (newVal: TagFieldSettings) => void;
  isOpen: boolean;
  onClose: () => void;
}

interface SettingsProps {
  value: TagFieldSettings;
  modify: TagChangeMethods;
}

export const SettingsModal = ({
  value,
  onChange,
  isOpen,
  onClose,
}: SettingsModalProps): ReactElement => {
  const { t } = useTranslation();

  const [tmpValue, modify] = useTagsMethods(value);

  const submit = () => {
    onChange(tmpValue);
    onClose();
  };
  const close = () => {
    onClose();
    modify.setSettings(value);
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaList />
          &nbsp;
          {t('global:form_editor.edit_select_items')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowX="auto">
          <Settings value={tmpValue} modify={modify} />
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions onSubmit={submit} onCancel={close} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ListWrapper = chakra(Box, {
  baseStyle: {
    px: '2',
    minW: 'xs',
    direction: 'column',
    h: '50vh',
    minH: 'sm',
    overflowY: 'auto',
    flex: '1 0 auto',
  },
});

const ActionWrapper = chakra(Flex, {
  baseStyle: {
    justifyContent: 'center',
    py: '2',
    flex: '0 1 auto',
    borderTopStyle: 'solid',
    borderTopWidth: 'medium',
  },
});

const Settings = ({ value, modify }: SettingsProps): ReactElement => {
  const { t } = useTranslation();
  const tags = value.tags.filter((tag) => !tag.deleted);

  return (
    <Flex direction="column">
      <ListWrapper>
        {tags.map((tag) => (
          <TagListItem key={tag.value} value={tag} modify={modify} />
        ))}
      </ListWrapper>
      <ActionWrapper>
        <Button
          colorScheme="green"
          display="flex"
          alignItems="center"
          size="sm"
          onClick={modify.addTag}
        >
          <FaPlus />
          &nbsp;&nbsp; {t('global:form_editor.add_item')}
        </Button>
      </ActionWrapper>
    </Flex>
  );
};
