import { useEffect } from 'react';
import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FieldValue,
  FormEditor,
  useFormDefinition,
} from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { ChangeType, WithChange } from '@bq/components/SettingsCRUD/types';
import { uniqid } from 'app/assets/js/tsutil';

import { TypeFormFields } from './TypeFormFields';
import { TicketTypesForm } from './types';

interface Props {
  isOpen: boolean;
  selected: WithChange<TicketTypesForm> | null;
  onClose: () => void;
  onChange: (modifications: WithChange<TicketTypesForm>) => void;
}

export const TypesModalForm = ({
  onClose,
  isOpen,
  selected,
  onChange,
}: Props) => {
  const formDefinition = useFormDefinition(
    `TicketTypes.${selected?.ID}`,
    'edit'
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      {formDefinition ? (
        <TypesModalContent
          onClose={onClose}
          isOpen={isOpen}
          selected={selected}
          onChange={onChange}
          defaultFormDefinition={formDefinition}
        />
      ) : (
        <>
          <ModalOverlay />
          <ModalContent>
            <LoadingPage />
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

interface ContentProps extends Props {
  defaultFormDefinition: FieldValue[];
}

const TypesModalContent = ({
  selected,
  defaultFormDefinition,
  onClose,
  onChange,
}: ContentProps) => {
  const { t } = useTranslation();
  const methods = useForm<TicketTypesForm>({
    defaultValues: selected ? { form: defaultFormDefinition, ...selected } : {},
  });

  const { field: formField } = useController({
    name: 'form',
    control: methods.control,
  });

  const name = methods.watch('name') ?? uniqid();

  useEffect(() => {
    if (!selected) {
      methods.setValue('slug', name.replace(/\s+/g, '-').toLowerCase());
    }
  }, [methods, name, selected]);

  return (
    <FormProvider {...methods}>
      <form
        data-ignore-form
        onSubmit={methods.handleSubmit((data) => {
          const ID = selected ? selected.ID : uniqid();
          const change: ChangeType = selected ? 'upd' : 'add';
          const name = data.name ?? ID;

          onChange({
            ID,
            change,
            name,
            slug: data.slug,
            defaultTicketGroupID: data.defaultTicketGroupID,
            defaultTab: data.defaultTab,
            form: data.form ?? [],
            states: data.states ?? [],
            canHaveDependencies: data.canHaveDependencies,
            canHaveWorks: data.canHaveWorks,
          });
          onClose();
        })}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader>{t('Ticketing:form.ticket_type')}</ModalHeader>
          <ModalBody>
            <TypeFormFields />
            <Divider />
            <FormEditor
              value={formField.value ?? []}
              onChange={formField.onChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={onClose}
              variant="outline"
            >
              {t('global:close')}
            </Button>
            <Button type="submit" colorScheme="green">
              {t('global:save')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </FormProvider>
  );
};
