import { notificationRegistry } from './notification-registry';
import {
  LocalNotificationMeta,
  NotificationHandle,
  NotificationProps,
} from './types';

export function showLocalNotification(
  handle: NotificationHandle,
  { title, onClick, onClose, ...options }: NotificationProps
): void {
  const notification = new Notification(title, {
    ...options,
    actions: undefined, // actions aren't supported for local notifications
  });
  notification.addEventListener('click', () => {
    onClick?.(null);
  });
  notification.addEventListener('close', () => {
    delete notificationRegistry[handle];
    onClose?.();
  });

  notificationRegistry[handle] = { type: 'local', handle, notification };
}

export function dismissLocalNotification(notification: LocalNotificationMeta) {
  notification.notification.close();
}
