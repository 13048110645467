import Module from 'BootQuery/Assets/js/module';
import { lazy } from 'react';
import { renderReact } from 'BootQuery/Assets/js/render-react';

const TimeRules = lazy(
  () => import('../components/TimeRules').then(({ TimeRules }) => ({ default: TimeRules }))
);

export default class Settings extends Module {
  activateElements(target, data) {
    this.activateTimeRuleSettings(target);
  }

  activateTimeRuleSettings(target) {
    const $tgt = target.findElement('#time-rule-settings');
    if ($tgt.length > 0) {
      this.renderTimeRuleSettings($tgt);
    }
  }

  renderTimeRuleSettings($container) {
    const container = $container[0];
    setTimeout(() => {
      renderReact(container, TimeRules);
    }, 0);
  }

  setRuleGetter(getRules) {
    $('#settings-form').ev('beforeSubmit.timeRules', (e) => {
      let input = $(e.currentTarget).find('[name="timeRules"]');
      if (!input.length) {
        input = $('<input/>', {
          type: 'hidden',
          name: 'timeRules',
        });
      }
      input.val(JSON.stringify(getRules()));
      $('#setting-timeRules').append(input);
    });
  }

  unsetRuleGetter() {
    $('#settings-form').off('beforeSubmit.timeRules');
  }
}
