<template>
	<div class="mails-wrapper">
		<div class="text-center" v-if="loading">
			<div class="spinner-grow m-3"></div>
		</div>
		<Multipane class="mails-split" v-else-if="canView && availableAccounts.length">
			<router-view
				v-if="showAccountList && $mq === 'xl'"
				name="accountList"
				:accounts="availableAccounts"
				@newMessage="newMessage"
			/>
			<MultipaneResizer v-if="showAccountList && $mq === 'xl'" />
			<template v-if="$mq === 'xl' || $mq === 'lg'">
				<router-view
					name="threadList"
					:threads.sync="threads"
					:filters.sync="filters"
					:accounts="availableAccounts"
					:editors="editors"
					:showAccountList="showAccountList"
					@newMessage="newMessage"
					style="width: 24%; min-width: 250px;"
				/>
				<MultipaneResizer />
				<router-view
					name="threadView"
					:editors="editors"
					:threads="threads"
					:availableAccounts="availableAccounts"
					:availableTicketingGroups="availableTicketingGroups"
					:availableTicketTypes="availableTicketTypes"
					:signatureTemplateData="signatureTemplateData"
          :templates="templates"
					ref="threadView"
					@submitEditor="submitEditor"
					@cancelEditor="closeEditor"
					@newMessage="newMessage"
					style="flex-grow: 1; flex-shrink: 0; flex-basis: 600px;"
				/>
			</template>
			<template v-else>
				<router-view
					v-if="threadID"
					name="threadView"
					:editors="editors"
					:threads="threads"
					:availableAccounts="availableAccounts"
					:availableTicketingGroups="availableTicketingGroups"
					:availableTicketTypes="availableTicketTypes"
					:signatureTemplateData="signatureTemplateData"
          :templates="templates"
					ref="threadView"
					@submitEditor="submitEditor"
					@cancelEditor="closeEditor"
					@newMessage="newMessage"
					style="width: 100%;"
				/>
				<router-view
					v-else
					name="threadList"
					:threads.sync="threads"
					:filters.sync="filters"
					:accounts="availableAccounts"
					:editors="editors"
					:showAccountList="showAccountList"
					@newMessage="newMessage"
					style="width: 100%;"
				/>
			</template>
		</Multipane>
		<div class="alert alert-danger text-center" v-else>
			<span v-if="!canView">{{ tr('Mails:label.access_denied') }}</span>
			<span v-else>{{ tr('Mails:label.no_accounts_available') }}</span>
		</div>
	</div>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import { v4 as uuid } from 'uuid';
import { isArray } from 'lodash-es';
import { processThread } from '../js/util';
import {
  getTicketingGroupsIfEnabled,
  getTicketingTypesIfEnabled,
} from '../js/ticketing';


export default {
  components: {
    Multipane,
    MultipaneResizer,
  },
  data() {
    return {
      loading: true,
      canView: null,
      displaySettings: {},
      availableAccounts: null,
      signatureTemplateData: null,
      templates: null,
      availableTicketingGroups: null,
      availableTicketTypes: null,
      threads: [],
      editors: [],
      filters: {
        searchString: null,
        address: {
          address: null,
          direction: null,
        },
        hasAttachment: false,
        isHighPriority: false,
        hasTicket: null,
      },
    };
  },
  created() {
    document.title = `E-mails | ${window.Bootstrap.bootquery.appTitle}`;

    const module = window.BootQuery.getModuleInstance('Mails');
    Promise.all([
      module.getCanView(),
      module.getAvailableAccounts(),
      module.getSignatureTemplateData(),
      module.getTemplates(),
      module.getDisplaySettings(),
      getTicketingGroupsIfEnabled(),
      getTicketingTypesIfEnabled(),
    ]).then(([
      canView,
      accounts,
      sigData,
      templates,
      displaySettings,
      ticketingGroups,
      ticketTypes,
    ]) => {
      this.canView = canView;
      this.availableAccounts = accounts;
      this.signatureTemplateData = sigData;
      this.templates = templates;
      this.displaySettings = displaySettings;
      this.availableTicketingGroups = ticketingGroups;
      this.availableTicketTypes = ticketTypes;
      this.loading = false;
    });
  },
  computed: {
    showAccountList() {
      const setting = this.displaySettings.showAccountList || 'always';
      if (setting === 'always') {
        return true;
      }
      if (setting === 'whenMultipleAccounts') {
        return this.availableAccounts.length > 1;
      }
      return false;
    },
    threadID() {
      return this.$route.params.threadID;
    },
  },
  methods: {
    newMessage(options) {
      const editor = {
        uuid: uuid(),
        editStart: new Date(),
        lastChange: new Date(),
        accountID: null,
        subject: '',
        to: [],
        cc: [],
        bcc: [],
        attachments: [],
        content: {},
        textContent: '',
        htmlContent: '',
        isSending: false,
        priority: 3,
        threadID: null,
        inReplyToMessageID: null,
        forwardMessageID: null,
        ...options,
      };
      if (typeof (editor.to) === 'string') {
        editor.to = editor.to.split(/[,;]/);
      }
      if (typeof (editor.cc) === 'string') {
        editor.cc = editor.cc.split(/[,;]/);
      }
      if (typeof (editor.bcc) === 'string') {
        editor.bcc = editor.bcc.split(/[,;]/);
      }

      this.editors.push(editor);
      if (!editor.threadID) {
        this.$router.replace(
          `/${this.$route.params.accountID}/threads/editor-${editor.uuid}`,
        );
      }
    },
    async submitEditor(editorUUID, message) {
      const editor = this.editors.find((editor) => editor.uuid === editorUUID);
      editor.isSending = true;

      const { sentID } = await Api.post('/api/mails/sendMessage', {
        accountID: message.accountID,
        fromDisplayName: message.accountInfo.formattedName,
        subject: message.subject,
        to: message.to,
        cc: message.cc,
        bcc: message.bcc,
        content: message.content,
        text: message.textContent,
        html: message.htmlContent,
        attachments: message.attachments,
        priority: message.priority,
        inReplyToMessageID: editor.inReplyToMessageID,
        forwardMessageID: editor.forwardMessageID,
        sendSeperate: message.sendSeperate,
        seperateTickets: message.seperateTickets,
        quotePrevious: message.quotePrevious,
        meta: message.meta,
      });

      const returnedID = editor.threadID || sentID;
      let thread = null;
      let messages = null;
      if (isArray(returnedID)) {
        const threadReqs = returnedID
          .reverse()
          .map((threadID) => Api.get(`/api/mails/threads/${threadID}`));
        const returnedThreads = await Promise.all(threadReqs);

        returnedThreads.forEach((threadRes) => {
          ({ thread, messages } = threadRes);
          thread.messages = messages;
          thread = processThread(thread);
          this.threads.unshift(thread);
        });
      } else {
        const threadRes = await Api.get(`/api/mails/threads/${returnedID}`);
        ({ thread, messages } = threadRes);
        thread.messages = messages;
        thread = processThread(thread);
      }

      this.closeEditor(editorUUID);

      if (editor.threadID) {
        const threadIdx = this.threads.findIndex(
          (thread) => thread.rootID === editor.threadID,
        );
        this.$set(this.threads, threadIdx, thread);
        this.$refs.threadView.threadUpdated(thread);
      } else {
        this.$router.push(`/${this.$route.params.accountID}/threads/${thread.rootID}`);
      }
    },
    closeEditor(editorUUID) {
      if (this.$route.params.threadID === `editor-${editorUUID}`) {
        this.$router.replace(`/${this.$route.params.accountID}/threads/`);
      }
      this.editors = this.editors.filter((editor) => editor.uuid !== editorUUID);
    },
  },
};
</script>
