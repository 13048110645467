module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade primary-modal\" tabindex=\"-1\" aria-hidden=\"true\" style=\"padding-left: 12px; padding-right: 12px; padding-top: 12px; padding-bottom: 12px;\">\n  <div class=\"modal-dialog h-100 w-100 m-0\" style=\"max-width: none;\">\n    <div class=\"modal-content embedable h-100 w-100\">\n      <div class=\"modal-body p-0 rounded-top\" style=\"overflow: hidden;\">\n          <div class=\"signature-area w-100 h-100\">\n              <canvas></canvas>\n          </div>\n      </div>\n      <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-danger signature-clear-button\">\n            <span class=\"fas fa-eraser\"></span>\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.clear",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":12},"end":{"line":12,"column":33}}}))
    + "\n        </button>\n        <div class=\"dropdown mr-auto\">\n            <a href=\"#\" data-toggle=\"dropdown\" class=\"btn btn-success dropdown-toggle\">\n                <span class=\"fas fa-download\"></span>\n            </a>\n            <div class=\"dropdown-menu\">\n                <a href=\"#\" download=\"signature.png\" target=\"_blank\" class=\"dropdown-item signature-download\" data-format=\"image/png\">PNG</a>\n                <a href=\"#\" download=\"signature.jpeg\" target=\"_blank\" class=\"dropdown-item signature-download\" data-format=\"image/jpeg\">JPEG</a>\n                <a href=\"#\" download=\"signature.svg\" target=\"_blank\" class=\"dropdown-item signature-download\" data-format=\"image/svg+xml\">SVG</a>\n            </div>\n        </div>\n        <button type=\"button\" class=\"btn btn-link text-danger\" data-dismiss=\"modal\">\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.cancel",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":34}}}))
    + "\n        </button>\n        <button type=\"submit\" class=\"btn btn-success signature-submit-button\">\n            <span class=\"fa fa-check\"></span>\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"button.save",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":29,"column":12},"end":{"line":29,"column":32}}}))
    + "\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true})