import { Button } from '@chakra-ui/react';
import { ImageInput } from 'BootQuery/Assets/components/ImageInput/ImageInput';
import { useTranslation } from 'react-i18next';

interface Props {
  changeCallback: (newVal: string | null | undefined) => void;
  submitHandler: () => void;
  value: string;
}
export const ImageInputBase64 = ({ changeCallback, submitHandler, value }: Props) => {
  const { t } = useTranslation();
  const changeHandler = (file: File | null | undefined) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        changeCallback(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <ImageInput
        w="full"
        h="15rem"
        changeCallback={(newVal) => changeHandler(newVal)}
        defaultValue={value}
      />
      <Button w="full" onClick={submitHandler}>
        {t('global:send')}
      </Button>
    </>
  );
};
