import { Conference } from '../../types/call';
import { dialerStore } from './store/pbx';
import { CallDisplayProps } from './types';

export interface CallDisplayProperties {
  phoneNumber: string | null;
  displayName: string | null;
  conference: Conference | null;
  error: string | null;
}

export function useCallDisplayProperties(
  callInfo: CallDisplayProps
): CallDisplayProperties {
  const pbxCalls = dialerStore((store) => store.calls);

  if (callInfo.type === 'softphone') {
    const { phoneNumber, displayName } = callInfo.call;

    const pbxCall = pbxCalls.find((pbxCall) => {
      return (
        pbxCall.call.sourcePhonePoint.phonePoint.sipCallId ===
        callInfo.call.sipCallId
      );
    });
    const conference =
      pbxCall?.call.currentDestination?.destination.type === 'conference'
        ? pbxCall.call.currentDestination.destination.point
        : null;

    return {
      phoneNumber,
      displayName,
      conference,
      error: callInfo.call.error?.reasonPhrase ?? null,
    };
  }

  const { connection, call } = callInfo.call;

  const conference =
    call.currentDestination?.destination.type === 'conference'
      ? call.currentDestination?.destination.point
      : null;
  const isIncoming =
    connection === 'destination' || connection === 'ringing' || !connection;
  const phoneNumber = isIncoming
    ? call.sourceInfo.phoneNumber
    : call.destinationInfo.phoneNumber;

  return {
    phoneNumber,
    conference,
    displayName: null,
    error: null,
  };
}
