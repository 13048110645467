import { Dispatch, MutableRefObject, SetStateAction, useCallback } from 'react';
import { DragOverEvent } from '@dnd-kit/core';

import { findItem } from '../../dnd-util';
import { idInfo } from '../id-info';
import { BaseItem, CloneHandler, DraggedItemInfo } from '../types';
import { useAddOrMove } from './use-add-or-move';

export interface HandleDragOverProps<C> {
  currentlyDragged: MutableRefObject<DraggedItemInfo<C> | null>;
  onChange: Dispatch<SetStateAction<BaseItem<C>[]>>;
  cloneHandler: CloneHandler<C>;
}

export type DragOverHandler = (ev: DragOverEvent) => void;
export function useHandleDragOver<C>({
  currentlyDragged,
  cloneHandler,
  onChange,
}: HandleDragOverProps<C>): DragOverHandler {
  const addOrMove = useAddOrMove({ cloneHandler, currentlyDragged });

  return useCallback(
    (ev: DragOverEvent) => {
      const active = currentlyDragged.current;
      if (!active) {
        throw new Error('No active item');
      }
      if (!ev.over) {
        return;
      }
      const { id: overId, isContainer: isOverContainer } = idInfo(ev.over.id);
      if (overId === active.item.id) {
        return;
      }

      onChange((items) => {
        const activeInfo = findItem(items, active.item.id);
        const over = findItem(items, overId);
        if (!over) {
          console.error('Unable to find dragged over item', overId);

          return items;
        }

        if (over.item.mode === 'clone') {
          return items;
        }

        const currentContainer = activeInfo?.path?.at(-1) ?? null;
        const overContainer = over.path.at(-1);

        if (isOverContainer) {
          if (currentContainer !== overId) {
            const target = { container: overId };

            return addOrMove(items, active.item, target);
          }
        } else if (
          overContainer &&
          active.item.id !== overContainer &&
          currentContainer !== overContainer
        ) {
          const target = {
            container: overContainer,
            position: over.position + 1,
          };

          return addOrMove(items, active.item, target);
        }

        return items;
      });
    },
    [currentlyDragged, addOrMove, onChange]
  );
}
