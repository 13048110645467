import { getFieldTypes } from './field-types';
import { FieldType, FieldValue } from './types';

type FormValues = Record<string, unknown>;
export function loadValues(
  definition: FieldValue[],
  values: FormValues,
  fieldTypes?: FieldType[]
): FormValues {
  const types = fieldTypes ?? getFieldTypes();

  return definition.reduce<FormValues>((formVal, field) => {
    const fieldType = types.find((type) => type.type === field.type);
    if (!fieldType) {
      throw new Error(`Unknown field type ${field.type}`);
    }

    if (fieldType.loadValue) {
      return fieldType.loadValue(field, formVal);
    }

    return formVal;
  }, values);
}
