import { ReactElement, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CompanyLocationFormData } from './types';

export interface CompanyLocationFormWrapperProps {
  defaultValues?: Partial<CompanyLocationFormData>;
  onSubmit: (formData: Partial<CompanyLocationFormData>) => void;
  children?: ReactNode;
}

export const CompanyLocationFormWrapper = ({
  onSubmit,
  defaultValues = {},
  children,
}: CompanyLocationFormWrapperProps): ReactElement => {
  const formMethods = useForm<CompanyLocationFormData>({
    mode: 'onBlur',
    defaultValues,
  });
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form
        data-ignore-form
        onSubmit={handleSubmit(onSubmit)}
        style={{ height: '100%' }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
