import {
  forEach, map, has, clone, isArray, isObject,
} from 'lodash-es';
// eslint-disable-next-line import/no-cycle
import { submitForm } from 'BootQuery/Assets/js/BootQuery';
import tr from 'BootQuery/Assets/js/translate';
import * as Api from 'BootQuery/Assets/js/apiRequest';

function activateFilterPickle(filterElement, data) {
  if (!filterElement.attr('name')) {
    return;
  }
  const nameParts = filterElement.attr('name').split('-');
  if (nameParts.length < 2) {
    return;
  }
  const tableName = nameParts.shift();
  const filterName = nameParts.join('-');
  const tableInfo = data.tables[tableName];
  let filterInfo = $.grep(tableInfo.filters, (filter) => filter.key == filterName);

  if (!has(filterInfo, '0.link.source')) {
    filterElement.pickle();
    return;
  }

  [filterInfo] = filterInfo;
  filterElement.pickle({
    multiple: filterInfo.multiple,
    results(searchTerm, results, callback) {
      const element = $(this);
      if (element.data('isQuerying')) {
        return;
      }
      element.data('isQuerying', true);

      const params = {
        sortby: filterInfo.link.textColumn,
        limit: 100,
        value: filterElement.val(),
        idColumn: filterInfo.link.idColumn,
        textColumn: filterInfo.link.textColumn,
        source: filterInfo.link.source,
      };

      let filters = [];
      if (filterInfo.link.filters) {
        // Clone, otherwise search term will stay in there
        // even after removing it, because it's a reference
        filters = clone(filterInfo.link.filters);
      }
      if (searchTerm.length && searchTerm.trim().length) {
        const key = `${filterInfo.link.textColumn}_like_ci`;
        if (isArray(filters)) {
          filters.push({
            key,
            value: searchTerm,
          });
        } else if (isObject(filters)) {
          filters[key] = searchTerm;
        } else {
          console.error('Weird filter format: ', filters);
          throw new Error('Weird filter format');
        }
      }
      params.filters = filters;
      Api.get('/api/getSelectOptions', {
        controller: data.bootquery.controller,
        ...params,
      }).then((results) => {
        const options = results.map((option) => ({
          id: option[filterInfo.link.idColumn],
          text: option[filterInfo.link.textColumn],
        }));
        element.data('isQuerying', false);
        callback(options, searchTerm.length);
      });
    },
  });
}

export default function refreshFilters(target, data) {
  target = $(target);
  if (!data || !data.tables) {
    return;
  }

  forEach(data.tables, (table, tableName) => {
    const format = (option) => {
      let addonIcon = '';
      if (option.addon_icon) {
        addonIcon = option.addon_icon;
      }
      return `<span class="${addonIcon}"></span>&nbsp;&nbsp;${option.name}`;
    };

    const { filters } = table;
    const filterSelector = target.findElement(`#${tableName}-picker .filter-selector`);
    filterSelector.pickle({
      multiple: true,
      searchable: false,
      placeholder: tr('filter.choose_filters'),
      results(searchTerm, inResults, callback) {
        const results = filters
          .filter((filter) => !filter.is_hidden)
          .map((filter) => {
            const formattedText = format(filter);
            const option = {
              id: filter.key,
              text: formattedText,
              icon: filter.addon_icon,
              persist: true,
            };

            if (
              typeof filter.value !== 'undefined'
							&& filter.value !== null
							&& filter.value !== ''
            ) {
              if (filter.display_value == 'true' || filter.display_value == 1) { filter.display_value = 'Da'; }
              if (filter.display_value == 'false' || filter.display_value == 0) { filter.display_value = 'Ne'; }
              option.text = `${formattedText}: ${filter.display_value}`;
            }
            return option;
          });
        callback(results);
      },
    });
    filterSelector.pickle('refresh');
    const usedFilters = $.grep(filters, (filter) => filter.value && !filter.is_hidden);
    const selectedKeys = map(usedFilters, 'key');
    forEach(selectedKeys, (key) => {
      filterSelector.pickle('select', key);
    });

    target.findElement(`#${tableName}-filters-clear`).prop('disabled', usedFilters.length == 0);
    filterSelector.on('select', (e) => {
      e.preventDefault();
      e.stopPropagation();
      const id = e.value;

      const escapedTableName = $.escapeSelector(tableName);
      const escapedId = $.escapeSelector(id);
      const fieldset = $(`#${escapedTableName}-filter_${escapedId}`);
      const input = fieldset
        .show()
        .find('select, input')
        .first();
      fieldset.find('button').prop('disabled', false);

      input.data('initial-value', input.val());
      if (!input.val()) {
        filterSelector.data('noSubmitOnUnselect', true);
        filterSelector.pickle('unselect', id);
        filterSelector.data('noSubmitOnUnselect', false);
      }
      input.prop('disabled', false);
      if (input.is('.filters-pickle')) {
        activateFilterPickle(input, data);
        input.pickle('disabled', false);
      }
      input.focus();
      input.ev('keypress.filters', (ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
          input.closest('form').submit();
        }
      });
      $(`#${tableName}-picker`).hide();
    });

    filterSelector.off('unselect').on('unselect', (e) => {
      if (filterSelector.data('noSubmitOnUnselect')) {
        return;
      }
      const inputName = `${tableName}-${e.value}`;
      const form = $(e.currentTarget).closest('form');
      form.find(`input[name="${inputName}"], select[name="${inputName}"]`)
        .val('')
        .prop('disabled', true);
      form.submit();
    });

    target
      .findElement('.filters-cancel-button')
      .off('click')
      .on('click', (e) => {
        const activeInput = $(`fieldset[id^="${tableName}-filter_"]:visible`).find('input');
        activeInput.val(activeInput.data('initial-value'));
        $(`fieldset[id^="${tableName}-filter_"]`).hide();
        $(`#${tableName}-picker`).show();
        $('.filter-selector').pickle('refresh');
      });

    target
      .findElement(`#${tableName}-picker .pickle-container`)
      .off('click', '.pickle-tag')
      .on('click', '.pickle-tag', (e) => {
        e.preventDefault();
        e.stopPropagation();

        const id = $(e.currentTarget).data('pickle-value');
        $(`fieldset[id^="${tableName}-filter_"]`).hide();
        const escapedTableName = $.escapeSelector(tableName);
        const escapedId = $.escapeSelector(id);
        const field = $(`#${escapedTableName}-filter_${escapedId}`);
        field.show();
        const input = field.find('select, input').first();
        if (input.is('.filters-pickle')) {
          activateFilterPickle(input, data);
        } else {
          input.data('initial-value', input.val());
          input.focus();
          input.ev('keypress.filters', (ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              input.closest('form').submit();
            }
          });
        }
        $(`#${tableName}-picker`).hide();
      });

    target
      .findElement(`#${tableName}-filters-clear`)
      .off('click')
      .on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        const filtersForm = $(e.currentTarget).closest(`#${tableName}-filter-form`);
        filtersForm
          .find('fieldset input, fieldset select')
          .val('')
          .prop('disabled', true);
        filtersForm.submit();
      });

    target
      .findElement(`#${tableName}-filter-form`)
      .off('submit submit.filter-submit')
      .on('submit.filter-submit', (e) => {
        e.stopPropagation();
        e.preventDefault();
        console.log('Submit!');

        const filtersForm = $(e.currentTarget);
        submitForm(filtersForm, data);

        return false;
      });
  });
}
