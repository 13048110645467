import { Input } from '@chakra-ui/react';

import { useCampaignFormProvider } from '../CampaignFormWrapper';

export const NameField = () => {
  const {
    methods: { register },
  } = useCampaignFormProvider();

  return (
    <Input
      size={['lg', 'lg', 'sm']}
      {...register('name', { required: 'required' })}
    />
  );
};
