import { useCallback, useState } from 'react';

import { sleep } from 'app/assets/js/tsutil';

import { useSoftphoneEvent } from './store/softphone-events';
import { SoftphoneError } from './types';

/**
 * Return the last softphone error. Set after softphone emits it, clears
 * after `timeoutMs`.
 * @param timeoutMs - Timeout after which the error is cleared (miliseconds)
 * @returns Error, if present
 */
export function useSoftphoneError(timeoutMs: number): SoftphoneError | null {
  const [error, setError] = useState<SoftphoneError | null>(null);

  useSoftphoneEvent(
    'error',
    useCallback(
      async (err) => {
        setError(err);
        await sleep(timeoutMs);
        setError(null);
      },
      [timeoutMs]
    )
  );

  return error;
}
