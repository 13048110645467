import { Api } from 'BootQuery/Assets/js/api';
import { getTicketGroups } from '../../Api';
import { TicketGroup } from '../../types';
import { FilterOption } from './GroupSelect';

export const loadOptions = async (search: string) => {
  const data = await getTicketGroups({
    filters: {
      $search: search,
    },
    fields: ['ID', 'name'],
  });

  return ticketGroupsToFilterVal(data);
};

export const loadValueFromID = async (ID: number) => {
  const data = await Api.get<TicketGroup>(`/api/ticketing/groups/${ID}`);

  return data.data;
};

const ticketGroupsToFilterVal = (groups: TicketGroup[]): FilterOption[] => {
  return groups.map((group) => ({
    label: group.name,
    value: group.ID,
  }));
};
