module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"currentText") : depth0), depth0)) != null ? stack1 : "")
    + "\n\n\n-----------------------------------\nOn "
    + alias3(lookupProperty(helpers,"format_date").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),lookupProperty(helpers,"tr").call(alias2,"format.datetime_s",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":55}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":5,"column":3},"end":{"line":5,"column":58}}}))
    + " "
    + alias3(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"fromMailAddress") : stack1), depth0))
    + " wrote:\n\n"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"previousText") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"useData":true})