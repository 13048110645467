import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { loadOverviews } from 'BootQuery/Assets/components/Overviews/load-overviews';
import { Jsonish } from 'BootQuery/Assets/components/type-util';
import { Api } from 'BootQuery/Assets/js/api';
import { filterTypes } from '../filter-types';

export async function fetchOverviews(): Promise<IOverviewEditorItem[]> {
  const { data } = await Api.get<Jsonish>('/api/sms/overviews');

  return loadOverviews(data, filterTypes);
}

export function saveOverviews(overviews: IOverviewEditorItem[]): Promise<void> {
  return Api.post('/api/sms/overviews', overviews);
}
