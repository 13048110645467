import { PropsWithChildren, ReactElement, useMemo } from 'react';

import { ContactModal } from './ContactModal';
import { ContactModalContext } from './ContactModalContext';
import { OnContactAdded } from './types';
import { useContactModal } from './use-contact-modal';

interface Props {
  onAdded?: OnContactAdded;
}

type AllProps = PropsWithChildren<Props>;

export const ContactModalProvider = ({
  onAdded,
  children,
}: AllProps): ReactElement => {
  const { assignContact, modalProps } = useContactModal({ onAdded });

  const ctxValue = useMemo(() => ({ assignContact }), [assignContact]);

  return (
    <ContactModalContext.Provider value={ctxValue}>
      {children}
      <ContactModal {...modalProps} />
    </ContactModalContext.Provider>
  );
};
