import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaListOl, FaListUl } from 'react-icons/fa';

import { useRichText } from '../../RichtextContex';
import RichTextButton from '../RichTextButton';
import { useListToggles } from './ToggleHandlers';

export const ListButtonGroup = () => {
  const { t } = useTranslation();
  const { editor } = useRichText();
  const { toggleBulletList, toggleOrderedList } = useListToggles(editor);

  return (
    <>
      <RichTextButton
        key="ol"
        label={t('global:richtext.insert_numbered')}
        icon={<FaListOl />}
        action={toggleOrderedList}
        property="orderedList"
      />
      <RichTextButton
        key="ul"
        label={t('global:richtext.insert_bullet')}
        icon={<FaListUl />}
        action={toggleBulletList}
        property="bulletList"
      />
    </>
  );
};

export const Lists = memo(ListButtonGroup);
