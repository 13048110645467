import { ReactElement, useCallback } from 'react';
import { Box, Button, FormLabel, VStack } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { LinkedCasesRow } from './LinkedCasesRow';
import { CustomEventFormData } from './types';

export const LinkedCasesField = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { control } = useFormContext<CustomEventFormData>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'cases',
  });

  const addCase = useCallback(() => {
    append({ salesCase: null });
  }, [append]);

  return (
    <Box>
      <FormLabel fontWeight="bold">{t('Events:cases')}:</FormLabel>
      <VStack alignItems="stretch">
        {fields.map((field, idx) => (
          <LinkedCasesRow key={field.id} idx={idx} onRemove={remove} />
        ))}
        <Button colorScheme="green" size="sm" variant="ghost" onClick={addCase}>
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('global:add')}
        </Button>
      </VStack>
    </Box>
  );
};
