import { FilterValue } from '@bq/components/FilterBar';
import { Api } from 'BootQuery/Assets/js/api';

import { reportParams } from './report-params';
import { AgentReportRow, ReportRow, ReportSettings } from './types';

export async function loadData(
  settings: ReportSettings,
  filters: FilterValue[] = [],
  groupBy: string[] = []
): Promise<ReportRow[]> {
  if (!settings.datePeriod) {
    throw new Error('Missing date period');
  }
  const params = await reportParams(settings, filters);
  const { data } = await Api.get<ReportRow[]>(
    '/api/ticketing/reports/tickets',
    {
      params: { ...params, groupBy },
    }
  );

  return data;
}

export function userDisplayName(row: AgentReportRow): string {
  return row.user.person?.fullName ?? row.user.username;
}
