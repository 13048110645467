import { SetStateAction } from 'react';

// Workaround for weird TS behaviour. Checking if something is an instanceof function
// should be the right thing to do, but seems to error
// https://github.com/microsoft/TypeScript/issues/37663
//
// eslint-disable-next-line @typescript-eslint/ban-types
const isFunc = (x: unknown): x is Function => typeof x === 'function';

/**
 * Takes the new value parameter that would be passed to a setState function
 * and "applies" it. Meaning it returns the output if it's a function, value otherwise
 * @param val Previous value
 * @param change New value or `(prev) => newValue` function
 * @returns
 */
export function applySetState<T>(val: T, change: SetStateAction<T>): T {
  if (isFunc(change)) {
    return change(val);
  }

  return change;
}
