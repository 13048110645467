import { lazy, ReactElement, Suspense, useState, memo } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { FaEye } from 'react-icons/fa';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { CallListCall } from './types';

const CallDetails = lazy(
  () => import('./CallDetails').then(({ CallDetails }) => ({ default: CallDetails }))
);

interface Props {
  call: CallListCall;
}

const CallDetailsModal = memo(({ call }: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const closeModal = () => {
    setModalOpen(false);
  };
  const openModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <IconButton
        variant="ghost"
        size="sm"
        tooltipPlacement="right"
        hasArrow
        icon={<FaEye />}
        label={t('Telephony:view_details')}
        onClick={openModal}
      />
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('Telephony:details.call_details')}</ModalHeader>
          <ModalBody>
            <Suspense fallback={<LoaderScale />}>
              <CallDetails call={call} />
            </Suspense>
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button onClick={closeModal}>{t('global:close')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}, isEqual);
CallDetailsModal.displayName = 'CallDetailsModal';

export { CallDetailsModal };
