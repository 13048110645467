import { MenuGroup } from '@chakra-ui/react';
import { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableDensity } from './types';
import { ButtonRadio } from '../ButtonRadio';

interface DensitySelectorProps {
    value: TableDensity;
    onChange: (value: TableDensity) => void;
}

export const DensitySelector = ({ value, onChange }: DensitySelectorProps): ReactElement => {
  const { t } = useTranslation();

  const handleChange = useCallback((newVal: string) => {
    if (newVal !== 'sm' && newVal !== 'md' && newVal !== 'lg') {
      console.warn('Got invalid density from input: ', newVal);

      return;
    }

    onChange(newVal);
  }, [onChange]);

  return (
    <MenuGroup title={t('global:display_density')}>
      <ButtonRadio
        name="densitySelector"
        options={densities}
        value={value}
        onChange={handleChange}
      />
    </MenuGroup>
  );
};

const densities = [
  { id: 'sm', title: 'SM' },
  { id: 'md', title: 'MD' },
  { id: 'lg', title: 'LG' },
];
