import { FormEvent, ReactElement, useCallback, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { formatNumber } from 'app/assets/js/tsutil';
import {
  ContactSelector,
  ContactValue,
  OnContactChange,
} from 'app/Modules/Phonebook/Assets/components/ContactSelector';
import { NumberType } from 'app/Modules/Phonebook/Assets/components/types';
import { useNumberTypes } from 'app/Modules/Phonebook/Assets/components/use-number-types';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { ContactModalProps } from './types';

export const ContactModal = (props: ContactModalProps): ReactElement => {
  const { t } = useTranslation('Telephony');

  const { isOpen, onCancel, phoneNumber } = props;
  const numberTypes = useNumberTypes();

  return (
    <Modal
      size="6xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onCancel}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('Telephony:add_number_to_contact')}{' '}
          {phoneNumber && formatNumber(phoneNumber)}
        </ModalHeader>
        {numberTypes ? (
          <ContactModalContent {...props} numberTypes={numberTypes} />
        ) : (
          <ModalBody>
            <LoadingPage />
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

interface ContactModalContentProps extends ContactModalProps {
  numberTypes: NumberType[];
}

export const ContactModalContent = ({
  onSubmit,
  onCancel,
  phoneNumber,
  formState,
  numberTypes,
}: ContactModalContentProps): ReactElement => {
  const [contact, setContact] = useState<ContactValue | null>(null);
  const [numberType, setNumberType] = useState<string>(
    numberTypes[0].ID.toString()
  );

  const handleChange: OnContactChange = useCallback(
    (contact, newlyCreated) => {
      setContact(contact);
      if (newlyCreated && phoneNumber && contact) {
        onSubmit(phoneNumber, contact, parseInt(numberType, 10));
      }
    },
    [onSubmit, phoneNumber, numberType]
  );

  const submit = useCallback(
    (ev: FormEvent): void => {
      ev.preventDefault();

      if (!phoneNumber || !contact) {
        throw new Error('Submitted contact form without a number or contact');
      }

      onSubmit(phoneNumber, contact, parseInt(numberType, 10));
    },
    [phoneNumber, contact, onSubmit, numberType]
  );

  return (
    <>
      <ModalBody>
        <form id="number-to-contact-form" data-ignore-form onSubmit={submit}>
          <ContactSelector value={contact} onChange={handleChange} />
          <Select
            mt={4}
            value={numberType}
            onChange={(ev) => setNumberType(ev.target.value)}
          >
            {numberTypes.map((type) => (
              <option key={type.ID} value={type.ID.toString()}>
                {type.name}
              </option>
            ))}
          </Select>
        </form>
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <FormActions
          form="number-to-contact-form"
          onCancel={onCancel}
          state={formState}
        />
      </ModalFooter>
    </>
  );
};
