import { useState } from 'react';
import { Modal, ModalOverlay } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { onCloseWithNoCallbackEvent, useModalEvents } from './modal-events';
import { ModalInstanceProvider } from './ModalInstanceContext';
import { PreventSubmitDefaultModal } from './PreventSubmitDefaultModal';
import { ModalInstaceProps } from './types';
import { useModal } from './use-modal';

export const ModalInstance = <CloseCallbackData, >({
  modalID,
  componentProps,
  children,
  callback,
  modalRemoveFn,
  preventClose,
  order,
}: ModalInstaceProps<CloseCallbackData>) => {
  const { t } = useTranslation();
  const globalDefaultPreventReason = `${t('global:unsaved_changes')} ${t(
    'personal_confirm'
  )}`;
  const { addModal, highestOrder } = useModal();
  const [isClosePrevented, setIsClosePrevented] = useState<boolean>(
    preventClose ? preventClose.defaultPreventState : false
  );
  const [preventReason, setPreventReason] = useState<string | undefined>(
    preventClose
      ? preventClose.preventReason ?? preventClose.defaultPreventReason
      : globalDefaultPreventReason
  );

  useModalEvents<'closeWithCallback'>(
    'closeWithCallback',
    ({ modalID: callbackModalID, data, noPreventClose }) => {
      if (modalID === callbackModalID) {
        if (isClosePrevented && !noPreventClose) {
          addModal({
            children: (
              <PreventSubmitDefaultModal
                confirmAction={() => {
                  if (callback) {
                    callback(data);
                  }
                  modalRemoveFn(modalID);
                }}
                preventActionDesc={preventReason ?? globalDefaultPreventReason}
              />
            ),
          });
        } else {
          if (callback) {
            callback(data);
          }
          modalRemoveFn(modalID);
        }
      }
    }
  );

  useModalEvents<'closeNoCallback'>(
    'closeNoCallback',
    ({ modalID: callbackModalID, noPreventClose }) => {
      if (modalID === callbackModalID) {
        if (isClosePrevented && !noPreventClose) {
          addModal({
            children: (
              <PreventSubmitDefaultModal
                confirmAction={() => {
                  modalRemoveFn(modalID);
                }}
                preventActionDesc={preventReason ?? globalDefaultPreventReason}
              />
            ),
          });
        } else {
          modalRemoveFn(modalID);
        }
      }
    }
  );

  useModalEvents<'noCloseCallback'>(
    'noCloseCallback',
    ({ modalID: callbackModalID, data }) => {
      if (modalID === callbackModalID) {
        if (callback) {
          callback(data);
        }
      }
    }
  );

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        onCloseWithNoCallbackEvent({ modalID });
      }}
      scrollBehavior="inside"
      trapFocus={highestOrder === order}
      blockScrollOnMount={componentProps?.modal?.size === 'full'}
      {...componentProps?.modal}
    >
      <ModalInstanceProvider
        modalID={modalID}
        preventClose={{
          state: isClosePrevented,
          setState: setIsClosePrevented,
        }}
        preventReason={{
          state: preventReason,
          seetState: setPreventReason,
        }}
      >
        <ModalOverlay {...componentProps?.modalOverlay} />
        {children}
      </ModalInstanceProvider>
    </Modal>
  );
};
