module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<base href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"pdf_base") : stack1), depth0))
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"build_dev") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":1},"end":{"line":31,"column":12}}})) != null ? stack1 : "")
    + "\n	<link rel=\"canonical\" href=\"http://clickvox/"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"controller") : stack1), depth0))
    + "/"
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"method") : stack1), depth0))
    + "\">\n\n	<script nonce=\""
    + alias3(alias2(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"cspNonce")), depth0))
    + "\">\n		function yellAtUserForUsingIE() {\n			// Use var instead of const/let, who knows what they're using\n			var ua = window.navigator.userAgent;\n			if (ua.indexOf(\"MSIE \") > 0 || !!navigator.userAgent.match(/Trident.*rv\\:11\\./)) {\n				window.onload = function () {\n					document.body.innerHTML = [\n						'<div class=\"container pt-3\">',\n						'<div class=\"alert alert-danger\">',\n						'<h3>Unsupported browser</h3>',\n						'<strong>Internet Explorer</strong> is not supported.<br>',\n						'Please use a recent version of any modern browser, such as <strong>Google Chrome</strong>, <strong>Mozilla Firefox</strong> or <strong>Microsoft Edge</strong>.',\n						'</div>',\n						'</div>',\n					].join('\\n');\n				}\n			}\n\n			return false;\n		}\n\n		yellAtUserForUsingIE();\n	</script>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"json_data") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":1},"end":{"line":63,"column":8}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"build_dev") : depth0),{"name":"unless","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":1},"end":{"line":68,"column":12}}})) != null ? stack1 : "")
    + "	<script async src=\""
    + alias3(lookupProperty(helpers,"resolveAssetName").call(alias1,"main.js",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":69,"column":20},"end":{"line":69,"column":50}}}))
    + "\"></script>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<link rel=\"stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,"vendor.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":20,"column":31},"end":{"line":20,"column":64}}}))
    + "\">\n		<link rel=\"stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,"fonts.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":21,"column":31},"end":{"line":21,"column":63}}}))
    + "\">\n		<link rel=\"stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,"main.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":22,"column":31},"end":{"line":22,"column":62}}}))
    + "\">\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"modules") : depth0)) != null ? lookupProperty(stack1,"User") : stack1)) != null ? lookupProperty(stack1,"themes") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":24,"column":2},"end":{"line":30,"column":11}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"resolveAssetName").call(alias1,lookupProperty(helpers,"concat").call(alias1,"theme_",(depth0 != null ? lookupProperty(depth0,"name") : depth0),".css",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":25,"column":27},"end":{"line":25,"column":56}}}),{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":25,"column":9},"end":{"line":25,"column":57}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":3},"end":{"line":27,"column":10}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<link rel=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":15},"end":{"line":26,"column":56}}})) != null ? stack1 : "")
    + "stylesheet\" href=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,lookupProperty(helpers,"concat").call(alias1,"theme_",(depth0 != null ? lookupProperty(depth0,"name") : depth0),".css",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":26,"column":93},"end":{"line":26,"column":122}}}),{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":26,"column":74},"end":{"line":26,"column":124}}}))
    + "\" class=\"theme-stylesheet\" title=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "alternate ";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<link rel=\"stylesheet\" href=\""
    + container.escapeExpression(lookupProperty(helpers,"resolveAssetName").call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_default.css",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":29,"column":32},"end":{"line":29,"column":72}}}))
    + "\" class=\"theme-stylesheet\" title=\"default\">\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<script type=\"application/json\" id=\"initial_json\">\n		"
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"json_data") : depth0), depth0)) != null ? stack1 : "")
    + "\n	</script>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<script async src=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,"runtime.js",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":66,"column":21},"end":{"line":66,"column":54}}}))
    + "\"></script>\n		<script async src=\""
    + alias2(lookupProperty(helpers,"resolveAssetName").call(alias1,"vendor.js",{"name":"resolveAssetName","hash":{},"data":data,"loc":{"start":{"line":67,"column":21},"end":{"line":67,"column":53}}}))
    + "\"></script>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "		<link rel=\"stylesheet\" type=\"text/css\" href=\"/app/assets/css/report.css\">\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"report") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":30},"end":{"line":79,"column":92}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "print"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"pdf") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":79,"column":49},"end":{"line":79,"column":85}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return " pdf";
},"21":function(container,depth0,helpers,partials,data) {
    return "no-pdf";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"bodyClass") : depth0), depth0));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n\n<html lang=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"lang")), depth0))
    + "\" translate=\"no\" class=\"notranslate\">\n\n<head>\n\n	<meta charset=\"utf-8\">\n	<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">\n	<meta name=\"google\" content=\"notranslate\" />\n	<link id=\"favicon\" rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/logo_ast.png\">\n	<title>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + "</title>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"pdf_base") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":1},"end":{"line":15,"column":8}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"report") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":1},"end":{"line":71,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"bootquery") : depth0)) != null ? lookupProperty(stack1,"report") : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":1},"end":{"line":75,"column":8}}})) != null ? stack1 : "")
    + "\n</head>\n\n<body class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"bootquery") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":13},"end":{"line":79,"column":99}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"bodyClass") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":79,"column":99},"end":{"line":79,"column":137}}})) != null ? stack1 : "")
    + " notranslate\">\n";
},"useData":true})