module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, alias2=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-group row\">\n    <label for=\"extension\" class=\"col-12 col-md-4 col-form-label text-left text-md-right\">"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"form.extension",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":2,"column":90},"end":{"line":2,"column":113}}}))
    + ":</label>\n    <div class=\"col-12 col-md-8\">\n        <input type=\"hidden\" name=\"extension\" value=\""
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"extensionID") : depth0), depth0))
    + "\">\n        <div class=\"form-control-plaintext\">"
    + alias1(alias2((depth0 != null ? lookupProperty(depth0,"extension") : depth0), depth0))
    + "</div>\n    </div>\n</div>";
},"useData":true})