import { FaFont } from 'react-icons/fa';
import { useRichText } from '../../RichtextContex';
import { RichTextColor } from '../RichTextColor';

export const TextColor = () => {
  const { editor } = useRichText();
  const onInput = (color: string | null) => editor
    ?.chain()
    .focus()
    .setColor(color ?? '#FFF')
    .run();

  return (
    <RichTextColor
      key="color"
      icon={<FaFont />}
      property="textStyle"
      inputHandler={onInput}
      defaultValue="#000"
    />
  );
};
