import { memo, useEffect, useMemo } from 'react';
import { EditorContent, useEditor } from '@tiptap/react';

import './fix.css';

import { AllExtensions } from './Extensions/AllExtensions';
import { FocusProvider } from './FocusContext';
import { RichTextProvider } from './RichtextContex';
import { RichTextToolbar } from './RichTextToolbar';
import { TableBubbleMenu } from './TableBubbleMenu';
import { RTEToolbar } from './Toolbar/RTEToolbar';
import { IRichText } from './types';

const RichText = ({ content, onChange, extensions }: IRichText) => {
  const externalExts = useMemo(
    () => extensions?.map((item) => item.extension) ?? [],
    [extensions]
  );
  const editor = useEditor({
    extensions: [...AllExtensions, ...externalExts],
    content,
  });
  useEffect(() => {
    editor?.off('blur');
    editor?.on('blur', ({ editor: updatedEditor }) => {
      onChange(updatedEditor.getHTML());
    });
  }, [editor, onChange]);

  return (
    <RichTextProvider editor={editor}>
      <FocusProvider>
        <RichTextToolbar>
          <RTEToolbar extensions={extensions} />
        </RichTextToolbar>
        {editor && <TableBubbleMenu editor={editor} />}
      </FocusProvider>
      <EditorContent editor={editor} className="editor" />
    </RichTextProvider>
  );
};

const RichTextMemo = memo(RichText);

export { RichTextMemo as RichText };
