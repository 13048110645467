import { ChangeEvent, ReactElement, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import { FaInfoCircle } from 'react-icons/fa';
import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { FileFieldSettings } from './types';
import { FileFieldAllowedTypes } from './FileFieldAllowedTypes';
import { FileSizeInput } from './FileSizeInput';

type Props = EditorFieldComponentProps<FileFieldSettings>;

export const FileFieldSettingsComponent = (props: Props): ReactElement => {
  const { setSetting } = useFieldSettings(props);

  const { t } = useTranslation();

  const setName = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setSetting('name', ev.target.value);
    },
    [setSetting]
  );
  const setMaxFileSize = useCallback(
    (val: SetStateAction<number | undefined>) => {
      setSetting('maxFileSize', val);
    },
    [setSetting]
  );
  const setAllowedTypes = useCallback(
    (val?: string | string[]) => {
      const accept = Array.isArray(val) && val.length === 0 ? '*' : val;
      setSetting('accept', accept);
    },
    [setSetting]
  );

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input
          placeholder={t('global:name') ?? undefined}
          value={props.content.settings.name}
          onChange={setName}
          size="sm"
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('global:form_editor.file_field_max_size')}</FormLabel>
        <FileSizeInput
          value={props.content.settings.maxFileSize}
          onChange={setMaxFileSize}
        />
      </FormControl>
      <FormControl>
        <HStack alignItems="center" mb="0.5rem" spacing={0}>
          <FormLabel mb={0}>
            {t('global:form_editor.file_field_allowed_types')}
          </FormLabel>
          <Tooltip label={t('global:form_editor.file_field_allowed_types_input_info')}>
            <Box>
              <FaInfoCircle style={{ margin: 0, verticalAlign: 'bottom' }} />
            </Box>
          </Tooltip>
        </HStack>
        <FileFieldAllowedTypes
          value={props.content.settings.accept}
          onChange={(newValue) => {
            if (newValue) {
              const acceptValues = newValue.map((item) => item.value);
              setAllowedTypes(acceptValues);
            }
          }}
        />
      </FormControl>
    </VStack>
  );
};
