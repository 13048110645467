import { ExportParams, ExportResponse } from '@bq/components/Export';
import { FilterExpression } from 'BootQuery/Assets/components/FilterBar';
import { Api } from 'BootQuery/Assets/js/api';

import { Call } from '../../types/call';

export interface CallsResponse {
  data: Call[];
  meta: {
    count: number | null;
  };
}

export interface GetCallParams {
  page?: number;
  filters?: FilterExpression[];
  limit?: number;
  fields?: unknown[];
  withContacts?: boolean;
  withCount?: boolean;
}

export async function getCalls({
  fields = undefined,
  page = 1,
  filters = [],
  limit = 10,
  withContacts = true,
  withCount = true,
}: GetCallParams): Promise<CallsResponse> {
  const { data } = await Api.get('/api/telephony/calls', {
    params: {
      page,
      filters,
      limit,
      fields,
      withContacts,
      withCount,
    },
  });

  return data as CallsResponse;
}

export async function markVoicemailListened(
  voicemailID: number
): Promise<void> {
  await Api.put(`/api/telephony/voicemails/${voicemailID}/listened`, true);
}

export async function generateExport(
  params: ExportParams
): Promise<ExportResponse> {
  const { data } = await Api.get<ExportResponse>(
    '/api/telephony/calls/export',
    {
      params,
    }
  );

  return data;
}
