import { EventEmitter } from 'events';
import { ReactRouteMatcher } from './react-routes';
import SocketEventListener from './socket-event-listener';

export default class Module extends EventEmitter {
  socketEvents: SocketEventListener;

  constructor() {
    super();

    this.socketEvents = new SocketEventListener(this.constructor.name);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public init(data: Record<string, unknown>): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public activateElements(target: string, data: Record<string, unknown>): void {}

  public static canHandleRoute(route: string): boolean {
    return false;
  }

  public static matchReactRoute?: ReactRouteMatcher;
}
