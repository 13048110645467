import { Api } from 'BootQuery/Assets/js/api';
import reusePromise from 'BootQuery/Assets/js/reuse-promise';
import SocketEventListener from 'BootQuery/Assets/js/socket-event-listener';
import { create as zustand } from 'zustand';
import { DeviceStatusEvent } from '../types/device';
import { PauseEvent, QueueMemberEvent, User } from '../types/user';

let eventListener: SocketEventListener | null = null;

export interface PbxUsersStore {
    loaded: boolean;
    users: User[];
    init: () => Promise<void>;
    addUser: (newUser: User) => void;
    updateUser: (newUser: User) => void;
    removeUser: (userNumber: string) => void;
}

export const pbxUsers = zustand<PbxUsersStore>((set, get) => ({
  loaded: false,
  users: [],
  addUser(newUser) {
    set({
      users: [...get().users, newUser],
    });
  },
  updateUser(newUser) {
    set({
      users: get().users.map((existingUser) => {
        if (existingUser.phoneNumber.phoneNumberE164 === newUser.phoneNumber.phoneNumberE164) {
          return newUser;
        }

        return existingUser;
      }),
    });
  },
  removeUser(userNumber) {
    set({
      users: get().users.filter((user) => user.phoneNumber.phoneNumberE164 !== userNumber),
    });
  },
  init: reusePromise(async () => {
    const state = get();
    if (state.loaded) {
      return;
    }

    if (!eventListener) {
      eventListener = new SocketEventListener('telephonyCurrentCalls');
    }

    eventListener.subscribeWebSocket('telephony/deviceStatus', (ev: DeviceStatusEvent) => {
      const changedUser = get().users.find(
        (user) => user.devices.some((dev) => dev.device.deviceId === ev.deviceId)
      );
      if (changedUser) {
        changedUser.devices = changedUser.devices.map((dev) => {
          if (dev.device.deviceId === ev.deviceId) {
            return {
              device: { ...dev.device, currentState: ev.status },
            };
          }

          return dev;
        });
        get().updateUser(changedUser);
      }
    });
    eventListener.subscribeWebSocket('telephony/agentPause', (ev: PauseEvent) => {
      const changedUser = get().users.find((user) => user.phoneNumber.phoneNumberE164 === ev.user);
      if (changedUser) {
        changedUser.queues = changedUser.queues.map(
          (queue) => ({
            ...queue,
            pause: { name: ev.pause, startAt: new Date() },
          })
        );
        get().updateUser(changedUser);
      }
    });
    eventListener.subscribeWebSocket('telephony/agentUnpause', (ev: PauseEvent) => {
      const changedUser = get().users.find((user) => user.phoneNumber.phoneNumberE164 === ev.user);
      if (changedUser) {
        changedUser.queues = changedUser.queues.map(
          (queue) => ({ ...queue, pause: null })
        );
        get().updateUser(changedUser);
      }
    });

    eventListener.subscribeWebSocket('telephony/queueLogin', (ev: QueueMemberEvent) => {
      const users = get().users.map((user) => {
        if (user.phoneNumber.phoneNumberE164 === ev.agent) {
          return {
            ...user,
            queues: [...user.queues, {
              queue: { name: ev.queue },
              penalty: 0,
              wrapupTime: 0,
              pause: null,
            }],
          };
        }

        return user;
      });

      set({ users });
    });
    eventListener.subscribeWebSocket('telephony/queueLogout', (ev: QueueMemberEvent) => {
      const users = get().users.map((user) => {
        if (user.phoneNumber.phoneNumberE164 === ev.agent) {
          return {
            ...user,
            queues: user.queues.filter((queue) => queue.queue.name !== ev.queue),
          };
        }

        return user;
      });

      set({ users });
    });

    const { data } = await Api.get<User[]>('/api/telephony/users');

    // Go through all users and parse their pause startAt dates
    const users = data.map((user) => ({
      ...user,
      queues: user.queues.map((queue) => ({
        ...queue,
        pause: queue.pause ? ({ ...queue.pause, startAt: new Date(queue.pause.startAt) }) : null,
      })),
    }));

    set({ users, loaded: true });
  }),
}));
