import { ReactElement, useMemo } from 'react';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'BootQuery/Assets/components/Select';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';

import { CustomEventFormData, CustomEventType } from './types';

interface Props {
  availableTypes: CustomEventType[];
}

export const EventTypeInput = ({ availableTypes }: Props): ReactElement => {
  const { t } = useTranslation('Events');

  const options = useMemo(
    () => typesToOptions(availableTypes),
    [availableTypes]
  );

  const { control } = useFormContext<CustomEventFormData>();
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: 'type',
    control,
    rules: { required: true },
    defaultValue: options[0],
  });

  return (
    <FormControl isInvalid={!!error}>
      <FormLabel fontWeight="bold">
        <>{t('Events:custom_event_type')}:</>
      </FormLabel>
      <Select
        key="eventTypeID"
        options={options}
        value={value}
        onChange={onChange}
        placeholder={t('Events:custom_event_type')}
        styles={modalSelect}
        selectedOptionColorScheme="brand"
      />
    </FormControl>
  );
};

function typesToOptions(types: CustomEventType[]): SelectOption[] {
  return types
    .filter((type) => !type.deletedAt)
    .map((type) => ({ value: type.ID.toString(), label: type.name }));
}
