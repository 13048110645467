export const defaultVisibleColumns = {
  $selected: true,
  change: true,
  ID: true,
  productType: true,
  name: true,
  longName: false,
  description: false,
  itemProgram: false,
  itemGroup: false,
  itemSubgroup: false,
  defaultAmountUnit: false,
  amountUnitGroup: false,
  packagingAmount: false,
  warrantyMonths: false,
  isThirdPartyService: false,
  producer: false,
  supplier: false,
  code: false,
  barcode: false,
  isCommision: false,
  note: false,
  currency: false,
  taxRate: false,
  wholesalePrice: false,
  retailPrice: false,
  purchasePrice: false,
  actionPrices: false,
  height: false,
  width: false,
  length: false,
  netWeight: false,
  grossWeight: false,
  actions: true,
};
