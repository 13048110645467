import EventEmitter from 'events';
import SignaturePad from 'signature_pad';
import { clone } from 'lodash-es';
import { getTemplate } from './BootQuery';

export default class SignatureField extends EventEmitter {
  constructor(element) {
    super();
    this.$el = $(element);
    this.$valInput = this.$el.find('input.form-signature-input');
    this.$modal = null;
    this.signaturePad = null;
    this.signature = null;

    const val = this.$valInput.val();
    const data = val ? (JSON.parse(val) || null) : null;
    if (data) {
      this.signature = data.signature;
      if (data.svg) {
        this.setPreview(data.svg);
      }
    }
  }

  init() {
    this.$el.ev('click.signature-field', (ev) => {
      ev.preventDefault();
      const $tgt = $(ev.target);
      if ($tgt.is('.dropdown') || $tgt.closest('.dropdown').length > 0) {
        return;
      }
      this.openModal();
    });
  }

  save() {
    this.signature = clone(this.signaturePad.toData());

    const canvas = this.$modal.find('canvas').get(0);
    const size = {
      width: parseInt(canvas.width, 10),
      height: parseInt(canvas.height, 10),
    };
    const imgUrl = this.signaturePad.toDataURL('image/svg+xml');
    const data = {
      size,
      svg: imgUrl,
      signature: this.signature,
    };
    this.$valInput.val(JSON.stringify(data));
    this.setPreview(imgUrl);
    this.$el.find('.dropdown').prop('hidden', false);
  }

  setPreview(previewDataUrl) {
    let previewEl = this.$el.find('img.form-signature-preview');
    if (previewEl.length === 0) {
      previewEl = $('<img/>', { class: 'form-signature-preview' }).appendTo(this.$el);
    }
    previewEl.attr('src', previewDataUrl);
  }

  async openModal() {
    const modalTemplate = await getTemplate('signatureModal');
    this.$modal = $.render(modalTemplate, {});
    this.$modal.find('.signature-clear-button').ev('click.signature', (ev) => {
      ev.preventDefault();
      this.signaturePad.clear();
    });
    this.$modal.find('.signature-submit-button').ev('click.signature', (ev) => {
      ev.preventDefault();
      this.save();
      this.$modal.modal('hide');
    });
    this.$modal.find('.signature-download').ev('click.signature', (ev) => {
      const mime = $(ev.currentTarget).data('format');
      const dataUrl = this.signaturePad.toDataURL(mime);
      ev.originalEvent.currentTarget.href = dataUrl;
    });
    // Wait for things to get a size we can measure before initialising things
    this.$modal.ev('shown.bs.modal', () => {
      const canvas = this.$modal.find('canvas').get(0);
      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
      });
      if (this.signature) {
        this.signaturePad.fromData(clone(this.signature));
      }
      this.resizeEditor();
    });
    this.$modal.ev('hidden.bs.modal', this.onModalClose.bind(this));
    $(window).ev('resize.signature-field', this.resizeEditor.bind(this));
    this.$modal.modal('show');
  }

  onModalClose() {
    this.signaturePad.off();
    this.signaturePad = null;
    this.$modal.modal('dispose');
    // The element will stay in the DOM even after dispose, so need to remove manually
    this.$modal.remove();
    this.$modal = null;
  }

  resizeEditor() {
    if (!this.$modal) {
      return; // Nothing to resize
    }

    const canvas = this.$modal.find('canvas').get(0);
    const ratio = Math.max(window.devicePixelRatio || 1, 1); // Handle high DPI
    const state = this.signaturePad.toData(); // Store signature data before clear

    // Clear canvas and signature pad internal data
    canvas.style.height = '100%';
    canvas.style.width = '100%';
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear();

    this.signaturePad.fromData(state); // Restore signature data and re-draw
  }
}
