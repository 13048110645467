import { FilterTypes } from '../FilterBar';
import { FieldType, FieldValue } from './types';

export function customFormFilters(
  fields: FieldValue[],
  fieldTypes: FieldType[]
): FilterTypes {
  return fields.reduce((columns, field) => {
    const fieldType = fieldTypes.find((type) => type.type === field.type);
    if (fieldType?.getFilters) {
      return { ...columns, ...fieldType.getFilters(field) };
    }

    return columns;
  }, {} as FilterTypes);
}
