import { useContext, useMemo } from 'react';
import { useAllItemsWithChanges } from './apply-item-changes';
import { SelectFieldSettingsContext } from './SelectFieldSettingsContext';
import { ItemsBySelect, SelectEditorSettings, SelectedItem } from './types';

function findSelectIndex(
  selects: SelectEditorSettings[],
  selectId: string
): number {
  return selects.findIndex((sel) => sel.id === selectId);
}

export function findSelectedPath(
  items: ItemsBySelect,
  selects: SelectEditorSettings[],
  selectedItem: SelectedItem | null
): SelectedItem[] {
  if (!selectedItem) {
    return [];
  }

  const { selectId, itemId } = selectedItem;
  const selectIdx = findSelectIndex(selects, selectId);
  if (selectIdx === -1) {
    throw new Error(`Unable to find select ${selectId}`);
  }
  const selectItems = items[selectId] ?? [];
  const item = selectItems.find((item) => item.key === itemId);
  if (!item) {
    // Return empty array instead of throwing for now
    // This case is possible since we handle resetting the selected item using useEffect, which
    // allows a re-render to happen before resetting the selected item
    console.warn(`Unable to find item ${itemId} in select ${selectId}`);

    return [];
  }

  let parentPath: SelectedItem[] = [];
  if (item.parentItemId) {
    if (selectIdx === 0) {
      throw new Error(
        `Tried to find parent item ${item.parentItemId}, but no more selects after ${selectId}`
      );
    }
    const parentSelect = selects[selectIdx - 1];
    const parentSelection: SelectedItem = {
      selectId: parentSelect.id,
      itemId: item.parentItemId,
    };
    parentPath = findSelectedPath(items, selects, parentSelection);
  }

  return [...parentPath, selectedItem];
}

export function useSelectedPath(): SelectedItem[] {
  const {
    selectedItem,
    settings: { selects },
  } = useContext(SelectFieldSettingsContext);

  const items = useAllItemsWithChanges();

  return useMemo(() => findSelectedPath(items, selects, selectedItem), [
    selectedItem,
    items,
    selects,
  ]);
}
