import { PriceType } from 'app/Modules/Sales/Assets/components/Offers/types';
import { Price } from 'app/Modules/Sales/Assets/js/Price';

import { PriceData } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseNumberOrUndefined = (val: any): number | undefined => {
  if (!val) {
    return undefined;
  }
  const toNum = parseFloat(val);
  if (Number.isNaN(toNum)) {
    return undefined;
  }

  return toNum;
};

export const calculateWithTaxDefaults = (
  data?: Partial<PriceData>,
  tax?: string | null
): Record<PriceType, number | undefined> => {
  if (!data) {
    return {
      wholesalePrice: undefined,
      contractPrice: undefined,
      retailPrice: undefined,
      purchasePrice: undefined,
    };
  }
  const { wholesalePrice, contractPrice, retailPrice, taxRate, purchasePrice } =
    data;

  const taxToNum = parseTax(tax ?? taxRate?.taxRate);

  if (!taxToNum) {
    return { wholesalePrice, contractPrice, retailPrice, purchasePrice };
  }

  return {
    wholesalePrice: parseSingularValue(wholesalePrice, taxToNum),
    contractPrice: parseSingularValue(contractPrice, taxToNum),
    retailPrice: parseSingularValue(retailPrice, taxToNum),
    purchasePrice: parseSingularValue(purchasePrice, taxToNum),
  };
};

const parseTax = (tax?: string) => {
  if (tax) {
    const taxNumVal = parseNumberOrUndefined(tax);
    if (taxNumVal) {
      return taxNumVal / 100;
    }

    return undefined;
  }

  return undefined;
};

const parseSingularValue = (
  value: number | undefined,
  tax: number | undefined
) => {
  const numVal = parseNumberOrUndefined(value);
  if (numVal) {
    const toPrice = new Price(numVal);
    const calcTax = toPrice.mul([tax]).add([numVal]).asNumber;

    return calcTax;
  }

  return undefined;
};
