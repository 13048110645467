import { useMemo } from 'react';
import { HStack, Text } from '@chakra-ui/react';
import { omit } from 'lodash-es';

import { searchContactsRaw } from '@bq/components/FormFields/Contact/utils';
import { Contact, ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import { Api } from 'BootQuery/Assets/js/api';
import i18n from 'BootQuery/Assets/js/i18n';

import { iconForContactType } from '../../ContactDisplay/util';
import { PartialExceptFor } from '../../type-util';
import { makeComboboxFilter } from './ComboboxFilter';
import { ComboboxFilterDef } from './ComboboxFilter/make-combobox-filter';

type ContactVal = Pick<Contact, 'type' | 'ID'>;
type AllowedContactTypes = Record<ContactType, boolean>;
type ContactFilterProps = ComboboxFilterDef<Contact, ContactVal>;

const contactValueToItem = async (value: ContactVal): Promise<Contact> => {
  const { data: contact } = await Api.get<Contact>(
    `/api/phonebook/contacts/${value.type}/${value.ID}`
  );

  return contact;
};

const contactItemToValue = ({ type, ID }: Contact): ContactVal => ({
  type,
  ID,
});

const ContactFilterItem = (item: Contact) => {
  const Icon = useMemo(() => iconForContactType(item.type), [item.type]);

  return (
    <HStack alignItems="center">
      <Icon style={{ display: 'inline' }} />
      <Text>{item.name}</Text>
    </HStack>
  );
};

const defaultAllowedContactTypes: AllowedContactTypes = {
  company: true,
  person: true,
  companyLocation: true,
  user: false,
  companyDepartment: false,
};

/**
 * Take a map of allowed contact types and generate a cache key.
 * Since contact types are the only difference, we can use that as a unique key.
 *
 * The key will be a sorted array of allowed contact types seperated by ":"
 *
 * For example:
 * ```javascript
 * { company: true, person: true, companyLocation: true, user: false, companyDepartment: false }
 * ```
 * Will return `"contactCombobox:company:companyLocation:person"`
 *
 */
function makeContactCacheKey(allowedContactTypes: AllowedContactTypes): string {
  // Filter out allowed types and sort their keys
  const keyParts = Object.entries(allowedContactTypes)
    .filter(([_type, isAllowed]) => isAllowed)
    .map(([type, _isAllowed]) => type)
    .sort((typeA, typeB) => typeA.localeCompare(typeB));

  return `contactCombobox:${keyParts.join(':')}`;
}

const makeContactFilterExtra = (props: ContactFilterExtraProps) => {
  const allowedContactTypes =
    props.allowedContactTypes ?? defaultAllowedContactTypes;
  const cacheKey = props.cacheKey ?? makeContactCacheKey(allowedContactTypes);
  const enableTextSearch = props.enableTextSearch ?? true;

  return {
    cacheKey,
    enableTextSearch,
    search: (search: string) => {
      return searchContactsRaw(search, allowedContactTypes);
    },
    itemToString: (item: Contact) => item.name,
    itemToValue: contactItemToValue,
    valueToItem: contactValueToItem,
    renderItem: ContactFilterItem,
  };
};

const contactFilterDefaults: (
  props: ContactFilterExtraProps
) => Omit<ContactFilterProps, 'toFilter'> = (extraProps) => ({
  name: () => i18n.t('Telephony:filters.contact'),
  operators: [],
  extraProps: makeContactFilterExtra(extraProps),
});

interface ContactFilterExtraProps {
  allowedContactTypes?: AllowedContactTypes;
  enableTextSearch?: boolean;
  cacheKey?: string;
}

type ContactFilterBasePorps = PartialExceptFor<ContactFilterProps, 'toFilter'>;
export type ContactFilterOptions = ContactFilterBasePorps &
  ContactFilterExtraProps;

/**
 * Used to define a Contact filter.
 * Already has defaults that are fit for most contact filtering use-cases.
 * Use only if you want to extend with other components (like custom options),
 * or new API endpoints.
 * @param filterDef - Override defined (ComboBox) filters for filtering contacts.
 * @param allowedContactTypes - allowed contact types, by default all
 * @returns Contact Filter
 */
export function makeContactFilter(filterDef: ContactFilterOptions) {
  return makeComboboxFilter({
    ...contactFilterDefaults(filterDef),
    ...omit(filterDef, ['allowedContactTypes']),
  });
}
