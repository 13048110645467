import {
  Box,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';
import { useModalInstance } from '@bq/components/UseModal';
import { CompanyLocationFormFields } from 'app/Modules/Phonebook/Assets/components/CompanyLocationForm';

import {
  CompanyLocationFormWrapper,
  useCompanyLocationFormWrapper,
} from './CompanyLocationFormWrapper';
import { CompanyLocationModalContentProps } from './types';
import { useLabelForCompanyLocationModal } from './utils';

export const CompanyLocationModalContent = (
  props: CompanyLocationModalContentProps
) => {
  console.log(props.data);
  const label = useLabelForCompanyLocationModal(props.mode);
  const { closeWithNoCallback } = useModalInstance();
  const { formState } = useCompanyLocationFormWrapper();

  return (
    <CompanyLocationFormWrapper {...props}>
      <ModalHeader>
        <HStack>
          <Box>
            <FaPlus />
          </Box>
          <Box>{label}</Box>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <CompanyLocationFormFields />
      </ModalBody>
      <ModalFooter>
        <FormActions
          state={formState}
          onCancel={() => {
            closeWithNoCallback();
          }}
        />
      </ModalFooter>
    </CompanyLocationFormWrapper>
  );
};
