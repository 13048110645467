import { useFormState } from 'BootQuery/Assets/components/form-state';
import { useCallback, useState } from 'react';
import { insertPerson } from '../api';
import { PersonFormData } from '../PersonForm';
import { CreatePersonModalProps, OnPersonCreate, PersonCreate } from './types';

type FormData = Partial<PersonFormData>;

export interface UseCreatePersonModalProps {
  onCreate?: OnPersonCreate;
}

export interface UseCreatePersonModalResult {
  createPerson: PersonCreate;
  modalProps: CreatePersonModalProps;
}

interface ModalState {
  editing: FormData;
  onCreate?: OnPersonCreate;
}

export function useCreatePersonModal({
  onCreate,
}: UseCreatePersonModalProps): UseCreatePersonModalResult {
  const [modalState, setModalState] = useState<ModalState | null>(null);
  const [formState, setFormState] = useFormState();

  const createPerson: PersonCreate = useCallback(
    (defaults, onCreate) => {
      setModalState({
        onCreate,
        editing: {
          nationalNumber: { type: 'HR-oib', nationalNumber: '' },
          ...defaults,
        },
      });
      setFormState(null);
    },
    [setFormState]
  );

  const onClose = useCallback(() => {
    setModalState(null);
    setFormState(null);
  }, [setFormState]);

  const onSave = useCallback(
    async (formData: FormData) => {
      setFormState('saving');

      const personID = await insertPerson(formData);

      if (modalState?.onCreate) {
        modalState.onCreate(personID, formData);
      }
      if (onCreate) {
        onCreate(personID, formData);
      }
      setFormState('saved');

      onClose();
    },
    [setFormState, onCreate, onClose, modalState]
  );

  return {
    createPerson,
    modalProps: {
      editing: modalState?.editing ?? null,
      formState,
      onClose,
      onSave,
    },
  };
}
