import { useQuery } from '@tanstack/react-query';

import { BaseSettings, FieldValue } from '@bq/components/FormEditor';
import { useFilterTypes } from '@bq/components/ListingSettings/utils';
import { loadOverviews } from '@bq/components/Overviews';
import { Jsonish } from '@bq/components/type-util';
import { Api } from 'BootQuery/Assets/js/api';

import { ticketFilterTypes } from '../filter-types';

export const useOverviews = (
  customFields: FieldValue<BaseSettings>[] | null
) => {
  const allFilterTypes = useFilterTypes(ticketFilterTypes, customFields ?? []);

  const overviews = useQuery({
    queryKey: ['ticketingOverviews'],
    queryFn: async () => {
      const { data } = await Api.get<Jsonish>('/api/ticketing/overviews', {});

      return loadOverviews(data, allFilterTypes);
    },
    enabled: !!customFields,
    refetchOnMount: false,
  });

  return overviews;
};
