import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { CardBody } from '@bq/components/Card';
import { LoadingPage } from '@bq/components/LoadingPage';
import { useBackgroundTask } from '@bq/components/use-background-task';

import { ImportTaskState } from '../../components/ImportTaskState';
import { ImportRouteParams, ImportState } from '../../components/types';
import { useCardBodyProps } from '../../components/use-card-body-props';

export const CreateProgress = (): ReactElement => {
  const { taskId } = useParams<ImportRouteParams>();
  if (!taskId) {
    throw new Error('Missing taskId route param');
  }

  const { data: task } = useBackgroundTask<ImportState>(taskId);

  return (
    <CardBody {...useCardBodyProps()} p={0}>
      {task ? <ImportTaskState {...task} /> : <LoadingPage />}
    </CardBody>
  );
};
