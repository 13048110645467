import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';
import { Plugin, PluginKey } from 'prosemirror-state';

const Collapse = Node.create({
  name: 'prev-message',
  addOptions() {
    return {
      HTMLAttributes: {
        class: 'prev-message',
      },
    };
  },
  content: 'block*',
  group: 'block',
  defining: true,
  parseHTML() {
    return [
      {
        tag: 'blockquote',
      },
    ];
  },
  addAttributes() {
    return {
      collapse: {
        default: 'closed',
        parseHTML: (element) => element.getAttribute('class'),
        renderHTML: (attributes) => ({
          class: attributes.collapse,
        }),
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      { class: 'prev-message-container' },
      [
        'button',
        {
          class: 'prev-message-button',
          contenteditable: 'false',
          type: 'button',
        },
        '...',
      ],
      ['blockquote', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0],
    ];
  },
  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey('clickHandler'),
        attr: {},
        props: {
          handleClick(view, pos, event) {
            if (
              event.target instanceof HTMLButtonElement &&
              event.target.classList.contains('prev-message-button')
            ) {
              view.dispatch(view.state.tr.setNodeMarkup(pos - 1, null, { collapse: 'open' }));
            }

            return false;
          },
        },
      }),
    ];
  },
});

export default Collapse;
