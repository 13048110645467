import Module from 'BootQuery/Assets/js/module';
import * as Api from 'BootQuery/Assets/js/apiRequest';
import Vue from 'BootQuery/Assets/js/vue';
import SoundMessage from 'app/assets/sounds/short.mp3';
import { purgeReact } from 'BootQuery/Assets/js/render-react';
import getRouter from './router';

const FacebookChatComponent = () => import('../components/FacebookChat.vue');

export default class FacebookChat extends Module {
  async init(_data) {
    super.init();
    this.messageSound = new Audio(SoundMessage);
    $(document).ev('renderController.facebookChat', this.destroyChatInterface.bind(this));
    this.loaded = false;
    this.facebookAccounts = await Api.get('/api/facebookChat/availableAccounts');
    if (this.facebookAccounts.length) {
      this.unreadChatIDs = await Api.get('/api/facebookChat/unreadChatIDs');
      this.setupEvents();
    } else {
      this.unreadChatIDs = [];
    }

    this.loaded = true;
    this.updateChatNotifier();
    this.emit('loaded');
  }

  setupEvents() {
    const newMessageEvents = this.facebookAccounts.map(
      (account) => `facebookChat/${account.ID}/newMessage`,
    );
    this.socketEvents.subscribeWebSocket(newMessageEvents, this.onMessage.bind(this));
    const seenEvents = this.facebookAccounts.map(
      (account) => `facebookChat/${account.ID}/chatSeen`,
    );
    this.socketEvents.subscribeWebSocket(seenEvents, this.onSeen.bind(this));
  }

  activateElements(target, _data) {
    const $chatContainer = target.findElement('#facebook-chat-container');
    if ($chatContainer.length) {
      this.renderChatInterface($chatContainer[0]);
    }
  }

  async renderChatInterface(chatContainer) {
    this.component = new Vue({
      el: chatContainer,
      router: getRouter(),
      render: (h) => h(FacebookChatComponent),
    });
  }

  renderChatRoute() {
    const existing = document.querySelector('#facebook-chat-container');
    if (existing) {
      this.renderChatInterface(existing, true);
    } else {
      purgeReact($(window.targetElement)[0]);
      const target = $('<div/>', { id: 'facebook-chat-container' });
      $(window.targetElement).html(target);
      this.renderChatInterface(target[0], true);
    }
    $(document).trigger('activateElements', [$(window.targetElement), window.Bootstrap]);
  }

  handleRoute(route) {
    if (route.startsWith('/facebookChat/')) {
      $(document).trigger('renderController', [window.targetElement, window.Bootstrap]);
      this.renderChatRoute();
      return;
    }
    throw new Error(`Don't know how to handle route: '${route}'`);
  }

  static canHandleRoute(route) {
    return route.startsWith('/facebookChat/');
  }

  destroyChatInterface() {
    if (this.component) {
      this.component.$destroy();
      this.component = null;
    }
  }

  updateChatNotifier() {
    const menuItem = $('.menu-container .nav-item > .nav-link[data-controller="facebookChat"]');
    const menuItemBadge = menuItem.find('.menu-item-notification-counter');
    menuItemBadge.text(this.unreadChatIDs.length);
    menuItemBadge.prop('hidden', this.unreadChatIDs.length === 0);
  }

  onMessage(message) {
    if (!message.seen && !message.direction) {
      if (this.unreadChatIDs.indexOf(message.entityID) === -1) {
        this.unreadChatIDs.push(message.entityID);
      }
      this.updateChatNotifier();
      this.messageSound.play();
    }
    this.emit('newMessage', message);
  }

  onSeen(data) {
    const index = this.unreadChatIDs.indexOf(data.entityID);
    if (index !== -1) {
      this.unreadChatIDs.splice(index, 1);
      this.updateChatNotifier();
    }
  }
}
