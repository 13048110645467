import { useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

interface UserGroup {
  ID: number;
  name: string;
}

export async function getGroups(): Promise<UserGroup[]> {
  const { data: groups } = await Api.get<UserGroup[]>('/api/groups');

  return groups.map(({ ID, name }) => ({ ID, name }));
}
export function useGroups(): UserGroup[] {
  const { data: groups } = useQuery({
    queryKey: ['userGroups'],
    queryFn: getGroups,
    suspense: true,
  });

  if (!groups) {
    throw new Error('Expected groups to be loaded with suspense');
  }

  return groups;
}
