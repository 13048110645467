import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { ImportSetupForm, PreviewImportConfig } from '../types';
import { useWatchWithDefaults } from './use-watch-with-defaults';

export function usePreviewConfig(
  uploadId: string,
  formMethods: UseFormReturn<ImportSetupForm>
): PreviewImportConfig {
  const columns = useWatchWithDefaults(formMethods, 'columns');
  const csvOptions = useWatchWithDefaults(formMethods, 'csvOptions');

  return useMemo(
    () => ({
      columns: columns.map((col, colIdx) => ({
        ...col,
        // Keep key and title predictable because they don't affect parsing in
        // backend at this phase, but would cause re-fetch for no reason
        key: `placeholder${colIdx}`,
        title: `placeholder-${colIdx}`,
      })),
      uploadId,
      options: csvOptions,
    }),
    [uploadId, columns, csvOptions]
  );
}
