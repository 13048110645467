import { ReactElement } from 'react';
import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';

import { AddTemplateButton } from './AddTemplateButton';
import { TemplateItem } from './TemplateItem';
import { Template } from './types';
import { useTemplates } from './use-templates';

export const DocumentTemplates = (): ReactElement => {
  const { data: templates } = useTemplates();
  if (!templates) {
    return <LoadingPage />;
  }

  return <DocumentTemplatesContent templates={templates} />;
};

interface ContentProps {
  templates: Template[];
}

const DocumentTemplatesContent = ({
  templates,
}: ContentProps): ReactElement => {
  const [modifiedTemplates, modify] = useSettingsCrud<Template>({
    parent: '#settings-form',
    inputName: 'sales[templates]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: templates,
  });

  return (
    <>
      <VStack w="full" pr={0}>
        {modifiedTemplates.map((item) => (
          <TemplateItem key={item.ID} template={item} modify={modify} />
        ))}
      </VStack>
      <Flex w="full" justify="end">
        <AddTemplateButton modify={modify} />
      </Flex>
    </>
  );
};
