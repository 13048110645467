import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
} from '@chakra-ui/react';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { ReactElement } from 'react';
import { useController, useFieldArray, useFormContext } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { EmailRow } from './types';

const emailAddressRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

interface FormWithEmails {
  emails: EmailRow[];
}

interface EmailListRowProps {
  idx: number;
  onRemove: () => void;
}

const EmailListRow = ({ idx, onRemove }: EmailListRowProps): ReactElement => {
  const { t } = useTranslation('Phonebook');

  const {
    formState: { errors },
    control,
  } = useFormContext<FormWithEmails>();

  const {
    field: { value, onChange },
  } = useController<FormWithEmails, `emails.${number}.email`>({
    name: `emails.${idx}.email`,
    control,
    rules: { pattern: emailAddressRegex },
  });

  const mailErrors = errors?.emails?.find
    ? errors.emails.find((_row, errIdx) => errIdx === idx)
    : undefined;
  const isInvalid = !!mailErrors?.email;

  return (
    <HStack w="full" mb="0.5" py="3">
      <Input
        isInvalid={isInvalid}
        value={value}
        placeholder={t('Phonebook:email_address')}
        onChange={(e) => {
          onChange(e.target.value.trim());
        }}
      />
      <DeleteButton onClick={onRemove} />
    </HStack>
  );
};

export const FormMails = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control } = useFormContext<FormWithEmails>();
  const { fields, append, remove } = useFieldArray({ control, name: 'emails' });
  const addRow = () => append({ email: '' });

  return (
    <FormControl>
      <FormLabel fontWeight="bold">{t('Phonebook:email_addresses')}:</FormLabel>

      {fields.map((field, idx) => (
        <EmailListRow key={field.id} idx={idx} onRemove={() => remove(idx)} />
      ))}

      <Flex justifyContent="flex-end">
        <Button
          colorScheme="green"
          bg="#34CB7F"
          onClick={addRow}
          verticalAlign="middle"
          rightIcon={<FaPlus />}
        >
          {t('global:add')}
        </Button>
      </Flex>
    </FormControl>
  );
};
