import { popoverAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';
import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const $arrowBg = cssVar('popper-arrow-bg');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);
const baseStyle = (brandColors: BrandColors) => {
  return definePartsStyle({
    content: {
      color: brandColors.fontStaticDark,
      bg: brandColors.background,
      [$arrowBg.variable]: brandColors.background,
      _dark: {
        bg: brandColors.backgroundDark,
        color: brandColors.fontStaticLight,
        [$arrowBg.variable]: brandColors.backgroundDark,
      },
    },
  });
};
export const popoverTheme = (brandColors: BrandColors) => {
  return defineMultiStyleConfig({ baseStyle: baseStyle(brandColors) });
};
