import { ReactElement, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileAlt } from 'react-icons/fa';

import { useModal } from '@bq/components/UseModal';

import { FormEditModal } from './FormEditModal';

export const FormEdit = (): ReactElement => {
  const { t } = useTranslation('Telephony');

  const { addModal } = useModal();
  const editForm = useCallback(() => {
    addModal({
      children: <FormEditModal />,
      componentProps: {
        modal: {
          size: '6xl',
          scrollBehavior: 'inside',
          closeOnOverlayClick: false,
        },
      },
    });
  }, [addModal]);

  return (
    <Button onClick={editForm}>
      <FaFileAlt />
      &nbsp;
      {t('Telephony:edit_form')}
    </Button>
  );
};
