import { ReactElement, useCallback, useMemo } from 'react';
import { Input, Td, Text, Tr } from '@chakra-ui/react';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DeleteButton } from '@bq/components/DeleteButton';

import { ColumnTypeSelect, useColumnTypes } from '../../ColumnTypeSelect';
import { useDatasetFormContext } from '../form';
import { IDatasetFormColumn } from '../schema';
import { useFieldArrayContext } from './field-array-context';

interface RowProps {
  field: IDatasetFormColumn & { index: number };
}

export const DatasetColumnRow = ({
  field: { index, key },
}: RowProps): ReactElement => {
  const { t } = useTranslation('Datasets');
  const { update, remove } = useFieldArrayContext();
  const { register, control, getValues } = useDatasetFormContext();
  const typeController = useController({
    control,
    name: `columns.${index}.type`,
  });
  const typeName = useColumnTypeName(typeController.field.value);

  const isNew = !key;
  const handleRemove = useCallback(() => {
    if (isNew) {
      remove(index);
    } else {
      // Soft-delete existing items
      update(index, { ...getValues(`columns.${index}`), deleted: true });
    }
  }, [index, isNew, getValues, update, remove]);

  return (
    <Tr>
      <Td py="2">
        <Input
          size="sm"
          placeholder={t('global:title')}
          {...register(`columns.${index}.title`)}
        />
      </Td>
      <Td py="2" px={isNew ? '2' : undefined}>
        {isNew ? (
          <ColumnTypeSelect
            size="sm"
            value={typeController.field.value}
            onChange={typeController.field.onChange}
          />
        ) : (
          <Text as="span">{typeName}</Text>
        )}
      </Td>
      <Td py={0}>
        <DeleteButton size="sm" onClick={handleRemove} />
      </Td>
    </Tr>
  );
};

function useColumnTypeName(typeKey: string): string {
  const types = useColumnTypes();

  return useMemo(() => {
    const type = types.find((t) => t.value === typeKey);

    return type?.label ?? typeKey;
  }, [types, typeKey]);
}
