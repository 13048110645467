import { ReactElement, useCallback, useMemo } from 'react';
import { DefaultValues, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Breadcrumb, Breadcrumbs } from '@bq/components/Breadcrumbs';
import { Card, CardBody, CardFooter, CardHeader } from '@bq/components/Card';
import { useFormState } from '@bq/components/form-state';
import { FormActions } from '@bq/components/FormActions';
import {
  FieldValue,
  GeneratedForm,
  loadValues,
} from '@bq/components/FormEditor';

import { Dataset, DatasetEntry } from '../types';
import { useCardBodyProps } from '../use-card-body-props';
import { useEntryForm } from './form';
import { IEntryForm } from './schema';
import { useDatasetEntry, useUpdateEntry } from './use-dataset-entry';

interface Props {
  dataset: Dataset;
  entry: DatasetEntry;
  formDefinition: FieldValue[];
}

export const DatasetEntryEditForm = ({
  dataset,
  entry,
  formDefinition,
}: Props): ReactElement => {
  const { datasetID, ID: entryID } = entry;
  const { t } = useTranslation('Datasets');
  const { refetch } = useDatasetEntry(datasetID, entryID);
  const [formState, setFormState] = useFormState();
  const { mutateAsync: updateEntry } = useUpdateEntry();
  const formMethods = useEntryForm({
    defaultValues: useDefaults(formDefinition, entry),
  });
  const {
    reset,
    formState: { isDirty },
  } = formMethods;

  const submit = useCallback(
    async (data: IEntryForm) => {
      setFormState('saving');
      await updateEntry({ ...data, datasetID, entryID });

      const { data: updated } = await refetch();
      if (!updated) {
        throw new Error('No data after refetching dataset');
      }
      reset(makeDefaults(formDefinition, updated));

      setFormState('saved');
    },
    [
      datasetID,
      entryID,
      reset,
      refetch,
      updateEntry,
      setFormState,
      formDefinition,
    ]
  );

  return (
    <form
      data-form-dirty={isDirty}
      data-ignore-form-save
      onSubmit={formMethods.handleSubmit(submit)}
    >
      <FormProvider {...formMethods}>
        <Card>
          <CardHeader>
            <Breadcrumbs>
              <Breadcrumb to="/datasets/list">
                {t('Datasets:datasets')}
              </Breadcrumb>
              <Breadcrumb to={`/datasets/dataset/${dataset.ID}/entries`}>
                {dataset.name}
              </Breadcrumb>
              <Breadcrumb
                to={`/datasets/dataset/${dataset.ID}/entries/${entry.ID}`}
              >
                {entry.displayName}
              </Breadcrumb>
            </Breadcrumbs>
          </CardHeader>
          <CardBody {...useCardBodyProps()}>
            <GeneratedForm fields={formDefinition} />
          </CardBody>
          <CardFooter>
            <FormActions state={formState} cancelLink="/datasets/list" />
          </CardFooter>
        </Card>
      </FormProvider>
    </form>
  );
};

export function useDefaults(
  formDefinition: FieldValue[],
  entry: DatasetEntry
): DefaultValues<IEntryForm> {
  return useMemo(
    () => makeDefaults(formDefinition, entry),
    [formDefinition, entry]
  );
}

function makeDefaults(
  formDefinition: FieldValue[],
  entry: DatasetEntry
): IEntryForm {
  return {
    data: loadValues(formDefinition, entry.data),
  };
}
