import { ReactElement } from 'react';
import { FormControl, Input } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';

import { FieldValue } from '../types';
import { FieldLabel } from './FieldLabel';

export const DefaultFieldComponent = ({
  id,
  settings,
  type,
}: FieldValue): ReactElement => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const ourError = get(errors, id)?.type;

  return (
    <FormControl isInvalid={!!ourError}>
      <FieldLabel {...settings} />
      <Input type={type} {...register(id, { required: settings.required })} />
    </FormControl>
  );
};
