import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';

import { OverviewMode } from './types';

export interface IOverviewsContext {
  mode: OverviewMode;
  selectedOverview?: string | null;
  childlessFirst: boolean;
  getQueryParams?: () => URLSearchParams | null
}

const OverviewsContext = createContext<IOverviewsContext | null>(null);

export const useOverviewsContext = (): IOverviewsContext => {
  const context = useContext(OverviewsContext);
  if (!context) {
    throw new Error('Missing context');
  }

  return context;
};

type ProviderProps = PropsWithChildren<IOverviewsContext>;

export const OverviewsProvider = ({
  mode,
  selectedOverview,
  childlessFirst,
  getQueryParams,
  children,
}: ProviderProps): ReactElement => {
  const contextValue = useMemo(
    () => ({ mode, selectedOverview, childlessFirst, getQueryParams }),
    [mode, selectedOverview, childlessFirst, getQueryParams]
  );

  return (
    <OverviewsContext.Provider value={contextValue}>
      {children}
    </OverviewsContext.Provider>
  );
};
