import { createContext, PropsWithChildren, useMemo } from 'react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { makeUseContext } from '@bq/components/make-use-context';

import { LocalCompany } from './DepartmentTreeView/types';
import { useLocalCompany } from './DepartmentTreeView/use-local-company';

export interface IPhonebookContext {
  localCompany: LocalCompany | null;
}

export const PhonebookContext = createContext<IPhonebookContext | null>(null);

export const usePhonebookContext = makeUseContext({ PhonebookContext });

export const PhonebookProvider = ({ children }: PropsWithChildren) => {
  const { data: localCompany, isLoading } = useLocalCompany();
  const contextValue = useMemo(
    () => ({ localCompany: localCompany ?? null }),
    [localCompany]
  );

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <PhonebookContext.Provider value={contextValue}>
      {children}
    </PhonebookContext.Provider>
  );
};
