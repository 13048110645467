const globalLocaleRequires = require.context('!../../build/clickvox-locale-loader.js!app/Locales/', true, /[^/]+\.json$/);
const moduleLocaleRequires = require.context('!../../build/clickvox-locale-loader.js!app/Modules/', true, /\.\/[^/]+\/Locales\/.+\.json$/);

const locales = {};
moduleLocaleRequires.keys().forEach((path) => {
  // Path:  ./{Module}/Locales/{locale}.json
  const pathParts = path.split('/');
  const moduleName = pathParts[1];
  const localeName = pathParts[3].replace(/\.json$/, '');

  if (!locales[localeName]) {
    locales[localeName] = {};
  }
  locales[localeName][moduleName] = moduleLocaleRequires(path);
});

globalLocaleRequires.keys().forEach((path) => {
  // Path: ./{locale}.json
  const pathParts = path.split('/');
  const localeName = pathParts[1].replace(/\.json$/, '');

  if (!locales[localeName]) {
    locales[localeName] = {};
  }
  locales[localeName].global = globalLocaleRequires(path);
});

export default locales;
