import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import { getDatasetEntry, updateDatasetEntry } from '../Api/dataset-entries';
import { DatasetEntryListItem } from '../DatasetEntriesList/schema';
import { UpdateDatasetEntry } from '../types';

export function useDatasetEntry(
  datasetID: number,
  entryID: number
): UseQueryResult<DatasetEntryListItem> {
  return useQuery({
    queryKey: ['datasetEntry', datasetID, entryID],
    queryFn: () => getDatasetEntry(datasetID, entryID),
  });
}

export interface UpdateEntryParams extends UpdateDatasetEntry {
  datasetID: number;
  entryID: number;
}
export type UpdateEntryMutation = UseMutationResult<
  void,
  unknown,
  UpdateEntryParams,
  unknown
>;
export function useUpdateEntry(): UpdateEntryMutation {
  return useMutation({
    mutationKey: ['Datasets.updateEntry'],
    mutationFn: ({ datasetID, entryID, ...formData }: UpdateEntryParams) => {
      return updateDatasetEntry(datasetID, entryID, formData);
    },
  });
}
