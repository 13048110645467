import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useUniqueID } from '@bq/components/use-unique-id';

import { Campaign } from '../../schemas';
import { FormCampaign } from '../../schemas/Campaign';
import {
  CampaignFormContext as CampaignFormContextI,
  CampaignFormWrapperProps,
  UseCampaignFormProviderReturn,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CampaignFormContext = createContext<CampaignFormContextI | null>(null);

export const CampaignFormWrapper = ({
  children,
  mode,
  data,
  campaignID,
}: PropsWithChildren<CampaignFormWrapperProps>) => {
  const [saveID, resetSaveID] = useUniqueID();

  const methods = useForm<Campaign>({
    defaultValues: mode === 'edit' ? data : {},
  });
  const [formState, setFormState] = useFormState();

  return (
    <CampaignFormContext.Provider
      value={{
        formState,
        setFormState,
        mode,
        saveID,
        resetSaveID,
        campaignID,
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </CampaignFormContext.Provider>
  );
};

export const useCampaignFormProvider = (): UseCampaignFormProviderReturn => {
  const methods = useFormContext<FormCampaign>();
  const ctx = useContext(CampaignFormContext);
  if (!ctx) {
    throw Error('Missing CampaignFormContext');
  }

  return useMemo(() => ({ methods, ctx }), [ctx, methods]);
};
