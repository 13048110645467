import { HTMLChakraProps, ThemingProps } from '@chakra-ui/react';

export type ButtonStyle = HTMLChakraProps<'button'> & ThemingProps<'button'>;

export const dropdownTagStyle: ButtonStyle = {
  marginRight: '1px',
  size: 'sm',
  borderRadius: 0,
  flexShrink: 0,
};

export const dropdownSelectStyle: ButtonStyle = {
  variant: 'outline',
};
