import { useCallback, useMemo } from 'react';
import { Select, Size } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { modalSelect, selectStyles } from '@bq/components/Select/select-styles';
import { SelectOption } from 'BootQuery/Assets/js/globalTypes';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  size?: Size;
}

export const ColumnTypeSelect = ({ value, onChange, size }: Props) => {
  const types = useColumnTypes();

  const fullValue = types.find((t) => t.value === value) ?? types[0];
  const handleChange = useCallback(
    (value: SelectOption | null) => {
      if (!value) {
        console.warn('Tried to select nothing');

        return;
      }

      onChange?.(value.value);
    },
    [onChange]
  );

  return (
    <Select
      size={size}
      value={fullValue}
      onChange={handleChange}
      options={types}
      chakraStyles={selectStyles}
      styles={modalSelect}
      menuPortalTarget={document.body}
    />
  );
};

export function useColumnTypes(): SelectOption[] {
  const { t } = useTranslation('Datasets');

  return useMemo(() => {
    return [
      { value: 'text', label: t('global:datatype.text') },
      { value: 'number', label: t('global:datatype.number') },
      { value: 'phone', label: t('global:datatype.phone_number') },
      { value: 'bool', label: t('global:datatype.bool') },
      { value: 'email', label: t('global:datatype.email') },
      { value: 'date', label: t('global:datatype.date') },
      { value: 'datetime', label: t('global:datatype.datetime') },
    ];
  }, [t]);
}
