import { useCallback } from 'react';
import { useCompanyDepartmentModal } from './CompanyDepartmentModal/useCompanyDepartment';
import { useCompanyLocationModal } from './CompanyLocationModal/useCompanyLocationModal';
import { useCompanyModal } from './CompanyModal/useCompanyModal';
import { usePersonModal } from './PersonModal/usePersonModal';
import { UseContactCreateModalProps } from './types';

export const useContactCreateModal = () => {
  const { createCompanyModal } = useCompanyModal();
  const { createPersonModal } = usePersonModal();
  const { createCompanyDepartmentModal } = useCompanyDepartmentModal();
  const { createCompanyLocationModal } = useCompanyLocationModal();

  return useCallback(
    (props: UseContactCreateModalProps) => {
      switch (props.type) {
        case 'company':
          return createCompanyModal(props.closeCallback, props.defaults);
        case 'person':
          return createPersonModal(props.closeCallback, props.defaults);
        case 'companyDepartment':
          return createCompanyDepartmentModal(
            props.closeCallback,
            props.defaults
          );
        case 'companyLocation':
          return createCompanyLocationModal(
            props.closeCallback,
            props.defaults
          );
        default:
          throw Error('Uknown Contact Type');
      }
    },
    [
      createCompanyDepartmentModal,
      createCompanyLocationModal,
      createCompanyModal,
      createPersonModal,
    ]
  );
};
