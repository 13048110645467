import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';

export const ticketFilterId = makeTextFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      if (/^\d+$/.test(value.trim())) {
        return ['ID', 'eq', parseInt(value.trim(), 10)];
      }
    }

    return null;
  },
  name: '#',
  operators: [],
});
