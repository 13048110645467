import { mapValues } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { SelectFieldSettingsContext } from './SelectFieldSettingsContext';
import {
  ChangesBySelect,
  ItemsBySelect,
  ItemChanges,
  SelectItem,
  SelectEditorSettings,
} from './types';

export function applyItemChanges(
  items: SelectItem[],
  changes: ItemChanges
): SelectItem[] {
  const removedAndUpdated = items
    .filter((item) => !changes.del.includes(item.key)) // Remove deleted items
    .map((item) => {
      // Use updated items if they exist
      const upd = changes.upd[item.key];
      if (upd) {
        return { ...upd, key: item.key };
      }

      return item;
    });

  return [...removedAndUpdated, ...changes.add];
}

export function applyAllItemChanges(
  items: ItemsBySelect,
  changes: ChangesBySelect
): ItemsBySelect {
  return mapValues(
    changes,
    (selectChanges, selectId) => applyItemChanges(items[selectId] ?? [], selectChanges)
  );
}

export function groupChangesBySelect(selects: SelectEditorSettings[]): ChangesBySelect {
  return selects.reduce(
    (acc: ChangesBySelect, select) => ({
      ...acc,
      [select.id]: select.itemChanges,
    }),
    {}
  );
}

export function useAllItemsWithChanges(): ItemsBySelect {
  const {
    items,
    settings: { selects },
  } = useContext(SelectFieldSettingsContext);

  return useMemo(() => {
    const changes = groupChangesBySelect(selects);

    const res = applyAllItemChanges(items ?? {}, changes);

    return res;
  }, [items, selects]);
}
