module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "	<span class=\"fas fa-users\"></span>\n	<strong>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"conferenceName") : depth0), depth0))
    + "</strong>\n	("
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"conferenceMembers") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":4,"column":90}}})) != null ? stack1 : "")
    + ")\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"format_number").call(alias1,depth0,{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":51}}}))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":51},"end":{"line":4,"column":81}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    return ", ";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"queue") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":1},"end":{"line":9,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"contacts") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":47,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"queue") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":1},"end":{"line":50,"column":8}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		<span class=\"fa fa-sitemap\"></span>&nbsp;"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"queue") : depth0), depth0))
    + "\n		"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answered") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":2},"end":{"line":8,"column":26}}})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "(";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"contacts") : stack1),{"name":"each","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":2},"end":{"line":32,"column":11}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<span>\n				<div class=\"popover-content-element\" hidden>\n					<div class=\"text-center\">\n						<span class=\"fa fa-lg fa-spinner fa-spin\"></span>\n					</div>\n				</div>\n				<a href=\"#\" class=\"contact-number-link noparse\" data-toggle=\"popover\" data-container=\"body\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\" data-person-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"personID") : depth0), depth0))
    + "\" data-company-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"companyID") : depth0), depth0))
    + "\" data-company-location-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"companyLocationID") : depth0), depth0))
    + "\" data-user-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"userID") : depth0), depth0))
    + "\" data-phone-number=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"context") : depths[1])) != null ? lookupProperty(stack1,"phoneNumber") : stack1), depth0))
    + "\" data-number-e164=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"context") : depths[1])) != null ? lookupProperty(stack1,"phoneNumber") : stack1), depth0))
    + "\" title=\"&lt;span class=&quot;fas fa-phone&quot;&gt;&lt;/span&gt; "
    + alias2(lookupProperty(helpers,"format_number").call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"context") : depths[1])) != null ? lookupProperty(stack1,"phoneNumber") : stack1),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":18,"column":464},"end":{"line":18,"column":504}}}))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isUser") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":5},"end":{"line":27,"column":12}}})) != null ? stack1 : "")
    + "					"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\n				</a>\n			</span>\n			"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":3},"end":{"line":31,"column":36}}})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fas fa-user\"></span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isPerson") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data,"loc":{"start":{"line":21,"column":5},"end":{"line":27,"column":5}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fas fa-briefcase\"></span>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCompany") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":23,"column":5},"end":{"line":27,"column":5}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fas fa-building\"></span>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCompanyLocation") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":5},"end":{"line":27,"column":5}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    return "						<span class=\"fas fa-building\"></span>\n					";
},"22":function(container,depth0,helpers,partials,data) {
    return " ili ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"phoneNumber") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":2},"end":{"line":46,"column":9}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<span>\n				<div class=\"popover-content-element\" hidden>\n					<div class=\"text-center\">\n						<span class=\"fa fa-lg fa-spinner fa-spin\"></span>\n					</div>\n				</div>\n				<a href=\"#\" class=\"number-link noparse\"  data-toggle=\"popover\" data-container=\"body\" data-trigger=\"click\" data-popover-content-element=\"true\" data-html=\"true\" data-phone-number=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"phoneNumber") : stack1), depth0))
    + "\" data-number-e164=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"phoneNumber") : stack1), depth0))
    + "\" title=\"&lt;span class=&quot;fas fa-phone&quot;&gt;&lt;/span&gt; "
    + alias2(lookupProperty(helpers,"format_number").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"phoneNumber") : stack1),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":41,"column":314},"end":{"line":41,"column":351}}}))
    + "\">\n					<span class=\"fas fa-phone\"></span>\n					"
    + alias2(lookupProperty(helpers,"format_number").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"context") : depth0)) != null ? lookupProperty(stack1,"phoneNumber") : stack1),{"name":"format_number","hash":{},"data":data,"loc":{"start":{"line":43,"column":5},"end":{"line":43,"column":42}}}))
    + "\n				</a>\n			</span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "		"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answered") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":49,"column":26}}})) != null ? stack1 : "")
    + "\n";
},"28":function(container,depth0,helpers,partials,data) {
    return ")";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"conferenceName") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":51,"column":7}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true})