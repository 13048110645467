import { extendTheme } from '@chakra-ui/react';

import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

import { componentsConfig } from './ComponentsConfig';

export const genTheme = (brandColors: BrandColors) => {
  return extendTheme({
    colors: {
      brand: brandColors,
    },
    sizes: {
      fullNoNavbar: 'calc(var(--chakra-vh) - var(--navbar-height))',
      fullModalHeight:
        'calc(var(--chakra-vh) - var(--chakra-space-32) - 128px - var(--navbar-height))',
    },
    fonts: {
      heading: '"Roboto", sans-serif',
      body: '"Roboto", sans-serif',
      brand: '"Roboto", sans-serif',
    },
    components: componentsConfig(brandColors),
  });
};
