import { chakra, SimpleGrid } from '@chakra-ui/react';
import { createContext, useCallback, useContext, useState } from 'react';
import { Primitive, RadioContextProps, RadioProviderProps } from './types';

const RadioCtx = createContext<RadioContextProps | null>(null);

export const RadioGroup = (props: RadioProviderProps) => {
  const { defaultVal, onChange, children, ...styleProps } = props;
  const [value, setValue] = useState<Primitive>(defaultVal);
  const [allValues, setAllValues] = useState<Primitive[]>([]);
  const setValueAndTriggerCallback = useCallback(
    (newVal: Primitive) => {
      setValue(newVal);
      onChange(newVal);
    },
    [onChange]
  );
  const registerSelf = useCallback((newValue: Primitive) => {
    setAllValues((prev) => [
      ...prev.filter((val) => val !== newValue),
      newValue,
    ]);
  }, []);
  const isSelected = useCallback(
    (valueF: string | number | boolean) => valueF === value,
    [value]
  );

  return (
    <RadioCtx.Provider
      value={{
        setValue: setValueAndTriggerCallback,
        value,
        registerSelf,
        allValues,
        isSelected,
      }}
    >
      <RadioContainer {...styleProps} columns={allValues.length ?? 0}>
        {children}
      </RadioContainer>
    </RadioCtx.Provider>
  );
};

export const useRadio = () => {
  const ctx = useContext<RadioContextProps | null>(RadioCtx);
  if (ctx === null) {
    throw Error('Trying to use RadioItem without Radio');
  }

  return ctx;
};

export const RadioContainer = chakra(SimpleGrid);
