import { ReactElement, useCallback, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';

import { CaseSelect } from './CaseSelect';
import { CaseBaseInfo, CasePickerModalProps } from './types';

export const CasePickerModal = ({
  isOpen,
  onSubmit,
  onCancel,
  options,
  formState,
}: CasePickerModalProps): ReactElement => {
  const { t } = useTranslation('Sales');

  const [selected, setSelected] = useState<CaseBaseInfo | null>(null);

  const handleSubmit = useCallback(() => {
    if (!selected) {
      throw new Error('Tried to submit Case picker without selected Case');
    }

    setSelected(null); // Clear selection on close
    onSubmit(selected, false);
  }, [onSubmit, selected]);

  const handleCancel = useCallback(() => {
    setSelected(null); // Clear selection before closing
    onCancel();
  }, [onCancel]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaListAlt style={{ display: 'inline' }} />
          &nbsp;
          {options?.title ? options.title : t('Caseing:pick_Case')}
        </ModalHeader>
        <ModalBody>
          <CaseSelect
            value={selected}
            onChange={setSelected}
            excludedCaseIds={options?.notCaseId}
          />
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            state={formState}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
