import { getPersonFormData } from 'app/Modules/Phonebook/Assets/components/PersonForm';
import { useQuery } from '@tanstack/react-query';
import { LoadingModal } from '@bq/components/UseModal/LoadingModal';
import { PersonModalContent } from './PersonModalContent';

export const EditPersonModalContent = ({ ID }: { ID: number }) => {
  const { data } = useQuery({
    queryKey: ['EditPersonModal', ID],
    queryFn: () => {
      return getPersonFormData(ID.toString());
    },
  });

  if (!data) {
    return <LoadingModal />;
  }

  return <PersonModalContent mode="edit" data={data} personID={ID} />;
};
