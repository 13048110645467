import { ButtonRadio } from 'BootQuery/Assets/components/ButtonRadio';
import { ReactElement, useCallback } from 'react';
import { DisplayMode, DisplayModeOption } from './types';

interface Props {
  options: DisplayModeOption[];
  value: DisplayMode;
  onChange: (value: DisplayMode) => void;
  colorScheme?: string;
}

export const DisplayModeToggle = ({
  options,
  value,
  onChange,
}: Props): ReactElement => {
  const handleChange = useCallback(
    (newVal: string) => {
      const found = options.find((item) => item.id === newVal);
      if (!found) {
        console.warn('unkown option');

        return;
      }
      onChange(found.id);
    },
    [onChange, options]
  );

  return (
    <ButtonRadio
      name="displayMode"
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
};
