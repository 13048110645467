module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":66}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "CC: "
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":23},"end":{"line":9,"column":94}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"currentText") : depth0), depth0)) != null ? stack1 : "")
    + "\n\n\n-------- Forwarded Message --------\nSubject: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "\nDate: "
    + alias2(lookupProperty(helpers,"format_date").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),lookupProperty(helpers,"tr").call(alias3,"format.datetime_s",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":58}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":61}}}))
    + "\nFrom: "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"fromMailAddress") : stack1), depth0))
    + "\nTo: "
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"to") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":75}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":9,"column":101}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"previousText") : depth0), depth0)) != null ? stack1 : "");
},"useData":true})