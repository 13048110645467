module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"tablerow"),depth0,{"name":"tablerow","hash":{"id_col":(depths[1] != null ? lookupProperty(depths[1],"id_col") : depths[1]),"dataAttributes":(depths[1] != null ? lookupProperty(depths[1],"rowDataAttributes") : depths[1]),"hasRowActions":(depths[1] != null ? lookupProperty(depths[1],"hasRowActions") : depths[1]),"rowCheckbox":(depths[1] != null ? lookupProperty(depths[1],"rowCheckbox") : depths[1]),"rowActions":(depths[1] != null ? lookupProperty(depths[1],"rowActions") : depths[1]),"tableName":(depths[1] != null ? lookupProperty(depths[1],"tableName") : depths[1]),"columns":(depths[1] != null ? lookupProperty(depths[1],"columns") : depths[1])},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr>\n        <td colspan=\""
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"columnCount") : depth0), depth0))
    + "\">\n            "
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.no_result",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":36}}}))
    + "\n        </td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"result") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":10,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true})