import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ModalContent } from '@chakra-ui/react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useModalInstance } from '@bq/components/UseModal/ModalInstanceContext';
import { uniqid } from 'app/assets/js/tsutil';

import { CaseInterestLevel, CasePriority } from '../../schemas';
import { IInterestLevelFormCtx, InterestLevelFormData } from './types';

const InterestLevelFormCtx = createContext<IInterestLevelFormCtx | null>(null);

export const InterestLevelFormWrapper = (
  props: PropsWithChildren<InterestLevelFormData>
) => {
  const methods = useForm<WithChange<CaseInterestLevel>>({
    defaultValues: makeDefaults(props),
  });

  const {
    preventClose: { setState },
  } = useModalInstance();
  const isDirty = useMemo(
    () => methods.formState.isDirty,
    [methods.formState.isDirty]
  );
  const [formState, setFormState] = useFormState();
  useEffect(() => {
    setState(isDirty);
  }, [isDirty, setState]);

  const { handleSubmit } = methods;
  const { closeWithCallback } = useModalInstance();

  return (
    <InterestLevelFormCtx.Provider
      value={{ formState, setFormState, mode: props.mode }}
    >
      <FormProvider {...methods}>
        <ModalContent
          as="form"
          onSubmit={handleSubmit(
            (data) => {
              if (props.mode === 'create') {
                closeWithCallback<CaseInterestLevel>(
                  { ...data, ID: data.ID ?? uniqid() },
                  true
                );
              }
              if (props.mode === 'edit') {
                closeWithCallback<CaseInterestLevel>(data, true);
              }
              setFormState('saved');
            },
            (a) => {
              console.log('error', a);
              /** Set form state ERROR */
              setFormState('error');
            }
          )}
        >
          {props.children}
        </ModalContent>
      </FormProvider>
    </InterestLevelFormCtx.Provider>
  );
};

export const useInterestLevelFormWrapper = () => {
  const ctx = useContext(InterestLevelFormCtx);
  if (!ctx) {
    throw Error('Missing Interest Level Form Wrapper');
  }

  return ctx;
};

function makeDefaults(
  interestLevel: InterestLevelFormData
): DeepPartial<WithChange<CaseInterestLevel>> {
  if (interestLevel.mode === 'edit') {
    return interestLevel.data as DeepPartial<CasePriority>;
  }

  return {};
}
