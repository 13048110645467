import { create as zustand } from 'zustand';

import { Api } from 'BootQuery/Assets/js/api';
import reusePromise from 'BootQuery/Assets/js/reuse-promise';
import SocketEventListener from 'BootQuery/Assets/js/socket-event-listener';

import { getCallCurrentState } from '../../../store/calls-zustand';
import { Call, Queue } from '../../../types/call';
import { Device, DeviceStatusEvent } from '../../../types/device';
import {
  PauseEvent,
  QueueMemberEvent,
  User,
  UserQueuePause,
} from '../../../types/user';
import { getPauseTypes } from '../../Api/pause-types';
import { maybeOpenCallForm } from '../auto-call-form';
import {
  CallAndConnection,
  getCallConnectedToDevice,
} from '../connected-calls';
import {
  CallResponse,
  CompulsoryCallFormPreferences,
  OriginateResponse,
} from '../types';
import { softphoneState } from './softphone';

interface UserInfo {
  user: User;
  operatorEnabled: boolean;
  switchToSpeakerAfterHangup: boolean;
}

async function getOwnUser(): Promise<UserInfo | null> {
  const { data } = await Api.get<UserInfo | null>('/api/telephony/me');

  return data;
}

async function getAvailableQueues(): Promise<Queue[]> {
  const { data } = await Api.get<Queue[]>('/api/pbxState/queues', {
    params: { fields: ['name'] },
  });

  return data;
}

async function getCompulsoryCallFormPreferences(): Promise<CompulsoryCallFormPreferences> {
  const { data } = await Api.get<CompulsoryCallFormPreferences>(
    '/api/telephony/compulsoryCallForm'
  );

  return data;
}

interface DeviceCalls extends CallAndConnection {
  deviceId: string;
}

interface RedirectParams {
  dropRingingCalls?: boolean;
  autoAnswerAfter?: number | null;
}

interface CallOpts {
  deviceId?: string | null;
  waitReady?: boolean;
}

interface DeviceCallId {
  callId: string;
  deviceId?: string;
}

interface DialerState {
  user: User | null;
  availableQueues: Queue[];
  pauseTypes: string[];
  callFormPreferences: CompulsoryCallFormPreferences | null;
  loaded: boolean;
  init: () => Promise<void>;
  currentDevice: Device | null;
  setCurrentDevice: (dev: Device | null) => void;
  calls: DeviceCalls[];
  call: (phoneNumber: string, options?: CallOpts) => Promise<CallResponse>;
  pickup: (callId: string) => Promise<void>;
  redirectRinging: (callId: string, params?: RedirectParams) => Promise<void>;
  transfer: (
    callId: string,
    number: string,
    callLeg: 'a' | 'b'
  ) => Promise<void>;
  spy: (userNumber: string) => Promise<void>;
  whisper: (userNumber: string) => Promise<void>;
  barge: (userNumber: string) => Promise<void>;
  operatorEnabled: boolean;
}

let eventListener: SocketEventListener | null = null;

export const dialerStore = zustand<DialerState>((set, get) => {
  const setCall = (call: DeviceCalls) => {
    const calls = get().calls.filter(
      (existing) => existing.call.callId !== call.call.callId
    );
    set({
      calls: [...calls, call],
    });
  };

  const matchCall = ({ callId, deviceId }: DeviceCallId, call: DeviceCalls) => {
    const callMatches = call.call.callId === callId;
    const deviceMatches = deviceId ? deviceId === call.deviceId : true;

    return callMatches && deviceMatches;
  };

  const removeCall = ({ callId, deviceId }: DeviceCallId) => {
    set({
      calls: get().calls.filter((call) => {
        const shouldRemove = matchCall({ callId, deviceId }, call);

        return !shouldRemove;
      }),
    });
  };

  const findCall = (match: DeviceCallId): DeviceCalls | null => {
    return get().calls.find((call) => matchCall(match, call)) ?? null;
  };

  const spyAction = async (
    action: 'spy' | 'whisper' | 'barge',
    userNumber: string
  ) => {
    const device = get().currentDevice;
    if (!device) {
      console.error('Unable to redirect ringing call without device');

      return;
    }

    await Api.post(
      `/api/dialer/devices/${device.deviceId}/${action}`,
      userNumber
    );
  };

  const userNum = () => get().user?.phoneNumber.phoneNumberE164;
  const getUser = () => {
    const { user } = get();
    if (!user) {
      throw new Error('User disappeared');
    }

    return user;
  };

  const setupDeviceEventListeners = (devices: Device[]) => {
    if (!eventListener) {
      return;
    }

    const deviceStatusSubscription = devices.map(
      (dev) => `telephony/deviceStatus/${dev.deviceId}`
    );
    eventListener.subscribeWebSocket(deviceStatusSubscription, (ev) => {
      const { deviceId, status } = ev as DeviceStatusEvent;
      const { user, currentDevice } = get();
      if (!user) {
        return;
      }

      const devices = user.devices.map((dev) => {
        if (dev.device.deviceId === deviceId) {
          return {
            device: {
              ...dev.device,
              currentState: status,
            },
          };
        }

        return dev;
      });
      set({ user: { ...user, devices } });

      if (deviceId === currentDevice?.deviceId) {
        set({ currentDevice: { ...currentDevice, currentState: status } });
      }
    });

    eventListener.subscribeWebSocket(
      ['telephony/callStart', 'telephony/callUpdate'],
      (ev) => {
        const callData = ev as Call;
        // console.log('CALL SOMETHING: ', callData);

        devices.forEach((dev) => {
          const connected = getCallConnectedToDevice(
            getCallCurrentState(callData),
            dev.deviceId
          );
          if (connected) {
            const prevCall = findCall({
              callId: callData.callId,
              deviceId: dev.deviceId,
            });
            setCall({ ...connected, deviceId: dev.deviceId });

            const justAnswered =
              connected.call.answered && !prevCall?.call?.answered;
            const isOwnCall = connected.connection === 'destination';
            const { callFormPreferences } = get();

            if (justAnswered && isOwnCall && callFormPreferences) {
              maybeOpenCallForm('answer', connected.call, callFormPreferences);
            }
          } else {
            removeCall({ callId: callData.callId, deviceId: dev.deviceId });
          }
        });
      }
    );

    eventListener.subscribeWebSocket('telephony/callEnd', (ev) => {
      const callEnd = ev as { callId: string };
      const { callId } = callEnd;

      const callInfo = findCall({ callId });
      console.log('CALL END: ', callId, callInfo);

      removeCall({ callId });

      const { callFormPreferences } = get();
      if (callInfo && callFormPreferences) {
        maybeOpenCallForm('hangup', callInfo.call, callFormPreferences);
      }
    });

    eventListener.subscribeWebSocket(
      'telephony/queueLogin',
      (ev: QueueMemberEvent) => {
        const { user } = get();
        if (user?.phoneNumber.phoneNumberE164 !== ev.agent) {
          return;
        }

        set({
          user: {
            ...user,
            queues: [
              ...user.queues,
              {
                queue: { name: ev.queue },
                penalty: 0,
                wrapupTime: 0,
                pause: null,
              },
            ],
          },
        });
      }
    );
    eventListener.subscribeWebSocket(
      'telephony/queueLogout',
      (ev: QueueMemberEvent) => {
        if (userNum() !== ev.agent) {
          return;
        }
        const user = getUser();

        set({
          user: {
            ...user,
            queues: user.queues.filter(
              (queue) => queue.queue.name !== ev.queue
            ),
          },
        });
      }
    );
    eventListener.subscribeWebSocket(
      `telephony/agentPause/${userNum()}`,
      (ev: PauseEvent) => {
        const user = getUser();
        set({
          user: {
            ...user,
            queues: user.queues.map((queue) => ({
              ...queue,
              pause: { name: ev.pause, startAt: new Date() },
            })),
          },
        });
      }
    );
    eventListener.subscribeWebSocket(
      `telephony/agentUnpause/${userNum()}`,
      (ev: PauseEvent) => {
        const user = getUser();
        set({
          user: {
            ...user,
            queues: user.queues.map((queue) => ({ ...queue, pause: null })),
          },
        });
      }
    );
  };

  return {
    init: reusePromise(async () => {
      if (get().loaded) {
        return;
      }

      const [userInfo, availableQueues, pauseTypes, callFormPreferences] =
        await Promise.all([
          getOwnUser(),
          getAvailableQueues(),
          getPauseTypes(),
          getCompulsoryCallFormPreferences(),
        ]);

      const user = userInfo?.user ?? null;
      const operatorEnabled = userInfo?.operatorEnabled ?? false;
      const switchToSpeakerAfterHangup =
        userInfo?.switchToSpeakerAfterHangup ?? false;

      set({ operatorEnabled });
      softphoneState.setState({ switchToSpeakerAfterHangup });

      const storedCurrentDevice = localStorage.getItem('dialerCurrentDeviceId');
      let currentDevice =
        (user?.devices ?? []).find(
          (dev) => dev.device.deviceId === storedCurrentDevice
        )?.device ?? null;
      if (!currentDevice && user && user.devices.length > 0) {
        currentDevice = user.devices[0].device;
      }

      if (!eventListener) {
        eventListener = new SocketEventListener('telephonyCurrentCalls');
      }

      set({ user, availableQueues, pauseTypes, callFormPreferences });
      if (user && user.devices.length > 0) {
        setupDeviceEventListeners(user.devices.map((dev) => dev.device));
      }

      set({ loaded: true });
      get().setCurrentDevice(currentDevice);
    }),
    setCurrentDevice: (currentDevice) => {
      set({ currentDevice });
      localStorage.setItem(
        'dialerCurrentDeviceId',
        currentDevice?.deviceId ?? 'null'
      );
      const protocol = currentDevice?.pbxTransport?.protocol ?? null;
      if (currentDevice && protocol && ['ws', 'wss'].includes(protocol)) {
        softphoneState.setState({ deviceId: currentDevice.deviceId });
        softphoneState.getState().init();
      } else {
        softphoneState.getState().deinit();
      }
    },
    call: async (
      phoneNumber: string,
      { deviceId, waitReady }: CallOpts = {}
    ) => {
      let device = get().currentDevice;
      if (deviceId) {
        device =
          (get().user?.devices ?? [])
            .map((dev) => dev.device)
            .find((dev) => dev.deviceId === deviceId) ?? null;
      }
      if (!device) {
        throw new Error('Unable to dial without device');
      }

      // Return of getState() is not reused on purpose, because the value may
      // change after init()
      const softphoneDeviceId = softphoneState.getState().deviceId;
      if (softphoneDeviceId === device.deviceId) {
        if (waitReady) {
          // Wait for softphone to be initialised.
          // This is mostly necesarry for click2call handler which will attempt dial
          // while everything is still loading
          // init() should wait for existing promise to resolve if called multiple times.
          await softphoneState.getState().init();
        }

        const idInfo = await softphoneState.getState().call(phoneNumber);

        return { type: 'softphone', ...idInfo };
      }

      const { data } = await Api.post<OriginateResponse>(
        `/api/dialer/devices/${device.deviceId}/dial`,
        phoneNumber
      );

      return { type: 'pbx', ...data };
    },
    pickup: async (callId) => {
      const device = get().currentDevice;
      if (!device) {
        console.error('Unable to pickup call without device');

        return;
      }

      await Api.post(`/api/dialer/devices/${device.deviceId}/pickup`, callId);
    },
    redirectRinging: async (callId, params = {}) => {
      const device = get().currentDevice;
      if (!device) {
        console.error('Unable to redirect ringing call without device');

        return;
      }

      await Api.post(`/api/dialer/devices/${device.deviceId}/redirect`, {
        callId,
        ...params,
      });
    },
    transfer: async (callId, destination, callLeg) => {
      await Api.post(`/api/telephony/calls/${callId}/transfer`, {
        callLeg,
        destination,
      });
    },
    spy: (userNumber) => spyAction('spy', userNumber),
    whisper: (userNumber) => spyAction('whisper', userNumber),
    barge: (userNumber) => spyAction('barge', userNumber),
    loaded: false,
    user: null,
    currentDevice: null,
    calls: [],
    availableQueues: [],
    callFormPreferences: null,
    pauseTypes: [],
    operatorEnabled: false,
  };
});

const compareCallStateDates = (
  callA: DeviceCalls,
  callB: DeviceCalls
): number => {
  const callADateStr = callA.call.currentState?.startAt;
  const callBDateStr = callB.call.currentState?.startAt;
  if (!callADateStr) {
    return -1;
  }
  if (!callBDateStr) {
    return 1;
  }
  const callADate = new Date(callADateStr);
  const callBDate = new Date(callBDateStr);

  return callBDate.getTime() - callADate.getTime();
};

export function getCurrentCall(state: DialerState): CallAndConnection | null {
  const calls = state.calls
    .filter((call) => call.deviceId === state.currentDevice?.deviceId)
    .sort(compareCallStateDates);

  return (
    calls.find((call) => call.call.currentState?.callState === 'up') ??
    calls.find((call) => call.call.currentState?.callState === 'dialing') ??
    calls.find((call) => call.call.currentState?.callState === 'ringing') ??
    calls.find((call) => {
      const currentDest = call.call.currentDestination?.destination;
      const state = call.call.currentState?.callState;

      return state === 'routing' && currentDest?.type === 'conference';
    }) ??
    calls[0] ??
    null
  );
}

export function getCurrentPause(state: DialerState): UserQueuePause | null {
  return (
    (state.user?.queues ?? [])
      .map((queue) => queue.pause)
      .find((pause) => pause !== null) ?? null
  );
}
