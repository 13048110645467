import { Flex, Tag } from '@chakra-ui/react';
import {
  TagOptionObject,
  tagTextColor,
  useFullTags,
} from 'BootQuery/Assets/components/TagInput';
import { ReactElement } from 'react';

interface Props {
  availableTags: TagOptionObject[];
  value: string[];
}

export const TagList = ({ value, availableTags }: Props): ReactElement => {
  const tags = useFullTags(value, availableTags);

  return (
    <Flex
      w="full"
      flexDirection="row"
      alignItems="center"
      flexWrap="wrap"
      sx={{ gap: '16px' }}
    >
      {tags.map((tag) => (
        <Tag
          key={tag.value}
          bgColor={tag.color}
          color={tagTextColor(tag.color)}
          fontWeight="bold"
          size="sm"
        >
          {tag.label ?? tag.value}
        </Tag>
      ))}
    </Flex>
  );
};
