import { VStack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import { CallCard } from '../../CallCard';
import { CallListCall } from '../types';

interface Props {
  calls: CallListCall[];
}

export const CardList = ({ calls }: Props): ReactElement => (
  <VStack
    spacing={0}
    alignItems="stretch"
    borderBottom="1px solid" borderTop="1px solid" borderColor="brand.500"
  >
    {calls.map((call) => (
      <CallCard key={call.ID} call={call} verboseStatus={false} />
    ))}
  </VStack>
);
