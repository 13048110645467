import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterMailBody = makeTextFilter({
  name: () => i18n.t('Ticketing:filter.mail_message_content'),
  toFilter: ({ operator, value }) => {
    if (!value) {
      return null;
    }

    return [
      'mailEvents',
      '$any',
      [['message.fulltextSearchVector', operator ?? 'eq', value]],
    ];
  },
  operators: () => [
    { operator: 'contains:ci', display: i18n.t('global:operators.contains') },
    {
      operator: 'contains:not:ci',
      display: i18n.t('global:operators.contains_not'),
    },
  ],
});
