module.exports = {"name":"Benutzer","menu_entries":{"userlist":"Benutzer","logout":"Ausloggen","user":"Benutzer","extensions":"Durchwahlen"},"tab":{"users":"Benutzer","groups":"Gruppen"},"table_header":{"user":"Benutzer","extension":"Durchwahl","groups":"Gruppen","employee":"Angestellter","name":"Name","group_name":"Name"},"label":{"settings":"Einstellungen","display":"Display","user":"Benutzer","local_extension":"[local_extension]","login":"Einloggen","user_managed_at":"[user_managed_at]"},"button":{"login":"Einloggen","login_with":(window.Handlebars["default"] || window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "Mit "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"provider") || (depth0 != null ? lookupProperty(depth0,"provider") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"provider","hash":{},"data":data,"loc":{"start":{"line":1,"column":4},"end":{"line":1,"column":16}}}) : helper)))
    + " anmelden";
},"useData":true}),"change_password":"Kennwort ändern"},"form":{"username":"Benutzername","password":"Passwort","password_confirm":"Passwort wiederholen","current_password":"aktuelles Passwort","recovery_email":"E-Mail zur Wiederherstellung","stayLoggedIn":"Angemeldet bleiben","email":"E-Mail","extension":"Durchwahl","employee":"Angestellter","groups":"Gruppen","group":"Gruppe","name":"Name","language":"Sprache","theme":"Thema","compact_tables":"Kompakte Tabellen","password_recovery":"Passwortwiederherstellung","send_email":"E-Mail senden","reset_password":"Passwort zurücksetzen","user_unavailable":"[user_unavailable]"},"error":{"invalid_email":"Ungültige E-Mail Adresse","incorrect_username_or_password":"[incorrect_username_or_password]"},"filter":{"username":"[username]","extension":"[extension]","group":"[group]"}}