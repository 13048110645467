import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

interface GetProductParams {
  productID: number;
  params?: GetOneParams;
}
export const getProduct = async <T = { ID: number }>({
  productID,
  params,
}: GetProductParams) => {
  const { data } = await Api.get<T>(`/api/products/${productID}`, {
    params,
  });

  return data;
};
