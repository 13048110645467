import { ReactElement, useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';

import { IconButton } from 'BootQuery/Assets/components/IconButton';
import {
  CellProps,
  CellValue,
  Column,
} from 'BootQuery/Assets/components/Table';

import { editCallForm } from '../CallForm';
import { CallListCall } from './types';

type CellComponent = (props: CellProps<CallListCall>) => ReactElement;

export const makeWrappedCallCell = (
  original: Column<CallListCall>
): CellComponent => {
  const WrappedCallCell = ({ row, rowIdx }: CellProps<CallListCall>) => {
    const edit = useCallback(() => {
      editCallForm(row);
    }, [row]);

    return (
      <>
        <CellValue row={row} column={original} rowIdx={rowIdx} />
        <Box
          position="absolute"
          right={0}
          top={0}
          bottom={0}
          display="flex"
          alignItems="center"
        >
          <IconButton
            onClick={edit}
            variant="ghost"
            size="sm"
            label="Edit"
            icon={<FaEdit />}
          />
        </Box>
      </>
    );
  };

  return WrappedCallCell;
};
