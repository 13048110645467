import { ReactElement } from 'react';
import {
  Slider, SliderTrack, SliderFilledTrack, SliderThumb,
} from '@chakra-ui/react';

export interface VolumeSliderProps {
    value: number;
    onChange: (value: number) => void;
    max?: number;
    id?: string;
}

function volumeFromInput(input: number, max: number): number {
  // 0 to 100 => keep normal scale
  if (input <= 100) {
    return input;
  }

  // Over 100 => scale the remaining 50 as 100 to <max>
  return 100 + ((input - 100) / 50) * (max - 100);
}

function inputFromVolume(volume: number, max: number): number {
  // 0 to 100 => keep normal scale
  if (volume <= 100) {
    return volume;
  }

  // Over 100 => scale the remaining 100 to <max> as 100 to 150
  return (50 * (volume + 2 * max - 300)) / (max - 100);
}

export const VolumeSlider = ({
  value, onChange, max = 400, id,
}: VolumeSliderProps): ReactElement => {
  const handleChange = (val: number) => {
    onChange(volumeFromInput(val, max));
  };

  return (
    <Slider
      step={1}
      min={0}
      max={max > 100 ? 150 : 100}
      value={inputFromVolume(value, max)}
      onChange={handleChange}
      id={id}
    >
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <SliderThumb />
    </Slider>
  );
};
