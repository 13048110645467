import { ReactElement, useEffect, useState } from 'react';
import { Spinner } from '@chakra-ui/react';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import {
  Breadcrumb,
  Breadcrumbs,
} from 'BootQuery/Assets/components/Breadcrumbs';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { FormState } from 'BootQuery/Assets/components/form-state';
import { Layout } from 'BootQuery/Assets/components/Layout/Layout';
import { LayoutHeader } from 'BootQuery/Assets/components/Layout/LayoutHeader';
import { LayoutBody } from 'BootQuery/Assets/components/Layout/LayoutBody';
import { LayoutFooter } from 'BootQuery/Assets/components/Layout/LayoutFooter';
import { CompanyLocationFormFields } from './CompanyLocationFormFields';
import { getCompanyInfo } from '../PersonForm/CompanyInput';

interface Props {
  title: string;
  formState: FormState;
  companyID?: string | number;
  locationID?: string | number;
}

export const CompanyLocationForm = ({
  companyID,
  locationID,
  formState,
}: Props): ReactElement => {
  const backLink = !companyID
    ? '/phonebook/company-locations'
    : `/phonebook/companies/${companyID}`;

  return (
    <Layout h='full'>
      <LayoutHeader>
        {companyID ? (
          <WithCompanyIDBreadcrumbs
            companyID={companyID}
            locationID={locationID}
          />
        ) : (
          <NoCompanyIDBreadcrumbs />
        )}
      </LayoutHeader>
      <LayoutBody>
        <CompanyLocationFormFields companyID={companyID} />
      </LayoutBody>
      <LayoutFooter isSticky display="flex" justifyContent="flex-end">
        <FormActions
          cancelLink={backLink}
          state={formState}
        />
      </LayoutFooter>
    </Layout>
  );
};

const NoCompanyIDBreadcrumbs = () => {
  const { t } = useTranslation('Phonebook');

  return (
    <Breadcrumbs>
      <Breadcrumb to="/phonebook">{t('Phonebook:phonebook_title')}</Breadcrumb>
      <Breadcrumb to="/phonebook/company-locations">
        {t('Phonebook:company_locations')}
      </Breadcrumb>
      <Breadcrumb to="/phonebook/company-locations/create">
        {t('global:create')}
      </Breadcrumb>
    </Breadcrumbs>
  );
};
const WithCompanyIDBreadcrumbs = ({
  companyID,
  locationID,
}: {
  companyID: string | number;
  locationID?: string | number;
}) => {
  const { t } = useTranslation('Phonebook');
  const { data: companyData, isLoading } = useQuery({
    queryKey: ['companyLocationSelect', companyID],
    queryFn: () => {
      if (companyID) {
        return getCompanyInfo(companyID);
      }

      return undefined;
    },
  });
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    if (companyData) {
      setCompanyName(companyData.name);
    }
  }, [companyData]);
  if (isLoading) { return <Spinner />; }

  return (
    <Breadcrumbs>
      <Breadcrumb to="/phonebook">{t('Phonebook:phonebook_title')}</Breadcrumb>
      <Breadcrumb to="/phonebook/companies">
        {t('Phonebook:companies')}
      </Breadcrumb>
      <Breadcrumb to={`/phonebook/companies/${companyID}`}>
        {companyName}
      </Breadcrumb>
      {locationID && (
        <Breadcrumb
          to={`/phonebook/companies/${companyID}/locations/${companyID}/edit`}
        >
          {t('global:edit')}
        </Breadcrumb>
      )}
      {!locationID && (
        <Breadcrumb to="/phonebook/company-locations">
          {t('Phonebook:company_locations')}
        </Breadcrumb>
      )}
      {!locationID && (
        <Breadcrumb to={`/phonebook/companies/${companyID}/locations/create`}>
          {t('global:create')}
        </Breadcrumb>
      )}
    </Breadcrumbs>
  );
};
