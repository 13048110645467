import { makeContactFilter } from '@bq/components/FilterBar/filters/ContactFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterContact = makeContactFilter({
  toFilter: ({ value }) => {
    if (!value) {
      return null;
    }
    if (typeof value === 'string') {
      return {
        $or: {
          'contact.company.name:contains:ci': value,
          'contact.person.fullName:contains:ci': value,
          'contact.companyLocation.name:contains:ci': value,
          'contact.companyDepartment.name:contains:ci': value,
          'contact.manualContact:contains:ci': value,
        },
      };
    }
    if (typeof value === 'object') {
      const { type, ID } = value;

      return {
        [`contact.${type}.ID`]: ID,
      };
    }

    return null;
  },
  allowedContactTypes: {
    company: true,
    person: true,
    companyLocation: true,
    user: false, // Disabled because it's not implemented in backend yet
    companyDepartment: true,
  },
  name: () => i18n.t('Ticketing:form.contact'),
  operators: [],
});
