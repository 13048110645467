import { ReactElement, useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FormActions } from '../FormActions';
import { usePortalContainer } from '../Portal/PortalContext';
import { GroupFilter } from './filters/GroupFilter';
import { FilterTypes, FilterValue } from './types';

interface FilterEditorProps {
  filterTypes: FilterTypes;
  value: FilterValue[];
  onChange: (value: FilterValue[]) => void;
}

interface FilterEditorModalProps extends FilterEditorProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FilterEditor = ({
  onChange,
  ...props
}: FilterEditorProps): ReactElement => {
  const onOperatorChange = () => {
    /* No operators here */
  };
  const handleChange = (value: FilterValue[] | null) => {
    onChange((value ?? []) as FilterValue[]);
  };

  return (
    <GroupFilter
      name=""
      {...props}
      operator="$and"
      onChange={handleChange}
      operators={[]}
      onOperatorChange={onOperatorChange}
      onRemove={() => {
        /* Empty */
      }}
      headless={true}
    />
  );
};

export const FilterEditorModal = ({
  isOpen,
  onClose,
  value,
  onChange,
  ...props
}: FilterEditorModalProps): ReactElement => {
  const { t } = useTranslation();

  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');
  const portalRef = usePortalContainer();
  // Store copy of filters in state variable, only update original copy on submit
  const [filtersValue, setFiltersValue] = useState<FilterValue[]>(value);
  useEffect(() => {
    setFiltersValue(value);
  }, [value]);

  const cancel = () => {
    onClose();
    setFiltersValue(value); // Reset filter values on cancel
  };
  const submit = () => {
    onClose();
    onChange(filtersValue);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancel}
      size="4xl"
      closeOnOverlayClick={false}
      portalProps={{ containerRef: portalRef }}
    >
      <ModalOverlay />
      <ModalContent bg={bg} color={colorValue}>
        <ModalHeader>{t('global:advanced_filters')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FilterEditor
            {...props}
            value={filtersValue}
            onChange={setFiltersValue}
          />
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions onSubmit={submit} onCancel={cancel} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
