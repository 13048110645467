import { ReactElement, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { FaFolderPlus, FaPlus } from 'react-icons/fa';

import { FilterTypes } from '../../FilterBar/types';
import { OverviewList } from '../types';
import { DropArea } from './DropArea';
import { OverviewEditorContext } from './OverviewEditorContext';
import { OverviewSettings } from './OverviewSettings';
import { genSlug, uniqueId } from './util';

export interface OverviewEditorProps {
  filterTypes: FilterTypes;
  value: OverviewList;
  onChange: (value: OverviewList) => void;
  withGroupVisibility?: boolean;
}

export const OverviewEditor = ({
  filterTypes,
  value,
  onChange,
  withGroupVisibility = false,
}: OverviewEditorProps): ReactElement => {
  const { t } = useTranslation();

  const [selectedId, setSelectedId] = useState<string | null>(
    value[0]?.id ?? null
  );
  const addNewItem = useCallback(
    (type: 'item' | 'group') => {
      const title = type === 'group' ? 'New group' : 'New overview';
      onChange([
        ...value,
        {
          id: uniqueId(),
          type,
          title,
          slug: genSlug(value, title),
          overviews: type === 'group' ? [] : undefined,
          filters: [],
          global: true,
          visible: true,
        },
      ]);
    },
    [value, onChange]
  );
  const addOverview = useCallback(() => addNewItem('item'), [addNewItem]);
  const addGroup = useCallback(() => addNewItem('group'), [addNewItem]);
  const dropAreaBg = useColorModeValue('gray.200', 'brand.mediumGray');
  const editingPersonal = false;

  return (
    <OverviewEditorContext.Provider
      value={{
        selectedId,
        setSelectedId,
        editingPersonal,
        withGroupVisibility,
        overviews: value,
        tempOverviews: value,
        setTempOverviews: onChange,
      }}
    >
      <Grid templateColumns="1fr 3fr">
        <GridItem display="flex" flexDirection="column">
          <Box flex="1 0 auto" shadow="inner" bg={dropAreaBg}>
            <DndProvider backend={HTML5Backend}>
              <DropArea />
            </DndProvider>
          </Box>
          <HStack
            display="flex"
            justifyContent="flex-end"
            borderColor="gray.200"
            borderTopWidth="1px"
            borderStyle="solid"
            flex="0 1 auto"
            px="2"
            py="2"
          >
            <Button
              onClick={addGroup}
              display="flex"
              alignItems="center"
              colorScheme="green"
              size="sm"
            >
              <FaFolderPlus />
              {t('global:overviews.add_group')}
            </Button>
            <Button
              onClick={addOverview}
              display="flex"
              alignItems="center"
              colorScheme="green"
              size="sm"
            >
              <FaPlus />
              {t('global:overviews.add')}
            </Button>
          </HStack>
        </GridItem>
        <GridItem>
          <OverviewSettings filterTypes={filterTypes} />
        </GridItem>
      </Grid>
    </OverviewEditorContext.Provider>
  );
};
