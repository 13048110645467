import { FaEnvelope } from 'react-icons/fa';

import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { BaseSettings, FieldType } from '../../types';
import { newFieldName } from '../../util';
import { EmailField } from './EmailField';

export const emailField = (): FieldType<BaseSettings> => ({
  type: 'email',
  name: i18n.t('global:form_editor.field_email'),
  icon: FaEnvelope,
  defaultSettings: { name: newFieldName(), required: false },
  components: {
    FormField: EmailField,
  },
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        getValue: ({ row }) => {
          if (row.data) {
            return (
              ((row.data as Record<string, string | number>) ?? {})[field.id] ??
              '-'
            );
          }

          return '-';
        },
      },
    ];
  },
  getFilters(field) {
    return {
      [field.id]: makeTextFilter({
        name: field.settings.name,
        toFilter({ value, operator }) {
          if (!value) {
            return null;
          }

          const op = operator ?? 'contains:ci';

          return [`data.${field.id}`, `string:${op}`, value];
        },
      }),
    };
  },
  getGroupBy(field) {
    return [
      {
        name: field.settings.name,
        key: `data.${field.id}`,
        exportValue: `data.${field.id}`,
        columnKey: `$custom-${field.id}`,
      },
    ];
  },
});
