import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  GetOneParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';
import { CompanyFormData } from './CompanyForm';
import { formatNationalNumber } from './NationalNumber';
import { PersonFormData } from './PersonForm';
import {
  ApiPermissions,
  CompanyApiData,
  CompanyLocationApiData,
  NumberType,
  PersonApiData,
} from './types';
import { CompanyDepartment } from './CompanyDepartmentList/types';

export type PeopleResponse = ListingResponse<PersonApiData>;
export type CompaniesResponse = ListingResponse<CompanyApiData>;
export type CompanyLocationsResponse = ListingResponse<CompanyLocationApiData>;

export async function getListing<T>(
  path: string,
  params: GetListingParams = {}
): Promise<T> {
  const { data } = await Api.get<T>(path, {
    params: {
      page: 1,
      filters: [],
      limit: 10,
      withCount: true,
      ...params,
    },
  });

  return data;
}

export async function getOne<T>(
  path: string,
  params: GetOneParams = {}
): Promise<T> {
  const { data } = await Api.get<T>(path, { params });

  return data;
}

export function getPeople<T extends PeopleResponse = PeopleResponse>(
  params: GetListingParams = {}
): Promise<T> {
  return getListing<T>('/api/phonebook/people', params);
}

export function getCompanies(
  params: GetListingParams = {}
): Promise<CompaniesResponse> {
  return getListing<CompaniesResponse>('/api/phonebook/companies', params);
}

export function getCompanyLocations(
  companyID: number | string,
  params: GetListingParams = {}
): Promise<CompanyLocationsResponse> {
  return getListing<CompanyLocationsResponse>(
    `/api/phonebook/companies/${companyID}/locations`,
    params
  );
}
export function getCompanyLocationsAll(params: GetListingParams = {}) {
  return getListing<CompanyLocationsResponse>(
    '/api/phonebook/companyLocations',
    params
  );
}

export function getPerson(
  personID: number | string,
  params: GetOneParams = {}
): Promise<PersonApiData> {
  return getOne<PersonApiData>(`/api/phonebook/people/${personID}`, params);
}

export function getCompany(
  companyID: number | string,
  params: GetOneParams = {}
): Promise<CompanyApiData> {
  return getListing<CompanyApiData>(
    `/api/phonebook/companies/${companyID}`,
    params
  );
}

export function getCompanyLocation(
  companyID: number | string,
  locationID: number | string,
  params: GetOneParams = {}
): Promise<CompanyLocationApiData> {
  return getListing<CompanyLocationApiData>(
    `/api/phonebook/companies/${companyID}/locations/${locationID}`,
    params
  );
}

export function getCompanyLocationNoCompanyID(
  locationID: number | string,
  params: GetOneParams = {}
) {
  return getListing<CompanyLocationApiData>(
    `/api/phonebook/companyLocations/${locationID}`,
    params
  );
}

export function getCompanyDepartment(
  departmentID: number | string,
  params: GetOneParams = {}
): Promise<CompanyDepartment> {
  return getListing<CompanyDepartment>(
    `/api/phonebook/departments/${departmentID}`,
    params
  );
}

export async function insertPerson(
  formData: Partial<PersonFormData>
): Promise<number> {
  if (formData.nationalNumber) {
    const { type, nationalNumber } = formData.nationalNumber;
    if (type && nationalNumber) {
      formData.nationalNumber.nationalNumber = formatNationalNumber(
        type,
        nationalNumber
      );
    }
  }

  const { data } = await Api.post<{ ID: number }>(
    '/api/phonebook/people',
    formData
  );

  return data.ID;
}

export async function insertCompany(
  formData: Partial<CompanyFormData>
): Promise<number> {
  if (formData.nationalNumber) {
    const { type, nationalNumber } = formData.nationalNumber;
    if (type && nationalNumber) {
      formData.nationalNumber.nationalNumber = formatNationalNumber(
        type,
        nationalNumber
      );
    }
  }

  const { data } = await Api.post<{ ID: number }>(
    '/api/phonebook/companies',
    formData
  );

  return data.ID;
}

export async function getNumberTypes(): Promise<NumberType[]> {
  const { data } = await Api.get<NumberType[]>('/api/phonebook/numberTypes');

  return data;
}

export async function getPermissions(): Promise<ApiPermissions> {
  const { data } = await Api.get<ApiPermissions>('/api/phonebook/permissions');

  return data;
}
