import { Api } from 'BootQuery/Assets/js/api';
import { Contact } from '../../types';

export async function addNumberToContact(
  type: string,
  id: number,
  phoneNumber: string,
  numberType: number
): Promise<void> {
  console.log('Assigning: ', { type, id, phoneNumber, numberType });

  switch (type) {
    case 'person':
      await Api.post(`/api/phonebook/people/${id}/phoneNumbers`, {
        phoneNumber,
        type: numberType,
      });
      break;
    case 'company':
      await Api.post(`/api/phonebook/companies/${id}/phoneNumbers`, {
        phoneNumber,
        type: numberType,
      });
      break;
    default:
      throw new Error(`Unhandled contact type "${type}"`);
  }
}

export async function searchContacts(search: string): Promise<Contact[]> {
  const { data } = await Api.get<Contact[]>('/api/phonebook/contacts', {
    params: {
      $search: search,
    },
  });

  return data;
}
