import { TagOptionObject } from 'BootQuery/Assets/components/TagInput';
import {
  IUseMethodsCreate,
  IUseMethodsReturn,
  IUseMethodsWrapped,
  useMethods,
} from 'BootQuery/Assets/js/use-methods';
import { newFieldId } from '../../util';
import { TagFieldSettings } from './types';

export type TagsMethods = {
  setSettings: (settings: TagFieldSettings) => TagFieldSettings;
  addTag: () => TagFieldSettings;
  deleteTag: (id: string) => TagFieldSettings;
  renameTag: (id: string, label: string) => TagFieldSettings;
  changeTagColor: (id: string, color: string) => TagFieldSettings;
};

type CreateTagsMethods = IUseMethodsCreate<TagFieldSettings, TagsMethods>;

export type TagChangeMethods = IUseMethodsWrapped<
  TagFieldSettings,
  TagsMethods,
  CreateTagsMethods
>;

export function useTagsMethods(
  initial: TagFieldSettings
): IUseMethodsReturn<TagFieldSettings, TagsMethods> {
  return useMethods(createTagsMethods, initial);
}

export function createTagsMethods(settings: TagFieldSettings): TagsMethods {
  return {
    setSettings: (settings) => settings,
    addTag: () => ({
      ...settings,
      tags: [
        ...settings.tags,
        { value: newFieldId(), label: 'new_tag', color: '#666' },
      ],
    }),
    deleteTag: (id) => updateTag(settings, id, { deleted: true }),
    renameTag: (id, label) => updateTag(settings, id, { label }),
    changeTagColor: (id, color) => updateTag(settings, id, { color }),
  };
}

function updateTag(
  settings: TagFieldSettings,
  tagId: string,
  changes: Partial<TagOptionObject>
) {
  return {
    ...settings,
    tags: settings.tags.map((tag) => {
      if (tag.value === tagId) {
        return { ...tag, ...changes };
      }

      return tag;
    }),
  };
}
