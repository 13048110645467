import { ReactElement, ReactNode } from 'react';
import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FaAlignCenter,
  FaAlignJustify,
  FaAlignLeft,
  FaAlignRight,
  FaChevronDown,
} from 'react-icons/fa';

import { useAlignToggle } from '../../Buttons/Align/ToggleHandlers';
import { useFocusProvider } from '../../FocusContext';
import { useRichText } from '../../RichtextContex';

export const AlignMenu = (): ReactElement => {
  const { editor } = useRichText();
  const { t } = useTranslation();
  const { tabIndex, handleBlur, handleEscapingToolbar } = useFocusProvider();
  const currentAlignment = ['left', 'center', 'right', 'justify'].find(
    (alignment) => editor?.isActive({ textAlign: alignment })
  ) as Alignment | undefined;
  const alignmentLabelIcon = useAlignment(currentAlignment ?? null);

  const {
    toggleAlignLeft,
    toggleAlignRight,
    toggleAlignCenter,
    toggleAlignJustify,
  } = useAlignToggle(editor);

  return (
    <Menu>
      <MenuButton
        as={Button}
        isActive={false}
        rightIcon={<FaChevronDown />}
        aria-label={
          alignmentLabelIcon
            ? alignmentLabelIcon.label
            : t('global:richtext.alignment.left')
        }
        size="sm"
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.key === 'Escape') {
            handleEscapingToolbar();
          }
        }}
        onBlur={handleBlur}
        tabIndex={tabIndex}
      >
        {alignmentLabelIcon ? alignmentLabelIcon.icon : <FaAlignLeft />}
      </MenuButton>

      <MenuList>
        <MenuOptionGroup value={currentAlignment}>
          <MenuItemOption
            value="left"
            onClick={() => {
              toggleAlignLeft();
            }}
          >
            <HStack>
              <FaAlignLeft />
              <Box fontSize="sm">{t('global:richtext.alignment.left')}</Box>
            </HStack>
          </MenuItemOption>

          <MenuItemOption
            value="center"
            onClick={() => {
              toggleAlignCenter();
            }}
          >
            <HStack>
              <FaAlignCenter />
              <Box>{t('global:richtext.alignment.center')}</Box>
            </HStack>
          </MenuItemOption>
          <MenuItemOption
            value="right"
            onClick={() => {
              toggleAlignRight();
            }}
          >
            <HStack>
              <FaAlignRight />
              <Box>{t('global:richtext.alignment.right')}</Box>
            </HStack>
          </MenuItemOption>
          <MenuItemOption
            value="justify"
            onClick={() => {
              toggleAlignJustify();
            }}
          >
            <HStack>
              <FaAlignJustify />
              <Box>{t('global:richtext.alignment.justify')}</Box>
            </HStack>
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

type Alignment = 'left' | 'right' | 'center' | 'justify';
type UseAlignmentReturn = { label: string; icon: ReactNode } | null;
const useAlignment = (
  currentAlignment: Alignment | null
): UseAlignmentReturn => {
  const { t } = useTranslation('global');

  if (!currentAlignment) {
    return null;
  }

  switch (currentAlignment) {
    case 'left':
      return {
        label: t('global:richtext.alignment.left'),
        icon: <FaAlignLeft />,
      };
    case 'right':
      return {
        label: t('global:richtext.alignment.right'),
        icon: <FaAlignRight />,
      };
    case 'center':
      return {
        label: t('global:richtext.alignment.center'),
        icon: <FaAlignCenter />,
      };
    case 'justify':
      return {
        label: t('global:richtext.alignment.justify'),
        icon: <FaAlignJustify />,
      };
    default:
      throw Error('Unknown alignment');
  }
};
