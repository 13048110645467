import { KeyboardEvent as ReactKeyboardEvent } from 'react';

export interface DownshiftKeyboardEvent extends KeyboardEvent {
  preventDownshiftDefault?: boolean;
}

export function fixHomeEnd(ev: ReactKeyboardEvent): void {
  if (ev.key === 'Home' || ev.key === 'End') {
    (ev.nativeEvent as DownshiftKeyboardEvent).preventDownshiftDefault = true;
  }
}

export function downshiftKeydownFix(ev: ReactKeyboardEvent): void {
  fixHomeEnd(ev);
}
