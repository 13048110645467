import { ReactElement, useCallback, useState } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { useCalcDuration } from './use-calc-duration';
import { useDelayedInterval } from './use-delayed-interval';

type DurationCounterProps = { from: Date } & BoxProps;

export const DurationCounter = ({
  from,
  ...styleProps
}: DurationCounterProps): ReactElement => {
  const calcDuration = useCalcDuration();
  const [duration, setDuration] = useState<string>(calcDuration(from));

  const updateDuration = useCallback(() => {
    setDuration(calcDuration(from));
  }, [calcDuration, from]);

  const delay = Math.ceil(from.getTime() / 1000) * 1000 - from.getTime();
  useDelayedInterval(updateDuration, 1000, delay);

  return (
    <Box as="span" {...styleProps}>
      {duration}
    </Box>
  );
};
