import { ReactElement } from 'react';
import { Badge, Flex } from '@chakra-ui/react';

import { CallListCall } from './CallList/types';
import { CallParty } from './CallParty';

export type CallDestinationProps = Pick<
  CallListCall,
  'destinations' | 'destinationInfo'
>;

export const CallDestination = (call: CallDestinationProps): ReactElement => {
  const otherDestinationCount = countOtherDestinations(call);

  return (
    <Flex alignItems="center">
      {otherDestinationCount > 0 && (
        <>
          <Badge>+ {otherDestinationCount}</Badge>&nbsp;
        </>
      )}
      <CallParty
        party={call.destinationInfo.destination}
        phoneNumber={call.destinationInfo.phoneNumber}
      />
    </Flex>
  );
};

function countOtherDestinations(call: CallDestinationProps): number {
  const destinations = call.destinations
    .filter(({ destination }) => ['conference', 'phonePoint'].includes(destination.type));

  return destinations.length - 1;
}
