import { makeSelectionCell } from '@bq/components/Table';
import i18n from 'BootQuery/Assets/js/i18n';

import { ActionsCell } from '../Cells/ActionCell';
import { TypeCell } from '../Cells/TypeCell';
import { ItemColumn } from '../types';
import { baseColumns } from './base-columns';
import { logisticalDataColumns } from './logistical-data-columns';
import { priceColumns } from './price-columns';
import { productColumns } from './product-columns';
import { serviceColumns } from './service-columns';

export const columns = (): ItemColumn[] => [
  selectionCellColumn(),
  ...baseColumns(),
  ...productColumns(),
  ...serviceColumns(),
  ...priceColumns(),
  ...logisticalDataColumns(),
  actionColumn(),
  typeColumn(),
];

export const selectionCellColumn = (): ItemColumn => ({
  ...makeSelectionCell(),
  order: 100,
});

export const typeColumn = (): ItemColumn => ({
  key: 'productType',
  title: i18n.t('Products:type'),
  order: 103,
  Cell: TypeCell,
});

export const actionColumn = (): ItemColumn => ({
  key: 'actions',
  /** Intentional no title */
  title: '',
  defaultVisible: true,
  width: '120px',
  order: 500,
  Cell: ActionsCell,
});
