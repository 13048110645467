import Fuse from 'fuse.js';
import { AllowTypesSelectOptions } from './types';

let searcher: Fuse<AllowTypesSelectOptions> | null = null;

const options: Fuse.IFuseOptions<AllowTypesSelectOptions> = {
  keys: ['label'],
  includeScore: true,
  ignoreLocation: true,
};

export function getMimeSearcher(
  list: AllowTypesSelectOptions[]
): Fuse<AllowTypesSelectOptions> {
  if (!searcher) {
    searcher = new Fuse<AllowTypesSelectOptions>(list, options);
  }

  return searcher;
}
