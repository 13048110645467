import { ReactElement } from 'react';
import { Box, FormControl, useColorModeValue } from '@chakra-ui/react';

import { ProductItems } from '@bq/components/FormFields/Product';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { ProductItemsSettings } from './types';

type Props = FieldValue<ProductItemsSettings>;

export const ProductItemsField = ({ id, settings }: Props): ReactElement => {
  const bg = useColorModeValue('brand.lightGray', '#494949');

  return (
    <FormControl>
      <FieldLabel {...settings} />
      <Box px="4" py="2" background={bg} rounded="md">
        <ProductItems fieldName={id} itemFields={settings.fields} />
      </Box>
    </FormControl>
  );
};
