import mitt from 'mitt';

import { ContactValue } from 'app/Modules/Phonebook/Assets/components/ContactSelector';
import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

interface ContactUpdated {
  phoneNumber: string;
  contact: ContactValue | null;
}

export type CallListEvents = {
  contactUpdated: ContactUpdated;
};

export const callListEvents = mitt<CallListEvents>();

export const useCallListEvent = createUseMitt(callListEvents);
