import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

export const LongNameField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<ItemBaseFields>();

  return (
    <FormControl isInvalid={!!errors.longName} {...formControlProps}>
      <FormLabel htmlFor="longName">{t('Products:long_name')}</FormLabel>
      <Input
        {...register('longName')}
        size={['md', 'md', 'sm']}
        isInvalid={!!errors.longName}
        placeholder={t('Products:long_name')}
      />
    </FormControl>
  );
};
