import { round } from 'lodash-es';
import { ConvertResult, UnitData, UnitGroupKey } from './types';

export const convertUnits = (
  unitData: UnitData,
  unitGroup: UnitGroupKey,
  from: string,
  to: string,
  value: number
): ConvertResult => {
  const selectedUnitGroup = unitData[unitGroup] ?? null;
  if (selectedUnitGroup === null) {
    throw Error('Selected unit group does not exist');
  }
  const { units } = selectedUnitGroup;
  const fromExists = Object.prototype.hasOwnProperty.call(units, from);
  const toExists = Object.prototype.hasOwnProperty.call(units, to);
  if (!fromExists || !toExists) {
    throw Error('Unit does not exist in current unit group');
  }
  let finalValue = value;
  const { ratio: fromRatio } = units[from];
  const { ratio: toRatio } = units[to];
  finalValue = (value * (fromRatio ?? 1)) / (toRatio ?? 1);

  return { unit: to, unitGroup, value: round(finalValue, 14) };
};
