import { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaUpload } from 'react-icons/fa';

import { FileInfo } from '../FileInput/types';
import { UploadedIcon } from '../FileInput/UploadedIcon';

type ContentProps = {
  file?: FileInfo | null;
};

export const SingleFileInputContent = ({
  file,
}: ContentProps): ReactElement => {
  const { t } = useTranslation('global');

  if (!file) {
    return (
      <>
        <FaUpload />
        &nbsp; {t('global:select_file')}
      </>
    );
  }

  switch (file.type) {
    case 'uploading':
      return (
        <>
          {file.name}&nbsp;
          <Text as="span" color="gray">
            ({Math.round(file.progress)}
            {'%'})
          </Text>
        </>
      );
    case 'uploaded':
      return (
        <>
          {file.name}&nbsp;
          <UploadedIcon />
        </>
      );
    case 'saved':
      return <>{file.name}</>;
    default:
      throw new Error('Unhandled upload state');
  }
};
