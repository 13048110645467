import { ReactElement } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import {
  useListingSettings,
  useSplitColumnsFeatures,
} from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { setColValues } from 'app/Modules/Ticketing/Util/GetColumns';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { TableSelectionProvider } from 'BootQuery/Assets/components/Table';
import { CardBulkActionMenu } from 'BootQuery/Assets/components/Table/CardBulkActionMenu';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns, personCardFeatures } from './columns';
import { personFilterTypes } from './filter-types';
import { generateExport } from './generate-export';
import { getCardPeople } from './get-people';
import {
  usePersonBulkActions,
} from './person-list-bulk-actions';
import { PersonCard } from './PersonCard';

interface Props {
  customFields?: FieldValue[];
  permissions: Permissions;
}

export const PersonCardList = ({
  customFields,
  permissions,
}: Props): ReactElement => {
  const selectedOverview = useSelectedOverview('people');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName: 'Phonebook.PersonList',
    viewName: 'card',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: selectedOverview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { filters, page, limit, density, columnsToShow } = listingSettings;

  const { columns: customColumns, features } = useSplitColumnsFeatures(
    columnsToShow,
    personCardFeatures
  );

  const { data, refetch } = useQuery({
    queryKey: ['Phonebook.PersonList.card', page, filters, limit],
    queryFn: () => getCardPeople({ page, filters, limit }),
    enabled: filters !== undefined,
    keepPreviousData: true,
  });
  const bulkActions = usePersonBulkActions(refetch);
  const itemCount = data?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: itemCount,
    displayModeOptions: phonebookDisplayModeOptions,
    menuChildren: <ExportMenuItem />,
  });

  if (!data) {
    return <LoadingPage />;
  }

  const { data: people } = data;

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<PersonApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardPeople({
            fields: [
              'ID',
              'firstName',
              'lastName',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],
            limit: 'null',
            filters,
          });

          return data;
        }}
        selectPage={() => people}
      >
        <Box mt={9} mb={4} px={4}>
          <ExportModalProvider
            onExport={generateExport}
            rowCount={itemCount}
            filterExpression={filters ?? []}
          >
            <ListHeading {...headingProps} Actions={<Actions />} />
          </ExportModalProvider>
        </Box>
        <SimpleGrid
          m="10px 15px 10px 10px"
          gridGap={4}
          columns={setColValues(density)}
        >
          {people.map((person) => (
            <PersonCard
              key={person.ID}
              person={person}
              customColumns={customColumns}
              features={features}
            />
          ))}
        </SimpleGrid>

        <CardBulkActionMenu<PersonApiData> actionGroups={bulkActions} />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};
