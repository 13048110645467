import { Api } from 'BootQuery/Assets/js/api';
import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsyncCreatableSelect } from 'chakra-react-select';
import {
  chakraSelectStyles,
  formatCreateLabel,
  itemToSelectOption,
  selectItemToValue,
  SelectOption,
  selectStyles,
  valueToSelectItem,
} from 'BootQuery/Assets/components/Select';
import { ListItem } from '../types';
import { PersonFormData } from './types';

async function searchCompanyPositions(search: string): Promise<SelectOption[]> {
  const { data: positions } = await Api.get<ListItem[]>(
    '/api/phonebook/companyPositions',
    {
      params: {
        $search: search,
        fields: ['ID', 'name'],
      },
    }
  );

  return positions.map(itemToSelectOption);
}

export const CompanyPositionInput = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control } = useFormContext<PersonFormData>();

  return (
    <Controller
      control={control}
      name="companyPosition"
      render={({ field }) => (
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          isClearable
          loadOptions={searchCompanyPositions}
          value={valueToSelectItem(field.value)}
          onChange={(val: SelectOption | null) => {
            field.onChange(selectItemToValue(val));
          }}
          formatCreateLabel={formatCreateLabel}
          placeholder={t('Phonebook:position')}
          styles={chakraSelectStyles}
          chakraStyles={selectStyles}
          selectedOptionColorScheme="brand"
        />
      )}
    />
  );
};
