import { forwardRef, ReactElement } from 'react';
import { InputProps } from '@chakra-ui/react';

import { InlineEdit } from '../InlineEdit';

export const FilenameInput = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref): ReactElement => {
    return <InlineEdit {...props} ref={ref} />;
  }
);
FilenameInput.displayName = 'FilenameInput';
