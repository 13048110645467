import Module from 'BootQuery/Assets/js/module';

import { ReactRoutes } from './react-routes';

export default class Datasets extends Module {
  get provides() {
    return {
      reactRoutes: {
        ReactRoutes,
      },
    };
  }

  static matchReactRoute = '/datasets/';

  async init(data) {
    super.init();
  }

  activateElements(target, data) {}
}
