module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " table-sm";
},"3":function(container,depth0,helpers,partials,data) {
    return "mb-0";
},"5":function(container,depth0,helpers,partials,data) {
    return "table-sm";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"tableName") : depth0), depth0))
    + "-table";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"datatableRowPartial") : depth0), depth0));
},"11":function(container,depth0,helpers,partials,data) {
    return "tablerow";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			data-datatable-"
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "=\""
    + alias2(alias1(depth0, depth0))
    + "\"\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-responsive\">\n	<table class=\"table table-striped table table-hover table-sticky-header"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"session"))) && lookupProperty(stack1,"compactTables")),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":72},"end":{"line":2,"column":133}}})) != null ? stack1 : "")
    + " datatable mb-0 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"noBottomMargin") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":149},"end":{"line":2,"column":182}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"small") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":183},"end":{"line":2,"column":211}}})) != null ? stack1 : "")
    + " d-print-table\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tableName") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":231},"end":{"line":2,"column":274}}})) != null ? stack1 : "")
    + "\"\n		data-datatable-row-partial=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"datatableRowPartial") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":103}}})) != null ? stack1 : "")
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tableDataAttributes") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":2},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "		data-datatable-module=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"module") : depth0), depth0))
    + "\">\n		<thead>\n			"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"tableheader"),depth0,{"name":"tableheader","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</thead>\n		<tbody>\n			"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"tablebody"),depth0,{"name":"tablebody","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</tbody>\n		<tfoot>\n			"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"tablefooter"),depth0,{"name":"tablefooter","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "		</tfoot>\n	</table>\n</div>";
},"usePartial":true,"useData":true})