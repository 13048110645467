import { ModalBody, ModalContent } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';

import { CaseTypesModalContent } from './CaseTypesModalContent';
import { ICaseTypesFormData } from './types';
import { useSalesFormDefinition } from './use-sales-form-definition';

export const CaseTypeModal = (props: ICaseTypesFormData) => {
  const caseType = props.mode === 'edit' ? props.data : {};
  const formDefinition = useSalesFormDefinition(caseType.ID ?? 0, 'edit');
  if (formDefinition) {
    return (
      <CaseTypesModalContent
        {...props}
        caseType={caseType}
        formDefinition={formDefinition}
      />
    );
  }

  return (
    <ModalContent>
      <ModalBody>
        <LoadingPage />
      </ModalBody>
    </ModalContent>
  );
};
