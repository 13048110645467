import { z } from 'zod';

export const columnSchema = z.object({
  key: z.string().nullable().optional(), // will be blank for new columns
  title: z.string().nonempty().min(1),
  type: z.enum([
    'phone',
    'email',
    'bool',
    'number',
    'date',
    'datetime',
    'text',
  ]),
  deleted: z.boolean().optional(),
});

export const datasetFormSchema = z.object({
  name: z.string().nonempty().min(1),
  displayNameTemplate: z.string().optional().nullable(),
  columns: z.array(columnSchema),
});

export type IDatasetFormColumn = z.infer<typeof columnSchema>;
export type IDatasetForm = z.infer<typeof datasetFormSchema>;
