import { Box, HStack } from '@chakra-ui/react';
import { Contact } from 'app/Modules/Phonebook/Assets/js/types';
import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import { SelectOption, selectStyles } from 'BootQuery/Assets/components/Select';
import { Api } from 'BootQuery/Assets/js/api';
import { ReactElement, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsyncSelect } from 'chakra-react-select';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';
import { FormContactValue, CustomEventFormData } from './types';

interface Props {
  idx: number;
  onRemove: (idx: number) => void;
}

export const LinkedContactRow = ({ idx, onRemove }: Props): ReactElement => {
  const { t } = useTranslation('Events');

  const { control } = useFormContext<CustomEventFormData>();
  const remove = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  return (
    <HStack>
      <Box flex="1 1 auto">
        <Controller
          control={control}
          name={`contacts.${idx}.contact`}
          render={({ field }) => (
            <AsyncSelect<SelectOption, false>
              selectedOptionColorScheme="brand"
              cacheOptions
              defaultOptions
              menuPortalTarget={document.body}
              chakraStyles={selectStyles}
              loadOptions={searchContacts}
              value={valueToSelectItem(field.value)}
              onChange={(val: SelectOption | null) => {
                field.onChange(selectItemToValue(val));
              }}
              placeholder={t('Events:contact')}
              styles={modalSelect}
            />
          )}
        />
      </Box>
      <DeleteButton size="sm" onClick={remove} />
    </HStack>
  );
};

async function searchContacts(search: string): Promise<SelectOption[]> {
  const { data: contacts } = await Api.get<Contact[]>(
    '/api/phonebook/contacts',
    {
      params: {
        $search: search,
      },
    }
  );

  return contacts.map((contact) => ({
    label: contact.name,
    value: `${contact.type}-${contact.ID}`,
  }));
}

export function selectItemToValue(
  option: SelectOption | null
): FormContactValue | null {
  if (!option) {
    return null;
  }

  return { ID: option.value, name: option.label };
}

function valueToSelectItem(val: FormContactValue | null): SelectOption | null {
  if (!val) {
    return null;
  }

  return { label: val.name, value: val.ID };
}
