import { DragOverlay } from '@dnd-kit/core';
import { ReactElement } from 'react';

import { BaseItem, useActiveItem } from '../DndContainers';
import { EditorField } from './EditorField';
import { FieldListItem } from './FieldListItem';
import { TypeItem, ValueItem } from './types';
import { itemIsField, itemIsType } from './util';

export const FormEditorDragOverlay = (): ReactElement => {
  const activeItem = useActiveItem<TypeItem | ValueItem>();

  return (
    <DragOverlay>
      {activeItem && <DraggedItem {...activeItem.item} />}
    </DragOverlay>
  );
};

type DraggedItemProps = BaseItem<TypeItem | ValueItem>;

const DraggedItem = (props: DraggedItemProps): ReactElement => {
  if (itemIsType(props)) {
    return <FieldListItem {...props} />;
  }
  if (itemIsField(props)) {
    return <EditorField {...props} />;
  }

  throw new Error('Can only drag field types and fields');
};
