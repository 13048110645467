import {
  AsyncCreatableSelect,
  AsyncSelect,
  GroupBase,
} from 'chakra-react-select';

import { OBJ, Primitive } from 'BootQuery/Assets/js/globalTypes';

import { useSelectProps } from './select-props';
import { SelectOption, SelectProps } from './types';

export const Select = <
  ValType extends Primitive = Primitive,
  AdditionalData extends OBJ = OBJ,
  Option extends SelectOption<ValType, AdditionalData> = SelectOption<
    ValType,
    AdditionalData
  >,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: SelectProps<ValType, AdditionalData, Option, Group>
  ) => {
  const selectProps = useSelectProps(props);

  if (props?.isCreatable) {
    const { creatableProps } = props;

    return (
      <AsyncCreatableSelect<Option, false, Group>
        {...creatableProps}
        {...selectProps}
      />
    );
  }

  return <AsyncSelect<Option, false, Group> {...selectProps} />;
};
