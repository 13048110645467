import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterUpdatedAt = makeDateFilter({
  name: () => i18n.t('global:item_crud_dates.updated_at'),
  toFilter: makeDateToFilter('updatedAt'),
});
