import { useMemo } from 'react';
import { getFieldTypes } from './field-types';
import { FieldType, FieldValue, GroupBy } from './types';

export const customFormGroupBy = (
  fields: FieldValue[],
  fieldTypes: FieldType[]
): GroupBy[] => {
  return fields.reduce((groupBys, field) => {
    const fieldType = fieldTypes.find((type) => type.type === field.type);
    if (fieldType?.getGroupBy) {
      return [...groupBys, ...fieldType.getGroupBy(field)];
    }

    return groupBys;
  }, [] as GroupBy[]);
};

export const useCustomFormGroupBy = (
  fields: FieldValue[],
  fieldTypes: FieldType[] | null = null
): GroupBy[] => {
  return useMemo(
    () => customFormGroupBy(fields, fieldTypes ?? getFieldTypes()),
    [fields, fieldTypes]
  );
};
