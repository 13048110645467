import { PropsWithChildren, ReactElement, useCallback } from 'react';
import { Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileAlt } from 'react-icons/fa';

import { useModal } from '@bq/components/UseModal';

import { SettingsFormEditModal } from './SettingsFormEditModal';

export interface Props {
  formName: string;
  inputName: string;
}

export const SettingsFormEditButton = ({
  formName,
  inputName,
  children,
}: PropsWithChildren<Props>): ReactElement => {
  const { addModal } = useModal();
  const editForm = useCallback(() => {
    addModal({
      children: (
        <SettingsFormEditModal formName={formName} inputName={inputName} />
      ),
      componentProps: {
        modal: {
          size: '6xl',
          scrollBehavior: 'inside',
          closeOnOverlayClick: false,
        },
      },
    });
  }, [addModal, formName, inputName]);

  return (
    <Button onClick={editForm}>{children ?? <DefaultButtonContent />}</Button>
  );
};

export const DefaultButtonContent = (): ReactElement => {
  const { t } = useTranslation('global');

  return (
    <>
      <FaFileAlt />
      &nbsp;
      {t('global:edit_form')}
    </>
  );
};
