import { Checkbox } from '@chakra-ui/react';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentPermissions, setInputValue } from './util';

function getCallActionPermissions(groupID: number | string): Promise<boolean> {
  return getCurrentPermissions<boolean>('callActions', groupID);
}

interface Props {
  groupID: number | string;
}

export const ActionsPermission = ({ groupID }: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [value, setValue] = useState<boolean | null>(null);
  useEffect(() => {
    getCallActionPermissions(groupID).then(setValue);
  }, [groupID]);

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const val = ev.target.checked;
      setValue(val);
      setInputValue('callActions', val);
    },
    [setValue]
  );

  if (value === null) {
    return <>...</>;
  }

  return (
    <Checkbox isChecked={value} onChange={handleChange}>
      {t('Telephony:permissions.can_do_call_actions')}
    </Checkbox>
  );
};
