import { FormControl, FormLabel } from '@chakra-ui/react';
import { SingleDateInput } from 'BootQuery/Assets/components/DateInput';
import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CustomEventFormData } from './types';

export const EventAtField = (): ReactElement => {
  const { t } = useTranslation('Events');

  const { control } = useFormContext<CustomEventFormData>();
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name: 'eventAt',
    control,
    rules: { required: true },
    defaultValue: new Date(),
  });

  if (typeof value === 'string' && value !== '') {
    throw new Error('Got string instead of Date for datetime field eventAt');
  }

  const inputClass = error
    ? 'datepicker-input datepicker-input-invalid'
    : 'datepicker-input';

  return (
    <FormControl>
      <FormLabel fontWeight="bold">
        <>{t('Events:event_at')}:</>
      </FormLabel>
      <SingleDateInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        inputClass={inputClass}
        isClearable={false}
        showTime
      />
    </FormControl>
  );
};
