import { useMemo } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { PartialItem } from '../../../types';
import { FieldProps } from '../../types';

export const VolumeField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const { watch } = useFormContext<Partial<PartialItem>>();
  const height = watch('good.heightMeters') ?? 0;
  const length = watch('good.lengthMeters') ?? 0;
  const width = watch('good.widthMeters') ?? 0;

  const unit = watch('good.dimensionUnit')?.symbol ?? 'm';
  const volume = useMemo(() => {
    return height * width * length;
  }, [height, length, width]);

  return (
    <FormControl {...formControlProps}>
      <FormLabel htmlFor="volume">{t('Products:volume')}</FormLabel>
      <InputGroup size={['md', 'md', 'sm']}>
        <Input placeholder={t('Products:volume')} isDisabled value={volume} />
        <InputRightAddon>{`${unit}³`}</InputRightAddon>
      </InputGroup>
    </FormControl>
  );
};
