import i18n from 'BootQuery/Assets/js/i18n';

import { fieldFromEditorValue } from '../../from-editor-value';
import { ListIcon } from '../../icons';
import { loadDefinitions } from '../../load-definitions';
import { fieldToEditorValue } from '../../to-editor-value';
import { EditorItem, FieldType, FieldValue } from '../../types';
import { ListField } from './ListField';
import { ListFieldEditorOuter } from './ListFieldEditorOuter';
import { StaticListField } from './StaticListField';
import { ListFieldSettings } from './types';

export const listField = (): FieldType<ListFieldSettings> => ({
  type: 'list',
  name: i18n.t('global:form_editor.field_list'),
  icon: ListIcon,
  defaultSettings: () => ({
    name: `[${i18n.t('global:form_editor.untitled_list')}]`,
    required: false,
    subfields: [],
  }),
  hasChildren: true,
  loadDefinition: (settings, types) => ({
    ...settings,
    subfields: loadDefinitions(types, settings.subfields),
  }),
  toEditorValue: (field, fieldTypes): EditorItem => ({
    id: field.id,
    hasChildren: true,
    children: field.settings.subfields.map((subfield) => {
      return fieldToEditorValue(subfield, fieldTypes);
    }),
    content: {
      itemType: 'fieldValue',
      ...field,
    },
  }),
  fromEditorValue: (item, fieldTypes): FieldValue<ListFieldSettings> => {
    if (!item.hasChildren) {
      throw new Error('ListField has no children');
    }

    return {
      id: item.content.id,
      type: item.content.type,
      settings: {
        ...item.content.settings,
        subfields: item.children.map((child) => {
          return fieldFromEditorValue(child, fieldTypes);
        }),
      },
    };
  },
  components: {
    EditorFieldOuter: ListFieldEditorOuter,
    FormField: ListField,
    StaticFormField: StaticListField,
  },
});
