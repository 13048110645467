import {
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Button, ModalBody, ModalFooter, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';
import { ListingGrid, ListingGridItem } from '@bq/components/ListingGrid';
import { uniqid } from 'app/assets/js/tsutil';

import {
  ContactCategory,
  UserGroup,
} from '../../HopsOperator/HopsContacts/types';
import { CategoryEdit } from './CategoryEdit';
import { CategoryItem } from './CategoryItem';

interface Props {
  initialCategories: ContactCategory[];
  groups: UserGroup[];
  onSubmit: (categories: ContactCategory[]) => void;
  onCancel: () => void;
}

export const ContactSettingsContent = ({
  initialCategories,
  groups,
  onSubmit,
  onCancel,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [categories, setCategories] =
    useState<ContactCategory[]>(initialCategories);

  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
    initialCategories[0]?.id ?? null
  );
  const selectedCategory = useMemo(() => {
    if (!selectedCategoryId) {
      return null;
    }

    return categories.find((cat) => cat.id === selectedCategoryId) ?? null;
  }, [categories, selectedCategoryId]);

  const handleSubmit = useCallback(() => {
    console.log('Categories: ', categories);
    onSubmit(categories);
  }, [onSubmit, categories]);

  const handleCategoryChange = useCallback(
    (id: string, newValue: SetStateAction<ContactCategory>) => {
      setCategories((prevCategories) => {
        return prevCategories.map((prev) => {
          if (prev.id === id) {
            return typeof newValue === 'function' ? newValue(prev) : newValue;
          }

          return prev;
        });
      });
    },
    []
  );

  const addCategory = useCallback(() => {
    setCategories((prev) => {
      const newCategory: ContactCategory = {
        id: `$new-${uniqid()}`,
        name: 'New category',
        contacts: Array(20).fill(null),
        color: null,
        groups: [],
      };

      return [...prev, newCategory];
    });
  }, [setCategories]);

  const removeCategory = useCallback((categoryId: string) => {
    setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
  }, []);
  const moveCategory = useCallback(
    (categoryId: string, direction: 'up' | 'down') => {
      setCategories((prev) => {
        const currentPos = prev.findIndex((prev) => prev.id === categoryId);
        if (currentPos === -1) {
          console.error('Unable to find category to move: ', {
            categoryId,
            categories: prev,
          });
          throw new Error('Category to move not found');
        }

        const newPos =
          direction === 'up'
            ? Math.max(currentPos - 1, 0)
            : Math.min(currentPos + 1, prev.length - 1);

        if (newPos === currentPos) {
          return prev;
        }

        const thisCat = prev[currentPos];
        const swapWithCat = prev[newPos];

        return prev.map((cat, idx) => {
          if (idx === currentPos) {
            return swapWithCat;
          }
          if (idx === newPos) {
            return thisCat;
          }

          return cat;
        });
      });
    },
    []
  );

  const selectedCategoryIdx = categories.findIndex(
    (cat) => cat.id === selectedCategoryId
  );

  return (
    <>
      <ModalBody>
        <ListingGrid height="full">
          <ListingGridItem initialWidth="1fr" minWidth={150}>
            <VStack width="full" alignItems="stretch">
              {categories.map((category) => (
                <CategoryItem
                  key={category.id}
                  id={category.id}
                  name={category.name}
                  color={category.color}
                  setSelectedCategoryId={setSelectedCategoryId}
                  isSelected={selectedCategoryId === category.id}
                />
              ))}
              <Button size="sm" colorScheme="green" onClick={addCategory}>
                <FaPlus /> {t('global:add')}
              </Button>
            </VStack>
          </ListingGridItem>
          <ListingGridItem initialWidth="4fr" minWidth={400}>
            <CategoryEdit
              value={selectedCategory}
              onChange={handleCategoryChange}
              groups={groups}
              removeCategory={removeCategory}
              moveCategory={moveCategory}
              isFirst={selectedCategoryIdx === 0}
              isLast={selectedCategoryIdx === categories.length - 1}
            />
          </ListingGridItem>
        </ListingGrid>
      </ModalBody>
      <ModalFooter>
        <FormActions onCancel={onCancel} onSubmit={handleSubmit} />
      </ModalFooter>
    </>
  );
};
