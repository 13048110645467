import { useMethods } from 'BootQuery/Assets/js/use-methods';
import {
  Modifications,
  SettingsMethodsRaw,
  UseSettingsMethodsResult,
  WithID,
} from './types';

export const createSettingsMethods = <ItemType extends WithID>(
  prev: Modifications<ItemType>
): SettingsMethodsRaw<ItemType> => {
  return {
    add: (newItem: ItemType) => ({
      ...prev,
      add: [...prev.add, { ...newItem }],
    }),
    del: (ID: ItemType['ID']) => {
      const wasNew = prev.add.some((added) => added.ID === ID);

      if (wasNew) {
        return {
          // If new item, remove from added
          ...prev,
          add: prev.add.filter((added) => added.ID !== ID),
        };
      }

      return {
        // If new item, mark as deleted
        ...prev,
        del: [...prev.del, ID],
      };
    },
    upd: (newItem: ItemType) => {
      const wasAdded = prev.add.some((added) => added.ID === newItem.ID);

      if (wasAdded) {
        // If new item, update it's data in added array list
        return {
          ...prev,
          add: prev.add.map((added) => {
            if (added.ID === newItem.ID) {
              return { ...added, ...newItem };
            }

            return added;
          }),
        };
      }

      // If existing item, mark updates
      return {
        ...prev,
        upd: { ...prev.upd, [newItem.ID]: newItem },
      };
    },
    undo: (ID: ItemType['ID']) => {
      const { add, del, upd } = prev;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [ID]: toBeRemoved, ...rest } = upd;

      return {
        add: add.filter((item) => item.ID !== ID),
        del: del.filter((item) => item !== ID),
        upd: rest,
      } as Modifications<ItemType>;
    },
    clearAll: () => ({
      add: [],
      upd: {} as Record<ItemType['ID'], Partial<ItemType>>,
      del: [],
    }),
  };
};

export const useSettingsMethods = <ItemType extends WithID>(
  initial: Modifications<ItemType>
): UseSettingsMethodsResult<ItemType> => {
  return useMethods(createSettingsMethods, initial);
};
