import { ReactElement } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ExportFormFields } from './types';

interface Props {
  maxResults: number;
}

export const LimitField = ({ maxResults }: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { control } = useFormContext<ExportFormFields>();

  const controller = useController({ control, name: 'limit' });

  return (
    <FormControl mb="5">
      <FormLabel fontWeight="bold">{t('Telephony:max_results')}</FormLabel>
      <HStack spacing="3">
        <Input
          size="sm"
          flex="0 1 6em"
          type="number"
          min={1}
          max={maxResults}
          step={1}
          value={controller.field.value}
          onChange={controller.field.onChange}
        />
        <Box flex="1 1 auto" pr="1">
          <Slider
            min={1}
            max={maxResults}
            step={1}
            value={controller.field.value}
            onChange={controller.field.onChange}
            focusThumbOnChange={false}
          >
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Box>
        <Text flex="0 1 auto" as="span">
          {maxResults}
        </Text>
      </HStack>
    </FormControl>
  );
};
