import { TaxRateData } from '../../types';
import { getTaxRates } from './getTaxRates';

interface SearchTaxRatesParams {
  search?: string;
}
export const searchTaxRates = async ({
  search,
}: SearchTaxRatesParams): Promise<TaxRateData[]> => {
  const taxRates = await getTaxRates({
    params: {
      filters: {
        'name:contains:ci': search,
      },
    },
  });

  return taxRates.data;
};
