import { FileUpload } from 'BootQuery/Assets/js/file-upload';

import { FileInfo } from '../FileInput/types';
import { uniqid } from '../FormEditor/util';
import { SetFile } from './types';

export async function handleUpload(
  file: File,
  onChange: SetFile
): Promise<void> {
  const upload = new FileUpload(file);
  const fileId = uniqid();

  // Store current file state ourselves so we don't have to require a full
  // setState action
  let currentFile: FileInfo = {
    type: 'uploading',
    fileId,
    file,
    upload,
    name: file.name,
    progress: 0,
    size: file.size,
  };

  const setFile = (file: FileInfo) => {
    currentFile = file;
    onChange(file);
  };

  upload.events.on('progress', (progress) => {
    if (currentFile.type !== 'uploading') {
      // Already uploaded
      return;
    }

    setFile({
      ...currentFile,
      progress,
    });
  });

  upload.start().then((uploadId) => {
    setFile({
      type: 'uploaded',
      uploadId,
      fileId,
      name: file.name,
      link: `/api/uploads/${uploadId}`,
      size: file.size,
    });
  });
}
