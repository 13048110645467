import { isEqual, pick } from 'lodash-es';
import { create as zustand } from 'zustand';

import { Api as api } from 'BootQuery/Assets/js/api';
import reusePromise from 'BootQuery/Assets/js/reuse-promise';

import { tabs, TabsStateStore } from '../../../store/tabs';
import { softphoneInstance } from '../softphone-instance';
import {
  FuncType,
  proxySessionMethod,
  proxySettingsMethod,
  proxySoftphoneMethod,
  softphoneActionWaiters,
} from '../softphone-tab-proxy';
import {
  DeviceCredentials,
  IceConfig,
  SessionIdInfo,
  SessionInterface,
  SessionStateInterface,
  SoftphoneError,
  SoftphoneInterface,
  SoftphoneSettingsInterface,
  SoftphoneStateInterface,
} from '../types';
import { softphoneEvents } from './softphone-events';

async function getIceConfig(): Promise<IceConfig> {
  const { data } = await api.get('/api/dialer/iceConfig');

  return data as IceConfig;
}

async function getCredentials(deviceId: string): Promise<DeviceCredentials> {
  const { data } = await api.get(`/api/dialer/deviceCredentials/${deviceId}`);

  return data as DeviceCredentials;
}

function pickStateProps(session: SessionInterface): SessionStateInterface {
  return pick(session, [
    'sessionId',
    'sipCallId',
    'startAt',
    'lastStateChange',
    'phoneNumber',
    'displayName',
    'state',
    'muted',
    'waitingForAttendedTransfer',
    'error',
  ]);
}

const makeSessionProxy = (session: SessionInterface): SessionInterface => ({
  ...pickStateProps(session),
  hold: proxySessionMethod(session.sessionId, 'hold'),
  unhold: proxySessionMethod(session.sessionId, 'unhold'),
  end: proxySessionMethod(session.sessionId, 'end'),
  answer: proxySessionMethod(session.sessionId, 'answer'),
  toggleMute: proxySessionMethod(session.sessionId, 'toggleMute'),
  transfer: proxySessionMethod(session.sessionId, 'transfer'),
  transferToSession: proxySessionMethod(session.sessionId, 'transferToSession'),
  confirmTransfer: proxySessionMethod(session.sessionId, 'confirmTransfer'),
  sendDtmf: proxySessionMethod(session.sessionId, 'sendDtmf'),
});

function pickSettingsStateProps(settings: Partial<SoftphoneSettingsInterface>) {
  return pick(settings, [
    'echoCancellation',
    'noiseSupression',
    'avoidAudioProcessing',
    'inputVolume',
    'outputVolume',
    'ringVolume',
    'audioInputDevice',
    'audioOutputDevice',
    'ringDevice',
    'preset',
    'presets',
    'lastPreset',
    'muted',
  ]);
}
interface SoftphoneStoreInterface extends SoftphoneStateInterface {
  deviceId: string | null;
  init: (force?: boolean) => Promise<void>;
  deinit: () => void;
  call: (number: string, waitReady?: boolean) => Promise<SessionIdInfo>;
  switchHere: () => void;
  switchToSpeakerAfterHangup: boolean;
}

type SoftphoneWindow = Window &
  typeof globalThis & {
    softphone: SoftphoneInterface | null;
  };

type MasterTabState = Pick<TabsStateStore, 'masterTabId' | 'tabs'>;

function softphoneFromTabData({
  masterTabId,
  tabs,
}: MasterTabState): SoftphoneStateInterface | null {
  if (!masterTabId) {
    return null;
  }
  const tab = tabs[masterTabId];
  if (!tab) {
    return null;
  }

  return (tab.softphone ?? null) as SoftphoneStateInterface | null;
}

export const softphoneState = zustand<SoftphoneStoreInterface>(
  (set, get, { subscribe }) => {
    let softphoneHere = false;
    let unsubOwnChanges: (() => void) | null = null;
    let unsubTabChanges: (() => void) | null = null;
    let unsubMasterTabChanges: (() => void) | null = null;

    const doCall = proxySoftphoneMethod('call');

    const onSoftphoneCmd = async (
      actionId: string,
      cmd: string,
      args: unknown[]
    ) => {
      const keyCmd = cmd as FuncType<SoftphoneInterface>;
      const self = get();

      const func = self[keyCmd] ?? null;
      if (typeof func !== 'function') {
        console.error(
          `Tried to execute non existent softphone command: ${keyCmd}`
        );

        return;
      }

      // Handle Promise and non-promise returns
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const returnValue = await Promise.resolve((func as any)(...args));

      localStorage.setItem(
        'softphoneCmdRet',
        JSON.stringify({ actionId, returnValue })
      );
      localStorage.removeItem('softphoneCmdRet');
    };
    const onSoftphoneSettingsCmd = (cmd: string, args: unknown[]) => {
      const keyCmd = cmd as FuncType<SoftphoneSettingsInterface>;
      const self = get();

      const func = self.settings[keyCmd] ?? null;
      if (typeof func !== 'function') {
        console.error(
          `Tried to execute non existent softphone settings command: ${keyCmd}`
        );

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (func as any)(...args);
    };
    const onSoftphoneSessionCmd = (
      sessionId: string,
      cmd: string,
      args: unknown[]
    ) => {
      const keyCmd = cmd as FuncType<SessionInterface>;
      const self = get();

      const sess = self.sessions.find((sess) => sess.sessionId === sessionId);
      if (!sess) {
        console.error(
          `Tried to execute session cmd for unknown session ${sessionId}`
        );

        return;
      }

      const func = sess[keyCmd] ?? null;
      if (typeof func !== 'function') {
        console.error(
          `Tried to execute non existent softphone session command: ${keyCmd}`
        );

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (func as any)(...args);
    };
    const storageEventListener = (ev: StorageEvent) => {
      if (ev.key === 'softphoneCmd' && ev.newValue) {
        if (!softphoneHere) {
          return;
        }

        const { cmd, args, actionId } = JSON.parse(ev.newValue);
        if (typeof cmd !== 'string' || !Array.isArray(args)) {
          console.error('Invalid softphoneCmd format: ', { cmd, args });

          return;
        }

        onSoftphoneCmd(actionId, cmd, args);
      }
      if (ev.key === 'softphoneError' && ev.newValue) {
        const err = JSON.parse(ev.newValue) as SoftphoneError;
        console.log('Passing through softphone error: ', err);
        softphoneEvents.emit('error', err);
      }
      if (ev.key === 'softphoneCmdRet' && ev.newValue) {
        const { actionId, returnValue } = JSON.parse(ev.newValue);
        const waiter = softphoneActionWaiters[actionId];
        if (waiter) {
          waiter(returnValue);
          delete softphoneActionWaiters[actionId];
        }
      }

      if (ev.key === 'softphoneSettingsCmd' && ev.newValue) {
        if (!softphoneHere) {
          return;
        }

        const { cmd, args } = JSON.parse(ev.newValue);
        if (typeof cmd !== 'string' || !Array.isArray(args)) {
          console.error('Invalid softphoneSettingsCmd format: ', { cmd, args });

          return;
        }

        onSoftphoneSettingsCmd(cmd, args);
      }
      if (ev.key === 'softphoneSessionCmd' && ev.newValue) {
        if (!softphoneHere) {
          return;
        }

        const { cmd, args, sessionId } = JSON.parse(ev.newValue);
        if (
          typeof sessionId !== 'string' ||
          typeof cmd !== 'string' ||
          !Array.isArray(args)
        ) {
          console.error('Invalid softphoneSessionCmd format: ', {
            cmd,
            args,
            sessionId,
          });

          return;
        }

        onSoftphoneSessionCmd(sessionId, cmd, args);
      }
    };
    const syncFromMasterTabState = (state: MasterTabState) => {
      const softphoneState = softphoneFromTabData(state);
      if (!softphoneState) {
        return;
      }

      set({
        ...softphoneState,
        // Keep unchanged settings values, those are our proxied functions
        settings: { ...get().settings, ...softphoneState.settings },
        sessions: softphoneState.sessions.map((session) => {
          return makeSessionProxy(session);
        }),
      });
    };

    /**
     * Wait for softphone to be ready, in a state to start calls
     */
    const waitSoftphoneReady = async (): Promise<void> => {
      await softphoneInstance.softphone?.init();
      const softphoneStatus = softphoneInstance.softphone?.status;
      if (
        softphoneStatus === 'registering' ||
        softphoneStatus === 'connecting'
      ) {
        return new Promise((resolve) => {
          softphoneEvents.on('stateChange', (state) => {
            if (state === 'ready') {
              resolve();
            }
          });
        });
      }

      return Promise.resolve();
    };

    const state: SoftphoneStoreInterface = {
      init: reusePromise(async () => {
        const { deviceId } = get();
        if (!deviceId) {
          return;
        }
        const { masterTabId, tabId } = tabs.getState();
        softphoneHere = tabId === masterTabId;
        if (softphoneHere) {
          console.log('Softie here');
          unsubOwnChanges = subscribe((state) => {
            const tabState = tabs.getState();
            tabState.setOwnTab({
              ...tabState.getOwnTab(),
              softphone: pick(state, ['settings', 'sessions', 'status']),
            });
          });
        } else {
          console.log('Softie elsewhere');
          syncFromMasterTabState(tabs.getState());
          unsubTabChanges = tabs.subscribe((state, prevState) => {
            const curSoft = softphoneFromTabData(state);
            const prevSoft = softphoneFromTabData(prevState);
            if (isEqual(prevSoft, curSoft)) {
              syncFromMasterTabState(state);
            }
          });
          unsubMasterTabChanges = tabs.subscribe((state, prevState) => {
            if (state.masterTabId !== prevState.masterTabId) {
              get().deinit();
              get().init();
            }
          });
        }
        window.addEventListener('storage', storageEventListener);

        const [credentials, ice] = await Promise.all([
          getCredentials(deviceId),
          getIceConfig(),
        ]);

        if (softphoneHere) {
          const { default: Softphone } = await import('../softphone/softphone');
          const softphone = new Softphone({
            ice,
            credentials,
            dtmfMode: 'info',
            transferCode: '*2',
          });
          softphoneInstance.softphone = softphone;
          softphone.events.on('stateChange', (state) => {
            set({ status: state });
            softphoneEvents.emit('stateChange', state);
          });
          softphone.events.on('settingChange', (changes) => {
            set({
              settings: {
                ...get().settings,
                ...pickSettingsStateProps(changes),
              },
            });
          });
          softphone.events.on('sessionStart', (session) => {
            set({
              sessions: [...get().sessions, makeSessionProxy(session)],
            });
          });
          softphone.events.on('sessionUpdate', (changed) => {
            set({
              sessions: get().sessions.map((sess) => {
                if (sess.sessionId === changed.sessionId) {
                  return { ...sess, ...changed };
                }

                return sess;
              }),
            });
          });
          softphone.events.on('sessionEnd', (sessionId) => {
            const state = get();
            const current = getCurrentCall(state);
            const { preset, setPreset, lastPreset } = state.settings;

            if (sessionId === current?.sessionId) {
              // Current call is still in sessions list, so check if more than 1
              const hasOtherCalls = state.sessions.length > 1;
              console.log('Current call died', { hasOtherCalls });

              if (
                preset === 'handphone' &&
                !hasOtherCalls &&
                state.switchToSpeakerAfterHangup
              ) {
                setPreset(lastPreset ?? 'speaker');
              }
            }

            set({
              sessions: state.sessions.filter(
                (sess) => sess.sessionId !== sessionId
              ),
            });
          });
          softphone.events.on('error', (err) => {
            softphoneEvents.emit('error', err);
            localStorage.setItem('softphoneError', JSON.stringify(err));
            localStorage.removeItem('softphoneError');
          });

          // Sync settings from softphone's defaults
          console.log(
            'Getting softphone settings: ',
            softphone.settings,
            pickSettingsStateProps(softphone.settings)
          );
          set({
            settings: {
              ...get().settings,
              ...pickSettingsStateProps(softphone.settings),
            },
          });

          await softphone.init();

          (window as SoftphoneWindow).softphone = softphone;
        }
      }),
      deinit: () => {
        if (unsubOwnChanges) {
          unsubOwnChanges();
        }
        if (unsubTabChanges) {
          unsubTabChanges();
        }
        if (unsubMasterTabChanges) {
          unsubMasterTabChanges();
        }

        if (softphoneInstance.softphone) {
          softphoneInstance.softphone.deinit();
          softphoneInstance.softphone = null;
        }

        window.removeEventListener('storage', storageEventListener);
      },
      call: async (number: string, waitReady = false) => {
        softphoneEvents.emit('dial', number);

        if (waitReady) {
          await waitSoftphoneReady();
        }

        return doCall(number);
      },
      switchHere: proxySoftphoneMethod('switchHere'),
      settings: {
        toggleMute: proxySettingsMethod('toggleMute'),
        setAudioInputDevice: proxySettingsMethod('setAudioInputDevice'),
        setAudioOutputDevice: proxySettingsMethod('setAudioOutputDevice'),
        setRingDevice: proxySettingsMethod('setRingDevice'),
        setNoiseSupression: proxySettingsMethod('setNoiseSupression'),
        setEchoCancellation: proxySettingsMethod('setEchoCancellation'),
        setAvoidAudioProcessing: proxySettingsMethod('setAvoidAudioProcessing'),
        setInputVolume: proxySettingsMethod('setInputVolume'),
        setOutputVolume: proxySettingsMethod('setOutputVolume'),
        setRingVolume: proxySettingsMethod('setRingVolume'),
        setPreset: proxySettingsMethod('setPreset'),
        setPresets: proxySettingsMethod('setPresets'),

        echoCancellation: false,
        noiseSupression: false,
        avoidAudioProcessing: false,

        inputVolume: 100,
        outputVolume: 100,
        ringVolume: 100,

        audioInputDevice: null,
        audioOutputDevice: null,
        ringDevice: null,

        presets: {},
        preset: null,
        lastPreset: null,

        muted: false,
      },
      sessions: [],
      status: 'inactive',
      deviceId: null,
      switchToSpeakerAfterHangup: false,
    };

    return state;
  }
);

export function getCurrentCall(
  state: SoftphoneStoreInterface
): SessionInterface | null {
  const dialingStates = ['dialing', 'dialing+ringback'];

  return (
    state.sessions.find((call) => call.state === 'inCall') ??
    state.sessions.find((call) => call.state === 'ended') ??
    state.sessions.find((call) => dialingStates.includes(call.state)) ??
    state.sessions.find((call) => call.state === 'onHold') ??
    state.sessions.find((call) => call.state === 'ringing') ??
    null
  );
}

export function getCallWaitingForTransfer(
  state: SoftphoneInterface
): SessionInterface | null {
  return state.sessions.find((call) => call.waitingForAttendedTransfer) ?? null;
}
