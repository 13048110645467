import { ReactElement, ReactNode } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { CompanyFormData } from './types';

export interface CompanyFormWrapperProps {
  defaultValues?: Partial<CompanyFormData>;
  onSubmit: (formData: Partial<CompanyFormData>) => void;
  children?: ReactNode;
  formId?: string,
}

export const CompanyFormWrapper = ({
  onSubmit,
  defaultValues = {},
  children,
  formId,
}: CompanyFormWrapperProps): ReactElement => {
  const formMethods = useForm<CompanyFormData>({
    mode: 'onBlur',
    defaultValues: makeDefaults(defaultValues),
  });
  const { handleSubmit } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form data-ignore-form onSubmit={handleSubmit(onSubmit)} id={formId}>
        {children}
      </form>
    </FormProvider>
  );
};

type FormDefaults = DeepPartial<CompanyFormData>;
const makeDefaults = (form: FormDefaults): FormDefaults => ({
  ...form,
  addresses: form.addresses?.length ? form.addresses : [{}],
  phoneNumbers: form.phoneNumbers?.length ? form.phoneNumbers : [{}],
  emails: form.emails?.length ? form.emails : [{}],
});
