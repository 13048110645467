import { set } from 'lodash-es';
import { FetchedSettings } from './types';

export function isFetched(
  fetchedMap: FetchedSettings,
  settingKey: string[]
): boolean {
  return getFetched(fetchedMap, settingKey) === true;
}

export function setFetched(
  fetchedMap: FetchedSettings,
  settingKey: string[]
): FetchedSettings {
  if (!isFetched(fetchedMap, settingKey)) {
    return set({ ...fetchedMap }, settingKey, true);
  }

  return fetchedMap;
}

type FetchedResult = boolean | FetchedSettings;

function getFetched(
  fetchedMap: FetchedSettings,
  settingKey: string[]
): FetchedResult {
  for (const keySeg of settingKey) {
    const val = fetchedMap[keySeg];

    // No key, not fetched
    if (!val) {
      return false;
    }

    // Key is true, we fetched this and everything under it
    if (val === true) {
      return true;
    }

    // Prepare next segment
    fetchedMap = val;
  }

  return fetchedMap;
}
