import { ReactElement, ReactNode } from 'react';
import {
  FormControl, FormLabel,
} from '@chakra-ui/react';
import { VolumeSlider, VolumeSliderProps } from './VolumeSlider';

interface VolumeInputProps extends VolumeSliderProps {
    label: string;
    icon?: ReactNode;
}

export const VolumeInput = ({ label, icon, ...sliderProps }: VolumeInputProps): ReactElement => (
  <FormControl mb="3">
    <FormLabel display="flex" alignItems="center" htmlFor={sliderProps.id} mb="0">
      {icon && <>{icon}&nbsp;</>}
      {label}: <strong>{sliderProps.value}%</strong>
    </FormLabel>
    <VolumeSlider {...sliderProps} />
  </FormControl>
);
