import { memo, ReactElement } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props {
  count: number | null;
}

export const TableCountFooter = memo(({ count }: Props): ReactElement => {
  const { t } = useTranslation('global');

  return (
    <Flex>
      <Box px={4} ml="auto">
        {count !== null ? (
          <Text as="span">
            {t('global:total_data_count')}:
            <Text as="span" fontWeight="bold">
              {count}
            </Text>
          </Text>
        ) : (
          <>...</>
        )}
      </Box>
    </Flex>
  );
});
TableCountFooter.displayName = 'TableCountFooter';
