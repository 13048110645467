import { ReactElement } from 'react';
import { Box, Checkbox, HStack, Tag, Text, Tooltip } from '@chakra-ui/react';

import { formatNumber } from 'app/assets/js/number-util';

import { ImportColumnConfig, PreviewOk, PreviewResult } from '../types';

export interface PreviewCellProps {
  value: PreviewResult;
  column: ImportColumnConfig;
}

export const PreviewCell = ({
  value,
  column,
}: PreviewCellProps): ReactElement => {
  if (value.status === 'error') {
    return (
      <Text as="span" color="red">
        {value.error}
      </Text>
    );
  }

  switch (column.type) {
    case 'text':
      return <Text as="span">{String(value.value)}</Text>;
    case 'number':
      return <Box textAlign="right">{String(value.value ?? '-')}</Box>;
    case 'phone':
      return <PhonePreviewCell {...value} />;
    case 'email':
      return <EmailPreviewCell {...value} />;
    case 'bool':
      return <BoolPreviewCell {...value} />;
    default:
      return <Text as="span">{JSON.stringify(value.value)}</Text>;
  }
};

export const BoolPreviewCell = (value: PreviewOk): ReactElement => {
  if (value.value === null) {
    return <>-</>;
  }

  if (typeof value.value !== 'boolean') {
    throw new Error('Bool value should be a boolean');
  }

  return <Checkbox isChecked={value.value === true} />;
};

export const EmailPreviewCell = (value: PreviewOk): ReactElement => {
  if (!value.value) {
    return <>-</>;
  }

  if (!Array.isArray(value.value)) {
    throw new Error('Phone numbers value should be an array');
  }

  return (
    <>
      {value.value.map((email) => (
        <Tag key={email}>{formatNumber(email)}</Tag>
      ))}
    </>
  );
};

export const PhonePreviewCell = (value: PreviewOk): ReactElement => {
  if (!value.value) {
    return <>-</>;
  }

  if (!Array.isArray(value.value)) {
    throw new Error('Phone numbers value should be an array');
  }

  return (
    <HStack>
      {value.value.map((phone) => (
        <Tooltip label={phone} key={phone}>
          <Tag>{formatNumber(phone)}</Tag>
        </Tooltip>
      ))}
    </HStack>
  );
};
