import { FormMode } from 'BootQuery/Assets/js/globalTypes';

import { patchCampaign } from '../../api/Campaign/patchCampaign';
import { postCampaign } from '../../api/Campaign/postCampaign';
import {
  Campaign,
  FormCampaign,
  PatchCampaign,
  PostCampaign,
} from '../../schemas/Campaign';

export const handleCampaignFormSubmit = async (
  data: FormCampaign,
  mode: FormMode,
  campaignID: number | null
): Promise<Campaign> => {
  if (mode === 'edit' && campaignID) {
    return patchCampaign(campaignID, toPatchData(data), { fields: ['$full'] });
  }
  if (mode === 'edit' && !campaignID) {
    throw Error('Tried to patch campaign without ID');
  }

  return postCampaign(toPostData(data), { fields: ['$full'] });
};

const toPostData = (data: FormCampaign): PostCampaign => ({
  caseTypeID: data.caseType.ID,
  name: data.name,
});

const toPatchData = (data: FormCampaign): PatchCampaign => ({
  name: data.name,
});
