import { ReactElement } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { FaEdit, FaTrash, FaUndo } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';

import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';
import { IconButton } from '@bq/components/IconButton';
import { ChangeBadge } from '@bq/components/ChangeBadge';

import { useModal } from '@bq/components/UseModal/use-modal';
import { Template } from './types';
import { TemplateModal } from './TemplateModal';

interface Props {
  template: WithChange<Template>;
  modify: SettingsMethods<Template>;
}

export const TemplateItem = ({ template, modify }: Props): ReactElement => {
  const { t } = useTranslation('Sales');
  const { addModal } = useModal();

  return (
    <HStack w="full">
      <HStack key={template.ID} w="full">
        <HStack w="full">
          <Box
            fontWeight="bold"
            mr={2}
            textDecor={template.change === 'del' ? 'line-through' : 'none'}
          >
            {template.name}
          </Box>
          <ChangeBadge type={template.change} />
        </HStack>
        <HStack>
          {template.change && (
            <IconButton
              label={t('global:undo_changes')}
              icon={<FaUndo/>}
              variant="ghost"
              size="sm"
              onClick={() => {
                modify.undo(template.ID);
              }}
            />
          )}
          <IconButton
            label={t('global:edit')}
            icon={<FaEdit />}
            variant="ghost"
            size="sm"
            onClick={() => {
              addModal({
                children: <TemplateModal mode="edit" data={template} />,
                preventClose: {
                  defaultPreventState: false,
                },
                componentProps: {
                  modal: { size: '6xl' },
                },
                callback: modify.upd,
              });
            }}
          />
          <IconButton
            label={t('global:delete')}
            isDisabled={template.change === 'del'}
            size="sm"
            icon={<FaTrash />}
            variant="ghost"
            colorScheme="red"
            onClick={() => {
              modify.del(template.ID);
            }}
          />
        </HStack>
      </HStack>
    </HStack>
  );
};
