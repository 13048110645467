import { FaHighlighter } from 'react-icons/fa';
import { useRichText } from '../../RichtextContex';
import { RichTextColor } from '../RichTextColor';

export const HighlightColor = () => {
  const { editor } = useRichText();
  const onInput = (color: string | null) => {
    editor
      ?.chain()
      .focus()
      .toggleHighlight({ color: color ?? '#FFF' })
      .run();
  };

  const clearHighlight = () => {
    editor
      ?.chain()
      .focus()
      .toggleHighlight()
      .run();
  };

  return (
    <RichTextColor
      key="higlight"
      clearable={true}
      icon={<FaHighlighter />}
      property="highlight"
      inputHandler={onInput}
      clearHandler={clearHighlight}
      defaultValue="rgba(0, 0, 0, 0)"
    />
  );
};
