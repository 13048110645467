import { useMemo } from 'react';
import { Column } from './Table';

interface SortableColumns {
  name: string;
  sort: string;
}
export const useSortableColumns = <T>(
  columns: Column<T>[]
): SortableColumns[] => {
  return useMemo(() => {
    return columns.reduce((all: SortableColumns[], current) => {
      if (current.sort) {
        return [
          ...all,
          {
            name: current.title ?? current.key,
            sort: current.sort,
          },
        ];
      }

      return all;
    }, []);
  }, [columns]);
};
