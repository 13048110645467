import { FilterTypes } from '@bq/components/FilterBar';

import { assignedToUserFilter } from './assigned-to-filter';
import { campaignFilter } from './campaign-filter';
import { caseTypeFilter } from './case-type-filter';
import { createdAtFilter } from './created-at-filter';
import { createdByUserFilter } from './created-by-filter';
import { idFilter } from './id-filter';
import { interestLevelFilter } from './interest-level-filter';
import { nameFilter } from './name-filter';
import { priorityFilter } from './priority-filter';
import { stateFilter } from './state-filter';
import { tagsFilter } from './tags-filter';
import { updatedAtFilter } from './updated-at-filter';

export const caseFilterTypes: FilterTypes = {
  ID: idFilter,
  name: nameFilter,
  /** TODO: subject: filter */
  createdAt: createdAtFilter,
  updatedAt: updatedAtFilter,
  createdByUser: createdByUserFilter,
  assignedToUser: assignedToUserFilter,
  caseType: caseTypeFilter,
  tags: tagsFilter,
  campaign: campaignFilter,
  state: stateFilter,
  priority: priorityFilter,
  interestLevel: interestLevelFilter,
  /** TODO: special filters */
};
