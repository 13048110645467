module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal fade primary-modal\" tabindex=\"-1\" aria-hidden=\"true\" data-refferer=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalReferrer") : depth0), depth0))
    + "\" data-modal-target=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalTarget") : depth0), depth0))
    + "\">\n  <div class=\"modal-dialog\" style=\"width: 90%; max-width: none;\">\n    <div class=\"modal-content "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalClass") : depth0), depth0))
    + " embedable\">\n      <div class=\"modal-header "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalHeadingClass") : depth0), depth0))
    + "\">\n\n        <div class=\"modal-title\">"
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"modalHeading") : depth0), depth0)) != null ? stack1 : "")
    + "</div>\n      </div>\n      <div class=\"modal-body "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalBodyClass") : depth0), depth0))
    + "\">\n        "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"modalBody") : depth0), depth0)) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"modal-footer "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"modalFooterClass") : depth0), depth0))
    + "\">\n        "
    + ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"modalFooter") : depth0), depth0)) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true})