import { ReactElement } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Select, SelectOption } from '@bq/components/SelectWrappers';
import { useNumberTypes } from 'app/Modules/Phonebook/Assets/components/use-number-types';

import { CallFormForm } from './types';

export const NumberTypeField = (): ReactElement => {
  const { control } = useFormContext<CallFormForm>();
  const {
    field: { value, onChange },
  } = useController({ control, name: 'numberType' });

  const options = useNumberTypeOptions();
  if (!options) {
    return <>...</>;
  }

  return (
    <Select
      isClearable={false}
      options={options}
      value={value}
      onChange={onChange}
      size="sm"
    />
  );
};

function useNumberTypeOptions(): SelectOption<number>[] | null {
  const numberTypes = useNumberTypes();
  if (!numberTypes) {
    return null;
  }

  return numberTypes.map((numberType) => ({
    value: numberType.ID,
    label: numberType.name,
  }));
}
