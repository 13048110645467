import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { CreateCompanyModalProps } from './types';
import { CompanyFormFields, CompanyFormWrapper } from '../CompanyForm';

const formId = 'phonebook-create-company';

export const CreateCompanyModal = ({
  editing,
  onClose,
  onSave,
  formState,
}: CreateCompanyModalProps): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <Modal
      size="6xl"
      scrollBehavior="inside"
      isOpen={editing !== null}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FaPlus style={{ display: 'inline' }} />
            &nbsp;
          {t('Phonebook:create_company')}
        </ModalHeader>
        <ModalBody>
          {editing && (
            <CompanyFormWrapper
              formId={formId}
              defaultValues={editing}
              onSubmit={onSave}
            >
              <CompanyFormFields />
            </CompanyFormWrapper>
          )}
        </ModalBody>
        <ModalFooter>
          <FormActions form={formId} state={formState} onCancel={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
