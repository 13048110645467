import { ReactElement } from 'react';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { PhoneNumberList } from '../PhoneNumberList';

interface Props<RowType> {
  getCellValue: (row: RowType, rowIdx: number) => string[] | null | undefined;
}

export function makePhoneNumbersCell<RowType>({
  getCellValue,
}: Props<RowType>) {
  const PhoneNumbersCell = ({
    row,
    rowIdx,
  }: CellProps<RowType>): ReactElement => {
    const value = getCellValue(row, rowIdx) ?? [];

    return <PhoneNumberList phoneNumbers={value} />;
  };
  PhoneNumbersCell.displayName = 'PhoneNumbersCell';

  return PhoneNumbersCell;
}
