import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { IOverviewItem } from 'BootQuery/Assets/components/Overviews';

import { usePhonebookOverviews } from './overviews';
import { PhonebookOverviews } from './types';
import { squashOverviews } from './utils';

type RouterParams = {
  overview?: string;
};

type List = 'people' | 'companies' | 'companyLocations' | 'employees';

export function useSelectedOverview(list: List): IOverviewItem | null {
  const { overview } = useParams<RouterParams>();
  const { data: overviews } = usePhonebookOverviews();

  const squashedOverviewsAll: PhonebookOverviews | null = useMemo(() => {
    if (overviews) {
      return {
        people: squashOverviews(overviews.people),
        companies: squashOverviews(overviews.companies),
        companyLocations: squashOverviews(overviews.companyLocations),
        employees: squashOverviews(overviews.employees),
      };
    }

    return null;
  }, [overviews]);

  if (!squashedOverviewsAll || !overview) {
    return null;
  }
  if (
    list !== 'people' &&
    list !== 'companies' &&
    list !== 'companyLocations' &&
    list !== 'employees'
  ) {
    return null;
  }

  const listOverviews = squashedOverviewsAll[list] ?? [];

  return listOverviews.find((ov) => ov.slug === overview) ?? null;
}
