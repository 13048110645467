import { useCallback } from 'react';
import { ButtonGroup } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';

import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import { DeleteButton } from '@bq/components/DeleteButton';
import { CellProps } from '@bq/components/Table';

import { DatasetEntryListItem } from '../schema';
import { USE_DATASET_ENTRIES_KEY } from '../use-dataset-entries';

// import { useDeleteDataset } from '../use-delete-dataset';

export const ActionsCell = ({ row }: CellProps<DatasetEntryListItem>) => {
  const queryClient = useQueryClient();
  const confirmAction = useConfirmationModal();
  // const { mutateAsync } = useDeleteDataset();

  const deleteAction = useCallback(() => {
    confirmAction({
      onConfirm: async () => {
        // await mutateAsync(row.ID);
        queryClient.refetchQueries({
          queryKey: [USE_DATASET_ENTRIES_KEY, row.datasetID],
        });
      },
    });
  }, [confirmAction, row, queryClient]);

  return (
    <ButtonGroup size="sm" zIndex="3" float="right">
      <DeleteButton variant="ghost" onClick={deleteAction} />
    </ButtonGroup>
  );
};
