import { ModalBody } from '@chakra-ui/react';

import { CaseBulkFormFields } from './CasesBulkFormFields';

export const CasesBulkFormModalBody = () => {
  return (
    <ModalBody>
      <CaseBulkFormFields />
    </ModalBody>
  );
};
