import { ReactElement } from 'react';
import { Flex, HTMLChakraProps } from '@chakra-ui/react';

import { formatNumber } from 'app/assets/js/tsutil';
import { PopoverLink } from 'BootQuery/Assets/components/PopoverTrigger';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

interface NumberListProps {
  phoneNumbers: string[];
  fontSize?: HTMLChakraProps<'a'>['fontSize'];
}

export const PhoneNumberList = ({
  phoneNumbers,
  fontSize,
}: NumberListProps): ReactElement => (
  <Flex zIndex={3}>
    <WithSeparator separator=",&nbsp;">
      {phoneNumbers.map((phoneNumber) => (
        <PopoverLink
          fontSize={fontSize}
          fontWeight="normal"
          as="a"
          key={phoneNumber}
          href={`tel:${phoneNumber}`}
          title={phoneNumber}
        >
          {formatNumber(phoneNumber)}
        </PopoverLink>
      ))}
    </WithSeparator>
  </Flex>
);
