import { UseQueryResult } from '@tanstack/react-query';

import { UseListingSettingsReturn } from '@bq/components/ListingSettings';
import { sortValueToApiSort } from 'BootQuery/Assets/js/sort-value-to-api-sort';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';

import {
  listDatasetEntries,
  ListDatasetEntriesResponse,
} from '../Api/dataset-entries';
import { DatasetEntryListItem } from './schema';

export function useDatasetEntries(
  datasetID: number,
  { filters, limit, page, sort }: UseListingSettingsReturn<DatasetEntryListItem>
): UseQueryResult<ListDatasetEntriesResponse> {
  return useDebouncedQuery({
    queryKey: [USE_DATASET_ENTRIES_KEY, datasetID, filters, limit, page, sort],
    queryFn: () => {
      return listDatasetEntries(datasetID, {
        filters,
        limit,
        page,
        sort: sortValueToApiSort(sort),
      });
    },
    enabled: filters !== undefined,
  });
}

export const USE_DATASET_ENTRIES_KEY = 'Datasets.datasetEntries';
