import { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { CallActionsMenu } from '../CallList/CallActionsMenu';
import { CallDetailsModal } from '../CallList/CallDetailsModal';
import { CallRecordingIndicators } from '../CallList/CallRecordingIndicators';
import { CallTickets } from '../CallList/CallTickets';
import { CallListCall } from '../CallList/types';

interface Props {
  call: CallListCall;
}

export const CallActions = ({ call }: Props): ReactElement => {
  const hasTicketing = 'Ticketing' in window.Bootstrap.modules;

  return (
    <Flex>
      <CallRecordingIndicators call={call} />
      <Box ml="2">
        {hasTicketing && <CallTickets call={call} />}
        <CallActionsMenu call={call} />
        <CallDetailsModal call={call} />
      </Box>
    </Flex>
  );
};
