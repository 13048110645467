import { useEffect, useMemo } from 'react';
import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  SelectOption,
  SelectOptionWithAdditionalData,
} from '@bq/components/Select';
import { Select } from '@bq/components/SelectWrappers';
import { useUnits } from 'app/Modules/Products/Assets/js/api';
import {
  BaseUnitGroup,
  useTranslateBaseUnitGroups,
} from 'app/Modules/Products/Assets/js/use-translate-base-unit-groups';

import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

type UnitGroupOption = SelectOptionWithAdditionalData<string, { key: string }>;

export const UnitField = (_props: FieldProps) => {
  const { data } = useUnits();
  const { control, watch, setValue } =
    useFormContext<Partial<ItemBaseFields>>();
  const { t } = useTranslation('Products');
  const unitGroupTranslations = useTranslateBaseUnitGroups();
  const unitGroups: UnitGroupOption[] = useMemo(() => {
    if (data) {
      return Object.keys(data).map((item) => ({
        value: item,
        label: unitGroupTranslations[item as BaseUnitGroup] ?? item,
        key: item,
      }));
    }

    return [];
  }, [data, unitGroupTranslations]);
  const amountUnitGroup = watch('amountUnitGroup');
  const units = useGroupUnitOptions(amountUnitGroup?.key);

  const { field: unitGroupField } = useController<
    Partial<ItemBaseFields>,
    'amountUnitGroup'
  >({
    control,
    name: 'amountUnitGroup',
  });
  const { field: defaultUnitField } = useController<
    Partial<ItemBaseFields>,
    'defaultAmountUnit'
  >({
    control,
    name: 'defaultAmountUnit',
  });

  useEffect(() => {
    setValue('defaultAmountUnit', undefined);
  }, [setValue, unitGroupField.value]);

  return (
    <SimpleGrid columns={[1, 1, 2, 2]} gridGap={4} w="full">
      <FormControl>
        <FormLabel htmlFor="amountUnitGroup">
          {t('Products:amount_unit')}
        </FormLabel>
        <Select<string, { key: string }>
          size={['md', 'md', 'sm']}
          options={unitGroups}
          onChange={(val) => {
            if (val) {
              unitGroupField.onChange(val);
            }
          }}
          value={
            unitGroupField.value
              ? {
                  value: unitGroupField.value.key,
                  label:
                    unitGroupTranslations[
                      unitGroupField.value.key as BaseUnitGroup
                    ] ?? unitGroupField.value,
                  key: unitGroupField.value.key,
                }
              : undefined
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel htmlFor="defaultAmountUnit">
          {t('Products:default_amount_unit')}
        </FormLabel>
        <Select
          queryKey={amountUnitGroup?.key}
          size={['md', 'md', 'sm']}
          options={units}
          isDisabled={!amountUnitGroup}
          onChange={(val) => {
            if (val) {
              defaultUnitField.onChange({ symbol: val.value });
            }
          }}
          value={
            defaultUnitField.value
              ? {
                  value: defaultUnitField.value.symbol,
                  label: defaultUnitField.value.symbol,
                }
              : undefined
          }
        />
      </FormControl>
    </SimpleGrid>
  );
};

function useGroupUnitOptions(group?: string): SelectOption[] {
  const { data: units } = useUnits();

  return useMemo(() => {
    if (!units || !group) {
      return [];
    }

    if (units[group]) {
      return Object.values(units[group].units).map(({ symbol }) => ({
        label: symbol,
        value: symbol,
      }));
    }

    return [];
  }, [group, units]);
}
