import phpToMoment from 'BootQuery/Assets/js/phpToMoment';
import moment from 'moment';

export default {
  install(Vue, _options) {
    Vue.mixin({
      filters: {
        formatDate(value, format) {
          if (!value) {
            return null;
          }
          return moment(value).format(phpToMoment(format));
        },
      },
    });
  },
};
