import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { CaseInterestLevel } from '../../schemas';

export const getInterestLevels = async (params?: GetListingParams) => {
  const { data } = await Api.get<ListingResponse<CaseInterestLevel>>(
    '/api/sales/interestLevels',
    {
      params,
    }
  );

  return data;
};
