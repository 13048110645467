import { memoize } from 'lodash-es';
import { FaComments, FaEnvelope, FaListAlt, FaPhone } from 'react-icons/fa';

import { FilterTypes } from 'BootQuery/Assets/components/FilterBar';
import { makeComboboxFilter } from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import {
  makeUserFilter,
  userFilterIDExpr,
} from 'BootQuery/Assets/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

import { getCustomEventTypes } from '../api';
import { EventTypeItem } from './EventTypeItem';
import { EventType } from './types';

const user = makeUserFilter({
  name: () => i18n.t('Events:filters.user'),
  toFilter({ value }) {
    if (!value || typeof value === 'string') {
      return null;
    }

    const userID = userFilterIDExpr(value);

    return { userID };
  },
});

const getEventTypes = memoize(async (): Promise<EventType[]> => {
  const customEventTypes = await getCustomEventTypes();

  return [
    ...customEventTypes
      .filter((type) => !type.deletedAt)
      .map((type) => ({
        label: type.name,
        value: `custom-${type.ID}`,
      })),
    { label: i18n.t('Telephony:call'), value: 'call', icon: FaPhone },
    { label: i18n.t('Mails:email'), value: 'mail', icon: FaEnvelope },
    { label: i18n.t('Ticketing:ticket'), value: 'ticket', icon: FaListAlt },
    {
      label: i18n.t('CustomerChat:chat'),
      value: 'customerChat',
      icon: FaComments,
    },
  ];
});

const type = makeComboboxFilter<EventType, { type: string }>({
  name: () => i18n.t('Events:filters.type'),
  toFilter({ value }) {
    if (!value || typeof value === 'string') {
      return null;
    }

    return {
      type: value.value,
    };
  },
  operators: [],
  extraProps: {
    cacheKey: 'eventType',
    itemToString: (item) => item.label,
    async valueToItem(value) {
      const eventTypes = await getEventTypes();
      const type = eventTypes.find((type) => type.value === value.type);
      if (!type) {
        throw new Error(`Unknown event type ${value}`);
      }

      return type;
    },
    itemToValue(item) {
      return { type: item.value };
    },
    async search(searchStr) {
      const search = searchStr.toLowerCase();
      const eventTypes = await getEventTypes();

      return eventTypes.filter((type) => {
        return type.value.toLowerCase().includes(search);
      });
    },
    renderItem: EventTypeItem,
    enableTextSearch: false,
  },
});

export const filterTypes: FilterTypes = {
  user,
  type,
};
