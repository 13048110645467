import { lazy, ReactElement, ReactNode, Suspense } from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { getContactInfo } from 'app/Modules/Phonebook/Assets/js/contact-info';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';
import { PopoverButton } from 'BootQuery/Assets/components/PopoverTrigger';

import { Portal } from '../Portal';

interface ContactDisplayProps {
  contactID: number;
  contactType: ContactType;
  contactName: string;
  children?: ReactNode;
  valueFontWeight?: string | undefined;
  zIndex?: number;
  fontSize?: string;
}

function useContactInfo(ID: number, type: ContactType) {
  return useQuery({
    queryKey: ['contactInfo', type, ID],
    queryFn: () => getContactInfo(type, ID),
  });
}

const ContactDetails = lazy(() => import('./ContactDetails'));

const ContactPopoverContent = ({
  contactID,
  contactType,
  children,
}: Omit<ContactDisplayProps, 'contactName'>): ReactElement => {
  const { data: contactInfo } = useContactInfo(contactID, contactType);

  return (
    <Suspense fallback={<LoaderScale />}>
      {contactInfo && (
        <ContactDetails contact={contactInfo}>{children}</ContactDetails>
      )}
    </Suspense>
  );
};

export const SimpleContactDisplay = ({
  contactID,
  contactType,
  contactName,
  children,
  valueFontWeight,
  zIndex = 3,
  fontSize = 'sm',
}: ContactDisplayProps): ReactElement => (
  <Popover isLazy>
    <PopoverTrigger>
      <PopoverButton
        valueFontWeight={valueFontWeight}
        userSelect="text"
        zIndex={zIndex}
        fontSize={fontSize}
      >
        {contactName}
      </PopoverButton>
    </PopoverTrigger>
    <Portal>
      <FixedPopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody>
          <ContactPopoverContent
            contactID={contactID}
            contactType={contactType}
          >
            {children}
          </ContactPopoverContent>
        </PopoverBody>
      </FixedPopoverContent>
    </Portal>
  </Popover>
);
