import { forwardRef, memo } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Optional } from 'app/assets/js/tsutil';
import { useTranslation } from 'react-i18next';
import { Button } from '@chakra-ui/react';
import { IconButtonProps } from './IconButton';

type AddButtonProps = Optional<IconButtonProps, 'label' | 'icon'>;

// eslint-disable-next-line react/display-name
const AddButtonInner = forwardRef<HTMLButtonElement, AddButtonProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <Button
        rightIcon={<FaPlus />}
        colorScheme="green"
        variant="solid"
        {...props}
        ref={ref}
      >
        {t('global:add')}
      </Button>
    );
  }
);

export const AddButton = memo(AddButtonInner);
