import { makeCheckboxCell } from '@bq/components/Cells/CheckboxCell';
import { makeEmailsCell } from '@bq/components/Cells/EmailsCell';
import { makePhoneNumbersCell } from '@bq/components/Cells/PhoneNumbersCell';
import { Column } from '@bq/components/Table';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';

import { ImportColumnConfig } from '../types';
import { DatasetEntryListItem } from './schema';
import { EmailList, PhoneList } from './types';

export function datasetFieldToColumn(
  columnConfig: ImportColumnConfig
): Column<DatasetEntryListItem> {
  switch (columnConfig.type) {
    case 'text':
      return {
        key: `$d-text-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => row.data[columnConfig.key],
      };

    case 'number':
      return {
        key: `$d-number-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => row.data[columnConfig.key],
      };

    case 'bool':
      return {
        key: `$d-bool-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => row.data[columnConfig.key],
        Cell: makeCheckboxCell({
          getCellValue: (row) => row.data[columnConfig.key],
        }),
      };

    case 'date':
      return {
        key: `$d-date-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => {
          return formatDate(row.data[columnConfig.key], DateType.Date);
        },
      };

    case 'datetime':
      return {
        key: `$d-datetime-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => {
          return formatDate(row.data[columnConfig.key], DateType.DateTime);
        },
      };

    case 'phone':
      return {
        key: `$d-phone-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => row.data[columnConfig.key],
        Cell: makePhoneNumbersCell({
          getCellValue: (row) => {
            const items: PhoneList | null = row.data[columnConfig.key];
            if (!items) {
              return [];
            }

            return items.map((item) => item.phone);
          },
        }),
      };

    case 'email':
      return {
        key: `$d-email-${columnConfig.key}`,
        title: columnConfig.title,
        getValue: ({ row }) => row.data[columnConfig.key],
        Cell: makeEmailsCell({
          getCellValue: (row) => {
            const items: EmailList | null = row.data[columnConfig.key];
            if (!items) {
              return [];
            }

            return items.map((item) => item.email);
          },
        }),
      };

    default:
      throw new Error('Unknown column type');
  }
}
