import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Option } from '@bq/components/ButtonRadio';

export function useLocationTypes(): Option[] {
  const { t } = useTranslation('Events');

  return useMemo(
    () => [
      { title: t('Events:location_type.unknown'), id: 'unknown' },
      { title: t('Events:location_type.in_person'), id: 'inPerson' },
      { title: t('Events:location_type.video_call'), id: 'videoCall' },
      { title: t('Events:location_type.phone_call'), id: 'phoneCall' },
    ],
    [t]
  );
}

export function useLocationTypeTitle(id: string): string {
  const types = useLocationTypes();
  const type = types.find((type) => type.id === id);

  return type?.title ?? id;
}
