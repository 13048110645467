import { Api } from 'BootQuery/Assets/js/api';

interface SendResponse {
  sentID: number;
}

// TODO: maybe clarify types if we don't port to new mails soon
// this data comes from non-TS code anyway
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function submitEditor(submitted: any): Promise<SendResponse> {
  const {
    data: { sentID },
  } = await Api.post<SendResponse>('/api/mails/sendMessage', {
    accountID: submitted.accountID,
    fromDisplayName: submitted.accountInfo.formattedName,
    subject: submitted.subject,
    to: submitted.to,
    cc: submitted.cc,
    bcc: submitted.bcc,
    content: submitted.content,
    text: submitted.textContent,
    html: submitted.htmlContent,
    attachments: submitted.attachments,
    priority: submitted.priority,
    inReplyToMessageID: submitted.inReplyToMessageID,
    forwardMessageID: submitted.forwardMessageID,
    quotePrevious: submitted.quotePrevious,
  });

  return { sentID };
}
