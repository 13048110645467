import { makeTimeFilter } from '@bq/components/FilterBar/filters/TimeFilter';
import { CustomData } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { TimeIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { TimeField } from './TimeField';

export const timeField = (): FieldType => ({
  type: 'time',
  name: i18n.t('global:form_editor.field_time'),
  icon: TimeIcon,
  components: {
    FormField: TimeField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
  }),
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        sort: `data.${field.id}`,
        getValue: ({ row }) => {
          if (!row.data) {
            return '-';
          }

          const value = (row.data as CustomData)[field.id];
          if (!value) {
            return '-';
          }

          return value;
        },
      },
    ];
  },
  getFilters(field) {
    return {
      [`$custom-${field.id}`]: makeTimeFilter({
        name: field.settings.name,
        toFilter: ({ operator, value }) => {
          if (!value) {
            return null;
          }

          return {
            $and: [
              [`data.${field.id}`, operator, value],
              // Prevent showing empty strings for lte operator
              [`data.${field.id}`, 'neq', ''],
            ],
          };
        },
      }),
    };
  },
});
