import {
  ApiContactCategory,
  CategoryContactValue,
  ContactCategory,
  ContactItem,
} from '../../HopsOperator/HopsContacts/types';
import { getFixedSizeContacts } from '../../HopsOperator/HopsContacts/util';

export function categoryForSave(category: ContactCategory): ApiContactCategory {
  return {
    id: category.id,
    name: category.name,
    color: category.color,
    contacts: minContactPages(category.contacts).map(contactForSave),
    groups: category.groups,
  };
}

function contactForSave(
  contact: ContactItem | null
): CategoryContactValue | null {
  if (contact?.contact) {
    return {
      ID: contact.contact.ID,
      type: contact.contact.type,
      color: contact.color ?? null,
    };
  }

  return null;
}

/**
 * Find last non-null contact item
 */
function minContactPages(
  contacts: (ContactItem | null)[]
): (ContactItem | null)[] {
  // Start iterating from back and record index of last contact, stop after that.
  // If array empty, value will stay 0
  let lastContactIdx = 0;

  for (let i = contacts.length - 1; i >= 0; i--) {
    const item = contacts[i];
    // Check that there is an actual contact, not just leftover color value from editing
    if (item?.contact) {
      lastContactIdx = i;
      break;
    }
  }

  return getFixedSizeContacts(contacts.slice(0, lastContactIdx + 1));
}
