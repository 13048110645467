import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const createdAtFilter = makeDateFilter({
  name: () => i18n.t('global:item_crud_dates.created_at'),
  toFilter: makeDateToFilter('createdAt'),
});
