import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  CustomFormDefinitionResult,
  DefinitionType,
  getCustomFormDefinition,
} from './api';
import { FieldValue } from './types';

type QueryOptions = Omit<
  UseQueryOptions<
    CustomFormDefinitionResult,
    unknown,
    CustomFormDefinitionResult,
    QueryKey
  >,
  'queryKey' | 'queryFn' | 'initialData'
>;

export function useFormDefinition(
  name: string,
  mode: DefinitionType = 'use',
  options: QueryOptions = {}
): FieldValue[] | null {
  const { data: definition } = useQuery<
    CustomFormDefinitionResult,
    unknown,
    CustomFormDefinitionResult,
    QueryKey
  >({
    ...options,
    queryKey: [`useCustomFormDefinition:${name}:${mode}`],
    queryFn: () => getCustomFormDefinition(name, mode),
  });

  return definition?.data ?? null;
}
