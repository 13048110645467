import { ReactElement } from 'react';

import { useFormDefinition } from '@bq/components/FormEditor';
import { LoadingPage } from '@bq/components/LoadingPage';
import { PageWrapper } from '@bq/components/PageWrapper';
import { useNumericPathParam } from 'app/assets/js/use-path-param';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

import { DatasetEntryCreateForm } from '../components/DatasetEntryForm';
import { useDataset } from '../components/DatasetForm/use-dataset';

export const DatasetEntryCreate = (): ReactElement => {
  const datasetID = useNumericPathParam('datasetID');

  const { data: dataset } = useDataset(datasetID);
  const formDefinition = useFormDefinition(`Datasets.${datasetID}`);

  const datasetTitle = dataset?.name ?? '...';
  useAppTitle(`Create | ${datasetTitle} | Datasets`);

  return (
    <PageWrapper>
      {dataset && formDefinition ? (
        <DatasetEntryCreateForm
          dataset={dataset}
          formDefinition={formDefinition}
        />
      ) : (
        <LoadingPage />
      )}
    </PageWrapper>
  );
};
