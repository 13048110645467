module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!DOCTYPE html>\n<html lang=\"en\">\n<head>\n    <meta charset=\"utf-8\">\n	<meta name=\"viewport\" content=\"width=device-width, initial-scale=1\">\n	<link id=\"favicon\" rel=\"icon\" type=\"image/png\" href=\"/app/assets/img/logo_ast.png\">\n	<title>"
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"appTitle")), depth0))
    + "</title>\n\n    <script nonce=\""
    + alias2(alias1(((stack1 = ((stack1 = (data && lookupProperty(data,"root"))) && lookupProperty(stack1,"bootquery"))) && lookupProperty(stack1,"cspNonce")), depth0))
    + "\">\n        if (window.localStorage) {\n            window.localStorage.setItem('login', 'true');\n            window.localStorage.removeItem('login');\n        }\n    </script>\n</head>\n<body>Logged in...</body>\n</html>";
},"useData":true})