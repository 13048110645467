import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

/** This is basically updated at so don't be confused by the updated at translation! */
/** The first versionCreatedAt would be the equivalent of createdAt */
export const createdAtFilter = makeDateFilter({
  name: () => i18n.t('global:item_crud_dates.created_at'),
  toFilter: makeDateToFilter('firstVersionCreatedAt'),
});
