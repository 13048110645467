import { getCompanyFormData } from 'app/Modules/Phonebook/Assets/components/CompanyForm';
import { useQuery } from '@tanstack/react-query';
import { LoadingModal } from '@bq/components/UseModal/LoadingModal';
import { CompanyModalContent } from './CompanyModalContent';

export const EditCompanyModalContent = ({ ID }: { ID: number }) => {
  const { data } = useQuery({
    queryKey: ['EditCompanyModal', ID],
    queryFn: () => {
      return getCompanyFormData(ID.toString());
    },
  });

  if (!data) {
    return <LoadingModal />;
  }

  return <CompanyModalContent mode="edit" data={data} companyID={ID} />;
};
