import {
  getDefaultComboboxOperators,
  makeComboboxFilter,
} from '@bq/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { CaseType } from '../../schemas';

export const caseTypeFilter = makeComboboxFilter({
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return { [`caseType.name:${containsOp}:ci`]: value };
    }

    if (typeof value === 'object') {
      const { ID } = value;

      return {
        [`caseType.ID:${operator ?? 'eq'}`]: ID,
      };
    }

    return null;
  },
  extraProps: {
    cacheKey: 'CaseFilterCaseType',
    search: async (search: string) => {
      const { data } = await Api.get<ListingResponse<CaseType>>(
        '/api/sales/caseTypes',
        {
          params: {
            filters: search ? { $search: search } : {},
            fields: ['ID', 'name'],
          },
        }
      );

      return data.data;
    },
    itemToString: (item: CaseType) => item.name,
    enableTextSearch: true,
    itemToValue: (item: CaseType) => ({ id: item.ID }),
    valueToItem: async (value) => {
      const { data } = await Api.get<CaseType>(
        `/api/sales/caseTypes/${value.id}`,
        { params: { field: ['ID', 'name'] } }
      );

      return data;
    },
  },

  name: () => i18n.t('Sales:case.type'),
  operators: getDefaultComboboxOperators(false),
});
