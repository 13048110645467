import { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';

import { DatasetImportLayout } from '../components/DatasetImportLayout';
import { CreateProgress, CreateSetup, CreateUpload } from './CreateImport';

export const DatasetCreate = (): ReactElement => {
  return (
    <>
      <Routes>
        <Route path="/import" element={<DatasetImportLayout />}>
          <Route index element={<CreateUpload />} />
          <Route path="/import/:uploadId/setup" element={<CreateSetup />} />
          <Route
            path="/import/:uploadId/progress/:taskId"
            element={<CreateProgress />}
          />
        </Route>
        {/* <Route
          path="/import/empty"
          element={
            <>TODO: Offer form builder to create an import from stratch</>
          }
        /> */}

        <Route path="*" element={<>{'Not found'}</>} />
      </Routes>
    </>
  );
};
