import { ReactElement } from 'react';

import { useDisplayMode } from '@bq/components/ListingSettings';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { useFormDefinition } from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { CompanyCardList } from './CompanyCardList';
import { CompanyTableList } from './CompanyTableList';
import { MobileCompanyCardList } from './MobileCompanyCardList';

export const CompanyList = (): ReactElement => {
  const customFields = useFormDefinition('Phonebook.company');
  const isMobile = useIsMobile();
  const settingsLoaded = useLoadUserSettings('Phonebook.CompanyList', {});
  const [displayMode] = useDisplayMode('Phonebook.CompanyList');

  if (!settingsLoaded || !customFields) {
    return <LoadingPage />;
  }

  if (isMobile) {
    return <MobileCompanyCardList customFields={customFields} />;
  }

  return displayMode === 'card' ? (
    <CompanyCardList customFields={customFields} />
  ) : (
    <CompanyTableList customFields={customFields} />
  );
};
