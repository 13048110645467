const eventListenersMixin = {
  created() {
    this.$options.eventBindings = [];
  },
  beforeDestroy() {
    this.$options.eventBindings.forEach(({ targetObject, event, callback }) => {
      targetObject.off(event, callback);
    });
    this.$options.eventBindings = [];
  },
  methods: {
    $onEvent(targetObject, event, callback) {
      targetObject.on(event, callback);
      this.$options.eventBindings.push({ targetObject, event, callback });
    },
    $offEvent(targetObject, event, callback = null) {
      const listeners = this.$options.eventBindings.find((bindingInfo) => {
        if (event === bindingInfo.event && bindingInfo.targetObject === targetObject) {
          return (callback === null) || callback === bindingInfo.callback;
        }

        return false;
      });

      if (listeners.length > 0) {
        listeners.forEach(({ targetObject, event, callback }) => {
          targetObject.off(event, callback);
        });
        this.$options.eventBindings = this.$options.eventBindings
          .filter((eventBinding) => listeners.includes(eventBinding));
      }
    },
  },
};

export default {
  install(Vue, _options) {
    Vue.mixin(eventListenersMixin);
  },
};
