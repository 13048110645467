module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col-xs-12 col-sm-12 col-md-6\">\n    <div class=\"col-xs-12 col-md-8 col-sm-6\">\n        <div class=\"refresh-toggle-container\">\n            <div class=\"refresh-label-container\">\n                <label>"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.refresh_type",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":5,"column":23},"end":{"line":5,"column":51}}}))
    + ":&nbsp;&nbsp;</label>\n            </div>\n\n            <div class=\"refresh-buttons-container\">\n                <div class=\"btn-group btn-group-justified\" data-toggle=\"buttons\">\n                    <label class=\"btn btn-default active\">\n                        <input type=\"radio\" name=\"datatable-refresh-type\" id=\"manual\" autocomplete=\"off\" checked /> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.refresh_manual",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":11,"column":116},"end":{"line":11,"column":146}}}))
    + "\n                    </label>\n\n                    <label class=\"btn btn-default\">\n                        <input type=\"radio\" name=\"datatable-refresh-type\" id=\"auto\" autocomplete=\"off\" /> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.refresh_auto",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":15,"column":106},"end":{"line":15,"column":134}}}))
    + "\n                    </label>\n                </div>\n            </div>\n        </div>\n    </div>\n\n    <div class=\"col-xs-12 col-md-4 col-sm-6 refresh-status-container\">\n        <a href=\"/calls/all/\" class=\"btn btn-primary btn-block data-update-btn noparse hidden\">\n            <span class=\"glyphicon glyphicon-refresh\"></span>&nbsp;\n            "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.refresh",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":25,"column":35}}}))
    + "&nbsp;\n            <span class=\"badge text-bold new-call-count\"></span>\n\n        </a>\n\n        <a href=\"/calls/all/\" class=\"btn btn-success btn-block data-ok-btn noparse\">\n            <span class=\"glyphicon glyphicon-ok\"></span> "
    + alias2(lookupProperty(helpers,"tr").call(alias1,"label.refresh_up_to_date",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":31,"column":57},"end":{"line":31,"column":91}}}))
    + "\n        </a>\n    </div>\n</div>\n";
},"useData":true})