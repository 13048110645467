import { PropsWithChildren, createContext, useContext } from 'react';
import { ContactSelectMultiProps } from '../types';

const MultiContactSelectCtx = createContext<ContactSelectMultiProps | null>(
  null
);

export const MultiContactSelectProvider = (
  props: PropsWithChildren<ContactSelectMultiProps>
) => {
  const { children, ...rest } = props;

  return (
    <MultiContactSelectCtx.Provider value={rest}>
      {children}
    </MultiContactSelectCtx.Provider>
  );
};

export const useMultiContactSelectCtx = () => {
  const ctx = useContext(MultiContactSelectCtx);
  if (!ctx) {
    throw Error('Missing MultiContactSelectContext');
  }

  return ctx;
};
