import { ChangeEvent, useCallback, useMemo } from 'react';
import { Box, FormControl, Input } from '@chakra-ui/react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import { Price } from 'app/Modules/Sales/Assets/js/Price';

import { Prices } from '../../../types';
import { parseNumberOrUndefined } from '../../utils';

interface Props {
  formKey: 'retailPrice' | 'wholesalePrice' | 'contractPrice' | 'purchasePrice';
  label: string;
}
export const PriceField = ({ formKey, label }: Props) => {
  const { control } = useFormContext<Prices>();
  const { field: withTax } = useController({
    control,
    name: `price.withTax.${formKey}`,
  });
  const {
    field: withoutTax,
    fieldState: { error },
  } = useController({
    control,
    name: `price.${formKey}`,

    rules: {
      validate: {
        requiredAndNumeric: (val) => {
          return !!parseNumberOrUndefined(val);
        },
      },
    },
  });
  const tax = useWatch({ control, name: 'price.taxRate.taxRate' });
  const taxToNum = useMemo(() => {
    if (tax) {
      const taxNumVal = parseNumberOrUndefined(tax);
      if (taxNumVal) {
        return taxNumVal / 100;
      }

      return undefined;
    }

    return undefined;
  }, [tax]);

  const handleWithoutTaxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // See is it a number and do something with it, otherwise ignore it
      const numVal = parseNumberOrUndefined(e.target.value);
      if (numVal) {
        // If tax is present we calc it
        if (taxToNum) {
          const toPrice = new Price(numVal);
          const calcTax = toPrice.mul([taxToNum]).add([numVal]).asNumber;
          withTax.onChange(calcTax);
          withoutTax.onChange(numVal);
        } else {
          // Otherwise both are same and we send an upd event
          withTax.onChange(numVal);
          withoutTax.onChange(numVal);
        }
      }
      withoutTax.onChange(e.target.value);
    },
    [taxToNum, withTax, withoutTax]
  );

  const handleWithTaxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // See is it a number and do something with it, otherwise ignore it
      const numVal = parseNumberOrUndefined(e.target.value);
      if (numVal) {
        // If tax is present we calc it
        if (taxToNum) {
          const toPrice = new Price(numVal);
          const calcWithoutTax = toPrice.div([1 + taxToNum]).asNumber;
          withTax.onChange(numVal);
          withoutTax.onChange(calcWithoutTax);
        } else {
          // Otherwise both are same and we send an upd event
          withTax.onChange(numVal);
          withoutTax.onChange(numVal);
        }
      }
      withTax.onChange(e.target.value);
    },
    [taxToNum, withTax, withoutTax]
  );

  return (
    <>
      <Box>{label}:</Box>
      <FormControl isInvalid={!!error}>
        <Input
          value={withoutTax.value}
          min={0}
          type="number"
          step={0.0001}
          onChange={handleWithoutTaxChange}
          size={['md', 'md', 'sm']}
        />
      </FormControl>
      <Input
        value={withTax.value}
        min={0}
        type="number"
        step={0.0001}
        onChange={handleWithTaxChange}
        size={['md', 'md', 'sm']}
      />
    </>
  );
};
