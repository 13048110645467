import { makeTextFilter } from '@bq/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const nameFilter = makeTextFilter({
  name: () => i18n.t('global:title'),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    return ['name', operator ?? 'contains:ci', value];
  },
});
