import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  ChangeEvent,
} from 'react';
import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { UserGroup } from '../../HopsOperator/HopsContacts/types';

interface Props {
  groups: UserGroup[];
  value: number[];
  onChange: Dispatch<SetStateAction<number[]>>;
}

export const CategoryGroups = ({
  groups,
  value,
  onChange,
}: Props): ReactElement => {
  return (
    <HStack wrap="wrap" spacing="3" alignContent="center" px="3">
      {groups.map((group) => (
        <CategoryGroup
          key={group.ID}
          group={group}
          value={value}
          onChange={onChange}
        />
      ))}
    </HStack>
  );
};

interface GroupProps {
  group: UserGroup;
  value: number[];
  onChange: Dispatch<SetStateAction<number[]>>;
}

const CategoryGroup = ({
  group,
  value,
  onChange,
}: GroupProps): ReactElement => {
  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newChecked = ev.target.checked;

      onChange((prev) => {
        // Add group to list if not there
        if (newChecked && !prev.includes(group.ID)) {
          return [...prev, group.ID];
        }

        // Remove group from list if there
        if (!newChecked && prev.includes(group.ID)) {
          return prev.filter((prevID) => prevID !== group.ID);
        }

        return prev;
      });
    },
    [onChange, group.ID]
  );

  const isChecked = value.includes(group.ID);

  return (
    <Box>
      <Checkbox key={group.ID} isChecked={isChecked} onChange={handleChange}>
        {group.name}
      </Checkbox>
    </Box>
  );
};
