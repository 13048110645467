import { getDefaultComboboxOperators } from '@bq/components/FilterBar/filters/ComboboxFilter';
import {
  makeUserFilter,
  userFilterIDExpr,
} from '@bq/components/FilterBar/filters/UserFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const createdByUserFilter = makeUserFilter({
  name: () => i18n.t('Sales:case.created_by'),
  operators: getDefaultComboboxOperators(false),
  toFilter: ({ value, operator }) => {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      const containsOp = operator === 'neq' ? 'contains:not' : 'contains';

      return {
        $or: {
          [`createdByUser.username:${containsOp}:ci`]: value,
          [`createdByUser.person.fullName:${containsOp}:ci`]: value,
        },
      };
    }

    const userID = userFilterIDExpr(value);

    return { [`createdByUser.ID:${operator ?? 'eq'}`]: userID };
  },
});
