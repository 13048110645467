import { ReactElement, useCallback, useState } from 'react';
import { Flex, IconButton, Input, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import { useUpdateEffect } from 'react-use';

import { Jsonish } from '@bq/components/type-util';
import { useAutosizingInput } from 'BootQuery/Assets/components/use-autosizing-input';
import i18n from 'BootQuery/Assets/js/i18n';
import { parseToNumber } from 'BootQuery/Assets/js/utils';

import { FilterName } from '../FilterName';
import { FilterOperatorInput, FilterTagOperator } from '../FilterOperator';
import { FilterProps, FilterType, ParseQueryString } from '../types';

/** This file is almost completly identical to TextFilter */
/** It has additional util of converting all input values to number if possible */
/** Also uses number specific operators */

interface NumberInputProps {
  value?: number | null | NumberRange;
  onChange?: (value: number | NumberRange | null) => void;
  onRemove: () => void;
  isNew?: boolean;
  operator: string | null | undefined;
}
interface NumberRangeInputProps {
  value?: null | NumberRange;
  onChange?: (value: NumberRange | null) => void;
  isNew?: boolean;
}
interface NumberSingleInputProps {
  value?: null | number;
  onChange?: (value: number | null) => void;
  isNew?: boolean;
}
interface NumberRange {
  from: number;
  to: number;
}

const parseFilter = (
  value: ParseQueryString | Jsonish,
  operator: string | null
): number | null | NumberRange => {
  if (!value) {
    return null;
  }
  if (operator === 'between') {
    if (typeof value !== 'object') {
      return null;
    }
    if (!('from' in value && 'to' in value)) {
      return null;
    }
    if (typeof value.from !== 'number' || typeof value.to !== 'number') {
      return null;
    }

    return { from: value.from, to: value.to };
  }
  if (typeof value === 'string') {
    return parseToNumber(value);
  }
  if (typeof value === 'number') {
    return value;
  }

  return null;
};
function isNumberRange(
  value: Jsonish | NumberRange | null
): value is NumberRange {
  if (!value || !(value instanceof Object)) {
    return false;
  }

  const hasFrom = 'from' in value && typeof value.from === 'number';
  const hasTo = 'to' in value && typeof value.to === 'number';

  return hasFrom && hasTo;
}
const getValueFromJson = (
  value: Jsonish,
  operator: string | null | undefined
): number | null | NumberRange => {
  if (!value) {
    return null;
  }

  if (typeof value === 'number' || isNumberRange(value)) {
    return value;
  }

  return parseFilter(value, operator ?? null);
};

const NumberRangeInput = ({
  isNew,
  onChange,
  value,
}: NumberRangeInputProps) => {
  const [internalValue, setInternalValue] = useState<{
    from: number | undefined;
    to: number | undefined;
  }>(value ?? { from: 0, to: 0 });
  const handleChange = useCallback((value: string, target: 'from' | 'to') => {
    const parseValueToNumber = parseToNumber(value);
    setInternalValue((prev) => ({ ...prev, [target]: parseValueToNumber }));
  }, []);
  useUpdateEffect(() => {
    if (onChange) {
      onChange({ to: internalValue.to ?? 0, from: internalValue.from ?? 0 });
    }
  }, [internalValue, onChange]);
  const inputEl1 = useAutosizingInput<number | NumberRange>(
    internalValue.from || 100
  );
  const inputEl2 = useAutosizingInput<number | NumberRange>(
    internalValue.to || 100
  );

  return (
    <>
      <Input
        type="number"
        value={internalValue.from}
        onChange={(ev) => {
          handleChange(ev.target.value, 'from');
        }}
        ref={inputEl1}
        minWidth="4"
        border="none"
        background="none"
        borderLeftRadius={0}
        size="sm"
        borderLeft="2px solid"
        borderRight="2px solid"
        borderColor="gray.400"
        autoFocus={isNew}
        _dark={{
          borderColor: 'gray.600',
        }}
      />
      <Input
        type="number"
        value={internalValue.to}
        onChange={(ev) => {
          handleChange(ev.target.value, 'to');
        }}
        ref={inputEl2}
        minWidth="4"
        border="none"
        background="none"
        borderLeftRadius={0}
        size="sm"
        borderRight="2px solid"
        borderColor="gray.400"
        _dark={{
          borderColor: 'gray.600',
        }}
      />
    </>
  );
};

const NumberInputSingle = ({
  isNew,
  onChange,
  value,
}: NumberSingleInputProps) => {
  const handleChange = useCallback(
    (value: string) => {
      const parseValueToNumber = parseToNumber(value);
      if (onChange) {
        onChange(parseValueToNumber);
      }
    },
    [onChange]
  );

  const inputEl = useAutosizingInput<number | NumberRange>(value || 100);

  return (
    <Input
      ref={inputEl}
      type="number"
      value={value ?? undefined}
      onChange={(ev) => {
        handleChange(ev.target.value);
      }}
      minWidth="4"
      border="none"
      background="none"
      borderLeftRadius={0}
      size="sm"
      autoFocus={isNew}
    />
  );
};

const FilterNumberTag = ({
  value = 0,
  onChange,
  onRemove,
  isNew,
  operator,
}: NumberInputProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');

  if (operator === 'between') {
    const assertValue = !(typeof value === 'number') ? value : null;

    return (
      <Flex background={bgColor} height="8" borderRightRadius="md">
        <NumberRangeInput
          value={assertValue}
          onChange={onChange}
          isNew={isNew}
        />
        <IconButton
          onClick={onRemove}
          variant="link"
          aria-label="Close"
          icon={<FaTimes />}
          size="xs"
        />
      </Flex>
    );
  }
  const assertValue = typeof value === 'number' ? value : undefined;

  return (
    <Flex background={bgColor} height="8" borderRightRadius="md">
      <NumberInputSingle
        value={assertValue}
        onChange={onChange}
        isNew={isNew}
      />
      <IconButton
        onClick={onRemove}
        variant="link"
        aria-label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};

const FilterNumberInput = ({
  value = 0,
  onChange,
  operator,
}: NumberInputProps): ReactElement => {
  if (operator === 'between') {
    const valueRange = !(typeof value === 'number') ? value : null;

    return <NumberRangeInput value={valueRange} onChange={onChange} />;
  }

  return (
    <NumberInputSingle
      value={
        !value || typeof value === 'number' ? value ?? undefined : undefined
      }
      onChange={(value) => {
        if (onChange) {
          onChange(value);
        }
      }}
    />
  );
};

export const NumberTag = ({
  name,
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
  isNew,
}: FilterProps<number | NumberRange>): ReactElement => (
  <Flex>
    <FilterName name={name} />
    {operators.length > 0 && (
      <FilterTagOperator
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterNumberTag
      operator={operator}
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      isNew={isNew}
    />
  </Flex>
);

export const NumberInput = ({
  value,
  onChange,
  operators,
  operator,
  onOperatorChange,
  onRemove,
}: FilterProps<number | NumberRange>): ReactElement => (
  <>
    {operators.length > 0 && (
      <FilterOperatorInput
        operators={operators}
        value={operator ?? null}
        onChange={onOperatorChange}
      />
    )}
    <FilterNumberInput
      value={value}
      onChange={onChange}
      onRemove={onRemove}
      operator={operator}
    />
  </>
);

type NumberFilter = FilterType<number | NumberRange>;
type NumberUserDef = Partial<NumberFilter> &
  Pick<NumberFilter, 'toFilter' | 'name'>;
export function makeNumberFilter(filterDef: NumberUserDef): NumberFilter {
  return {
    tagComponent: NumberTag,
    inputComponent: NumberInput,
    operators: () => [
      { operator: 'eq', display: i18n.t('global:operators.is') },
      { operator: 'neq', display: i18n.t('global:operators.is_not') },
      { operator: 'gt', display: i18n.t('global:operators.greater_than') },
      { operator: 'gte', display: i18n.t('global:operators.greater_or_equal') },
      { operator: 'lt', display: i18n.t('global:operators.less_than') },
      { operator: 'lte', display: i18n.t('global:operators.less_or_equal') },
      { operator: 'between', display: i18n.t('global:operators.between') },
    ],
    defaultValue: 0,
    fromJSON: getValueFromJson,
    fromQueryString: parseFilter,
    ...filterDef,
  };
}
