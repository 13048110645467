import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { getPermissions } from './api';
import { EditPermissions, PermissionWithAggregate, Permissions } from './types';

export function usePermissions(): UseQueryResult<Permissions> {
  return useQuery<Permissions>({
    queryKey: ['Phonebook.permissions'],
    queryFn: async () => {
      const data = await getPermissions();

      return {
        ...data,
        edit: aggregateEditPermissions(data.edit),
      };
    },
  });
}

function aggregateEditPermissions(
  edit: EditPermissions
): PermissionWithAggregate<EditPermissions> {
  const allAllowed = Object.values(edit).reduce((all, current: boolean) => {
    return all && current;
  }, true);
  const someAllowed = Object.values(edit).some((item: boolean) => item);
  const noneAllowed = !someAllowed;

  return {
    ...edit,
    allAllowed,
    someAllowed,
    noneAllowed,
  };
}
