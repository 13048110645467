import { uniqid } from 'app/assets/js/tsutil';
import {
  IUseMethodsReturn,
  IUseMethodsWrapped,
  useMethods,
} from 'BootQuery/Assets/js/use-methods';
import { CustomEventTypeSettings, IDType } from './types';

type EventTypeSettingsMethodsRaw = {
  add: (eventType?: Partial<CustomEventTypeSettings>) => CustomEventTypeSettings[];
  remove: (typeID: IDType) => CustomEventTypeSettings[];
  modify: (
    typeID: IDType,
    changes: Partial<CustomEventTypeSettings>
  ) => CustomEventTypeSettings[];
};

export type EventTypeSettingsMethods = IUseMethodsWrapped<
  CustomEventTypeSettings[],
  EventTypeSettingsMethodsRaw
>;

export type UseSettingsMethodsResult = IUseMethodsReturn<
  CustomEventTypeSettings[],
  EventTypeSettingsMethodsRaw
>;

export function useEventTypeSettingsMethods(
  initial: CustomEventTypeSettings[]
): UseSettingsMethodsResult {
  return useMethods(createMethods, initial);
}

function createMethods(prev: CustomEventTypeSettings[]): EventTypeSettingsMethodsRaw {
  return {
    add(eventType) {
      return [
        ...prev,
        {
          ID: `$new-${uniqid()}`,
          name: 'New event type',
          ...eventType,
        },
      ];
    },
    remove(typeID) {
      return prev.map((event) => {
        if (event.ID === typeID) {
          return { ...event, deleted: true };
        }

        return event;
      });
    },
    modify(typeID, changes) {
      return prev.map((event) => {
        if (event.ID === typeID) {
          return { ...event, ...changes };
        }

        return event;
      });
    },
  };
}
