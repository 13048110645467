import { Text, useColorModeValue } from '@chakra-ui/react';
import { ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaArrowLeft,
  FaArrowRight,
  FaArrowsAltH,
  FaRegHourglass,
  FaTimes,
} from 'react-icons/fa';
import { CallDirection } from '../CallList/types';

interface Props {
  finished: boolean;
  direction: CallDirection | null;
  answered: boolean;
  verbose?: boolean;
}

const iconByDirection = {
  outgoing: FaArrowRight,
  incoming: FaArrowLeft,
  internal: FaArrowsAltH,
};

export const CallStatus = ({
  finished,
  direction,
  answered,
  verbose = true,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');

  const directionLabels = useMemo(() => ({
    outgoing: t('Telephony:outgoing'), // Odlazni'
    incoming: t('Telephony:incoming'), // 'Dolazni'
    internal: t('Telephony:internal'), // 'Interni'
  }), [t]);

  const [blue, green, red] = useColorModeValue(
    ['blue.600', 'green.600', 'red.600'],
    ['blue.400', 'green.400', 'red.400']
  );

  if (!finished) {
    return (
      <>
        <Text as="span" color={blue}>
          <FaRegHourglass />
        </Text>
        {verbose && <>&nbsp; {t('Telephony:call')} - {t('Telephony:in_progress')}</>}
      </>
    );
  }

  if (!direction) {
    return (
      <>
        <Text as="span" color={red}>
          <FaTimes />
        </Text>
        {verbose && <>&nbsp; {t('Telephony:call')} - {t('global:error')}</>}
      </>
    );
  }

  const Icon = iconByDirection[direction];

  return (
    <>
      <Text as="span" color={answered ? green : red}>
        <Icon />
      </Text>
      {verbose && (
        <>
          &nbsp; {t('Telephony:call')} - {directionLabels[direction]}{','}&nbsp;
          <Text as="span" color={answered ? green : red}>
            {answered ? t('Telephony:answered') : t('Telephony:unanswered')}
          </Text>
        </>
      )}
    </>
  );
};
