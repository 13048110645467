import { ReactElement } from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PageWrapper } from 'BootQuery/Assets/components/PageWrapper';
import { useAppTitle } from 'BootQuery/Assets/js/use-app-title';

export const Calendar = (): ReactElement => {
  const { t } = useTranslation('Events');
  useAppTitle(t('Events:calendar_title'));

  return (
    <PageWrapper
      p={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Heading className="fa-spin" display="inline">
        {t('Events:calendar_title')}
      </Heading>
    </PageWrapper>
  );
};
