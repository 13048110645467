import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaFileExport } from 'react-icons/fa';

import { ExportBackgroundProgress } from './ExportBackgroundProgress';
import { ExportModalForm } from './ExportModalForm';
import { ExportModalLink } from './ExportModalLink';
import { ExportState } from './types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const ExportModal = ({ isOpen, onClose }: Props): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [exportState, setExportState] = useState<ExportState>({ type: 'form' });

  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const color = useColorModeValue('brand.darkGray', 'brand.lightGray');

  const close = useCallback(() => {
    setExportState({ type: 'form' });
    onClose();
  }, [onClose]);

  return (
    <Modal onClose={close} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent bg={bg} color={color}>
        <ModalHeader display="flex" alignItems="center">
          <FaFileExport style={{ display: 'inline' }} />
          &nbsp;
          {t('global:export')}
        </ModalHeader>
        <ExportModalContent
          onClose={close}
          exportState={exportState}
          setExportState={setExportState}
        />
      </ModalContent>
    </Modal>
  );
};

interface ContentProps {
  onClose: () => void;
  exportState: ExportState;
  setExportState: Dispatch<SetStateAction<ExportState>>;
}

const ExportModalContent = ({
  onClose,
  exportState,
  setExportState,
}: ContentProps): ReactElement => {
  switch (exportState.type) {
    case 'form':
      return (
        <ExportModalForm onClose={onClose} setExportState={setExportState} />
      );
    case 'background':
      return (
        <ExportBackgroundProgress
          taskId={exportState.taskId}
          setExportState={setExportState}
        />
      );
    case 'done':
      return (
        <ExportModalLink
          exportLink={exportState.downloadAt}
          onClose={onClose}
        />
      );
    default:
      throw new Error('Invalid export modal state');
  }
};
