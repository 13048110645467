import { UseQueryResult } from '@tanstack/react-query';

import { UseListingSettingsReturn } from '@bq/components/ListingSettings';
import { sortValueToApiSort } from 'BootQuery/Assets/js/sort-value-to-api-sort';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';

import { listDatasets, ListDatasetsResponse } from '../Api/datasets';
import { DatasetListItem } from './schema';

export function useDatasetList({
  filters,
  limit,
  page,
  sort,
}: UseListingSettingsReturn<DatasetListItem>): UseQueryResult<ListDatasetsResponse> {
  return useDebouncedQuery({
    queryKey: [USE_DATASET_LIST_KEY, filters, limit, page, sort],
    queryFn: () => {
      return listDatasets({
        filters,
        limit,
        page,
        sort: sortValueToApiSort(sort),
      });
    },
    enabled: filters !== undefined,
  });
}

export const USE_DATASET_LIST_KEY = 'Datasets.datasetListTable';
