/* eslint-disable no-redeclare */
import { upperFirst } from 'lodash-es';

import { capitalizeFirstLetter } from './capitalize-word';
import { ContactOption, ContactValue } from './types';

export function splitName(search: string): {
  firstName: string;
  lastName: string;
} {
  const parts = search.split(/\s+/);

  if (parts.length === 1) {
    return { firstName: upperFirst(search), lastName: '' };
  }

  const mid = parts.length / 2;

  const firstName = parts.slice(0, mid).join(' ');
  const lastName = parts.slice(mid).join(' ');

  return {
    firstName: capitalizeFirstLetter(firstName),
    lastName: capitalizeFirstLetter(lastName),
  };
}

export function valueToSelect(
  contact: ContactValue | null
): ContactOption | null;
export function valueToSelect(contact: ContactValue): ContactOption;
export function valueToSelect(contact: null): null;
export function valueToSelect(
  contact: ContactValue | null
): ContactOption | null {
  if (!contact) {
    return null;
  }

  return {
    value: `${contact.type}-${contact.ID}`,
    label: contact.name,
  };
}

export function selectToValue(
  select: ContactOption | null
): ContactValue | null;
export function selectToValue(select: ContactOption): ContactValue;
export function selectToValue(select: null): null;
export function selectToValue(
  select: ContactOption | null
): ContactValue | null {
  if (!select) {
    return null;
  }

  const [type, idStr] = select.value.split('-');
  if (
    type !== 'user' &&
    type !== 'person' &&
    type !== 'company' &&
    type !== 'companyLocation'
  ) {
    throw new Error(`Invalid contact type ${type}`);
  }

  return {
    type,
    ID: parseInt(idStr, 10),
    name: select.label,
  };
}
