import { makeBooleanFilter } from '@bq/components/FilterBar/filters/Boolean';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketResolveDeadlineExceeded = makeBooleanFilter({
  toFilter: ({ value }) => {
    return { 'resolveDeadlineExceededAt:null': !value };
  },
  name: () => i18n.t('Ticketing:filter.resolve_deadline_exceeded'),
  extraProps: {},
});
