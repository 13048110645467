import { ReactElement, SetStateAction, useCallback } from 'react';
import { Box, HStack, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Primitive } from 'BootQuery/Assets/js/globalTypes';

import { RadioButton, RadioButtonGroup } from '../Radio';

interface Props {
  ID: number;
  name: string;
  value: boolean | null;
  onItemChange: (itemID: number, value: SetStateAction<boolean | null>) => void;
}

export const ReportAgentEntity = ({
  ID,
  name,
  value,
  onItemChange,
}: Props): ReactElement => {
  const { t } = useTranslation('global');

  const borderColor = useColorModeValue('gray.200', 'blackAlpha.300');
  const nameColor = useColorModeValue('blackAlpha100', 'gray.100');

  const handleChange = useCallback(
    (newVal: Primitive) => {
      onItemChange(ID, valueFromRadio(newVal));
    },
    [onItemChange, ID]
  );

  return (
    <HStack
      px="2"
      py="1"
      borderWidth="thin"
      borderStyle="solid"
      borderColor={borderColor}
      borderBottomStyle="none"
      background="whiteAlpha.100"
      overflow="hidden"
      _first={{
        borderTopRadius: 'base',
      }}
      _last={{
        borderBottomRadius: 'base',
        borderBottomStyle: 'solid',
      }}
    >
      <Box color={nameColor} flex="1 1 auto">
        {name}
      </Box>
      <RadioButtonGroup
        flex="0 1 auto"
        onChange={handleChange}
        defaultVal={String(value)}
      >
        <RadioButton px="2" py="1" value="null">
          {t('global:default')}
        </RadioButton>
        <RadioButton px="2" py="1" value="true">
          {t('global:yes')}
        </RadioButton>
        <RadioButton px="2" py="1" value="false">
          {t('global:no')}
        </RadioButton>
      </RadioButtonGroup>
    </HStack>
  );
};

function valueFromRadio(value: Primitive): boolean | null {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}
