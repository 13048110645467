import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { chakraComponents, OptionProps } from 'chakra-react-select';

import { StateOption } from './types';

export const StateOptionComponent = (
  props: OptionProps<StateOption>
): ReactElement => {
  const { color, label } = props.data;

  return (
    <chakraComponents.Option {...props}>
      <Box
        whiteSpace="nowrap"
        color="white"
        bg={color}
        rounded="sm"
        py="0.5"
        px="4"
        fontWeight="bold"
        fontSize="xs"
        flex="1 1 auto"
      >
        {label}
      </Box>
    </chakraComponents.Option>
  );
};
