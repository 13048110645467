import { ReactElement, useContext } from 'react';
import { get } from 'lodash-es';

import { ViewOnlyRichText } from '@bq/components/RichText/RichTextViewOnly';

import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';

export const StaticFormField = ({ id }: FieldValue): ReactElement => {
  const { formData } = useContext(StaticFormContext);
  const value = get(formData, id) as string;

  return <ViewOnlyRichText content={value ?? ''} />;
};
