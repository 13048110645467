import { get, merge, reduce } from 'lodash-es';

function loadManifests(r) {
  return r.keys().reduce((modules, key) => {
    const manifest = r(key);
    modules[manifest.name] = manifest;
    return modules;
  }, {});
}

const modules = loadManifests(require.context('app/Modules/', true, /^\.\/[^/]+\/manifest\.json$/));

export default class BootqueryModules {
  static get modules() {
    return modules;
  }

  static getWantedList(moduleName) {
    const wanted = get(this.modules, [moduleName, 'wants']) || [];
    return reduce(
      wanted,
      (byPriority, wantedModuleName) => {
        const wantedModule = this.modules[wantedModuleName];
        if (wantedModule) {
          byPriority[wantedModule.priority] = wantedModuleName;
        }
        return byPriority;
      },
      {},
    );
  }

  static getHookedList(moduleName) {
    return reduce(
      this.modules,
      (hookedByPriority, module) => {
        let { hooks } = module;
        if (typeof hooks === 'string') {
          hooks = [hooks];
        }
        if (hooks && (hooks.includes(module) || hooks.includes('*'))) {
          hookedByPriority[module.priority] = module.name;
        }
        return hookedByPriority;
      },
      {},
    );
  }

  static getOverridesList(moduleName, includeSelf = false) {
    const overrides = merge(this.getHookedList(moduleName), this.getWantedList(moduleName));
    if (includeSelf) {
      const selfModule = this.modules[moduleName];
      overrides[selfModule.priority] = moduleName;
    }
    return overrides;
  }
}
