import { ForwardedRef, forwardRef, memo, ReactElement } from 'react';
import { Box, List, Portal, useMultiStyleConfig } from '@chakra-ui/react';

import { AutocompleteListItem } from './AutocompleteListItem';
import { FilterTypeItem, UseComboboxRet } from './types';

type AutocompleteListBase = Pick<
  UseComboboxRet,
  'isOpen' | 'getMenuProps' | 'getItemProps' | 'selectItem' | 'highlightedIndex'
>;

interface AutocompleteListProps extends AutocompleteListBase {
  items: FilterTypeItem[];
}

const AutocompleteListInner = (
  {
    isOpen,
    getMenuProps,
    getItemProps,
    items,
    selectItem,
    highlightedIndex,
  }: AutocompleteListProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement => {
  const { list: listStyle } = useMultiStyleConfig('Menu', {});
  // This throws errors when using portals, but everything seems fine
  // So just supress the error
  const menuProps = getMenuProps({}, { suppressRefError: true });

  return (
    <Portal>
      <Box ref={ref} display={isOpen ? undefined : 'none'}>
        <List
          {...menuProps}
          sx={listStyle}
          overflowY="auto"
          maxH="max(calc(90vh - var(--navbar-height)), 400px)"
        >
          {isOpen &&
            items.map((item, index) => (
              <AutocompleteListItem
                key={index}
                index={index}
                item={item}
                isActive={highlightedIndex === index}
                selectItem={selectItem}
                getItemProps={getItemProps}
              />
            ))}
        </List>
      </Box>
    </Portal>
  );
};

const AutocompleteList = memo(forwardRef(AutocompleteListInner));
AutocompleteList.displayName = 'AutocompleteList';

export { AutocompleteList };
