import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { ImportConfig } from '../types';

interface StartImportResponse {
  taskId: string;
}

export async function startImport(config: ImportConfig): Promise<string> {
  const { data } = await Api.post<StartImportResponse>(
    '/api/datasets/import',
    config
  );

  return data.taskId;
}

type ImportMutation = UseMutationResult<string, unknown, ImportConfig>;

export function useStartImport(): ImportMutation {
  return useMutation({
    mutationKey: ['Datasets.import'],
    mutationFn: (config: ImportConfig) => startImport(config),
  });
}
