import { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useEventTypes } from '../event-types';
import { Event } from '../types';
import { EventErrorFallback } from './EventErrorFallback';

type Props = Event;

export const EventCard = (event: Props): ReactElement => {
  return (
    <ErrorBoundary
      FallbackComponent={({ resetErrorBoundary, error }) => (
        <EventErrorFallback
          resetErrorBoundary={resetErrorBoundary}
          error={error}
          view="card"
        />
      )}
    >
      <EventTypeCard {...event} />
    </ErrorBoundary>
  );
};

const EventTypeCard = (event: Props) => {
  const eventType = useEventTypes()[event.type];
  if (!eventType) {
    throw new Error(`Unknown event type ${event.type}`);
  }

  return <eventType.components.Card {...event} />;
};
