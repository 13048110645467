import { ReactElement } from 'react';
import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContentField } from './ContentField';
import { MailTemplate } from './types';

export const TemplateFormFields = (): ReactElement => {
  const { t } = useTranslation('Mails');
  const {
    register,
    formState: { errors },
  } = useFormContext<Partial<MailTemplate>>();

  return (
    <VStack spacing={4} w="full">
      <FormControl isInvalid={!!errors.name}>
        <FormLabel htmlFor="name">{t('global:name')}</FormLabel>
        <Input
          {...register('name', { required: true })}
          isInvalid={!!errors.name}
          placeholder={t('global:name')}
        />
      </FormControl>
      <ContentField />
    </VStack>
  );
};
