import { useCallback, useState } from 'react';

import { useFormState } from '@bq/components/form-state';

import {
  CaseBaseInfo,
  CasePickerModalProps,
  CaseSelectHandler,
  ICasePickerContext,
  SelectCaseFn,
  SelectCaseOptions,
} from './types';

export interface UseCasePickerModalResult extends ICasePickerContext {
  modalProps: CasePickerModalProps;
}

interface SelectorState {
  onSelect: CaseSelectHandler;
  options?: SelectCaseOptions;
}

export const useCasePickerModal = (): UseCasePickerModalResult => {
  const [selectorState, setSelectorState] = useState<SelectorState | null>(
    null
  );
  const [formState, setFormState] = useFormState();

  const selectCase: SelectCaseFn = useCallback(
    (onSelect, options) => {
      setFormState(null);
      setSelectorState({
        onSelect,
        options,
      });
    },
    [setFormState]
  );

  const onSubmit = useCallback(
    async (Case: CaseBaseInfo, newlyAdded: boolean) => {
      setFormState('saving');

      selectorState?.onSelect(Case, newlyAdded);

      setFormState('saved');
      setSelectorState(null);
    },
    [setFormState, selectorState]
  );
  const onCancel = useCallback(() => {
    setFormState(null);
    setSelectorState(null);
  }, [setFormState]);

  return {
    selectCase,
    modalProps: {
      isOpen: selectorState !== null,
      onSubmit,
      onCancel,
      options: selectorState?.options,
      formState,
    },
  };
};
