import { Api } from 'BootQuery/Assets/js/api';
import { CustomData, GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { IAppointment } from '../schema';

export interface PostAppointment {
  title: string;
  startAt: Date | string;
  endAt: Date | string;
  data?: CustomData;
  location: {
    type: 'unknown' | 'inPerson' | 'video' | 'phone';
    location?: string | undefined;
  } | null;
  reminders: { timestamp: Date | string }[];
  participants: {
    type: 'user' | 'person' | 'company';
    ID: number;
  }[];
}

export const createAppointment = async <T = IAppointment>(
  data: PostAppointment,
  params?: GetOneParams
) => {
  const { data: newData } = await Api.post<T>('/api/appointments', data, {
    params,
  });

  return newData;
};
