import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export type BaseUnitGroup = 'unit' | 'mass' | 'distance' | 'area' | 'volume';
type BaseUnitTranslation = Record<BaseUnitGroup, string>;
export const useTranslateBaseUnitGroups = () => {
  const { t } = useTranslation('Products');
  const translations: BaseUnitTranslation = useMemo(
    () => ({
      unit: t('Products:base_units:unit'),
      mass: t('Products:base_units:mass'),
      distance: t('Products:base_units:distance'),
      area: t('Products:base_units:area'),
      volume: t('Products:base_units:volume'),
      time: t('Products:base_units:time'),
    }),
    [t]
  );

  return translations;
};
