import {
  Box,
  HStack,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';

import { CompanyFormFields } from 'app/Modules/Phonebook/Assets/components/CompanyForm';

import { CompanyFormWrapper } from './CompanyFormWrapper';
import { CompanyModalFooter } from './CompanyModalFooter';
import { CompanyModalContentProps } from './types';
import { useLabelForCompanyModal } from './utils';

export const CompanyModalContent = (props: CompanyModalContentProps) => {
  const label = useLabelForCompanyModal(props.mode);

  return (
    <CompanyFormWrapper {...props}>
      <ModalHeader>
        <HStack>
          <Box>
            <FaPlus />
          </Box>
          <Box>{label}</Box>
        </HStack>
      </ModalHeader>
      <ModalBody>
        <CompanyFormFields />
      </ModalBody>
      <ModalFooter>
        <CompanyModalFooter />
      </ModalFooter>
    </CompanyFormWrapper>
  );
};
