import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { ApiItem } from '../../types';

interface GetProductsParams {
  params?: GetListingParams;
}

export async function listProductsRaw({
  params,
}: GetProductsParams): Promise<ListingResponse<ApiItem>> {
  const { data } = await Api.get<ListingResponse<ApiItem>>('/api/products', {
    params,
  });

  return data;
}
