import { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FaCalendarCheck } from 'react-icons/fa';

import { Card, CardBody, CardHeader } from '@bq/components/Card';
import { EventCardComponent } from 'app/Modules/Events/Assets/components';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';

import { useLocationTypeTitle } from '../Appointment/location-types';
import { ILocation } from '../Appointment/schema';
import { AppointmentEventMenu } from './AppointmentEventMenu';
import { AppointmentEvent } from './types';

export const AppointmentEventCard: EventCardComponent<AppointmentEvent> = (
  event
) => {
  return (
    <Card>
      <CardHeader display="flex" justifyContent="space-between">
        <Flex alignItems="center">
          <FaCalendarCheck style={{ display: 'inline' }} />
          &nbsp;
          {event.data.title}
        </Flex>
        <Flex alignItems="center">
          {formatDate(event.timestamp, DateType.DateTime)}
          <AppointmentEventMenu {...event} />
        </Flex>
      </CardHeader>
      <CardBody>
        {formatDate(event.data.startAt, DateType.DateTime)}
        {' - '}
        {formatDate(event.data.endAt, DateType.DateTime)}
        <br />
        {event.data.location && event.data.location.type !== 'unknown' && (
          <Location {...event.data.location} />
        )}
      </CardBody>
    </Card>
  );
};

const Location = (location: ILocation): ReactElement => {
  return (
    <>
      <Text fontWeight="bold" as="strong">
        {useLocationTypeTitle(location.type)}:
      </Text>{' '}
      {location.location}
    </>
  );
};
