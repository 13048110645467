import { RefCallback, useCallback, useEffect, useRef } from 'react';

import InputAutosizer from 'app/assets/js/input-autosizer';

export function useAutosizingInput<ValueType = string>(
  value: ValueType,
  minWidth = 50
): RefCallback<HTMLInputElement> {
  const inputAutosizer = useRef<InputAutosizer | null>(null);
  const prevRef = useRef<HTMLInputElement | null>(null);

  const inputRef: RefCallback<HTMLInputElement> = useCallback(
    (inputEl) => {
      if (!inputRef) {
        return;
      }

      const elChanged = prevRef.current !== inputEl;

      const autosizerEmpty = inputAutosizer.current === null;
      if ((elChanged || autosizerEmpty) && inputEl) {
        inputAutosizer.current = new InputAutosizer(inputEl, {
          minWidth,
        });

        inputAutosizer.current.update();
      }

      prevRef.current = inputEl;
    },
    [minWidth]
  );

  useEffect(() => {
    inputAutosizer.current?.update();
  }, [value]);

  return inputRef;
}
