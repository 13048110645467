import { ReactElement, useCallback } from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV, FaPen, FaTrashAlt } from 'react-icons/fa';

import { Portal } from '@bq/components/Portal';
import {
  EventMenuItem,
  useEventMenus,
} from 'app/Modules/Events/Assets/components';
import { useEventList } from 'app/Modules/Events/Assets/components/EventList/EventListContext';

import { useAppointmentEvents, useAppointmentFormModal } from '../Appointment';
import { deleteAppointment } from '../Appointment/api';
import { AppointmentEvent } from './types';

export const AppointmentEventMenu = (event: AppointmentEvent): ReactElement => {
  const { ID } = event.data;
  const { t } = useTranslation('Events');
  const items = useEventMenus(event.type);
  const { removeEvent } = useEventList();
  const onRemove = useAppointmentEvents()?.onDelete;
  const openAppointmentModal = useAppointmentFormModal();

  const edit = useCallback(() => {
    openAppointmentModal({
      mode: 'edit',
      appointmentID: ID,
    });
  }, [ID, openAppointmentModal]);
  const remove = useCallback(async () => {
    await deleteAppointment(ID);
    removeEvent?.('salesCaseAppointment', ID.toString());
    onRemove?.(ID);
  }, [ID, removeEvent, onRemove]);

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        ml="2"
        size="sm"
        variant="ghost"
        icon={<FaEllipsisV />}
      />
      <Portal>
        <MenuList>
          <MenuItem onClick={edit}>
            <FaPen />
            &nbsp;
            {t('global:edit')}
          </MenuItem>
          {items.map((item) => (
            <EventMenuItem
              key={`${event.type}-${event.id}`}
              {...item}
              event={event}
            />
          ))}
          <MenuItem color="red" onClick={remove}>
            <FaTrashAlt />
            &nbsp;
            {t('global:delete')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
