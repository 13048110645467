import { RefObject, useLayoutEffect, useState } from 'react';

/**
 * Check if an element in `ref` is vertically overflowing it's container.
 * Possible return values:
 * - null: unknown, not checked yet
 * - false: not overflowing
 * - true: overflowing
 */
export const useOverflowing = (ref: RefObject<HTMLElement>): boolean | null => {
  const [overflowing, setOverflowing] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    const eventsContainer = ref.current.closest('#ticket-events-list');
    if (!eventsContainer || !(eventsContainer instanceof HTMLElement)) {
      console.warn('Unable to find #ticket-events-list container');

      return;
    }

    eventsContainer.style.display = 'block';
    setOverflowing(ref.current.scrollHeight > ref.current.clientHeight);
    eventsContainer.style.display = '';
  }, [ref]);

  return overflowing;
};
