module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.lambda(depth0, depth0)) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"head"),depth0,{"name":"head","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<body>\n    <div id=\"content-wrapper\">\n        <div class=\"container nomenu mr-auto ml-auto\" id=\"main-container\">\n            <div id=\"php-debugs\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"_php_debug") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":72}}})) != null ? stack1 : "")
    + "</div>\n            <div class=\"col-12 col-md-10 col-lg-8 pt-3 mr-auto ml-auto bq-content bq-default\">\n                <div class=\"card\">\n                    <div class=\"card-header\">\n                        <span class=\"fa fa-key\"></span>&nbsp;"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.password_recovery",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":92}}}))
    + "\n                    </div>\n\n                    <div class=\"card-body\">\n                        <form id=\"recoveryForm\" class=\"form-horizontal\" action=\"/user/requestRecovery\" method=\"post\" role=\"form\" data-form=\"recoveryForm\">\n                            <div class=\"col-12 mx-auto\" style=\"max-width: 1000px;\">\n                                <div class=\"row\">\n                                    "
    + ((stack1 = container.invokePartial(lookupProperty(partials,"form"),((stack1 = (depth0 != null ? lookupProperty(depth0,"forms") : depth0)) != null ? lookupProperty(stack1,"recoveryForm") : stack1),{"name":"form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n                            <div class=\"col-12 ml-auto\" style=\"max-width: 1000px;\">\n                                <div class=\"row\">\n                                    <button class=\"btn btn-primary ml-auto\">"
    + alias2(lookupProperty(helpers,"tr").call(alias1,"form.send_email",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":21,"column":76},"end":{"line":21,"column":100}}}))
    + "</button>\n                                </div>\n                            </div>\n                        </form>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</body>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"end"),depth0,{"name":"end","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})