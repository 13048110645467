import {
  createContext,
  ForwardedRef,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { CallResponse } from './Dialer/types';

type OnDial = (call: CallResponse) => void;

export interface IDialInterceptorContext {
  onDial?: OnDial;
}

export const DialInterceptorContext = createContext<IDialInterceptorContext>(
  {}
);

export const DialInterceptor = ({
  children,
  onDial,
}: PropsWithChildren<IDialInterceptorContext>): ReactElement => {
  const value = useMemo(() => ({ onDial }), [onDial]);
  const childrenRef = useCatchTelLinkDial(onDial);

  return (
    <DialInterceptorContext.Provider value={value}>
      <span style={{ display: 'contents' }} ref={childrenRef}>
        {children}
      </span>
    </DialInterceptorContext.Provider>
  );
};

/**
 * Catch dials triggered from the global tel: link handler.
 *
 * The tel: link handler will trigger a clickvox.dial event on the tel: link.
 *
 * Here we catch it from any child element
 */
const useCatchTelLinkDial = (onDial?: OnDial): ForwardedRef<HTMLElement> => {
  const childrenRef = useRef<HTMLElement>(null);

  const catchDial: EventListener = useCallback(
    (ev) => {
      if (!(ev instanceof CustomEvent)) {
        throw new Error('Caught dial is not a custom event');
      }

      console.log('Caught dial!', ev.detail);
      if (onDial) {
        onDial(ev.detail);
      }
    },
    [onDial]
  );

  useEffect(() => {
    const childrenEl = childrenRef.current;

    if (childrenEl) {
      childrenEl.addEventListener('clickvox.dial', catchDial);
    }

    return () => {
      if (childrenEl) {
        childrenEl.removeEventListener('clickvox.dial', catchDial);
      }
    };
  }, [catchDial]);

  return childrenRef;
};
