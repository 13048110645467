import i18n from 'BootQuery/Assets/js/i18n';
import { makeComboboxFilter } from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import { Api } from 'BootQuery/Assets/js/api';
import { PartialExceptFor } from 'BootQuery/Assets/components/type-util';
import { FilterType } from 'BootQuery/Assets/components/FilterBar';
import { WrappedComboboxFilter } from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter/make-combobox-filter';
import { SMSAccounts } from '../Api/getAccounts';
import { SMSGroups } from '../Api/getGroups';
import { SendingAccountItem } from '../SendingAccountItem';

type SendingGroupVal = Pick<SMSGroups, 'name'> & { type: 'group' };
type SendingAccountVal = Pick<SMSAccounts, 'phoneNumber'> & { type: 'account' };
export type SendingAccVal = SendingGroupVal | SendingAccountVal;

const valToSendingAcc = async ({
  ...props
}: SendingAccVal): Promise<SendingAccVal> => {
  if (props.type === 'account') {
    const { phoneNumber } = props;
    const { data } = await Api.get<SMSAccounts>(
      `/api/sms/accounts/${phoneNumber}`
    );

    return { type: 'account', phoneNumber: data.phoneNumber };
  }
  const { name } = props;
  const { data } = await Api.get<SMSGroups>(`/api/sms/groups/${name}`);

  return { type: 'group', name: data.name };
};

const sendingAccToVall = (item: SendingAccVal): SendingAccVal => {
  if (item.type === 'account') {
    return { type: 'account', phoneNumber: item.phoneNumber };
  }

  return { type: 'group', name: item.name };
};

const searchAccountOrGroup = async (
  search: string
): Promise<SendingAccVal[]> => {
  const { data: accountData } = await Api.get<SMSAccounts[]>(
    '/api/sms/accounts',
    {
      params: { $search: search },
    }
  );
  const { data: groupData } = await Api.get<SMSGroups[]>('/api/sms/groups', {
    params: { $search: search },
  });
  const parsedAccountData: SendingAccountVal[] = accountData.map((item) => ({
    phoneNumber: item.phoneNumber,
    type: 'account',
  }));
  const parsedGroupData: SendingGroupVal[] = groupData.map((item) => ({
    name: item.name,
    type: 'group',
  }));

  return [...parsedAccountData, ...parsedGroupData];
};

type AllOptions = WrappedComboboxFilter<SendingAccVal, SendingAccVal>;
type Options = PartialExceptFor<AllOptions, 'toFilter'>;

export function makeSendingAccountFilter(definition: Options): FilterType {
  return makeComboboxFilter({
    name: () => i18n.t('Sms:filters.sending_account_group'),
    operators: [],
    extraProps: {
      cacheKey: 'sendingAccountCombobox',
      renderItem: SendingAccountItem,
      search: searchAccountOrGroup,
      valueToItem: valToSendingAcc,
      itemToValue: sendingAccToVall,
      enableTextSearch: false,
      itemToString: (item: SendingAccVal) => {
        if (item.type === 'account') { return item.phoneNumber; }

        return item.name;
      },
    },
    ...definition,
  });
}
