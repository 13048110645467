import { memo, ReactElement } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

const UploadedIcon = (): ReactElement => (
  <Tooltip label="Uploaded">
    <Text as="span" color="green.400">
      <FaCheckCircle />
    </Text>
  </Tooltip>
);
const UploadedIconMemo = memo(UploadedIcon);

export { UploadedIconMemo as UploadedIcon };
