import { useQuery } from '@tanstack/react-query';

import { LoadingModal } from '@bq/components/UseModal';

import { getOneCampaign } from '../../api/Campaign/getOneCampaign';
import { CampaignModalForm } from './CampaignModalForm';
import { CampaignFormWrapperProps } from './types';

export const EditCampaignModal = (
  props: Omit<CampaignFormWrapperProps, 'campaignID'> & { campaignID: number }
) => {
  const { data } = useQuery({
    queryKey: ['Campaign', props.campaignID],
    queryFn: () => getOneCampaign(props.campaignID),
  });
  if (!data) {
    return <LoadingModal />;
  }

  return <CampaignModalForm {...props} data={data} />;
};
