import { ComponentProps } from 'react';
import { SystemStyleObject } from '@chakra-ui/theme-tools';
import {
  ChakraStylesConfig,
  GroupBase,
  StylesConfig,
} from 'chakra-react-select';

import { OBJ, Primitive } from 'BootQuery/Assets/js/globalTypes';

import { SelectOption } from './types';

export const getStyles = <
  ValType extends Primitive = Primitive,
  AdditionalData extends OBJ = OBJ,
  Option extends SelectOption = SelectOption<ValType, AdditionalData>,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const chakraSelectStyles: ChakraStylesConfig<Option, false, Group> & {
    menuPortal: (
      provided: SystemStyleObject,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      state: ComponentProps<any>
    ) => SystemStyleObject;
  } = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 8000,
    }),
    option: (provided) => ({ ...provided, whiteSpace: 'pre-wrap' }),
  };

  const selectStyles: StylesConfig<Option, false, Group> = {
    menuPortal: (provided) => ({ ...provided, zIndex: 8000 }),
  };

  return { chakraSelectStyles, selectStyles };
};
