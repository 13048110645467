import { deleteUpload } from 'BootQuery/Assets/js/file-upload';

import { FilesDispatch } from './files-state';
import { FileInfo } from './types';

export function removeUpload(file: FileInfo, dispatch: FilesDispatch) {
  if (file.type === 'uploaded') {
    deleteUpload(file.uploadId);
  } else if (file.type === 'uploading') {
    file.upload.cancel();
  }

  dispatch({ type: 'removeFile', file });
}
