import { FaTags } from 'react-icons/fa';

import { makeComboboxFilter } from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import { TagOptionObject } from 'BootQuery/Assets/components/TagInput';
import i18n from 'BootQuery/Assets/js/i18n';

import { FieldType } from '../../types';
import { newFieldName } from '../../util';
import { StaticTagField } from './StaticTagField';
import { makeTagCell } from './TagCell';
import { TagField } from './TagField';
import { TagFieldSettingsComponent } from './TagFieldSettings';
import { TagFieldSettings } from './types';

export type { TagFieldSettings };

export const tagField = (): FieldType<TagFieldSettings> => ({
  type: 'tags',
  name: i18n.t('global:form_editor.field_tags'),
  icon: FaTags,
  components: {
    EditorFieldSettings: TagFieldSettingsComponent,
    StaticFormField: StaticTagField,
    FormField: TagField,
  },
  defaultSettings: () => ({
    name: newFieldName(),
    required: false,
    tags: [],
  }),
  getFilters(field) {
    return {
      [field.id]: makeComboboxFilter<TagOptionObject, TagOptionObject>({
        name: field.settings.name,
        toFilter: ({ value, operator }) => {
          if (!value) {
            return null;
          }

          const filter =
            typeof value === 'string'
              ? { [`data.${field.id}:$any`]: value }
              : { [`data.${field.id}:$any`]: value.value };

          return operator === 'notContains' ? { $not: filter } : filter;
        },
        operators: () => [
          {
            operator: 'contains',
            display: i18n.t('global:operators.contains'),
          },
          {
            operator: 'notContains',
            display: i18n.t('global:operators.contains_not'),
          },
        ],
        extraProps: {
          cacheKey: `$c-tags-${field.id}`,
          search: async (search: string) => {
            const lcSearch = search.toLowerCase();
            if (lcSearch) {
              return field.settings.tags.filter((tag) => {
                const text = (tag.label ?? tag.value).toLowerCase();

                return text.includes(lcSearch);
              });
            }

            return field.settings.tags;
          },
          itemToString: (item) => item.label ?? item.value,
          valueToItem: (value) => value,
          itemToValue: (item) => item,
        },
      }),
    };
  },
  getColumns(field) {
    return [
      {
        key: `$custom-${field.id}`,
        title: field.settings.name,
        Cell: makeTagCell(field),
      },
    ];
  },
});
