import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { ProductFormFields } from './FormFields';
import { ProductFormWrapper } from '../ProductFormWrapper';
import { FooterButtons } from './FooterButtons';
import { ProductFormData } from '../types';

export const FormModalWrapper = (props: ProductFormData) => {
  const { t } = useTranslation('Products');

  return (
    <ProductFormWrapper {...props}>
      <ModalHeader>
        {props.mode === 'create'
          ? t('Products:create_product')
          : t('Products:edit_product')}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <ProductFormFields />
      </ModalBody>
      <ModalFooter>
        <FooterButtons />
      </ModalFooter>
    </ProductFormWrapper>
  );
};
