import { ReactElement } from 'react';
import { Spinner } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { FieldValue, useFormDefinition } from '@bq/components/FormEditor';
import { OverviewButton } from '@bq/components/OverviewButton/OverviewButton';
import { IOverviewEditorItem } from '@bq/components/Overviews';
import { useDOMInputJSON } from '@bq/components/use-dom-input';

import { useOverviews } from '../Api/overviews';
import { useFilterTypes } from '../CallList/filter-types';

export const OverviewsEdit = (): ReactElement => {
  const customFields = useFormDefinition('Telephony.callForm');
  const { data: overviews } = useOverviews({
    customFields,
    enabled: !!customFields,
  });

  if (!overviews || !customFields) {
    return <Spinner />;
  }

  return (
    <EditOverviewsButton overviews={overviews} customFields={customFields} />
  );
};

interface OverviewsButtonProps {
  overviews: IOverviewEditorItem[];
  customFields: FieldValue[];
}

const EditOverviewsButton = ({
  overviews,
  customFields,
}: OverviewsButtonProps): ReactElement => {
  const { t } = useTranslation('Sales');
  const filterTypes = useFilterTypes(customFields);
  const [stored, setStored] = useDOMInputJSON<IOverviewEditorItem[]>(
    '#settings-form',
    'telephonyOverviews',
    overviews
  );

  return (
    <OverviewButton
      filterTypes={filterTypes}
      onChange={(value) => {
        setStored(value);
      }}
      value={stored}
    >
      {t('global:edit_overviews')}
    </OverviewButton>
  );
};
