module.exports=(window.Handlebars["default"]||window.Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"card\">\n	<div class=\"card-body\">\n		<div class=\"card-title\">\n			<strong>\n				<span class=\"fa fa-envelope\"></span>\n				<span class=\"new-mail-title\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"subject") : depth0), depth0))
    + "</span>\n			</strong>\n			<span class=\"text-muted float-right\">\n				<span class=\"badge badge-secondary\">"
    + alias1(lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),"label.now",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":9,"column":40},"end":{"line":9,"column":58}}}))
    + "</span>\n				<button type=\"button\" class=\"btn btn-link text-danger ml-auto cancel-edit-btn\">\n                	<span class=\"fa fa-times-circle\"></span>\n            	</button>\n			</span>\n		</div>\n		<div class=\"ticket-new-mail-event-container\"></div>\n	</div>\n</div>";
},"useData":true})