import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

import { Calendar } from '../pages/Calendar';
import { Events } from '../pages/Events';

export const ReactRoutes: ReactRoute[] = [
  { path: '/events', component: Events },
  { path: '/events/all', component: Events },
  { path: '/events/overviews/:overview', component: Events },
  { path: '/calendar/*', component: Calendar },
];
