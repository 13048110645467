import { ChangeEvent, ReactElement, useCallback } from 'react';
import { Checkbox, FormControl } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useDOMInput } from '@bq/components/use-dom-input';

import { useTicketingSettingsContext } from './TicketSettingsContext';

export const AutoselectCompanyAsClient = (): ReactElement => {
  const { autoselectCompanyAsClient } = useTicketingSettingsContext();

  const { t } = useTranslation('Ticketing');

  const [companyAsClient, setCompanyAsClient] = useDOMInput(
    '#settings-form',
    'ticketing[autoselectCompanyAsClient]',
    autoselectCompanyAsClient ? 'true' : 'false'
  );

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      setCompanyAsClient(ev.target.checked ? 'true' : 'false');
    },
    [setCompanyAsClient]
  );

  return (
    <FormControl>
      <Checkbox isChecked={companyAsClient === 'true'} onChange={handleChange}>
        {t('Ticketing:form.autoselect_company_as_client')}
      </Checkbox>
    </FormControl>
  );
};
