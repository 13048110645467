import { useMemo } from 'react';

import { IOverviewEditorItem } from '@bq/components/Overviews';

export function useGenericAllOverview(): IOverviewEditorItem[] {
  return useMemo(
    () => [
      {
        title: 'Svi',
        slug: '',
        type: 'item',
        filters: [],
        visible: true,
        global: true,
      },
    ],
    []
  );
}
