import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

import { inputTheme } from './FormElements/InputConfig';
import {
  drawerTheme,
  menuTheme,
  modalTheme,
  popoverTheme,
  tooltipTheme,
} from './Overlay';
import { tableTheme } from './Overlay/TableConfig';

export const componentsConfig = (brandColors: BrandColors) => ({
  Drawer: drawerTheme(brandColors),
  Modal: modalTheme(brandColors),
  Popover: popoverTheme(brandColors),
  Menu: menuTheme(brandColors),
  Tooltip: tooltipTheme(brandColors),
  Input: inputTheme(brandColors),
  Table: tableTheme(),
});
