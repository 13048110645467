import {
  ButtonGroup,
  HStack,
  useColorModeValue,
  Text,
  ButtonGroupProps,
  TableProps,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BulkActionConfirmButton } from './BulkActionConfirmButton';
import { BulkActionStandardButton } from './BulkActionStandardButton';
import { BulkActionTextButton } from './BulkActionTextButton';
import { GenericBulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

export interface ActionGroup<T extends OBJ> {
  actions?: GenericBulkAction<T>[];
  styleProps: ButtonGroupProps;
}
interface Props<T extends OBJ> {
  actionGroups?: ActionGroup<T>[];
  showDefaultsSelectionMenu?: boolean;
  height: number;
  tableSize: TableProps['size'];
}

const buttonSizeForTableSize: Record<string, string> = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

export const TableBulkActionMenu = <T extends OBJ>({
  actionGroups,
  height,
  tableSize,
}: Props<T>) => {
  const { selection } = useTableSelection<T>();
  const { t } = useTranslation();
  const headerColor = useColorModeValue('#F5F5F5', '#393939');
  const buttonSize =
    typeof tableSize === 'string' ? buttonSizeForTableSize[tableSize] : null;
  if (actionGroups === undefined || selection.length === 0) { return <></>; }

  return (
    <HStack
      h={`${height + 2}px`}
      position="absolute"
      top={0}
      align="center"
      shadow="md"
      borderBottom="2px"
      borderColor="brand.500"
      w="full"
      pl={16}
      bg={headerColor}
    >
      {selection.length > 0 && (
        <>
          <Text display="block" w="min-content" whiteSpace="nowrap" mb={0} fontSize={buttonSize ?? 'xs'}>{`${t('global:selected')} ${
            selection.length
          } ${t('global:items')}`}</Text>
          <HStack w="full" pl={3} pr={8}>
            {actionGroups.map((group, idx) => {
              const { actions } = group;

              return (
                <ButtonGroup
                  isAttached
                  key={idx}
                  size={buttonSize ?? 'sm'}
                  {...group.styleProps}
                >
                  {actions?.map((action) => {
                    if (action.needsConfirm) {
                      return (
                        <BulkActionConfirmButton
                          key={action.name}
                          action={action}
                        />
                      );
                    }
                    if (action.displayText === true) {
                      return (
                        <BulkActionTextButton
                          key={action.name}
                          action={action}
                        />
                      );
                    }

                    return (
                      <BulkActionStandardButton
                        action={action}
                        key={action.name}
                      />
                    );
                  })}
                </ButtonGroup>
              );
            })}
          </HStack>
        </>
      )}
    </HStack>
  );
};
