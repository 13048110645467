import { useCallback, useMemo } from 'react';
import { AsyncSelect, SingleValue } from 'chakra-react-select';

import { selectStyles } from '@bq/components/Select';

import { modalSelect } from '@bq/components/Select/select-styles';
import { getManyCasesSelect } from '../../../../api/Case/getManyCasesSelect';
import { CaseBaseInfo } from './types';

interface SelectOption {
  label: string;
  value: number;
  title: string;
}
interface Props {
  onChange: (newValue: SingleValue<CaseBaseInfo>) => void;
  value: CaseBaseInfo | null;
  excludedCaseIds?: number | number[];
}

export const CaseSelect = ({ onChange, value, excludedCaseIds }: Props) => {
  const handleChange = useCallback(
    (value: SingleValue<SelectOption>) => {
      if (value) {
        onChange({ ID: value.value, name: value.title });
      }
    },
    [onChange]
  );

  return (
    <AsyncSelect
      useBasicStyles
      size={{ base: 'lg', md: 'sm', lg: 'sm' }}
      value={useCaseSelectValue(value)}
      cacheOptions
      defaultOptions
      menuPortalTarget={document.body}
      chakraStyles={selectStyles}
      styles={modalSelect}
      onChange={handleChange}
      loadOptions={useLoadOptions(excludedCaseIds)}
      selectedOptionColorScheme="brand"
    />
  );
};

const loadOptions = async (
  search: string,
  excludedCaseIds?: number | number[]
): Promise<SelectOption[]> => {
  let extraFilters = {};
  if (excludedCaseIds) {
    const excluded = Array.isArray(excludedCaseIds)
      ? excludedCaseIds
      : [excludedCaseIds];
    if (excluded.length > 0) {
      extraFilters = { 'ID:notInArray': excluded };
    }
  }

  const { data } = await getManyCasesSelect({
    filters: {
      $search: search,
      ...extraFilters,
    },
    fields: ['ID', 'name'],
  });

  return data.map(salesCaseToSelectValue);
};

function useLoadOptions(
  excludedCaseIds?: number | number[]
): (search: string) => Promise<SelectOption[]> {
  return useCallback(
    (search: string) => loadOptions(search, excludedCaseIds),
    [excludedCaseIds]
  );
}

const salesCaseToSelectValue = (salesCase: CaseBaseInfo): SelectOption => ({
  value: salesCase.ID,
  title: salesCase.name,
  label: `#${salesCase.ID} - ${salesCase.name}`,
});

function useCaseSelectValue(
  salesCase: CaseBaseInfo | null
): SelectOption | null {
  return useMemo((): SelectOption | null => {
    if (!salesCase) {
      return null;
    }

    return salesCaseToSelectValue(salesCase);
  }, [salesCase]);
}
