import tinycolor from 'tinycolor2';
import { ContactCategory } from './types';

export const CONTACTS_PER_PAGE = 4 * 5;

/**
 * Ensure the contacts array is exact size for full grid
 */
export function getFixedSizeContacts<T>(
  contacts: (T | null)[],
  minLength: number | null = null
): (T | null)[] {
  const contactsLength = Math.max(minLength ?? 0, contacts.length);
  // Calculate number of pages, but set minimum of 1
  const pages = Math.max(Math.ceil(contactsLength / CONTACTS_PER_PAGE), 1);
  const targetSize = CONTACTS_PER_PAGE * pages;

  if (contacts.length > targetSize) {
    return contacts.slice(0, targetSize);
  }

  if (contacts.length < targetSize) {
    // construct an array containing enough nulls to reach the target size
    const numMissing = targetSize - contacts.length;
    const pad = new Array<T | null>(numMissing).fill(null);

    return [...contacts, ...pad];
  }

  return contacts;
}

export function getBorderColor(color: string): string {
  const lighter = tinycolor(color).brighten(20);
  const darker = tinycolor(color).darken(20);

  return tinycolor.mostReadable(color, [lighter, darker]).toHexString();
}

export function getTextColor(color: string): string {
  return tinycolor.mostReadable(color, ['black', 'white']).toHexString();
}

export function categoriesWithFixedSizeContacts(
  categories: ContactCategory[]
): ContactCategory[] {
  return categories.map((category) => ({
    ...category,
    contacts: getFixedSizeContacts(category.contacts),
  }));
}
