module.exports=(window.Handlebars["default"]||window.Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"address") : depth0), depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":31},"end":{"line":22,"column":61}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return ", ";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <th style=\"text-align: right; vertical-align: top;\">CC: </th>\n            <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":32,"column":25}}})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.lambda((depth0 != null ? lookupProperty(depth0,"previousHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"previousText") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":44,"column":4}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <pre>"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"previousText") : depth0), depth0))
    + "</pre>\n    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = alias1((depth0 != null ? lookupProperty(depth0,"currentHtml") : depth0), depth0)) != null ? stack1 : "")
    + "\n<hr>\n-------- Forwarded Message --------<br>\n<table border=\"0\">\n    <tbody>\n        <tr>\n            <th style=\"text-align: right;\">Subject: </th>\n            <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"subject") : stack1), depth0))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right;\">Date: </th>\n            <td>"
    + alias2(lookupProperty(helpers,"format_date").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"date") : stack1),lookupProperty(helpers,"tr").call(alias3,"format.datetime_s",{"name":"tr","hash":{},"data":data,"loc":{"start":{"line":12,"column":44},"end":{"line":12,"column":68}}}),{"name":"format_date","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":71}}}))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right; vertical-align: top;\">From: </th>\n            <td>"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"fromMailAddress") : stack1), depth0))
    + "</td>\n        </tr>\n        <tr>\n            <th style=\"text-align: right; vertical-align: top;\">To: </th>\n            <td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"to") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":23,"column":25}}})) != null ? stack1 : "")
    + "            </td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"cc") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":35,"column":15}}})) != null ? stack1 : "")
    + "    </tbody>\n</table>\n<br>\n<blockquote cite=\"mid:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"previous") : depth0)) != null ? lookupProperty(stack1,"messageID") : stack1), depth0))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"previousHtml") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "")
    + "</blockquote>";
},"useData":true})