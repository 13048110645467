import { PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';

import { findFirstFocusableChild } from 'BootQuery/Assets/js/utils';

import { useFocusProvider } from './FocusContext';

export const RichTextToolbar = ({ children }: PropsWithChildren) => {
  const { handleFocusingToolbar, handleBlur, toolbarRef } = useFocusProvider();

  return (
    <Box
      ref={toolbarRef}
      tabIndex={0}
      className="richtext-toolbar"
      onBlur={handleBlur}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();

          handleFocusingToolbar();
          const firstFocusableChild = findFirstFocusableChild(
            event.currentTarget
          );
          firstFocusableChild?.focus();
        }
      }}
    >
      {children}
    </Box>
  );
};
