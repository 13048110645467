import { ReactElement } from 'react';
import { omit } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { ListHeading } from '@bq/components/ListHeading';
import { useHeadingProps } from '@bq/components/ListingSettings';
import { Table, TableSelectionProvider } from '@bq/components/Table';
import { useModal } from '@bq/components/UseModal/use-modal';

import { postProduct } from './API/Product/postProduct';
import { useProductSettingsCtx } from './Context/ProductSettingContext';
import { handleNewProduct } from './Events/product-events';
import { useProductListUpdates } from './Events/use-product-list-updates';
import { FormModalWrapper } from './Form/FormFields/FormModalWrapper';
import { Item, LoadedItem, PartialItem } from './types';
import { useParseToRequestData } from './utils';

export const ProductList = (): ReactElement => {
  const { t } = useTranslation();
  const { addModal } = useModal();
  const parseToRequestData = useParseToRequestData();
  const { listingSettings, count, items, queryKey } = useProductSettingsCtx();
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count,
    displayModeOptions: [],
  });

  useProductListUpdates(queryKey);

  const { density, sort, setSort } = listingSettings;

  return (
    <>
      <ListHeading
        {...headingProps}
        Actions={
          <IconButton
            colorScheme="green"
            icon={<FaPlus />}
            label={t('global:add')}
            onClick={() => {
              addModal({
                children: <FormModalWrapper mode="create" />,
                preventClose: {
                  defaultPreventState: false,
                },
                componentProps: {
                  modal: { size: '6xl', scrollBehavior: 'inside' },
                },
                callback: async (data: PartialItem) => {
                  const newData = await postProduct<LoadedItem>({
                    data: omit(parseToRequestData(data), ['$full']),
                  });
                  handleNewProduct(newData);
                },
              });
            }}
          />
        }
      />
      <TableSelectionProvider<Item> idColumn="ID" page={null}>
        <Table<LoadedItem>
          columns={listingSettings.columnsToShow}
          rows={items}
          size={density}
          sort={{ onChange: setSort, value: sort }}
        />
      </TableSelectionProvider>
    </>
  );
};
