import { css } from '@emotion/css';

export const tdStyle = css({
  position: 'relative',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const tdStyleStatic = css({
  whiteSpace: 'normal',
});

export const wholeRowAnchor = css({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 2,
  '& ~ *': {
    position: 'relative',
  },
  '& ~ button, & ~ a': {
    position: 'relative',
    zIndex: 3,
  },
});

export const selectableText = css({
  zIndex: 0,
});
