import { ReactElement } from 'react';
import { FormControl } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { TagInput } from 'BootQuery/Assets/components/TagInput';

import { FieldValue } from '../../types';
import { FieldLabel } from '../FieldLabel';
import { TagFieldSettings } from './types';

type Props = FieldValue<TagFieldSettings>;

export const TagField = ({ id, settings }: Props): ReactElement => {
  const { control } = useFormContext();
  const { field } = useController({ control, name: id });

  return (
    <FormControl>
      <FieldLabel {...settings} />
      <TagInput
        value={field.value}
        onChange={field.onChange}
        options={settings.tags}
      />
    </FormControl>
  );
};
