import { ReactElement, useCallback } from 'react';

import { usePickCaseEvent } from 'app/Modules/Sales/Assets/js/pick-case';

import { CasePickerModal } from './CasePickerModal';
import { useCasePickerModal } from './use-case-picker-modal';

export const CasePickerConnector = (): ReactElement => {
  const { selectCase, modalProps } = useCasePickerModal();

  usePickCaseEvent(
    'pickCase',
    useCallback(
      ({ onSelect, options }) => {
        selectCase(onSelect, options);
      },
      [selectCase]
    )
  );

  return <CasePickerModal {...modalProps} />;
};
