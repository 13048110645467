import { Center, chakra } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useRadio } from './RadioGroup';
import { RadioProps } from './types';

export const Radio = (props: RadioProps) => {
  const { value, children, ...styleProps } = props;
  const { registerSelf, setValue, isSelected } = useRadio();
  useEffect(() => {
    registerSelf(value);
    // THIS IS INTENDED
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioContainer
      {...styleProps}
      onClick={() => {
        setValue(value);
      }}
      aria-checked={isSelected(value)}
    >
      {children}
    </RadioContainer>
  );
};

const RadioContainer = chakra(Center, {
  baseStyle: {
    w: 'full',
    h: 'full',
    cursor: 'pointer',
  },
});
