import {
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FilterEditor } from 'BootQuery/Assets/components/FilterBar/FilterEditor';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  ChangeEvent,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { filterTypes } from '../message-filter-types';
import { PermissionsValue } from './types';
import { getCurrentFilterPermissions, setFilterInputValue } from './util';

interface Props {
  groupID: number | string;
  isOpen: boolean;
  onClose: () => void;
}

interface EditorContentProps {
  value: PermissionsValue;
  onChange: Dispatch<SetStateAction<PermissionsValue | null>>;
}

const EditorContent = ({
  value,
  onChange,
}: EditorContentProps): ReactElement => {
  const { t } = useTranslation('Sms');

  const setNone = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      onChange(ev.target.checked ? [] : 'none');
    },
    [onChange]
  );

  return (
    <>
      <Checkbox isChecked={value !== 'none'} onChange={setNone}>
        {t('Sms:permissions.can_see_messages')}
      </Checkbox>
      {value !== 'none' && (
        <>
          <Heading mt="3" as="h1" fontSize="xl">
            {t('Sms:permissions.message_filter')}:
          </Heading>
          <FilterEditor
            filterTypes={filterTypes}
            value={value}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
};

export const MessageVisibilityModal = ({
  isOpen,
  onClose,
  groupID,
}: Props): ReactElement => {
  const { t } = useTranslation('Sms');

  const [value, setValue] = useState<PermissionsValue | null>(null);
  const cancel = () => {
    console.debug('cancel');
    setValue(null);
    onClose();
  };
  const submit = () => {
    console.debug('submit', value);
    if (value) {
      setFilterInputValue(value);
    }
    onClose();
  };

  const noValue = value === null;
  useEffect(() => {
    if (noValue) {
      getCurrentFilterPermissions(groupID).then(setValue);
    }
  }, [groupID, noValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={cancel}
      size="4xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('Sms:permissions.message_visibility')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {value === null ? (
            <LoadingPage />
          ) : (
            <EditorContent value={value} onChange={setValue} />
          )}
        </ModalBody>
        {value !== null && (
          <ModalFooter justifyContent="flex-end">
            <FormActions onSubmit={submit} onCancel={cancel} />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
