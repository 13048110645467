import { useMemo } from 'react';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Good } from '../../../types';
import { FieldProps } from '../../types';

interface Props {
  weightType: 'good.grossWeightKg' | 'good.netWeightKg';
}
export const WeightField = ({
  formControlProps,
  weightType,
}: FieldProps & Props) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<Good>();
  const weightTypeA = useMemo(() => {
    return weightType === 'good.grossWeightKg'
      ? 'grossWeightKg'
      : 'netWeightKg';
  }, [weightType]);

  return (
    <FormControl {...formControlProps}>
      <FormLabel htmlFor={weightType}>
        {weightType === 'good.grossWeightKg'
          ? t('Products:gross_weight')
          : t('Products:net_weight')}
      </FormLabel>
      <Input
        {...register(weightType, { valueAsNumber: true })}
        size={['md', 'md', 'sm']}
        type="number"
        step="any"
        isInvalid={!!errors?.good?.[weightTypeA] ?? false}
        placeholder={
          weightType === 'good.grossWeightKg'
            ? t('Products:gross_weight')
            : t('Products:net_weight')
        }
      />
    </FormControl>
  );
};
