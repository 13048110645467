import { FormMode } from 'BootQuery/Assets/js/globalTypes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLabelForCompanyModal = (mode: FormMode) => {
  const { t } = useTranslation('Phonebook');

  return useMemo(() => {
    switch (mode) {
      case 'create':
        return t('Phonebook:create_company');
      case 'edit':
        return t('Phonebook:edit_company');
      case 'view':
        return t('Phonebook:view_company');
      default:
        throw Error('Unknown Form Mode');
    }
  }, [mode, t]);
};
