import { useFormContext } from 'react-hook-form';

import { Api } from 'BootQuery/Assets/js/api';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';

import { CompaniesResponse } from '../api';
import { CompanyFormData } from './types';

export const useDuplicateCompany = () => {
  const { watch } = useFormContext<CompanyFormData>();

  const name = watch('name');
  const { data } = useDebouncedQuery({
    queryKey: ['CompanyChecker', name],
    queryFn: () => {
      return getDuplicateCompany(name);
    },
  });

  return { data };
};

const getDuplicateCompany = async (name = '') => {
  if (name.length <= 2) {
    return [];
  }
  const {
    data: { data },
  } = await Api.get<CompaniesResponse>('/api/phonebook/companies', {
    params: {
      filters: {
        'name:contains:ci': name,
      },
      fields: ['ID', 'name'],
      limit: 'null',
    },
  });

  return data;
};
