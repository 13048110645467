import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import { Campaign } from '../../schemas';
import { CampaignModalForm } from './CampaignModalForm';
import { EditCampaignModal } from './EditCampaignModal';

export const useCampaignModalForm = () => {
  const { addModal } = useModal();

  const createCampaignModal = useCallback(
    (closeCallback: (campaign: Campaign) => void) => {
      addModal<Campaign>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { scrollBehavior: 'inside' } },
        children: <CampaignModalForm mode="create" campaignID={null} />,
      });
    },
    [addModal]
  );
  const editCampaignModal = useCallback(
    ({
      closeCallback,
      ID,
    }: {
      closeCallback: (campaign: Campaign) => void;
      ID: number;
    }) => {
      addModal<Campaign>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { scrollBehavior: 'inside' } },
        children: <EditCampaignModal mode="edit" campaignID={ID} />,
      });
    },
    [addModal]
  );

  return { createCampaignModal, editCampaignModal };
};
