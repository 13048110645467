import {
  useMethods,
  IUseMethodsCreate,
  IUseMethodsWrapped,
  IUseMethodsReturn,
} from 'BootQuery/Assets/js/use-methods';
import { newFieldId } from '../../util';
import {
  SelectEditorSettings,
  SelectFieldEditorSettings,
  SelectItem,
} from './types';

type Settings = SelectFieldEditorSettings;
type Select = SelectEditorSettings;

export type SelectMethods = {
  addItem: (selectId: string, parentItemId?: string) => Settings;
  deleteItem: (selectId: string, itemId: string) => Settings;
  updateItem: (selectId: string, item: SelectItem) => Settings;
  addSelect: () => Settings;
  renameSelect: (selectId: string, name: string) => Settings;
  deleteSelect: (selectId: string) => Settings;
};

type CreateSelectMethods = IUseMethodsCreate<Settings, SelectMethods>;

export type SelectChangeMethods = IUseMethodsWrapped<
  Settings,
  SelectMethods,
  CreateSelectMethods
>;

export function useSelectMethods(initial: Settings): IUseMethodsReturn<Settings, SelectMethods> {
  return useMethods(createSelectMethods, initial);
}

export function createSelectMethods(settings: Settings): SelectMethods {
  return {
    addItem(selectId, parentItemId) {
      return updateSelect(settings, selectId, (p) => addSelectItem(p, parentItemId));
    },
    deleteItem(selectId, itemId) {
      // Remove the item
      let withDeleted = updateSelect(settings, selectId, (p) => deleteSelectItem(p, itemId));

      let deletedIds = [itemId];
      for (let i = 0; i < withDeleted.selects.length; i++) {
        withDeleted = {
          ...withDeleted,
          // eslint-disable-next-line no-loop-func
          selects: withDeleted.selects.map((select, idx) => {
            if (idx === i) {
              const delResult = deleteNewlyAddedSubitems(select, deletedIds);
              deletedIds = [...deletedIds, ...delResult.removedIds];

              return delResult.select;
            }

            return select;
          }),
        };
      }

      // Remove any newly added sub-items of deleted item
      return withDeleted;
    },
    updateItem(selectId, item) {
      return updateSelect(settings, selectId, (p) => updateSelectItem(p, item));
    },
    addSelect() {
      return {
        ...settings,
        selects: [
          ...settings.selects,
          genSelect(),
        ],
      };
    },
    deleteSelect(selectId) {
      return {
        ...settings,
        selects: settings.selects.filter((select) => select.id !== selectId),
      };
    },
    renameSelect(selectId, name) {
      return updateSelect(settings, selectId, { name });
    },
  };
}

type SelectMod = ((prev: Select) => Select) | Partial<Select>;
function updateSelect(
  settings: Settings,
  selectId: string,
  mod: SelectMod
): Settings {
  const doModify = (select: Select) => {
    if (typeof mod === 'function') {
      return mod(select);
    }

    return { ...select, ...mod };
  };

  return {
    ...settings,
    selects: settings.selects.map((sel) => {
      if (sel.id === selectId) {
        return doModify(sel);
      }

      return sel;
    }),
  };
}

function genSelect(): Select {
  return {
    id: newFieldId(),
    name: 'New dropdown',
    new: true,
    itemChanges: { add: [], del: [], upd: {} },
  };
}

function genSelectItem(): SelectItem {
  return {
    key: newFieldId(),
    name: 'New item',
    extraData: {},
  };
}

function addSelectItem(prev: Select, parentItemId?: string): Select {
  const newItem = {
    ...genSelectItem(),
    parentItemId,
  };

  const itemChanges = {
    ...prev.itemChanges,
    add: [...prev.itemChanges.add, newItem],
  };

  return { ...prev, itemChanges };
}

function updateSelectItem(prev: Select, item: SelectItem): Select {
  let changes = prev.itemChanges;
  const isNew = changes.add.find((added) => added.key === item.key);

  // If a newly added item is updated, update it in the added list
  if (isNew) {
    changes = {
      ...changes,
      add: changes.add.map((added) => (added.key === item.key ? item : added)),
    };
  } else {
    // Already saved items need to be marked for update
    changes = {
      ...changes,
      upd: { ...changes.upd, [item.key]: item },
    };
  }

  return { ...prev, itemChanges: changes };
}

function deleteSelectItem(prev: Select, itemId: string): Select {
  let changes = prev.itemChanges;
  const isNew = changes.add.find((added) => added.key === itemId);

  // If a newly added item is deleted, just remove it from added list
  if (isNew) {
    changes = {
      ...changes,
      add: changes.add.filter((added) => added.key !== itemId),
    };
  } else {
    // Already saved items need to be marked for deletion
    changes = {
      ...changes,
      del: [...changes.del, itemId],
    };
  }

  return { ...prev, itemChanges: changes };
}

interface SubitemDeleteResult {
  select: Select,
  removedIds: string[];
}

function deleteNewlyAddedSubitems(prev: Select, deletedParents: string[]): SubitemDeleteResult {
  const removedIds = prev.itemChanges.add
    .filter((item) => deletedParents.includes(item.parentItemId ?? ''))
    .map((item) => item.key);

  const add = prev.itemChanges.add.filter((item) => !removedIds.includes(item.key));

  return {
    select: {
      ...prev,
      itemChanges: {
        ...prev.itemChanges,
        add,
      },
    },
    removedIds,
  };
}
