import { ReactElement, useCallback, useMemo } from 'react';
import { Select, SingleValue } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectOption } from 'BootQuery/Assets/js/globalTypes';

import { TicketTypesForm } from './types';

export const DefaultTabSelect = (): ReactElement => {
  const { control, watch } = useFormContext<TicketTypesForm>();
  const canHaveDependencies = watch('canHaveDependencies');
  const canHaveWorks = watch('canHaveWorks');

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: 'defaultTab',
  });

  const options = useOptions({ canHaveDependencies, canHaveWorks });

  const selectedValue = options.find((item) => item.value === value);
  const setValue = useCallback(
    (newValue: SingleValue<SelectOption>) => {
      onChange(newValue?.value);
    },
    [onChange]
  );

  return (
    <Select<SelectOption, false>
      onChange={setValue}
      value={selectedValue}
      options={options}
      useBasicStyles
      size="sm"
      selectedOptionColorScheme="brand"
    />
  );
};

interface TypeFeatures {
  canHaveDependencies: boolean;
  canHaveWorks: boolean;
}

function useOptions(features: TypeFeatures): SelectOption[] {
  const { t } = useTranslation('Ticketing');

  const options = useMemo(
    () => [
      { value: 'details', label: t('Ticketing:details'), isVisible: true },
      { value: 'events', label: t('Ticketing:events'), isVisible: true },
      {
        value: 'dependencies',
        label: t('Ticketing:subtickets'),
        isVisible: features.canHaveDependencies,
      },
      {
        value: 'works',
        label: t('Ticketing:works'),
        isVisible: features.canHaveWorks,
      },
    ],
    [t, features.canHaveDependencies, features.canHaveWorks]
  );

  const availableOptions = useMemo(
    () => options.filter((obj) => obj.isVisible),
    [options]
  );

  return availableOptions;
}
