import Vuex from 'vuex';
import { invert } from 'lodash-es';
import { generateODataQuery, getCIF, getContact } from './cif-util';
import { CHANNEL_TYPES } from './in2-constants';

const CHANNEL_TYPE_MAP = invert(CHANNEL_TYPES);
const CALL_CHANNELS = [CHANNEL_TYPES.Phone, CHANNEL_TYPES.Mobile, CHANNEL_TYPES.Sms];

async function getDynamicsContactInfo(contactid) {
  const contact = await getContact(
    contactid,
    ['contactid', 'fullname'],
    ['in2_contact_in2_contact_data_Customer']
  );

  // Bail out if somehow failed
  if (!contact) {
    return null;
  }

  const phoneNumbers = contact.in2_contact_in2_contact_data_Customer
    .filter(({ in2_channel: channelId }) => CALL_CHANNELS.includes(channelId))
    .map(({ in2_contact_value: phoneNumber, in2_channel: channelId }) => {
      const channelName = CHANNEL_TYPE_MAP[channelId];
      return {
        phoneNumber,
        channelName,
        channelId,
      };
    });

  return {
    contactid,
    phoneNumbers,
    fullname: contact.fullname,
  };
}

const entityContactMeta = {
  incident: {
    idField: 'incidentid',
    contactIdField: '_customerid_value',
  },
  in2_hc_appointment: {
    idField: 'activityid',
    contactIdField: '_in2_contact_id_value',
  },
  in2_hc_encounter: {
    idField: 'in2_hc_encounterid',
    contactIdField: '_in2_contact_id_value',
  },
  in2_hc_policy: {
    idField: 'in2_hc_policyid',
    contactIdField: '_in2_contact_id_value',
  },
  in2_hc_v_diagnosis: {
    idField: 'in2_hc_v_diagnosisid',
    contactIdField: '_in2_contact_id_value',
  },
  in2_hc_v_medical_document: {
    idField: 'in2_hc_v_medical_documentid',
    contactIdField: '_in2_contact_id_value',
  },
  email: {
    idField: 'activityid',
    contactIdField: '_regardingobjectid_value',
  },
  phonecall: {
    idField: 'activityid',
    contactIdField: '_regardingobjectid_value',
  },
};

async function contactidForEntity(entityType, entityid) {
  if (entityType === 'contact') {
    return entityid;
  }

  const mapping = entityContactMeta[entityType];
  if (mapping) {
    const cif = await getCIF();
    const searchJSON = await cif.searchAndOpenRecords(
      entityType,
      generateODataQuery({
        select: mapping.contactIdField,
        filter: `${mapping.idField} eq '${entityid}'`,
      }),
      true
    );
    const entity = JSON.parse(searchJSON)[0] || null;
    if (entity) {
      return entity[mapping.contactIdField] || null;
    }
  }

  return null;
}

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    dynamicsContext: null,
    dynamicsContact: null,
    dynamicsUser: null,
  },
  actions: {
    async changeDynamicsContact({ commit }, contactid) {
      if (!contactid) {
        commit('setDynamicsContact', null);
        return;
      }

      commit('setDynamicsContact', { contactid, loaded: false });
      const contact = await getDynamicsContactInfo(contactid);
      if (contact) {
        commit('setDynamicsContact', { ...contact, loaded: true });
      } else {
        commit('setDynamicsContact', null);
      }
    },
    async changeDynamicsContext({ commit, dispatch }, context) {
      const { etn, pagetype, id } = context;

      let contactid = null;
      if (pagetype === 'entityrecord') {
        commit('setDynamicsContext', context);
        contactid = await contactidForEntity(etn, id);
      } else {
        commit('setDynamicsContext', null);
      }

      dispatch('changeDynamicsContact', contactid);
    },
  },
  mutations: {
    setDynamicsContact(state, contact) {
      state.dynamicsContact = contact;
    },
    setDynamicsContext(state, context) {
      state.dynamicsContext = context;
    },
    setDynamicsUser(state, user) {
      state.dynamicsUser = user;
    },
  },
});
