import { SetStateAction, useCallback } from 'react';

import i18n from 'BootQuery/Assets/js/i18n';

import { BaseItem } from '../DndContainers';
import { FieldChangeFn, useFormEditorContext } from './FormEditorContext';
import {
  BaseSettings,
  EditorFieldComponentProps,
  EditorItem,
  FieldType,
  TypeItem,
  ValueItem,
} from './types';

export function findType<ST extends BaseSettings>(
  fieldTypes: FieldType<ST>[],
  type: string
): FieldType<ST> {
  const fieldType = fieldTypes.find((field) => field.type === type);
  if (!fieldType) {
    throw new Error(`Field type ${type} not found`);
  }

  return fieldType as FieldType<ST>;
}

export type FieldSettingsArgs<ST extends BaseSettings> =
  EditorFieldComponentProps<ST>;

export type FieldSettingsRet<ST extends BaseSettings> = {
  setSetting: <K extends keyof ST>(
    key: K,
    newVal: SetStateAction<ST[K]>
  ) => void;
  onChange: (change: Partial<ValueItem<ST>> | FieldChangeFn<ST>) => void;
};

export function useFieldSettings<ST extends BaseSettings>({
  id,
}: FieldSettingsArgs<ST>): FieldSettingsRet<ST> {
  const { modifyField } = useFormEditorContext();
  const onChange = useCallback(
    (changed: Partial<ValueItem<ST>> | FieldChangeFn<ST>) => {
      if (typeof changed === 'function') {
        modifyField(id, changed as unknown as FieldChangeFn<BaseSettings>);
      } else {
        modifyField(id, (prev) => ({ ...prev, ...changed }));
      }
    },
    [id, modifyField]
  );

  const doSetSetting = <K extends keyof ST>(
    key: K,
    newVal: SetStateAction<ST[K]>
  ): void => {
    onChange((prev) => {
      const prevVal = prev.settings[key];
      const val =
        typeof newVal === 'function'
          ? (newVal as (prev: ST[K]) => ST[K])(prevVal)
          : newVal;

      return {
        ...prev,
        settings: {
          ...prev.settings,
          [key]: val,
        },
      };
    });
  };

  const setSetting = useCallback(doSetSetting, [onChange]);

  return { setSetting, onChange };
}

export function uniqid(): string {
  return Math.random().toString(36).substring(2);
}

export function newFieldId(): string {
  return `$new-${uniqid()}`;
}

export function newFieldName(): string {
  const innerName = i18n.t('global:form_editor.untitled_field');

  return `[${innerName}]`;
}

export function itemIsType(item: EditorItem): item is BaseItem<TypeItem> {
  return item.content.itemType === 'fieldType';
}
export function itemIsField(item: EditorItem): item is BaseItem<ValueItem> {
  return item.content.itemType === 'fieldValue';
}

export function assertIsType(
  item: EditorItem
): asserts item is BaseItem<TypeItem> {
  if (!itemIsType(item)) {
    throw new Error(`Wanted fieldType, got ${item.content.itemType}`);
  }
}
export function assertIsField(
  item: EditorItem
): asserts item is BaseItem<ValueItem> {
  if (!itemIsField(item)) {
    throw new Error(`Wanted fieldValue, got ${item.content.itemType}`);
  }
}
