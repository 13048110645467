import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal';

import {
  AppointmentEventsProvider,
  useAppointmentEvents,
} from './AppointmentEventsProvider';
import {
  AppointmentFormModal,
  AppointmentFormModalProps,
} from './AppointmentFormModal';
import { IAppointment } from './schema';

export const useAppointmentFormModal = () => {
  const { addModal } = useModal();
  const handlers = useAppointmentEvents();

  return useCallback(
    (
      props: AppointmentFormModalProps,
      callback?: (data: IAppointment) => void
    ) => {
      addModal<IAppointment>({
        callback,
        children: (
          // Copy the context value because the modal is rendered outside, in
          // modal manager's root
          <AppointmentEventsProvider {...handlers}>
            <AppointmentFormModal {...props} />
          </AppointmentEventsProvider>
        ),
        preventClose: {
          defaultPreventState: false,
        },
        componentProps: {
          modal: {
            size: '2xl',
          },
        },
      });
    },
    [addModal, handlers]
  );
};
