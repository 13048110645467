import { ReactElement, useCallback, useMemo, useReducer } from 'react';
import { Box, chakra } from '@chakra-ui/react';
import { useDrop, useDropArea } from 'react-use';

import { pickFiles } from 'BootQuery/Assets/js/pick-files';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { addUploads } from './add-uploads';
import { FileList } from './FileList';
import { filesStateReducer } from './files-state';
import { FileError, FileValue } from './types';
import { validateFiles } from './util';

export interface FileInputProps {
  title?: string;
  allowRemovingSaved?: boolean;
  value: FileValue[];
  onChange: (value: FileValue[]) => void;
  onError?: (value: FileError[]) => void;
  accept?: string[] | string;
  maxSize?: number;
}

const FileInputWrapper = chakra(Box, {
  baseStyle: {
    bg: 'gray.50',
    borderColor: 'gray.200',
    w: 'full',
    borderWidth: 'medium',
    borderRadius: 'md',
    borderStyle: 'dashed',
    cursor: 'pointer',
    position: 'relative',
    _hover: {
      bg: 'gray.100',
      borderColor: 'gray.300',
    },
  },
});

const FileInputTitle = chakra(Box, {
  baseStyle: {
    color: 'gray.400',
    w: 'full',
    px: '2',
    py: '1',
    fontSize: 'xs',
    fontWeight: 'bold',
    pointerEvents: 'none',
  },
});

const FileInputOverlay = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
});

const dragOverStyle = { borderColor: 'blue.300', bg: 'blue.50' };
const disablePointerEvents = { '*': { pointerEvents: 'none' } };

export const FileInput = ({
  title,
  value,
  onChange,
  accept,
  maxSize,
  onError,
}: FileInputProps): ReactElement => {
  const [files, setFiles] = useReducer(filesStateReducer, value);
  const { over: isDragging } = useDrop();

  const outVal = useMemo(
    () => files.filter((f): f is FileValue => f.type !== 'uploading'),
    [files]
  );

  // Detect actual value change from outside and take the value
  // value is compared to outVal so we know the parent didn't just
  // return the value we sent back through onChange
  useChangeEffect(
    outVal,
    () => {
      onChange(outVal);
    },
    [onChange]
  );

  const onFiles = useCallback(
    (newFiles: File[] | null) => {
      if (!newFiles) {
        return;
      }

      const { valid, invalid } = validateFiles(
        newFiles,
        maxSize ?? null,
        accept ?? null
      );

      if (onError && invalid.length > 0) {
        onError(invalid);
      }
      if (valid.length > 0) {
        addUploads(valid, setFiles);
      }
    },
    [maxSize, accept, onError]
  );

  const openPicker = useCallback(() => {
    pickFiles({ accept, multiple: true }).then(onFiles);
  }, [onFiles, accept]);

  const [bond, { over }] = useDropArea({ onFiles });
  const outerStyle = isDragging ? disablePointerEvents : undefined;
  const wrapperStyle = over ? dragOverStyle : {};
  const wrapperBottomPadding = title ? { pb: '6' } : { py: '4' };

  return (
    <FileInputWrapper
      color='blackAlpha.700'
      sx={outerStyle}
      {...bond}
      {...wrapperStyle}
      {...wrapperBottomPadding}
    >
      <FileInputOverlay onClick={openPicker} />
      {title && <FileInputTitle>{title}</FileInputTitle>}
      <FileList files={files} dispatch={setFiles} />
    </FileInputWrapper>
  );
};
