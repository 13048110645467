import { cssVar, defineStyleConfig } from '@chakra-ui/react';
import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const $arrowBg = cssVar('popper-arrow-bg');

const baseStyle = (brandColors: BrandColors) => ({
  color: brandColors.fontStaticDark,
  bg: brandColors.background,
  [$arrowBg.variable]: brandColors.background,
  _dark: {
    bg: brandColors.backgroundDark,
    color: brandColors.fontStaticLight,
    [$arrowBg.variable]: brandColors.backgroundDark,
  },
});

export const tooltipTheme = (brandColors: BrandColors) => {
  return defineStyleConfig({ baseStyle: baseStyle(brandColors) });
};
