import Vuex from 'vuex';

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    waitingChatCount: 0,
  },
  mutations: {
    setWaitingChatCount(state, waitingChatCount) {
      state.waitingChatCount = waitingChatCount;
    },
  },
});
