import { useEffect } from 'react';

export function useDelayedInterval(
  callback: () => void,
  intervalMS: number,
  initialDelayMS: number
): void {
  useEffect(() => {
    let interval: number|null = null;
    let initialDelay: number|null = window.setTimeout(() => {
      initialDelay = null;

      interval = window.setInterval(callback, intervalMS);
      callback();
    }, initialDelayMS);

    return () => {
      if (initialDelay) {
        window.clearTimeout(initialDelay);
      }
      if (interval) {
        window.clearInterval(interval);
      }
    };
  }, [callback, intervalMS, initialDelayMS]);
}
