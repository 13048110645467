import { Box, BoxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export const LayoutBody = forwardRef<HTMLDivElement, BoxProps>(
  (props: BoxProps, ref) => (
    <Box
      px="6"
      py="4"
      w="full"
      h="full"
      overflow="auto"
      {...props}
      ref={ref}
    />
  )
);

LayoutBody.displayName = 'LayoutBody';
