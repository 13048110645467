import { ChangeEvent, ReactElement, useCallback } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Select,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { DateRange, DateRangeInput } from '@bq/components/DateInput';
import { FilterBar } from '@bq/components/FilterBar';

import { useReportContext } from './ReportContext';
import { ReportGroupByMenu } from './ReportGroupByMenu';
import { TimeStep } from './types';

function validTimeStep(val: string): val is TimeStep {
  return (
    val === 'hour' ||
    val === 'day' ||
    val === 'week' ||
    val === 'month' ||
    val === 'year'
  );
}

interface StepSelectorProps {
  value: TimeStep;
  onChange: (step: TimeStep) => void;
}

const StepSelector = ({ value, onChange }: StepSelectorProps): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const handleChange = useCallback(
    (ev: ChangeEvent<HTMLSelectElement>) => {
      const val = ev.target.value;

      if (!validTimeStep(val)) {
        throw new Error(`Invalid time step ${val}`);
      }

      onChange(val);
    },
    [onChange]
  );

  return (
    <Select value={value} onChange={handleChange}>
      <option value="hour">{t('global:time_units.hour')}</option>
      <option value="day">{t('global:time_units.day')}</option>
      <option value="week">{t('global:time_units.week')}</option>
      <option value="month">{t('global:time_units.month')}</option>
      <option value="year">{t('global:time_units.year')}</option>
    </Select>
  );
};

export const ReportSettings = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { settings, setSettings, filterTypes, filterVal, setFilterVal } =
    useReportContext();
  const onPeriodChange = useCallback(
    (datePeriod: DateRange | null) => {
      setSettings((prev) => ({ ...prev, datePeriod }));
    },
    [setSettings]
  );

  const onStepChange = useCallback(
    (step: string | null) => {
      if (!step || !validTimeStep(step)) {
        console.error(`Invalid time step ${step}`);

        return;
      }
      setSettings((prev) => ({ ...prev, step }));
    },
    [setSettings]
  );

  return (
    <VStack w="full" p={5}>
      <Box my={5} w="full">
        <FilterBar
          filterTypes={filterTypes}
          value={filterVal}
          onChange={(val) => {
            setFilterVal(val);
          }}
        />
      </Box>
      <HStack
        w="full"
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing="12"
      >
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="bold">
            {t('global:group_by')}:
          </FormLabel>
          <ReportGroupByMenu />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="bold">
            {t('global:reports.time_range')}:
          </FormLabel>
          <DateRangeInput
            value={settings.datePeriod}
            onChange={onPeriodChange}
            inputClass="custom-input"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm" fontWeight="bold">
            {t('global:reports.time_step')}:
          </FormLabel>
          <StepSelector value={settings.step} onChange={onStepChange} />
        </FormControl>
      </HStack>
    </VStack>
  );
};
