import { Context, useContext } from 'react';

type ContextObj<K extends string, T> = {
  [key in K]: Context<T>;
};

export function makeUseContext<K extends string, T>(
  ctxObj: ContextObj<K, T>
): () => NonNullable<T> {
  const ctxName = Object.keys(ctxObj)[0] as K;
  const ctxType = ctxObj[ctxName];

  return (): NonNullable<T> => {
    const ctx = useContext(ctxType);
    if (!ctx) {
      throw new Error(`Missing context ${ctxName}`);
    }

    return ctx;
  };
}
